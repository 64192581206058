<div id="container">
  <div class="pastilla">
    <span class="tituloLista">{{textoMostrar}}</span>
    <span class="icon-close" (click)="deseleccionar()">
      <svg><use xlink:href="#icon-close"></use></svg>
    </span>
  </div>
  <span class="pastilla">
    <span class="hs-bin" (click)="borrar()">
      <svg><use xlink:href="#hs-bin"></use></svg>
    </span>
    <span class="tituloLista" (click)="borrar()">Borrar {{tipoDato}}</span>
  </span>
</div>
