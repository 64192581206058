import { ColDef } from "ag-grid-community";
import { DatosEmpleadosService } from "../services/mantenimiento/datos-empleados.service";

export class GridColDefsEmpleados{

  constructor(private datosEmpleadosService: DatosEmpleadosService){}

  columnDefs: ColDef[] = [
    { headerName:"Código", field: "codigo", sortable:true, resizable:true, headerClass:"header", width: 250, headerCheckboxSelection: this.datosEmpleadosService.consultaEmpleados ? false : true,
    checkboxSelection: this.datosEmpleadosService.consultaEmpleados ? false : true},
    { headerName:"Nombre", field: "nombre", sortable:true, resizable:true, headerClass:"header", width: 180},
    { headerName:"Apellido 1", field: "apellido1", sortable:true, resizable:true, headerClass:"header", width: 180},
    { headerName:"Apellido 2", field: "apellido2", sortable:true, resizable:true, headerClass:"header", width: 180 },
    { headerName:"Nif", field: "nif", sortable:true, resizable:true, headerClass:"header", width: 150 },
    { headerName:"Empresa", field: "nombreEmpresa", sortable:true, resizable:true, headerClass:"header", width: 300,
      cellRenderer: params => {
        if(params.data.nombreEmpresa){
          return `${params.data.codigoEmpresa} - ${params.data.nombreEmpresa}`
        }else return null
      }
    },
    { headerName:"Centro", field: "nombreCentro", sortable:true, resizable:true, headerClass:"header", width: 300,
      cellRenderer: params => {
        if(params.data.nombreCentro){
          return `${params.data.codigoCentro} - ${params.data.nombreCentro}`
        }else return null
      }
    }
  ];
}
