import { ColDef } from 'ag-grid-community';
import { Firmantes } from './firmantes';
import { EstadosTipoEnvio } from './tipo-envio';

export class GridColDefsEnvios{

  public columnDefs: ColDef[] = [
    { headerName: "Nombre del dosier", field: "nombre", sortable:true, resizable: true, headerClass: "header", width: 500, comparator: customComparator },
    { headerName: "Nº docs", field: "tiposDocumento", sortable:true, resizable: true, headerClass: "header", width: 200,
    cellRenderer: params => {
      let nDocumentos = params.data.tiposDocumento.length;
      return nDocumentos
    } },
    { headerName: "Firmantes", field: "firmantes", sortable:true, resizable: true, headerClass: "header", width: 200,
      cellRenderer: params => {
        let nFirmantes = params.data.firmantes.length
        return nFirmantes
      }
    },
    { headerName: "Validadores", field: "validadores", sortable:true, resizable: true, headerClass: "header", width: 200,
      // cellRenderer: params => {
      //   let validadoresArray = params.data.firmantes.filter((x: Firmantes) => x.tipoDestinatario.desc == 'Validador');
      //   let nValidadores = validadoresArray.length;
      //   return nValidadores
      // }, 
      valueGetter: params => {
        let validadoresArray = params.data.firmantes.filter((x: Firmantes) => x.tipoDestinatario.desc == 'Validador');
        let nValidadores = validadoresArray.length;
        return nValidadores
      }
    },
    { headerName: "Estado", field: "estado", sortable:true, resizable: true, headerClass: "header", width: 1000,
      cellRenderer: params => {
        let estado: EstadosTipoEnvio;
        if(params.data.activo == true) estado = EstadosTipoEnvio.completo
        else estado = EstadosTipoEnvio.incompleto
        return estado
      }
    }
  ]
}

const customComparator = (valueA:any, valueB:any) => {
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};
