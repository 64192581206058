<div class="modal-nombre">{{firma.empleado}}</div>
<div *ngIf="firma.nombreEmpresa && firma.nombreCentro" class="modal-datos">{{firma.nombreEmpresa}} - {{getNombreCentro()}}</div>
<div *ngIf="!firma.nombreEmpresa && firma.nombreCentro" class="modal-datos">?? - {{getNombreCentro()}}</div>
<div *ngIf="firma.nombreEmpresa && !firma.nombreCentro" class="modal-datos">{{firma.nombreEmpresa}}</div>
<div *ngIf="!firma.nombreEmpresa && !firma.nombreCentro" class="modal-datos">?? - ??</div>
<div class="modal-datos">{{firma.tipoEnvio}}</div>
<div class="modal-datos" [innerHTML]="getIcono()"></div>
<app-detalle-firmas-errores *ngIf="firma.estadoString == 'Error'" [firma]="firma"></app-detalle-firmas-errores>


