import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TipoFirmante } from '../../../../envios/models/tipo-firmante';
import { FirmanteNif } from '../../../models/firmante-nif';
import { FormularioDocumentoService } from '../../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../../services/formulario-validaciones-docs.service';

@Component({
  selector: 'app-configuracion-firmas-certificado',
  templateUrl: './configuracion-firmas-certificado.component.html',
  styleUrls: ['./configuracion-firmas-certificado.component.css']
})
export class ConfiguracionFirmasCertificadoComponent implements OnInit {

  @Input() firmante: TipoFirmante;

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              public formularioValidacionesDocsService: FormularioValidacionesDocsService) { }

  ngOnInit(): void {
  }

  get formFirma(){ return this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup}

  obtenerIndexNifArray():number{
    return this.formularioDocumentoService.firmantesNif.value.findIndex((x:FirmanteNif) => x.tipoFirmante.codigo == this.firmante.codigo)
  }

}
