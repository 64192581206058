<div class="main">
  <h2>Identifiación del firmante principal</h2>
<form [formGroup]="formularioDocumentoService.formularioDocumento">
  <div class="input-firmante">
    <div class="input-radio">
      <input type="radio" id="generico1" formControlName="generico" [value]=false>
    </div>
    <span>
      <svg class="icon">
        <use xlink:href="#icon-personal"></use>
      </svg>
    </span>
    <label for="generico1">Contiene datos de identificación del firmante principal</label>
  </div>
  <p>Los documentos se subirán en el momento del envío conteniendo los datos identificativos del firmante principal</p>
  <div class="input-firmante">
    <div class="input-radio">
      <input type="radio" id="generico2" formControlName="generico" [value]=true>
    </div>
    <span>
      <svg class="icon">
        <use xlink:href="#icon-plantilla"></use>
      </svg>
    </span>
    <label for="generico2">Los datos de identificación se incluirán antes del envío</label>
  </div>
  <p>El documento  se guardará como plantilla y será autorellenado antes del envío con los datos identificativos de los firmantes principales al combinarlo con un listado excel de firmantes</p>
  <span class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValido('generico')">
    {{formularioValidacionesDocsService.mensajeErrorGenerico}}
    </span>
</form>
</div>

