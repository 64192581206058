import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatosConfiguracionDocumentosService } from '../../services/datos-configuracion-documentos.service';
import { DatosDocumentosService } from '../../services/datos-documentos.service';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../services/formulario-validaciones-docs.service';

@Component({
  selector: 'app-configuracion-documento-datos-cif',
  templateUrl: './configuracion-documento-datos-cif.component.html',
  styleUrls: ['./configuracion-documento-datos-cif.component.css']
})
export class ConfiguracionDocumentoDatosCifComponent implements OnInit {

  cif:string = '';
  @Input() idPdf: string;
  cargando: boolean = false;

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              private datosDocumentosService: DatosDocumentosService,
              public datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService,
              public formularioValidacionesDocsService: FormularioValidacionesDocsService) { }

  ngOnInit(): void {
    this.mostrarCif()

  }

  get cifPage(){ return this.formularioDocumentoService.formularioDocumento.controls['cifPage'] }
  get cifOrden(){ return this.formularioDocumentoService.formularioDocumento.controls['cifOrden'] }
  get cifFormulario(){ return this.formularioDocumentoService.formularioDocumento.controls['cifFormulario'] }
  get posicionCif(){ return this.formularioDocumentoService.formularioDocumento.controls['posicionCif'] }
  get idTipoDocumento(){ return this.formularioDocumentoService.formularioDocumento.controls['idTipoDocumento'] }

  async traeCif(id:string, pagina:number, orden:number): Promise<string>{
    return await this.datosDocumentosService.traeCif(id, pagina, orden).toPromise();
  }

  mostrarCif(){
    this.datosConfiguracionDocumentosService.nohayCif = false;
    if(this.cifPage.value > 0 && this.cifPage.value <= this.formularioDocumentoService.totalPages && this.cifOrden.value > 0){
      this.cargando = true;
      this.traeCif(this.idPdf, this.cifPage.value, this.cifOrden.value).then(
        value => {
          this.cif = value;
          this.datosConfiguracionDocumentosService.nohayCif = false;
          this.cargando = false;
        },
        error => {
          console.error(error);
          this.cargando = false;
          this.datosConfiguracionDocumentosService.nohayCif = true;
          this.cif = "";
        }
      )
    }else{
      this.cif = ""
    }
  }

  borrarInputContrario(tipo:string){
    if(tipo=='pagina'){
      this.posicionCif.setValue('pagina');
      this.cifFormulario.setValue(null);

    }
    if(tipo=='formulario'){
      this.posicionCif.setValue('formulario');
      this.cifPage.setValue(null);
      this.cifOrden.setValue(null);
      this.cif='';
      this.datosConfiguracionDocumentosService.nohayCif = false;
    }
  }
}
