<h6>PROGRESO</h6>
<div class="aside-container">
  <div class="aside-content">
    <ul>
      <div *ngFor="let elemento of elementos; let i = index">
        <li class="{{getClass(i)}}"><span></span><label *ngIf="!oculto">{{elemento}}</label></li>
      </div>
    </ul>
  </div>
</div>
