import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-empleados-barra-superior',
  templateUrl: './empleados-barra-superior.component.html',
  styleUrls: ['./empleados-barra-superior.component.css']
})
export class EmpleadosBarraSuperiorComponent implements OnInit {

  @Output() botonClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  botonAccionClicked(id: string){
    this.botonClicked.emit(id);
  }

}
