<div>
  <div class="flex">
    <!-- <app-indicador-pastilla-filtro *ngIf="isEntornoSandbox"
                                   [tituloFiltro]="'Sandbox'"
                                   [color]="'white'"
                                   [bgColor]="'var(--hs-lila)'"
                                   [colorNum]="'var(--hs-lila)'"
                                   [colorX]="'white'"
                                   [colorHover]="'var(--hs-grey3)'"
                                   [num]="datosFirmaService.firmasFiltradas.length"
                                   (limpiar)="getSelectedItems($event)"></app-indicador-pastilla-filtro>
    <app-indicador-pastilla-filtro *ngIf="verNuevasParam"
                                   [tituloFiltro]="'Nuevos'"
                                   [color]="'var(--hs-black)'"
                                   [bgColor]="'var(--hs-yellow1)'"
                                   [colorNum]="'white'"
                                   [colorHover]="'var(--hs-grey1)'"
                                   [bgCirculo]="'#f57411'"
                                   [num]="datosFirmaService.firmasFiltradas.length"
                                   (limpiar)="getSelectedItems($event)"></app-indicador-pastilla-filtro> -->
    <app-drop-down-multiseleccion [listaDesplegable]="empresasList"
                                  [tituloDropDown]="'Empresa'"
                                  (seleccionItemsOut)="anadirFiltros($event)"
                                  [limpiarTodo]="limpiarTodo"></app-drop-down-multiseleccion>
    <app-drop-down-multiseleccion [listaDesplegable]="centrosList"
                                  [tituloDropDown]="'Centro'"
                                  (seleccionItemsOut)="anadirFiltros($event)"
                                  [limpiarTodo]="limpiarTodo"></app-drop-down-multiseleccion>

    <span *ngIf="isFiltrosLimpios()>0" (click)="limpiarFiltros()" class="eliminar">
      Limpiar filtros
    </span>
  </div>
</div>
