import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RegisterComponent } from './components/register/register.component';
import { IniPageComponent } from './components/loginAzureAd/ini-page/ini-page.component';
import { LoginAzureAdComponent } from './components/loginAzureAd/login-azure-ad/login-azure-ad.component';
import { SelectorTenantsComponent } from './components/loginAzureAd/selector-tenants/selector-tenants.component';
import { InvitacionUsuarioModalComponent } from './components/invitacion-usuario-modal/invitacion-usuario-modal.component';


@NgModule({
  declarations: [LoginPageComponent, LoginFormComponent, RegisterComponent, IniPageComponent, LoginAzureAdComponent, SelectorTenantsComponent, InvitacionUsuarioModalComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class LoginModule { }
