import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SeleccionItems } from 'src/app/shared/models/desplegable-data';

@Component({
  selector: 'app-indicador-pastilla-filtro',
  templateUrl: './indicador-pastilla-filtro.component.html',
  styleUrls: ['./indicador-pastilla-filtro.component.css']
})
export class IndicadorPastillaFiltroComponent {

  @Input() tituloFiltro:string = '';
  @Input() num:number = null;
  @Input() color:string;
  @Input() bgColor:string;
  @Input() colorNum:string;
  @Input() colorX:string;
  @Input() colorHover:string;
  @Input() bgCirculo:string;
  @Input() paddingPastilla:number = 4;
  @Output() limpiar: EventEmitter<SeleccionItems> = new EventEmitter<SeleccionItems>();
  hover: boolean;

  limpiarFiltro(){
    this.limpiar.emit({
      tipo: this.tituloFiltro,
      items: []
    });
  }
}
