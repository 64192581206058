import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TipoEnvio } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';



@Component({
  selector: 'app-selector-tipo-envio',
  templateUrl: './selector-tipo-envio.component.html',
  styleUrls: ['./selector-tipo-envio.component.css']
})
export class SelectorTipoEnvioComponent implements OnInit {

  @Input() tiposEnvio: TipoEnvio[] = [];
  @Input() tiposEnvioCargados: boolean = false;
  @Output() tipoEnvioSelected: EventEmitter<string> = new EventEmitter<string>();;
  panelOpenState: boolean = false;
  textoBuscar = '';
  @Input() isSandbox: boolean = false;

  constructor() { }

  ngOnInit(): void {

  }

  selectTipoEnvio(idTipoEnvio: string){
    this.tipoEnvioSelected.emit(idTipoEnvio);
  }

  filtrar(_textoBuscar: string) {
    this.textoBuscar = _textoBuscar;
  }

  getTiposEnvioFiltro(){
    if(this.textoBuscar?.trim().length > 0) {
      let enviosActivos = this.tiposEnvio.filter(x => x.nombre.toUpperCase().includes(this.textoBuscar.trim().toUpperCase()));
      return enviosActivos.filter(x=> x.activo==true)
    }else {
      return this.tiposEnvio.filter(x=> x.activo==true)
    }
  }
}
