<!-- <div class="modal-alert">
    <svg class="icon-alert">
      <use xlink:href="#icon-alert"></use>
    </svg>
    <span>¡Acción irreversible!</span>
</div>			 -->
<!-- <div class="modal-alert">
    <svg class="icon-alert">
      <use xlink:href="#hs-alert"></use>
    </svg>
    <span>¡Acción irreversible!</span>
</div> -->

<div class="alert">
  <svg class="icon-alert">
    <use xlink:href="#hs-alert"></use>
  </svg>
  <!-- <div class="alert-message"> -->
    <span>¡Acción irreversible!</span>
  <!-- </div> -->
</div>
