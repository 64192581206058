import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LoginModule } from './pages/login/login.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuPrincipalModule } from './pages/menuPrincipal/menu-principal.module';
import { PanelControlModule } from './pages/panelControl/panel-control.module';
import { TokenInetrcptInterceptor } from './pages/login/services/token-inetrcpt.interceptor';
import { SubidaPrincipalModule } from './pages/subidaPrincipal/subida-principal.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { EmpleadosModule } from './pages/empleados/empleados.module';
import { MaestrosModule } from './pages/configuracion/maestros/maestros.module';
import { EnviosModule  } from './pages/configuracion/Envios/Envios.module';
import { ConfiguracionMaestrosComponent } from './pages/configuracion/configuracion-maestros/configuracion-maestros.component';
import { DocumentosModule } from './pages/configuracion/Envios/documentos/documentos.module';
import { SeguridadModule } from './pages/seguridad/seguridad.module';
import { ConfiguracionModule } from './pages/configuracion/configuracion.module';
import { AgGridModule } from 'ag-grid-angular';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    ConfiguracionMaestrosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    LoginModule,
    MenuPrincipalModule,
    PanelControlModule,
    SubidaPrincipalModule,
    MatMomentDateModule,
    MaestrosModule,
    EnviosModule,
    EmpleadosModule,
    DocumentosModule,
    SeguridadModule,
    ConfiguracionModule,
    AgGridModule
  ],
  exports:[
    MatMomentDateModule
  ],
  providers: [
    // Registramos el interceptor para que se ejecute en cada peticion http. Con multi = true, podremos añadir más interceptores
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInetrcptInterceptor,
      multi: true
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS,
    },


  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
