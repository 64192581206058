import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TipoFirmante, TipoFirmanteCopiaBasica } from '../../../envios/models/tipo-firmante';
import { ConfiguracionFirma, FirmanteNif } from '../../models/firmante-nif';
import { DatosDocumentosService } from '../../services/datos-documentos.service';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../services/formulario-validaciones-docs.service';
import { DatosConfiguracionDocumentosService } from '../../services/datos-configuracion-documentos.service';

@Component({
  selector: 'app-configuracion-documento-datos-nif',
  templateUrl: './configuracion-documento-datos-nif.component.html',
  styleUrls: ['./configuracion-documento-datos-nif.component.css']
})
export class ConfiguracionDocumentoDatosNifComponent implements OnInit {

  nif: string = '';
  @Input() tipoFirmante: TipoFirmanteCopiaBasica; //TipoFirmante
  @Input() idPdf: string;
  firmanteDetalle: boolean = false;
  firmanteNifForm: FormGroup = new FormGroup({});
  cargando: boolean = false;
  @Output() incluirFirmanteEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              private fb: FormBuilder,
              public formularioValidacionesDocsService: FormularioValidacionesDocsService,
              private datosDocumentosService: DatosDocumentosService,
              public datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService) {
                this.montarFormularioVacio();
               }

  ngOnInit(): void {
    this.inicializaFormularioFirmanteNif();
  }

  get idTipoDocumento(){ return this.formularioDocumentoService.formularioDocumento.controls['idTipoDocumento'] }
  get firmantesNif(){ return this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'] as FormArray}
  get posicionNif(){ return this.firmanteNifForm.controls['posicionNif']}
  get nifPage(){ return this.firmanteNifForm.controls['nifPage']}
  get nifOrden(){ return this.firmanteNifForm.controls['nifOrden']}
  get nifFormulario(){return this.firmanteNifForm.controls['nifFormulario'] }
  get incluirFirmante(){ return this.firmanteNifForm.controls['incluirFirmante']}
  get generico(){return this.formularioDocumentoService.formularioDocumento.controls['generico'] }
  get esCopiaBasica(){return this.formularioDocumentoService.formularioDocumento.controls['esCopiaBasica'] }

  montarFormularioVacio(){
    this.firmanteNifForm = this.fb.group({
      tipoFirmante: [],
      posicionNif: [],
      nifPage: [],
      nifOrden: [],
      nifFormulario:[],
      incluirFirmante:[]
    })
  }

  //si el array de nifs no está vacío, montamos el formulario con estos valores y mostramos el Nif correspondiente
  //si esCopiaBasica true, el firmante principal aparece ya informado con solo check y deshabilitado para que no se pueda eliminar. Si volvemos atrás y deschequeamos copia básica, el firmante principal se elimina y se muestra con la flecha para poder mostarlo y añadir page y orden o formulario.
  inicializaFormularioFirmanteNif(){
    if(this.firmantesNif.value.length > 0){
      this.firmantesNif.value.forEach((x:any, i:number) => {
        if(x.tipoFirmante.codigo == this.tipoFirmante.codigo){
          this.firmanteNifForm = this.fb.group({
            tipoFirmante: [this.tipoFirmante.desc],
            posicionNif:[this.buscarPosicionNif(x.nifOrden, x.nifPage, x.nifFormulario)],
            nifPage: [x.nifPage==0 ? null : x.nifPage],
            nifOrden: [(x.nifOrden==0 && !this.generico.value && !this.esCopiaBasica.value)? null : x.nifOrden],
            nifFormulario:[x.nifFormulario=='' ? null : x.nifFormulario],
            incluirFirmante:[{value: (((this.generico.value || this.esCopiaBasica.value) && x.nifPage==1) || this.tipoFirmante.checkSiCopiaBasica) ? true : false, disabled: this.tipoFirmante.checkSiCopiaBasica}]
          })
          this.firmantesNif.at(i).get('posicionNif').setValue(this.buscarPosicionNif(x.nifOrden, x.nifPage, x.nifFormulario))
          this.firmantesNif.at(i).get('nifPage').setValue(x.nifPage==0 ? null : x.nifPage);
          this.firmantesNif.at(i).get('nifOrden').setValue((x.nifOrden==0 && !this.generico.value && !this.esCopiaBasica.value) ? null : x.nifOrden);
          this.firmantesNif.at(i).get('nifFormulario').setValue(x.nifFormulario=='' ? null : x.nifFormulario)
          // this.cargando = true;
          if(this.nifPage.value > 0 && this.nifPage.value <= this.formularioDocumentoService.totalPages && this.nifOrden.value > 0){
            this.cargando = true;
            this.muestraNif(this.idPdf, this.nifPage.value, this.nifOrden.value);
          }else{
            this.nif = "";
            this.datosConfiguracionDocumentosService.noExistenNifs[this.obtenerIndexNifArray()] = false;
          }
          // this.muestraNif(this.idPdf, this.nifPage.value, this.nifOrden.value);
          if(!this.generico.value && !this.tipoFirmante.checkSiCopiaBasica) this.firmanteDetalle = true
        }
      })
    }else{
      this.firmanteNifForm = this.fb.group({
        tipoFirmante: [this.tipoFirmante.desc],
        posicionNif: [],
        nifPage: [],
        nifOrden: [],
        nifFormulario:[],
        incluirFirmante:[{value: this.tipoFirmante.checkSiCopiaBasica ? true : false, disabled: this.tipoFirmante.checkSiCopiaBasica}]
      })
      if(this.tipoFirmante.checkSiCopiaBasica){
        this.firmanteNifForm.get('posicionNif').setValue('pagina');
        this.firmanteNifForm.get('nifPage').setValue(1);
        this.firmanteNifForm.get('nifOrden').setValue(0);
        this.firmanteNifForm.get('nifFormulario').setValue(null);
        this.addFirmanteNifInForm();
      }
    }
    //si no hay datos borramos el firmante de FirmantesNif, para que salte el error de no hay firmantes en las validaciones
    if(this.nifPage.value == null && this.nifOrden.value == null && this.nifFormulario.value==null && this.posicionNif.value==null){
      let indexBorrar = this.firmantesNif.value.findIndex(x=>x.tipoFirmante.desc==this.firmanteNifForm.value.tipoFirmante)
      if(indexBorrar>-1){
        this.formularioDocumentoService.deleteFirmanteNifInFormArray(this.firmantesNif.at(indexBorrar).value)
      }
    }
  }

  buscarPosicionNif(nifOrden: number, nifPage: number, nifFormulario:string):string{
    if(nifOrden || nifPage) return 'pagina'
    if(nifFormulario) return 'formulario'
    return null
  }

  mostrarDetalle(){
    this.firmanteDetalle = !this.firmanteDetalle
  }

  addFirmanteNifInForm(){
    let valoresFirmanteNif: FirmanteNif;
    valoresFirmanteNif = {
      tipoFirmante: {
        codigo: this.tipoFirmante.codigo,
        desc: this.tipoFirmante.desc
      },
      nifPage: this.firmanteNifForm.controls['nifPage'].value,
      nifOrden: this.firmanteNifForm.controls['nifOrden'].value,
      firmas: {} as ConfiguracionFirma,
      formularios: [],
      camposEditables: [],
      nifFormulario:this.firmanteNifForm.controls['nifFormulario'].value,
      posicionNif: this.firmanteNifForm.controls['posicionNif'].value
    }

    //borramos la entrada del array solo cuando los dos valores son nulos, sinó no para que se puedan hacer las validaciones
    if(this.nifPage.value == null && this.nifOrden.value == null && this.nifFormulario.value==null && this.posicionNif.value==null){
      this.formularioDocumentoService.deleteFirmanteNifInFormArray(valoresFirmanteNif)
    }else{
      this.formularioDocumentoService.addFirmanteNifInFormArray(valoresFirmanteNif)
      this.datosConfiguracionDocumentosService.noExistenNifs[this.obtenerIndexNifArray()] = false;
      if(this.nifPage.value > 0 && this.nifPage.value <= this.formularioDocumentoService.totalPages && this.nifOrden.value > 0){
        this.cargando = true;
        this.muestraNif(this.idPdf, valoresFirmanteNif.nifPage, valoresFirmanteNif.nifOrden);
      }else{
        this.nif = "";
        this.datosConfiguracionDocumentosService.noExistenNifs[this.obtenerIndexNifArray()] = false;
      }
    }


  }

  obtenerIndexNifArray():number{
    return this.formularioDocumentoService.firmantesNif.value.findIndex((x:FirmanteNif) => x.tipoFirmante.codigo == this.tipoFirmante.codigo)
  }

  muestraNif(id:string, pagina:number, orden:number){
    this.traeNif(id, pagina, orden).then(
      value => {
        this.nif = value;
        this.datosConfiguracionDocumentosService.noExistenNifs[this.obtenerIndexNifArray()] = false;
        this.cargando = false;
      },
      error => {
        console.error(error);
        this.cargando = false;
        this.datosConfiguracionDocumentosService.noExistenNifs[this.obtenerIndexNifArray()] = true;
        this.nif = "";
      }
    )
  }

  async traeNif(id:string, pagina:number, orden:number): Promise<string>{
    return await this.datosDocumentosService.traeNif(id, pagina, orden).toPromise();
  }

  borrarInputContrario(tipo:string){
    if(tipo=='pagina'){
      this.posicionNif.setValue('pagina');
      this.nifFormulario.setValue(null);
    }
    if(tipo=='formulario'){
      this.posicionNif.setValue('formulario');
      this.nifPage.setValue(null);
      this.nifOrden.setValue(null);
      this.nif='';
    }
  }

  getClickCheckbox(){
    this.incluirFirmante?.valueChanges.subscribe(value => {
      if(value){
        this.posicionNif.setValue('pagina');
        this.nifOrden.setValue(0);
        this.nifPage.setValue(1);
        this.nifFormulario.setValue(null);
      }else {
        this.posicionNif.setValue(null);
        this.nifOrden.setValue(null);
        this.nifPage.setValue(null);
        this.nifFormulario.setValue(null);
      }
      this.addFirmanteNifInForm()
      this.incluirFirmanteEmitter.emit(value)
    })
  }
}
