import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-centros-barra-superior',
  templateUrl: './centros-barra-superior.component.html',
  styleUrls: ['./centros-barra-superior.component.css']
})
export class CentrosBarraSuperiorComponent implements OnInit {

  @Output() botonClicked: EventEmitter<string> = new EventEmitter<string>();
  // @Output() ver: EventEmitter<string> = new EventEmitter<string>();
  forumlario: UntypedFormGroup = new UntypedFormGroup({nuevasFirmas: new UntypedFormControl('')});
  buscar: boolean = false;
  textoBuscar: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  botonAccionClicked(id: string){
    this.botonClicked.emit(id);
    if(id == "Buscar") this.buscar = true;
  }

  buscarTexto(textoBuscar: string){
    this.textoBuscar = textoBuscar;
    if(textoBuscar == undefined) this.buscar = false;
    this.botonClicked.emit(`Buscar:${textoBuscar ? textoBuscar.trim() : ''}`);
  }

}
