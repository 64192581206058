import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Firmas } from 'src/app/pages/panelControl/models/firmas';
import { DetallesEstado } from '../../models/detalles-estado';

@Component({
  selector: 'app-detalle-firma-caducidad',
  templateUrl: './detalle-firma-caducidad.component.html',
  styleUrls: ['./detalle-firma-caducidad.component.css']
})
export class DetalleFirmaCaducidadComponent implements OnInit {

  @Input() firma?: Firmas;
  fechaCaducidad: string = '';
  firmanteCaducidad: boolean = false;
  @Input() mostrarDetalles: {count: number, abrir: boolean};
  @Output() switchCaducidadEmitter: EventEmitter<DetallesEstado> = new EventEmitter<DetallesEstado>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges){
    if(changes.mostrarDetalles){
      this.firmanteCaducidad = changes.mostrarDetalles.currentValue.abrir;
      this.switchCaducidadEmitter.emit({tipo: 'caducidad', estado: this.firmanteCaducidad});
    }

  }

  ngOnInit(): void {
    if (this.firma?.expireTime == 0) this.fechaCaducidad = "No caduca";
    else if(this.firma?.fechaCreacion)
    {
      let fechaCaducidad = new Date(this.firma?.fechaCreacion);
      fechaCaducidad.setDate(fechaCaducidad.getDate()  + this.firma?.expireTime);
      this.fechaCaducidad = fechaCaducidad?.toLocaleDateString("en-GB");
    }
  }

  mostrarCaducidad(){
    this.firmanteCaducidad = !this.firmanteCaducidad;
    this.switchCaducidadEmitter.emit({tipo: 'caducidad', estado: this.firmanteCaducidad});
  }
}
