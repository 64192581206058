import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TipoFirmante } from '../../../../envios/models/tipo-firmante';
import { FirmanteNif } from '../../../models/firmante-nif';

@Component({
  selector: 'app-campos-editables-firmante',
  templateUrl: './campos-editables-firmante.component.html',
  styleUrls: ['./campos-editables-firmante.component.css']
})
export class CamposEditablesFirmanteComponent implements OnInit {

  @Input() firmante: any;
  @Output() firmanteSeleccionado: EventEmitter<FirmanteNif> = new EventEmitter<FirmanteNif>();



  constructor() { }

  ngOnInit(): void {
  }

  //mandamos el estado al padre (configuracion-documento-formularios) para que gestione abrir/cerrar el firmante seleccionado.
  mostrarEstado(firmanteNuevo: FirmanteNif){
    this.firmanteSeleccionado.emit(firmanteNuevo);
  }



}
