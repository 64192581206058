import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Firmas } from 'src/app/pages/panelControl/models/firmas';
import { DetallesEstado } from '../../models/detalles-estado';

@Component({
  selector: 'app-detalle-firma-remitente',
  templateUrl: './detalle-firma-remitente.component.html',
  styleUrls: ['./detalle-firma-remitente.component.css']
})
export class DetalleFirmaRemitenteComponent implements OnInit {

  @Input() remitente: string = '';
  firmanteRemitente: boolean = false;
  @Input() mostrarDetalles: {count: number, abrir: boolean};
  @Output() switchRemitenteEmitter: EventEmitter<DetallesEstado> = new EventEmitter<DetallesEstado>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges){
    if(changes.mostrarDetalles){
      this.firmanteRemitente = changes.mostrarDetalles.currentValue.abrir;
      this.switchRemitenteEmitter.emit({tipo: 'remitente', estado: this.firmanteRemitente})
    }

  }

  ngOnInit(): void {
  }

  mostrarRemitente(){
    this.firmanteRemitente = !this.firmanteRemitente;
    this.switchRemitenteEmitter.emit({tipo: 'remitente', estado: this.firmanteRemitente})
  }

}
