<div class="barra-iconos-acciones">
  <!-- <div> -->
      <app-icono-superior [icono]="'icon-excel-empty'" [isGrey]="true" [id]="'Excel'" (clickIcon)="botonAccionClicked($event)"></app-icono-superior>
  <!-- </div>
  <div> -->
      <app-icono-superior [icono]="'icon-pdf-empty'" [isGrey]="true" [id]="'Pdf'" (clickIcon)="botonAccionClicked($event)"></app-icono-superior>
  <!-- </div> -->
  <!-- <div class="icons-separator">
      <app-icono-superior [icono]="'icon-plus'" [id]="'Plus'" (clickIcon)="botonAccionClicked($event)"></app-icono-superior>
  </div> -->
  <!-- <div>
      <app-icono-superior *ngIf="!buscar" [icono]="'icon-search'" [id]="'Buscar'" (clickIcon)="botonAccionClicked($event)">
      </app-icono-superior>
      <div *ngIf="buscar" style="font-size: 16px">
          <app-input-buscador (buscador)="buscarTexto($event)" [textoBuscar]="textoBuscar"></app-input-buscador>
      </div>
  </div> -->
</div>
