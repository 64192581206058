<h1>Configuración</h1>
<div class="container">
  <div>
    <h6>Dosieres y Documentos</h6>
    <div class="indicador-incompletos">
      <app-indicador-numero [numero]="numEnviosIncompletos" [color]="'var(--hs-red)'"></app-indicador-numero>
      <div>Dosieres incompletos</div>
      <div (click)="navegar('/panel/dosieres-configurados/incompletos')">Acceder</div>
    </div>
    <div class="content">
      <app-boton-acceso [icono]="'#hs-file'" [tituloBoton]="'Dosieres'" [texto]="''" [id]="''" (click)="navegar('/panel/dosieres-configurados')"></app-boton-acceso>
      <app-boton-acceso [icono]="'#hs-doc-sin-texto'" [tituloBoton]="'Documentos'" [texto]="''" [id]="''" (click)="navegar('/panel/documentos-configurados')"></app-boton-acceso>
    </div>
    <h6>Organización</h6>
    <div class="content">
      <app-boton-acceso [icono]="'#hs-empresa'" [tituloBoton]="'Empresas'" [texto]="''" [id]="''" (click)="navegar('/panel/configuracion/empresas')"></app-boton-acceso>
      <app-boton-acceso [icono]="'#hs-centro'" [tituloBoton]="'Centros'" [texto]="''" [id]="''" (click)="navegar('/panel/configuracion/centros')"></app-boton-acceso>
      <app-boton-acceso *ngIf="mostrarUsuarios" [icono]="'#hs-user'" [tituloBoton]="'Usuarios'" [texto]="''" [id]="''" (click)="navegar('/panel/usuarios')"></app-boton-acceso>
      <app-boton-acceso *ngIf="moduloRltActivo" class="rlt-button" [icono]="'#hs-rlt'" [tituloBoton]="'RLT'" [texto]="''" [id]="'rlt'" (click)="navegar('/panel/configuracion-rlt')"></app-boton-acceso>

    </div>
  </div>
  <div>
    <h6>Accesos a configuración</h6>
    <ul>
      <li (click)="navegar('panel/configuracion-dosier/0')">Nuevo dosier</li>
      <li (click)="navegar('panel/nueva-configuracion-documento')">Nuevo documento</li>
    </ul>
  </div>

</div>
