import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Filtros } from '../../../models/filtros';
import { DatosFirmaService } from '../../../../pages/panelControl/services/datos-firma.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationMessageComponent } from '../../modals/confirmation-message/confirmation-message.component';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';
import { FormularioDocumentoService } from 'src/app/pages/configuracion/Envios/documentos/services/formulario-documento.service';
import { filter } from 'rxjs/operators';
import { TipoDocumentoClass } from 'src/app/pages/configuracion/Envios/documentos/models/tipo-documento';
import { FormularioEnvioService } from 'src/app/pages/configuracion/Envios/envios/services/formulario-envio.service';
import { TipoEnvioClass } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.css']
})
export class MenuLateralComponent implements OnInit {

  menuVisible: boolean = true;

  @Output() mostrarMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  focusSeleccion: string = '';

  constructor(private router: Router,
              private datosFirmaService: DatosFirmaService,
              private dialog: MatDialog,
              private formularioDocumentoService: FormularioDocumentoService,
              private formularioEnvioService: FormularioEnvioService) {

              }

  ngOnInit() {
    this.checkFocusSeleccion(this.router.url);
    // this.focusSeleccion = this.router.url;
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.checkFocusSeleccion(event.url)
      // {
        // this.currentUrl = event.url;


      // }
      );

  }

  checkFocusSeleccion(currentUrl: string){
    let splitUrl = currentUrl.split('/');
    if(currentUrl == '/panel/dosieres-configurados'||
       currentUrl == '/panel/dosieres-configurados/incompletos' ||
       currentUrl == `/panel/configuracion-dosier/${splitUrl[splitUrl.length-1]}` ||
       currentUrl == '/panel/documentos-configurados' ||
       currentUrl == '/panel/nueva-configuracion-documento' ||
       currentUrl == '/panel/editar-configuracion-documento' ||
       currentUrl == '/panel/configuracion/empresas' ||
       currentUrl == '/panel/configuracion/centros' ||
       currentUrl == '/panel/usuarios' ||
       currentUrl == '/panel/configuracion-rlt' ||
       currentUrl == '/panel/configuracion-usuario') this.focusSeleccion = '/panel/opciones-configuracion';
    else if(currentUrl == '/panel/subida?envio=estandar' ||
            currentUrl == '/panel/subida?envio=universal') this.focusSeleccion = '/panel/subida';
    else this.focusSeleccion = currentUrl;
  }

  navegar(ruta: string){
    // if(ruta == '/panel/envios')
    // {
    //   if(this.datosFirmaService.firmasFiltradas.length == 0){
    //     let filtros: Filtros[] = [{
    //       text: '',
    //       value: '',
    //       estadoString: 'TODOS',
    //       tipofirma: '',
    //       fechaDesde: null,
    //       fechaHasta: null,
    //       todasLasFechas: true,
    //       nombreFirmate: '',
    //       tipoDosier: '',
    //       centro: '',
    //       empresa: '',
    //       textoBuscar: '',
    //       nuevas: false,
    //       isEntornoSandbox: false
    //     }];
    //     this.datosFirmaService.SetFilter(filtros);
    //     this.datosFirmaService.Filter();
    //   }
    // }

    this.router.navigate([ruta]);
  }

  avisaCerrarConfiguraciones(ruta:string){
    // this.focusSeleccion = ruta;
    if(this.router.url.includes('configuracion-documento')){
      if(!this.formularioDocumentoService.hayCambios()){
        this.formularioDocumentoService.resetFormularioDocumento();
        this.navegar(ruta);
        this.formularioDocumentoService.documentoAEditar = new TipoDocumentoClass();
        this.focusSeleccion = ruta;
      }else{
        const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
          data: {
            tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
            icono: 'icon-save2',
            titulo: '¿Deseas salir sin guardar?',
            mensaje:'Se perderán todos los cambios realizados en la configuración del documento.',
            textoConfirmacion: 'Salir sin guardar',
            iconoConfirmacion: 'icon-unsaved',
            textoCancelar: 'cerrar'
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result === 'OK'){
            this.formularioDocumentoService.resetFormularioDocumento();
            this.navegar(ruta);
            this.formularioDocumentoService.documentoAEditar = new TipoDocumentoClass();
            this.focusSeleccion = ruta;
          }
        });
      }
    }else if(this.router.url.includes('configuracion-dosier')){
      if(!this.formularioEnvioService.hayCambiosDosier()){
        this.navegar(ruta);
        this.formularioEnvioService.envioAEditar = new TipoEnvioClass();
        this.focusSeleccion = ruta;
      }else{
        const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
          data: {
            tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
            icono: 'icon-save2',
            titulo: '¿Deseas salir sin guardar?',
            mensaje:'Se perderán todos los cambios realizados en la configuración del dosier.',
            textoConfirmacion: 'Salir sin guardar',
            iconoConfirmacion: 'icon-unsaved',
            textoCancelar: 'cerrar'
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result === 'OK'){
            this.navegar(ruta);
            this.formularioEnvioService.envioAEditar = new TipoEnvioClass();
            this.focusSeleccion = ruta;
          }
        });
      }
    }else{
      this.navegar(ruta);
      this.focusSeleccion = ruta;
    }
  }

  desplegarMenu(){
    this.menuVisible = !this.menuVisible;
    this.mostrarMenu.emit(this.menuVisible);
  }

}
