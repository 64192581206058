import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../login/services/login.service';
import { DatosSubidaFicheros } from '../models/subida-ficheros';
import { IGestionarDatosSubidaService } from './ports/i-gestionar-datos-subida-service';

@Injectable({
  providedIn: 'root'
})
export class GestionaDatosSubidaApiService implements IGestionarDatosSubidaService{


  constructor(private client: HttpClient,
              private endpointService:EndpointService,
              private loginService: LoginService) { }

  getIdActiva(): Observable<any> {
     const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
     return this.client.get(this.endpointService.UrlSubidaGetIdActiva,options).pipe(
      tap((resp:any)=>{
        return resp
      })
    );
  }

  getEmpleados(file: File): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    const fromData:FormData = new FormData();
    fromData.append('file',file,file.name);

    return this.client.post(this.endpointService.UrlSubidaCargaExcelMasivo, fromData, options).pipe(
      tap((response: any) =>{
        return response
      })
    );
  }

  subeFichero(idTipoEnvio: string, files: DatosSubidaFicheros[]): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.post(this.endpointService.UrlSubidaSubirFichero, {idTipoEnvio, files}, options).pipe(
      map((response: any) =>{
        if (response.Ok) {
          return response.Data;
        }
        else throw new Error(response.Message);
      })
    );
  }

  getIdSubida(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.get(this.endpointService.UrlSubidaCreaSubida,options).pipe(
      tap((ret:any)=>{
        return ret
      }))

  }

  SubeFichero(data: FormData, codigoSubida: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };

    let url = this.endpointService.UrlSubidaAddFichero+`/${codigoSubida}`

    return this.client.post(url,data,options)
  }

  procesaFicheros(idSubida: number, param: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };

    return this.client.post(this.endpointService.UrlSubidaProcesaSubida, param, options).pipe(
      map((response: any) =>{
        if (response.Ok) return response.Data;
      })
    )
  }

  getLogSubida(idSubida: number): Observable<boolean> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.get(this.endpointService.UrlSubidaGetLog.replace("{id}", idSubida.toString()), options).pipe(
      map((response: any) => {
        return JSON.parse(response.Data);
      })
    );
  }

  cancelaSubida(idSubida: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.post(this.endpointService.UrlSubidaCancelaSubida.replace("{id}", idSubida.toString()), null, options).pipe(
      map((response: any)=>{
        return response.Ok
      })
    );;
  }

  cancelaSubidaInacitivad(codigoSubida: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.post(this.endpointService.UrlCancelaSubidaAuto.replace("{id}", codigoSubida), null, options).pipe(
      map((response: any)=>{
        return response.Ok
      })
    )
  }
}
