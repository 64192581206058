import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CentrosModel, CentrosSearchResponse } from 'src/app/pages/configuracion/maestros/centros/models/centros-model';
import { environment } from 'src/environments/environment';
import { IRecuperarCentrosService } from './ports/i-recuperar-centros-service';



@Injectable({
  providedIn: 'root'
})
export class RecuperaCentrosMockService implements IRecuperarCentrosService{

  constructor(private http: HttpClient) { }

  guardaCentro(data: any): Observable<void> {
    throw new Error('Method not implemented.');
  }

  getCentros(refresh?: boolean): Observable<CentrosModel[]> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')

      return this.http.get<CentrosSearchResponse>(`${environment.ApiCentrosMock}`, {headers})
        .pipe(
          map(resp => {
            let centros: CentrosModel[] = resp.datos;
              return centros;
            }
          )
      );
  }
  
  guardaCentros(data: CentrosModel[]): Observable<void> {
    return of(null);
  }

  deleteCentro(data: CentrosModel): Observable<void> {
    throw new Error('Method not implemented.');
  }
  
}
