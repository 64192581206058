<div class="layout-menu">
    <img [src]="'assets/images/logo-hire-sign-color.png'" alt="image" class="logo" (click)="goHome()">
    <button class="header-button ayuda" (click)="abrirFreshWorks()">
        <span class="header-button-text-ayuda" >Ayuda</span>
				<span class="header-button-icon">
					<svg class="icon-help">
					  <use xlink:href="#hs-help"></use>
					</svg>
				</span>
    </button>
    <button class="header-button" #toggleButton>
      <div class="flex-conf" #flexConf>
        <span class="header-button-icon" #icon>
          <svg class="icon-login" #iconSvg>
            <use xlink:href="#hs-user" #iconUse></use>
          </svg>
        </span>
        <div class="header-button-text" #nameHeader>
          <span class="child" style="font-size: 13px;" #name>{{getNameLogin()}}</span>
          <br>
          <span class="child" style="font-size: 10px;" #entorno>{{nombreEntorno}}</span>
        </div>
      </div>
      <app-usuario-activo-selector *ngIf="configuracion" (clickFuera)="cerrarMenu($event)"></app-usuario-activo-selector>
    </button>
</div>
