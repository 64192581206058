<ag-grid-angular
    style="height: 100%;"
    class="ag-theme-alpine"
    [rowData]="empresasData"
    [columnDefs]="columnDefs"
    (gridReady)="cargaApi($event)"
    [rowSelection]="'multiple'"
    (selectionChanged)="selectRows($event)"
    [gridOptions]="gridOptions"
    [rowMultiSelectWithClick]=true
    [overlayLoadingTemplate]="overlayLoadingTemplate"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate">
</ag-grid-angular>

