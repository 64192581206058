import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SqpGridComponentComponent } from './sqpGrid/sqp-grid-component/sqp-grid-component.component';
import { SharedModule } from '../../shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    SqpGridComponentComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    ReactiveFormsModule,
    MatDialogModule,
  ],
  exports: [
    SqpGridComponentComponent
  ]
})

export class SqpComponentsModule { }
