import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import { Workbook } from 'exceljs';
import { ColDef } from 'ag-grid-community';
import { GridColDefsData } from '../../pages/panelControl/models/grid-col-defs-data';
import { LoginService } from '../../pages/login/services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndpointService } from '../services/endpoint.service';

@Injectable({
  providedIn: 'root'
})

export class  DownloadFilesService {
  //Descarga masiva:variables
  public canelaDescarga:boolean=false;
    
  //Exprtar a excel:variables
  private workbook: any = {};
 // Variables para exportar excel

 private worksheet: any = {};
 columnDefs: ColDef[] =  new GridColDefsData().columDefs;
 columnasaExcluir:string[] =['esNueva','descargar']
 //metdos

  constructor( private client:HttpClient,
              private _snackBar: MatSnackBar,
              private endpointService:EndpointService,
               private loginService:LoginService) { }
  //#region Descargas
  // Genera la descarga masiva de los documentos que se muestren en el grid de firmas
  async GetAllDocs(ids: number[],nombre:string) {
     let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token,
      },

      ),

    };

    await this.client.post(this.endpointService.UrlFirmaDescargaMasiva,ids, httpOptions).pipe(
    map((response: any) => {
      if(response == null) {
        let error = "No se han encontrado ficheros disponibles para descargar"
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
        throw new Error(error);
      }
      // Recuperamos el fichero en formato base64 string y obtenemos el array de bytes
      const byteCharacters = atob(response.Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return byteArray;
    })).toPromise().then(
      ok=>{
        if(!this.canelaDescarga){

          const blob = new Blob([ok], { type: "application/zip" });
           let fecha = new Date();
           let fileName = nombre;
           fileSaver.saveAs(blob, fileName);
        }


    }).catch(err=>console.error(err));

  }
  getHeaders(){

  }

  exportaFormatoExcel(){
    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token,
      },

      ),

    };
    return this.client.get(this.endpointService.UrlSubidaGetCabeceraExcelConfigurada,httpOptions).pipe(
      tap((resp:any)=>{
        this.guardaExcelFormato(resp.data);

      })

    );
  }

  guardaExcelFormato(data:string[]){
    let columns:any[] = [];
    data.forEach(x=>{
      columns.push({
        header:x,
        key:x,
        width:x.length*1.5
      }
    )});

    this.workbook = new Workbook();
    this.worksheet = this.workbook.addWorksheet('Empleados');
    this.worksheet.columns=columns;

    this.workbook.xlsx.writeBuffer().then((data:any) =>
    {
      let fecha = new Date();
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fileSaver.saveAs(blob, `Hire&Sign_envios_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getMinutes()}.xlsx`);
    });
  }
  descargaManuales() {
    return this.client.get(this.endpointService.UrlManuales, {}).pipe(
      map((response: any) => {
        // Recuperamos el fichero en formato base64 string y obtenemos el array de bytes
        const byteCharacters = atob(response.fileContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return {fileBlob: byteArray, fileName: response.fileName};
      })).subscribe(
        ok => {
          const fileType = ok.fileName.toString().endsWith('.zip') ? 'application/zip' : 'application/pdf';
          const blob = new Blob([ok.fileBlob], { type: fileType });
          fileSaver.saveAs(blob, ok.fileName);
        },
        error =>{
          console.error(error);
          this._snackBar.open('Se ha producido un error '+error.message, 'Cerrar', {duration: 3000});
        }
      );
  }
  //#endregion
}


