import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { TipoEnvio } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';
import { GridColDefsSeguridad } from '../../models/grid-col-defs-seguridad';

@Component({
  selector: 'app-grid-seguridad-tipos-envio',
  templateUrl: './grid-seguridad-tipos-envio.component.html',
  styleUrls: ['./grid-seguridad-tipos-envio.component.css']
})
export class GridSeguridadTiposEnvioComponent implements OnInit {

  @Input() enviosData: TipoEnvio[] = [];

  api!: GridApi;
  apiCol: any;
  columnDefs: ColDef[] = new GridColDefsSeguridad().columnDefs;
  gridOptions: GridOptions = {
    headerHeight: 0,
    onFirstDataRendered: (params) => {
      // let nodesToSelect: RowNode[] = [];
      // let idTiposEnvio = this.filtroSeguridadFirmas.split(';');
      this.dataSeguridad.enviosSeleccionados=this.dataSeguridad.enviosSeleccionados.map(x=>x.id);
      params.api.forEachNode((node:any) => {
        if (this.dataSeguridad.enviosSeleccionados.includes(node.data.idTipoEnvio.toString())) node.setSelected(true);
      });
      // params.api.forEachNode((node:any) => {
      //   if (idTiposEnvio.includes(node.data.idTipoEnvio.toString())) nodesToSelect.push(node);
      // });
      // nodesToSelect.forEach(x => (this.api as any).selectNode(x, true));
    }
  };
  @Input() filtroSeguridadFirmas: string = '';
  @Input() dataSeguridad: any;
  @Output() seleccionEnvios: EventEmitter<any> = new EventEmitter<any>();
  overlayNoRowsTemplate: string ='<span>No se han encontrado tipos de dosier</span>';
  overlayLoadingTemplate: string ='<span class="ag-overlay-loading-center">Cargando...</span>';

  constructor() { }

  ngOnInit(): void {
  }

  cargaApi(arg: GridReadyEvent){
    this.api = arg.api;
    this.apiCol = arg.columnApi;
    this.api.sizeColumnsToFit();
  }


  selectRows(event: any){
   let enviosSeleccionados = this.api.getSelectedRows();
   this.seleccionEnvios.emit(enviosSeleccionados);
  }



}
