import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BotonAccionData } from 'src/app/shared/models/boton-accion-data';
import { Firmas } from 'src/app/pages/panelControl/models/firmas';
import { ResumenFirmas } from 'src/app/pages/panelControl/models/resumen-firmas';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationMessageComponent } from 'src/app/shared/components/modals/confirmation-message/confirmation-message.component';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';
import { PanelEliminarFirmasComponent } from '../panel-eliminar-firmas/panel-eliminar-firmas.component';
import { FiltroFirma, Filtros, getFiltroInicial } from 'src/app/shared/models/filtros';
import { FiltroSelectorComponent } from '../filtro-selector/filtro-selector.component';
import { DescargaMasivaModalComponent } from '../descarga-masiva-modal/descarga-masiva-modal.component';
import { BarraSuperiorComponent } from '../barra-superior/barra-superior.component';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { GridColDefsData } from 'src/app/pages/panelControl/models/grid-col-defs-data';
import { ColDef } from 'ag-grid-community';
import { ExportadorPdfService } from 'src/app/shared/ExportadorFicheros/services/exportador-pdf.service';
import { ExportadorExcelService } from 'src/app/shared/ExportadorFicheros/services/exportador-excel.service';
import { DatosExcel } from 'src/app/shared/ExportadorFicheros/models/datos-excel';
import { DatosPdf } from 'src/app/shared/ExportadorFicheros/models/datos-pdf';
import { TipoDocumento } from 'src/app/pages/configuracion/Envios/documentos/models/tipo-documento';
import { Documento } from '../../models/documento';
import { SpinnerModalComponent } from 'src/app/shared/components/others/spinner-modal/spinner-modal.component';
import { DesplegableData } from 'src/app/shared/models/desplegable-data';
import { EstadosFirmaService } from '../../services/estados-firma.service';
import { DetalleFirmaComponent } from '../detalle-firma/detalle-firma.component';

@Component({
  selector: 'app-panel-de-control',
  templateUrl: './panel-de-control.component.html',
  styleUrls: ['./panel-de-control.component.css']
})
export class PanelDeControlComponent implements OnInit {

  botonesRight: BotonAccionData[] = [{id: 'nuevo_envio', titulo: 'nuevo envío', icono: 'icon-send', redirect: '/panel/subida'}];
  firmasResumen: ResumenFirmas[] = [];
  firmasResumenInicial: ResumenFirmas[] = [];
  firmas: any;// Variable definida como any para que inicialmente se muestre el label de "Cargando" del grid. Posteriormente se cargará la lista de firmas
  columnDefs: ColDef[] =  new GridColDefsData().columDefs;
  columnasaExcluir:string[] =['esNueva','descargar'];
  columnasExclusivasExports: string[] = ['Documentos subidos', 'Documentos pendientes'];
  refrescando = false;
  filtros: Filtros[] = [];
  panelOculto:boolean=false;
  verNuevasParam:boolean=false;
  verEnviosEntornoSandbox:boolean=false;
  @ViewChild(BarraSuperiorComponent) barrSuperior!: BarraSuperiorComponent;
  textoBuscar: string = '';

  tiposDosier: DesplegableData[];
  empresas: DesplegableData[];
  centros: DesplegableData[];

  verEstado: string='';
  limpiarEstados: {num:number, clear: boolean};
  filtrosNuevos: FiltroFirma[]=[];
  gridOptions:any ={
    getRowStyle: (params: any) => {
      if(params.data.esNueva) return { fontWeight: "bold", backgroundColor: "var(--hs-yellow3)" };
      else return {};
    }
  }

  constructor(public datosFirmaService: DatosFirmaService,
               private router: Router, private route: ActivatedRoute,
               public dialog: MatDialog,
               private exportadorPdfService: ExportadorPdfService,
               private exportadorExcelService: ExportadorExcelService,
               public estadosFirmaService: EstadosFirmaService) {
    const param = this.route.snapshot.paramMap.get('nuevos');
    if(param=='nuevos') this.verNuevasParam=true;
    this.getFirmas().then((ok)=>{
      this.tiposDosier  = this.datosFirmaService.GetTipoDosierLista();
      this.empresas  = this.datosFirmaService.GetEmpresaLista();
      this.centros  = this.datosFirmaService.GetCentrosLista();
    });
  }

  ngOnInit(): void {
  }

  async getFirmas(){
    //const dialogRef : any = this.refrescando ? this.dialog.open(MatSpinner, { disableClose: true }) : '';
    this.firmasResumen = await this.datosFirmaService.GetResumen(this.refrescando);
    this.firmas = (this.datosFirmaService.firmasFiltradas.length > 0) ? this.datosFirmaService.firmasFiltradas : this.datosFirmaService.firmas;
    //if(this.refrescando)  dialogRef.close();
  }

  // Filtro panel lateral, ahora se hace a través del servicio
  // clickEstadoEnvio(estadoFirma: string){
  //   this.verEstado = estadoFirma;
  // }

//#region Acciones barra superior

  clickBarraSuperior(id: string){
    switch (id) {
      case "Descargar":
        this.descargarFirmasGrid();
        break;
      case 'Excel':
        this.exportaExcel();
        break;
      case "Eliminar":
        this.eliminarFirmas();
        break;
      case "Pdf":
        this.exportaPdf();
        break;
      default:
        break;
    }
  }

  eliminarFirmas(){
    if(this.firmas)
    {
      let firmasError = this.firmas.filter((x:Firmas) => x.estadoString == "Error");
      const dialogRef = this.dialog.open(PanelEliminarFirmasComponent, {
        data: firmasError,
        position: { right: '0'},
        panelClass: 'modal-class'
      });
      dialogRef.afterClosed().subscribe(result => {
        if(typeof result == 'number'){
          this.refresh();
        }
      });
    }
  }

  descargarFirmasGrid(){
    if(this.firmas){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'hs-download',
          titulo: 'Descargar documentos',
          mensaje: '<p>Descarga los certificados de firma y los documentos firmados de los registros mostrados en el listado</p>',
          textoConfirmacion: 'Descargar',
          iconoConfirmacion: 'hs-download',
          textoCancelar: 'cancelar'
        },
        autoFocus: 'dialog'
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK')
        {

          let firmasDescargar = this.datosFirmaService.firmasFiltradas.length > 0 ? this.datosFirmaService.firmasFiltradas : this.firmas;
          let idsFirmas = firmasDescargar.filter((x: Firmas) => x.estadoString === "Firmado" || x.estadoString === "Parcialmente firmado").map((x: Firmas) => x.idFirma);
          this.dialog.open(DescargaMasivaModalComponent,{
            data:{
              ids:idsFirmas
            }
          });
          //this.datosFirmaService.GetAllDocs(idsFirmas);
        }
      });
    }
  }

  exportaExcel(){
    if(this.firmas){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-excel-empty',
          titulo: 'Generar excel',
          mensaje: '<p>Genera un documento Excel con la información de los registros mostrados en el listado</p>',
          textoConfirmacion: 'Generar excel',
          iconoConfirmacion: 'icon-excel-empty',
          textoCancelar: 'cancelar'
        },
        autoFocus: 'dialog'
      });

      let datosExcel = this.montarParametrosExcel();

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK')
        {
          // Filtramos firmas a exportar
          let firmasExportar = this.datosFirmaService.firmasFiltradas.length > 0 ? this.datosFirmaService.firmasFiltradas : this.firmas;
          // Cargamos los datos de los documentos de las firmas y exportamos el excel
          const dialogRefExport = this.dialog.open(SpinnerModalComponent);
          let abort :AbortController = new AbortController();
          this.datosFirmaService.GetDatosExtraFirmas(abort.signal).then(() => {
            let rows = this.GetRowsExportExcel(firmasExportar, datosExcel.columnas);
            this.exportadorExcelService.exportaExcel(datosExcel, rows);
            dialogRefExport.close();
          }).catch((error:any) => {
            console.error("Error exportando a excel", error);
            dialogRefExport.close();
          });
        }
      });
    }
  }

  montarParametrosExcel(): DatosExcel{
    let fecha = new Date();
    let datosExcel = {
      workSheetName:'Firmas',
      nombreFichero:`Hire&Sign_envios_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.xlsx`,
      columnas: this.GetColumnsExportExcel()
    }
    return datosExcel
  }

  //Generamos las columnas basandonos en las del grid
  private GetColumnsExportExcel(): any[]{
    let columns:any[] = [];
    this.columnDefs.forEach((x, i)=>{
      //Solo generamos las columnas con nombre (internas no)
      if(!x.hide) {
        if(!this.columnasaExcluir.includes(x.field??'')){
          let element:any = {};
          element.name = x.headerName;
          element.filterButton = true;
          element.width = x.width;
          columns.push(element);
        }
      }
    });
    // this.columnasExclusivasExports.forEach(x => {
    //   let element:any = {};
    //   element.name = x;
    //   element.filterButton = true;
    //   element.width = 100;
    //   columns.push(element);
    // });
    return columns;
  }

  private GetRowsExportExcel(data: Firmas[], columnas: any[]):any[]{
    let rows:any[] = [];
    data.forEach(x=>{
      let fechaEnvio = x.fechaFirma ?? x.fechaEnvio ?? x.fechaUltimaActualizacion;
      // let estadoRlt = x.estadoRlt ? 'Enviado':'Pendiente';
      let estadoRlt;
      if(x.estadoRlt == null) estadoRlt = '';
      else if(x.estadoRlt) estadoRlt='Enviado';
      else if(!x.estadoRlt) estadoRlt='Pendiente';
      let row:any[] = [];
      columnas.forEach((y: any) => {
        switch (y.name) {
          case 'Estado': row.push(x.estadoString); break;
          case 'Firmante principal': row.push(x.empleado); break;
          case 'Fecha creación': row.push(x.fechaCreacion); break;
          case 'Fecha envío/firma': row.push(fechaEnvio); break;
          case 'Tipo de dosier': row.push(x.tipoEnvio); break;
          case 'Centro': row.push(`${x.codigoCentro} - ${x.nombreCentro}`); break;
          case 'Empresa': row.push(`${x.codigoEmpresa} - ${x.nombreEmpresa}`); break;
          case 'Docs': row.push(x.numeroDocumentos); break;
          case 'RLT': row.push(estadoRlt); break;
          case 'Fecha contrato': row.push(x.fechaContrato); break;
          default: row.push(''); break;
        }
      });
      rows.push(row);
    });
    return rows
  }

  exportaPdf(){
    if(this.firmas){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-pdf-empty',
          titulo: 'Generar PDF',
          mensaje: '<p>Genera un documento PDF con la información de los registros mostrados en el listado</p>',
          textoConfirmacion: 'Generar PDF',
          iconoConfirmacion: 'icon-pdf-empty',
          textoCancelar: 'cancelar'
        },
        autoFocus: 'dialog'
      });

      //montamos el objeto a pasar al servicio con el modelo DatosPdf
      const datos = this.montarParametrosPdf()

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK')
        {
          // Filtramos firmas a exportar
          let firmasExportar = this.datosFirmaService.firmasFiltradas.length > 0 ? this.datosFirmaService.firmasFiltradas : this.firmas;
          // Cargamos los datos de los documentos de las firmas y exportamos el excel
          const dialogRefExport = this.dialog.open(SpinnerModalComponent);
          let abort :AbortController = new AbortController();
          this.datosFirmaService.GetDatosExtraFirmas(abort.signal).then(() => {
          //escogemos la fecha a mostrar y la convertimos a formato correcto
          firmasExportar.forEach((x: any) => {
            let fecha =  x.fechaCreacion ?? x.fechaUltimaActualizacion;
            x._fechaCreacion = new Date(fecha).toLocaleDateString("en-GB");
            fecha = x.fechaFirma ?? x.fechaEnvio ?? x.fechaUltimaActualizacion;
            x._fechaFirma = new Date(fecha).toLocaleDateString("en-GB");
            if(x.estadoRlt ==null) x.estadoRlt = '';
            else if(x.estadoRlt) x.estadoRlt='Enviado';
            else if(!x.estadoRlt) x.estadoRlt='Pendiente';
            // x.estadoRlt = x.estadoRlt ? 'Enviado':'Pendiente';
            x.nombreCentro = `${x.codigoCentro} - ${x.nombreCentro}`;
            x.nombreEmpresa = `${x.codigoEmpresa} - ${x.nombreEmpresa}`;
            x._fechaContrato = x.fechaContrato!=null ? new Date(x.fechaContrato).toLocaleDateString("en-GB") : '';
          });

          this.exportadorPdfService.exportaPdf(datos, firmasExportar);
          dialogRefExport.close();
          }).catch((error:any) => {
            console.error("Error exportando a excel", error);
            dialogRefExport.close();
          });
        }
      });
    }
  }

  montarParametrosPdf(): DatosPdf{
    let fecha = new Date();
    let columns: any[] = [];
    new GridColDefsData().columDefs.forEach(x => {
      if(!x.hide) {
        if(x.field?.toLocaleLowerCase().includes("fecha")) columns.push({header: x.headerName, dataKey: `_${x.field}`});
        else if(!this.columnasaExcluir.includes(x.field ?? '')) columns.push({header: x.headerName, dataKey: x.field});

      }
    });
    // columns.push({header: 'Documentos subidos', dataKey: 'documentosSubidosString'})
    // columns.push({header: 'Documentos pendientes', dataKey: 'documentosPendientesString'})
    //montamos el objeto a pasar al servicio
    let datosPdf = {
      landscape: true,
      nombreFichero:`Hire&Sign_envios_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.pdf`,
      registros: columns
    }
    return datosPdf
  }

  filtrarFirmasNuevoFiltro(event: boolean){
    if(this.firmas && event){
      // this.filtros = this.datosFirmaService.filtros;
      this.firmas = this.datosFirmaService.firmasFiltradas;
      this.datosFirmaService.GetResumen(false).then(x => { this.firmasResumen = x});
    }
  }

  buscarFirmas(textoBuscar: string){
    if(this.firmas){
      this.textoBuscar = textoBuscar;
    }
  }

  ocultar(event:boolean){
    this.panelOculto=event;
  }

  verNuevos(nuevas: boolean){
    this.verNuevasParam = nuevas;
  }

  selectVerEnviosEntornoSandbox(isEntornoSandbox: boolean){
    this.verEnviosEntornoSandbox = isEntornoSandbox;
  }

  refresh(){

    // this.datosFirmaService.firmasFiltradas = [];
    this.datosFirmaService.refreshFirmas = true;
    this.datosFirmaService.GetFirmas().subscribe(ok=>{
      this.getFirmas().then(resp=>{
        // this.firmasResumen = this.datosFirmaService.Filter();
        this.firmasResumen = this.datosFirmaService.filtrarNuevo(this.datosFirmaService.filtrosConEstado)
        this.firmas = this.datosFirmaService.firmasFiltradas;
        this.datosFirmaService.refreshFirmas = false;
      });
    })
  }

  irASubida(){
    this.router.navigate(['/panel/subida']);
  }

  switchOffNuevas(event: boolean){
    if(event) this.verNuevasParam = false;
  }
  switchOffSandbox(event: boolean){
    if(event) this.verEnviosEntornoSandbox = false;
  }

  limpiarTodosEstados(event:{num:number, clear: boolean}){
    this.limpiarEstados = event;
  }
  dialogRefSpinnerAbrirModal:any;

  selectRowChanged(event: any){

    //SI ES UN ELMENTO QUE SE PUEDE CLICKAR NO HACEMOS NADA
    if(event.event.target.classList.contains('clickable')) return;

  	//  let firma:Firmas = this.api.getSelectedNodes().map((x: any) => x.data)[0];
    let firma: Firmas = event.data;

    if(!firma.cargaCompleatada) {
      const i = this.datosFirmaService.firmas.findIndex(x=>x.idFirma == firma.idFirma);
      this.dialogRefSpinnerAbrirModal = this.dialog.open(SpinnerModalComponent);
      this.datosFirmaService.GetDatosExtraFirmaCall(i).then(resp=>{
        this.abreModal(this.datosFirmaService.firmas[i]);
      })
    }else{
      this.abreModal(firma);
    }

  }
  private abreModal(firma:Firmas){
    this.dialogRefSpinnerAbrirModal.close();
    const dialogRef = this.dialog.open(DetalleFirmaComponent, {
      data: firma,
      panelClass: 'modal-class-detalle',
      position: { right: '0'}
    });
    dialogRef.afterClosed().subscribe(result=>{
      if(result && result.refresh){
        this.refresh();
      }
    })
  }
}
