import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TooltipMessages } from 'src/app/shared/models/tooltip.mesages';

@Component({
  selector: 'app-barra-superior',
  templateUrl: './barra-superior.component.html',
  styleUrls: ['./barra-superior.component.css']
})
export class BarraSuperiorComponent implements OnInit {


  @Output() botonClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() ver: EventEmitter<string> = new EventEmitter<string>();
  forumlario: UntypedFormGroup = new UntypedFormGroup({ nuevasFirmas: new UntypedFormControl('') });
  buscar: boolean = false;
  textoBuscar: string = "";
  @Input() backgroundActive: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  botonAccionClicked(id: string) {
    this.botonClicked.emit(id);
    if (id == "Buscar") this.buscar = true;
  }

  buscarTexto(_textoBuscar: string) {
    this.textoBuscar = _textoBuscar;
    if (this.textoBuscar == undefined) this.buscar = false;
    this.botonClicked.emit(`Buscar:${this.textoBuscar ? this.textoBuscar.trim() : ''}`);
  }

  getTooltip(accion: string){
    if (accion.toUpperCase() == "DESCARGAR") return TooltipMessages.superior_descarga;
    else if (accion.toUpperCase() == "PDF") return TooltipMessages.superior_pdf;
    else if (accion.toUpperCase() == "EXCEL") return TooltipMessages.superior_excel;
    else if (accion.toUpperCase() == "ELIMINAR") return TooltipMessages.superior_eliminar;
    else if (accion.toUpperCase() == "PLUS") return TooltipMessages.superior_envionuevo;
    else if (accion.toUpperCase() == "FILTRO") return TooltipMessages.superior_filtro;
    else if (accion.toUpperCase() == "BUSCAR") return TooltipMessages.superior_busqueda;
    else return '';
  }
}
