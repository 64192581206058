import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef } from 'ag-grid-community';
import { ConfirmationMessageComponent } from 'src/app/shared/components/modals/confirmation-message/confirmation-message.component';
import { SelectorComponent } from 'src/app/shared/components/selectores/selector/selector.component';
import { DatosExcel } from 'src/app/shared/ExportadorFicheros/models/datos-excel';
import { DatosPdf } from 'src/app/shared/ExportadorFicheros/models/datos-pdf';
import { ExportadorExcelService } from 'src/app/shared/ExportadorFicheros/services/exportador-excel.service';
import { ExportadorPdfService } from 'src/app/shared/ExportadorFicheros/services/exportador-pdf.service';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';
import { FiltroDocs } from 'src/app/shared/models/filtros';
import { GridColDefsDocs } from 'src/app/pages/configuracion/Envios/documentos/models/grid-col-defs-docs';
import { EstadosTipoDocumento, TipoDocumento } from 'src/app/pages/configuracion/Envios/documentos/models/tipo-documento';
import { DatosDocumentosService } from '../../services/datos-documentos.service';
import { DocumentosBarraSuperiorComponent } from '../documentos-barra-superior/documentos-barra-superior.component';
import { DocumentosFiltroSelectorComponent } from '../documentos-filtro-selector/documentos-filtro-selector.component';
import { Router } from '@angular/router';
import { DatosConfiguracionDocumentosService } from '../../services/datos-configuracion-documentos.service';

@Component({
  selector: 'app-documentos-page',
  templateUrl: './documentos-page.component.html',
  styleUrls: ['./documentos-page.component.css']
})
export class DocumentosPageComponent implements OnInit {

  tipoDocs: TipoDocumento[];
  textoBuscar: string = '';
  filtros: FiltroDocs[] = [];
  @ViewChild(DocumentosBarraSuperiorComponent) barrSuperior!: DocumentosBarraSuperiorComponent;
  verIncompletos: boolean = false;
  @ViewChild(SelectorComponent) selector!: SelectorComponent;
  columnDefs: ColDef[] = new GridColDefsDocs().columnDefs;

  constructor(public datosDocumentosService: DatosDocumentosService,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog,
              private exportadorPdfService: ExportadorPdfService,
              private exportadorExcelService: ExportadorExcelService,
              private router: Router,
              private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService) { }

  ngOnInit() {
    this.getDocumentosData();

    // this.datosDocumentosService.SetFilter(this.datosDocumentosService.filtros);
    // this.datosDocumentosService.Filter();
    // if(this.datosDocumentosService.filtros.length>0){
    //   this.datosDocumentosService.filtros.forEach(x=>{
    //     if(x.text=='Texto'){
    //       this.textoBuscar=x.textoBuscar;
    //       this.tipoDocs=this.datosDocumentosService.docsFiltrados;
    //     }
    //   })
    // }
  }

  ngOnDestroy() :void {
    // this.filtros.forEach(x => this.quitarFiltro(x));
  }

  getDocumentosData(){
    this.datosDocumentosService.getTipoDocumentos().subscribe(
      next => {
        this.tipoDocs = next;

        this.datosDocumentosService.SetFilter(this.datosDocumentosService.filtros);
        this.datosDocumentosService.Filter();
        if(this.datosDocumentosService.filtros.length>0){
          this.datosDocumentosService.filtros.forEach(x=>{
            if(x.text=='Texto'){
              this.textoBuscar=x.textoBuscar;
              this.tipoDocs=this.datosDocumentosService.docsFiltrados;
            }
          })
        }
        // this.filtros.push({ text: '',
        //                     textoBuscar: '',
        //                     value: '',
        //                     // estado: '',
        //                     idTipoDocumento: 0,
        //                     nombre:'',
        //                     tipoDoc:''
        //                   })
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  refresh(){
    // this.tipoDocs = [...this.datosDocumentosService.envios]
    this.getDocumentosData()
  }

  //#region Acciones barra superior

  clickBarraSuperior(id: string){
    switch (id) {
      case 'Excel':
        this.exportaExcel();
        break;
      case "Pdf":
        this.exportaPdf();
        break;
      // case "Plus":
      //   this.configuraNuevoDocumento();
      //   break;
      // case "Filtrar":
      //   this.filtrarEnvios();
      //   break;
      // case id.match(/(Buscar:.*)/)?.input:
      //   let textoBuscar = id.replace('Buscar:','');
      //   this.buscarDocumentos(textoBuscar);
      //   break;
      default:
        break;
    }
  }

  configuraNuevoDocumento(){
    this.router.navigate(['/panel/nueva-configuracion-documento']);
  }

  exportaPdf(){
    if(this.tipoDocs){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-pdf-empty',
          titulo: 'Generar PDF',
          mensaje: '<p>Genera un documento PDF con la información de los registros mostrados en el listado</p>',
          textoConfirmacion: 'Generar PDF',
          iconoConfirmacion: 'icon-pdf-empty',
          textoCancelar: 'cancelar'
        }
      });

      //montamos el objeto a pasar al servicio con el modelo DatosPdf
      const datos = this.montarParametrosPdf();
      dialogRef.afterClosed().subscribe(result => {
        this.tipoDocs.map((x:any)=>{ //miramos si firmantesNif viene como array o está modificado a número porqué esta no es la primera vez en descargarse
          if(Array.isArray(x.firmantesNif)) x.firmantesNif=x.firmantesNif.length;
          else if (typeof x.firmantesNif == 'number') x.firmantesNif = x.firmantesNif;
          else x.firmantesNif = 0;
        })
        if(result === 'OK') this.exportadorPdfService.exportaPdf(datos, this.tipoDocs)
      });
    }
  }

  montarParametrosPdf(): DatosPdf{
    let columns: any [] = [];
    this.columnDefs.forEach( x => {
      columns.push( {header: x.headerName, dataKey: x.field} )
    })
    let fecha = new Date();
    let datosPdf = {
      landscape: true,
      nombreFichero:`Hire&Sign_documentos_configurados_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.pdf`,
      registros: columns
    }
    return datosPdf
  }

  exportaExcel(){
    if(this.tipoDocs){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-excel-empty',
          titulo: 'Generar excel',
          mensaje: '<p>Genera un documento Excel con la información de los registros mostrados en el listado</p>',
          textoConfirmacion: 'Generar excel',
          iconoConfirmacion: 'icon-excel-empty',
          textoCancelar: 'cancelar'
        }
      });

      let rows = this.GetRows(this.tipoDocs);
      let datosExcel = this.montarParametrosExcel();

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK') this.exportadorExcelService.exportaExcel(datosExcel, rows);
      });
    }
  }

  montarParametrosExcel(): DatosExcel{
    let fecha = new Date();
    let datosExcel = {
      workSheetName:'Documentos',
      nombreFichero:`Hire&Sign_documentos_configurados_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.xlsx`,
      columnas: this.GetColumns()
    }
    return datosExcel
  }

  private GetColumns(): any[]{
    let columns:any[] = [];
    this.columnDefs.forEach( x => {
        let element:any = {};
        element.name = x.headerName;
        element.filterButton = true;
        element.width = x.width;
        columns.push(element);
    });
    return columns;
  }

  private GetRows(data: TipoDocumento[]): any{
    let rows:any[] = [];
    data.forEach((x:any)=>{//miramos si firmantesNif viene como array o está modificado a número porqué esta no es la primera vez en descargarse
      if(Array.isArray(x.firmantesNif)) x.firmantesNif=x.firmantesNif.length;
      else if (typeof x.firmantesNif == 'number') x.firmantesNif = x.firmantesNif;
      else x.firmantesNif = 0;
      let row:any[] =
      [
        x.nombre,
        x.paginas,
        x.firmantesNif,
        x.tipoDoc,
        // x.estado
      ];

      rows.push(row);
    });
    return rows
  }

  buscarDocumentos(textoBuscar: string){
    if(this.tipoDocs){
      let _filtroBusqueda: FiltroDocs = this.datosDocumentosService.filtros.find(x => x.text == 'Texto');
      if(_filtroBusqueda) {
        _filtroBusqueda.textoBuscar = textoBuscar;
        _filtroBusqueda.value = textoBuscar;
      } else {
        _filtroBusqueda = {
          textoBuscar: textoBuscar,
          value: textoBuscar,
          text: 'Texto',
          idTipoDocumento: 0,
          estado: '',
          nombre: '',
          tipoDoc: ''
        };
        this.datosDocumentosService.filtros.push(_filtroBusqueda)
        // this.filtros.push(_filtroBusqueda);
      }
      this.datosDocumentosService.SetFilter(this.datosDocumentosService.filtros);
      this.datosDocumentosService.Filter();
      this.tipoDocs = this.datosDocumentosService.docsFiltrados;
    }
  }

  quitarFiltro(filtro: FiltroDocs){
    this.filtros = this.filtros.filter(x => x != filtro);
    this.datosDocumentosService.SetFilter(this.filtros);
    this.datosDocumentosService.Filter();
    this.tipoDocs = this.datosDocumentosService.docsFiltrados;
    this.verIncompletos = false;
    if(filtro.text == 'Texto') {
      this.barrSuperior.buscarTexto('');
    }
    // if(this.filtros.length>0 && this.filtros[0].estado == EstadosTipoDocumento.incompleto){
    //   this.selector.click();
    // }
  }

  // filtrarEnvios(){
  //   if(this.tipoDocs){
  //     const dialogRef = this.dialog.open(DocumentosFiltroSelectorComponent,{
  //       panelClass: 'modal-class-center'} );
  //     dialogRef.afterClosed().subscribe(result => {
  //         if(result == 'Filtrar'){
  //           this.tipoDocs = this.datosDocumentosService.docsFiltrados;
  //           this.filtros = this.datosDocumentosService.filtros;
  //         }
  //       });
  //     }
  // }

  filtrarDocumentos(event:string){
    if(event=='Filtrar') this.tipoDocs = this.datosDocumentosService.docsFiltrados;
  }

  seleccionFiltroChange(verEstado: boolean){
    this.verIncompletos = !this.verIncompletos
    this.filtros[0]= {
      text: verEstado ? 'Mostrando documentos' : '',
      textoBuscar: '',
      value: verEstado ? 'Incompleto' : '',
      estado: verEstado ? 'Incompleto' : 'TODOS',
      idTipoDocumento: 0,
      nombre:'',
      tipoDoc:''
    };
    this.datosDocumentosService.SetFilter(this.filtros);
    this.datosDocumentosService.Filter();
    this.tipoDocs = this.datosDocumentosService.docsFiltrados;
  }

  abrirDetallesDocumento(event:any){
    this.datosConfiguracionDocumentosService.transferirDocId(event.data);
    this.router.navigate([`/panel/editar-configuracion-documento`])
  }
}

