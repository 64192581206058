import { InfoApi } from "../ModelosApis/InfoApi";

export const EndpointConfigV3: InfoApi[] = [
  new InfoApi({
    nombre: "ApiLogin",
    version: 'V3',
    endpointV2: 'api/v2/Auth/Login',
    endpointV3: 'api/Authentication/Login'


  }),
  new InfoApi({
    nombre: 'ApiRefreshToken',
    version: 'V2',
    endpointV3: 'api/Authentication/Login',
    endpointV2: 'api/v2/Auth/RefreshToken'

  }),
  new InfoApi({
    nombre: 'ApiLogOut',
    version: 'V2',
    endpointV3: 'api/Authentication/Login',
    endpointV2: 'api/v2/Auth/LogOut'

  }),
  new InfoApi({
    nombre: 'ApiGetFirmas',
    version: 'V2',
    endpointV2: 'api/v2/Signature/GetFirmas/{todos}',
    endpointV3: 'api/v2/Signature/GetFirmas/{todos}'
  }),
  new InfoApi({
    nombre: 'ApiEliminaFirmas',
    version: 'V2',
    endpointV2: 'api/v2/Signature/EliminaFirmas',
    endpointV3: 'api/v2/Signature/EliminaFirmas'
  }),
  new InfoApi({
    nombre: 'ApiDescargarFirmas',
    version: 'V2',
    endpointV2: 'api/v2/Signature/DownloadSignature/{id}',
    endpointV3: 'api/v2/Signature/DownloadSignature/{id}'
  }),
  new InfoApi({
    nombre: 'ApiFirmaGetDocumentos',
    version: 'V2',
    endpointV2: 'api/v2/Signature/GetDocumentos',
    endpointV3: 'api/v2/Signature/GetDocumentos'
  }),
  new InfoApi({
    nombre: 'ApiFirmaGetEventos',
    version: 'V2',
    endpointV2: 'api/v2/Signature/GetEventos',
    endpointV3: 'api/v2/Signature/GetEventos'
  }),
  new InfoApi({
    nombre: 'ApiFirmaGetTiposDocumentos',
    version: 'V2',
    endpointV2: 'api/v2/Signature/GetTiposDocumentoFirma',
    endpointV3: 'api/v2/Signature/GetTiposDocumentoFirma'
  }),
  new InfoApi({
    nombre: 'ApiFirmaDownloadDocument',
    version: 'V2',
    endpointV2: 'api/v2/Signature/DownloadDocument/{idFirma}/{idDocumento}',
    endpointV3: 'api/v2/Signature/DownloadDocument/{idFirma}/{idDocumento}'
  }),
  new InfoApi({
    nombre: 'ApiFirmaElimina',
    version: 'V2',
    endpointV2: 'api/v2/Signature/EliminaFirma/{idFirma}',
    endpointV3: 'api/v2/Signature/EliminaFirma/{idFirma}'
  }),
  new InfoApi({
    nombre: 'ApiFirmaCancela',
    version: 'V2',
    endpointV2: 'api/v2/Signature/CancelaFirma/{idFirma}',
    endpointV3: 'api/v2/Signature/CancelaFirma/{idFirma}',
  }),
  new InfoApi({
    nombre: 'ApiFirmaDescargaMasiva',
    version: 'V2',
    endpointV2: 'api/v2/Signature/GeneraDescargaMasiva',
    endpointV3: 'api/v2/Signature/GeneraDescargaMasiva'
  }),
  new InfoApi({
    nombre: 'ApiSubidaSubirFichero',
    version: 'V2',
    endpointV2: 'api/v2/Signature/UploadAjax',
    endpointV3: 'api/v2/Signature/UploadAjax'
  }),
  new InfoApi({
    nombre: 'ApiSubidaProcesaSubida',
    version: 'V2',
    endpointV2: 'api/v2/Signature/ProcesaSubida',
    endpointV3: 'api/v2/Signature/ProcesaSubida'
  }),
  new InfoApi({
    nombre: 'ApiSubidaCancelaSubida',
    version: 'V2',
    endpointV2: 'api/v2/Signature/CancelaSubida/{id}',
    endpointV3: 'api/v2/Signature/CancelaSubida/{id}'
  }),
  new InfoApi({
    nombre: 'ApiSubidaGetLog',
    version: 'V2',
    endpointV2: 'api/v2/Signature/GetLogSubidaFicheros/{id}',
    endpointV3: 'api/v2/Signature/GetLogSubidaFicheros/{id}'
  }),
  new InfoApi({
    nombre: 'ApiSubidaCargaExcelMasivo',
    version: 'V2',
    endpointV2: 'api/v2/Signature/CargaExcelMasivo',
    endpointV3: 'api/v2/Signature/CargaExcelMasivo',
  }),
  new InfoApi({
    nombre: 'ApiSubidaCreaSubida',
    version: 'V2',
    endpointV2: 'api/v2/Signature/CreaSubida',
    endpointV3: 'api/v2/Signature/CreaSubida'
  }),
  new InfoApi({
    nombre: 'ApiSubidaAddFichero',
    version: 'V2',
    endpointV2: 'api/v2/Signature/AddFicheroSubida',
    endpointV3: 'api/v2/Signature/AddFicheroSubida'
  }),
  new InfoApi({
    nombre: 'ApiSubidaGetIdActiva',
    version: 'V2',
    endpointV2: 'api/v2/Signature/GetIdActiva',
    endpointV3: 'api/v2/Signature/GetIdActiva'
  }),
  new InfoApi({
    nombre: 'ApiCancelaSubidaAuto',
    version: 'V2',
    endpointV2: 'api/v2/Signature/CancelaSubidaAuto/{id}',
    endpointV3: 'api/v2/Signature/CancelaSubidaAuto/{id}'
  }),

  new InfoApi({
    nombre: 'ApiSubidaGetCabeceraExcelConfigurada',
    version: 'V2',
    endpointV2: 'api/v2/Signature/GetCabecerasExcelConfiguradas',
    endpointV3: 'api/v2/Signature/GetCabecerasExcelConfiguradas'
  }),
  new InfoApi({
    nombre: 'ApiGetTipoEnvio',
    version: 'V2',
    endpointV2: 'api/TipoDosier/GetAllTipoDosier',
    endpointV3: 'api/TipoDosier/GetAllTipoDosier'
  }),
  new InfoApi({
    nombre: 'ApiGetTipoDocumento',
    version: 'V2',
    endpointV2: 'api/Documents/GetAllTipoDocumento',
    endpointV3: 'api/Documents/GetAllTipoDocumento'
  }),
  new InfoApi({
    nombre: 'ApiGetTipoDestinatarios',
    version: 'V2',
    endpointV2: 'api/TiposEnvioDesplegables/GetTipoDestinatarios',
    endpointV3: 'api/TiposEnvioDesplegables/GetTipoDestinatarios'
  }),
  new InfoApi({
    nombre: 'ApiGetTipoBusquedas',
    version: 'V2',
    endpointV2: 'api/TiposEnvioDesplegables/GetTipoBusquedas',
    endpointV3: 'api/TiposEnvioDesplegables/GetTipoBusquedas'
  }),
  new InfoApi({
    nombre: 'ApiGetTipoFirmantes',
    version: 'V2',
    endpointV2: 'api/TiposEnvioDesplegables/GetTipoFirmantes',
    endpointV3: 'api/TiposEnvioDesplegables/GetTipoFirmantes'
  }),
  new InfoApi({
    nombre: 'ApiGetTipoDirecciones',
    version: 'V2',
    endpointV2: 'api/TiposEnvioDesplegables/TiposDirecciones',
    endpointV3: 'api/TiposEnvioDesplegables/TiposDirecciones'
  }),
  new InfoApi({
    nombre: 'ApiAltaTipoEnvio',
    version: 'V2',
    endpointV2: 'api/TipoDosier/AltaTipoDosier',
    endpointV3: 'api/TipoDosier/AltaTipoDosier'
  }),
  new InfoApi({
    nombre: 'ApiGetTipoEnvioId',
    version: 'V2',
    endpointV2: 'api/TipoDosier/GetTipoDosier/',
    endpointV3: 'api/TipoDosier/GetTipoDosier/'
  }),
  new InfoApi({
    nombre: 'ApiAltaTipoDocumento',
    version: 'V2',
    endpointV2: 'api/Documents/AltaTipoDocumento',
    endpointV3: 'api/Documents/AltaTipoDocumento'
  }),
  new InfoApi({
    nombre: 'ApiEmpleadosGetExcelConfiguration',
    version: 'V2',
    endpointV2: '/api/v2/Empleados/GetExcelConfiguration',
    endpointV3: '/api/v2/Empleados/GetExcelConfiguration'
  }),
  new InfoApi({
    nombre: 'ApiEmpleadoDeleteEmpleados',
    version: 'V2',
    endpointV2: '/api/v2/Empleados/DeleteEmpleados',
    endpointV3: '/api/v2/Empleados/DeleteEmpleados'
  }),
  new InfoApi({
    nombre: 'ApiEmpleadoAddEmpleados',
    version: 'V2',
    endpointV2: '/api/v2/Empleados/AddEmpleados',
    endpointV3: '/api/v2/Empleados/AddEmpleados'
  }),
  new InfoApi({
    nombre: 'ApiManuales',
    version: 'V2',
    endpointV2: 'api/Manuales/GetManual',
    endpointV3: 'api/Manuales/GetManual'
  }),
  new InfoApi({
    nombre: 'ApiUrlUserValidEnvioTest',
    version: 'V2',
    endpointV2: 'api/v2/Auth/UserValidEnvioTest',
    endpointV3: 'api/v2/Auth/UserValidEnvioTest'
  }),
  new InfoApi({
    nombre: 'GetEstadoSubidaExcel',
    version: 'V2',
    endpointV2: 'api/v2/Empleados/GetEstadoSubida/{id}',
    endpointV3: 'api/v2/Empleados/GetEstadoSubida/{id}'
  }),
  new InfoApi({
    nombre: 'GetSubidasPendientesExcel',
    version: 'V2',
    endpointV2: 'api/v2/Empleados/GetSubidaPendinte',
    endpointV3: 'api/v2/Empleados/GetSubidaPendinte'
  }),
  new InfoApi({
    nombre: 'ApiSubeFicheroTemporal',
    version: 'V3',
    endpointV2: 'api/FicherosTemporales/SubeFicheroTemporal',
    endpointV3: 'api/FicherosTemporales/SubeFicheroTemporal'
  }),
  new InfoApi({
    nombre: 'GetUrlFileTemporal',
    version: 'V3',
    endpointV2: 'api/FicherosTemporales/GetUrlFileTemporal/{id}',
    endpointV3: 'api/FicherosTemporales/GetUrlFileTemporal/{id}'
  }),
  new InfoApi({
    nombre: 'GetUsuariosTipo',
    version: 'V3',
    endpointV2: 'api/Users/GetUsuariosTipo',
    endpointV3: 'api/Users/GetUsuariosTipo'
  }),
  new InfoApi({
    nombre: 'GetEstadosUsuario',
    version: 'V3',
    endpointV2: 'api/Users/GetEstadosUsuario',
    endpointV3: 'api/Users/GetEstadosUsuario'
  }),
  new InfoApi({
    nombre: 'GetEmpresas',
    version: 'V3',
    endpointV2: 'api/Empresas/GetEmpresas',
    endpointV3: 'api/Empresas/GetEmpresas'
  }),
  new InfoApi({
    nombre: 'GuardaEmpresa',
    version: 'V3',
    endpointV2: 'api/Empresas/GuardaEmpresa',
    endpointV3: 'api/Empresas/GuardaEmpresa'
  }),
  new InfoApi({
    nombre: 'GetCentros',
    version: 'V3',
    endpointV2: 'api/Centros/GetCentros',
    endpointV3: 'api/Centros/GetCentros'
  }),
  new InfoApi({
    nombre: 'GuardaCentro',
    version: 'V3',
    endpointV2: 'api/Centros/GuardaCentro',
    endpointV3: 'api/Centros/GuardaCentro'
  }),
  new InfoApi({
    nombre: 'GetUsers',
    version: 'V3',
    endpointV2: 'api/Users/GetUsers',
    endpointV3: 'api/Users/GetUsers'
  }),
  new InfoApi({
    nombre: 'GuardaUser',
    version: 'V3',
    endpointV2: 'api/Users/GuardaUser',
    endpointV3: 'api/Users/GuardaUser'
  }),
  new InfoApi({
    nombre: 'ActivaUsuario',
    version: 'V3',
    endpointV2: 'api/Users/ActivaUsuario/{login}',
    endpointV3: 'api/Users/ActivaUsuario/{login}'
  }),
  new InfoApi({
    nombre: 'DesactivaUsuario',
    version: 'V3',
    endpointV2: 'api/Users/DesactivaUsuario/{login}',
    endpointV3: 'api/Users/DesactivaUsuario/{login}'
  }),
  new InfoApi({
    nombre: 'LoginA3Api',
    version: 'V2',
    endpointV2: 'login/LoginA3Api',
    endpointV3: 'login/LoginA3Api'
  }),
  new InfoApi({
    nombre: 'GuardaCentros',
    version: 'V3',
    endpointV2: 'api/Centros/GuardaCentros',
    endpointV3: 'api/Centros/GuardaCentros'
  }),
  new InfoApi({
    nombre: 'GetCofiguracionRlt',
    version: 'V3',
    endpointV2: 'api/ConfiguracionAplicacion/GetCofiguracionRlt',
    endpointV3: 'api/ConfiguracionAplicacion/GetCofiguracionRlt'
  }),
  new InfoApi({
    nombre: 'GuardaConfiguracionRlt',
    version: 'V3',
    endpointV2: 'api/ConfiguracionAplicacion/GuardaConfiguracionRLT',
    endpointV3: 'api/ConfiguracionAplicacion/GuardaConfiguracionRLT'
  }),
  new InfoApi({
    nombre: 'IsModuloRltActivo',
    version: 'V3',
    endpointV2: 'api/v2/Rlt/IsModuloActivo',
    endpointV3: 'api/ConfiguracionAplicacion/IsModuloActivo'
  }),
  new InfoApi({
    nombre: 'DeleteEmpresa',
    version: 'V3',
    endpointV2: 'api/Empresas/DeleteEmpresa/{codigoEmpresa}',
    endpointV3: 'api/Empresas/DeleteEmpresa/{codigoEmpresa}'
  }),
  new InfoApi({
    nombre: 'DeleteCentro',
    version: 'V3',
    endpointV2: 'api/Centros/DeleteCentro/{codigoCentro}/{codigoEmpresa}',
    endpointV3: 'api/Centros/DeleteCentro/{codigoCentro}/{codigoEmpresa}'
  }),
  new InfoApi({
    nombre: 'GetIdTipoEnvioRlt',
    version: 'V2',
    endpointV2: 'api/v2/Rlt/GetIdTipoEnvio',
    endpointV3: 'api/v2/Rlt/GetIdTipoEnvio'
  })

];
