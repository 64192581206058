<div class="content">
  <form [formGroup]="firmanteNifForm">
    <div *ngIf="!generico.value && !tipoFirmante.checkSiCopiaBasica" class="flex hover" (click)="mostrarDetalle()">
      <h6>{{tipoFirmante.desc}}</h6>
      <svg class="icon-arrow" [ngClass]="firmanteDetalle ? 'icon-arrow-down':''">
        <use xlink:href="#hs-arrow"></use>
      </svg>
    </div>
    <div *ngIf="generico.value || tipoFirmante.checkSiCopiaBasica" class="flex">
      <input id="incluirFirmante" type="checkbox" formControlName="incluirFirmante" [ngStyle]="{'cursor': tipoFirmante.checkSiCopiaBasica ? 'default':'pointer'}" (click)="getClickCheckbox()">
      <label for=""><h6>{{tipoFirmante.desc}}</h6></label>
    </div>
    <div *ngIf="firmanteDetalle" style="margin-left: 20px;">
      <div class="flex-box">
        <div class="flex">
          <input type="radio" formControlName="posicionNif" value="pagina" (click)="borrarInputContrario('pagina'); addFirmanteNifInForm()">
          <div class="flex-input" style="margin-right: 25px">
            <label for="nifPage">Página</label>
            <input type="number" formControlName="nifPage" (keyup)="addFirmanteNifInForm()" [ngClass]="formularioValidacionesDocsService.isCampoValidoArray('nifPage', obtenerIndexNifArray()) && obtenerIndexNifArray() > -1 ? 'errorInput':'' " [readOnly]="posicionNif.value!=='pagina'">
          </div>
          <div class="flex-input">
            <label for="nifOrden">Orden</label>
            <input type="number" formControlName="nifOrden" (keyup)="addFirmanteNifInForm()" [ngClass]="formularioValidacionesDocsService.isCampoValidoArray('nifOrden', obtenerIndexNifArray()) && obtenerIndexNifArray() > -1? 'errorInput':'' " [readOnly]="posicionNif.value!=='pagina'">
          </div>
        </div>
          <mat-spinner *ngIf="cargando" diameter="30" strokeWidth="2" style="margin-top: 25px;"></mat-spinner>
          <div *ngIf="!cargando && nif!==''" class="nif">
            NIF: {{nif}}
          </div>
      </div>
      <div class="flex-box">
          <input type="radio" formControlName="posicionNif" value="formulario" (click)="borrarInputContrario('formulario'); addFirmanteNifInForm()">
          <div class="flex-input">
            <label for="nifFormulario">Formulario</label>
            <input type="text" class="text-largo" formControlName="nifFormulario" (keyup)="addFirmanteNifInForm()" [ngClass]="formularioValidacionesDocsService.isCampoValidoArray('nifFormulario', obtenerIndexNifArray()) && obtenerIndexNifArray() > -1 ? 'errorInput':'' " [readOnly]="posicionNif.value!=='formulario'">
          </div>
      </div>
      <span *ngIf="obtenerIndexNifArray() > -1">
        <p class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValidoArray('nifPage', obtenerIndexNifArray())">
          {{formularioValidacionesDocsService.mensajeErrorNifPage}}
        </p>
        <p class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValidoArray('nifOrden', obtenerIndexNifArray())">
          {{formularioValidacionesDocsService.mensajeErrorNifOrden}}
        </p>
        <p class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValidoArray('nifFormulario', obtenerIndexNifArray())">
          {{formularioValidacionesDocsService.mensajeErrorNifFormulario}}
        </p>
        <p class="texto-validacion" *ngIf="datosConfiguracionDocumentosService.noExistenNifs[obtenerIndexNifArray()]">Nif no encontrado. Informe de una página y orden válidos.</p>
      </span>
    </div>
  </form>
</div>
