<h3>Últimas actualizaciones </h3>
<div class="square square-big">
  <!-- <div class="spinner">
    <mat-spinner diameter="35" strokeWidth="2"></mat-spinner>
  </div> -->

    <div *ngIf="firmasService.cargandoDatos" class="spinner">
        <mat-spinner diameter="35" strokeWidth="2"></mat-spinner>
    </div>
    <ul *ngIf="!firmasService.cargandoDatos">
        <li *ngFor="let item of UltimasFirmas">
            <div class="flex-tag" [innerHTML]="getIcono(item)"></div>
            <div [ngClass]="{'red': item.estadoString == 'Error'}">{{item.empleado}}</div>
            <div [ngClass]="{'red': item.estadoString == 'Error'}">{{item.tipoEnvio}}</div>
        </li>
    </ul>
</div>
