<form [formGroup]="formulario">
  <div style="display: flex;">
    <input style="width: 95%;" [ngClass]="{'errorInput': errorClass}" type="text" matInput [matDatepicker]="picker" formControlName="fecha" (dateChange)="dateSelected($event)" readonly>
    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
    <mat-datepicker-toggle matIconSuffix [for]="picker" id="selectorFechaPicker" [disableRipple]="true">
    <!-- <mat-datepicker-toggle matIconSuffix [for]="picker" id="selectorFechaPicker" [disableRipple]="true" matRipple> -->
      <mat-icon matDatepickerToggleIcon></mat-icon>
      <!-- <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon> -->
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </div>
</form>

