import { ColDef } from 'ag-grid-community';

export class GridColDefsAddTipoDocs{

  flex = 'display: flex; align-items: center; padding: 10px 0 10px 0; justify-content: center';
  text = 'line-height: 20px; word-break: break-word;';

  public columnDefs: ColDef[] = [
    { headerName: "Nombre del documento", field: "nombre", sortable:true, resizable: true, headerClass:"header", width: 200, wrapText: true, autoHeight: true, checkboxSelection: true, comparator: customComparator,
    cellRenderer: params => {
      let nombreDoc = `<div style="${this.text}">${params.value}</div>`;
      return nombreDoc;
    }
    },
    { headerName:"Tipo doc.", field: "tipoDoc", sortable:true, resizable: false, headerClass:"header", width: 130, wrapText: true, autoHeight: true,
    cellRenderer: params => {
      let tipoDocIcon = `<div style="${this.flex}">
                          <span>${this.GetIcono(params.value)}</span>
                          <span style="${this.text}">${params.value}</span>
                         </div>`;
      return tipoDocIcon;
    }
    }
  ]


  GetIcono(estadoString: string){
    let style = 'width: 20px; height: 20px; margin: 10px 5px -5px 0px;';
    if(estadoString == 'Plantilla'){
      return `<svg style="${style}"><use xlink:href="#icon-plantilla"></use></svg>`;
    }else if(estadoString == 'Doc con datos personales'){
      return `<svg style="${style}"><use xlink:href="#icon-personal"></use></svg>`;
    }
    return null
  }

}

const customComparator = (valueA:any, valueB:any) => {
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};
