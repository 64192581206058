import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
@Component({
  selector: 'app-empleados-tooltip',
  templateUrl: './empleados-tooltip.component.html',
  styleUrls: ['./empleados-tooltip.component.css']
})
export class EmpleadosTooltipComponent implements OnInit {

  error: boolean = false;
  errorMensaje: string = '';
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: any): void {

    if(params.value != "")
    {
      this.error = true;
      this.errorMensaje = params.value;
    }
  }
}
