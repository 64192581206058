<!-- <div class="container"> -->
  <div class="botonAmarillo">
    <svg [ngClass]="id=='rlt'?'icono-rlt':'icono'">
      <use attr.xlink:href="{{icono}}"></use>
    </svg>
    <h2 class="titulo" [ngStyle]="{'margin-top': id=='rlt'?'0px':'8px'}">{{tituloBoton}}</h2>
    <div class="text">{{texto}}</div>
    <!-- <app-boton-accion mat-button (botonClicked)="irOpcion(id)" [titulo]="accionBoton" [id]="id" [outline]="true" [cancelar]="false" [btnAcceso]="true"></app-boton-accion> -->
  </div>
  <!-- <div class="info" [innerHTML]="infoText"></div> -->
<!-- </div> -->

