
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../services/formulario-validaciones-docs.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-configuracion-documento-nombre',
  templateUrl: './configuracion-documento-nombre.component.html',
  styleUrls: ['./configuracion-documento-nombre.component.css']
})
export class ConfiguracionDocumentoNombreComponent implements OnInit {

  @Output() cambiarDoc: EventEmitter<any> = new EventEmitter<any>();
  mostrarCambiar: boolean = false;

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              public formularioValidacionesDocsService: FormularioValidacionesDocsService,
              private router: Router) { }

  ngOnInit(): void {
    if(this.router.url.includes('editar')) this.mostrarCambiar = true;
  }

  get nombre(){ return this.formularioDocumentoService.formularioDocumento.controls['nombre']}
  get esRLT(){ return this.formularioDocumentoService.formularioDocumento.controls['esRLT']}

  click(){
    this.cambiarDoc.emit('cambiar');
  }

}
