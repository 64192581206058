import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { Firmas } from 'src/app/pages/panelControl/models/firmas';
import { GridColDefsData } from 'src/app/pages/panelControl/models/grid-col-defs-data';


@Component({
  selector: 'app-boton-ultimas-firmas',
  templateUrl: './boton-ultimas-firmas.component.html',
  styleUrls: ['./boton-ultimas-firmas.component.css']
})
export class BotonUltimasFirmasComponent implements OnInit {

  @Input() UltimasFirmas:Firmas[]=[];
  constructor( public firmasService:DatosFirmaService, private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  getIcono(_firma: Firmas){
    return this.domSanitizer.bypassSecurityTrustHtml(new GridColDefsData().GetIcono(_firma.estadoString, true));
  }
}
