import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/shared/services/breadcrumbs.service';

@Component({
  selector: 'app-selector-tipo-subida',
  templateUrl: './selector-tipo-subida.component.html',
  styleUrls: ['./selector-tipo-subida.component.css']
})
export class SelectorTipoSubidaComponent implements OnInit {

  @Output() tipoSubidaSelected: EventEmitter<string> = new EventEmitter<string>();
  // infoText: any = "<span>Consta de documentos genéricos (sin datos personales) que el sistema autorellena al combinarlo con una plantilla excel de firmantes-destinatarios.</span>\
  // <ul>\
  // <li>Permite 1 único firmante por dosier.</li>\
  // <li>Requiere subida y configuración previa del dosier genérico.</li>\
  // <li>Se proporciona plantilla excel a rellenar con el listado de firmantes.</li>\
  // </ul>";

  constructor(private breadcrumbsService: BreadcrumbsService) { }

  ngOnInit(): void {
  }

  selectTipoSubida(tipoSubida: string){
    if (tipoSubida.toUpperCase() == "ESTÁNDAR") {
      this.tipoSubidaSelected.emit('normal');
      this.breadcrumbsService.addBreadcrumbs('Envío estándar');
    }
    else {
      this.tipoSubidaSelected.emit('masiva');
      this.breadcrumbsService.addBreadcrumbs('Envío universal');
    }
  }

}
