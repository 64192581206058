import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public param:string = '';
  constructor(private route: ActivatedRoute) {
   
}

  ngOnInit(): void {
    this.param = this.route.snapshot.paramMap.get('mensaje'); 
  }

}
