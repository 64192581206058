import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationMessageComponent } from 'src/app/shared/components/modals/confirmation-message/confirmation-message.component';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';
import { DatosCentrosService } from '../../services/datos-centros.service';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { RecuperaEmpresasApiService } from '../../../empresas/services/recupera-empresas-api.service';
import { RecuperaConfiguracionAplicacionApiService } from 'src/app/pages/configuracion/configuracion-rlt/services/recupera-configuracion-aplicacion-api.service';
import { SpinnerModalComponent } from 'src/app/shared/components/others/spinner-modal/spinner-modal.component';

@Component({
  selector: 'app-centros-modal',
  templateUrl: './centros-modal.component.html',
  styleUrls: ['./centros-modal.component.css']
})
export class CentrosModalComponent implements OnInit {

  formCentro: FormGroup = new FormGroup({});
  isEditar: boolean = false;
  nuevo: boolean = false;
  titulosArray: string[]=[
    "Detalle de centro",
    "Edición de centro",
    "Nuevo centro"
  ]
  tituloModal: string = "";
  moduloRltActivo: boolean = false;
  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA)
              public info: any,
              private _snackBar: MatSnackBar,
              private fb: FormBuilder,
              private datosCentrosService: DatosCentrosService,
              public recuperaConfiguracionAplicacionService: RecuperaConfiguracionAplicacionApiService,
              private datosEmpresasApiService: RecuperaEmpresasApiService) {
              }

  ngOnInit(): void {
    this.iniciarFormularioCentro();
    this.nuevo = this.info.nuevo;
    this.cambiarTituloModal();
    this.isModuloRltActivo();
  }

  cambiarTituloModal(){
    if(this.isEditar == false) this.tituloModal = this.titulosArray[0]
    if(this.isEditar == true && this.nuevo == false) this.tituloModal = this.titulosArray[1]
    if(this.nuevo == true) this.tituloModal = this.titulosArray[2]
  }

  iniciarFormularioCentro(){
    this.formCentro = this.fb.group({
      codigoCentro:[this.info.centro.codigoCentro, Validators.required],
      nombreCentro:[this.info.centro.nombreCentro, Validators.required],
      emailCentro:[this.info.centro.emailCentro, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)],
      nombreEmpresa:[this.info.centro.nombreEmpresa],
      codigoEmpresa:[this.info.centro.codigoEmpresa],
      nombreRlt:[this.info.centro.nombreRlt],
      dniRlt:[this.info.centro.dniRlt]
    })
  }

  validateCodigoCentro(event: any){
    const value = event.target.value;
    if (value == '') {
      this.formCentro.controls['codigoCentro'].setErrors({'codigoVacio': true});
    } else {
      if(value.length > 25) this.formCentro.controls['codigoCentro'].setErrors({'codigoLargo': true});
      else{
        let indexCodigo = this.info.centros.findIndex(x=> x.codigoCentro == this.codigoCentro && x.codigoEmpresa == this.codigoEmpresa);
        if(indexCodigo >- 1) this.formCentro.controls['codigoCentro'].setErrors({'codigoExiste': (indexCodigo>-1)});
        else this.formCentro.controls['codigoCentro'].setErrors(null);
      }
    }
  }

  get controlCodigoCentro(){ return this.formCentro.controls['codigoCentro']}
  get codigoCentro(){ return this.formCentro.controls['codigoCentro'].value}
  get nombreCentro(){ return this.formCentro.controls['nombreCentro'].value}
  get emailCentro(){ return this.formCentro.controls['emailCentro'].value}
  get codigoEmpresa(){ return this.formCentro.controls['codigoEmpresa'].value}
  get nombreEmpresa(){ return this.formCentro.controls['nombreEmpresa'].value}
  get nombreRlt(){ return this.formCentro.controls['nombreRlt'].value}
  get dniRlt(){ return this.formCentro.controls['dniRlt'].value}

  // pattern('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
  editarCentro(){
    this.isEditar = true;
    this.cambiarTituloModal();
  }

  guardarCambiosCentro(){

    if(this.formCentro.invalid){
      this.formCentro.markAllAsTouched();
      return
    }


    let objetoCentro = {
      codigoCentro:this.formCentro.controls['codigoCentro'].value,
      nombreCentro:this.formCentro.controls['nombreCentro'].value,
      emailCentro:this.formCentro.controls['emailCentro'].value,
      codigoEmpresa:this.formCentro.controls['codigoEmpresa'].value,
      nombreEmpresa: '',
      nombreRlt:this.formCentro.controls['nombreRlt'].value,
      dniRlt:this.formCentro.controls['dniRlt'].value
    }

    const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
    this.datosCentrosService.guardaCentro(objetoCentro).subscribe(
      ok => {
        dialogRefSpinner.close();
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'Centro guardado correctamente',
          },
        });
        this.dialogRef.close('OK');
        this.datosCentrosService.refreshCentros = true;
        this.isEditar = !this.isEditar;
      },
      error => {
        console.error("error", error);
        dialogRefSpinner.close();
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'Error. El centro no se ha guardado',
            alert: true
          },
        });
      }
    );
  }

  // eliminarCentro(){
  //   const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
  //     data: {
  //       tipo: TipoMensajeConfirmacion.ConfirmacionCompleja,
  //       icono: 'icon-delete',
  //       titulo: '¿Eliminar centro?',
  //       preguntaConfirmacion: '¿Eliminar centro?',
  //       textoConfirmacion: 'Eliminar el centro seleccionado',
  //       textoBoton: 'Eliminar',
  //       iconoConfirmacion: 'icon-delete'
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if(result === 'OK'){
  //       this.datosCentrosService.deleteCentro(this.info.centro).subscribe(
  //         next => {
  //           const dialogRef = this.dialog.open(ModalMessageComponent, {
  //             data: {
  //               mensaje: 'Centro eliminado correctamente',
  //             },
  //           });
  //           this.dialogRef.close('OK');
  //           this.datosCentrosService.refreshCentros = true;
  //         },
  //         error => {
  //           const dialogRef = this.dialog.open(ModalMessageComponent, {
  //             data: {
  //               mensaje: 'Error. El centro no se ha eliminado',
  //               alert: true
  //             },
  //           });
  //           this.dialogRef.close();
  //         }
  //       );;
  //     }
  //     //faltaría mostrar error si hay problemas en la llamada http, este no es el caso de aquí.
  //   })
  // }

  isCampoValido(campo:string){
    return this.formCentro.controls[campo].errors && this.formCentro.controls[campo].touched;
  }

  isModuloRltActivo(){
    this.recuperaConfiguracionAplicacionService.isModuloRltActivo().subscribe(
      ok =>{
        this.moduloRltActivo = ok;
      },
      error => {
        console.log(error);
        this.moduloRltActivo = false;
      }
    );
  }

}
