import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatRipple } from '@angular/material/core';
import moment from 'moment';

@Component({
  selector: 'app-selector-fecha',
  templateUrl: './selector-fecha.component.html',
  styleUrls: ['./selector-fecha.component.css']
})
export class SelectorFechaComponent implements OnInit {

  formulario: FormGroup = new FormGroup({});
  @Input() campoFecha: string;
  @Output() fechaEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Input() errorClass: boolean = false;
  @ViewChild(MatRipple) ripple: MatRipple;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    let fechaModificada = this.campoFecha ? new Date(this.campoFecha.split(' ')[0] ): null;
    this.formulario = this.fb.group({
      fecha: [fechaModificada]
    })
  }

  get fecha(){ return this.formulario.controls['fecha']}

  dateSelected(event: any){
    // this.launchRipple();
    let date:Date | null = (this.fecha.value as any).toDate();
    let fechaMasUnDia:Date | null = new Date(date.getTime() + (24 * 60 * 60 * 1000));
    this.fechaEmitter.emit(fechaMasUnDia.toISOString())
  }

  launchRipple() {
    const rippleRef = this.ripple.launch({
      persistent: true, // Ripple will not fade out automatically
      centered: true
    });

    // Fade out the ripple after a certain delay (in milliseconds)
    setTimeout(() => {
      rippleRef.fadeOut();
    }, 1000); // Adjust the delay as needed
  }

}
