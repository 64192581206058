<!-- <div class="bg-white">
    <app-titulo-opcion titulo={{tituloPagina}} icono="#icon-send" icono2={{icono2}}></app-titulo-opcion>
</div> -->
<div class="container">
  <div class="flex-titulo">
    <h1>Nueva configuración de dosier</h1>
    <div class="flex-end">
      <app-selector [textoOpcion1]="''" [textoOpcion2]="'Activado'" [active]="activado" [control]="'estado'" (mostrarSeleccion)="toggleActivado($event)"></app-selector>
    </div>
  </div>
  <div class="content">
    <!-- <div class="flex-end">
      <app-selector [textoOpcion1]="'Desactivado'" [textoOpcion2]="'Activado'" [active]="activado" [control]="'estado'" (mostrarSeleccion)="toggleActivado($event)"></app-selector>
    </div> -->
    <form [formGroup]="formularioEnvioService.formularioEnvio">
      <!-- Nombre -->
      <div class="container-seccion">
        <h2>Nombre del dosier</h2>
      </div>

      <div class="container-white">
        <!-- <span class="grey">(nombre del dosier)</span> -->
        <!-- <h5>NOMBRE del dosier</h5> -->
        <input id="nombre" type="text" formControlName="nombre" [ngClass]="formularioValidacionesService.isCampoValido('nombre') ? 'errorInput':'' " >
        <br>
        <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('nombre')">
          {{formularioValidacionesService.mensajeErrorNombre}}
          </span>
      </div>

      <!-- Fin nombre -->
      <!-- Destinatarios, array de firmantes -->
      <div class="container-seccion">
        <h2>Destinatarios</h2>
      </div>
      <div id="destinatarios" class="container-white">
        <app-firmantes [totalFirmantes]="firmantes" [hayCopiaBasica]="hayCopiaBasica"></app-firmantes>
        <label for="email">Tipo de dirección de e-mail preferida</label>
        <br>
        <select id="standard-select" formControlName="mailPrincipal" (change)="changeValue($event, mailPrincipal )" [ngClass]="formularioValidacionesService.isCampoValido('mailPrincipal') ? 'errorInput':''">
          <option *ngFor="let mail of tiposMails"
                  [value]="mail.codigo"
                  >{{mail.desc}}</option>
        </select>
        <br>
        <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('mailPrincipal')">
          {{formularioValidacionesService.mensajeErrorMail}}
        </span>
      </div>
      <!-- Fin destinatarios -->
      <!-- Subject -->
      <div class="container-seccion">
        <h2>Configuracion del mensaje</h2>
      </div>
      <div class="container-white">
        <label for="subject">Asunto</label>
        <div style="margin-bottom: 20px">
          <input id="subject" type="text" formControlName="subject">
          <!-- <input id="subject" type="text" formControlName="subject" [ngClass]="formularioValidacionesService.isCampoValido('subject') ? 'errorInput':''" [readonly]="formularioEnvioService.disableSubject"> -->
          <!-- <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('subject')">
            {{formularioValidacionesService.mensajeErrorSubject}}
          </span>
          <div class="defecto">
            <input type="checkbox" formControlName="defectoSubject" (click)="formularioValidacionesService.modificarDefectoValidators('defectoSubject')">
            <label for="defectoSubject">Por defecto</label>
          </div> -->
        </div>
        <!-- Fin subject -->
        <!-- Body del email -->
        <label for="mensaje">Mensaje</label>
        <div  class="flex-input">
          <textarea id="body" formControlName="body"></textarea>
          <!-- <textarea id="body" formControlName="body" [ngClass]="formularioValidacionesService.isCampoValido('body') ? 'errorInput':''" [readonly]="formularioEnvioService.disableBody"></textarea> -->
          <!-- <br> -->
          <!-- <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('body')">
            {{formularioValidacionesService.mensajeErrorBody}}
          </span>
          <div class="defecto">
            <input type="checkbox" formControlName="defectoBody" (click)="formularioValidacionesService.modificarDefectoValidators('defectoBody')">
            <label for="defectoBody">Por defecto</label>
        </div> -->
        </div>
      </div>

      <!-- Fin body del e-mail -->
      <!-- Documentos adjuntos -->
      <div class="container-seccion">
        <h2>Documentos adjuntos</h2>
        <p *ngIf="!envio?.esRLT" style="font-size: 13px;">Puedes crear un nuevo documento o seleccionar uno ya existente en otro envío. También los puedes fusionar en uno solo.</p>
      </div>

      <div id="docs-adjuntos">
        <!-- <div class="flex">
          <span>
            <svg class="icon-attach blue" style="padding-top: 10px">
              <use xlink:href="#icon-attach"></use>
            </svg>
          </span>
          <h5>DOCUMENTOS ADJUNTOS:</h5>
        </div> -->
        <div *ngIf="documentosEnvio.length>0" id="documentos">
            <app-grupo-docs [totalDocumentos]="documentosEnvio" (docEliminadoEmitter)="refresh()"></app-grupo-docs>
        </div>
        <!-- <p style="margin-top: 10px">Puedes crear un nuevo documento o seleccionar uno ya existente en otro dosier.</p> -->
        <div *ngIf="!envio?.esRLT" class="anadir-docs">
          <label for="">Añadir documentos</label>
          <div class="flex-docs-adjuntos">
            <app-boton-accion id="addExistenteId" mat-button (botonClicked)="anadirDocExistente()" [titulo]="'Documentos existentes'" [outline]="true"[outlineYellow]="true" [cancelar]="false"></app-boton-accion>
            <app-boton-accion id="addNuevoId" mat-button (botonClicked)="anadirNuevoDoc()" [titulo]="'Nuevo documento'" [outline]="true"[outlineYellow]="true" [cancelar]="false"></app-boton-accion>

            <!-- <app-boton-accion-secundaria (click)="anadirDocExistente()" [id]="'docExistente'" [icono]="'icon-attach'" [titulo]="'añadir doc existente'"></app-boton-accion-secundaria>
            <app-boton-accion [id]="'nuevoDoc'" [icono]="'icon-plus'" [titulo]="'añadir nuevo doc'" (click)="anadirNuevoDoc()"></app-boton-accion> -->
          </div>
        </div>

      </div>
      <!-- Fin documentos adjuntos -->
      <!-- Ajustes -->
      <div class="container-seccion">
        <h2>Ajustes adicionales</h2>
      </div>
      <div id="ajustes" class="container-white">
        <!-- Solicitar archivos adjuntos -->
        <!-- <div class="checkboxAjustes">
          <input type="checkbox" formControlName="solicitarAdjunto" (click)="formularioValidacionesService.getCheckboxClick('solicitarAdjunto')">
          <span>
            <svg class="icon-attach blue icon-ajustes">
              <use xlink:href="#icon-attach"></use>
            </svg>
          </span>
          <label for="solicitarAdjunto">Solicitar archivos adjuntos</label>
        </div>
        <div class="input-ajustes">
          <input type="number" formControlName="numeroAdjuntos" [ngClass]="[formularioValidacionesService.isCampoValido('numeroAdjuntos') ? 'errorInput':'', formularioEnvioService.disableAdjuntos ? 'bg-transparent':'']" [readonly]="formularioEnvioService.disableAdjuntos">
          <label for="numeroAdjuntos">Cantidad de archivos a subir por el firmante principal</label>
          <br>
          <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('numeroAdjuntos')">
            {{formularioValidacionesService.mensajeErrorAdjuntos}}
          </span>
        </div> -->
        <!-- Fin archivos adjuntos -->
        <!-- Recordatorios programados -->
        <h3>Recordatorios programados</h3>
        <div class="checkboxAjustes">
          <input type="checkbox" formControlName="recordatorio" (click)="formularioValidacionesService.getCheckboxClick('recordatorio'); clickAvisoFirma()">
          <!-- <span>
            <svg class="icon-attach blue icon-ajustes">
              <use xlink:href="#icon-bell-no-dot"></use>
            </svg>
          </span> -->
          <label for="recordatorio">Activar recordatorios</label>
        </div>
        <div *ngIf="recordatorio.value">
          <div class="diasEnvio">Días tras el envío</div>
          <div class="input-ajustes">
            <app-envios-recordatorios *ngFor="let recordatorio of formularioEnvioService.recordatorios; index as i" [indexAviso]="i" (eliminar)="eliminarAviso($event)" (update)="updateAviso($event)" [numAviso]="recordatorio"></app-envios-recordatorios>
            <div>
              <p class="link-agregar" (click)="agregarAviso()">Añadir otro aviso</p>
            </div>
            <!-- <input #elasticInput (input)="resize()" type="text" formControlName="reminders_String" [ngStyle]="{'width.px': width}" [ngClass]="[formularioValidacionesService.isCampoValido('reminders_String') ? 'errorInput':'', formularioEnvioService.disableRecordatorio ? 'bg-transparent':'']" [readonly]="formularioEnvioService.disableRecordatorio">
            <label for="reminders_String">Días posteriores al envío</label>
            <br> -->
            <!-- <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('reminders_String')">
              {{formularioValidacionesService.mensajeErrorRecordatorios}}
            </span> -->

          </div>
          <span class="texto-validacion val-avisos" *ngIf="formularioValidacionesService.validacionAvisos()">
            {{formularioValidacionesService.mensajeErrorAvisosFirma}}
          </span>
        </div>

        <!-- Fin recordatorios programados -->
        <!-- Envío universal -->
        <h3 style="margin-top: 20px">Envío universal</h3>
        <div class="checkboxAjustes">
          <input type="checkbox" formControlName="envioMasivo">
          <!-- <span>
            <svg class="icon-attach blue icon-ajustes">
              <use xlink:href="#icon-universal"></use>
            </svg>
          </span> -->
          <label for="envioMasivo">Activar envío universal</label>
        </div>
        <!-- Fin envío universal -->
      </div>
      <!-- Configuración avanzada - envío programado -->
      <!-- <div id="conf-avanzada">
        <div class="tab">
          <input type="checkbox" id="check-conf">
          <label class="tab-label" for="check-conf">CONFIGURACIÓN AVANZADA</label>
          <div class="tab-content">
            <div class="checkboxAjustes">
              <input type="checkbox" formControlName="programado" (click)="formularioValidacionesService.getCheckboxClick('programado')">
              <span>
                <svg class="icon-attach blue icon-ajustes">
                  <use xlink:href="#icon-pending"></use>
                </svg>
              </span>
              <label for="programado">Envío programado</label>
            </div>
            <div class="input-ajustes">
              <input type="number" formControlName="documentosEnvioProgramado" [ngClass]="[formularioValidacionesService.isCampoValido('documentosEnvioProgramado') ? 'errorInput':'', formularioEnvioService.disableProgramado ? 'bg-transparent':'']" [readonly]="formularioEnvioService.disableProgramado">
              <label for="documentosEnvioProgramado">Cantidad de envíos de este tipo a acumular para realizar el envío.</label>
              <br>
              <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('documentosEnvioProgramado')">
                {{formularioValidacionesService.mensajeErrorProgramado}}
              </span>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Fin configuración avanzada -->
      <!-- <span #hiddenText style="visibility: hidden;white-space: pre;">{{elasticInput.value}}</span> -->
      <!-- Fin ajustes -->
    </form>
  </div>
</div>

<app-barra-footer [id]="'nuevo envio configurado'" [icono]="'hs-send'" [titulo]="'Procesar y enviar'" [id2]="'guardar borrador'" [titulo2]="'Guardar borrador'" [icono2]="'hs-guardar'" [dobleAccion]="true" (botonClicked)="comprobarNumFirmantes()" (botonClicked2)="guardaBorrador()" class="sticky"></app-barra-footer>
