import { Component, OnInit } from '@angular/core';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../services/formulario-validaciones-docs.service';

@Component({
  selector: 'app-configuracion-documento-firmante',
  templateUrl: './configuracion-documento-firmante.component.html',
  styleUrls: ['./configuracion-documento-firmante.component.css']
})
export class ConfiguracionDocumentoFirmanteComponent implements OnInit {

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              public formularioValidacionesDocsService: FormularioValidacionesDocsService) { }

  ngOnInit(): void {
  }

}
