import { Component, Input, OnInit } from '@angular/core';
import { DatosConfiguracionDocumentosService } from '../../services/datos-configuracion-documentos.service';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../services/formulario-validaciones-docs.service';

@Component({
  selector: 'app-configuracion-documento-configurar-cif',
  templateUrl: './configuracion-documento-configurar-cif.component.html',
  styleUrls: ['./configuracion-documento-configurar-cif.component.css']
})
export class ConfiguracionDocumentoConfigurarCifComponent implements OnInit {

  mostrarCif: boolean = false;
  cifArray: string[]=[];
  icono: string = '';
  @Input() idPdf: string;

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              public formularioValidacionesDocService: FormularioValidacionesDocsService) {
              }

  ngOnInit(): void {
    if(this.contieneCif.value == true) this.mostrarCif = true;
    else this.mostrarCif = false;
  }

  get nombre(){ return this.formularioDocumentoService.formularioDocumento.controls['nombre'] }
  get contieneCif(){ return this.formularioDocumentoService.formularioDocumento.controls['contieneCif'] }
  get posicionCif(){ return this.formularioDocumentoService.formularioDocumento.controls['posicionCif'] }
  get cifOrden(){ return this.formularioDocumentoService.formularioDocumento.controls['cifOrden'] }
  get cifPage(){ return this.formularioDocumentoService.formularioDocumento.controls['cifPage'] }
  get cifFormulario(){ return this.formularioDocumentoService.formularioDocumento.controls['cifFormulario'] }

  getClickCheckbox(){
    this.contieneCif?.valueChanges.subscribe(value => {
      if(value) this.mostrarCif = true
      else {
        this.mostrarCif = false;
        this.posicionCif.setValue(null);
        this.cifOrden.setValue(null);
        this.cifPage.setValue(null);
        this.cifFormulario.setValue(null);
      }
    })
  }
}
