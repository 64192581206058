import { ColDef } from "ag-grid-community";

export class GridColDefsUsuarios{

  // styleInactivo = 'background-color: #F7E4D9; border-radius: 3px; color: #48565F; padding: 4px 10px 4px 10px;';


  // styleInactivo = 'background-color: #CCCCCC; border-radius: 3px; color: #48565F; padding: 4px 10px 4px 10px;';
  styleInactivo = 'border-radius: 16px; padding: 4px 10px; margin: 10px 0; font-size: 12px; align-self: center; background-color: #CCCCCC;  color: #48565F;';
  styleInvitacion = 'border-radius: 16px; padding: 4px 10px; margin: 10px 0; font-size: 12px; align-self: center; background-color: var(--hs-orange2); color: #965B02; font-weight: 400;';
  styleActivo = 'border-radius: 16px; padding: 4px 10px; margin: 10px 0; font-size: 12px; align-self: center; background-color: #3FA677; color: white; font-weight: 500;';

  columnDefs: ColDef[] = [
    { headerName: "Nombre", field: "nombre", sortable:true, resizable: true, headerClass:"header", width: 400,
    cellRenderer: params => {
      let fullNombre = `${params.data.nombre} ${params.data.apellido1}`
      return fullNombre
    }},
    { headerName:"Correo electrónico", field: "email", sortable:true, resizable: true, headerClass:"header", width: 450},
    { headerName:"Rol", field: "rol", sortable:true, resizable: true, headerClass:"header", width: 300 },
    { headerName:"Permisos", field: "rol", sortable:true, resizable: true, headerClass:"header", width: 500,
    cellRenderer: params => {
      let permisos;
      if(params.data.rol == 'Usuario') permisos = 'Sólo envío de firmas'
      else if(params.data.rol == 'Supervisor') permisos = 'Enviar Firmas y configurar'
      else if(params.data.rol == 'Administrador') permisos = 'Todos los permisos, sin restricciones'
      return permisos
    }
  },
    { headerName:"Estado", field: "usuarioActivo", sortable:true, resizable: true, headerClass:"header", width: 1000,
    comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
      const nameA = this.generateEstadoUsuario(nodeA.data);
      const nameB = this.generateEstadoUsuario(nodeB.data);
      return nameA.localeCompare(nameB);
    },
    cellRenderer: params => {
      return this.generateEstadoUsuario(params.data)
    }
  }
  ];

  getEstado(estado: string){
    let style;
    if(estado == 'Activo') {
      style = this.styleActivo
      return style
    }
    if(estado == 'Pendiente activación'){
      style = this.styleInvitacion
      return style
    }
    if(estado == 'Desactivado'){
      style = this.styleInactivo
      return style
    }
    return ''
  }

  generateEstadoUsuario(data:any):string{
    let estado = data.usuarioActivo;
      let invitacionAceptada = data.invitacionAceptada;
      let invitacionEnviada = data.invitacionEnviada;
      let estadoDesc;
      if(estado) estadoDesc = 'Activo';
      else if(!estado && invitacionEnviada && !invitacionAceptada) estadoDesc = 'Pendiente activación';
      else estadoDesc = 'Desactivado'
      let estadoUsuario = `<div>
                            <span style="${this.getEstado(estadoDesc)}">${estadoDesc}</span>
                           </div>`;
      return estadoUsuario
  }
}
