import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DatosEnviosService } from '../Envios/envios/services/datos-envios.service';
import { RecuperaConfiguracionAplicacionApiService } from '../configuracion-rlt/services/recupera-configuracion-aplicacion-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-configuracion-page',
  templateUrl: './configuracion-page.component.html',
  styleUrls: ['./configuracion-page.component.css']
})
export class ConfiguracionPageComponent {

  numEnviosIncompletos: string = '-';
  moduloRltActivo: boolean = false;
  mostrarUsuarios: boolean;

  constructor(private router: Router,
              private datosEnviosService: DatosEnviosService,
              public recuperaConfiguracionAplicacionService: RecuperaConfiguracionAplicacionApiService){}

  async ngOnInit(){
    if(environment.LoginUniversal) this.mostrarUsuarios = true;
    else this.mostrarUsuarios = false;
    this.isModuloRltActivo();
    await this.datosEnviosService.getTipoEnvios();
    this.numEnviosIncompletos = this.datosEnviosService.enviosFiltrados.filter(x=> x.estado=='Incompleto').length.toString();
    // this.datosFirmasService.cargarColDefs(this.moduloRltActivo);

  }

  navegar(ruta:string){
    this.router.navigate([ruta])
  }

  async isModuloRltActivo() {
    try {
      const result = await this.recuperaConfiguracionAplicacionService.isModuloRltActivo().toPromise();

      this.moduloRltActivo = result;
    } catch (error) {
      console.log(error);
      this.moduloRltActivo = false;
    }
  }

  // isModuloRltActivo(){
  //   this.recuperaConfiguracionAplicacionService.isModuloRltActivo().subscribe(
  //     ok =>{

  //       this.moduloRltActivo = ok;
  //     },
  //     error => {
  //       console.log(error);
  //       this.moduloRltActivo = false;
  //     }
  //   );
  // }
}
