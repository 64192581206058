import { ColDef } from "ag-grid-community";

export class GridColDefsEmpresas{
  columnDefs: ColDef[] = [
    { headerName: "Código empresa", field: "codigoEmpresa", sortable:true, resizable:true, headerClass:"header", width: 300,headerCheckboxSelection: true, checkboxSelection: true,
    valueFormatter: (params) => {
      // Format numbers to include leading zeros for proper sorting
      return params.value.toLocaleString(undefined, {minimumIntegerDigits: 10});
    },
    comparator: (valueA, valueB) => {
      // Convert the formatted numbers back to compare for sorting
      return Number(valueA) - Number(valueB);
    }},
    { headerName:"Nombre empresa", field: "nombreEmpresa", sortable:true, resizable:true, headerClass:"header", width: 300},
    { headerName:"Cif", field: "cif", sortable:true, resizable:true, headerClass:"header", width: 200 },
    { headerName:"Nombre validador", field: "nombreEmpleado", sortable:true, resizable:true, headerClass:"header", width: 350,
    cellRenderer: params => {
      let validador:string;
      if(params.data.nombreEmpleado && params.data.apellido1Empleado) validador = `${params.data.nombreEmpleado} ${params.data.apellido1Empleado}`;
      else if(params.data.nombreEmpleado && !params.data.apellido1Empleado) validador = `${params.data.nombreEmpleado}`;

      return validador
    } },
    { headerName:"Email validador", field: "emailEmpleado", sortable:true, resizable:true, headerClass:"header", width: 1000 }
  ];
}
