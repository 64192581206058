import { ContenidoDocVisible } from "./contenido-doc-visible"
import { ContenidoDocVisibleGuardar } from "./contenido-doc-visible-guardar"
import { FirmanteNif } from "./firmante-nif"
import { TiposContenido } from "./tipos-contenido"

export interface TipoDocumento {
      // alturaTagFirma: number,
      // anchuraTagFirma: number,
      // campoNifEmpleado: string,
      // campoNifEmpresa: string,
      cifOrden?: number,
      cifPage?: number,
      cifFormulario?:string,
      // cifRegex: string,
      esCopiaBasica: boolean,
      // esFirmaTag: boolean,
      formatoNombreArchivoFirmado: string,
      formatoNombreArchivoAuditTrail: string,
      fusionable?: boolean,
      generico: boolean,
      idTipoDocumento: number,
      // nifOrden: number,
      // nifPage: number,
      // nifRegex: string,
      nombre: string,
      // numFirmantes: number,
      ordenFusion?: number,
      // sel: boolean,
      split: string,
      // tag: string,
      texto?: string,
      textoFormulario?:string,
      textoAExcluir?: string,
      textoAExcluirFormulario?:string,
      // ubicacion: string,
      // nombreEnvio: string,
      paginas: number,
      estado?: string,
      tipoDoc?: string,
      // urlDocTemp?: string,
      idTipoDocOrigen?: number,
      incluirContenido?: boolean,
      tiposContenido?: ContenidoDocVisibleGuardar[],
      firmantesNif?: FirmanteNif[],
      idPdf: string,
      esRLT: boolean
}

export class EstadosTipoDocumento{
  static incompleto: string ='Incompleto'
  static completo: string = 'Listo para envío'
}
export class EstadosTipoDocumentoApi{
  static incompleto: string ='Incompleto'
  static completo: string = 'Completo'
}
export class GenericoTipoDocumento{
  static plantilla: string = 'Plantilla'
  static docDatosPers: string = 'Doc con datos personales'
}

export interface DocumentosSearchResponse{
  resultado: string;
  datos: TipoDocumento[];
}

//no cambiar el orden de TipoDocumentoClass ya que se utilitza convertido a JSON para chequear si hay cambios en el documento a configurar.
export class TipoDocumentoClass implements TipoDocumento{
  cifOrden = null;
  cifPage = null;
  cifFormulario = null;
  generico = null;
  idPdf = "";
  idTipoDocOrigen = null;
  idTipoDocumento = null;
  incluirContenido = false;
  nombre = "";
  texto = "";
  textoAExcluir = "";
  tiposContenido = [];
  paginas = null;
  esCopiaBasica = null;
  split = "";
  formatoNombreArchivoAuditTrail = "";
  formatoNombreArchivoFirmado = "";
  firmantesNif = [];
  textoFormulario = "";
  textoAExcluirFormulario = "";
  esRLT = false;
}

