<!-- <app-titulo-opcion titulo="{{nombre.value}}" [icono]="icono"></app-titulo-opcion> -->
<div class="main">
  <h2>Identificación del contenido</h2>
  <p>Para que Hire & Sign pueda identificar el documento automáticamente indica el texto que contiene y que lo diferencia de otros documentos.</p>
  <form [formGroup]="formularioDocumentoService.formularioDocumento">
    <div class="flex">
      <label for="texto">Contiene el texto:</label>
      <input type="text" formControlName="texto">
    </div>
    <div class="flex">
      <label for="textoAExcluir">Excluir si contiene el texto:</label>
      <input type="text" formControlName="textoAExcluir">
    </div>
    <div class="flex" (click)="mostrarConfAvanzada()">
      <h6>Configuración avanzada</h6>
        <svg class="icon-arrow" [ngClass]="mostrarAvanzada ? 'icon-arrow-down':''">
          <use xlink:href="#icon-arrow"></use>
        </svg>
    </div>
    <div *ngIf="mostrarAvanzada">
      <div class="flex">
        <input type="checkbox" style="margin-bottom: 4px;" formControlName="esCopiaBasica" [ngClass]="formularioValidacionesDocsService.isCampoValido('esCopiaBasica') ? 'errorInput':'' " (click)="getEsCopiaBasicaCheckBox()">
        <label for="esCopiaBasica" style="padding-left: 5px;">Copia básica</label>
      </div>
      <span class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValido('esCopiaBasica')">
        {{formularioValidacionesDocsService.mensajeErrorCopiaBasica}}
      </span>
      <div class="flex">
        <label for="formatoNombreArchivoFirmado">Nombre Doc:</label>
        <input type="text" formControlName="formatoNombreArchivoFirmado" [ngClass]="formularioValidacionesDocsService.isCampoValido('formatoNombreArchivoFirmado') ? 'errorInput':'' ">
      </div>
      <span class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValido('formatoNombreArchivoFirmado')">
        {{formularioValidacionesDocsService.mensajeErrorNombreArchivoFirmado}}
      </span>
      <div class="flex">
        <label for="formatoNombreArchivoAuditTrail">Nombre Audit:</label>
        <input type="text" formControlName="formatoNombreArchivoAuditTrail" [ngClass]="formularioValidacionesDocsService.isCampoValido('formatoNombreArchivoAuditTrail') ? 'errorInput':'' ">
      </div>
      <span class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValido('formatoNombreArchivoAuditTrail')">
        {{formularioValidacionesDocsService.mensajeErrorNombreArchivoAudit}}
      </span>
      <div class="flex">
        <label for="split">Split:</label>
        <input type="text" formControlName="split" [ngClass]="formularioValidacionesDocsService.isCampoValido('split') ? 'errorInput':'' ">
      </div>
      <span class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValido('split')">
        {{formularioValidacionesDocsService.mensajeErrorSplit}}
      </span>
      <div class="flex">
        <label for="textoFormulario">Texto Formulario:</label>
        <input type="text" formControlName="textoFormulario">
      </div>
      <div class="flex">
        <label for="textoAExcluirFormulario">Texto Formulario Excluir:</label>
        <input type="text" formControlName="textoAExcluirFormulario">
      </div>
    </div>
  </form>
</div>
