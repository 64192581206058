import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { TipoDocumento } from 'src/app/pages/configuracion/Envios/documentos/models/tipo-documento';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { ConversionDatosApiService } from 'src/app/shared/services/conversion-datos-api.service';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { IRecuperarTiposDocumentoService } from './ports/i-recuperar-tipos-documento-service';
import { FirmanteNif, TipoFirma } from '../models/firmante-nif';
import { ContenidoDocVisibleGuardar } from '../models/contenido-doc-visible-guardar';

@Injectable({
  providedIn: 'root'
})

export class RecuperaTipoDocumentoApiService implements IRecuperarTiposDocumentoService{

  private readonly URL_GET_TIPO_DOCUMENTO: string = this.endpointService.UrlGetTipoDocumento;
  private URL_ALTA_TIPO_DOCUMENTO: string = this.endpointService.UrlAltaTipoDocumento;

  documentos: any[];

  constructor(private client: HttpClient,
              private loginService: LoginService,
              private endpointService: EndpointService,
              private conversionDatosApiService: ConversionDatosApiService) { }

  getTipoDocumentos(refresh?: boolean):Observable<TipoDocumento[]>{
    if(this.documentos && !refresh){
      return of(this.documentos)
    }else{
      const options = {
        headers: new HttpHeaders({
          'Content-Type':'application/json',
          'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
        })
      };
      return this.client.get(this.URL_GET_TIPO_DOCUMENTO, options).pipe(
        // delay(3000),
        map((response: any) =>{
          if (response) {
            this.documentos = this.conversionDatosApiService.mapDocumentosEntrada(response)
            this.documentos.forEach(x=>{
              let idFormulario = 1;
              let idTipoContenido = 1;
              x.tiposContenido.map((y: ContenidoDocVisibleGuardar)=> y.id = idTipoContenido++)
              x.firmantesNif.map((y: any)=>{
                y.formularios.map((z:any)=> z.id = idFormulario++)
                if(y.firmas.tipoFirma == "dcf") y.firmas.tipoFirma = TipoFirma.Certificado;
                else if(y.firmas.tipoFirma == "validator") y.firmas.tipoFirma = TipoFirma.Validador;
                else if((y.firmas.tipoFirma == "signer" || y.firmas.tipoFirma == "signature") && y.firmas.posicionFirmas.length == 0) y.firmas.tipoFirma = TipoFirma.SinFirma;
                else if(y.firmas.tipoFirma == "signer" || y.firmas.tipoFirma == "signature") y.firmas.tipoFirma = TipoFirma.Dibujada;
                else y.firmas.tipoFirma = TipoFirma.SinFirma;
                if(y.nifOrden == 0 && !x.generico && !x.esCopiaBasica) y.nifOrden = null;
                if(y.nifPage == 0) y.nifPage = null;

                if(y.firmas.tipoFirma !== TipoFirma.Dibujada) y.firmas.tieneTag = null; //desde el back viene como false ya que no es nullable pero en el front lo queremos como null si la firma no es dibujada.
              })
            })
            // this.documentos.forEach(x=>{
            //   x.firmantesNif.forEach((y: FirmanteNif)=>{
            //     if((y.firmas.tipoFirma == TipoFirma.Validador && y.camposEditables.length>0) ||
            //        (y.firmas.tipoFirma == TipoFirma.Validador && y.formularios.length>0)
            //     ){
            //         console.log('doc con validador y campos: ', x)
            //       }
            //   })
            // })
            return this.documentos;
          }
          else throw new Error(response.Message);
        })
      );
    }

  }


  guardaDocumento(data:any): Observable<void> {
    data.activo = true;
    data = this.conversionDatosApiService.mapDocumentosSalida(data);
    if(data.FirmantesNif.length>0){
      data.FirmantesNif = this.conversionDatosApiService.mapFirmantesNifSalida(data.FirmantesNif);
    }
    data.TiposContenido = this.conversionDatosApiService.mapTiposContenidoSalida(data.TiposContenido);

    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.post<void>(this.URL_ALTA_TIPO_DOCUMENTO, data, options)
  }
}
