
<div class="dossier">
  <div class="dossier-name" (click)="mostrarDocs()">
    <span>{{datosSubidaService.tipoEnvio?.nombre | uppercase}}</span>
    <!-- <span *ngIf="datosSubidaService.tipoEnvio?.tiposDocumento?.length==1" class="dossier-name-numberdocs"> - {{datosSubidaService.tipoEnvio?.tiposDocumento?.length}} documento</span>
    <span *ngIf="datosSubidaService.tipoEnvio?.tiposDocumento?.length!==1" class="dossier-name-numberdocs"> - {{datosSubidaService.tipoEnvio?.tiposDocumento?.length}} documentos</span> -->
    <svg [ngClass]="docs ? 'icon-arrow-up':'icon-arrow-down'">
      <use xlink:href="#icon-arrow"></use>
    </svg>
  </div>
  <div *ngIf="docs" class="flex-docs-subida">
    <div>
      <p class="tipo-docs">Documentos a subir</p>
      <div class="flex-inner-docs">
        <div *ngFor="let tipoDoc of datosSubidaService.tipoEnvio?.tiposDocumento">
          <span *ngIf="!tipoDoc.generico" class="titulo-doc" [ngStyle]="{'margin-right': !tipoDoc.generico ? '25px':0}">
            <svg class="icon">
              <use xlink:href="#hs-doc-subir"></use>
            </svg>
            <span>
              {{tipoDoc.nombre}}
            </span>
          </span>
        </div>
      </div>
    </div>
    <div>
      <p class="tipo-docs">Documentos genéricos</p>
      <div class="flex-inner-docs">
        <div *ngFor="let tipoDoc of datosSubidaService.tipoEnvio?.tiposDocumento">
          <span class="titulo-doc" *ngIf="tipoDoc.generico" [ngStyle]="{'margin-right': tipoDoc.generico ? '25px':0}">
            <svg class="icon">
              <use xlink:href="#hs-doc-ok"></use>
            </svg>
            <span>
              {{tipoDoc.nombre}}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <app-avance-subida></app-avance-subida> -->

