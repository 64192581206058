import { Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TipoFirmante } from '../../../../envios/models/tipo-firmante';
import { FirmanteNif } from '../../../models/firmante-nif';
import { DatosConfiguracionDocumentosService } from '../../../services/datos-configuracion-documentos.service';

@Component({
  selector: 'app-casillas-temporal',
  templateUrl: './casillas-temporal.component.html',
  styleUrls: ['./casillas-temporal.component.css']
})
export class CasillasTemporalComponent implements OnInit {

  @Input() posicionCasilla: {
    mostrar: boolean,
    left: number,
    top: number,
    firmante: TipoFirmante,
    nombre: string,
    orden: number
  }
  @ViewChild('draggableIcon') draggableIcon: ElementRef;
  @ViewChild('draggableDiv') draggableDiv: ElementRef;
  @ViewChild('draggableHref') draggableHref: ElementRef;
  casillaPosition: { left: number, top: number, height: number, width: number };
  casillaEnviar: any;
  isDragging: boolean = false;
  clientX: number = 0;
  clientY: number = 0;
  initialX: number = 0;
  initialY: number = 0;
  xOffset: number = 0;
  yOffset: number = 0;
  currentX: number = 0;
  currentY: number = 0;
  finalX: number = 0;
  finalY: number = 0;

  constructor(private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges){
    this.posicionCasilla = changes.posicionCasilla.currentValue
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event:any){

    if(event.target === this.draggableDiv.nativeElement || event.target === this.draggableIcon.nativeElement){
      if(this.posicionCasilla.mostrar){

        this.draggableDiv.nativeElement.style.display = 'flex';
        this.isDragging = true;
        this.clientX = this.posicionCasilla.left;
        this.clientY = this.posicionCasilla.top;
        this.initialX = this.posicionCasilla.left - this.xOffset;
        this.initialY = this.posicionCasilla.top - this.yOffset;

      }
    }
  }

  @HostListener('document:mousemove', ['$event']) onMouseMove(event: any) {
    if (this.isDragging) {
      this.currentX = event.clientX - this.initialX - 20;
      this.currentY = event.clientY - this.initialY - 20;
      this.xOffset = this.currentX;
      this.yOffset = this.currentY;
      this.finalX = this.currentX + this.posicionCasilla.left;
      this.finalY = this.currentY + this.posicionCasilla.top - 50;
      this.casillaPosition = {
        left: this.finalX,
        top: this.finalY,
        height: this.draggableDiv.nativeElement.clientHeight,
        width: this.draggableDiv.nativeElement.clientWidth
      }

      this.setTransform();
    }
  }

  //enviamos la posición final a casillas-dibujo a través del observable del servicio DatosConfiguracionDocuemntosService
  @HostListener('document:mouseup', ['$event']) onMouseUp(event: any) {
    this.isDragging = false;
    if (this.draggableIcon && (event.target === this.draggableIcon.nativeElement || event.target === this.draggableDiv.nativeElement || event.target === this.draggableHref.nativeElement)) {
      this.finalX = this.currentX + this.posicionCasilla.left;
      this.finalY = this.currentY + this.posicionCasilla.top;
      this.casillaPosition = {
        left: this.finalX,
        top: this.finalY,
        height: this.draggableDiv.nativeElement.clientHeight,
        width: this.draggableDiv.nativeElement.clientWidth
      }
      this.datosConfiguracionDocumentosService.casillaPosition = this.casillaPosition;
      if(this.datosConfiguracionDocumentosService.isDentro('casillas-temporal', this.casillaPosition)){

        this.casillaEnviar = {
          x: this.casillaPosition.left - 81,
          y: this.casillaPosition.top - 100,
          width: this.casillaPosition.width,
          height: this.casillaPosition.height,
          firmante: this.posicionCasilla.firmante,
          nombre: this.posicionCasilla.nombre,
          orden: this.posicionCasilla.orden
        }

        this.datosConfiguracionDocumentosService.transferirDatosCasilla(this.casillaEnviar)
      }
      this.posicionCasilla.mostrar = false;
    }
  }

  setTransform() {
    this.draggableDiv.nativeElement.style.transform = `translate3d(${this.currentX}px, ${this.currentY}px, 0)`;
  }

}
