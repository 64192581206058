import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit {

  @Input() elementos: string[] = [];
  @Input() stepActive: string = "";
  @Input() finalizarProceso: boolean = false;
  @Output() ocultoEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  oculto: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  clickOcultar(_oculto: boolean){
    this.oculto = _oculto;
    this.ocultoEventEmitter.emit(this.oculto);
  }

  getClass(index: number){
    if(this.finalizarProceso) return 'progess-complete';
    else if(index < this.elementos.indexOf(this.stepActive)) return 'progess-complete';
    else if (this.elementos.indexOf(this.stepActive)== index) return 'progess-active';
    else return 'start-progress';
  }

}
