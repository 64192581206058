<div class="main">
  <h2>Incluir contenido</h2>
<form [formGroup]="formularioDocumentoService.formularioDocumento">
  <div class="flex">
    <input id="clickBoxId" type="checkbox" formControlName="incluirContenido" (click)="getClickCheckbox()">
    <span>
      <svg class="icon-text">
        <use xlink:href="#icon-text-field"></use>
      </svg>
    </span>
    <label for="incluirContenido" style="font-weight: 500;">Incluir contenido específico</label>
  </div>
  <p>Antes realizar el envío es posible definir campos de texto que se autorellenen, mediante el uso de palabras clave, con contenido específico en función de, p.ej. el firmante principal, fecha actual, etc.</p>

  <div *ngIf="mostrarListaPalabras">
    <h6>Instrucciones</h6>
    <ol>
      <li>Arrastra las palabras clave al documento</li>
      <li>Emplázalas en el lugar deseado</li>
      <li>Ajusta la anchura del campo de texto</li>
    </ol>
  </div>
  <div class="flex" (click)="mostrarPalabrasClave()">
    <h6>Palabras clave</h6>
    <span >
      <svg class="icon-arrow" [ngClass]="mostrarListaPalabras ? 'icon-arrow-down':''">
        <use xlink:href="#icon-arrow"></use>
      </svg>
    </span>
  </div>
  <div *ngIf="mostrarListaPalabras" class="">
    <span class="alta">Fecha de alta</span>

      <app-contenido-doc *ngFor="let palabra of palabraMoverArray" [palabraClave]="palabra" [topPosition]="top" [leftPosition]="left"></app-contenido-doc>

      <app-configuracion-documento-palabra-clave *ngFor="let palabra of palabrasClave" [palabraClave]="palabra" (moverPalabra)="getPalabraAMover($event)"></app-configuracion-documento-palabra-clave>

  </div>
</form>

</div>

