<!-- <div (click)="clicked()"><img *ngIf="icono" src="{{icono}}"><span>{{titulo}}</span></div> -->
<button class="btn buttonSecondary" (click)="clicked()">
    <span class="btn-icon" *ngIf="icono">
        <svg [class]="icono">
            <use [attr.xlink:href]="icono|iconoRef"></use>
        </svg>
    </span>
    <span class="btn-text">{{titulo}}</span>
</button>
<!-- <button class="btn buttonSecondary" (click)="clicked()">
    <span class="btn-icon" *ngIf="icono">
        <svg [class]="icono">
            <use [attr.xlink:href]="icono|iconoRef"></use>
        </svg>
    </span>
    <span class="btn-text">{{titulo}}</span>
</button> -->
