import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BotonMenuComponent} from './components/boton-menu/boton-menu.component';
import { BotonResumenFirmasComponent } from './components/boton-resumen-firmas/boton-resumen-firmas.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component'
import { SharedModule } from 'src/app/shared/shared.module';
import { PanelControlModule } from '../panelControl/panel-control.module';
import { BotonMultipleComponent } from './components/boton-multiple/boton-multiple.component';
import { BotonUltimasFirmasComponent } from './components/boton-ultimas-firmas/boton-ultimas-firmas.component';
import { BotonDescargaManualComponent } from './components/boton-descarga-manual/boton-descarga-manual.component';

@NgModule({
  declarations: [
    BotonMenuComponent,
    BotonResumenFirmasComponent,
    MainMenuComponent,
    BotonMultipleComponent,
    BotonUltimasFirmasComponent,
    BotonDescargaManualComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PanelControlModule
  ],
  exports: [
    BotonMenuComponent
  ]
})
export class MenuPrincipalModule { }
