import { ColDef } from 'ag-grid-community';

export class GridColDefsSeguridadCentros{

  public columnDefs: ColDef[] = [
    { headerName: "Nombre del centro", field: "nombreCentro", sortable: true, filter: true, headerClass: "header", wrapText: true, autoHeight: true, checkboxSelection: true, width: 500,
    cellRenderer: params => {
      return `${params.data.nombreCentro} - ${params.data.nombreEmpresa}`
   }
  }
  ]

}
