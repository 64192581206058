<div class="flex">
  <input #inputAviso value={{numAviso}} type="number" min=1 max=60 [ngClass]="[formularioValidacionesService.isCampoValido('reminders_String') ? 'errorInput':'', formularioEnvioService.disableRecordatorio ? 'bg-transparent':'']" [readonly]="formularioEnvioService.disableRecordatorio" (keyup)="updateAviso(inputAviso.value)" (mouseup)="updateAviso(inputAviso.value)">
  <span class="eliminar" (click)="eliminarAviso()">
    <svg class="icon-close">
      <use xlink:href="#hs-bin"></use>
    </svg>
  </span>
</div>

<!-- <div>
  <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('reminders_String')">
    {{formularioValidacionesService.mensajeErrorRecordatorios}}
  </span>
</div> -->
