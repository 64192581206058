import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef } from 'ag-grid-community';
import { ConfirmationMessageComponent } from 'src/app/shared/components/modals/confirmation-message/confirmation-message.component';
import { DatosExcel } from 'src/app/shared/ExportadorFicheros/models/datos-excel';
import { DatosPdf } from 'src/app/shared/ExportadorFicheros/models/datos-pdf';
import { ExportadorExcelService } from 'src/app/shared/ExportadorFicheros/services/exportador-excel.service';
import { ExportadorPdfService } from 'src/app/shared/ExportadorFicheros/services/exportador-pdf.service';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';
import { FiltroBasico } from 'src/app/shared/models/filtros';
import { CentrosModel } from '../../models/centros-model';
import { GridColDefsCentros } from '../../models/grid-col-defs-centros';
import { DatosCentrosService } from '../../services/datos-centros.service';
import { CentrosBarraSuperiorComponent } from '../centros-barra-superior/centros-barra-superior.component';
import { CentrosModalComponent } from '../centros-modal/centros-modal.component';
import { EmpresasModel } from '../../../empresas/models/empresas-model';
import { RecuperaEmpresasApiService } from '../../../empresas/services/recupera-empresas-api.service';
import { RecuperaEmpresasMockService } from '../../../empresas/services/recupera-empresas-mock.service';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { SpinnerModalComponent } from 'src/app/shared/components/others/spinner-modal/spinner-modal.component';

@Component({
  selector: 'app-centros-page',
  templateUrl: './centros-page.component.html',
  styleUrls: ['./centros-page.component.css']
})
export class CentrosPageComponent implements OnInit {

  centros: CentrosModel[];
  empresas: EmpresasModel[]=[];
  filtros: FiltroBasico[] = [];
  columnDefs: ColDef[] = new GridColDefsCentros().columnDefs;
  @ViewChild(CentrosBarraSuperiorComponent) barrSuperior!: CentrosBarraSuperiorComponent;
  mostrarSubidaExcel: boolean = false;
  centrosSeleccionados: CentrosModel[] = [];
  textoBorrar: string = '';

  constructor(public datosCentrosService: DatosCentrosService,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog,
              private exportadorPdfService: ExportadorPdfService,
              private exportadorExcelService: ExportadorExcelService,
              private datosEmpresasApiService: RecuperaEmpresasApiService,
              private dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
    this.getCentros();
  }

  getCentros(){
    this.datosCentrosService.getCentros().subscribe(
      nextCentros => {
        this.datosEmpresasApiService.getEmpresas().subscribe(
          nextEmpresas => {
              nextCentros.forEach(x => {
                x.nombreEmpresa = nextEmpresas.find(y => x.codigoEmpresa == y.codigoEmpresa).nombreEmpresa;
              });
              this.centros = nextCentros;
              this.empresas = nextEmpresas;
              this.filtros.push({ text: '',textoBuscar: '',value: ''})
              this.mostrarSubidaExcel = false;
            },
            error => {
              this._snackBar.open(error, 'Cerrar',{duration: 3000});
            }
          )
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  refresh(){
    this.getCentros();
  }

  clickBarraSuperior(id: string){
    this.mostrarSubidaExcel = false;
    switch (id) {
      case 'Excel-download':
        this.exportaExcel();
        break;
      case 'Excel-upload':
        this.mostrarSubidaExcel = true;
        break;
      case "Pdf":
        this.exportaPdf();
        break;
      // case id.match(/(Buscar:.*)/)?.input:
      //   let textoBuscar = id.replace('Buscar:','');
      //   this.buscarCentros(textoBuscar);
      //   break;
      case "Plus":
        this.creaNuevoCentro();
        break;
      default:
        break;
    }
  }

  creaNuevoCentro(){
    let info: any = {};
    info.centro={
      codigoCentro:'',
      nombreCentro:'',
      emailCentro:'',
      // codigoEmpresa: this.centros[0].codigoEmpresa,
      codigoEmpresa: '',
      nombreEmpresa: ''
      // nombreEmpresa: this.centros[0].nombreEmpresa
    }
    info.nuevo = true;
    info.empresas = this.empresas;
    info.centros = this.centros;
    const dialogRef = this.dialog.open(CentrosModalComponent, {
      data: info,
      panelClass: 'modal-class-detalle',
      position: { right: '0'}
    });

    dialogRef.afterClosed().subscribe(result=>{
      if(result === 'OK'){
        this.refresh();

      }
    })
  }

  exportaPdf(){
    this.centros = this.datosCentrosService.centros;
    if(this.centros){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-pdf-empty',
          titulo: 'Generar PDF',
          mensaje: 'Genera un documento PDF con la información de los registros mostrados en el listado',
          textoConfirmacion: 'Generar PDF',
          iconoConfirmacion: 'icon-pdf-empty',
          textoCancelar: 'cancelar'
        }
      });

      //montamos el objeto a pasar al servicio con el modelo DatosPdf
      const datos = this.montarParametrosPdf()

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK') this.exportadorPdfService.exportaPdf(datos, this.centros);
      });
    }
  }

  montarParametrosPdf(): DatosPdf{
    let columns: any [] = [];
    this.columnDefs.forEach( x => {
      columns.push( {header: x.headerName, dataKey: x.field} )
    })
    let fecha = new Date();
    let datosPdf = {
      landscape: true,
      nombreFichero:`Hire&Sign_centros_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.pdf`,
      registros: columns
    }
    return datosPdf
  }

  exportaExcel(){
    this.centros = this.datosCentrosService.centros;
    if(this.centros){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-excel-empty',
          titulo: 'Generar excel',
          mensaje: 'Genera un documento Excel con la información de los registros mostrados en el listado',
          textoConfirmacion: 'Generar excel',
          iconoConfirmacion: 'icon-excel-empty',
          textoCancelar: 'cancelar'
        }
      });

      let rows = this.GetRows(this.centros);
      let datosExcel = this.montarParametrosExcel();

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK') this.exportadorExcelService.exportaExcel(datosExcel, rows);
      });
    }
  }

  montarParametrosExcel(): DatosExcel{
    let fecha = new Date();
    let datosExcel = {
      workSheetName:'Centros',
      nombreFichero:`Hire&Sign_centros_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.xlsx`,
      columnas: this.GetColumns()
    }
    return datosExcel
  }

  //Generamos las columnas basandonos en las del grid
  private GetColumns(): any[]{
    let columns:any[] = [];
    this.columnDefs.forEach( x => {
        let element:any = {};
        element.name = x.headerName;
        element.filterButton = true;
        element.width = x.width;
        columns.push(element);
    });
    return columns;
  }

  // Mapeamos los datos de los centros a las filas del excel
  private GetRows(data:CentrosModel[]):any[]{
    let rows:any[] = [];
    data.forEach(x=>{
      let row:any[] =
      [
        x.nombreCentro,
        x.emailCentro,
        x.codigoCentro,
        x.codigoEmpresa,
        x.nombreEmpresa,
        x.nombreRlt,
        x.dniRlt
      ];
      rows.push(row);
    });
    return rows
  }

  buscarCentros(textoBuscar:string){
    if(this.centros){
      this.filtros[0].textoBuscar = textoBuscar;
      this.filtros[0].text = 'Texto';
      this.filtros[0].value = textoBuscar;
      this.datosCentrosService.SetFilter(this.filtros);
      this.datosCentrosService.Filter();
      this.centros = this.datosCentrosService.centrosFiltrados;
    }
  }

  quitarFiltro(filtro: FiltroBasico){
    this.datosCentrosService.deleteFilter(filtro);
    this.datosCentrosService.Filter();
    this.centros = this.datosCentrosService.centrosFiltrados;
    this.barrSuperior.buscarTexto('');
  }

  abreModal(centro:any){
    let info: any = {};
    info.centro = centro.data;
    info.centros = this.centros;
    info.nuevo = false;
    const dialogRef = this.dialog.open(CentrosModalComponent, {
      data: info,
      panelClass: 'modal-class-detalle',
      position: { right: '0'}
    });

    dialogRef.afterClosed().subscribe(result=>{
      if(result === 'OK'){
        this.refresh();
      }
    })
  }

  seleccionCentros(event: CentrosModel[]){
    this.centrosSeleccionados = event;
    if(this.centrosSeleccionados.length > 1) this.textoBorrar = `${event.length} centros seleccionados`;
    else this.textoBorrar = `${event.length} centro seleccionado`;
  }

  deseleccionar: {count: number, uncheck: boolean}
  num: number = 1;

  deseleccionarAll(){
    this.deseleccionar = {count: this.num++, uncheck: true}
  }

  eliminarCentro(){
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: {
        tipo: TipoMensajeConfirmacion.ConfirmacionCompleja,
        icono: 'icon-delete',
        titulo: this.centrosSeleccionados.length>1? '¿Eliminar centros?':'¿Eliminar centro?',
        // preguntaConfirmacion: '¿Eliminar centro?',
        textoConfirmacion: this.centrosSeleccionados.length>1?'Eliminar los centros seleccionados':'Eliminar el centro seleccionado',
        textoBoton: 'Eliminar',
        iconoConfirmacion: 'icon-delete'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'OK'){
        const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
        let count: number = 0;
        this.centrosSeleccionados.forEach(x=>{
          this.datosCentrosService.deleteCentro(x).subscribe(
            next => {
              count++;
              if(this.centrosSeleccionados.length == count){
                dialogRefSpinner.close();
                const dialogRef = this.dialog.open(ModalMessageComponent, {
                  data: {
                    mensaje: this.centrosSeleccionados.length==1 ? 'Centro eliminado correctamente':'Centros eliminados correctamente',
                  },
                });
                // this.dialogRef.close('OK');
                this.datosCentrosService.refreshCentros = true;
                this.refresh();
              }

            },
            error => {
              dialogRefSpinner.close();
              console.error("error", error);
              const dialogRef = this.dialog.open(ModalMessageComponent, {
                data: {
                  mensaje: this.centrosSeleccionados.length==1 ? 'Error. El centro no se ha eliminado':'Error. Los centros no se han eliminado',
                  alert: true
                },
              });
              // this.dialogRef.close();
            }
          );
        })

      }
      //faltaría mostrar error si hay problemas en la llamada http, este no es el caso de aquí.
    })
  }
}
