import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { TipoEnvio } from "../../Envios/envios/models/tipo-envio";

export function formRltValidators(envios: TipoEnvio[]): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {

      const activo: boolean = form.get('activo').value;
      const dosier: string = form.get('dosier').value;
      const periodicidad: string = form.get('periodicidad').value;
      const diaEnvio: string = form.get('diaEnvio').value;
      const horaEnvio: string = form.get('horaEnvio').value;

      let errors:{
        oneDocRequired?: boolean,
        dosierRequired?: boolean,
        periodicidadRequired?: boolean,
        diaEnvioRequired?: boolean,
        horaEnvioRequired?: boolean} = {};

      if(activo){
        if(dosier == '' || dosier == null){
          errors.dosierRequired = true
        }else{

          envios.forEach(x=>{
            if(x.nombre == dosier && x.tiposDocumento?.length !== 1) errors.oneDocRequired = true
          })
        }
        if(periodicidad == '' || periodicidad == null){
          errors.periodicidadRequired = true
        }
        if(diaEnvio == '' || diaEnvio == null){
          errors.diaEnvioRequired = true
        }
        if(horaEnvio == '' || horaEnvio == null){
          errors.horaEnvioRequired = true
        }
        return Object.keys(errors).length === 0 ? null : errors;
        // return errors
      }else return null
  }
}
