import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../login/services/login.service';
import { Firmas } from '../models/firmas';
import { IGestionarFirmasService } from './ports/i-gestionar-firmas-service';

@Injectable({
  providedIn: 'root'
})
export class GestionaFirmasMockService implements IGestionarFirmasService{

  firmas: Firmas[] = [];

  constructor(private client: HttpClient,
              private loginService: LoginService ) { }

  GetFirmas(): Observable<void | any[]> {
    // let url = this.URL_LOGIN.replace('{todos}', 'true');
    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token,
      })
    };
    return this.client.get(`${environment.ApiFirmasMock}`, httpOptions).pipe(
      map((response: any)=>{

        return this.firmas = response.Data;
        })
      )
  }

  GetInfoDocs(index: number): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token,
      }),
    }

    let url = `${environment.ApiInfoDocsMock}`;
    // /${ this.firmas[index].idFirma}
    //CARGAMOS LOS DOCUMETNOS
    return this.client.get(url, httpOptions).pipe(
      map((response: any)=>{

      return  response.Data
    }))

  }

  GetInfoEventos(index: number): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token,
      }),
    }

    let url = `${environment.ApiInfoEventosMock}`

    return this.client.get(url, httpOptions).pipe(
      map((response: any)=>{

      return response.Data;
    }))
  }

  GetInfoTipoDocs(index: number): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token,
      }),
    }

    let url = `${environment.ApiInfoTipoDocsMock}`;

    return this.client.get(url, httpOptions).pipe(
      map((response: any)=>{

      return response.Data;
    }))
  }

  BorrarFirmas(ids: number[]): Observable<string> {
    let response = `Mock - Estoy eliminando las firmas con ids: ${ids}`

    return of(response)
  }

  BorrarFirma(idFirma: number): Observable<string> {
    let response = `Mock - Estoy eliminando la firma con id: ${idFirma}`

    return of(response)
  }

  CancelarFirma(idFirma: number): Observable<string> {
    let response = `Mock - Estoy cancelando la firma con id: ${idFirma}`

    return of(response)
  }

  DescargaFirmaCompleta(id: any): Observable<string> {
    let response = `Mock - Estoy descargando la firma con id: ${id}`

    return of(response)
  }

}
