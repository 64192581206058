import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

import { TiposContenido } from '../../models/tipos-contenido';

@Component({
  selector: 'app-configuracion-documento-palabra-clave',
  templateUrl: './configuracion-documento-palabra-clave.component.html',
  styleUrls: ['./configuracion-documento-palabra-clave.component.css']
})
export class ConfiguracionDocumentoPalabraClaveComponent implements OnInit {

  @Input() palabraClave: TiposContenido;

  @ViewChild('palabra') palabra: ElementRef;
  @ViewChild('iconUse') iconUse: ElementRef;
  @ViewChild('iconSvg') iconSvg: ElementRef;

  @Output() moverPalabra: EventEmitter<any> = new EventEmitter<any>()

  palabraPosition: { left: number, top: number, height: number, width: number };

  constructor() { }

  ngOnInit(): void {
  }

  checkPosicionPalabra(){
    const {left, top, height, width} = this.palabra.nativeElement.getBoundingClientRect();
    this.palabraPosition = {left, top, height, width};
    this.moverPalabra.emit({
        tipo: {id: this.palabraClave.id, nombre: this.palabraClave.nombre},
        x: this.palabraPosition.left,
        y: this.palabraPosition.top,
        width: this.palabraPosition.width,
        height: this.palabraPosition.height
    })
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: any) {
    if (event.target === this.iconUse.nativeElement || event.target === this.palabra.nativeElement || event.target === this.iconSvg.nativeElement){
      this.checkPosicionPalabra()
    }
  }
}
