import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuarioModel } from '../../models/usuario-model';
import { DatosUsuariosService } from '../../services/datos-usuarios.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InvitacionUsuarioModalComponent } from 'src/app/pages/login/components/invitacion-usuario-modal/invitacion-usuario-modal.component';
import { FiltroBasico } from 'src/app/shared/models/filtros';
import { GridColDefsUsuarios } from '../../models/grid-col-defs-usuarios';
import { ColDef } from 'ag-grid-community';
import { UsuariosModalComponent } from '../usuarios-modal/usuarios-modal.component';

@Component({
  selector: 'app-usuarios-page',
  templateUrl: './usuarios-page.component.html',
  styleUrls: ['./usuarios-page.component.css']
})
export class UsuariosPageComponent implements OnInit {

  usuarios: UsuarioModel[];
  estadoSeleccionado: string;
  usuariosFiltrados: UsuarioModel[];
  filtros: FiltroBasico[] = [];
  columnDefs: ColDef[] = new GridColDefsUsuarios().columnDefs;
  invitacionUsuario: boolean = false;

  constructor(private datosUsuariosService: DatosUsuariosService,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog) {
                this.datosUsuariosService.getUsuariosTipo();
              }

  ngOnInit(): void {

    // this.datosUsuariosService.getEstadosUsuario().subscribe(
    //   next => {

    //   }
    // )
    this.datosUsuariosService.getUsuariosTipo().subscribe(
      next => {
      }
    )
    this.getUsuarios();
  }

  getUsuarios(){
    this.datosUsuariosService.getUsuarios().subscribe(
      next => {
        this.usuarios = next;
        this.usuariosFiltrados = next;
        this.filtros.push({ text: '',
                            textoBuscar: '',
                            value: ''
                            })
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  invitarUsuario(){
    this.invitacionUsuario = true;
    let usuarioDataNuevo = {
      data:{
            nombre:'',
            apellido1: '',
            email:'',
            rol:'',
            tipoSeguridad:'',
            usuarioActivo:false
          }
    }
    this.abreModal(usuarioDataNuevo)
    // const dialogRef = this.dialog.open(InvitacionUsuarioModalComponent, {
    //   data: {},
    //   panelClass: 'modal-class-detalle',
    //   // position: {}
    // });

    // dialogRef.afterClosed().subscribe(result=>{
    //   if(result === 'OK'){
    //     this.refreshUsuarios(true);
    //   }
    // })

  }

  filterUsuarios(event:string){
    this.estadoSeleccionado = event;
    if(this.estadoSeleccionado == 'Activo') {
      this.usuariosFiltrados = this.usuarios.filter(x=> x.usuarioActivo)
    }
    if(this.estadoSeleccionado == 'Desactivado'){

      this.usuariosFiltrados = this.usuarios.filter(x=> (!x.usuarioActivo && x.invitacionAceptada && x.invitacionEnviada) || (!x.usuarioActivo && !x.invitacionAceptada && !x.invitacionEnviada))
    }
    if(this.estadoSeleccionado == 'Pendiente activación'){
      this.usuariosFiltrados = this.usuarios.filter(x=> !x.usuarioActivo && x.invitacionEnviada && !x.invitacionAceptada)
    }
    if(this.estadoSeleccionado == 'Todos') this.usuariosFiltrados = this.usuarios
  }

  refreshUsuarios(event:boolean){
    this.datosUsuariosService.refreshUsuarios = true;
    if(event) {
      this.getUsuarios();
    }
  }

  buscarUsuarios(textoBuscar:string){
    if(this.usuariosFiltrados){
      this.filtros[0].textoBuscar = textoBuscar;
      this.filtros[0].text = 'Texto';
      this.filtros[0].value = textoBuscar;
      this.datosUsuariosService.SetFilter(this.filtros);
      this.datosUsuariosService.Filter();
      this.usuariosFiltrados = this.datosUsuariosService.usuariosFiltrados;
    }
  }

  abreModal(usuario:any){
    let dataEnviarModal:any = {
      datosUsuario: usuario.data,
      nuevo: this.invitacionUsuario
    }
    const dialogRef = this.dialog.open(UsuariosModalComponent, {
      data: dataEnviarModal,
      panelClass: 'modal-class-detalle',
      position: { right: '0'}
    });

    this.invitacionUsuario = false;

    dialogRef.afterClosed().subscribe(result=>{
      if(result === 'OK'){
        this.refreshUsuarios(true);
      }
    })
  }
}
