
// Components
import { BarraSuperiorComponent } from './components/layouts/barra-superior/barra-superior.component';
import { MenuLateralComponent } from './components/layouts/menu-lateral/menu-lateral.component';
import { StandarLayoutComponent } from './components/layouts/standar-layout/standar-layout.component';
import { TituloFiltroComponent } from './components/layouts/titulo-filtro/titulo-filtro.component';
import { SubtituloComponent } from './components/layouts/subtitulo/subtitulo.component';
import { BotonOcultarComponent } from './components/botones/boton-ocultar/boton-ocultar.component';
import { BotonResumenComponent } from './components/botones/boton-resumen/boton-resumen.component';
import { TituloOpcionComponent } from './components/layouts/titulo-opcion/titulo-opcion.component';
import { IconoSuperiorComponent } from './components/selectores/icono-superior/icono-superior.component';
import { BotonCancelarComponent } from './components/botones/boton-cancelar/boton-cancelar.component';
import { ConfirmationMessageComponent } from './components/modals/confirmation-message/confirmation-message.component';
import { BotonAccionComponent } from './components/botones/boton-accion/boton-accion.component';
import { SelectorFechaDesdeHastaComponent } from './components/selectores/selector-fecha-desde-hasta/selector-fecha-desde-hasta.component';
import { ListaDesplegableComponent } from './components/selectores/lista-desplegable/lista-desplegable.component';
import { DesplegableDataComponent } from './models/desplegable-data/desplegable-data.component';
import { BuscadorComponent } from './models/buscador/buscador.component';
import { SelectorComponent } from './components/selectores/selector/selector.component';
import { BreadCrumbsComponent } from './components/selectores/bread-crumbs/bread-crumbs.component';
import { BotonAccionSecundariaComponent } from './components/botones/boton-accion-secundaria/boton-accion-secundaria.component';
import { BarraInfoComponent } from './components/layouts/barra-info/barra-info.component';
import { AlertaAccionIrreversibleComponent } from './components/modals/alerta-accion-irreversible/alerta-accion-irreversible.component';
import { ModalMessageComponent } from './components/modals/modal-message/modal-message.component';
import { InputBuscadorComponent } from './components/selectores/input-buscador/input-buscador.component';
import { ProgressComponent } from './components/others/progress/progress.component';
import { UploadFileComponent } from './components/others/upload-file/upload-file.component';
import { UploadFileDirective } from './directives/upload-file.directive';
import { UploadFileProcessingComponent } from './components/others/upload-file-processing/upload-file-processing.component';
import { BarraFooterComponent } from './components/layouts/barra-footer/barra-footer.component';
import { SpinnerModalComponent } from './components/others/spinner-modal/spinner-modal.component';

// Pipes
import { EstadosFirmaIconoPipe } from './pipes/estados-firma-icono.pipe';
import { IconoRefPipe } from './pipes/icono-ref.pipe';

// Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from '../../app/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { environment } from 'src/environments/environment';

//Services

import { RecuperaTipoDocumentoMockService } from '../pages/configuracion/Envios/documentos/services/recupera-tipo-documento-mock.service';
import { RecuperaCentrosApiService } from '../pages/configuracion/maestros/centros/services/recupera-centros-api.service';
import { RecuperaEmpresasApiService } from '../pages/configuracion/maestros/empresas/services/recupera-empresas-api.service';
import { GestionaEmpleadosApiService } from '../pages/empleados/services/gestiona-empleados-api.service';
import { GestionaEmpleadosMockService } from '../pages/empleados/services/gestiona-empleados-mock.service';
import { RecuperaEmpresasMockService } from '../pages/configuracion/maestros/empresas/services/recupera-empresas-mock.service';
import { RecuperaCentrosMockService } from '../pages/configuracion/maestros/centros/services/recupera-centros-mock.service';
import { RecuperaTipoDocumentoApiService } from '../pages/configuracion/Envios/documentos/services/recupera-tipo-documento-api.service';
import { RecuperaTipoEnvioMockService } from '../pages/configuracion/Envios/envios/services/recupera-tipo-envio-mock.service';
import { RecuperaTipoEnvioApiService } from '../pages/configuracion/Envios/envios/services/recupera-tipo-envio-api.service';
import { GestionaFirmasMockService } from '../pages/panelControl/services/gestiona-firmas-mock.service';
import { GestionaFirmasApiService } from '../pages/panelControl/services/gestiona-firmas-api.service';
import { GestionaDatosSubidaApiService } from '../pages/subidaPrincipal/services/gestiona-datos-subida-api.service';
import { GestionaDatosSubidaMockService } from '../pages/subidaPrincipal/services/gestiona-datos-subida-mock.service';
import { DocumentoPdfConfiguracionMockService } from '../pages/configuracion/Envios/documentos/services/documento-pdf-configuracion-mock.service';
import { DocumentoPdfConfiguracionApiService } from '../pages/configuracion/Envios/documentos/services/documento-pdf-configuracion-api.service';
import { RecuperaTiposContenidoMockService } from '../pages/configuracion/Envios/documentos/services/recupera-tipos-contenido-mock.service';
import { RecuperaTiposContenidoApiService } from '../pages/configuracion/Envios/documentos/services/recupera-tipos-contenido-api.service';
import { RecuperaUsuariosMockService } from '../pages/seguridad/services/recupera-usuarios-mock.service';
import { RecuperaUsuariosApiService } from '../pages/seguridad/services/recupera-usuarios-api.service';
import { TiposConfiguracion } from 'src/environments/Configuration/TiposConfiguracion';
import { InyeccionDependenciasFactory } from './services/inyeccion-dependencias-factory';
import { UploadFileDatosDocumentoComponent } from './components/others/upload-file-datos-documento/upload-file-datos-documento.component';
import { UsuarioActivoSelectorComponent } from './components/selectores/usuario-activo-selector/usuario-activo-selector.component';
import { IndicadorNumeroComponent } from './components/others/indicador-numero/indicador-numero.component';
import { BotonAccesoDirectoComponent } from './components/botones/boton-acceso-directo/boton-acceso-directo.component';
import { BotonAccesoComponent } from './components/botones/boton-acceso/boton-acceso.component';
import { DropDownMultiseleccionComponent } from './components/selectores/drop-down-multiseleccion/drop-down-multiseleccion.component';
import { IndicadorPastillaFiltroComponent } from './components/selectores/indicador-pastilla-filtro/indicador-pastilla-filtro.component';
import { CalendarioRangoComponent } from './components/selectores/calendario-rango/calendario-rango.component';
import { PastillaSeleccionMultipleComponent } from './components/selectores/pastilla-seleccion-multiple/pastilla-seleccion-multiple.component';
import { SelectorFechaComponent } from './components/selectores/selector-fecha/selector-fecha.component';

const MaterialComponets = [
  MatDialogModule,
  MatCheckboxModule,
  MatButtonModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatSelectModule,
  MatExpansionModule,
  MatStepperModule,
  MatIconModule,
  MatRadioModule,
  MatTooltipModule
];

@NgModule({
  declarations: [
    BarraSuperiorComponent,
    MenuLateralComponent,
    StandarLayoutComponent,
    TituloFiltroComponent,
    SubtituloComponent,
    BotonOcultarComponent,
    BotonResumenComponent,
    TituloOpcionComponent,
    IconoSuperiorComponent,
    BotonCancelarComponent,
    ConfirmationMessageComponent,
    BotonAccionComponent,
    BotonAccionSecundariaComponent,
    SelectorFechaDesdeHastaComponent,
    ListaDesplegableComponent,
    DesplegableDataComponent,
    BuscadorComponent,
    SelectorComponent,
    BreadCrumbsComponent,
    EstadosFirmaIconoPipe,
    IconoRefPipe,
    BarraInfoComponent,
    AlertaAccionIrreversibleComponent,
    ProgressComponent,
    ModalMessageComponent,
    InputBuscadorComponent,
    BarraFooterComponent,
    UploadFileComponent,
    UploadFileDirective,
    UploadFileProcessingComponent,
    SpinnerModalComponent,
    UploadFileDatosDocumentoComponent,
    UsuarioActivoSelectorComponent,
    IndicadorNumeroComponent,
    BotonAccesoDirectoComponent,
    BotonAccesoComponent,
    DropDownMultiseleccionComponent,
    IndicadorPastillaFiltroComponent,
    CalendarioRangoComponent,
    PastillaSeleccionMultipleComponent,
    SelectorFechaComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    PdfViewerModule,
    MaterialComponets
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialComponets,
    BotonOcultarComponent,
    BotonResumenComponent,
    TituloFiltroComponent,
    SubtituloComponent,
    TituloOpcionComponent,
    IconoSuperiorComponent,
    SelectorComponent,
    BotonAccionComponent,
    BotonAccionSecundariaComponent,
    BotonCancelarComponent,
    ConfirmationMessageComponent,
    SelectorFechaDesdeHastaComponent,
    ListaDesplegableComponent,
    EstadosFirmaIconoPipe,
    IconoRefPipe,
    BarraInfoComponent,
    AlertaAccionIrreversibleComponent,
    ProgressComponent,
    ModalMessageComponent,
    InputBuscadorComponent,
    BarraFooterComponent,
    UploadFileComponent,
    UploadFileDirective,
    UploadFileProcessingComponent,
    PdfViewerModule,
    SpinnerModalComponent,
    UploadFileDatosDocumentoComponent,
    IndicadorNumeroComponent,
    BotonAccesoDirectoComponent,
    BotonAccesoComponent,
    DropDownMultiseleccionComponent,
    IndicadorPastillaFiltroComponent,
    CalendarioRangoComponent,
    PastillaSeleccionMultipleComponent,
    SelectorFechaComponent
  ],
  providers: [
  //   // SI PONEMOS EL FORMATO dd/MM/yyyy el input manual de las fechas devuelve formato incorrecto
  //   {
  //     provide: MAT_DATE_LOCALE,
  //     useValue: 'es-ES'
  //   }
  //
    // {
    //   provide: MAT_RADIO_DEFAULT_OPTIONS,
    //   useValue: { color: 'primary' },
    // },
    {
      provide:'RecuperaTipoEnvio',
      useClass:InyeccionDependenciasFactory.GetRecuperaTipoEnvio
    },
    // {
    //   provide:'RecuperaTipoEnvio',
    //   useClass: RecuperaTipoEnvioApiService
    // },
    {
      provide: 'RecuperaTipoDocumento',
      useClass: InyeccionDependenciasFactory.GetRecuperaTipoDocumento
    },
    {
      provide: 'RecuperaCentros',
      useClass: InyeccionDependenciasFactory.GetRecuperaCentros
    },
    {
      provide: 'RecuperaEmpresas',
      useClass: InyeccionDependenciasFactory.GetRecuperaEmpresas
    },
    {
      provide: 'GestionaEmpleados',
      useClass: InyeccionDependenciasFactory.GestionaEmpleados
    },
    // {
    //   provide: 'GestionaEmpleados',
    //   useClass: GestionaEmpleadosApiService
    // },
    {
      provide: 'GestionaFirmas',
      useClass: InyeccionDependenciasFactory.GetGestionaFirmas
    },
    // {
    //   provide: 'GestionaFirmas',
    //   useClass: GestionaFirmasApiService
    // },
    {
      provide: 'GestionaDatosSubida',
      useClass: InyeccionDependenciasFactory.GetGestionaDatosSubida
    },
    // {
    //   provide: 'GestionaDatosSubida',
    //   useClass: GestionaDatosSubidaApiService
    // },
    {
      provide: 'DocumentoPdfConfiguracion',
      useClass: InyeccionDependenciasFactory.GetDocumentoPdfConfiguracion
    },
    {
      provide: 'RecuperaTiposContenido',
      useClass: InyeccionDependenciasFactory.GetRecuperaTiposContenido
    },
    {
      provide: 'RecuperaUsuarios',
      useClass: InyeccionDependenciasFactory.GetRecuperaUsuarios
    },
    {
      provide:'ListaEndpints',
      useClass: InyeccionDependenciasFactory.GetListaEndpints
    },
    {
      provide:'RecuperaConfiguracionAplicacion',
      useClass: InyeccionDependenciasFactory.GetRecuperaConfiguracionAplicacion
    },
    {
      provide:'RecuperaEmpleados',
      useClass: InyeccionDependenciasFactory.GetRecuperaEmpleados
    }

  ]
})
export class SharedModule {
}
