import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { DatosSubidaService } from 'src/app/pages/subidaPrincipal/services/datos-subida.service';


@Component({
  selector: 'app-procesamiento',
  templateUrl: './procesamiento.component.html',
  styleUrls: ['./procesamiento.component.css']
})
export class ProcesamientoComponent implements OnInit, OnDestroy {

  api: any;
  apiCol:any;
  @Input() errorSubida: boolean = false;
  @Input() porcentajeSubida: string = "0%";
  hayErrores: boolean = false;
  noErrores: boolean = false;

  infoLogSuscription: Subscription;
  subidaFinalizada: boolean = false;
  datosSubidaInicial: any[] = [];
  verNuevasParam: boolean = false;
  @Input() isSandbox: boolean = false;


  rowClassRules = {
    'ag-bg-rosa': function(params:any) { return params.data.estado === 'Error' }
  }

  constructor(public datosSubidaService: DatosSubidaService) {
    this.getInfoLog();
  }

  ngOnInit(): void {
  }

  cargaApi(arg: any){
    this.api = arg.api;
    this.apiCol = arg.columnApi;
    this.api.sizeColumnsToFit(arg);
    this.datosSubidaInicial = [...this.datosSubidaService.datosProcesoServidor.dataLogSubida]
  }

  getInfoLog(){
    this.infoLogSuscription = this.datosSubidaService.datosLog$.subscribe(value => {

      // if(value.subida && value.subida.estado=='Error'){

      //   this.noErrores = false;
      //   this.hayErrores = true;
      // }else{
      //   if(value.subida.finalizado){
      //     this.noErrores = true;
      //     // this.hayErrores = false;
      //   }
      // }
      if(value.subida && value.subida.finalizado){
        this.subidaFinalizada = value.subida.finalizado
        if(value.subida.estado=='Error') this.hayErrores = true;
        else this.noErrores = true;
      }
      // if(value.subida && value.subida.estado=='Error'){

      //   this.noErrores = false;
      //   this.hayErrores = true;
      // }else{
      //   if(value.subida.finalizado){
      //     this.noErrores = true;
      //     // this.hayErrores = false;
      //   }
      // }
      // if(value.subida){

      //   this.subidaFinalizada = value.subida.finalizado
      // }
    })
  }

  ngOnDestroy(){
    this.datosSubidaService.infoLog$.next({});
  }

  seleccionFiltroChange(mostrarErrores: boolean){
    if(mostrarErrores) this.datosSubidaService.datosProcesoServidor.dataLogSubida = this.datosSubidaInicial.filter(x => x.estado=='Error');
    else this.datosSubidaService.datosProcesoServidor.dataLogSubida = this.datosSubidaInicial;
  }
}
