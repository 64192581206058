import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Firmantes } from '../models/firmantes';
import { TipoDocumento } from '../../documentos/models/tipo-documento';
import { TipoEnvio, TipoEnvioClass } from '../models/tipo-envio';
import { DatosEnviosService } from './datos-envios.service';
import { requiredIfRecordatorioSelected, validacionNombre, validacionTipoDocs } from './customValidators';
import { TipoMail } from '../models/tipo-mail';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class FormularioEnvioService {

  public formularioEnvio: FormGroup = new FormGroup({});
  firmante = {} as Firmantes;
  envio!: TipoEnvio
  // disableAdjuntos: boolean = true;
  disableRecordatorio: boolean = true;
  // disableProgramado: boolean = true;
  // disableSubject: boolean = true;
  // disableBody: boolean = true;
  documento = {} as TipoDocumento;
  objetoEnvio: any;
  enviosFiltrados: TipoEnvio[] = [];
  recordatorios: number[] = [null];
  firmantesInit: Firmantes[] = [
    {
      orden:1,
      tipoFirmante:{
        codigo:"100",
        desc:"Firmante principal"
        },
      tipoDestinatario:{
        codigo:"",
        desc:""
      },
      tipoBusqueda:{
        codigo:"",
        desc:""
      }
    }
  ]
  documentosArrayInit: TipoDocumento[] =[
    {
      // alturaTagFirma: null,
      // anchuraTagFirma: null,
      // campoNifEmpleado: '',
      // campoNifEmpresa: '',
      cifOrden: null,
      cifPage: null,
      // cifRegex: '',
      // esCopiaBasica: false,
      // esFirmaTag: false,
      // formatoNombreArchivoFirmado: '',
      // formatoNombreAuditTrail: '',
      fusionable: false,
      generico: false,
      idTipoDocumento: null,
      // nifOrden: null,
      // nifPage: null,
      // nifRegex: '',
      nombre: '',
      // numFirmantes: null,
      ordenFusion: null,
      // sel: false,
      // splitString: '',
      // tag: '',
      texto: '',
      textoAExcluir: '',
      // ubicacion: '',
      // nombreEnvio: '',
      paginas: null,
      estado: "Incompleto",
      idTipoDocOrigen: null,
      incluirContenido: false,
      tiposContenido: [],
      firmantesNif: [],
      idPdf:'',
      esCopiaBasica:false,
      split:'',
      formatoNombreArchivoAuditTrail:'',
      formatoNombreArchivoFirmado:'',
      esRLT:false
    }
  ]

  formEnvioTemporal: FormGroup = new FormGroup({});
  tiposMails: TipoMail[]=[];

constructor(public fb: FormBuilder,
            private datosEnvioService: DatosEnviosService,
            private _snackBar: MatSnackBar) {

            this.montarFormularioVacio();
            this.getTipoMail();
}

montarFormularioVacio(){
  let formArrayEnvioInit = this.setExistingFirmantes(this.firmantesInit)
  // let formArrayDocumento = this.setExistingDocumentos(this.documentosArrayInit)
  this.formularioEnvio = this.fb.group({
    activo: false,
    idV3TipoEnvio: [''],
    idTipoEnvio: [''],
    nombre: [''],
    firmantes: formArrayEnvioInit,
    mailPrincipal: [''],
    subject:[''],
    // defectoSubject: [true],
    body: [''],
    // defectoBody: [true],
    tiposDocumento: this.fb.array([]),
    // solicitarAdjunto: [false],
    // numeroAdjuntos: [null],
    recordatorio: [false],
    reminders_String: [null],
    envioMasivo: [false],
    // programado: [false],
    // documentosEnvioProgramado: [null],
    esRLT: [false]
  })
}

//al cargar el formulario miramos si es un formulario de tipo de envío nuevo con id=0 o una modificación de tipo de envío
async inicializaFormulario(envio: TipoEnvio){
    await this.getTipoEnvios();
    this.envio = envio;
    let formArrayEnvio = this.setExistingFirmantes(envio.firmantes);
    this.isReadOnly();
    if(envio.idTipoEnvio == ""){
      this.formularioEnvio = this.fb.group({
        activo: false,
        idV3TipoEnvio:[''],
        idTipoEnvio:[''],
        nombre: ['', [validacionNombre(this.enviosFiltrados, envio), Validators.pattern(/^[^'"/\\]*$/)]],
        firmantes: formArrayEnvio,
        mailPrincipal: ['', Validators.required],
        subject:[''],
        // defectoSubject: [true],
        body: [''],
        // defectoBody: [true],
        tiposDocumento: this.fb.array([], validacionTipoDocs(this.envio)),
        // solicitarAdjunto: [false],
        // numeroAdjuntos: [null, requiredIfAdjuntoSelected(() => this.solicitarAdjunto)],
        recordatorio: [false],
        reminders_String: [null],
        // reminders_String: [null, requiredIfRecordatorioSelected(() => this.recordatorio)],
        envioMasivo: [false],
        // programado: [false],
        // documentosEnvioProgramado: [null, requiredIfProgramadoSelected(() => this.programado)],
        //estado:[''],
        esRLT: [false]
      })
    }else{
      let formArrayDocumento = this.setExistingDocumentos(envio.tiposDocumento)
      this.formularioEnvio = this.fb.group({
        activo:[envio.activo],
        idV3TipoEnvio:[envio.idV3TipoEnvio],
        idTipoEnvio:[envio.idTipoEnvio],
        nombre: [envio.nombre, [validacionNombre(this.enviosFiltrados, envio), Validators.pattern(/^[^'"/\\]*$/)]],
        firmantes: formArrayEnvio,
        mailPrincipal: [envio.mailPrincipal.codigo, Validators.required],
        subject: [envio.subject],
        // defectoSubject: [this.isDefecto(envio.subject)],
        body: [envio.body],
        // defectoBody: [this.isDefecto(envio.body)],
        tiposDocumento: formArrayDocumento,
        // solicitarAdjunto: [envio.solicitarAdjunto],
        // numeroAdjuntos: [envio.numeroAdjuntos, requiredIfAdjuntoSelected(() => this.solicitarAdjunto)],
        recordatorio: [envio.recordatorio],
        reminders_String: [envio.reminders_String],
        // reminders_String: [envio.reminders_String, requiredIfRecordatorioSelected(() => this.recordatorio)],
        envioMasivo:[envio.envioMasivo],
        // programado: [envio.programado],
        // documentosEnvioProgramado: [envio.documentosEnvioProgramado, requiredIfProgramadoSelected(() => this.programado)],
        estado: [envio.estado],
        esRLT:[envio.esRLT]
      })
      if (envio.reminders_String?.length>0) this.disableRecordatorio=false;
      this.envioAEditar = {...this.formularioEnvio.value};
      this.envioAEditar.mailPrincipal = this.buscarMailDescripcion();
    }
    this.getAvisos(); //montamos el array de avisos de firma

  }

  //hacemos los campos asociados readOnly si los defectos y los ajustes están o no clicados
  isReadOnly(){
    // this.envio.solicitarAdjunto == true ? this.disableAdjuntos = false : this.disableAdjuntos = true;
    this.envio.recordatorio == true ? this.disableRecordatorio = false : this.disableRecordatorio = true ;
    // this.envio.caduca == true ? this.disableCaducidad = false : this.disableCaducidad = true;
    // this.envio.programado == true ? this.disableProgramado = false : this.disableProgramado = true;
    // this.envio.subject == '' || this.envio.subject == null ? this.disableSubject = true : this.disableSubject = false;
    // this.envio.body == '' || this.envio.body == null ? this.disableBody = true : this.disableBody = false;
  }

  //marcamos los checboxes de defecto si los campos asociados están vacios. Los checkboxes de defecto no vienen en la api.
  // isDefecto(campo: string):boolean{
  //   if(campo == '') { return true }
  //   if(campo == null || campo !=='') { return false }
  //   else return true
  // }

  //getters de los campos del formulario
  get firmantes(){ return this.formularioEnvio.get('firmantes') as FormArray }
  get tiposDocumento(){ return this.formularioEnvio.get('tiposDocumento') as FormArray }
  // get defectoSubject(){ return this.formularioEnvio.get('defectoSubject').value }
  // get defectoBody(){ return this.formularioEnvio.get('defectoBody').value }
  // get solicitarAdjunto(){ return this.formularioEnvio.get('solicitarAdjunto').value }
  get recordatorio(){ return this.formularioEnvio.get('recordatorio').value }
  get reminders_String(){ return this.formularioEnvio.get('reminders_String').value}
  // get programado(){ return this.formularioEnvio.get('programado').value }

  //convertimos el firmantesArrayForm en un array de firmantes
  getFirmantes(): Firmantes[]{
    let arrayFirmantes: Firmantes[] = []
    this.firmantes.value.forEach((x: Firmantes) => {
      this.firmante = {
        orden: x.orden,
        tipoFirmante: {codigo: x.tipoFirmante.codigo, desc: x.tipoFirmante.desc},
        tipoDestinatario: {codigo: x.tipoDestinatario.codigo, desc: x.tipoDestinatario.desc},
        tipoBusqueda: {codigo: x.tipoBusqueda.codigo, desc: x.tipoBusqueda.desc}
      }
      arrayFirmantes.push(this.firmante)
    })
    return arrayFirmantes
  }

  //actualizamos un firmante del firmanteArayForm y convertimos el firmantesArrayForm actualizado en un array de Firmantes
  updateFirmantes(firmante: Firmantes){
    this.firmantes.at(firmante.orden-1).setValue(firmante)
    return this.getFirmantes()
  }

  //actualizamos el orden del firmantesArrayForm con el nuevo orden del array totalFirmantes que viene del componente y convertimos el firmantesArrayForm actualizado en un array de Firmantes
  updateOrdenInForm(totalFirmantes: Firmantes[]){
    this.firmantes.setValue(totalFirmantes);
    return this.getFirmantes()
  }

  //añadimos un firmante nuevo y convertimos el firmantesArrayForm actualizado en un array de Firmantes
  addFirmante(firmante: Firmantes){
    const firmanteInForm = this.fb.group({
      orden: [firmante.orden],
      tipoFirmante: this.fb.group({
        codigo:[firmante.tipoFirmante.codigo],
        desc:[firmante.tipoFirmante.desc]
      }),
      tipoDestinatario: this.fb.group({
        codigo:[firmante.tipoDestinatario.codigo],
        desc:[firmante.tipoDestinatario.desc]
      }),
      tipoBusqueda: this.fb.group({
        codigo:[firmante.tipoBusqueda.codigo],
        desc:[firmante.tipoBusqueda.desc]
      })
    })
    this.firmantes.push(firmanteInForm)
    return this.getFirmantes();
  }

  //borramos un firmante con el index, reordenamos los firmantes restantes y convertimos el firmantesArrayForm actualizado en un array de Firmantes
  deleteFirmante(index: number){
    this.firmantes.removeAt(index);
    this.reordenaFirmantes();
    return this.getFirmantes();
  }

  //actualizamos el orden del firmantesArrayForm con los nuevos índices y convertimos el firmantesArrayForm actualizado en un array de Firmantes
  reordenaFirmantes(){
    this.firmantes.value.map((x:Firmantes) => x.orden = this.firmantes.value.indexOf(x)+1)
    return this.getFirmantes();
  }

  //creamos un nuevo form array con los firmantes que tenemos en el objeto tipo de envío de la api
  setExistingFirmantes(firmantesExistentes: Firmantes[]): FormArray{
    const formArray = new FormArray([]);
    if(firmantesExistentes){
      firmantesExistentes.forEach(x => formArray.push(this.fb.group({
        orden: [x.orden],
        tipoFirmante: this.fb.group({
          codigo:[x.tipoFirmante.codigo],
          desc:[x.tipoFirmante.desc]
        }),
        tipoDestinatario: this.fb.group({
          codigo:[x.tipoDestinatario.codigo],
          desc:[x.tipoDestinatario.desc]
        }),
        tipoBusqueda: this.fb.group({
          codigo:[x.tipoBusqueda.codigo],
          desc:[x.tipoBusqueda.desc]
        })
      })))
    }

    return formArray
  }

  //creamos un nuevo form array con los documentos que tenemos en el objeto tipo de envío de la api
  setExistingDocumentos(documentosExistentes: TipoDocumento[]):FormArray{
    const formArrayDocs = new FormArray([], validacionTipoDocs(this.envio));
    if(documentosExistentes){
      documentosExistentes.forEach(x=> formArrayDocs.push(this.fb.group({
        // alturaTagFirma:[x.alturaTagFirma],
        // anchuraTagFirma:[x.anchuraTagFirma],
        // campoNifEmpleado:[x.campoNifEmpleado],
        // campoNifEmpresa:[x.campoNifEmpresa],
        cifOrden:[x.cifOrden],
        cifPage:[x.cifPage],
        // cifRegex:[x.cifRegex],
        // esCopiaBasica:[x.esCopiaBasica],
        // esFirmaTag:[x.esFirmaTag],
        // formatoNombreArchivoFirmado:[x.formatoNombreArchivoFirmado],
        // formatoNombreAuditTrail:[x.formatoNombreAuditTrail],
        fusionable:[x.fusionable],
        generico:[x.generico],
        idTipoDocumento:[x.idTipoDocumento],
        idTipoDocOrigen: [x.idTipoDocOrigen],
        // nifOrden:[x.nifOrden],
        // nifPage:[x.nifPage],
        // nifRegex:[x.nifRegex],
        nombre:[x.nombre],
        // numFirmantes:[x.numFirmantes],
        ordenFusion:[x.ordenFusion],
        // sel:[x.sel],
        // splitString:[x.splitString],
        // tag:[x.tag],
        texto:[x.texto],
        textoAExcluir:[x.textoAExcluir],
        // ubicacion:[x.ubicacion],
        // nombreEnvio:[x.nombreEnvio],
        paginas:[x.paginas],
        estado:[x.estado],
        firmantesNif: [x.firmantesNif],
        incluirContenido: [x.incluirContenido],
        tiposContenido: [x.tiposContenido],
        idPdf:[x.idPdf],
        esCopiaBasica:[x.esCopiaBasica],
        split:[x.split],
        formatoNombreArchivoAuditTrail:[x.formatoNombreArchivoAuditTrail],
        formatoNombreArchivoFirmado:[x.formatoNombreArchivoFirmado],
        esRLT:[x.esRLT]
      })))
    }

    return formArrayDocs
  }

  //convertimos el tiposDocumento en un array de tipo documento
  getDocumentos(): TipoDocumento[]{
    let arrayDocumentos: TipoDocumento[] = []
    this.tiposDocumento.value.forEach((x: TipoDocumento) => {
      this.documento = {
        // alturaTagFirma: x.alturaTagFirma,
        // anchuraTagFirma: x.anchuraTagFirma,
        // campoNifEmpleado: x.campoNifEmpleado,
        // campoNifEmpresa: x.campoNifEmpresa,
        cifOrden: x.cifOrden,
        cifPage: x.cifPage,
        // cifRegex: x.cifRegex,
        // esCopiaBasica: x.esCopiaBasica,
        // esFirmaTag: x.esFirmaTag,
        // formatoNombreArchivoFirmado: x.formatoNombreArchivoFirmado,
        // formatoNombreAuditTrail: x.formatoNombreAuditTrail,
        fusionable: x.fusionable,
        generico: x.generico,
        idTipoDocumento: x.idTipoDocumento,
        // nifOrden: x.nifOrden,
        // nifPage: x.nifPage,
        // nifRegex: x.nifRegex,
        nombre: x.nombre,
        // numFirmantes: x.numFirmantes,
        ordenFusion: x.ordenFusion,
        // sel: x.sel,
        // splitString: x.splitString,
        // tag: x.tag,
        texto: x.texto,
        textoAExcluir: x.textoAExcluir,
        // ubicacion: x.ubicacion,
        // nombreEnvio: x.nombreEnvio,
        paginas: x.paginas,
        estado: x.estado,
        idTipoDocOrigen: x.idTipoDocOrigen,
        incluirContenido: x.incluirContenido,
        tiposContenido: x.tiposContenido,
        firmantesNif: x.firmantesNif,
        idPdf: x.idPdf,
        esCopiaBasica: x.esCopiaBasica,
        split: x.split,
        formatoNombreArchivoAuditTrail: x.formatoNombreArchivoAuditTrail,
        formatoNombreArchivoFirmado: x.formatoNombreArchivoFirmado,
        esRLT: x.esRLT
      }
      arrayDocumentos.push(this.documento)
    })

    return arrayDocumentos
  }

  //borramos un documento a través del index y convertimos el tiposDocumento actualizado en un array de tipo documento
  deleteDocumento(index: number){
    this.tiposDocumento.removeAt(index);
    return this.getDocumentos();
  }

  //actualizamos el form desde el modal de añadir documentos con la información en pantalla (objetoEnvio)
  actualizaFormulario(objetoEnvio:TipoEnvio){
    this.formularioEnvio.setValue(objetoEnvio)
    this.objetoEnvio = this.formularioEnvio.value
  }

  //añadimos documentos al form y volvemos a actualizar el formulario con los datos en pantalla (objetoEnvio)
  addDocumentosEnForm(documentosExistentes: TipoDocumento[]){
    this.formularioEnvio = this.fb.group({
      activo: [this.objetoEnvio.activo],
      idV3TipoEnvio:[this.objetoEnvio.idV3TipoEnvio],
      idTipoEnvio:[this.objetoEnvio.idTipoEnvio],
      nombre: [this.objetoEnvio.nombre, [validacionNombre(this.enviosFiltrados, this.objetoEnvio), Validators.pattern(/^[^'"/\\]*$/)]],
      firmantes: this.setExistingFirmantes(this.objetoEnvio.firmantes),
      mailPrincipal: [this.objetoEnvio.mailPrincipal.codigo, Validators.required],
      subject: [this.objetoEnvio.subject],
      // defectoSubject: [this.objetoEnvio.defectoSubject],
      body: [this.objetoEnvio.body],
      // defectoBody: [this.objetoEnvio.defectoBody],
      tiposDocumento: this.setExistingDocumentos(documentosExistentes),
      // solicitarAdjunto: [this.objetoEnvio.solicitarAdjunto],
      // numeroAdjuntos: [this.objetoEnvio.numeroAdjuntos, requiredIfAdjuntoSelected(() => this.solicitarAdjunto)],
      recordatorio: [this.objetoEnvio.recordatorio],
      reminders_String: [this.objetoEnvio.reminders_String],
      envioMasivo: [this.objetoEnvio.envioMasivo],
      // programado: [this.objetoEnvio.programado],
      // documentosEnvioProgramado: [this.objetoEnvio.documentosEnvioProgramado, requiredIfProgramadoSelected(() => this.programado)]
      estado: [this.objetoEnvio.estado],
      esRLT: [this.objetoEnvio.esRLT]
    })
  }

    //actualizamos el orden del tiposDocumento con el nuevo orden del array totalDocs que viene del componente y convertimos el firmantesArrayForm actualizado en un array de Firmantes
    updateOrdenDocsInForm(totalDocs: TipoDocumento[]){
      this.tiposDocumento.setValue(totalDocs);
      return this.getDocumentos()
    }

  //llamamos el array de tipo de envío para poder hacer la validación de nombre de envío duplicado
  async getTipoEnvios(){
    await this.datosEnvioService.getTipoEnvios()
    this.enviosFiltrados = this.datosEnvioService.enviosFiltrados
   }

  totalDocsFusion: TipoDocumento[] = [];
  totalDocsNoFusion: TipoDocumento[] = [];
  totalDocumentos: TipoDocumento[] = [];

  documentosFusionables(){
    this.totalDocsFusion = this.getDocumentos().filter(x => x.fusionable)
    this.totalDocsNoFusion = this.getDocumentos().filter(x => !x.fusionable)
    this.reordenarFusion();
  }

  reordenarFusion(){
    this.totalDocsFusion.map(x => x.ordenFusion = this.totalDocsFusion.indexOf(x)+1);
  }

  marcarFusionables(){
    this.totalDocsFusion.map(x => x.fusionable = true);
    this.totalDocsNoFusion.map(x => {
      x.fusionable = false;
      x.ordenFusion = null;
    });
  }

  //juntamos los docs fusionables y los no fusionables en el totalDocumentos array
  juntarDocs(){
    this.totalDocumentos = this.totalDocsFusion.concat(this.totalDocsNoFusion)
    this.updateOrdenDocsInForm(this.totalDocumentos)
  }

  //gestionado desde el servicio para que dispare la función después de iniciar el formulario de envíos.
  getAvisos(){
    this.recordatorios = [null];
    if(this.reminders_String !== null && this.reminders_String !== undefined){
      let recordatoriosArray = this.reminders_String.split(',');
      this.recordatorios = recordatoriosArray.map((x:string)=> Number(x));
      if(this.recordatorios.length > 0 && this.recordatorios[0]!==0) this.formularioEnvio.controls['recordatorio'].setValue(true);
    }

  }

  async resetFormularioEnvio(){
    let envioInicio = {} as TipoEnvio;
    envioInicio.idTipoEnvio = "";
    await this.inicializaFormulario(envioInicio);
  }

  async recuperaFormularioTemporal(envio: TipoEnvio){
    const isEmpty = Object.keys(this.formEnvioTemporal.value).length;
    if(isEmpty > 0) {
      this.formularioEnvio = this.formEnvioTemporal;
      this.getAvisos()
    }
    else await this.inicializaFormulario(envio)
  }

  addFormularioTemporal(){
    this.formEnvioTemporal = this.formularioEnvio;
  }

  envioAEditar:any = new TipoEnvioClass();

  hayCambiosDosier():boolean{
    let objetoFinal = this.formularioEnvio.value;
    objetoFinal.mailPrincipal = this.buscarMailDescripcion();
    if(JSON.stringify(this.envioAEditar) === JSON.stringify(objetoFinal)){
      return false;
    }else{
      return true
    }
  }

  buscarMailDescripcion():TipoMail{
    let mailDesc: TipoMail = {codigo:'', desc:''}
    this.tiposMails.forEach(x => {
      if(x.codigo == this.formularioEnvio.controls['mailPrincipal'].value){
        mailDesc = {codigo: x.codigo,desc: x.desc}
      }
    })
    return mailDesc
  }

  getTipoMail(){
    this.datosEnvioService.getTipoMail().subscribe(
      next => {
        this.tiposMails = next;
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }
}
