<div class="main">
  <form [formGroup]="formularioDocumentoService.formularioDocumento">
    <div class="flex">
      <h2>Nombre del documento</h2>
      <input id="nombre"
              type="text"
              formControlName="nombre"
              [ngClass]="formularioValidacionesDocsService.isCampoValido('nombre') ? 'errorInput':'' "
              [attr.disabled]="esRLT.value ? true:null">
    </div>
    <span id="errorNombreId" class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValido('nombre')">
    {{formularioValidacionesDocsService.mensajeErrorNombre}}
    </span>
  </form>
  <div class="cambiar">
    <app-boton-accion *ngIf="mostrarCambiar" [icono]="'hs-upload'" [titulo]="'cambiar documento'"  (click)="click()"></app-boton-accion>
  </div>


