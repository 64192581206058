import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TipoFirmante } from '../../../../envios/models/tipo-firmante';
import { FirmanteNif } from '../../../models/firmante-nif';
import { FormularioDocumentoService } from '../../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../../services/formulario-validaciones-docs.service';

@Component({
  selector: 'app-configuracion-firmas-dibujada-trazado',
  templateUrl: './configuracion-firmas-dibujada-trazado.component.html',
  styleUrls: ['./configuracion-firmas-dibujada-trazado.component.css']
})
export class ConfiguracionFirmasDibujadaTrazadoComponent implements OnInit {

  @ViewChild('botonAnadir') botonAnadir: ElementRef;
  @Input() firmante: any;

  posicionBoton: { left: number, top: number, height: number, width: number };
  posicionFirma: {
    mostrar: boolean,
    left: number,
    top: number,
    firmante: TipoFirmante
  } = {mostrar: false, left:0, top:0, firmante:{codigo:'', desc:''}}

  firmaMoverArray: any[] = [];

  constructor(public formularioValidacionesDocsService: FormularioValidacionesDocsService,
              private formularioDocumentoService: FormularioDocumentoService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges){
    this.posicionFirma.firmante = changes.firmante.currentValue
  }

  get formFirma(){ return this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup}

  getPosicion(event:any){
    const {left, top, height, width} = this.botonAnadir.nativeElement.getBoundingClientRect();
    this.posicionBoton = {left, top, height, width};
    this.posicionFirma = {
      mostrar: true,
      left: this.posicionBoton.left,
      top: this.posicionBoton.top,
      firmante: this.posicionFirma.firmante
    }
    this.firmaMoverArray.push(this.posicionFirma)

    //solo puede haber un elemento al momento de moverse. Si se ha creado uno y no se ha movido, lo borramos al crear el segundo
    if(this.firmaMoverArray.length > 1){
    this.firmaMoverArray.shift();
    }
  }

  obtenerIndexNifArray():number{
    return this.formularioDocumentoService.firmantesNif.value.findIndex((x:FirmanteNif) => x.tipoFirmante.codigo == this.firmante.codigo)
  }
}
