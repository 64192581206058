import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ConfiguraRltPageComponent } from './configuracion-rlt/configura-rlt-page/configura-rlt-page.component';
import { ConfiguracionPageComponent } from './configuracion-page/configuracion-page.component';

@NgModule({
  declarations: [
    ConfiguraRltPageComponent,
    ConfiguracionPageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    ReactiveFormsModule,
    MatDialogModule,
    DragDropModule
  ],

  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
 ],
})
export class ConfiguracionModule { }
