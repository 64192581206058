import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { TipoDocumento } from '../../../documentos/models/tipo-documento';
import { DatosConfiguracionDocumentosService } from '../../../documentos/services/datos-configuracion-documentos.service';
import { FormularioEnvioService } from '../../services/formulario-envio.service';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.css']
})
export class DocumentoComponent implements OnInit {

  @Input() doc: TipoDocumento;
  @Input() index: number;

  @Output() eliminarClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService,
              private router: Router,
              private formularioEnvioService: FormularioEnvioService) { }

  ngOnInit(): void {

  }

  clicked(){
    this.eliminarClicked.emit(this.doc)
  }

  getTooltip(nombre: string){ return nombre }

  editar(){
    // this.formularioEnvioService.editDocDesdeEnvio();
    this.formularioEnvioService.addFormularioTemporal();
    this.datosConfiguracionDocumentosService.transferirDocId(this.doc);
    this.router.navigate([`/panel/editar-configuracion-documento`])
  }

}
