import { ElementSchemaRegistry } from '@angular/compiler';
import { ColDef, ICellRendererParams } from 'ag-grid-community';

export class GridColDefsDocs{

  // public columnDefs: ColDef[] = [
  //   { headerName: "Nombre del documento", field: "nombre", sortable:true, resizable: true, headerClass:"header", width: 700},
  //   { headerName:"Pág.", field: "paginas", sortable:true, resizable: true, filter:true, headerClass:"header", width: 150},
  //   { headerName:"Firmantes", field: "numFirmantes", sortable:true, resizable: true, filter:true, headerClass:"header", width: 200},
  //   { headerName:"Tipo doc.", field: "tipoDoc", sortable:true, resizable: true, filter:true, headerClass:"header", width: 500, wrapText: true, autoHeight: true,
  //     cellRenderer: params => {
  //       let tipoDocIcon = `${this.GetIcono(params.value)} ${params.value}`;
  //       return tipoDocIcon;
  //     }
  //   },
  //   { headerName:"Estado", field: "estado", sortable:true, resizable: true, filter:true, headerClass:"header", width: 1000}
  // ]
  public columnDefs: ColDef[] = [
    { headerName: "Nombre del documento", field: "nombre", sortable:true, resizable: true, headerClass:"header", width: 750, comparator: customComparator},
    { headerName:"Pág.", field: "paginas", sortable:true, resizable: true, headerClass:"header", width: 200},
    { headerName:"Firmantes", field: "firmantesNif", sortable:true, resizable: true, headerClass:"header", width: 250,
      cellRenderer: params => {
        let nFirmantes;
        if(params.data.firmantesNif && params.data.firmantesNif !== null){
           nFirmantes = params.data.firmantesNif.length
        }else{
          nFirmantes = 0
        }
        return nFirmantes
      }
    },
    { headerName:"Tipo doc.", field: "tipoDoc", sortable:true, resizable: true, headerClass:"header", width: 500, wrapText: true, autoHeight: true,
      cellRenderer: params => {
        let tipoDocIcon = `${this.GetIcono(params.value)} ${params.value}`;
        return tipoDocIcon;
      }
    },
    // { headerName:"Estado", field: "estado", sortable:true, resizable: true, headerClass:"header", width: 900}
  ]


  GetIcono(estadoString: string){
    let style = 'width: 20px; height: 20px; margin: 10px 5px -5px 0px;';
    if(estadoString == 'Plantilla'){
      return `<svg style="${style}"><use xlink:href="#icon-plantilla"></use></svg>`;
    }else if(estadoString == 'Doc con datos personales'){
      return `<svg style="${style}"><use xlink:href="#icon-personal"></use></svg>`;
    }
    return null
  }

}

const customComparator = (valueA:any, valueB:any) => {
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};
