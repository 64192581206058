import { Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmationMessageComponent } from 'src/app/shared/components/modals/confirmation-message/confirmation-message.component';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';
import { Firmantes } from '../../models/firmantes';
import { TipoDocumento } from '../../../documentos/models/tipo-documento';
import { EstadosTipoEnvio, EstadosTipoEnvioApi, TipoEnvio } from '../../models/tipo-envio';
import { TipoMail } from '../../models/tipo-mail';
import { DatosEnviosService } from '../../services/datos-envios.service';
import { FormularioEnvioService } from '../../services/formulario-envio.service';
import { FormularioValidacionesService } from '../../services/formulario-validaciones.service';
import { DocumentosModalComponent } from '../../../documentos/components/documentos-modal/documentos-modal.component';
import { DatosDocumentosService } from '../../../documentos/services/datos-documentos.service';
import { EnviosConfirmacionFirmantesModalComponent } from '../envios-confirmacion-firmantes-modal/envios-confirmacion-firmantes-modal.component';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { FirmanteNif } from '../../../documentos/models/firmante-nif';

@Component({
  selector: 'app-configura-envio-page',
  templateUrl: './configura-envio-page.component.html',
  styleUrls: ['./configura-envio-page.component.css']
})
export class ConfiguraEnvioPageComponent implements OnInit {

  tituloPagina: string = '';
  icono2: string = '';
  tiposMails: TipoMail[] = [];
  objetoEnvio!: TipoEnvio;
  envio!: TipoEnvio;
  envios: TipoEnvio[] = [];
  mostrarForm: boolean = false;
  isMismoNombre: boolean = false;
  firmantes: Firmantes[] = []
  firmantesInit: Firmantes[] = [
    {
      orden:1,
      tipoFirmante:{
        codigo:"100",
        desc:"Firmante principal"
        },
      tipoDestinatario:{
        codigo:"",
        desc:""
      },
      tipoBusqueda:{
        codigo:"",
        desc:""
      }
    }
  ]
  documentos: TipoDocumento[] = [];
  documentosEnvio: TipoDocumento[] = [];
  estadoCambiado: boolean = false;
  recordatorios: number[]=[null];
  hayCopiaBasica: number = 0;
  errorValidador: boolean = false;
  errorValidadorCampos: string[] = [];
  arrayMismoNum: boolean[] = [];
  arrayMismoCodigo: boolean[] = [];

  constructor(private datosEnvioService: DatosEnviosService,
              private _snackBar: MatSnackBar,
              public formularioEnvioService: FormularioEnvioService,
              public formularioValidacionesService: FormularioValidacionesService,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private activeRoute: ActivatedRoute,
              private datosDocumentosService: DatosDocumentosService,
              private router: Router) {}

  async ngOnInit() {
    this.getTipoMail();
    await this.getEnvio();
    this.getTipoDocumentos();
    this.getTitulo();
    await this.formularioEnvioService.resetFormularioEnvio();
    await this.formularioEnvioService.recuperaFormularioTemporal(this.envio);
    this.firmantes = this.formularioEnvioService.formularioEnvio.controls['firmantes'].value;
    this.documentosEnvio = this.formularioEnvioService.formularioEnvio.controls['tiposDocumento'].value;
    this.formularioEnvioService.formEnvioTemporal = new FormGroup({});
    // this.formularioEnvioService.inicializaFormulario(this.envio)
    this.checkActivado();
  }

  getTitulo(){
    if(this.envio.idTipoEnvio == ""){
      this.tituloPagina = "Nueva configuración de dosier";
      this.icono2 = "#icon-plus";
    }else{
      this.tituloPagina = "Editar dosier configurado";
      this.icono2 = "";
    }
  }

  getTipoMail(){
    this.datosEnvioService.getTipoMail().subscribe(
      next => {
        this.tiposMails = next;
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  getTipoDocumentos(){
    this.datosDocumentosService.getTipoDocumentos().subscribe(
      next => {
        this.documentos = next;
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

 async getTipoEnvios(){
  if(this.datosEnvioService.enviosFiltrados){
    this.envios = this.datosEnvioService.enviosFiltrados
  }else{
    await this.datosEnvioService.getTipoEnvios()
    //  this.envios = this.datosEnvioService.envios
     this.envios = this.datosEnvioService.enviosFiltrados
  }

  }

  //Getters para el formulario
  get nombre(){ return this.formularioEnvioService.formularioEnvio.get('nombre') }
  get mailPrincipal(){ return this.formularioEnvioService.formularioEnvio.get('mailPrincipal') as FormGroup}
  get subject(){ return this.formularioEnvioService.formularioEnvio.get('subject') }
  get numeroAdjuntos(){ return this.formularioEnvioService.formularioEnvio.get('numeroAdjuntos') }
  get tiposDocumento(){ return this.formularioEnvioService.formularioEnvio.get('tiposDocumento')}
  get recordatorio(){ return this.formularioEnvioService.formularioEnvio.get('recordatorio')}
  get reminders_String(){ return this.formularioEnvioService.formularioEnvio.get('reminders_String')}

  changeValue(e: any,value:any) {
    value?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  comprobarNumFirmantes(){
    this.objetoEnvio = this.formularioEnvioService.formularioEnvio.value;
    this.arrayMismoNum = [];
    this.arrayMismoCodigo = [];
    let hayDocs: boolean = true;
    if(this.objetoEnvio.tiposDocumento.length == 0){
      hayDocs = false;
    }else{
      this.objetoEnvio.tiposDocumento.forEach(x=>{
        this.comprobarErroresValidador(x);
        if(x.fusionable == false){
          if(x.firmantesNif == null){
            this.arrayMismoNum.push(false);
          }else
          if(x.firmantesNif.length == this.objetoEnvio.firmantes.length){
            this.arrayMismoNum.push(true);
            x.firmantesNif.forEach(y=>{
              let index = this.objetoEnvio.firmantes.findIndex(z=>z.tipoFirmante.codigo==y.tipoFirmante.codigo)
              if(index>-1){
                this.arrayMismoCodigo.push(true)
              }else this.arrayMismoCodigo.push(false)
            })
          }else{ this.arrayMismoNum.push(false) }
        }
      })
    }
    this.formularioValidacionesService.addValidatorsFirmantesArrayForm();
    this.objetoEnvio.mailPrincipal = this.buscarMailDescripcion();
    this.formularioValidacionesService.validacionAvisos();
    if(this.formularioEnvioService.formularioEnvio.invalid){
      this.formularioEnvioService.formularioEnvio.markAllAsTouched();
    }
    if(hayDocs==false ||
       this.arrayMismoNum.includes(false) ||
       this.arrayMismoCodigo.includes(false) ||
       this.formularioValidacionesService.validacionAvisos()==true ||
       this.formularioEnvioService.formularioEnvio.invalid ||
       this.errorValidador ||
       this.errorValidadorCampos.length>0){

       this.abrirModalErrores();
    }else{
      this.objetoEnvio.estado = EstadosTipoEnvioApi.completo
      this.objetoEnvio.activo = this.activado;
      if(this.objetoEnvio.activo == false) this.abrirModalConfirmacionActivado();
      else this.guardaTipoDosierRequest();
    }
    this.errorValidador=false;
    this.errorValidadorCampos=[];
  }

  abrirModalErrores(){
    let dataEnvio = {
      infoEnvio: this.objetoEnvio,
      isValidadorError: this.errorValidador,
      isValidadorErrorCampos: this.errorValidadorCampos,
      diferenteNumeroFirmantes: this.arrayMismoNum.includes(false),
      diferenteCodigoFirmantes: this.arrayMismoCodigo.includes(false)
    }
    const dialogRef = this.dialog.open(EnviosConfirmacionFirmantesModalComponent, {
      data: {
        extraInfo: dataEnvio,
        tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
        // icono: 'hs-send',
        // titulo: '¿Desea guardar el dosier incompleto?',
        // preguntaConfirmacion: '¿Desea guardar el dosier incompleto?',
        textoConfirmacion: 'Guardar como incompleto',
        iconoConfirmacion: 'hs-send',
        textoCancelar: 'cancelar'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'OK'){
        this.objetoEnvio.estado = EstadosTipoEnvioApi.incompleto
        this.objetoEnvio.activo = false;
        this.guardaTipoDosierRequest();
      }
    });
  }

  anadirNuevoDoc(){
    this.formularioEnvioService.addFormularioTemporal();
    this.router.navigate(['/panel/nueva-configuracion-documento']);
  }

  abrirModalConfirmacionActivado(){
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: {
        extraInfo:{boton: 'Guardar desactivado'},
        tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
        icono: 'icon-save',
        soloMensaje: '¿Desea guardar la configuración del dosier como activo?',
        textoConfirmacion: 'Guardar activo',
        iconoConfirmacion: 'icon-save',
        textoCancelar: 'cancelar'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'OK') {
        this.objetoEnvio.activo = true;
        this.guardaTipoDosierRequest();
      }else if(result === 'desactivar') {
        this.objetoEnvio.activo = false;
        this.guardaTipoDosierRequest();
      }
    });
  }

  confirmacionGuardadoOk(){
    const dialogRef = this.dialog.open(ModalMessageComponent, {
      data: {
        mensaje: 'Configuración de dosier guardada correctamente',
      },
    });
  }

  //cogemos la data del envio a través del parámetro de la ruta y se lo mandamos como id al formulario de nueva configuración de envío
  async getEnvio(){
    let id: string;
    this.activeRoute.params.subscribe((params:Params) => {
      id = params.idTipoEnvio;
    })
    if(id == "0"){
      let nuevoEnvio = {} as TipoEnvio;
      nuevoEnvio.idTipoEnvio = "";
      nuevoEnvio.reminders_String = [];
      // nuevoEnvio.expire_time = null;
      nuevoEnvio.numeroAdjuntos = null;
      nuevoEnvio.documentosEnvioProgramado = null;
      nuevoEnvio.firmantes = this.firmantesInit;
      this.envio = nuevoEnvio;
      this.firmantes = this.firmantesInit;
    }else{
      await this.datosEnvioService.getTipoEnvio(id).then(
        (resp:any) => {
          this.envio = resp;
          this.firmantes = this.envio.firmantes
          this.documentosEnvio = this.envio.tiposDocumento
        },
        error => {
          this._snackBar.open(error, 'Cerrar',{duration: 3000});
        }
      )
    }
  }

  //funcion para cambiar tamaño input
  @ViewChild('hiddenText') textEl: ElementRef;

  minWidth: number = 24;
  width: number = this.minWidth;

  resize() {
      setTimeout(() => this.width = Math.max(this.minWidth, this.textEl.nativeElement.offsetWidth));
  }

  buscarMailDescripcion():TipoMail{
    let mailDesc: TipoMail = {codigo:'', desc:''}
    this.tiposMails.forEach(x => {
      if(x.codigo == this.mailPrincipal.value){
        mailDesc = {codigo: x.codigo,desc: x.desc}
      }
    })
    return mailDesc
  }

  anadirDocExistente(){
    this.objetoEnvio = this.formularioEnvioService.formularioEnvio.value;
    this.objetoEnvio.mailPrincipal = this.buscarMailDescripcion();
    //eliminamos estado si lo lleva porqué el formulario no lo tiene. Se le añade antes de guardar el envío. Se tiene que revisar
    this.objetoEnvio.estado;
    const dialogRef = this.dialog.open(DocumentosModalComponent, {
      data: {
        documentos:this.documentos,
        objetoEnvio: this.objetoEnvio,
        origenModal: 'anadir-doc-existente'
      },
      panelClass: 'modal-class-detalle',
      position: { right: '0'},
      autoFocus: 'dialog'
    });

    dialogRef.afterClosed().subscribe(result=>{
      if(result === 'OK'){
        this.refresh();
      }
    })
  }

  refresh(){
    this.hayCopiaBasica = 0;
    this.documentosEnvio = this.formularioEnvioService.tiposDocumento.value
    this.documentosEnvio.forEach((x: TipoDocumento)=> x.esCopiaBasica ? this.hayCopiaBasica++ : this.hayCopiaBasica)
  }

  guardaTipoDosierRequest(){
    delete this.objetoEnvio.estado;
    this.objetoEnvio.nombre = this.objetoEnvio.nombre.trim();
    this.datosEnvioService.guardaEnvio(this.objetoEnvio).subscribe(
      ok => {
        this.confirmacionGuardadoOk();
      },
      error => {
        console.error("error", error);
      },
      () => {
        this.datosEnvioService.refresh = true;
        this.router.navigate(['/panel/dosieres-configurados']);
      }
    );
  }

  guardaBorrador(){
    this.objetoEnvio = this.formularioEnvioService.formularioEnvio.value;
    if(this.objetoEnvio.estado) delete this.objetoEnvio.estado;
    this.datosEnvioService.guardaEnvio(this.objetoEnvio).subscribe(
      ok => {
        this.confirmacionGuardadoBorradorOk();

      },
      error => {
        console.error("error", error);
      },
      () => {
        this.datosEnvioService.refresh = true;
        this.router.navigate(['/panel/dosieres-configurados']);
      }
    );
  }

  confirmacionGuardadoBorradorOk(){
    const dialogRef = this.dialog.open(ModalMessageComponent, {
      data: {
        mensaje: 'Borrador guardado correctamente',
      },
    });
  }


  activado: boolean = false;
  toggleActivado(event:any){
    this.activado = event;
  }

  checkActivado(){
    if(this.envio.estado == EstadosTipoEnvio.completo || this.envio.activo == true) this.activado = true;
    else this.activado = false;
  }

  agregarAviso(){
    if(this.recordatorio.value == true){
      if(this.formularioEnvioService.recordatorios.includes(null)) this._snackBar.open('Se tiene que completar el campo, antes de agregar otro.', 'Cerrar', {duration: 3000})
      else if(!this.formularioEnvioService.disableRecordatorio) this.formularioEnvioService.recordatorios.push(null)
      else this._snackBar.open('Se tiene que marcar "Avisos de firma" para poder agregar un aviso', 'Cerrar', {duration: 3000})
      this.guardarAForm();
    }
  }

  clickAvisoFirma(){ //añadimos un aviso si no hay ninguno al clicar la casilla
    if(this.recordatorio.value == false && this.formularioEnvioService.recordatorios.length == 0) this.formularioEnvioService.recordatorios.push(1)
  }

  eliminarAviso(event:number){
    this.formularioEnvioService.recordatorios.splice(event,1)
    this.guardarAForm();
    if(this.formularioEnvioService.recordatorios.length == 0) this.formularioEnvioService.formularioEnvio.controls['recordatorio'].setValue(false);
  }

  updateAviso(event: any){
    this.formularioEnvioService.recordatorios[event.index] = event.valor;
    this.guardarAForm();
  }

  guardarAForm(){
    let numRecordatorios = this.formularioEnvioService.recordatorios.toString()
    this.formularioEnvioService.formularioEnvio.controls['reminders_String'].setValue(numRecordatorios)
  }

  comprobarErroresValidador(tipoDoc: TipoDocumento){
    this.objetoEnvio.firmantes.forEach(x=>{
      tipoDoc.firmantesNif.forEach((y:any)=>{
        if(x.tipoFirmante.codigo==y.tipoFirmante.codigo &&
           ((x.tipoDestinatario.codigo!=="validator" && y.tipoDestinatario.codigo=="validator") ||
            (x.tipoDestinatario.codigo=="validator" && y.tipoDestinatario.codigo!=="validator") )) {
          this.errorValidador=true;
        }
        else if((y.tipoDestinatario.codigo=="validator" && y.formularios.length>0) ||
                (y.tipoDestinatario.codigo=="validator" && y.camposEditables.length>0)) {
                  if(!this.errorValidadorCampos.includes(tipoDoc.nombre)) this.errorValidadorCampos.push(tipoDoc.nombre);
                }
      })
    })
  }

}
