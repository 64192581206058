<div [ngClass]="{'aside-container':true,'aside-big':!oculto,'aside-small':oculto}">
  <div class="aside-title">
      <h4>Filtros</h4>
      <!-- <app-boton-ocultar (cambioOculto)="clickOcultar($event)"></app-boton-ocultar> -->
  </div>
  <div>
    <div class="selectores">
      <app-selector [textoOpcion2]="'Sandbox'" [active]="verEnviosEntornoSandbox" [control]="'verEnviosEntornoSandbox'" (mostrarSeleccion)="selectVerEnviosEntornoSandbox($event)"></app-selector>

      <app-selector [textoOpcion2]="'Ver sólo nuevos'" [active]="verNuevasParam" [control]="'nuevasFirmas'" (mostrarSeleccion)="verNuevosClicked($event)"></app-selector>
    </div>

    <ul>
      <li *ngFor="let element of resumen" matTooltip="{{getTooltip(element.estado)}}" [matTooltipPosition]="'after'" (click)="clickTipoFirma(element)" [ngClass]="{'seleccion': element.estado==estadosFirmaService.estadoSeleccionado}">
        <div class="leyenda-color" [class]="element.icono"></div>
        <div>{{element.etiqueta}}</div>
        <span class="shipping-qty flex-right">{{element.firmas.length}}</span>
      </li>
    </ul>
  </div>
</div>
