import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TipoEnvio } from '../../../envios/models/tipo-envio';
import { TipoDocumento } from '../../models/tipo-documento';
import { DatosDocumentosService } from '../../services/datos-documentos.service';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';
import { DocumentosModalComponent } from '../documentos-modal/documentos-modal.component';

@Component({
  selector: 'app-configuracion-documento-copiar-configuracion',
  templateUrl: './configuracion-documento-copiar-configuracion.component.html',
  styleUrls: ['./configuracion-documento-copiar-configuracion.component.css']
})
export class ConfiguracionDocumentoCopiarConfiguracionComponent implements OnInit {


  nombreDocCopiar: string = '';
  copiar: boolean = false;

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              private datosDocumentosService: DatosDocumentosService,
              private dialog: MatDialog
              ) { }

  //cuando iniciamos el formulario, ahora va siempre a id=0 pero después puede venir un id des de la url (como en envios)

  //si existe el id del documento a copiar, inicializamos el componente con esta información.
  ngOnInit(): void {
    if(this.idTipoDocOrigen.value){
      this.nombreDocCopiar = this.datosDocumentosService.getTipoDocumento(this.idTipoDocOrigen.value).nombre
      this.copiar = true
    }

  }

  get nombre(){ return this.formularioDocumentoService.formularioDocumento.controls['nombre'] }
  get copyConfiguracion(){ return this.formularioDocumentoService.formularioDocumento.controls['copyConfiguracion'] }
  get idTipoDocOrigen(){ return this.formularioDocumentoService.formularioDocumento.controls['idTipoDocOrigen'] }
  get generico(){ return this.formularioDocumentoService.formularioDocumento.controls['generico'] }

  //al abrir el modal le pasamos un objeto envio porqué es el mismo componenente que usamos para añadir documentos en envíos, y lo pide.
  abrirModalDocumentos(){
    if(!this.copiar){
      let envio = {} as TipoEnvio;
      const dialogRef = this.dialog.open(DocumentosModalComponent, {
        data: {
          documentos:this.formularioDocumentoService.documentos,
          objetoEnvio: envio,
          origenModal: 'configuracion-documento'
        },
        panelClass: 'modal-class-detalle',
        position: { right: '0'},
        autoFocus: 'dialog'
      });

      dialogRef.afterClosed().subscribe(result=>{
        if(result.data){
          this.copiar = true;
          this.nombreDocCopiar = result.data[0].nombre
          this.idTipoDocOrigen.setValue(result.data[0].idTipoDocumento)

          this.formularioDocumentoService.copiaTipoDocumento(result.data[0]) //se llama cuando damos a siguiente
          this.formularioDocumentoService.idTipoDocOrigen = result.data[0].idTipoDocOrigen
        }else{
          this.copyConfiguracion.setValue(false)
        }
      })
    }else{
      this.deleteCopy()
    }

  }

  deleteCopy(){
    this.formularioDocumentoService.cancelarCopiaTipoDocumento();
    this.copiar = false;
    // this.copyConfiguracion.setValue(false)
    // this.idTipoDocOrigen.setValue(null)
    // this.generico.reset()
  }

}
