import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TipoFirmante } from '../../../../envios/models/tipo-firmante';
import { FirmanteNif } from '../../../models/firmante-nif';
import { FormularioDocumentoService } from '../../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../../services/formulario-validaciones-docs.service';

@Component({
  selector: 'app-formularios-grupo-casillas',
  templateUrl: './formularios-grupo-casillas.component.html',
  styleUrls: ['./formularios-grupo-casillas.component.css']
})
export class FormulariosGrupoCasillasComponent implements OnInit {

  @Input() firmante: any;
  @Output() addForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() enviarInput: EventEmitter<any> = new EventEmitter<any>();
  @Input() formulario: FormGroup;
  @Input() indexMostrar: number;
  @ViewChild('radio') radio: ElementRef;

  @ViewChild('botonAnadir') botonAnadir: ElementRef;

  posicionBoton: { left: number, top: number, height: number, width: number };
  posicionCasilla: {
    mostrar: boolean,
    left: number,
    top: number,
    firmante: TipoFirmante,
    nombre: string,
    orden: number
  } = {mostrar: false, left:0, top:0, firmante:{codigo:'', desc:''}, nombre:'', orden: null}

  casillaMoverArray: any[] = [];

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              public formularioValidacionesDocsService: FormularioValidacionesDocsService,
              private _snackBar: MatSnackBar) {}

  ngOnChanges(changes: SimpleChanges){
    this.posicionCasilla.firmante = changes.firmante?.currentValue;
  }

  ngOnInit(): void {
  }

  addFormulario(){
    this.addForm.emit();
  }

  get nombre(){ return this.formulario.controls['nombre']}
  get orden(){ return this.formulario.controls['orden']}
  get idForm(){ return this.formulario.controls['id']}
  get formularios(){ return this.formularioDocumentoService.firmantesNif.at(this.obtenerIndexNifArray()).get('formularios') as FormArray}

  checkRadio(){
    // this.formulario.controls['nombre'].enable();
    // this.counter++;
    // if(this.counter%2==0){
    //   this.radio.nativeElement.checked=false;
    //   this.isDisabled=true;
    //   this.currentCheck=false;
    // }
    // else {
    //   this.radio.nativeElement.checked=true;
    //   this.isDisabled=false;
    //   this.currentCheck=true;
    // };

    // this.guardaInput(input)
  }

  guardaNombre(){
    this.formularioDocumentoService.addFormulariosEnFirmante(this.firmante, this.formulario);
  }

  obtenerIndexNifArray():number{
    return this.formularioDocumentoService.firmantesNif.value.findIndex((x:FirmanteNif) => x.tipoFirmante.codigo == this.firmante.tipoFirmante.codigo)
  }

  getPosicion(event:any){
    if(this.nombre.value == null || this.nombre.value == ''){
      this._snackBar.open(`Se tiene que informar el nombre del formulario antes de dibujar los campos.`,'Cerrar', {duration: 3000});
    }else{
      const {left, top, height, width} = this.botonAnadir.nativeElement.getBoundingClientRect();
      this.posicionBoton = {left, top, height, width};
      this.posicionCasilla = {
        mostrar: true,
        left: this.posicionBoton.left,
        top: this.posicionBoton.top,
        firmante: this.posicionCasilla.firmante,
        nombre: this.nombre.value,
        orden: this.orden.value
      }
      this.casillaMoverArray.push(this.posicionCasilla)

      //solo puede haber un elemento al momento de moverse. Si se ha creado uno y no se ha movido, lo borramos al crear el segundo
      if(this.casillaMoverArray.length > 1){
      this.casillaMoverArray.shift();
      }
    }
  }
}
