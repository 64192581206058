
<div class="container">
  <h6>Instrucciones</h6>
  <div *ngIf="datosSubida.faseSubidaFichero">
      <div class="instructions">
          <div>Sube los documentos del dosier.</div>
          <div>No refrescar ni salir de la página hasta completarse la subida</div>
      </div>
  </div>
  <div *ngIf="datosSubida.faseSubidaExcel" class="instructions-little">
      <div>1- Descarga la plantilla excel.</div>
      <div>2- Rellena la información de los firmantes (sólo 1 firmante-destinatario por dosier).</div>
      <div>3- Sube el listado Excel de firmantes.</div>

  </div>
</div>

