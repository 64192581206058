import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DesplegableData } from 'src/app/shared/models/desplegable-data';
import { FiltroDocs } from 'src/app/shared/models/filtros';
import { DatosDocumentosService } from '../../services/datos-documentos.service';

@Component({
  selector: 'app-documentos-filtros-nuevos',
  templateUrl: './documentos-filtros-nuevos.component.html',
  styleUrls: ['./documentos-filtros-nuevos.component.css']
})
export class DocumentosFiltrosNuevosComponent {
  formGroup: FormGroup = new FormGroup({});
  tipoDocs: DesplegableData[] = [];
  estados: DesplegableData[] = [];
  nombre: DesplegableData[] = [];

  @Output() filtrarDocumentos: EventEmitter<string> = new EventEmitter<string>();

  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private datosDocumentosService: DatosDocumentosService) {
                this.inicializaFormulario();
              }

  ngOnInit(): void {
    if(this.datosDocumentosService.filtros.length>0){
      this.datosDocumentosService.filtros.forEach(x=>{
        if(x.tipoDoc!=''){
          this.formGroup = new FormGroup({
            tipoDocs: new FormControl(x.tipoDoc)
          });
        }
        if(x.tipoDoc=='TODOS'){
          this.formGroup = new FormGroup({
            tipoDocs: new FormControl('Todos los tipos documento'),
          });
        }
      })
    }else{
      this.formGroup = new FormGroup({
        tipoDocs: new FormControl('Todos los tipos documento'),
      });
    }
  }

  inicializaFormulario() {
    // this.tipoDocs  = this.datosDocumentosService.GetGenericoLista();
    if(this.datosDocumentosService.documentos){
      this.tipoDocs  = this.datosDocumentosService.GetTipoDocumentosLista();
      // this.estados  = this.datosDocumentosService.GetEstadosLista();
      // this.nombre  = this.datosDocumentosService.GetNombreDocsLista();
      this.formGroup = new FormGroup({
        tipoDocs: new FormControl('Todos los tipos documento'),
        // estados: new FormControl('Estado'),
        // nombreDocs: new FormControl('Nombre documento')
      });
    }

  }

  aplicarFiltros(){
    let filtros: FiltroDocs[] = [];
    this.actualizaFiltroDocumento(filtros);
    this.filtrarDocumentos.emit('Filtrar');
  }

  // actualizaFiltroNombreDocs(filtros: FiltroDocs[]){
  //   const nombreDocs = this.formGroup.controls["nombreDocs"].value;

  //   if(nombreDocs=='Nombre documento'||nombreDocs=='') return

  //   const tipo = this.nombre.find(x=> x.desc == nombreDocs );

  //   const filtroNombreDocs:FiltroDocs ={
  //     text: 'Nombre documento',
  //     textoBuscar: '',
  //     value: tipo?.desc??'',
  //     estado:'',
  //     idTipoDocumento: parseInt(tipo?.id??''),
  //     nombre: tipo?.desc??'',
  //     tipoDoc:''
  //   }
  //   filtros.push(filtroNombreDocs)
  // }

  actualizaFiltroDocumento(filtros: FiltroDocs[]){
    const tipoDocumento = this.formGroup.controls["tipoDocs"].value;

    if(tipoDocumento=='TODOS'||tipoDocumento=='') return

    // if(tipoDocumento=='TODOS')
    let tipo;
    if(tipoDocumento=='Todos los tipos documento'){
      tipo = {id:'TODOS', desc:'TODOS'}
    }else{
      tipo = this.tipoDocs.find(x=> x.id == tipoDocumento);
    }

    const filtroTipoDoc:FiltroDocs ={
      text: 'Tipo documento',
      textoBuscar: '',
      value: tipo?.desc??'',
      estado:'',
      idTipoDocumento: 0,
      nombre:'',
      tipoDoc:tipo?.desc??''
    }
    let existeTipo = this.datosDocumentosService.filtros.findIndex(x=>x.text=='Tipo documento');
    if(existeTipo>-1) this.datosDocumentosService.filtros[existeTipo] = filtroTipoDoc;
    else this.datosDocumentosService.filtros.push(filtroTipoDoc)
    // filtros.push(filtroTipoDoc)
    this.datosDocumentosService.SetFilter(this.datosDocumentosService.filtros);
    this.datosDocumentosService.Filter();

  }

  // actualizaFiltroEstado(filtros: FiltroDocs[]){
  //   const tipoEstado = this.formGroup.controls["estados"].value;
  //   if(tipoEstado=='Estado'||tipoEstado=='') return

  //   const tipo = this.estados.find(x => x.id == tipoEstado)
  //   const filtroEstado: FiltroDocs = {
  //     text: 'Estado',
  //     textoBuscar: '',
  //     value: tipo?.desc??'',
  //     estado: tipo?.desc??'',
  //     idTipoDocumento: 0,
  //     nombre:'',
  //     tipoDoc:''
  //   }
  //   filtros.push(filtroEstado)
  // }
}
