import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TenanData } from 'src/app/pages/login/models/tenant_data';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';
import { ConfirmationMessageComponent } from '../../modals/confirmation-message/confirmation-message.component';
import { FormularioDocumentoService } from 'src/app/pages/configuracion/Envios/documentos/services/formulario-documento.service';
import { MatDialog } from '@angular/material/dialog';
import { TipoDocumentoClass } from 'src/app/pages/configuracion/Envios/documentos/models/tipo-documento';
import { environment } from 'src/environments/environment';
import { TipoEnvioClass } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';
import { FormularioEnvioService } from 'src/app/pages/configuracion/Envios/envios/services/formulario-envio.service';
import { DatosCentrosService } from 'src/app/pages/configuracion/maestros/centros/services/datos-centros.service';
import { DatosDocumentosService } from 'src/app/pages/configuracion/Envios/documentos/services/datos-documentos.service';
import { DatosEnviosService } from 'src/app/pages/configuracion/Envios/envios/services/datos-envios.service';

@Component({
  selector: 'app-usuario-activo-selector',
  templateUrl: './usuario-activo-selector.component.html',
  styleUrls: ['./usuario-activo-selector.component.css']
})
export class UsuarioActivoSelectorComponent implements OnInit {

  public tenants:TenanData[] =[{Name:'',Tenant :''}];
  mostrarConfiguracionUsuario: boolean;

  @Output() clickFuera: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public loginService: LoginService,
              private router: Router,
              private _snackBar: MatSnackBar,
              private activatedRoute: ActivatedRoute,
              private eRef: ElementRef,
              private formularioDocumentoService: FormularioDocumentoService,
              private dialog: MatDialog,
              private formularioEnvioService: FormularioEnvioService,
              private datosCentrosService: DatosCentrosService,
              private datosDocumentosService: DatosDocumentosService,
              private datosEnviosService: DatosEnviosService) { }

  ngOnInit(): void {
    if(environment.LoginUniversal) this.mostrarConfiguracionUsuario = true;
    else this.mostrarConfiguracionUsuario = false;
  }

  avisaCerrarConfiguraciones(destino: string){
    if(this.router.url.includes('configuracion-documento')){
      if(!this.formularioDocumentoService.hayCambios()){
        this.formularioDocumentoService.resetFormularioDocumento();
        this.formularioDocumentoService.documentoAEditar = new TipoDocumentoClass();
        this.irADestino(destino);
      }else{
        if(destino=='logout'){
          const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
            data: {
              tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
              icono: 'icon-save2',
              titulo: '¿Deseas salir de Hire & Sign sin guardar?',
              mensaje:'Se perderán todos los cambios realizados en la configuración del documento.',
              textoConfirmacion: 'Salir sin guardar',
              iconoConfirmacion: 'icon-unsaved',
              textoCancelar: 'cerrar'
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            if(result === 'OK'){
              this.formularioDocumentoService.resetFormularioDocumento();
              this.formularioDocumentoService.documentoAEditar = new TipoDocumentoClass();
              this.logout();
            }
          });
        }else{
          const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
            data: {
              tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
              icono: 'icon-save2',
              titulo: '¿Deseas salir sin guardar?',
              mensaje:'Se perderán todos los cambios realizados en la configuración del documento.',
              textoConfirmacion: 'Salir sin guardar',
              iconoConfirmacion: 'icon-unsaved',
              textoCancelar: 'cerrar'
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            if(result === 'OK'){
              this.formularioDocumentoService.resetFormularioDocumento();
              this.formularioDocumentoService.documentoAEditar = new TipoDocumentoClass();
              this.irADestino(destino);
            }
          });
        }
      }
    }else if(this.router.url.includes('configuracion-dosier')){
      if(!this.formularioEnvioService.hayCambiosDosier()){
        this.formularioEnvioService.envioAEditar = new TipoEnvioClass();
        this.irADestino(destino);
      }else{
        if(destino=='logout'){
          const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
            data: {
              tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
              icono: 'icon-save2',
              titulo: '¿Deseas salir de Hire & Sign sin guardar?',
              mensaje:'Se perderán todos los cambios realizados en la configuración del dosier.',
              textoConfirmacion: 'Salir sin guardar',
              iconoConfirmacion: 'icon-unsaved',
              textoCancelar: 'cerrar'
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            if(result === 'OK'){
              this.logout();
              this.formularioEnvioService.envioAEditar = new TipoEnvioClass();
            }
          });
        }else{
          const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
            data: {
              tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
              icono: 'icon-save2',
              titulo: '¿Deseas salir sin guardar?',
              mensaje:'Se perderán todos los cambios realizados en la configuración del dosier.',
              textoConfirmacion: 'Salir sin guardar',
              iconoConfirmacion: 'icon-unsaved',
              textoCancelar: 'cerrar'
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            if(result === 'OK'){
              this.irADestino(destino);
              this.formularioEnvioService.envioAEditar = new TipoEnvioClass();
            }
          });
        }
      }
    }else{
      this.irADestino(destino);
    }
  }

  logout(){
    this.loginService.logout().subscribe(
      ok => {
        // localStorage.removeItem('SQPFIRMA_login');
        localStorage.clear();
        this.router.navigate(['/login']);
      },
      error => {
        this._snackBar.open(error.error.Message, "Cerrar", { duration: 3000 });
        this.router.navigate(['/login']);
      });
  }

  // configuracionUsuario(){
  //   this.router.navigate(['/panel/configuracion-usuario'])
  // }

  // cambiarEntorno(){
  //   this.router.navigate([`selectTenant/${this.loginService.getTenantToken().tenantToken}`])
  // }

  @HostListener('document:click', ['$event']) clickout(event:any) {
    if(this.eRef.nativeElement.contains(event.target)) {
      this.clickFuera.emit(false)
    } else {
      this.clickFuera.emit(true)
    }
  }

  irADestino(destino: string){
    switch(destino){
      case 'configuracion':
        this.router.navigate(['/panel/configuracion-usuario']);
      break;
      case 'entorno':
        this.datosCentrosService.refreshCentros = true;
        this.datosDocumentosService.refreshDocs = true;
        this.datosEnviosService.refresh = true;
        this.router.navigate([`selectTenant/${this.loginService.getTenantToken().tenantToken}`])
      break;
      case 'logout':
        const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
          data: {
            tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
            icono: 'icon-save2',
            // titulo: 'Salir',
            // mensaje:'¿Realmente deseas salir de Hire & Sign?',
            soloMensaje:'¿Realmente deseas salir de Hire & Sign?',
            textoConfirmacion: 'Salir',
            iconoConfirmacion: 'icon-unsaved',
            textoCancelar: 'cerrar'
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result === 'OK'){
            this.logout();
          }
        });

      break;
    }
  }

}
