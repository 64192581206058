import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { UploadExcelEmpleadosComponent } from './components/upload-excel-empleados/upload-excel-empleados.component';
import { EmpleadosTooltipComponent } from './components/empleados-tooltip/empleados-tooltip.component';
import { EmpleadosPageComponent } from './components/mantenimiento/empleados-page/empleados-page.component';
import { EmpleadosBarraSuperiorComponent } from './components/mantenimiento/empleados-barra-superior/empleados-barra-superior.component';
import { EmpleadosDataGridComponent } from './components/mantenimiento/empleados-data-grid/empleados-data-grid.component';
import { EmpleadosModalComponent } from './components/mantenimiento/empleados-modal/empleados-modal.component';
import { MaestrosModule } from '../configuracion/maestros/maestros.module';
import { EmpleadosFiltrosComponent } from './components/mantenimiento/empleados-filtros/empleados-filtros.component';
import { SqpComponentsModule } from 'src/app/shared/components/sqp-components/sqp-components.module';



@NgModule({
  declarations: [
    UploadExcelEmpleadosComponent,
    EmpleadosTooltipComponent,
    EmpleadosPageComponent,
    EmpleadosBarraSuperiorComponent,
    EmpleadosDataGridComponent,
    EmpleadosModalComponent,
    EmpleadosFiltrosComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    MaestrosModule,
    SqpComponentsModule
  ]
})
export class EmpleadosModule { }
