import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DocumentosPageComponent } from './components/documentos-page/documentos-page.component';
import { DocumentosBarraSuperiorComponent } from './components/documentos-barra-superior/documentos-barra-superior.component';
import { DocumentosDataGridComponent } from './components/documentos-data-grid/documentos-data-grid.component';
import { DocumentosFilterDetailsComponent } from './components/documentos-filter-details/documentos-filter-details.component';
import { DocumentosFiltroSelectorComponent } from './components/documentos-filtro-selector/documentos-filtro-selector.component';
import { DocumentosModalComponent } from './components/documentos-modal/documentos-modal.component';
import { AddDocsDataGridComponent } from './components/add-docs-data-grid/add-docs-data-grid.component';
import { ConfiguracionDocumentoPageComponent } from './components/configuracion-documento-page/configuracion-documento-page.component';
import { ConfiguracionDocumentoAyudaSubidaComponent } from './components/configuracion-documento-ayuda-subida/configuracion-documento-ayuda-subida.component';
import { ConfiguracionDocumentoProgresoComponent } from './components/configuracion-documento-progreso/configuracion-documento-progreso.component';
import { ConfiguracionDocumentoBotoneraComponent } from './components/configuracion-documento-botonera/configuracion-documento-botonera.component';
import { ConfiguracionDocumentoUploadComponent } from './components/configuracion-documento-upload/configuracion-documento-upload.component';
import { ConfiguracionDocumentoNombreComponent } from './components/configuracion-documento-nombre/configuracion-documento-nombre.component';
import { ConfiguracionDocumentoCopiarConfiguracionComponent } from './components/configuracion-documento-copiar-configuracion/configuracion-documento-copiar-configuracion.component';
import { ConfiguracionDocumentoFirmanteComponent } from './components/configuracion-documento-firmante/configuracion-documento-firmante.component';
import { ConfiguracionDocumentoIncluirContenidoComponent } from './components/configuracion-documento-incluir-contenido/configuracion-documento-incluir-contenido.component';
import { ConfiguracionDocumentoPalabraClaveComponent } from './components/configuracion-documento-palabra-clave/configuracion-documento-palabra-clave.component';
import { ContenidoDocComponent } from './components/contenido-doc/contenido-doc.component';
import { DocDragComponent } from './components/doc-drag/doc-drag.component';
import { ConfiguracionDocumentoTextoExcluirComponent } from './components/configuracion-documento-texto-excluir/configuracion-documento-texto-excluir.component';
import { ConfiguracionDocumentoConfigurarCifComponent } from './components/configuracion-documento-configurar-cif/configuracion-documento-configurar-cif.component';
import { ConfiguracionDocumentoDatosCifComponent } from './components/configuracion-documento-datos-cif/configuracion-documento-datos-cif.component';
import { ConfiguracionDocumentoIconInfoComponent } from './components/configuracion-documento-icon-info/configuracion-documento-icon-info.component';
import { ConfiguracionDocumentoConfigurarNifComponent } from './components/configuracion-documento-configurar-nif/configuracion-documento-configurar-nif.component';
import { ConfiguracionDocumentoDatosNifComponent } from './components/configuracion-documento-datos-nif/configuracion-documento-datos-nif.component';
import { ConfiguracionFirmasTipoFirmaComponent } from './components/configuracion-firmas/configuracion-firmas-tipo-firma/configuracion-firmas-tipo-firma.component';
import { ConfiguracionFirmasDibujadaComponent } from './components/configuracion-firmas/configuracion-firmas-dibujada/configuracion-firmas-dibujada.component';
import { ConfiguracionFirmasDibujadaTrazadoComponent } from './components/configuracion-firmas/configuracion-firmas-dibujada-trazado/configuracion-firmas-dibujada-trazado.component';
import { ConfiguracionFirmasDibujadaTagComponent } from './components/configuracion-firmas/configuracion-firmas-dibujada-tag/configuracion-firmas-dibujada-tag.component';
import { ConfiguracionFirmasCertificadoComponent } from './components/configuracion-firmas/configuracion-firmas-certificado/configuracion-firmas-certificado.component';
import { ConfiguracionFirmasTipoFirmantesComponent } from './components/configuracion-firmas/configuracion-firmas-tipo-firmantes/configuracion-firmas-tipo-firmantes.component';
import { FirmasDibujoComponent } from './components/configuracion-firmas/firmas-dibujo/firmas-dibujo.component';
import { FirmasTemporalComponent } from './components/configuracion-firmas/firmas-temporal/firmas-temporal.component';
import { CasillasDibujoComponent } from './components/configuracion-formularios/casillas-dibujo/casillas-dibujo.component';
import { CasillasTemporalComponent } from './components/configuracion-formularios/casillas-temporal/casillas-temporal.component';
import { ConfiguracionDocumentoFormulariosComponent } from './components/configuracion-formularios/configuracion-documento-formularios/configuracion-documento-formularios.component';
import { ConfiguracionDocumentoConfigurarFirmaComponent } from './components/configuracion-firmas/configuracion-documento-configurar-firma/configuracion-documento-configurar-firma.component';
import { FormulariosFirmanteComponent } from './components/configuracion-formularios/formularios-firmante/formularios-firmante.component';
import { FormulariosGrupoCasillasComponent } from './components/configuracion-formularios/formularios-grupo-casillas/formularios-grupo-casillas.component';
import { CamposEditablesPageComponent } from './components/configuracion-campos-editables/campos-editables-page/campos-editables-page.component';
import { CamposEditablesFirmanteComponent } from './components/configuracion-campos-editables/campos-editables-firmante/campos-editables-firmante.component';
import { CamposEditablesAddCamposComponent } from './components/configuracion-campos-editables/campos-editables-add-campos/campos-editables-add-campos.component';
import { CamposTemporalComponent } from './components/configuracion-campos-editables/campos-temporal/campos-temporal.component';
import { CamposDibujoComponent } from './components/configuracion-campos-editables/campos-dibujo/campos-dibujo.component';
import { TituloOpcionConfDocsComponent } from './components/titulo-opcion-conf-docs/titulo-opcion-conf-docs.component';
import { TituloPasoConfDocComponent } from './components/titulo-paso-conf-doc/titulo-paso-conf-doc.component';
import { DocumentosFiltrosNuevosComponent } from './components/documentos-filtros-nuevos/documentos-filtros-nuevos.component';
import { SqpComponentsModule } from 'src/app/shared/components/sqp-components/sqp-components.module';



@NgModule({
  declarations: [
    DocumentosPageComponent,
    DocumentosBarraSuperiorComponent,
    DocumentosDataGridComponent,
    DocumentosFilterDetailsComponent,
    DocumentosFiltroSelectorComponent,
    DocumentosModalComponent,
    AddDocsDataGridComponent,
    ConfiguracionDocumentoPageComponent,
    ConfiguracionDocumentoAyudaSubidaComponent,
    ConfiguracionDocumentoProgresoComponent,
    ConfiguracionDocumentoBotoneraComponent,
    ConfiguracionDocumentoUploadComponent,
    ConfiguracionDocumentoNombreComponent,
    ConfiguracionDocumentoCopiarConfiguracionComponent,
    ConfiguracionDocumentoFirmanteComponent,
    ConfiguracionDocumentoIncluirContenidoComponent,
    ConfiguracionDocumentoPalabraClaveComponent,
    ContenidoDocComponent,
    DocDragComponent,
    ConfiguracionDocumentoTextoExcluirComponent,
    ConfiguracionDocumentoConfigurarCifComponent,
    ConfiguracionDocumentoDatosCifComponent,
    ConfiguracionDocumentoIconInfoComponent,
    ConfiguracionDocumentoConfigurarNifComponent,
    ConfiguracionDocumentoDatosNifComponent,
    ConfiguracionDocumentoConfigurarFirmaComponent,
    ConfiguracionFirmasTipoFirmaComponent,
    ConfiguracionFirmasDibujadaComponent,
    ConfiguracionFirmasDibujadaTrazadoComponent,
    ConfiguracionFirmasDibujadaTagComponent,
    ConfiguracionFirmasCertificadoComponent,
    ConfiguracionFirmasTipoFirmantesComponent,
    FirmasDibujoComponent,
    FirmasTemporalComponent,
    CasillasDibujoComponent,
    CasillasTemporalComponent,
    ConfiguracionDocumentoFormulariosComponent,
    FormulariosFirmanteComponent,
    FormulariosGrupoCasillasComponent,
    CamposEditablesPageComponent,
    CamposEditablesFirmanteComponent,
    CamposEditablesAddCamposComponent,
    CamposTemporalComponent,
    CamposDibujoComponent,
    TituloOpcionConfDocsComponent,
    TituloPasoConfDocComponent,
    DocumentosFiltrosNuevosComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    ReactiveFormsModule,
    MatDialogModule,
    DragDropModule,
    SqpComponentsModule
  ],

  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
 ]
})
export class DocumentosModule { }

