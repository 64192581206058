import { Injectable } from "@angular/core";
import { EndpointConfigV2 } from "src/environments/Configuration/EnpointConfig.v2";
import { environment } from "src/environments/environment";
import { InfoApi } from "src/environments/ModelosApis/InfoApi";
import { IListaEndpointsService } from "./Ports/i-lista-endpoints.service";

@Injectable({
    providedIn: 'root'
  })

  export class ListaEndpointsV2Service implements IListaEndpointsService{
    public get Endpoints(): InfoApi[]{
      EndpointConfigV2.forEach(x=>x.baseUrl=environment.ApiUrlV2);
      return EndpointConfigV2;
    }
  
  }