import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenInetrcptInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.interceptAsync(req, next));
  }

  async interceptAsync(request: HttpRequest<unknown>, next: HttpHandler) {
    // Obtenemos el token de la autorizacion de la consulta
    let requestCloned = request;
    let tieneHeader:boolean = request.headers.keys().indexOf("authorization") >=0 ;


    let authorization = request.headers.get("authorization");

    if(tieneHeader&&!authorization)  this.router.navigate(['/IniPage']);
    if(authorization){

      // Obtenemos el token y comprobamos su validez
      const token = authorization.toString().replace('','');

      if(this.loginService.tokenIsExpired() && !request.url.endsWith('RefreshToken'))
      {

        // Si el token está caducado y la consulta NO es RefreshToken, llamamos a RefreshToken
        if(this.loginService.refreshIsExpired()){
          //Vamos a login

          this.router.navigate(['/IniPage']);

          return throwError(new HttpErrorResponse({status:401,error:'Token caducado'})).toPromise();
        }
        await this.loginService.refreshToken().toPromise().then(x=>{
          requestCloned = request.clone({
            setHeaders: {
              'authorization': `Bearer ${ this.loginService.getUserLocalStorage().Token.Token }`
            }
          });
        });
      }
    }
    // Procesamos la consulta clonada y si el error es Unauthorized nos redirige al login

    return next.handle(requestCloned).pipe(
      catchError((err: HttpErrorResponse) => {

        console.error(err);
        switch(err.status){
          case 401:

            this.router.navigate(['/IniPage']);
            return from(next.handle(requestCloned));
            break;
          case 200: //Error si el resultado no es jso
          case 404:
             return from (next.handle(requestCloned));
              break;


          default:
            return throwError( err );
        }



      })
    ).toPromise();
  }
}
