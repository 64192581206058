import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.css']
})
export class SelectorComponent implements OnInit {

  @Input() textoOpcion1: string = "";
  @Input() iconoOpcion1: string = "";
  @Input() textoOpcion2: string = "";
  @Input() iconoOpcion2: string = "";
  @Input() active: boolean = false;
  @Input() forumlario: UntypedFormGroup = new UntypedFormGroup({});
  @Input() control : string = "";
  @Input() backgroundActive: boolean = false;
  @Output() mostrarSeleccion: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
    if(this.hayFormulario()){
      this.forumlario.controls[this.control].setValue(this.active);
    }
  }

  public click(){
    this.active= !this.active;
    if(this.hayFormulario()){
      this.forumlario.controls[this.control].setValue(this.active);
    }
    this.mostrarSeleccion.emit(this.active);
  }

  private hayFormulario():boolean{
    if(!this.control || this.control=="") return false;
    if(!this.forumlario||!this.forumlario.controls||Object.keys(this.forumlario.controls).length==0)return false;
    return true;

  }

  public getClasss(){
    return{
      'palanca-fondo':true,
      'palanca-fondo-activo':this.active,
      'palanca-fondo-inactivo':!this.active
    }
  }

}
