import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-boton-acceso',
  templateUrl: './boton-acceso.component.html',
  styleUrls: ['./boton-acceso.component.css']
})
export class BotonAccesoComponent {

  @Input() icono: string;
  @Input() tituloBoton: string;
  @Input() texto: string;
  @Input() accionBoton: string
  @Input() id: string;
  @Input() infoText: InnerHTML;

  constructor(private router: Router){}

  irOpcion(id:string){
    switch (id){
      case 'Envío estándar':
        this.router.navigate([`/panel/editar-configuracion-documento`])
    }

  }

}
