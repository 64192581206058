<!-- <mat-form-field style="width: 100%">
    <mat-icon matPrefix>
        <svg class="icon-search"><use xlink:href="#icon-search"></use></svg>
    </mat-icon>
    <input matInput type="search" placeholder="Buscar" [(ngModel)]="textoBuscar" (keyup)="buscar()" autofocus>
    <mat-icon matSuffix style="cursor: pointer;" (click)="limpiarTexto()">
        <svg class="icon-close"><use xlink:href="#icon-close"></use></svg>
    </mat-icon>
</mat-form-field> -->
<div>
  <input id="buscador" [ngStyle]="{'width.px': inputWidth}" type="text" placeholder="Buscar" [(ngModel)]="textoBuscar" (keyup)="buscar()">
  <svg class="hs-lupa"><use xlink:href="#hs-lupa"></use></svg>
  <!-- <span (click)="limpiarTexto()"> -->
    <svg class="hs-close" [ngStyle]="{'display':textoBuscar!=''?'block':'none'}" (click)="limpiarTexto()"><use xlink:href="#icon-close"></use></svg>
  <!-- </span> -->
</div>

