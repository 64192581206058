import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmpresasModel } from '../../models/empresas-model';
import { DatosEmpresasService } from '../../services/datos-empresas.service';
import { RecuperaEmpresasApiService } from '../../services/recupera-empresas-api.service';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { validacionCodigoNuevo } from '../../services/custom-validators-empresas';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerModalComponent } from 'src/app/shared/components/others/spinner-modal/spinner-modal.component';

@Component({
  selector: 'app-nueva-empresa-modal',
  templateUrl: './nueva-empresa-modal.component.html',
  styleUrls: ['./nueva-empresa-modal.component.css']
})
export class NuevaEmpresaModalComponent implements OnInit {

  formEmpresa: FormGroup = new FormGroup({});
  empresa: EmpresasModel;
  empresas: EmpresasModel[];

  constructor(private fb: FormBuilder,
              private datosEmpresasService: DatosEmpresasService,
              private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private datosEmpresasApiService: RecuperaEmpresasApiService,
              private _snackBar: MatSnackBar) {
                this.iniciarFormularioVacio();
              }

  ngOnInit(): void {
    this.getEmpresas();
  }

  getEmpresas(){
    //llamamos directamente al servicio de la api, porque no reconoce getEmpresas() como función al pasar por la interface IRecuperarEmpresasService
    this.datosEmpresasApiService.getEmpresas().subscribe(
      next => {
        this.empresas = next;
        this.iniciarFormularioEmpresa();
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  iniciarFormularioVacio(){
    this.formEmpresa = this.fb.group({
      nombreEmpresa:[''],
      codigoEmpresa:[''],
      cif: [''],
      tipoDocumento: [''],
      nombreEmpleado:[''],
      apellido1Empleado:[''],
      apellido2Empleado:[''],
      emailEmpleado:[''],
      // logo: ['']
    })
  }

  iniciarFormularioEmpresa(){
    this.formEmpresa = this.fb.group({
      nombreEmpresa:['', Validators.required],
      codigoEmpresa:['', validacionCodigoNuevo(this.empresas, 'codigoEmpresa')],
      cif: ['', [Validators.required, validacionCodigoNuevo(this.empresas, 'cif')]],
      tipoDocumento: [''],
      nombreEmpleado:[''],
      apellido1Empleado:[''],
      apellido2Empleado:[''],
      emailEmpleado:['', Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)],
      // logo: ['']
    })
  }

  get codigoEmpresa(){ return this.formEmpresa.controls['codigoEmpresa']}
  get cif(){ return this.formEmpresa.controls['cif']}

  guardarNuevaEmpresa(){
    if(this.formEmpresa.invalid){
      this.formEmpresa.markAllAsTouched();
      return
    }

    this.empresa = {
      codigoEmpresa:this.formEmpresa?.controls['codigoEmpresa']?.value,
      tipoDocumento: this.formEmpresa?.controls['tipoDocumento']?.value,
      cif:this.formEmpresa?.controls['cif']?.value,
      nombreEmpresa:this.formEmpresa?.controls['nombreEmpresa']?.value,
      nombreEmpleado:this.formEmpresa?.controls['nombreEmpleado']?.value,
      emailEmpleado:this.formEmpresa?.controls['emailEmpleado']?.value,
      apellido1Empleado:this.formEmpresa?.controls['apellido1Empleado']?.value,
      apellido2Empleado:this.formEmpresa?.controls['apellido2Empleado']?.value,
      logo:null
    };

    const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
    this.datosEmpresasApiService.guardaEmpresa(this.empresa).subscribe(
      next => {
        dialogRefSpinner.close();
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'Empresa guardada correctamente',
          },
        });
        this.dialogRef.close('OK');
      },
      error => {
        dialogRefSpinner.close();
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'Error. La empresa no se ha guardado',
            alert: true
          },
        });
        this.dialogRef.close();
      });

  }

  isCampoValido(campo:string){
    return this.formEmpresa.controls[campo]?.errors && this.formEmpresa.controls[campo]?.touched;
  }
}
