
<div class="flex">
  <!-- <input type="radio" name="select" #radio (click)="checkRadio()"> Formulario -->
  <!-- <div class="flex-input"> -->
    <!-- <div class="" style="display: flex;"> -->
      <label style="font-weight: 500;">Formulario</label>
      <form [formGroup]="formulario">
        <input id="inputNombreId" type="text" formControlName="nombre" (keyup)="guardaNombre()" [ngClass]="formularioValidacionesDocsService.isCampoValidoArrayFormularios(this.indexMostrar, obtenerIndexNifArray()) ? 'errorInput':''">
      </form>
    <!-- </div> -->

    <svg id="addFormularioId" class="icon-plus" (click)="addFormulario()">
      <use xlink:href="#icon-plus"></use>
    </svg>
  <!-- </div> -->

  <!-- <svg class="icon-delete">
    <use xlink:href="#icon-delete-empty"></use>
  </svg> -->
</div>
<p class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValidoArrayFormularios(this.indexMostrar, obtenerIndexNifArray())">
  {{formularioValidacionesDocsService.mensajeErrorNombreFormulario}}
</p>
<div class="flex-anadir">
  <div #botonAnadir>
    <app-boton-accion id="btnAnadirId" [icono]="'icon-plus'" [titulo]="'añadir campo'" (botonClicked)="getPosicion($event)"></app-boton-accion>
  </div>
</div>
<!-- <pre>form: {{formulario.value | json}}</pre> -->
<app-casillas-temporal *ngFor="let casilla of casillaMoverArray" [posicionCasilla]="posicionCasilla"></app-casillas-temporal>

