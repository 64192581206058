import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-titulo-paso-conf-doc',
  templateUrl: './titulo-paso-conf-doc.component.html',
  styleUrls: ['./titulo-paso-conf-doc.component.css']
})
export class TituloPasoConfDocComponent {

  @Input() icono: string = '';
  @Input() titulo: string = '';

}
