import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColDef } from 'ag-grid-community';
import { GridColDefsAddTipoDocs } from '../../models/grid-col-defs-add-tipo-docs';
import { TipoDocumento } from '../../models/tipo-documento';


@Component({
  selector: 'app-add-docs-data-grid',
  templateUrl: './add-docs-data-grid.component.html',
  styleUrls: ['./add-docs-data-grid.component.css']
})
export class AddDocsDataGridComponent implements OnInit {

  @Input() documentos: TipoDocumento[] = [];
  @Output() refresh:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() seleccionDocs: EventEmitter<any> = new EventEmitter<any>();
  @Input() seleccion: string = '';

  api: any;
  apiCol: any;
  columnDefs: ColDef[] = new GridColDefsAddTipoDocs().columnDefs
  docsSeleccionados: TipoDocumento[] = [];
  @Input() oldDocsSelected: TipoDocumento[] = [];
  textoBuscar: boolean;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  cargaApi(arg: any){
    this.api = arg.api;
    this.apiCol = arg.columnApi;
    this.api.sizeColumnsToFit();
    if(this.oldDocsSelected){
      this.api.forEachNodeAfterFilter((node:any) => {
        let index = this.oldDocsSelected.findIndex(x => x.idTipoDocumento == node.data.idTipoDocumento)
        if(index > -1) {
          node.setSelected(true);
        }
      });
    }

  }

  onSelectionChanged(event:any){
    this.docsSeleccionados = this.api.getSelectedRows();
    let envioDocs = {
      docsSeleccionados: this.docsSeleccionados,
      docsOrigen: this.seleccion
    }
    this.seleccionDocs.emit(envioDocs)
  }


  onQuickFilterChanged() {
    this.api.setGridOption('quickFilterText', (document.getElementById('quickFilter') as HTMLInputElement).value);
    if((document.getElementById('quickFilter') as HTMLInputElement).value !== '') this.textoBuscar = true;
    else this.textoBuscar = false;
  }

  borrarTexto(){
    this.api.setGridOption('quickFilterText', '');
    (document.getElementById('quickFilter') as HTMLInputElement).value = '';
    this.textoBuscar = false;
  }
}
