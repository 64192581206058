import { Injectable } from '@angular/core';
import { DatosPdf } from '../models/datos-pdf';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DatosBodyExportar } from '../models/datos-body-exportar';

@Injectable({
  providedIn: 'root'
})

export class ExportadorPdfService {

  constructor() { }

  exportaPdf(configuracion: DatosPdf, datosBody: DatosBodyExportar[]){
  // Cargamos las columnas con el campo a mostrar y su cabecera
  let columns: any[] = configuracion.registros;

  // Cargamos las filas y obtenemos la fecha a mostrar en el formato correcto en el caso de firmas
  let body: any[] = datosBody;

  // Generamos el pdf con los datos obtenidos
  let doc;
  if(configuracion.landscape) doc = new jsPDF({orientation: "landscape"})
  else doc = new jsPDF({orientation: "portrait"})

  autoTable(doc, {
    body: body,
    columns: columns,
    headStyles: {
      fillColor: [247,197,72],
      textColor:[7,14,40],
      fontStyle: 'normal'
    },
    alternateRowStyles: {
      fillColor: [255,250,239]
    }
  });

  doc.save(configuracion.nombreFichero)
  }
}
