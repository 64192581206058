import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { CentrosModel } from 'src/app/pages/configuracion/maestros/centros/models/centros-model';
import { IRecuperarCentrosService } from './ports/i-recuperar-centros-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConversionDatosApiService } from 'src/app/shared/services/conversion-datos-api.service';
import { RecuperaEmpresasApiService } from '../../empresas/services/recupera-empresas-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmpresasModel } from '../../empresas/models/empresas-model';


@Injectable({
  providedIn: 'root'
})
export class RecuperaCentrosApiService implements IRecuperarCentrosService{

  private readonly URL_GET_CENTROS: string = this.endpointService.UrlGetCentros;
  private readonly URL_GUARDA_CENTRO: string = this.endpointService.UrlGuardaCentro;
  private readonly URL_GUARDA_CENTROS: string = this.endpointService.UrlGuardaCentros;
  private readonly URL_DELETE_CENTRO: string = this.endpointService.UrlDeleteCentro;
  centros: CentrosModel[];
  countCentros: number = 0;
  empresas: EmpresasModel[];

  constructor(private client: HttpClient,
              private loginService: LoginService,
              private endpointService: EndpointService,
              private conversionDatosApiService: ConversionDatosApiService,
              private datosEmpresasApiService: RecuperaEmpresasApiService,
              private _snackBar: MatSnackBar) { }

  getCentros(refresh?: boolean): Observable<CentrosModel[]> {
    if (this.centros && !refresh) {
      return of(this.centros);
    } else {
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + this.loginService.getUserLocalStorage().Token.Token
        })
      };
      return this.client.get(this.URL_GET_CENTROS, options).pipe(
        switchMap((response: any) => {
          if (response) {
            this.centros = this.conversionDatosApiService.mapCentrosEntrada(response);
            return this.datosEmpresasApiService.getEmpresas().pipe(
              map(nextEmpresas => {
                this.centros.forEach(x => {
                  if (x.codigoEmpresa) {
                    x.nombreEmpresa = nextEmpresas.find(y => x.codigoEmpresa == y.codigoEmpresa)?.nombreEmpresa;
                  }
                });
                return this.centros;
              }),
              catchError(error => {
                this._snackBar.open(error, 'Cerrar', { duration: 3000 });
                return throwError(error);
              })
            );
          } else {
            throw new Error(response.Message);
          }
        })
      );
    }
  }

  guardaCentro(data:any): Observable<void>{
    data = this.conversionDatosApiService.mapCentrosSalida(data);
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.post<void>(this.URL_GUARDA_CENTRO, data, options)
  }

  guardaCentros(data:CentrosModel[]): Observable<void>{

    let dataEnvio:any[] = [];
    if(data==null || data.length==0 ) return of();
    data.forEach(x=> dataEnvio.push(this.conversionDatosApiService.mapCentrosSalida(x)));
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.post<void>(this.URL_GUARDA_CENTROS, dataEnvio, options)
  }

  deleteCentro(data: CentrosModel): Observable<void> {
    let url = this.URL_DELETE_CENTRO.replace("{codigoCentro}", data.codigoCentro).replace("{codigoEmpresa}", data.codigoEmpresa);

    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.delete<void>(url, options)
  }

}
