import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatosCentrosService } from 'src/app/pages/configuracion/maestros/centros/services/datos-centros.service';
import { RecuperaEmpresasMockService } from 'src/app/pages/configuracion/maestros/empresas/services/recupera-empresas-mock.service';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { DatosEmpleadosService } from '../../../services/mantenimiento/datos-empleados.service';
import { formEmpleadoValidators, validacionCodigo } from '../../../services/mantenimiento/customValidatorsEmpleados';
import { RecuperaEmpresasApiService } from 'src/app/pages/configuracion/maestros/empresas/services/recupera-empresas-api.service';

@Component({
  selector: 'app-empleados-modal',
  templateUrl: './empleados-modal.component.html',
  styleUrls: ['./empleados-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmpleadosModalComponent implements OnInit {

  formEmpleado: FormGroup = new FormGroup({});
  isEditar: boolean = false;
  nuevo: boolean = false;
  titulosArray: string[]=[
    "Detalle de empleado",
    "Edición de empleado",
    "Nuevo empleado"
  ]
  tituloModal: string = "";
  empresasList: string[] = [];
  centrosList: string[] = [];
  detallesIdentificativos: boolean = false;
  detallesDomicilio: boolean = false;
  detallesLaborales: boolean = false;
  detallesPersonales: boolean = false;
  fechaAltaEntrada: string;
  fechaNacimientoEntrada: string;
  fechaAltaPasarComponente: Date;

  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA)
              public info: any,
              private _snackBar: MatSnackBar,
              private fb: FormBuilder,
              private datosEmpresasApiService: RecuperaEmpresasApiService,
              private datosCentrosService: DatosCentrosService,
              public datosEmpleadosService: DatosEmpleadosService,
              private eRef: ElementRef) { }

  ngOnInit(): void {
    // this.getListaEmpresasCentros();
    this.getEmpresas();
    this.getCentros();

    this.iniciarFormularioEmpleado();
    this.nuevo = this.info.nuevo;
    this.cambiarTituloModal();
    // this.convertirFechaEntrada(this.fechaAlta.value, 'alta');
    // this.convertirFechaEntrada(this.fechaNacimiento.value, 'nacimiento');
  }

  cambiarTituloModal(){
    // if(this.isEditar == false) this.tituloModal = this.titulosArray[0]
    if(this.isEditar == false) this.tituloModal = `${this.nombre} ${this.apellido1}`
    // if(this.isEditar == true && this.nuevo == false) this.tituloModal = this.titulosArray[1]
    if(this.isEditar == true && this.nuevo == false) this.tituloModal = this.nombre
    if(this.nuevo == true) this.tituloModal = this.tituloModal = this.titulosArray[2]
  }

  iniciarFormularioEmpleado(){
    this.formEmpleado = this.fb.group({
      // codigo:[this.info.empleado.codigo, validacionCodigo(this.info?.empleados, this.info?.empleado)],
      codigo:[this.info.empleado.codigo? this.info.empleado.codigo:''],
      tipoDocumento:[this.info.empleado.tipoDocumento],
      nif:[this.info.empleado.nif, Validators.required],
      fechaNacimiento:[this.info.empleado.fechaNacimiento, Validators.required],
      // fechaNacimiento:[this.info.empleado.fechaNacimiento, [Validators.required, Validators.pattern(/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/)]],
      nombre:[this.info.empleado.nombre, Validators.required],
      apellido1:[this.info.empleado.apellido1, Validators.required],
      apellido2:[this.info.empleado.apellido2],
      nacionalidad:[this.info.empleado.nacionalidad],
      lugarNacimiento:[this.info.empleado.lugarNacimiento],
      estadoCivil:[this.info.empleado.estadoCivil],
      sexo:[this.info.empleado.sexo],
      tipoPersona:[this.info.empleado.tipoPersona],
      empresa:[this.info.empleado.codigoEmpresa ? `${this.info.empleado.codigoEmpresa}-${this.info.empleado.nombreEmpresa}`: null, Validators.required],
      centro:[this.info.empleado.codigoCentro ? `${this.info.empleado.codigoCentro}-${this.info.empleado.nombreCentro}`:null, Validators.required],
      provincia:[this.info.empleado.provincia],
      municipio:[this.info.empleado.municipio],
      codigoPostal:[this.info.empleado.codigoPostal],
      sigla:[this.info.empleado.sigla],
      numero:[this.info.empleado.numero],
      escalera:[this.info.empleado.escalera],
      piso:[this.info.empleado.piso],
      puerta:[this.info.empleado.puerta],
      mailPersonal:[this.info.empleado.mailPersonal, [Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      extensionTelefonoPersonal:[this.info.empleado.extensionTelefonoPersonal],
      telefonoPersonal:[this.info.empleado.telefonoPersonal],
      mailProfesional:[this.info.empleado.mailProfesional],
      extensionTelefonoProfesional:[this.info.empleado.extensionTelefonoProfesional],
      telefonoProfesional:[this.info.empleado.telefonoProfesional],
      fechaAlta:[this.info.empleado.fechaAlta, Validators.required],
      // fechaAlta:[this.info.empleado.fechaAlta, [Validators.required, Validators.pattern(/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/)]],
      puestoTrabajo:[this.info.empleado.puestoTrabajo],
      categoria:[this.info.empleado.categoria],
      convenio:[this.info.empleado.convenio]
    },{ validator: [formEmpleadoValidators(this.info?.empleados, this.info?.empleado)]} as AbstractControlOptions)
    // this.fechaAltaEntrada = this.fechaAlta?.value.split(' ')[0];
    // this.fechaAltaPasarComponente =
    this.fechaAltaEntrada = new Date(this.fechaAlta?.value.split(' ')[0]).toLocaleDateString();
    this.fechaNacimientoEntrada = new Date(this.fechaNacimiento?.value.split(' ')[0]).toLocaleDateString();
  }

  get codigo(){ return this.formEmpleado.controls['codigo']}
  get tipoDocumento(){ return this.formEmpleado.controls['tipoDocumento'].value}
  get nif(){ return this.formEmpleado.controls['nif'].value}
  get fechaNacimiento(){ return this.formEmpleado.controls['fechaNacimiento']}
  get nombre(){ return this.formEmpleado.controls['nombre'].value}
  get apellido1(){ return this.formEmpleado.controls['apellido1'].value}
  get apellido2(){ return this.formEmpleado.controls['apellido2'].value}
  get nacionalidad(){ return this.formEmpleado.controls['nacionalidad'].value}
  get lugarNacimiento(){ return this.formEmpleado.controls['lugarNacimiento'].value}
  get estadoCivil(){ return this.formEmpleado.controls['estadoCivil'].value}
  get sexo(){ return this.formEmpleado.controls['sexo'].value}
  get tipoPersona(){ return this.formEmpleado.controls['tipoPersona'].value}
  get empresa(){ return this.formEmpleado.controls['empresa'].value}
  get centro(){ return this.formEmpleado.controls['centro'].value}
  get provincia(){ return this.formEmpleado.controls['provincia'].value}
  get municipio(){ return this.formEmpleado.controls['municipio'].value}
  get codigoPostal(){ return this.formEmpleado.controls['codigoPostal'].value}
  get sigla(){ return this.formEmpleado.controls['sigla'].value}
  get numero(){ return this.formEmpleado.controls['numero'].value}
  get escalera(){ return this.formEmpleado.controls['escalera'].value}
  get piso(){ return this.formEmpleado.controls['piso'].value}
  get puerta(){ return this.formEmpleado.controls['puerta'].value}
  get mailPersonal(){ return this.formEmpleado.controls['mailPersonal'].value}
  get extensionTelefonoPersonal(){ return this.formEmpleado.controls['extensionTelefonoPersonal'].value}
  get telefonoPersonal(){ return this.formEmpleado.controls['telefonoPersonal'].value}
  get mailProfesional(){ return this.formEmpleado.controls['mailProfesional'].value}
  get extensionTelefonoProfesional(){ return this.formEmpleado.controls['extensionTelefonoProfesional'].value}
  get telefonoProfesional(){ return this.formEmpleado.controls['telefonoProfesional'].value}
  get fechaAlta(){ return this.formEmpleado.controls['fechaAlta']}
  get puestoTrabajo(){ return this.formEmpleado.controls['puestoTrabajo'].value}
  get categoria(){ return this.formEmpleado.controls['categoria'].value}
  get convenio(){ return this.formEmpleado.controls['convenio'].value}

  isCampoValido(campo:string){
    return this.formEmpleado.controls[campo].errors && this.formEmpleado.controls[campo].touched;
  }

  editarEmpleado(){
    this.isEditar = true;
    this.detallesDomicilio = true;
    this.detallesIdentificativos = true;
    this.detallesLaborales = true;
    this.detallesPersonales = true;
    this.cambiarTituloModal();
  }

  guardarCambiosEmpleado(){

    if(this.formEmpleado.invalid){
      this.detallesDomicilio = true;
      this.detallesIdentificativos = true;
      this.detallesLaborales = true;
      this.detallesPersonales = true;
      this.formEmpleado.markAllAsTouched();
      return
    }
    let objetoEmpleado: any = this.formEmpleado.value;
    objetoEmpleado.codigoEmpresa = this.empresa.split('-')[0];
    objetoEmpleado.nombreEmpresa = this.empresa.split('-')[1];
    objetoEmpleado.codigoCentro = this.centro.split('-')[0];
    objetoEmpleado.nombreCentro = this.centro.split('-')[1];

    // console.log('fechaAlta antes de formatre')
    // const [dayN, monthN, yearN] = this.fechaNacimiento.value.split('/');
    // const formattedDateN = new Date(yearN, monthN-1, dayN);
    // formattedDateN.setHours(formattedDateN.getHours() - formattedDateN.getTimezoneOffset() / 60);

    // objetoEmpleado.fechaNacimiento = formattedDateN.toISOString();

    // console.log('this.fechaAlta.value: ', this.fechaAlta.value)
    // const [dayA, monthA, yearA] = this.fechaAlta.value.split('/');
    // const formattedDateA = new Date(yearA, monthA-1, dayA);
    // formattedDateA.setHours(formattedDateA.getHours() - formattedDateA.getTimezoneOffset() / 60);

    // objetoEmpleado.fechaAlta = formattedDateA.toISOString();
    // console.log('objetoEmpleado: ', objetoEmpleado.fechaAlta);
    delete objetoEmpleado.empresa;
    delete objetoEmpleado.centro;

    this.isEditar = !this.isEditar;

    this.datosEmpleadosService.updateEmpleado(objetoEmpleado).subscribe(
      ok => {
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'Empleado guardado correctamente',
          },
        });
        this.dialogRef.close('OK');
        this.datosEmpleadosService.refreshEmpleados = true;
      },
      error => {
        console.error("error", error);
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'Error. El empleado no se ha guardado',
            alert: true
          },
        });
      }
    );
  }

  // getListaEmpresasCentros(){
  //   this.datosEmpresasApiService.empresas?.forEach(x=> this.empresasList.push(`${x.codigoEmpresa}-${x.nombreEmpresa}`));
  //   this.datosCentrosService.centros?.forEach(x=> this.centrosList.push(`${x.codigoCentro}-${x.nombreCentro}`))
  // }

  getEmpresas(){
    this.datosEmpresasApiService.getEmpresas().subscribe(
      next => {
        let empresas = next;
        empresas.forEach(x=> this.empresasList.push(`${x.codigoEmpresa}-${x.nombreEmpresa.toUpperCase()}`))

      },
      error => {
        console.error();
      }
    )
  }

  getCentros(){
    this.datosCentrosService.getCentros().subscribe(
      next=>{
        let centros = next;
        centros.forEach(x=> this.centrosList.push(`${x.codigoCentro}-${x.nombreCentro.toUpperCase()}`))
      },
      error => {
        console.error();
      }
    )
  }

  mostrarDetalles(tipo: string){
    switch (tipo){
      case 'identificativos':
        this.detallesIdentificativos = !this.detallesIdentificativos;
      break
      case 'domicilio':
        this.detallesDomicilio = !this.detallesDomicilio;
      break
      case 'laborales':
        this.detallesLaborales = !this.detallesLaborales;
      break
      case 'personales':
        this.detallesPersonales = !this.detallesPersonales;
      break
    }
  }

  // deleteEmpleado(){
  //   this.datosEmpleadosService.deleteEmpleado(this.info.empleado.codigo).subscribe(
  //     ok => {
  //       const dialogRef = this.dialog.open(ModalMessageComponent, {
  //         data: {
  //           mensaje: 'Empleado eliminado correctamente',
  //         },
  //       });
  //       this.dialogRef.close('OK');
  //       this.datosEmpleadosService.refreshEmpleados = true;
  //     },
  //     error => {
  //       console.error("error", error);
  //       const dialogRef = this.dialog.open(ModalMessageComponent, {
  //         data: {
  //           mensaje: 'Error. El empleado no se ha eliminado',
  //           alert: true
  //         },
  //       });
  //     }
  //   );
  // }

  // onKeyUp(event: KeyboardEvent, fecha: string, tipo:string){
  //   let fechaResultado;
  //   switch(tipo){
  //     case 'alta':
  //       if(event.key=='Backspace') this.fechaAlta.setValue(fecha)
  //       else if(fecha.length==2 || fecha.length==5) this.fechaAlta.setValue(`${fecha}/`);
  //       if(this.fechaAlta.value==''){
  //         this.fechaAlta.setValue(null)
  //       }
  //     break
  //     case 'nacimiento':
  //       if(event.key=='Backspace') this.fechaNacimiento.setValue(fecha)
  //       else if(fecha.length==2 || fecha.length==5) this.fechaNacimiento.setValue(`${fecha}/`);
  //       if(this.fechaNacimiento.value==''){
  //         this.fechaNacimiento.setValue(null)
  //       }
  //     break
  //   }

  // }

  // convertirFechaEntrada(fecha: string, tipo:string){
  //   if(fecha){
  //     let fechaNueva = new Date(fecha).toLocaleDateString("en-GB");
  //     if(tipo=='alta') this.fechaAlta.setValue(fechaNueva);
  //     if(tipo=='nacimiento') this.fechaNacimiento.setValue(fechaNueva);
  //   }
  // }

  setFecha(event:any, tipo:string){
    if(tipo=='alta') this.fechaAlta.setValue(event);
    if(tipo=='nacimiento') this.fechaNacimiento.setValue(event);
  }

}
