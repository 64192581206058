import { Component, OnInit, Input } from '@angular/core';
import { nullFirma, Firmas } from '../../models/firmas';

@Component({
  selector: 'app-detalle-firmas-errores',
  templateUrl: './detalle-firmas-errores.component.html',
  styleUrls: ['./detalle-firmas-errores.component.css']
})
export class DetalleFirmasErroresComponent implements OnInit {

  @Input()  firma:Firmas = nullFirma();
  constructor() { }

  ngOnInit(): void {
  }

}
