
<!-- <svg [ngClass]="{'color-grey':isGrey}" (click)="clicked()"> -->
<svg (click)="clicked()">
    <use [attr.xlink:href]="icono|iconoRef"></use>
</svg>


<!-- <div class="icono-superior" (click)="clicked()" title="{{id}}">
    <img src="{{icono}}">
</div> -->
