<div class="barra-flex-space-between">
  <h1>Gestión de envíos</h1>
  <div class="acciones">
    <app-barra-descargas (botonClicked)="clickBarraSuperior($event)"></app-barra-descargas>
    <app-boton-accion [id]="'nuevo envio'" [icono]="'hs-send'" [titulo]="'nuevo envío'" [outline]="false" (click)="irASubida()"></app-boton-accion>
  </div>
</div>
<div style="display: flex;">
  <app-panel-filtro-lateral (ocultoEventEmitter)="ocultar($event)"
                            [resumen]="firmasResumen"
                            (clickEstado)="estadosFirmaService.clickEstadoEnvio($event)"
                            (clickVerNuevos)="verNuevos($event)"
                            [verNuevasParam]="verNuevasParam"
                            (verSandbox)="selectVerEnviosEntornoSandbox($event)"
                            [verEnviosEntornoSandbox]="verEnviosEntornoSandbox"
                            [limpiarEstados]="limpiarEstados"
                            [resumenInicial]="firmasResumenInicial"
                            ></app-panel-filtro-lateral>
  <div class="main">
    <div class="barra-flex-space-between">
      <div class="flex-center-items">
        <app-input-buscador (buscador)="buscarFirmas($event)" [textoBuscar]="textoBuscar"></app-input-buscador>
        <app-firmas-filtros-nuevos [isEntornoSandbox]="verEnviosEntornoSandbox"
                                   (filtrarFirmas)="filtrarFirmasNuevoFiltro($event)"
                                   [verNuevasParam]="verNuevasParam"
                                   [textoBuscar]="textoBuscar"
                                   [tiposDosierList]="tiposDosier"
                                   [empresasList]="empresas"
                                   [centrosList]="centros"
                                   (switchOffNuevas)="switchOffNuevas($event)"
                                   (switchOffSandbox)="switchOffSandbox($event)"
                                   [verEstado]="estadosFirmaService.verEstado"
                                   (limpiarBuscador)="buscarFirmas($event)"
                                   (limpiarTodoEmitter)="limpiarTodosEstados($event)"></app-firmas-filtros-nuevos>
      </div>
    </div>
    <!-- <app-data-grid-panel [firmas]="firmas" (refresh)="refresh()"></app-data-grid-panel> -->
    <app-sqp-grid-component [data]="firmas"
                            (refresh)="refresh()"
                            [columnDefs]="columnDefs"
                            [seleccion]="'single'"
                            (abrirModalDataEmitter)="selectRowChanged($event)"
                            [gridOptions]="gridOptions"
                            [tipoData]="'firmas'"></app-sqp-grid-component>
  </div>
</div>
