import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-configuracion-documento-progreso',
  templateUrl: './configuracion-documento-progreso.component.html',
  styleUrls: ['./configuracion-documento-progreso.component.css']
})
export class ConfiguracionDocumentoProgresoComponent implements OnInit, OnChanges {

  @Input() elementos: string[] = [];
  stepActive: number;
  @Input() finalizarProceso: boolean = false;
  @Output() ocultoEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  oculto: boolean = false;

  steps: number[] = [1,2,3,4,5,6,7,8,9,10,11]
  tick: boolean = false;
  active: boolean = false;
  @Input() indexStep: number;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges){
    this.stepActive = changes.indexStep.currentValue
  }

  getClass(index:number){
    if(index == this.stepActive) return 'step-active'
    else if(index < this.stepActive) {
      return 'step-done'
    }
    return ''
  }

  getStepClass(index:number){
    if(index == this.stepActive) return ''
    else if(index < this.stepActive) {
      return 'step-active'
    }
    return ''
  }

  getStepClassLine(index:number){
    if(index == this.stepActive) return ''
    else if(index < this.stepActive) {
      return 'step-active-line'
    }
    return ''
  }

  getTick(index:number){
    if(index < this.stepActive) return 'true'
    return ''
  }

}
