<!-- <div class="mainPanel">
  <div class="main">
    <div class="BarraIconos">
      <app-titulo-opcion titulo="Documentos configurados" icono2="#icon-config" icono="#icon-doc"></app-titulo-opcion>
      <app-documentos-barra-superior (botonClicked)="clickBarraSuperior($event)"></app-documentos-barra-superior>
      <div class="main-title-right flexRight">
        <app-selector [textoOpcion1]="'Todos'" [textoOpcion2]="'Incompletos'" [active]="verIncompletos" [control]="'estado'" (mostrarSeleccion)="seleccionFiltroChange($event)"></app-selector>
    </div>
    </div>
    <app-barra-info [texto]="'Sube documentos, envíalos, consulta el estado del proceso, genera informes, cancela la firma de documentos no firmados o descarga los firmados.'"></app-barra-info>
    <div class="main-container">
      <div class="main-content">
        <app-documentos-filter-details [filtrosActivos]="datosDocumentosService.filtros" (qitarFiltro)="quitarFiltro($event)"></app-documentos-filter-details>
        <app-documentos-data-grid [tipoDocs]="tipoDocs" (refresh)="refresh()"></app-documentos-data-grid>
      </div>
    </div>
  </div>
</div>
<app-barra-footer [id]="'nuevo envio configurado'"
                  [icono]="'icon-plus'"
                  [titulo]="'configurar nuevo documento'"
                  (botonClicked)="configuraNuevoDocumento()"></app-barra-footer> -->

<div class="barra-flex-space-between">
  <h1>Documentos configurados</h1>
  <div class="acciones">
    <app-documentos-barra-superior (botonClicked)="clickBarraSuperior($event)"></app-documentos-barra-superior>
    <app-boton-accion [id]="'nuevo documento configurado'" [icono]="'icon-plus'" [titulo]="'nuevo documento'" [outline]="false" (click)="configuraNuevoDocumento()"></app-boton-accion>
  </div>
</div>
<div class="info">Sube documentos, envíalos, consulta el estado del proceso, genera informes, cancela la firma de documentos no firmados o descarga los firmados.</div>
<div class="main">
  <div class="barra-flex-space-between">
    <div class="flex-center-items">
      <app-input-buscador (buscador)="buscarDocumentos($event)" [textoBuscar]="textoBuscar"></app-input-buscador>
      <app-documentos-filtros-nuevos (filtrarDocumentos)="filtrarDocumentos($event)"></app-documentos-filtros-nuevos>
      <!-- <app-documentos-filter-details [filtrosActivos]="datosDocumentosService.filtros" (qitarFiltro)="quitarFiltro($event)"></app-documentos-filter-details> -->
    </div>
    <!-- <app-selector [textoOpcion1]="'Todos'" [textoOpcion2]="'Incompletos'" [active]="verIncompletos" [control]="'estado'" (mostrarSeleccion)="seleccionFiltroChange($event)"></app-selector> -->

  </div>
  <!-- <app-documentos-data-grid [tipoDocs]="tipoDocs" (refresh)="refresh()"></app-documentos-data-grid> -->
  <app-sqp-grid-component [data]="tipoDocs"
                          (refresh)="refresh()"
                          [columnDefs]="columnDefs"
                          [seleccion]="'single'"
                          (abrirModalDataEmitter)="abrirDetallesDocumento($event)"></app-sqp-grid-component>
</div>
