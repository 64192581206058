<!-- <h5>TIPO DE FIRMA</h5> -->
<p>¿Qué tipo de firma realizará este firmante?</p>
<form [formGroup]="formFirma">
  <div class="input-firmante">
    <div class="input-radio">
      <input type="radio" formControlName="tipoFirma" [value]="'DIBUJADA'">
    </div>
    <span>
      <svg class="icon">
        <use xlink:href="#icon-signature"></use>
      </svg>
    </span>
    <label for="tipoFirma">Firma dibujada</label>
  </div>
  <p class="infoFirma">Firma avanzada con biometría</p>
  <div class="input-firmante">
    <div class="input-radio">
      <input type="radio" formControlName="tipoFirma" [value]="'CERTIFICADO'">
    </div>
    <span>
      <svg class="icon">
        <use xlink:href="#icon-certificate"></use>
      </svg>
    </span>
    <label for="tipoFirma">Certificado digital</label>
  </div>
  <div class="input-firmante">
    <div class="input-radio">
      <input type="radio" formControlName="tipoFirma" [value]="'VALIDADOR'" (click)="checkValidador()">
    </div>
    <span>
      <svg class="icon-tick">
        <use xlink:href="#icon-tick"></use>
      </svg>
    </span>
    <label for="tipoFirma">Validador</label>
  </div>
  <div class="input-firmante">
    <div class="input-radio">
      <input type="radio" formControlName="tipoFirma" [value]="'SIN FIRMA'">
    </div>
    <span>
      <svg class="icon">
        <use xlink:href=""></use>
      </svg>
    </span>
    <label for="tipoFirma">Sin firma</label>
  </div>
  <div *ngIf="tipoFirma.value == null">
    <div class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValidoArray('firmas', obtenerIndexNifArray())">
      {{formularioValidacionesDocsService.mensajeErrorTipoFirma}}
    </div>
  </div>
  <div *ngIf="tipoFirma.value == 'SIN FIRMA'">
    <div class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValidoArray('firmas', obtenerIndexNifArray())">
      {{formularioValidacionesDocsService.mensajeErrorTipoFirma}}
    </div>
  </div>
</form>
