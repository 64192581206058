<div *ngIf="posicionFirma.mostrar"
     id="dragDiv"
     #draggableDiv
     class="draggable resize"
     [ngStyle]="{'top.px': posicionFirma.top, 'left.px': posicionFirma.left, 'width.px': 200, 'height.px': 60}">
     <!-- [ngStyle]="{'top.px': topPosition, 'left.px': leftPosition, 'width.px': firma.width, 'height.px': firma.height}"> -->
      <svg #draggableIcon class="icon-draggable">
        <use #draggableUse xlink:href="#icon-draggable"></use>
       </svg>
       <!-- <span></span> -->
       <!-- <span>{{firma.tipo.nombre}}</span> -->
     <!-- <div id="resizeDiv" class="pinta"></div> -->
</div>
