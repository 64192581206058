<div id="container" [ngStyle]="{'background-color': bgColor, 'padding-top.px':paddingPastilla, 'padding-bottom.px':paddingPastilla}">
  <svg *ngIf="tituloFiltro=='Sandbox'" class="hs-sandbox"><use xlink:href="#hs-sandbox"></use></svg>
  <div class="tituloFiltro" [ngStyle]="{'color': color}">{{tituloFiltro}}</div>
  <div *ngIf="num!==null" [ngClass]="{'circulo-xs': num?.toString().length<3, 'circulo-m': num?.toString().length==3, 'circulo-l': num?.toString().length>3}" [ngStyle]="{'background-color': bgCirculo ? bgCirculo : color, 'color': colorNum}">{{num}}</div>
  <span class="hs-close" (click)="limpiarFiltro()">
    <svg [ngStyle]="{'color': hover ? colorHover:colorX}" (mouseover)="hover=true" (mouseout)="hover=false"><use xlink:href="#icon-close"></use></svg>
    <!-- <svg [ngStyle]="{'color': colorX}" (mouseover)="hover" (mouseout)="!hover"><use xlink:href="#icon-close"></use></svg> -->
  </span>
</div>




