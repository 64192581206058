import { TipoFirmante } from "../../envios/models/tipo-firmante";

export interface FirmanteNif{
  tipoFirmante: TipoFirmante;
  nifPage: number;
  nifOrden: number;
  nifFormulario:string;
  posicionNif: string;
  firmas: ConfiguracionFirma;
  formularios: PosicionFormulario[];
  camposEditables: PosicionFirma[];
  tipoDestinatario?: TipoFirmante;
  tipoBusqueda?: TipoFirmante;
  orden?: number;
}

export interface ConfiguracionFirma{
  tipoFirma: TipoFirma;
  nombreCertificado: string;
  tieneTag: boolean;
  nombreTag: string;
  posicionFirmas: PosicionFirma[];
}

export interface PosicionFirma{
  id: number;
  pagina: number;
  posicionTop: number;
  posicionLeft: number;
  posicionRight: number;
  posicionWidth: number;
  posicionHeight: number;
}

export interface PosicionFirmaConFirmante extends PosicionFirma{
  firmante: TipoFirmante;
}

export interface PosicionFormulario extends PosicionFirma{
  nombre: string;
  orden: number;
}

export interface PosicionFormularioConFirmante extends PosicionFormulario{
  firmante: TipoFirmante;
}

export interface PosicionCampoConFirmante extends PosicionFirma{
  firmante: TipoFirmante;
}

export enum TipoFirma{
  Dibujada = "DIBUJADA",
  Certificado = "CERTIFICADO",
  Validador = "VALIDADOR",
  SinFirma = "SIN FIRMA"
}

export interface FirmaDibujo{
  id: number;
  pagina: number;
  x: number;
  y: number;
  width: number;
  height: number;
  firmante: TipoFirmante;
}

export interface CasillaDibujo{
  id: number;
  pagina: number;
  x: number;
  y: number;
  width: number;
  height: number;
  firmante: TipoFirmante;
  orden: number;
  nombre: string;
}

export interface ErroresForm{
  firmante: TipoFirmante;
  error: string;
}

export interface CoordenadasObjeto{
  left: number,
  top: number,
  height: number,
  width: number
}
