<div class="">
    <div class="table-width">
        <table>
            <tr>
                <th class="td-orden">Orden</th>
                <th class="td-drag"></th>
                <th class="td-firmante">Destinatarios</th>
                <th class="td-destinatario">Tipo de destinatario</th>
                <th class="td-busqueda">Tipo de búsqueda</th>
            </tr>
        </table>

        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <app-detalle-firmantes  class="app-firmante" class="example-box" *ngFor="let firmante of totalFirmantes; let i = index" [i]="i" [firmante]="firmante" [destinatarios]="destinatarios" [busquedas]="busquedas" [resetBusqueda]="resetBusqueda" (eliminarClicked)="eliminarFirmante($event)" cdkDrag></app-detalle-firmantes>
        </div>
        <!-- <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <app-detalle-firmantes *ngFor="let firmante of totalFirmantes; let i = index" [i]="i" [firmante]="firmante" [inputOtro]="inputOtro" [destinatarios]="destinatarios" [busquedas]="busquedas" (eliminarClicked)="eliminarFirmante($event)" cdkDrag></app-detalle-firmantes>
        </div> -->

        <h3 *ngIf="!esRLT.value" id="RLT1">Añadir firmantes</h3>
        <div *ngIf="!esRLT.value" id="RLT2" class="flex">
            <app-tipo-firmantes *ngFor="let tipo of tiposFirmantes; let i = index" [codigo]="tiposFirmantes[i].codigo" [descripcion]="tiposFirmantes[i].desc" (botonClicked)="recuperaClick($event)"></app-tipo-firmantes>
        </div>
    </div>
</div>
