import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentosSearchResponse, TipoDocumento } from 'src/app/pages/configuracion/Envios/documentos/models/tipo-documento';
import { environment } from 'src/environments/environment';
import { IRecuperarTiposDocumentoService } from './ports/i-recuperar-tipos-documento-service';

@Injectable({
  providedIn: 'root'
})
export class RecuperaTipoDocumentoMockService implements IRecuperarTiposDocumentoService{

  constructor(private http: HttpClient) { }

  guardaDocumento(data: any): Observable<void> {
    throw new Error('Method not implemented.');
  }

  getTipoDocumentos(): Observable<TipoDocumento[]> {

    const headers = new HttpHeaders()
    .set('content-type', 'application/json')

    return this.http.get<DocumentosSearchResponse>(`${environment.ApiDocumentosMock}`, {headers})
      .pipe(
        map(resp => {
            let documentos: TipoDocumento[] = resp.datos;
            return documentos;
          }
        )
    );
  }
}
