import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracion-maestros',
  templateUrl: './configuracion-maestros.component.html',
  styleUrls: ['./configuracion-maestros.component.css']
})
export class ConfiguracionMaestrosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
