<div class="flex">
  <svg class="icon-info">
    <use xlink:href="#icon-info"></use>
  </svg>
  <div>
    <span><span class="semi-bold">Página:</span> donde está localizado {{nombre}}.</span>
    <br>
    <span><span class="semi-bold">Orden:</span> orden que ocupa el {{nombre}} en la página respecto al resto de NIFs/CIFs.</span>
  </div>
</div>
