<div class="container">
  <form [formGroup]="firmanteForm">
    <table>
      <tr>
        <!-- <td class="orden td-orden">{{firmante.orden}}º</td> -->
        <td class="orden td-orden">{{i+1}}º</td>
        <div class="flex">
          <td class="td-drag">
            <svg class="icon-draggable">
              <use xlink:href="#icon-draggable"></use>
            </svg>
          </td>
          <div class="registro">
            <td class="td-firmante">
              <!-- <span>
                <svg class="icon-draggable">
                  <use xlink:href="#icon-draggable"></use>
                </svg>
              </span> -->
              <!-- <span>
                <svg class="icon-user">
                  <use xlink:href="#icon-user"></use>
                </svg>
              </span> -->
              <!-- <span *ngIf="(firmante.tipoFirmante.codigo !== personificableCodigo)" class="text"> -->
              <span class="text">
                {{firmante.tipoFirmante.desc}}
              </span>
              <!-- <span *ngIf="inputOtro" class="text">
                Otro:
                <input id="otro" type="text" formControlName="tipoFirmanteInput" (blur)="mostrarOtro(); cambioLista()">
                <br>
                <span class="texto-validacion" *ngIf="firmanteForm.controls['tipoFirmanteInput'].errors && firmanteForm.controls['tipoFirmanteInput'].touched">
                  El nombre del firmante es obligatorio.
                </span>
              </span> -->
              <!-- <span *ngIf="otro" class="text">
                <span class="text">
                {{firmanteForm.controls['tipoFirmanteInput'].value}}
                </span>
                <svg class="icon-edit" (click)="edit()">
                  <use xlink:href="#icon-edit-empty"></use>
                </svg>
              </span> -->
            </td>
            <td *ngIf="mostrarFirmantePpal" class="td-destinatario">
              <select formControlName="destinatario" (change)="changeValue($event, destinatario)" [attr.disabled]="esRLT.value ? true:null" [ngClass]="formularioValidacionesService.isCampoValidoArray('tipoDestinatario.codigo', i) ? 'errorInput':''">
                <option [value]="destinatarioFirmanteCodigo">Firmante</option>
              </select>
              <br>
              <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValidoArray('tipoDestinatario.codigo', i)">
                Requerido.
              </span>
            </td>
            <td *ngIf="!mostrarFirmantePpal" class="td-destinatario">
              <select formControlName="destinatario" (change)="changeValue($event, destinatario)" [ngClass]="formularioValidacionesService.isCampoValidoArray('tipoDestinatario.codigo', i) ? 'errorInput':''">
                <option *ngFor="let destinatario of destinatarios" [value]="destinatario.codigo">{{destinatario.desc}}</option>
              </select>
              <br>
              <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValidoArray('tipoDestinatario.codigo', i)">
                Requerido.
              </span>
            </td>
            <td class="td-busqueda">
              <select class="busquedaId"[attr.disabled]="esRLT.value ? true:null" formControlName="busqueda" (change)="changeValue($event, busqueda)" [ngClass]="formularioValidacionesService.isCampoValidoArray('tipoBusqueda.codigo', i) ? 'errorInput':''">
                <option *ngFor="let busqueda of busquedas" [value]="busqueda.codigo">{{busqueda.desc}}</option>
              </select>
              <br>
              <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValidoArray('tipoBusqueda.codigo', i)">
                Requerido.
              </span>
            </td>
            <td class="td-eliminar">
              <span *ngIf="firmantePrincipalCodigo != firmante.tipoFirmante.codigo" class="eliminar" (click)="clicked()">
                <svg class="icon-bin">
                  <use xlink:href="#hs-bin"></use>
                </svg>
              </span>
              <span *ngIf="firmantePrincipalCodigo == firmante.tipoFirmante.codigo" class="no-eliminar">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </td>
          </div>
        </div>


      </tr>
    </table>
  </form>
</div>
