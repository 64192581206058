import { Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TipoFirmante } from '../../../../envios/models/tipo-firmante';
import { DatosConfiguracionDocumentosService } from '../../../services/datos-configuracion-documentos.service';

@Component({
  selector: 'app-firmas-temporal',
  templateUrl: './firmas-temporal.component.html',
  styleUrls: ['./firmas-temporal.component.css']
})
export class FirmasTemporalComponent implements OnInit {

  @Input() posicionFirma: {
    mostrar: boolean,
    left: number,
    top: number,
    firmante: TipoFirmante
  }
  @ViewChild('draggableIcon') draggableIcon: ElementRef;
  @ViewChild('draggableDiv') draggableDiv: ElementRef;
  @ViewChild('draggableUse') draggableUse: ElementRef;
  firmaPosition: { left: number, top: number, height: number, width: number };
  firmaEnviar: any;
  isDragging: boolean = false;
  clientX: number = 0;
  clientY: number = 0;
  initialX: number = 0;
  initialY: number = 0;
  xOffset: number = 0;
  yOffset: number = 0;
  currentX: number = 0;
  currentY: number = 0;
  finalX: number = 0;
  finalY: number = 0;

  constructor(private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges){
    this.posicionFirma = changes.posicionFirma.currentValue
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event:any){
    if(event.target === this.draggableDiv.nativeElement ||
       event.target === this.draggableIcon.nativeElement ||
       event.target === this.draggableUse.nativeElement){
      if(this.posicionFirma.mostrar){
        this.draggableDiv.nativeElement.style.display = 'flex';
        this.isDragging = true;
        this.clientX = this.posicionFirma.left;
        this.clientY = this.posicionFirma.top;
        this.initialX = this.posicionFirma.left - this.xOffset;
        this.initialY = this.posicionFirma.top - this.yOffset;
      }
    }
  }

  @HostListener('document:mousemove', ['$event']) onMouseMove(event: any) {

    if (this.isDragging) {

      // this.currentX = event.clientX - this.initialX - 60;
      this.currentX = event.clientX - this.initialX - 20;
      this.currentY = event.clientY - this.initialY - 30;
      this.xOffset = this.currentX;
      this.yOffset = this.currentY;
      this.finalX = this.currentX + this.posicionFirma.left;
      this.finalY = this.currentY + this.posicionFirma.top - 50;
      this.firmaPosition = {
        left: this.finalX,
        top: this.finalY,
        height: this.draggableDiv.nativeElement.clientHeight,
        width: this.draggableDiv.nativeElement.clientWidth
      }

      this.setTransform();
    }
  }

  //enviamos la posición final a firmas-dibujo a través del observable del servicio DatosConfiguracionDocuemntosService
  @HostListener('document:mouseup', ['$event']) onMouseUp(event: any) {

    if (this.draggableIcon && (event.target === this.draggableIcon.nativeElement || event.target === this.draggableDiv.nativeElement || event.target === this.draggableUse.nativeElement)) {
      this.finalX = this.currentX + this.posicionFirma.left;
      this.finalY = this.currentY + this.posicionFirma.top;
      this.firmaPosition = {
        left: this.finalX,
        top: this.finalY,
        height: this.draggableDiv.nativeElement.clientHeight,
        width: this.draggableDiv.nativeElement.clientWidth
      }

      this.datosConfiguracionDocumentosService.firmaPosition = this.firmaPosition;
      if(this.datosConfiguracionDocumentosService.isDentro('firmas-temporal', this.firmaPosition)){
        this.firmaEnviar = {
          x: this.firmaPosition.left - 81,
          //y: (this.isDragging)?this.firmaPosition.top - 150:this.firmaPosition.top - 200 ,
          y: this.firmaPosition.top - 100,
          width: this.firmaPosition.width,
          height: this.firmaPosition.height,
          firmante: this.posicionFirma.firmante
        }
        this.datosConfiguracionDocumentosService.transferirDatosFirma(this.firmaEnviar)
      }
      this.posicionFirma.mostrar = false;
    }
    this.isDragging = false;
  }

  setTransform() {
    this.draggableDiv.nativeElement.style.transform = `translate3d(${this.currentX}px, ${this.currentY}px, 0)`;
  }

}
