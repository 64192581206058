import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DatosUsuariosService } from '../../../services/datos-usuarios.service';

@Component({
  selector: 'app-usuario-activo-conf',
  templateUrl: './usuario-activo-conf.component.html',
  styleUrls: ['./usuario-activo-conf.component.css']
})
export class UsuarioActivoConfComponent implements OnInit {

  @Output() guardarcambios: EventEmitter<string> = new EventEmitter<string>();

  constructor(public datosUsuariosService: DatosUsuariosService) { }


  ngOnInit(): void {
    this.datosUsuariosService.inicializarUsuarioActivoForm();
  }

  get nombre(){ return this.datosUsuariosService.usuarioActivoForm.controls['nombre']}
  get apellido1(){ return this.datosUsuariosService.usuarioActivoForm.controls['apellido1']}
  get email(){ return this.datosUsuariosService.usuarioActivoForm.controls['email']}

  guardar(){
    this.guardarcambios.emit('guardar');
  }

}
