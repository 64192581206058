import { Inject, Injectable } from '@angular/core';
import { IDatosConfiguracionService } from './ports/i-datos-configuracion-service';
import { Observable } from 'rxjs';
import { TiposContenido } from '../models/tipos-contenido';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DatosTiposContenidoService {

  tiposContenido: TiposContenido[] = [];

  constructor(@Inject('RecuperaTiposContenido') private datosTiposContenidoService: IDatosConfiguracionService) {   }

  getTiposContenido(): Observable<TiposContenido[]> {
    return this.datosTiposContenidoService.getTiposContenido()
      .pipe(
        map(resp => {
          this.tiposContenido = resp;
            return this.tiposContenido
          }
        )
    );
  }
}
