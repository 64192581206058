import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ResumenFirmas } from 'src/app/pages/panelControl/models/resumen-firmas';
import { FiltroFirma } from 'src/app/shared/models/filtros';
import { TooltipMessages } from 'src/app/shared/models/tooltip.mesages';
import { ModificarArraysService } from 'src/app/shared/services/modificar-arrays.service';
import { EstadosFirmaService } from '../../services/estados-firma.service';

@Component({
  selector: 'app-panel-filtro-lateral',
  templateUrl: './panel-filtro-lateral.component.html',
  styleUrls: ['./panel-filtro-lateral.component.css']
})
export class PanelFiltroLateralComponent implements OnInit {

  oculto: boolean = false;
  @Output() ocultoEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clickEstado: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickVerNuevos: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() resumen: ResumenFirmas[] = [];
  @Input() verNuevasParam:boolean=false;
  textoInfoEstadoFirma: string  = '';
  @Input() resumenInicial: ResumenFirmas[] = [];
  @Input() verEnviosEntornoSandbox: boolean = false;
  @Output() verSandbox: EventEmitter<boolean> = new EventEmitter<boolean>();
  // estadoSeleccionado: string='';
  @Input() limpiarEstados: {num:number, clear: boolean} = {num:0, clear: false};

  constructor(public estadosFirmaService: EstadosFirmaService) {
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.limpiarEstados?.currentValue?.num>0){
      this.estadosFirmaService.estadoSeleccionado = 'TODOS';
    }
  }

  ngOnInit() {
  }

  getClassPanel(){
    return !this.oculto ? 'panel-lateral' : 'panel-lateral-oculto';
  }

  clickOcultar(_oculto: boolean){
    this.oculto = _oculto;
    this.ocultoEventEmitter.emit(this.oculto);
  }

  clickTipoFirma(resumen: ResumenFirmas){
    this.clickEstado.emit(resumen.estado);
    this.estadosFirmaService.estadoSeleccionado = resumen.estado;
  }

  verNuevosClicked(nuevos: boolean){
    this.clickVerNuevos.emit(nuevos);
  }

  getTooltip(estado: string){
    if (estado.toUpperCase() == "BORRADOR") return TooltipMessages.envio_borradores;
    else if (estado.toUpperCase() == "FIRMADO") return TooltipMessages.envio_firmados;
    else if (estado.toUpperCase() == "PARCIALMENTE FIRMADO") return TooltipMessages.envio_parc_firmados;
    else if (estado.toUpperCase() == "RECHAZADO") return TooltipMessages.envio_rechazados;
    else if (estado.toUpperCase() == "NO FIRMADO") return TooltipMessages.envio_por_firmar;
    else if (estado.toUpperCase() == "CADUCADO") return TooltipMessages.envio_caducados;
    else if (estado.toUpperCase() == "ERROR") return TooltipMessages.envio_errores;
    else if (estado.toUpperCase() == "CANCELADO") return TooltipMessages.envio_cancelados;
    else return '';
  }

  selectVerEnviosEntornoSandbox(ver: boolean){
    this.verSandbox.emit(ver);
  }
}
