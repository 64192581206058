import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { FirmanteNif } from '../../../models/firmante-nif';
import { FormularioDocumentoService } from '../../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../../services/formulario-validaciones-docs.service';
import { DatosConfiguracionDocumentosService } from '../../../services/datos-configuracion-documentos.service';

@Component({
  selector: 'app-configuracion-firmas-tipo-firma',
  templateUrl: './configuracion-firmas-tipo-firma.component.html',
  styleUrls: ['./configuracion-firmas-tipo-firma.component.css']
})
export class ConfiguracionFirmasTipoFirmaComponent implements OnInit {

  @Output() cambioIndex: EventEmitter<number> = new EventEmitter<number>();
  @Input() firmante: FirmanteNif;

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              public formularioValidacionesDocsService: FormularioValidacionesDocsService,
              private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService,
              private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    // this.formularioDocumentoService.inicializaFormularioFirma(firmanteNif:);

  }

  get formFirma(){ return this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup}

  // get tieneCertificado(){ return this.formularioDocumentoService.formularioFirma.controls['tieneCertificado']}
  get firmantesNif(){ return this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'] as FormArray}
  get tipoFirma(){ return (this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup).get('tipoFirma') }

  obtenerIndexNifArray():number{
    return this.formularioDocumentoService.firmantesNif.value.findIndex((x:FirmanteNif) => x.tipoFirmante.codigo == this.firmante.tipoFirmante.codigo)
  }

  //esperamos a obtener el cambio de valor de firmas en formularioDocumento para transferir true a la botonera y este cambie el título del botón a finalizar/siguiente.
  checkValidador(){
    this.firmantesNif.at(0).get('firmas').valueChanges.subscribe(value => this.datosConfiguracionDocumentosService.transferirDatosValidador(true))
  }

  ngOnDestroy(){
    this.datosConfiguracionDocumentosService.transferirDatosValidador(false);
  }
}
