import { Component, ComponentFactoryResolver, ElementRef, HostListener, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationMessageComponent } from '../../modals/confirmation-message/confirmation-message.component';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';
import { FormularioDocumentoService } from 'src/app/pages/configuracion/Envios/documentos/services/formulario-documento.service';
import { TenanData } from 'src/app/pages/login/models/tenant_data';
import { DatosUsuariosService } from 'src/app/pages/seguridad/services/datos-usuarios.service';
import { Subscription } from 'rxjs';
import { UsuariActivo, UsuarioModel } from 'src/app/pages/seguridad/models/usuario-model';
import { openWidget } from 'src/assets/js/freshworks.js';
import { FormularioEnvioService } from 'src/app/pages/configuracion/Envios/envios/services/formulario-envio.service';
import { TipoDocumentoClass } from 'src/app/pages/configuracion/Envios/documentos/models/tipo-documento';
import { TipoEnvioClass } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';

@Component({
  selector: 'app-barra-superior',
  templateUrl: './barra-superior.component.html',
  styleUrls: ['./barra-superior.component.css']
})
export class BarraSuperiorComponent implements OnInit {

  configuracion: boolean = false;
  public tenants:TenanData[] = [{Name:'',Tenant :''}];
  private token:string = '';
  nombreEntorno:string = '';
  // multipleTenants:boolean = false;
  usuarioActivoSuscription: Subscription;
  usuarioActivo: UsuariActivo;
  // usuarioActivo: UsuarioModel;

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('flexConf') flexConf: ElementRef;
  @ViewChild('icon') icon: ElementRef;
  @ViewChild('iconSvg') iconSvg: ElementRef;
  @ViewChild('iconUse') iconUse: ElementRef;
  @ViewChild('nameHeader') nameHeader: ElementRef;
  @ViewChild('name') name: ElementRef;
  @ViewChild('entorno') entorno: ElementRef;

  @ViewChildren('.child') child: QueryList<ElementRef>;


  constructor(private loginService: LoginService,
              private router: Router,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog,
              private formularioDocumentoService: FormularioDocumentoService,
              private formularioEnvioService: FormularioEnvioService,
              private datosUsuariosService: DatosUsuariosService,
              private activatedRoute: ActivatedRoute,
              private renderer: Renderer2,
              private eRef: ElementRef) {
                this.getUsuarioActivoEditado();
               }

  @HostListener('click', ['$event']) onClick(event: any) {
    if(event.target == this.toggleButton.nativeElement ||
      event.target == this.flexConf.nativeElement ||
      event.target == this.icon.nativeElement ||
      event.target == this.iconSvg.nativeElement ||
      event.target == this.iconUse.nativeElement ||
      event.target == this.nameHeader.nativeElement ||
      event.target == this.name.nativeElement ||
      event.target == this.entorno.nativeElement) this.configuracion = !this.configuracion
    else this.configuracion = false
  }


  //cerrar el menú si clicamos fuera del componente
  @HostListener('document:click', ['$event']) clickout(event:any) {
    if(!this.eRef.nativeElement.contains(event.target)) this.configuracion = false
  }

  ngOnInit(): void {
    this.getTenant();
  }

  ngOnDestroy(){
    // if(this.usuarioActivoSuscription) this.usuarioActivoSuscription.unsubscribe();
    this.datosUsuariosService.transferirUsuarioActivo({});
  }

  getNameLogin(){
    const isEmpty = Object.keys(this.usuarioActivo).length;
    if(isEmpty>0){
      return `${this.usuarioActivo.Apellido1}, ${this.usuarioActivo.Nombre}`
    }else{
      let userData = this.loginService.getUserLocalStorage();
      return (userData.User.Nombre && userData.User.Apellido1) ? `${userData.User.Apellido1}, ${userData.User.Nombre}` : userData.User.Login;
    }
  }

  getTenant(){
    this.nombreEntorno = this.loginService.getTenantToken().tenantActivo.Name;
  }

  goHelp(){
    this._snackBar.open("Opción no disponible", "Cerrar", { duration: 3000 });
  }

  getUsuarioActivoEditado(){
    this.usuarioActivoSuscription = this.datosUsuariosService.datosUsuarioActivo$.subscribe((value:any) => {
      this.usuarioActivo = value;
      this.getNameLogin();
      // this.getTenant();
  })
}

  cerrarMenu(event: boolean){
    if(event && !this.eRef.nativeElement) this.configuracion=false
  }

  goHome(){
      if(this.router.url.includes('configuracion-documento')){
        if(!this.formularioDocumentoService.hayCambios()){
          this.formularioDocumentoService.resetFormularioDocumento();
          this.router.navigate(['/panel/main']);
          this.formularioDocumentoService.documentoAEditar = new TipoDocumentoClass();
        }else{
          const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
            data: {
              tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
              icono: 'icon-save2',
              titulo: '¿Deseas salir sin guardar?',
              mensaje:'Se perderán todos los cambios realizados en la configuración del documento.',
              textoConfirmacion: 'Salir sin guardar',
              iconoConfirmacion: 'icon-unsaved',
              textoCancelar: 'cerrar'
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            if(result === 'OK'){
              this.formularioDocumentoService.resetFormularioDocumento();
              this.router.navigate(['/panel/main']);
              this.formularioDocumentoService.documentoAEditar = new TipoDocumentoClass();

            }
          });
        }
      }else if(this.router.url.includes('configuracion-dosier')){
        if(!this.formularioEnvioService.hayCambiosDosier()){
          this.router.navigate(['/panel/main']);
          this.formularioEnvioService.envioAEditar = new TipoEnvioClass();
        }else{
          const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
            data: {
              tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
              icono: 'icon-save2',
              titulo: '¿Deseas salir sin guardar?',
              mensaje:'Se perderán todos los cambios realizados en la configuración del dosier.',
              textoConfirmacion: 'Salir sin guardar',
              iconoConfirmacion: 'icon-unsaved',
              textoCancelar: 'cerrar'
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            if(result === 'OK'){
              this.router.navigate(['/panel/main']);
              this.formularioEnvioService.envioAEditar = new TipoEnvioClass();

            }
          });
        }
      }else{
        this.router.navigate(['/panel/main'])
      }
  }

  abrirFreshWorks(){
  //  eval('window.FreshworksWidget("open")');
   openWidget();
  }
}
