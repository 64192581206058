<div class="aside-main">
    <!-- <button [ngClass]="menuVisible ? 'buttonArrowUp':'buttonArrowDown' " class="btn2 buttonAside buttonAsideToggle" (click)="desplegarMenu()">
        <svg class="icon">
          <use xlink:href="#hs-arrow-down"></use>
        </svg>
    </button> -->
    <div>
      <button class="btn2 buttonAside" [ngClass]="{'seleccion': focusSeleccion=='/panel/main'}" (click)="avisaCerrarConfiguraciones('/panel/main')" routerLinkActive="buttonAside-active">
        <span class="btn-icon">
            <svg class="icon">
              <use xlink:href="#hs-home"></use>
            </svg>
        </span>
    </button>
    <button class="btn2 buttonAside" [ngClass]="{'seleccion': focusSeleccion=='/panel/envios', 'seleccion-nuevos': focusSeleccion=='/panel/envios/nuevos'}" (click)="avisaCerrarConfiguraciones('/panel/envios')" routerLinkActive="buttonAside-active">
        <span class="btn-icon">
            <svg class="icon">
              <use xlink:href="#hs-list"></use>
            </svg>
        </span>
    </button>
    <button class="btn2 buttonAside" [ngClass]="{'seleccion': focusSeleccion=='/panel/subida'}" (click)="avisaCerrarConfiguraciones('/panel/subida')" routerLinkActive="buttonAside-active">
        <span class="btn-icon">
            <svg class="icon">
              <use xlink:href="#hs-send"></use>
            </svg>
        </span>
    </button>
    <button class="btn2 buttonAside" [ngClass]="{'seleccion': focusSeleccion=='/panel/configuracion/empleados'}" (click)="avisaCerrarConfiguraciones('/panel/configuracion/empleados')" routerLinkActive="buttonAside-active">
        <span class="btn-icon">
            <svg class="icon">
              <use xlink:href="#hs-employees"></use>
            </svg>
        </span>
    </button>
    <button class="btn2 buttonAside" [ngClass]="{'seleccion': focusSeleccion=='/panel/opciones-configuracion'}" (click)="avisaCerrarConfiguraciones('/panel/opciones-configuracion')" routerLinkActive="buttonAside-active">
        <span class="btn-icon">
            <svg class="icon">
              <use xlink:href="#hs-tools"></use>
            </svg>
        </span>
    </button>
    </div>

</div>
<!-- <div class="aside-main">
    <button class="btn buttonAside buttonAsideToggle">
        <svg class="icon">
          <use xlink:href="#icon-arrow"></use>
        </svg>
    </button>
    <button class="btn buttonAside" (click)="avisaCerrarConfiguraciones('/panel/main')" routerLinkActive="buttonAside-active">
        <span class="btn-icon">
            <svg class="icon">
              <use xlink:href="#icon-home"></use>
            </svg>
        </span>
    </button>
    <button class="btn buttonAside" (click)="avisaCerrarConfiguraciones('/panel/firmas')" routerLinkActive="buttonAside-active">
        <span class="btn-icon">
            <svg class="icon">
              <use xlink:href="#icon-list"></use>
            </svg>
        </span>
    </button>
    <button class="btn buttonAside" (click)="avisaCerrarConfiguraciones('/panel/subida')" routerLinkActive="buttonAside-active">
        <span class="btn-icon">
            <svg class="icon">
              <use xlink:href="#icon-send"></use>
            </svg>
        </span>
    </button>
    <button class="btn buttonAside" (click)="avisaCerrarConfiguraciones('/panel/empleados')" routerLinkActive="buttonAside-active">
        <span class="btn-icon">
            <svg class="icon">
              <use xlink:href="#icon-user"></use>
            </svg>
        </span>
    </button>
</div> -->
