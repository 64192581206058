<div [ngClass]="{'modal modal-buttons modal-right':true,'show-modal':visible}" class="modal modal-buttons modal-right show-modal">
  <div class="modal-container">
    <span class="modal-close" mat-dialog-close></span>
    <div class="modal-content">
      <app-detalle-firma-cabecera [firma]="firma"></app-detalle-firma-cabecera>
      <div class="scroll">
        <div class="detalles" (click)="switchDetalles()">{{tituloMostrarDetalles}}</div>
        <app-detalle-firma-firmantes [firma]="firma" [mostrarDetalles]="mostrarDetalles" (switchFirmantesEmitter)="checkSwitchDetalles($event)"></app-detalle-firma-firmantes>
        <app-detalle-firma-documentos [firma]="firma" [mostrarDetalles]="mostrarDetalles" (switchDocumentosEmitter)="checkSwitchDetalles($event)"></app-detalle-firma-documentos>
        <app-detalle-firmas-eventos [firma]="firma" [mostrarDetalles]="mostrarDetalles" (switchEventosEmitter)="checkSwitchDetalles($event)"></app-detalle-firmas-eventos>
        <app-detalle-firma-remitente [remitente]="firma.usuario" [mostrarDetalles]="mostrarDetalles" (switchRemitenteEmitter)="checkSwitchDetalles($event)"></app-detalle-firma-remitente>
        <app-detalle-firma-rlt *ngIf="firma.estadoRlt!==null" [firma]="firma" [mostrarDetalles]="mostrarDetalles" (switchRltEmitter)="checkSwitchDetalles($event)"></app-detalle-firma-rlt>
        <app-detalle-firma-caducidad *ngIf="firma.estadoString == 'Parcialmente firmado' || firma.estadoString == 'No firmado'" [firma]="firma" [mostrarDetalles]="mostrarDetalles" (switchCaducidadEmitter)="checkSwitchDetalles($event)"></app-detalle-firma-caducidad>
      </div>
      <!-- <div style="height: 50px;"></div> -->
      <div class="modal-buttons">
        <app-boton-accion mat-button *ngIf="verBorrar()" (botonClicked)="borrarFirma()" [icono]="'hs-bin'" [titulo]="'Eliminar'" [id]="'Eliminar'" [outline]="true" [cancelar]="false"></app-boton-accion>
        <app-boton-accion mat-button *ngIf="verCancelar()" (botonClicked)=" cancelarFirma() " [icono]="'icon-close'" [titulo]="'Cancelar Envío'" [id]="'Cancelar'" [outline]="true" [cancelar]="true"></app-boton-accion>
        <app-boton-accion mat-button *ngIf="verDescargar() && !datosFirmaService.descargandoFirmas" (botonClicked)=" descargarFirma() " [icono]="'hs-download'" [titulo]="'Descargar'" [id]="'Descargar'" [outline]="true" [cancelar]="false"></app-boton-accion>
        <mat-spinner *ngIf="datosFirmaService.descargandoFirmas" diameter="30" strokeWidth="2"></mat-spinner>
        <!-- <mat-spinner diameter="30" strokeWidth="2"></mat-spinner> -->
      </div>
    </div>
  </div>
</div>
