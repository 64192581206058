import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IRecuperarEmpresasService } from 'src/app/pages/configuracion/maestros/empresas/services/ports/i-recuperar-empresas-service';
import { FiltroBasico, Filtros } from 'src/app/shared/models/filtros';
import { EmpresasModel } from '../models/empresas-model';
import { RecuperaEmpresasApiService } from './recupera-empresas-api.service';

@Injectable({
  providedIn: 'root'
})
export class DatosEmpresasService {

 empresas: EmpresasModel[] = [];
 filtros: FiltroBasico[] = [];
 empresasFiltradas: EmpresasModel[] = [];
 refreshEmpresas: boolean;

  constructor(@Inject('RecuperaEmpresas') private datosEmpresa: IRecuperarEmpresasService,
              private datosEmpresasApiService: RecuperaEmpresasApiService) {
               }

  public getEmpresas(): Observable<EmpresasModel[]>{

    return this.datosEmpresa.getEmpresas(this.refreshEmpresas)
      .pipe(
        map((resp:any) => {
          this.empresas = resp

          return this.empresas
        })
      )
  }

  SetFilter(_filtros: FiltroBasico[]){
    this.filtros = _filtros;
  }

  // Aplicamos los filtros cargados al listado completo de empresas y guardamos el resultado
  //cogemos empresas del servicio api porque el componente llama directamente RecuperaEmpresasApiService ya que no reconoce la interface IRecuperarEmpresasService, entonces this.empresas aquí está vacio.

  Filter(){
    this.empresasFiltradas = [];
    this.empresasFiltradas = this.datosEmpresasApiService.empresas;

    if(this.filtros.length == 0){
      this.empresasFiltradas = this.datosEmpresasApiService.empresas;
    }else{
      for( const filter of this.filtros){
        if(filter.textoBuscar.trim() != ""){
          this.empresasFiltradas = this.empresasFiltradas.filter(x =>
            x.codigoEmpresa?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.nombreEmpresa?.toString().toUpperCase().includes(filter.textoBuscar.toLocaleUpperCase()) ||
            x.cif?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.nombreEmpleado?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.emailEmpleado?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase())
          )
        }
      }
      this.empresasFiltradas.forEach(x=> {
        if(! this.empresasFiltradas.includes(x))  this.empresasFiltradas.push(x);
      })
    }
  }

  deleteFilter(filter:FiltroBasico){
    this.filtros = [];
  }

  guardaEmpresa(objetoEmpresa: EmpresasModel):Observable<void>{
    return this.datosEmpresa.guardaEmpresa(objetoEmpresa)
  }

  deleteEmpresa(objetoEmpresa: EmpresasModel):Observable<void>{
    return this.datosEmpresa.deleteEmpresa(objetoEmpresa)
  }

  checkSiCodigoEmpresaExiste(empresa: EmpresasModel): boolean{
    let indexCodigo = this.empresas.findIndex(x=> x.codigoEmpresa == empresa.codigoEmpresa)
    if(indexCodigo>-1){
      let indexCif = this.empresas.findIndex(x=> x.cif == empresa.cif)
      if(indexCif>-1 && indexCodigo == indexCif) return false
      else return true
    }else return false
  }

}
