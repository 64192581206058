import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './pages/login/components/login-page/login-page.component';
import { MainMenuComponent } from './pages/menuPrincipal/components/main-menu/main-menu.component';
import { PanelDeControlComponent } from './pages/panelControl/components/panel-de-control/panel-de-control.component';
import { StandarLayoutComponent } from './shared/components/layouts/standar-layout/standar-layout.component';
import { SubidaPrincipalComponent } from './pages/subidaPrincipal/components/subida-principal/subida-principal.component';
import { EmpresasPageComponent } from './pages/configuracion/maestros/empresas/components/empresas-page/empresas-page.component';
import { CentrosPageComponent } from './pages/configuracion/maestros/centros/componentes/centros-page/centros-page.component';
import { ConfiguracionMaestrosComponent } from './pages/configuracion/configuracion-maestros/configuracion-maestros.component';
import { EnviosPageComponent } from './pages/configuracion/Envios/envios/components/envios-page/envios-page.component';
import { DocumentosPageComponent } from './pages/configuracion/Envios/documentos/components/documentos-page/documentos-page.component';
import { ConfiguraEnvioPageComponent } from './pages/configuracion/Envios/envios/components/configura-envio-page/configura-envio-page.component';
import { UploadExcelEmpleadosComponent } from './pages/empleados/components/upload-excel-empleados/upload-excel-empleados.component';
import { ConfiguracionDocumentoPageComponent } from './pages/configuracion/Envios/documentos/components/configuracion-documento-page/configuracion-documento-page.component';
import { UsuariosPageComponent } from './pages/seguridad/components/usuarios-page/usuarios-page.component';
import { RegisterComponent } from './pages/login/components/register/register.component';
import { IniPageComponent } from './pages/login/components/loginAzureAd/ini-page/ini-page.component';
import { LoginAzureAdComponent } from './pages/login/components/loginAzureAd/login-azure-ad/login-azure-ad.component';
import { SelectorTenantsComponent } from './pages/login/components/loginAzureAd/selector-tenants/selector-tenants.component';
import { UsuarioActivoPageComponent } from './pages/seguridad/components/usuario-activo/usuario-activo-page/usuario-activo-page.component';
import { ConfiguraRltPageComponent } from './pages/configuracion/configuracion-rlt/configura-rlt-page/configura-rlt-page.component';
import { ConfiguracionPageComponent } from './pages/configuracion/configuracion-page/configuracion-page.component';
import { AuthGuardAdminSupervisor } from './pages/login/services/auth-guard-admin-supervisor';
import { AuthGuardAdministrador } from './pages/login/services/auth-guard-administrador';
import { EmpleadosPageComponent } from './pages/empleados/components/mantenimiento/empleados-page/empleados-page.component';
import { FetchConfiguracionAplicationService } from './pages/empleados/services/mantenimiento/fetch-configuracion-aplication.service';

const routes: Routes = [
  {
    path: 'IniPage',
    component: IniPageComponent
  },
  {
    path: 'loginAzureAd',
    component: LoginAzureAdComponent
  },
  {
    path: 'selectTenant/:token',
    component: SelectorTenantsComponent
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'register/:mensaje',
    component: RegisterComponent,
  },
  {
    path: 'panel',
    component: StandarLayoutComponent,
    children: [

      {
        path: 'main',
        component: MainMenuComponent,
        data: {
          breadcrumb: 'Lanzadera'
        }
      },
      {
        path: 'envios',
        component: PanelDeControlComponent,
        data: {
          breadcrumb: 'Gestión de envíos'
        },

      },
      {
        path: 'envios/:nuevos',
        component: PanelDeControlComponent,
        data: {
          breadcrumb: 'Gestión de envíos'
        },

      },
      {
        path: 'subida',
        component: SubidaPrincipalComponent,
        data: {
          breadcrumb: 'Nuevo envío'
          // breadcrumb: 'Subida principal'
        }
      },
      {
        path: 'empleados',
        component: UploadExcelEmpleadosComponent,
        canActivate: [AuthGuardAdminSupervisor],
        data: {
          breadcrumb: 'Empleados'
        }
      },
      {
        path: 'usuarios',
        component: UsuariosPageComponent,
        canActivate: [AuthGuardAdministrador],
        data: {
          breadcrumb: 'Configuracion de usuarios'
        }
      },
      //   {
      //     path: 'Nuevo',
      //     component: DetalleFirmaFirmantesComponent,
      //     data: {
      //       breadcrumb: 'Nuevo envío estándar'
      //     },
      //   },
      //   {
      //     path: 'EnvioUniversal',
      //     component: DetalleFirmaFirmantesComponent,
      //     data: {
      //       breadcrumb: 'Nuevo envío universal'
      //     },
      //   },{
      //     path: 'Help',
      //     component: DetalleFirmasErroresComponent,
      //     data: {
      //       breadcrumb: 'Manual de usuario'
      //     },
      //   }
      {
        path: 'configuracion',
        component: ConfiguracionMaestrosComponent,
        canActivate: [AuthGuardAdminSupervisor],
        data: {
          breadcrumb: ''
        },
        children: [
          {
            path: 'empresas',
            component: EmpresasPageComponent,
            data: {
              breadcrumb: 'Configuración de empresas'
            }
          },
          {
            path: 'centros',
            component: CentrosPageComponent,
            data: {
              breadcrumb: 'Configuración de centros'
            }
          },
          {
            path: 'empleados',
            component: EmpleadosPageComponent,
            resolve:{
              data: FetchConfiguracionAplicationService
            },
            data: {
              breadcrumb: 'Configuración de empleados'
            }
          }
        ]
      },
      {
        path: 'dosieres-configurados',
        component: EnviosPageComponent,
        canActivate: [AuthGuardAdminSupervisor],
        data: {
          breadcrumb: 'Dosieres configurados'
        },
      },
      {
        path: 'dosieres-configurados/incompletos',
        component: EnviosPageComponent,
        canActivate: [AuthGuardAdminSupervisor],
        data: {
          breadcrumb: 'Dosieres configurados'
        },
      },
      {
        path: 'configuracion-dosier/:idTipoEnvio',
        component: ConfiguraEnvioPageComponent,
        canActivate: [AuthGuardAdminSupervisor],
        data: {
          breadcrumb: 'Configuración de dosier'
        },
      },
      {
        path: 'documentos-configurados',
        component: DocumentosPageComponent,
        canActivate: [AuthGuardAdminSupervisor],
        data: {
          breadcrumb: 'Documentos configurados'
        },
      },
      {
        path: 'nueva-configuracion-documento',
        component: ConfiguracionDocumentoPageComponent,
        canActivate: [AuthGuardAdminSupervisor],
        data: {
          breadcrumb: 'Nueva configuración de documento'
        },
      },
      {
        path: 'editar-configuracion-documento',
        component: ConfiguracionDocumentoPageComponent,
        canActivate: [AuthGuardAdminSupervisor],
        data: {
          breadcrumb: 'Editar configuración de documento'
        },
      },
      {
        path: 'configuracion-usuario',
        component: UsuarioActivoPageComponent,
        data: {
          breadcrumb: 'Configuración de usuario'
        },
      },
      {
        path: 'configuracion-rlt',
        component: ConfiguraRltPageComponent,
        canActivate: [AuthGuardAdminSupervisor],
        data: {
          breadcrumb: 'Configuración RLT'
        },
      },
      {
        path: 'opciones-configuracion',
        component: ConfiguracionPageComponent,
        data: {
          breadcrumb: 'Opciones configuración'
        },
      }
    ]
  },
  {
    path: '',
    redirectTo: '/IniPage',
    pathMatch: 'full'
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes , {useHash: true})],
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
