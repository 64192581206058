import { Firmantes } from "./firmantes";
import { TipoDocumento } from "../../documentos/models/tipo-documento";
import { TipoMail } from "./tipo-mail";


export interface TipoEnvio {
    idV3TipoEnvio: string;
    idTipoEnvio: string;
    nombre: string;
    directorio: string;
    subdirectorio: string;
    activo: boolean;
    diasRecordatorio: number;
    subject: string;
    body: string;
    numDocEnviar?: number;
    mailPrincipal: TipoMail;
    envioMasivo: boolean;
    esSoloGenericos: boolean;
    tiposDocumento: TipoDocumento[];
    estado: string;
    documentosAsociados:number;
    validadores: number;
    estadoGrid: string;
    firmantes:Firmantes[];
    tipoMail:TipoMail;
    solicitarAdjunto:boolean;
    numeroAdjuntos:number;
    recordatorio: boolean;
    reminders_String: number[];
    // caduca:boolean;
    // expire_time: number;
    programado: boolean;
    documentosEnvioProgramado:number;
    esRLT: boolean;
}

export class EstadosTipoEnvio{
  static incompleto: string ='Incompleto'
  static completo: string = 'Listo para envío'
}
export class EstadosTipoEnvioApi{
  static incompleto: string ='Incompleto'
  static completo: string = 'Completo'
}

export interface EnviosSearchResponse{
  resultados: string;
  datos: TipoEnvio[];
}

export class TipoEnvioClass{
  activo = false;
  idV3TipoEnvio = "";
  idTipoEnvio = "";
  nombre = "";
  firmantes = [
    {
    orden: 1,
    tipoFirmante: {codigo: '100', desc: 'Firmante principal'},
    tipoDestinatario: {codigo: '', desc: ''},
    tipoBusqueda: {codigo: '', desc: ''}
    }
  ];
  mailPrincipal = {codigo: "", desc: ""};
  subject = "";
  body = "";
  tiposDocumento = [];
  recordatorio = false;
  reminders_String = null;
  envioMasivo = false;
}
