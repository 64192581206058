import { Component, OnInit } from '@angular/core';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../services/formulario-validaciones-docs.service';
import { FormArray } from '@angular/forms';
import { ConfiguracionFirma, FirmanteNif } from '../../models/firmante-nif';

@Component({
  selector: 'app-configuracion-documento-texto-excluir',
  templateUrl: './configuracion-documento-texto-excluir.component.html',
  styleUrls: ['./configuracion-documento-texto-excluir.component.css']
})
export class ConfiguracionDocumentoTextoExcluirComponent implements OnInit {

  icono: string = '';
  mostrarAvanzada: boolean = false;

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              public formularioValidacionesDocsService: FormularioValidacionesDocsService) { }

  ngOnInit(): void {
    if(this.formatoNombreArchivoFirmado.invalid ||
    this.formatoNombreArchivoAuditTrail.invalid ||
    this.split.invalid || this.split.value !== '' ||
    this.esCopiaBasica.invalid || this.esCopiaBasica.value) this.mostrarAvanzada = true;
  }

  get nombre(){ return this.formularioDocumentoService.formularioDocumento.controls['nombre'] }
  get formatoNombreArchivoFirmado(){ return this.formularioDocumentoService.formularioDocumento.controls['formatoNombreArchivoFirmado'] }
  get formatoNombreArchivoAuditTrail(){ return this.formularioDocumentoService.formularioDocumento.controls['formatoNombreArchivoAuditTrail'] }
  get split(){ return this.formularioDocumentoService.formularioDocumento.controls['split'] }
  get esCopiaBasica(){ return this.formularioDocumentoService.formularioDocumento.controls['esCopiaBasica'] }
  get firmantesNif(){ return this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'] as FormArray}

  mostrarConfAvanzada(){
    this.mostrarAvanzada = !this.mostrarAvanzada;
  }

  //si deschequeamos copia básica, el firmante principal (si estamos haciendo una modificación o hemos tirado para atrás después de haber ido al paso 8), se elimina del array FirmantesNif.
  getEsCopiaBasicaCheckBox(){
    this.esCopiaBasica?.valueChanges.subscribe(value => {
      if(!value && this.firmantesNif.value.length>0){
        let valoresFirmanteNif: FirmanteNif;
        valoresFirmanteNif = {
          tipoFirmante: {
            codigo: '100',
            desc: 'Firmante principal'
          },
          nifPage: null,
          nifOrden: null,
          firmas: {} as ConfiguracionFirma,
          formularios: [],
          camposEditables: [],
          nifFormulario: null,
          posicionNif: null
        }
        this.formularioDocumentoService.deleteFirmanteNifInFormArray(valoresFirmanteNif)
      }
    })
  }
}
