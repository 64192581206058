<form [formGroup]="formGroup">
  <div class="modal-container">
    <div class="modal-header">
      <svg class="icon-close" mat-dialog-close>
        <use xlink:href="#icon-close"></use>
      </svg>
      <h1>Eliminar errores</h1>
      <div class="info-header">
        <app-alerta-accion-irreversible></app-alerta-accion-irreversible>
        <p>Se eliminarán de forma irreversible <strong>todos</strong> los errores de los registros mostrados en el listado.</p>
        <p>Es recomendable conservar los registros para completar los envíos.</p>
      </div>
    </div>
    <div class="flex-container">
      <div class="modal-content">
        <p>Selecciona los errores a eliminar:</p>
        <div class="todos">
          <input type="checkbox" name="checkbox-select-all"  formControlName="eliminarTodos" (change)="change($event)"/>
          <label for="checkbox-select-all">Todos</label>
        </div>
        <div class="modal-scroll">
          <ul class="modal-error-list">
            <li *ngFor="let firma of firmas">
              <div class="error-list-title">
                <div>
                  <input type="checkbox" name="checkbox-select-remove" formControlName="eliminar_{{firma.idFirma}}"/>
                </div>
                <label>{{firma.mensaje}}</label>
              </div>
              <div class="error-list-data">
                <div>{{firma.tipoEnvio}}</div>
                <div>{{firma.empleado}}</div>
                <div>{{firma.nombreEmpresa}}</div>
                <div>{{firma.codigoCentro}}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-buttons">
        <app-boton-accion mat-dialog-close [icono]="'icon-close'" [titulo]="'cancelar'" [id]="'cancelar'" [outline]="true" [cancelar]="true"></app-boton-accion>
        <app-boton-accion [icono]="'hs-bin'" [titulo]="'Eliminar Seleccion'" (botonClicked)="confirmarEliminacion()"></app-boton-accion>
      </div>
    </div>
  </div>
</form>

<!-- <app-titulo-opcion matDialogTitle [titulo]="'Eliminar errores'" [icono]="'assets/images/gestion_envios/icon_bin.svg'">
</app-titulo-opcion>
<p>Acion irreversible</p>
<p>Se eliminarán de forma irreversible todos los errores de los registros mostrados en el listado. Es recomendable conservar los registros para completar los envíos.</p>
<p>Selecciona los errores a eliminar:</p>
<form [formGroup]="formGroup">
    <div>
        <mat-checkbox [color]="'primary'" formControlName="eliminarTodos">Todos</mat-checkbox>
    </div>
    <mat-dialog-content>
        <table>
            <tr *ngFor="let firma of firmas">
                <td>
                    <mat-checkbox [color]="'primary'" formControlName="eliminar_{{firma.idFirma}}"></mat-checkbox>
                </td>
                <td>
                    <img src="assets/images/estados_firmas/error.svg">
                </td>
                <td>
                    <p>{{firma.mensaje}}</p>
                    <p>Tipo de dosier: <b>{{firma.tipoEnvio}}</b></p>
                    <p>Firmante principal: <b>{{firma.empleado}}</b></p>
                    <p>Empresa: <b>{{firma.codigoEmpresa}}</b></p>
                    <p>Centro: <b>{{firma.codigoCentro}}</b></p>
                </td>
            </tr>
        </table>
    </mat-dialog-content>
</form>
<mat-dialog-actions style="justify-content: end;">
    <div style="display: flex;">
        <app-boton-cancelar mat-button mat-dialog-close></app-boton-cancelar>
        <app-boton-accion mat-button (botonClicked)="confirmarEliminacion()" [icono]="'assets/images/gestion_envios/icon_bin.svg'" [titulo]="'Eliminar selección'" [id]="'Eliminar selección'"></app-boton-accion>
    </div>
</mat-dialog-actions> -->
