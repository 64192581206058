import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY } from '@angular/cdk/overlay/overlay-directives';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ContenidoDocVisible } from '../../models/contenido-doc-visible';
import { DatosConfiguracionDocumentosService } from '../../services/datos-configuracion-documentos.service';

@Component({
  selector: 'app-doc-drag',
  templateUrl: './doc-drag.component.html',
  styleUrls: ['./doc-drag.component.css']
})
export class DocDragComponent implements OnInit, OnChanges {

  @Input('x') x: number;
  @Input('y') y: number;
  @Input('Scrolly') scrolly: number;
  @Input('palabra') palabra: ContenidoDocVisible;
  @Input('i') i: number;
  @Input('id') id: number;
  @Input('pagina') pagina: number;

  @ViewChild('draggableDiv') draggableDiv: ElementRef;
  @ViewChild('draggableIcon') draggableIcon: ElementRef;
  @Output() coordAfterDrag: EventEmitter<any> = new EventEmitter<any>();
  @Output() borrarPalabra: EventEmitter<any> = new EventEmitter<any>();

  isDragging: boolean = false;
  topPosition:number = 0;
  leftPosition:number = 0;
  currentX:number = 0;
  currentY:number = 0;
  initialX:number = 0;
  initialY:number = 0;
  xOffset:number = 0;
  yOffset:number = 0;
  scrollTop:number = 0;
  finalX:number = 0;
  finalY:number = 0;
  clientX:number;
  clientY:number;

  palabraPosition: { left: number, top: number, height: number, width: number };

  constructor(private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService) { }

  ngOnInit() { }

  //recibimos la nueva posición del padre y actualizamos valores
  ngOnChanges(changes: SimpleChanges): void {
    this.leftPosition = this.x
    if(changes.scrolly){
      this.topPosition = this.y - changes.scrolly.currentValue
    }else{
      this.topPosition = this.y
    }
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: any) {

    //miramos si el mouse está dentro del div que queremos mover
    if (event.target === this.draggableIcon.nativeElement) {
      this.isDragging = true;
      this.clientX = event.clientX;
      this.clientY = event.clientY;
      this.initialX = event.clientX - this.xOffset;
      this.initialY = event.clientY - this.yOffset;
    }
  }

  //si isDragging es true movemos el div y mandamos la posición al servicio para comprobar que esté dentro de los límites del documento. Si está actualizamos nueva posición con setTransform()
  @HostListener('document:mousemove', ['$event']) onMouseMove(event: any) {
    let draggableDiv = this.draggableDiv.nativeElement;
    if (this.isDragging) {
      this.currentX = event.clientX - this.initialX;
      this.currentY = event.clientY - this.initialY;
      this.xOffset = this.currentX;
      this.yOffset = this.currentY;
      this.finalX = this.currentX + this.leftPosition;
      this.finalY = this.currentY + this.topPosition - 50;
      this.palabraPosition = {
        left: this.finalX,
        top: this.finalY,
        height: draggableDiv.clientHeight,
        width: draggableDiv.clientWidth
      }

      this.setTransform();
    }
  }

  //enviamos la nueva posición y tamaño al padre
  @HostListener('document:mouseup', ['$event']) onMouseUp(event: any) {
    this.isDragging = false;
    let draggableDiv = this.draggableDiv.nativeElement;

    //miramos si el mouse sale del icono
    if (event.target === this.draggableIcon.nativeElement) {
      this.finalX = this.currentX + this.leftPosition;
      this.finalY = this.currentY + this.topPosition + this.scrolly;

      this.palabraPosition = {
        left: this.finalX,
        top: this.finalY,
        height: draggableDiv.clientHeight,
        width: draggableDiv.clientWidth
      }
    }

    //si estamos haciendo resize, actualizamos con nuevas medidas y posiciones
    if(event.target === draggableDiv){
      this.palabraPosition = {
        left: this.currentX + this.leftPosition,
        top: this.currentY + this.topPosition + this.scrolly,
        height: draggableDiv.clientHeight,
        width: draggableDiv.clientWidth
      }
    }

    if((event.target === this.draggableIcon.nativeElement || event.target === draggableDiv) && this.palabraPosition){
      //restamos el scroll antes de pasarlo al servicio para tener las coord en pantalla, no las reales
      this.palabraPosition.top = this.palabraPosition.top - this.scrolly;
      this.datosConfiguracionDocumentosService.palabraPosition = this.palabraPosition;
      if(!this.datosConfiguracionDocumentosService.isDentro('doc-drag', this.palabraPosition)){
        this.draggableDiv.nativeElement.style.display='none';
        this.borrarPalabra.emit(this.id)
      }else{
        //añadimos otra vez scroll para tener las coord reales antes de mandarlo al padre
        this.coordAfterDrag.emit({
            id: this.id,
            xAfterDrag: this.palabraPosition.left,
            yAfterDrag: this.palabraPosition.top + this.scrolly,
            width: this.palabraPosition.width,
            height: this.palabraPosition.height,
            tipo: {id: this.palabra.tipo.id, nombre: this.palabra.tipo.nombre}
          })
      }
    }
  }

  setTransform() {
    this.draggableDiv.nativeElement.style.transform = `translate3d(${this.currentX}px, ${this.currentY - this.scrollTop}px, 0)`;
  }

  borrarCampo(){
    this.borrarPalabra.emit(this.id)
    this.draggableDiv.nativeElement.style.display='none';
  }
}
