import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-indicador-numero',
  templateUrl: './indicador-numero.component.html',
  styleUrls: ['./indicador-numero.component.css']
})
export class IndicadorNumeroComponent implements OnInit{

  @Input() numero: string;
  @Input() color: string;

  numi:string = '999';

  constructor() { }

  ngOnInit(): void {
  }

  getClass(num: string){
    switch(num.length){
      case 1:
        return "circulo-s"
      break;
      case 2:
        return "circulo-s"
      break;
      case 3:
        return "circulo-m"
      break;
      case 4:
        return "circulo-l"
      break;
      default:
        return "circulo-s"
    }

  }

}
