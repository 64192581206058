export class InfoApi{
    baseUrl:string="";
    nombre:string="";
    version:('V2'|'V3'|'')="";
    endpointV2:string="";
    endpointV3:string="";
    constructor(value?:Partial<InfoApi>){
        Object.assign(this,value);

    }
    

}