import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DesplegableData, SeleccionItems } from 'src/app/shared/models/desplegable-data';
import { TooltipMessages } from 'src/app/shared/models/tooltip.mesages';

@Component({
  selector: 'app-drop-down-multiseleccion',
  templateUrl: './drop-down-multiseleccion.component.html',
  styleUrls: ['./drop-down-multiseleccion.component.css']
})
export class DropDownMultiseleccionComponent {

  @Input() tituloDropDown: string ='';
  @Input() listaDesplegable: DesplegableData[] = [];
  @Input() listaDesplegableInicial: DesplegableData[]=[];
  @Output() seleccionItemsOut: EventEmitter<SeleccionItems> = new EventEmitter<SeleccionItems>();
  @Output() limpiarSeleccion: EventEmitter<string> = new EventEmitter<string>();
  mostrarLista: boolean = false;
  itemsEnviar: SeleccionItems = {} as SeleccionItems;
  @Input() limpiarTodo: {num: number, clear: boolean};
  deseleccion: boolean = false;

  checkboxForm: FormGroup = new FormGroup({});

  constructor(private formBuilder: FormBuilder,
              private eRef: ElementRef) {
                this.inicializarCheckboxForm();
              }

  ngOnChanges(changes: SimpleChanges){
    if((changes.listaDesplegable && changes.listaDesplegable.currentValue)){
      this.listaDesplegableInicial = changes.listaDesplegable.currentValue;
      changes.listaDesplegable.currentValue.forEach((item:any) => {
        const control = this.formBuilder.control(false);
        this.checkboxFormItems.push(control);
      });
    }
    if(changes.limpiarTodo && changes.limpiarTodo.currentValue.num >0){
      while (this.checkboxFormItems.length !== 0) {
        this.checkboxFormItems.removeAt(0)
      }
      changes.listaDesplegable.currentValue.forEach((item:any) => {
        const control = this.formBuilder.control(false);
        this.checkboxFormItems.push(control);
      });
      this.itemsEnviar ={
        tipo:'',
        items:[]
      }
    }
  }

  ngOnInit(){
    // this.listaDesplegable.forEach(x=> x.seleccionado=false)
  }

  inicializarCheckboxForm(){
    this.checkboxForm = this.formBuilder.group({
      items: this.formBuilder.array([])
    });
  }

  get checkboxFormItems(){ return this.checkboxForm.get('items') as FormArray}

  onCheckboxChange(i:number){
    // const itemsSeleccionados = this.checkboxForm.value.items
    // .map((checked:boolean, i:number) => (checked ? this.listaDesplegable[i] : null))
    // .filter((item:any) => item !== null);

    this.checkboxFormItems.controls.forEach((x,i)=>this.listaDesplegable[i].seleccionado=x.value)

    if(this.itemsEnviar.items?.length>0){
      this.itemsEnviar.items.forEach((x,i)=>{
        this.listaDesplegable.forEach(y=>{
          if(x.id==y.id && y.seleccionado==false) this.itemsEnviar.items.splice(i,1)
        })
      })
    }
    let seleccion = this.listaDesplegable.filter(x=> x.seleccionado==true)
    if(this.itemsEnviar.items?.length>0){

      seleccion.forEach(x=>{
        if(!this.itemsEnviar.items.includes(x)) this.itemsEnviar.items.push(x)
      })
    }else{
      this.itemsEnviar = {
        tipo: this.tituloDropDown,
        items: seleccion
      }
    }
    this.seleccionItemsOut.emit(this.itemsEnviar)
  }

  abrirMenu(){
    this.mostrarLista = !this.mostrarLista;
  }

  limpiarFiltro(){
    while (this.checkboxFormItems.length !== 0) {
      this.checkboxFormItems.removeAt(0)
    }
    this.listaDesplegable.forEach((item:any) => {
    const control = this.formBuilder.control(false);
    this.checkboxFormItems.push(control);
    });
    this.itemsEnviar ={
      tipo:this.itemsEnviar.tipo,
      items:[]
    }
    this.seleccionItemsOut.emit(this.itemsEnviar)
    this.mostrarLista = false;
  }

  @HostListener('document:click', ['$event']) clickout(event:any) {
    if(!this.eRef.nativeElement.contains(event.target) && !this.deseleccion && this.mostrarLista) {
      this.mostrarLista = false;
      this.buscarItems('');
    }
    this.deseleccion = false;
  }

  deseleccionarItems(id:string){
    let index = this.listaDesplegable.findIndex(x=> x.id == id);
    let indexEliminar = this.itemsEnviar.items.findIndex(x=> x.id == id);
    this.itemsEnviar.items.splice(indexEliminar,1);
    this.checkboxFormItems.at(index).patchValue(false);
    this.seleccionItemsOut.emit(this.itemsEnviar);
    this.deseleccion = true;
  }

  buscarItems(event:any){
    let listaTemporal = this.listaDesplegable;
    //marcamos los seleccionados para que se mantengan seleccionados
    this.checkboxFormItems.controls.forEach((x,i)=>{
      if(this.listaDesplegable[i].hasOwnProperty('seleccionado')) this.listaDesplegable[i].seleccionado=x.value
  });
    listaTemporal?.forEach(x=>{
      this.listaDesplegableInicial.map(y=>{
        if(x.id == y.id){
          y.seleccionado = x.seleccionado
        }
      })
    })
    this.listaDesplegable = this.listaDesplegableInicial;
    if(event!=='' || event!==null || event!==undefined){
      this.listaDesplegable = this.listaDesplegableInicial.filter(x=> x.desc.toString().toUpperCase().includes(event.toString().toUpperCase()));
    }else{
      this.listaDesplegable = this.listaDesplegableInicial;
    }

    while (this.checkboxFormItems.length !== 0) {
      this.checkboxFormItems.removeAt(0)
    }

    this.listaDesplegable.forEach((item:any) => {
      const control = this.formBuilder.control(item.seleccionado);
      this.checkboxFormItems.push(control);
      if(item.seleccionado){
        let index = this.itemsEnviar.items.findIndex(x=> x.id == item.id)
        if(index == -1) this.itemsEnviar.items.push(item);

        this.seleccionItemsOut.emit(this.itemsEnviar);
      }
    });
  }

  getTooltip(filtro: string){
    if(this.itemsEnviar.items?.length==1){
      if (filtro == "Tipo dosier") return TooltipMessages.filtro_tipoDosier;
      else if (filtro == "Empresa") return TooltipMessages.filtro_empresa;
      else if (filtro == "Centro") return TooltipMessages.filtro_centro;
      else return '';
    }else{
      return '';
    }
  }

}

