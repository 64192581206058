<!-- <div [ngClass]="{'aside-container':true,'aside-big':!oculto,'aside-small':oculto}">

  <div class="aside-title">
      <span>Progreso</span>
      <app-boton-ocultar (cambioOculto)="clickOcultar($event)"></app-boton-ocultar>
  </div>
  <div class="aside-content aside-progress">
      <ul>
          <div *ngFor="let elemento of elementos; let i = index">
              <li class="{{getClass(i)}}"><span></span><label *ngIf="!oculto">{{i+1}}. {{elemento}}</label></li>
          </div>
      </ul>
  </div>
</div> -->

<!-- <div> -->
  <div class="container" [ngClass]="{'border-radius' : indexStep<3}">
    <h6>PROGRESO</h6>
    <div class="progreso-linia" >

      <div class="parent" *ngFor="let step of steps; let i=index">

        <!-- <div class="dots" [ngClass]="[i%2==0?'step-active':'', tick?'step-done':'']"> -->
        <div class="dots" [ngClass]="getClass(i+1)">
          <span *ngIf="!getTick(i+1)" class="white-dot"></span>
          <span *ngIf="getTick(i+1)">
            <svg class="icon-tick">
              <use xlink:href="#hs-tick2"></use>
            </svg>
          </span>

        </div>
        <div *ngIf="i<10" class="step" [ngClass]="getStepClassLine(i+1)">
        </div>
      </div>

    </div>
  </div>

<!-- </div> -->
<!-- <div class="aside-container">
  <div class="aside-content aside-progress">
    <ul>
        <div *ngFor="let step of steps; let i = index">
            <li class="{{getClass(i)}}"> -->
            <!-- <li class="progess-active"> -->
              <!-- <span>{{i+1}}</span> -->
              <!-- <label></label> -->
            <!-- </li>
        </div>
    </ul>
  </div>
</div> -->
