import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';
import { FormArray, FormGroup } from '@angular/forms';
import { TipoFirma } from '../../models/firmante-nif';
import { Subscription } from 'rxjs';
import { DatosConfiguracionDocumentosService } from '../../services/datos-configuracion-documentos.service';

@Component({
  selector: 'app-configuracion-documento-botonera',
  templateUrl: './configuracion-documento-botonera.component.html',
  styleUrls: ['./configuracion-documento-botonera.component.css']
})
export class ConfiguracionDocumentoBotoneraComponent implements OnInit {

  icono: string = 'icon-arrow-forward';
  titulo: string = 'Siguiente';
  @Input() id: string;
  @Input() botonAccion: boolean = true;
  @Output() botonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() index: number;
  firmanteValidadorSubscripcion: Subscription;
  isValidador: boolean = false;

  constructor(private _snackBar: MatSnackBar,
              private formularioDocumentoService: FormularioDocumentoService,
              private datosConfiguracionDocumentoService: DatosConfiguracionDocumentosService) {
                this.getUsuarioActivoEditado();
               }

  get esRLT(){ return this.formularioDocumentoService.formularioDocumento.controls['esRLT']}
  get firmantesNif(){ return this.formularioDocumentoService.firmantesNif as FormArray}

  ngOnChanges(changes: SimpleChanges){
    if(changes.index.currentValue == 11 ||
       changes.index.currentValue == 12 ||
       (this.esRLT.value && changes.index.currentValue == 9) ||
       (changes.index.currentValue == 9 && this.checkSiValidador())) this.titulo = 'Finalizar';
    else this.titulo = 'Siguiente';
  }

  ngOnInit(): void {
  }

  atras(){
    this.botonClicked.emit('atras');
  }

  clicked(){
    if(this.index == 1) {
      this._snackBar.open(`Se tiene que subir un documento para poder continuar`,'Cerrar', {duration: 3000})
      this.botonClicked.emit()
    }else{
      this.botonClicked.emit(this.titulo)
    }

    // if(this.index == 11 || (this.esRLT.value && this.index == 9)){
    //   this.botonClicked.emit('finalizar');
    // }else{
    //   this.botonClicked.emit('siguiente');
    // }
  }

  checkSiValidador():boolean{
    let valorRetornado: boolean = false;
    if(this.firmantesNif.value.length==1 &&
      (this.firmantesNif.at(0)?.get('firmas') as FormGroup).controls['tipoFirma'].value==TipoFirma.Validador){
        this.titulo = 'Finalizar'
        valorRetornado=true
      }else{
        this.titulo = 'Siguiente'
        valorRetornado=false
      }
    return valorRetornado
  }

  getUsuarioActivoEditado(){
    this.firmanteValidadorSubscripcion = this.datosConfiguracionDocumentoService.datosValidador$.subscribe((value:any) => {
      if(value) this.checkSiValidador();
  })
}

}
