import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ConfiguracionRlt, DiaSemana } from '../models/configuracion-rlt';
import { DatosConfiguracionAplicacionService } from '../services/datos-configuracion-aplicacion.service';
import { TipoEnvio } from '../../Envios/envios/models/tipo-envio';
import { DatosEnviosService } from '../../Envios/envios/services/datos-envios.service';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { MatDialog } from '@angular/material/dialog';
import { ValidacionesFormularioRltService } from '../services/validaciones-formulario-rlt.service';

@Component({
  selector: 'app-configura-rlt-page',
  templateUrl: './configura-rlt-page.component.html',
  styleUrls: ['./configura-rlt-page.component.css']
})
export class ConfiguraRltPageComponent implements OnInit {

  configuracionRlt: ConfiguracionRlt = {} as ConfiguracionRlt;
  diasSemana: DiaSemana[] = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
  times: string[] = [];
  enviosData: TipoEnvio[] = [];
  enviosDataInicial: TipoEnvio[] = [];
  selectedDate: Date;
  hours: number[] = Array.from({ length: 24 }, (_, i) => i);
  mostrarLista: boolean = false;
  @ViewChild('dropDownDiv') dropDownDiv: ElementRef;

  constructor(public datosConfiguracionAplicacionService: DatosConfiguracionAplicacionService,
              private datosEnviosService: DatosEnviosService,
              private dialog: MatDialog,
              public validacionesFormularioRltService: ValidacionesFormularioRltService
) { }

  async ngOnInit() {
    await this.getEnviosData();
    this.datosConfiguracionAplicacionService.getConfiguracionRlt();
    this.generateTimes();
  }

  get activo(){ return this.datosConfiguracionAplicacionService.configuracionRltForm.controls['activo']}

  get configuracionRltForm(){ return this.datosConfiguracionAplicacionService.configuracionRltForm}

  guardaConfiguracionRlt():string | void{

    // this.actualizarFormulario();
    if(this.configuracionRltForm.invalid){

      this.configuracionRltForm.markAllAsTouched();
      return
    }

    let objetoConfiguracionRlt: ConfiguracionRlt;

    let dosier: TipoEnvio;

    this.enviosData.forEach(x => {
      if(x.nombre == this.configuracionRltForm.controls['dosier'].value){
        dosier = x;

      }
    })

    objetoConfiguracionRlt = {
      activo: this.activo.value,
      idTipoDosier: Number(dosier.idTipoEnvio),
      idTipoDosierV3: dosier.idV3TipoEnvio,
      periodicidad: this.configuracionRltForm.controls['periodicidad'].value,
      diaEnvio: this.configuracionRltForm.controls['diaEnvio'].value,
      hora: this.configuracionRltForm.controls['horaEnvio'].value
    }

    this.datosConfiguracionAplicacionService.guardaConfiguracionRlt(objetoConfiguracionRlt).subscribe(
      ok => {
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'Configuración del RLT guardada correctamente',
          },
        });
      },
      error => {
        console.error("error", error);
      }
    )
  }

  toggleActivado(event: any){
    this.activo.setValue(event);
  }

  async getEnviosData(){
    await this.datosEnviosService.getTipoEnvios();
    this.enviosData = this.datosEnviosService.enviosFiltrados;
    // this.enviosDataInicial = [...this.datosEnviosService.enviosFiltrados];
  }

  generateTimes() {
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0); // Set start time to midnight

    const endTime = new Date();
    endTime.setHours(23, 59, 59, 999); // Set end time to 11:59 PM

    const intervalMinutes = 1;
    let currentTime = new Date(startTime);

    while (currentTime <= endTime) {
      const timeString = this.formatTime(currentTime);
      this.times.push(timeString);

      currentTime = new Date(currentTime.getTime() + intervalMinutes * 60000);
    }
  }

  formatTime(date: Date): string {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');

    return `${hoursString}:${minutesString}`;
  }

  // filtrarDosier(event:string){
  //   if(event!=''){
  //     this.enviosData = this.enviosDataInicial.filter(x=> x.nombre.toString().toUpperCase().includes(event.toString().toUpperCase()));
  //   }else{
  //     this.enviosData = this.enviosDataInicial;
  //   }
  // }


  abrirMenu(){
    this.mostrarLista = !this.mostrarLista
  }

  getEnvioSeleccionado(envio: string){
    this.configuracionRltForm.controls['dosier'].setValue(envio)
    this.mostrarLista = false;
  }

  // @HostListener('document:click', ['$event']) clickout(event:any) {
  //   if(!this.dropDownDiv.nativeElement.contains(event.target)) {
  //     this.mostrarLista = false;
  //     this.filtrarDosier('');
  //   }
  // }

  actualizarFormulario(){
    try{
      if(this.datosConfiguracionAplicacionService.configuracionRltForm.controls){
        this.datosConfiguracionAplicacionService.configuracionRltForm.controls['activo'].updateValueAndValidity();
        this.datosConfiguracionAplicacionService.configuracionRltForm.controls['dosier'].updateValueAndValidity();
        this.datosConfiguracionAplicacionService.configuracionRltForm.controls['periodicidad'].updateValueAndValidity();
        this.datosConfiguracionAplicacionService.configuracionRltForm.controls['diaEnvio'].updateValueAndValidity();
        this.datosConfiguracionAplicacionService.configuracionRltForm.controls['horaEnvio'].updateValueAndValidity();
        this.datosConfiguracionAplicacionService.configuracionRltForm.updateValueAndValidity();
      }
    }catch(ex){
      console.error(ex);
    }
  }
}
