import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit {
  @Input()porcentajeSubida: string = "0%";
  @Input()mensjeCompletado:string ='';
  @Input()mensjeProgreso:string ='';
  @Input()textoLink:string = '';
  @Output()linkClicked:EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }
  click():void{
    this.linkClicked.emit(true);
  }

}
