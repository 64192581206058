<form class="login-container scale-up-center" [formGroup]="formGroup">
    <img [src]="'assets/images/logo-hire-sign-color.png'" />
    <mat-form-field class="login-element-fullwidth" appearance="fill">
        <mat-label>Usuario</mat-label>
        <input id="input-login" type="text" matInput formControlName="Username">
        <mat-error *ngIf="!usernameOk()">{{usernameError()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="login-element-fullwidth" appearance="fill">
        <mat-label>Contraseña</mat-label>
        <input type="password" matInput formControlName="Password" autocomplete="off">
        <mat-error *ngIf="!passwordOk()">{{passwordError()}}</mat-error>
    </mat-form-field>
    <button mat-raised-button class="login-element-fullwidth boton-login" (click)="hacerLogin()">
        <div>
            <span *ngIf="!procesando">Entrar</span>
            <mat-spinner diameter="25" color="primary" *ngIf="procesando"></mat-spinner>
        </div>
    </button>
</form>
<div class="login-terms">
    <div>© {{year}} SQUAREPOINT S.L. Todos los derechos reservados. </div>
    <div style="font-weight: bold;"><a href="https://hireandsign.com/nota-legal">Aviso legal</a></div>
</div>
