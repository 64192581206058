import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TiposContenido } from '../../models/tipos-contenido';
import { DatosConfiguracionDocumentosService } from '../../services/datos-configuracion-documentos.service';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';
import { DatosTiposContenidoService } from '../../services/datos-tipos-contenido.service';

@Component({
  selector: 'app-configuracion-documento-incluir-contenido',
  templateUrl: './configuracion-documento-incluir-contenido.component.html',
  styleUrls: ['./configuracion-documento-incluir-contenido.component.css']
})
export class ConfiguracionDocumentoIncluirContenidoComponent implements OnInit {

  mostrarListaPalabras: boolean = false;
  palabrasClave: any[] = [];
  top: number = 0;
  left: number = 0;
  posicion: number = 0;
  palabraMover: TiposContenido;
  palabraMoverArray: TiposContenido[] = []

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService,
              private _snackBar: MatSnackBar,
              private datosTiposContenidoService: DatosTiposContenidoService) {
                this.getTiposContenidoData()
               }

  ngOnInit(): void {
    if(this.incluirContenido.value == true) this.mostrarListaPalabras = true
    else this.mostrarListaPalabras = false
  }

  get nombre(){ return this.formularioDocumentoService.formularioDocumento.controls['nombre'] }
  get incluirContenido(){return this.formularioDocumentoService.formularioDocumento.get('incluirContenido') }

  mostrarPalabrasClave(){
    this.mostrarListaPalabras = !this.mostrarListaPalabras;
    if(this.mostrarListaPalabras == false) this.incluirContenido.setValue(false);
    else this.incluirContenido.setValue(true);
  }

  getClickCheckbox(){
    this.incluirContenido?.valueChanges.subscribe(value => {
      if(value) this.mostrarListaPalabras = true;
      else this.mostrarListaPalabras = false;
    })
  }

  getTiposContenidoData(){
    this.datosTiposContenidoService.getTiposContenido().subscribe(
      next => {
        this.palabrasClave = next;

      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  //Obtenemos la palabra a mover del hijo app-palabra-clave y la añadimos al array que pasaremos al hijo contenido-doc para empezar a mover
  getPalabraAMover(event: any){
    this.left = event.x;
    this.top = event.y;
    this.palabraMover = {
      nombre: event.tipo.nombre,
      id: event.tipo.id
    }
    this.palabraMoverArray.push(this.palabraMover)
    //solo puede haber un elemento al momento de moverse. Si se ha creado uno y no se ha movido, lo borramos al crear el segundo
    if(this.palabraMoverArray.length > 1){
      this.palabraMoverArray.shift();
    }
  }
}
