<div class="rectangulo-container">
  <!-- <div class="icono"> -->
    <svg class="icono">
      <use attr.xlink:href="{{icono}}"></use>
    </svg>
  <!-- </div> -->
  <div class="texto">
    <span class="titulo">{{titulo}}</span>
    <br>
    <span class="text">{{texto}}</span>
  </div>
</div>
