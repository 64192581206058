import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../login/services/login.service';
import { Firmas } from '../models/firmas';
import { IGestionarFirmasService } from './ports/i-gestionar-firmas-service';

@Injectable({
  providedIn: 'root'
})
export class GestionaFirmasApiService implements IGestionarFirmasService{

  firmas: Firmas[]; // = [];

  constructor(private client: HttpClient,
              private endpointSerivce:EndpointService,
              private loginService: LoginService) { }

  GetFirmas(refresh?:boolean): Observable<void | any[]> {
    if(this.firmas && !refresh){
      return of(this.firmas)
    }else{
    let url = this.endpointSerivce.UrlGetFirmas.replace('{todos}', 'true');

    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token,
      })
    };
    return this.client.get(url, httpOptions).pipe(
      map((response: any)=>{

        return this.firmas = JSON.parse(response.Data);
        })
      )
    }
  }

  GetInfoDocs(index: number): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token,
      }),
    }

    let url = this.endpointSerivce.UrlFirmaGetDocumentos;
    url = url.includes('{idFirma}') ? url.replace('{idFirma}', this.firmas[index].idFirma.toString()) : `${url}/${this.firmas[index].idFirma.toString()}`;

    //CARGAMOS LOS DOCUMETNOS
    return this.client.get(url, httpOptions).pipe(
      map((response: any)=>{

      return  JSON.parse(response.Data)
    }))

  }

  GetInfoEventos(index: number): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token,
      }),
    }

    let url = this.endpointSerivce.UrlFirmaGetEventos;
    url = url.includes('{idFirma}') ? url.replace('{idFirma}', this.firmas[index].idFirma.toString()) : `${url}/${this.firmas[index].idFirma.toString()}`;

    return this.client.get(url, httpOptions).pipe(
      map((response: any)=>{

      return JSON.parse( response.Data);
    }))
  }

  GetInfoTipoDocs(index: number): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token,
      }),
    }

    let url = this.endpointSerivce.UrlFirmaGetTiposDocumentos;
    url = url.includes('{idFirma}') ? url.replace('{idFirma}', this.firmas[index].idFirma.toString()) : `${url}/${this.firmas[index].idFirma.toString()}`;

    return this.client.get(url, httpOptions).pipe(
      map((response: any)=>{

      return JSON.parse( response.Data);
    }))
  }

  BorrarFirmas(ids: number[]): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      }),
      body: ids,
    };
    return this.client.delete(this.endpointSerivce.UrlEliminaFirmas, options).pipe(
      map((response: any) =>{

        if (response.Ok) return JSON.parse(response.Data);
        else throw new Error(response.Message);
      })
    );
  }

  BorrarFirma(idFirma: number): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.delete(this.endpointSerivce.UrlFirmaElimina.replace('{idFirma}', idFirma.toString()), options).pipe(
      map((response: any) =>{
        if (response.Ok) return "Firma eliminada correctamente";
        else throw new Error(response.Message);
      })
    );
  }

  CancelarFirma(idFirma: number): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.post(this.endpointSerivce.UrlFirmaCancela.replace('{idFirma}', idFirma.toString()), null, options).pipe(
      map((response: any) =>{
        if (response.Ok) return "Firma cancelada correctamente";
        else throw new Error(`No se ha podido cancelar la firma por un error interno de Signaturit: ${response.Message}`);
      })
    );
  }

  DescargaFirmaCompleta(id: any): Observable<string> {


    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };    return this.client.get(this.endpointSerivce.UrlDescargarFirmas.replace('{id}', id.toString()),options).pipe(
      map((response: any) => {
        if(response == null) throw new Error("No se han encontrado ficheros disponibles para descargar");
        // Recuperamos el fichero en formato base64 string y obtenemos el array de bytes
        return atob(response.Data);
      }))
  }

}
