export interface ConfirmationMessageData {
    tipo: TipoMensajeConfirmacion;
    icono: string;
    titulo: string;
    mensaje: string;
    soloMensaje: string;
    preguntaConfirmacion: string;
    textoConfirmacion: string;
    iconoConfirmacion: string;
    extraInfo: any;
    textoCancelar: string;
    textoBoton: string;
}
export enum TipoMensajeConfirmacion{
    ConfirmacionSimple,
    ConfirmacionCompleja,
    Informacion,
    Error,
};
