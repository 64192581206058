<div id="dragDiv"
     #draggableDiv
     class="draggable resize"
     [ngStyle]="{'top.px': topPosition, 'left.px': leftPosition, 'width.px': palabra.width, 'height.px': palabra.height}">
      <svg #draggableIcon class="icon-draggable">
        <use xlink:href="#icon-draggable"></use>
       </svg>
       <span>{{palabra.tipo.nombre}}</span>
       <svg class="icon-close" (click)="borrarCampo()">
        <use xlink:href="#icon-close"></use>
       </svg>
     <div id="resizeDiv" class="pinta"></div>
</div>

