import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../login/services/login.service';
import { IGestionarEmpleadosService } from './ports/i-gestionar-empleados-service';

@Injectable({
  providedIn: 'root'
})
export class GestionaEmpleadosMockService implements IGestionarEmpleadosService{

  constructor(private loginService: LoginService,
              private httpClient: HttpClient) { }

  getExcelConfiguration(): Observable<void> {
    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        "authorization": `Bearer ${this.loginService.getUserLocalStorage().Token.Token}`
      })
    };
    return this.httpClient.get(`${environment.ApiEmpleadosExcelMock}`, httpOptions).pipe(
      map( (response: any) =>{

        return response.Data
      })
    );
  }

  addEmpleados(empleados: any[]): Observable<any> {
    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        "authorization": `Bearer ${this.loginService.getUserLocalStorage().Token.Token}`
      })
    };
    return this.httpClient.post(`${environment.ApiEmpleadosExcelMock}`, empleados, httpOptions).pipe(
      map( (response: any) =>{

        return response.Message;
      }),
      catchError(error => {
        return throwError("No se han podido actualizar los empleados debido a un error interno.");
      })
    );
  }

  deleteEmpleados(): Observable<any> {
    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        "authorization": `Bearer ${this.loginService.getUserLocalStorage().Token.Token}`
      })
    };
    return this.httpClient.delete(`${environment.ApiEmpleadosExcelMock}`, httpOptions).pipe(
      map( (response: any) =>{
        return response.Message;
      }),
      catchError(error => {
        return "Ha habido un error durante la eliminación de empleados";
      })
    );
  }

  getEstadoSubida(id:string): Observable<any>{
    return of({ Estado : 'FINALIZADO', Mensaje : '' })
  }

  getSubidaPendiente():Observable<any>{
    return of({id:''});
  }

}
