import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, RowStyle } from 'ag-grid-community';
import { SqpGridColDefs } from '../models/sqp-grid-col-defs';

@Component({
  selector: 'app-sqp-grid-component',
  templateUrl: './sqp-grid-component.component.html',
  styleUrls: ['./sqp-grid-component.component.css']
})
export class SqpGridComponentComponent implements OnInit {

  api: any;
  apiCol: any;
  @Input() columnDefs: ColDef[];
  sqpColumnDefs: ColDef[] = [];
  @Input() data: any[];
  @Input() iconoEditar: boolean = false;
  rowStyle: RowStyle;
  @Input() seleccion: string;
  overlayNoRowsTemplate: string ='<span>No se han encontrado datos</span>';
  overlayLoadingTemplate: string ='<span class="ag-overlay-loading-center">Cargando...</span>';
  @Input() deseleccionar: {count:number, uncheck: boolean};
  @ViewChild('agGrid', { static: false }) agGrid: AgGridAngular;
  @Output() refresh:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() seleccionEliminarEmitter: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() abrirModalDataEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() gridOptions:any;
  @Input() tipoData: string;

  constructor() { }

  ngOnChanges(changes: SimpleChanges){
    if(changes && changes.deseleccionar?.currentValue?.count>0){
      if (this.agGrid && this.agGrid.api) {
        this.agGrid.api.deselectAll();
      }
    }
  }

  ngOnInit(): void {
    this.seleccion=='single'? this.rowStyle = { cursor: 'pointer' }:'';
    this.cargarColumnas();
  }

  cargaApi(arg: any){
    this.api = arg.api;
    this.apiCol = arg.columnApi;
    if(this.tipoData=='firmas'){
      this.api.applyColumnState({
        state: [{ colId: 'fechaFirma', sort: 'desc' }],
        defaultState: { sort: null },
      });
    }else this.api.sizeColumnsToFit();

  }

  onCellClicked(event: any){
    if(this.iconoEditar){
      const lastColumnIndex = this.sqpColumnDefs.length - 1;
      if (event.column.colDef.field === this.sqpColumnDefs[lastColumnIndex].field) {
        this.abrirModalDataEmitter.emit(event);
        // this.abreModal(event);
      }
    }else{
      this.abrirModalDataEmitter.emit(event);
      // this.abreModal(event);
    }
  }

  // abreModal(data: any){
  //   this.abrirModalDataEmitter.emit(data);
  // }

  onSelectionChanged(){
    let rowsSeleccionadasEliminar = this.api.getSelectedRows();
    this.seleccionEliminarEmitter.emit(rowsSeleccionadasEliminar)
  }

  cargarColumnas(){
    this.sqpColumnDefs = [];
    const sqpGridColDefs = new SqpGridColDefs().sqpColumnDefs;
    const columnDefsToReverse = [...this.columnDefs];
    if(!this.iconoEditar) this.columnDefs.forEach(x=> this.sqpColumnDefs.push(x))
    else {
      // this.columnDefs.reverse().forEach(x=> sqpGridColDefs.unshift(x));
      columnDefsToReverse.reverse().forEach(x=> sqpGridColDefs.unshift(x));
      this.sqpColumnDefs = [...sqpGridColDefs];
    }
  }

}
