import { TipoDocumento } from "../../configuracion/Envios/documentos/models/tipo-documento";
import { Documento } from "./documento";
import { ResumenEventos } from "./resumen-eventos";


export interface Firmas {
    idFirma: number;
    numeroDocFirmados: number;
    documentos: Documento[];
    codigoEmpleado: string;
    empleado: string;
    nombreEmpleado: string;
    apellido1Empleado: string;
    apellido2Empleado: string;
    nifEmpleado: string;
    emailEmpleado: string;
    codigoEmpresa: string;
    nombreEmpresa: string;
    codigoCentro: string;
    nombreCentro: string;
    estadoString: string;
    docNecesarios: number;
    numeroDocumentos: number;
    fechaUltimaActualizacion: Date;
    dia: number;
    mes: number;
    anyo: number;
    idTipoEnvio: string;
    tipoEnvio: string;
    mensaje: string;
    telefonoEmpleado: string;
    fechaCreacion?: Date;
    fechaEnvio?: Date;
    fechaFirma?: Date;
    esNueva: boolean;
    tiposDocumento: TipoDocumento[];
    eventos: ResumenEventos[];
    cargaCompleatada?:Boolean;
    usuario: string;
    expireTime: number;
    isEnvioTest: boolean;
    documentosSubidosString: string;
    documentosPendientesString: string;
    estadoRlt?: boolean;
    emailRlt?: string;
    fechaContrato: Date;
    cifEmpresa: string;
}

export function nullFirma():Firmas{
    return {
        idFirma: 0,
        numeroDocFirmados: 0,
        documentos: [],
        codigoEmpleado: '',
        empleado: '',
        nombreEmpleado: '',
        apellido1Empleado:'',
        apellido2Empleado: '',
        nifEmpleado: '',
        emailEmpleado:'',
        codigoEmpresa: '',
        nombreEmpresa: '',
        codigoCentro: '',
        nombreCentro: '',
        estadoString: '',
        docNecesarios:0,
        numeroDocumentos: 0,
        fechaUltimaActualizacion: new Date(),
        dia: 0,
        mes: 0,
        anyo: 0,
        idTipoEnvio: '',
        tipoEnvio: '',
        mensaje: '',
        telefonoEmpleado: '',
        fechaCreacion: new Date(),
        fechaEnvio: new Date(),
        fechaFirma: new Date(),
        esNueva: false,
        tiposDocumento: [],
        eventos: [],
        cargaCompleatada:false,
        usuario: '',
        expireTime: 0,
        isEnvioTest: false,
        documentosSubidosString: '',
        documentosPendientesString: '',
        estadoRlt: null,
        fechaContrato: new Date(),
        cifEmpresa:''
    }
}
