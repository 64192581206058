<form [formGroup]="formEmpresa">
  <div class="modal-container">
    <svg class="icon-close" mat-dialog-close>
      <use xlink:href="#icon-close"></use>
    </svg>
    <h1>Nueva empresa</h1>
    <div class="flex-column">
      <div class="modal-content">
        <h6 style="margin-top: 15px">Datos identificativos de la empresa</h6>
        <div class="input-text-custom">
          <label for="nombreEmpresa">Nombre de la empresa</label>
          <input type="text" id="nombreEmpresa" name="nombreEmpresa" formControlName="nombreEmpresa"
            [ngClass]="{'errorInput': isCampoValido('nombreEmpresa')}" />
          <span class="texto-validacion" *ngIf="isCampoValido('nombreEmpresa')">
            El nombre de la empresa es requerido.
          </span>
        </div>
        <div class="input-text-custom">
          <label for="codigoEmpresa">Código Empresa</label>
          <input type="text" id="codigoEmpresa" name="codigoEmpresa" formControlName="codigoEmpresa"
            [ngClass]="{'errorInput': isCampoValido('codigoEmpresa')}" />
          <span class="texto-validacion" *ngIf="codigoEmpresa.errors?.inputVacio && codigoEmpresa.touched">El código de la empresa es requerido.</span>
          <span class="texto-validacion" *ngIf="codigoEmpresa.errors?.codigoExiste && codigoEmpresa.touched">El código de la empresa ya existe.</span>
          <span class="texto-validacion" *ngIf="codigoEmpresa.errors?.codigoLargo && codigoEmpresa.touched">El código de la empresa debe contener como máximo 25 caracteres.</span>
        </div>
        <div class="input-text-custom">
          <label for="cif">Cif</label>
          <input type="text" id="cif" name="cif" formControlName="cif"
            [ngClass]="{'errorInput': isCampoValido('cif')}" />
          <span class="texto-validacion" *ngIf="cif.errors?.inputVacio && cif.touched">El cif de la empresa es requerido.</span>
          <span class="texto-validacion" *ngIf="cif.errors?.cifExiste && cif.touched">El cif de la empresa ya existe.</span>
        </div>
        <div class="input-text-custom">
          <label for="tipoDocumento">Tipo de documento</label>
          <input type="text" id="tipoDocumento" name="tipoDocumento" formControlName="tipoDocumento">
        </div>
        <h6 style="margin-top: 30px;">Datos del validador</h6>
        <div class="input-text-custom">
          <label for="nombreEmpleado">Nombre</label>
          <input type="text" id="nombreEmpleado" name="nombreEmpleado" formControlName="nombreEmpleado"
            [ngClass]="{'errorInput': isCampoValido('nombreEmpleado')}" />
          <!-- <span class="texto-validacion" *ngIf="isCampoValido('nombreEmpleado')">
            El nombre del empleado es requerido.
          </span> -->
        </div>
        <div class="input-text-custom">
          <label for="apellido1Empleado">1º apellido</label>
          <input  type="text"
                  id="apellido1Empleado"
                  name="apellido1Empleado"
                  formControlName="apellido1Empleado"
                  [ngClass]="{'errorInput': isCampoValido('apellido1Empleado')}"/>
          <!-- <span class="texto-validacion"
                  *ngIf="isCampoValido('apellido1Empleado')">
                  El primer apellido es requerido.
          </span> -->
        </div>
        <div class="input-text-custom">
          <label for="apellido2Empleado">2º apellido</label>
          <input  type="text"
                  id="apellido2Empleado"
                  name="apellido2Empleado"
                  formControlName="apellido2Empleado"/>
        </div>
        <div class="input-text-custom">
          <label for="emailEmpleado">E-mail</label>
          <input  type="email"
                  id="emailEmpleado"
                  name="emailEmpleado"
                  formControlName="emailEmpleado"
                  [ngClass]="{'errorInput': isCampoValido('emailEmpleado')}"/>
          <span class="texto-validacion"
                *ngIf="isCampoValido('emailEmpleado')">
                El email tiene que contener un formato correcto.
          </span>
        </div>
        <!-- <label for="logo">Logo</label>
        <img class="logo-border" src={{empresa.logo}} width="200px">
        <p><small>{{empresa.logo}}</small></p> -->
      </div>
    <div class="modal-buttons">
      <app-boton-accion mat-dialog-close [icono]="'icon-close'" [titulo]="'Cancelar'" [id]="'Cancelar'" [outline]="true"
        [cancelar]="true">
      </app-boton-accion>
      <app-boton-accion (botonClicked)="guardarNuevaEmpresa()" [icono]="'hs-guardar'" [titulo]="'Guardar'"
        [id]="'Guardar'">
      </app-boton-accion>
    </div>
    </div>
  </div>
</form>
