<!-- <div class="tab">
    <input type="checkbox" id="chck6">
    <label class="tab-label" for="chck6">CADUCIDAD</label>
    <div class="tab-content">
        <div class="caducidad">
            <div class="caducidad-block">
                <p>{{fechaCaducidad}}</p>
                <p>Caducidad configurada: {{this.firma?.expireTime}} día/s</p>
            </div>
        </div>
    </div>
</div> -->

<div class="flex">
  <span class="titulo">CADUCIDAD</span>
  <span (click)="mostrarCaducidad()">
    <svg class="icon-arrow" [ngClass]="firmanteCaducidad ? 'icon-arrow-down':''">
      <use xlink:href="#hs-arrow"></use>
    </svg>
  </span>
</div>
<div *ngIf="firmanteCaducidad" class="caducidad">
  <span>{{fechaCaducidad}}</span>
  <span>Caducidad configurada: {{this.firma?.expireTime}} día/s</span>
</div>
