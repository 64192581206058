import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { PosicionFirma, PosicionFormulario, TipoFirma } from "../models/firmante-nif";
import { TipoDocumento } from "../models/tipo-documento";

export function validacionNombre(documentos: TipoDocumento[], id: number): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    if (value == '') {
        return {nombreVacio: true};
    }else{
      let nombreExiste = documentos.findIndex(x => removeAccents(x.nombre.toUpperCase()) == removeAccents(control.value.toLocaleUpperCase()))
      if(nombreExiste > -1){
        let mismoId = documentos.findIndex(x=> x.idTipoDocumento == id)
        if(mismoId > -1){
          return null
        }else{
          return {nombreExiste: true}
        }
      }else{
        return null
      }
    }
  }
}

function removeAccents(str:string) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function posicionCifValidators(predicate:any){
  return ((formControl: FormControl)=>{
    if(!formControl.value && formControl.untouched) return null;
    if(predicate() && formControl.value==null){
      return {validadoresCifPosicion: true}
    }
    else return null
  })
}

export function cifValidators(predicates: (() => boolean|null|string)[], totalPages:number, tipo:string){
  return ((formControl: FormControl) => {
    if (predicates[0]() && predicates[1]()=='pagina'){
      // if(!formControl.value) return null;
      if(tipo == 'cifPage' && (formControl.value < 1 || formControl.value > totalPages)) return {validadoresCifTotalPages: true};
      if(tipo == 'cifOrden' && formControl.value < 1) return {validadoresCifOrdenMin: true}
      else return null;
    }
    if(predicates[0]() && predicates[1]()=='formulario'){
      // if(!formControl.value) return null;
      if(tipo == 'cifFormulario' && formControl.value==null) return {validadoresCifFormulario: true}
      else return null;
    }
    else{
      return null;
    }
  })
}



export function formDocValidators(predicate: any[]): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    //predicate[0]: {firmantes: this.firmantesNif, index: this.indexPaso}
    //predicate[1]: formulario
      const tipoFirma: TipoFirma = form.get('tipoFirma').value;
      const tieneTag: boolean = form.get('tieneTag').value;
      const nombreTag: string = form.get('nombreTag').value;
      const posicionFirmas: PosicionFirma[] = form.get('posicionFirmas').value;
      // const nombreCertificado: string = form.get('nombreCertificado').value;
      const esRLT: boolean = predicate[1]().get('esRLT').value;
      const regexp = /^\S*$/;
      if(tipoFirma == TipoFirma.SinFirma && esRLT) return {validadoresRLTSinFirma: true};
      if(predicate[0]()){
        if(predicate[0]().firmantes.value.length > 0 && tipoFirma == null && predicate[0]().index == 9) return {validadoresTipoFirmaRequired: true};
        if(tipoFirma == TipoFirma.Dibujada && tieneTag == null) return {validadoresTagRequired: true};
        if(tieneTag == true && (nombreTag == ''|| nombreTag == null)) return {validadoresNombreTagRequired: true};
        if(tipoFirma == TipoFirma.Dibujada && tieneTag == false && (posicionFirmas?.length == 0 || posicionFirmas == null)) return {validadoresPosicionFirmasRequired: true};
        if(nombreTag !== null && !regexp.test(nombreTag)) return {validadoresNombreTagTieneEspacios: true};
        // if(tipoFirma == TipoFirma.Certificado && (nombreCertificado == ''|| nombreCertificado == null)) return {validadoresNombreCertificadoRequired: true};
        else return null
      }else{
        return null
      }

  }
}

export function validacionNombreFormulario(casillasVerificacionMostradas: PosicionFormulario[]): ValidatorFn {
  return (form:AbstractControl) : ValidationErrors | null => {
    let casillasArray = [];
    casillasArray = casillasVerificacionMostradas;

    const nombre: string = form.get('nombre').value;
    const orden: number = form.get('orden').value;
    const id: number = form.get('id').value;

    if (nombre == '' || nombre == null) {
        // return {nombreFormularioVacio: true};
        return null
    }else{
        let nombreExiste = casillasArray.findIndex(x =>
          removeAccents(x.nombre.toUpperCase()) == removeAccents(nombre.toLocaleUpperCase())
        )
        if(nombreExiste > -1){
          let mismoId = casillasArray.findIndex(x=> x.id == id);
          if(mismoId > -1) casillasArray.splice(mismoId,1);
          let nombreExiste2 = casillasArray.findIndex(x =>
            removeAccents(x.nombre.toUpperCase()) == removeAccents(nombre.toLocaleUpperCase())
          )
          if(nombreExiste2 > -1){
            return {nombreFormularioExiste: true}
          }
        }
    }
    // if(orden == null){
    //   return {faltanCasillas: true}
    // }else
    return null
  }
}

export function validacionGenerico(predicate:any){
  return ((formControl: FormControl) => {
    if (predicate() == 4 && formControl.value == null) return {validadorGenerico: true};
    else return null;
  })
}

export function validacionNombreArchivoFirmado(predicate:any, tipo: string): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    if(value==null) return null;
    const audit = predicate().controls['formatoNombreArchivoAuditTrail'];
    const doc = predicate().controls['formatoNombreArchivoFirmado'];
    const regexPattern = /^[^{}]*(\{(CODIGO_EMPRESA|CODIGO_CENTRO|CODIGO_EMPLEADO|NOMBRE_EMPLEADO|APELLIDO1_EMPLEADO|APELLIDO2_EMPLEADO|NIF_EMPLEADO|EMAIL_EMPLEADO|TELEFONO_EMPLEADO|NOMBRE_DOCUMENTO|FECHA_CONTRATO)\}(?:[^{}]*\{(CODIGO_EMPRESA|CODIGO_CENTRO|CODIGO_EMPLEADO|NOMBRE_EMPLEADO|APELLIDO1_EMPLEADO|APELLIDO2_EMPLEADO|NIF_EMPLEADO|EMAIL_EMPLEADO|TELEFONO_EMPLEADO|NOMBRE_DOCUMENTO|FECHA_CONTRATO)\}[^{}]*)*)*[^{}]*\.pdf$/;
    switch(tipo){
      case 'doc':
        if(!regexPattern.test(value))return {validatorsArchivoFirmado: true};
        if(audit.value && value==audit.value) return {validatorsArchivoFirmadoDuplicado: true};
        if(audit.value && value!==audit.value) {
          doc.setErrors(null);
          audit.setErrors(null);
          return null;
        }
      break
      case 'audit':
        if(!regexPattern.test(value))return {validatorsArchivoAudit: true};
        if(doc.value && value==doc.value) return {validatorsArchivoAuditDuplicado: true};
        if(doc.value && value!==doc.value) {
          doc.setErrors(null);
          audit.setErrors(null);
          return null;
        }
      break
      default:
        return null
    }
    return null
  }
}
export function validacionSplit(totalPages:number): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    const regexPattern = /^[0-9,]+$/;
    if(value==null || value=='') return null
    else if(!regexPattern.test(value)) return {validatorsSplit: true};
    else{
      let arrayNum = value.split(',');
      let numNoAceptado = arrayNum.find(x=> x<2 || x>totalPages)
      if(numNoAceptado) return {validatorsSplit: true}
      else return null
    }
  }
}

export function validacionCopiaBasica(predicate:any):ValidatorFn{
  return (control:AbstractControl):ValidationErrors|null=>{
    const generico = predicate().controls['generico'].value;
    if(generico && control.value) return {validatorsCopiaBasica: true}
    else return null
  }
}
