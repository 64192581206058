import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { Filtros, getFiltroInicial } from 'src/app/shared/models/filtros';
import { GridColDefsData } from 'src/app/pages/panelControl/models/grid-col-defs-data';
import { ResumenFirmas } from 'src/app/pages/panelControl/models/resumen-firmas';
import { ModificarArraysService } from 'src/app/shared/services/modificar-arrays.service';
import { EstadosFirmaService } from 'src/app/pages/panelControl/services/estados-firma.service';

@Component({
  selector: 'app-boton-resumen-firmas',
  templateUrl: './boton-resumen-firmas.component.html',
  styleUrls: ['./boton-resumen-firmas.component.css']
})
export class BotonResumenFirmasComponent implements OnInit {

  @Input() resumenFirmas: ResumenFirmas[] = [];
  @Input() color: string = '';
  @Input() texto: string = '';

  constructor(private datosFirmaService: DatosFirmaService,
              private router: Router,
              private domSanitizer: DomSanitizer,
              public estadosFirmaService: EstadosFirmaService) {
  }

  ngOnInit() {
  }

  // navegarFirmaEstado(firma: ResumenFirmas){
  //   let filtro: Filtros = getFiltroInicial();
  //   filtro.estadoString = firma.estado;
  //   let filtros: Filtros[] = [filtro];
  //   this.datosFirmaService.SetFilter(filtros);
  //   this.datosFirmaService.Filter();
  //   this.router.navigate(['/panel/envios']);
  // }

  public NumeroNuevas(item:ResumenFirmas):number{
    let novedades = item.firmas.filter(x=>x.esNueva).length;
    return novedades;
  }

  getIcono(_resumenFirmas: ResumenFirmas){
    return this.domSanitizer.bypassSecurityTrustHtml(new GridColDefsData().GetColor(_resumenFirmas.estado, true));
  }

  goToFirmas(){
    this.router.navigate(['panel/envios'])
  }

}
