<div class="mainPanel">
  <div class="input-box">
    <input
      type="text"
      (input)="onQuickFilterChanged()"
      id="quickFilter"
      placeholder="Buscar"
    />
    <svg class="hs-lupa"><use xlink:href="#hs-lupa"></use></svg>
    <svg class="hs-close" [ngStyle]="{'display':textoBuscar?'block':'none'}" (click)="borrarTexto()"><use xlink:href="#icon-close"></use></svg>
  </div>
    <ag-grid-angular style="height: 100%;"
                     class="ag-theme-alpine"
                     [rowHeight]="80"
                     [rowData]="documentos"
                     [columnDefs]="columnDefs"
                     (gridReady)="cargaApi($event)"
                     [rowSelection]="seleccion === 'multiple' ? 'multiple' : 'single'"
                     [rowMultiSelectWithClick]=true
                     (selectionChanged)="onSelectionChanged($event)">
    </ag-grid-angular>
</div>
