import { Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TipoFirmante } from '../../../../envios/models/tipo-firmante';
import { DatosConfiguracionDocumentosService } from '../../../services/datos-configuracion-documentos.service';
import { FormularioDocumentoService } from '../../../services/formulario-documento.service';

@Component({
  selector: 'app-campos-temporal',
  templateUrl: './campos-temporal.component.html',
  styleUrls: ['./campos-temporal.component.css']
})
export class CamposTemporalComponent implements OnInit {

  @Input() posicionCampo: {
    mostrar: boolean,
    left: number,
    top: number,
    firmante: TipoFirmante
  } ={
    mostrar:false,
    left:0,
    top:0,
    firmante: null as TipoFirmante
  }
  @ViewChild('draggableIcon') draggableIcon: ElementRef;
  @ViewChild('draggableDiv') draggableDiv: ElementRef;
  @ViewChild('draggableHref') draggableHref: ElementRef;
  campoPosition: { left: number, top: number, height: number, width: number };
  campoEnviar: any;
  isDragging: boolean = false;
  clientX: number = 0;
  clientY: number = 0;
  initialX: number = 0;
  initialY: number = 0;
  xOffset: number = 0;
  yOffset: number = 0;
  currentX: number = 0;
  currentY: number = 0;
  finalX: number = 0;
  finalY: number = 0;

  constructor(private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService,
              private formularioDocumentoService: FormularioDocumentoService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges){
    this.posicionCampo = changes.posicionCampo.currentValue
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event:any){
    if(event.target === this.draggableDiv.nativeElement || event.target === this.draggableIcon.nativeElement){
      if(this.posicionCampo.mostrar){
        this.draggableDiv.nativeElement.style.display = 'flex';
        this.isDragging = true;
        this.clientX = this.posicionCampo.left;
        this.clientY = this.posicionCampo.top;
        this.initialX = this.posicionCampo.left - this.xOffset;
        this.initialY = this.posicionCampo.top - this.yOffset;
      }
    }
  }

  @HostListener('document:mousemove', ['$event']) onMouseMove(event: any) {
    if (this.isDragging) {
      this.currentX = event.clientX - this.initialX - 20;
      this.currentY = event.clientY - this.initialY - 20;
      this.xOffset = this.currentX;
      this.yOffset = this.currentY;
      this.finalX = this.currentX + this.posicionCampo.left;
      this.finalY = this.currentY + this.posicionCampo.top - 50;
      this.campoPosition = {
        left: this.finalX,
        top: this.finalY,
        height: this.draggableDiv.nativeElement.clientHeight,
        width: this.draggableDiv.nativeElement.clientWidth
      }
      this.setTransform();
    }
  }

  //enviamos la posición final a casillas-dibujo a través del observable del servicio DatosConfiguracionDocuemntosService
  @HostListener('document:mouseup', ['$event']) onMouseUp(event: any) {
    this.isDragging = false;
    if (this.draggableIcon && (event.target === this.draggableIcon.nativeElement || event.target === this.draggableDiv.nativeElement || event.target === this.draggableHref.nativeElement)) {
      this.finalX = this.currentX + this.posicionCampo.left;
      this.finalY = this.currentY + this.posicionCampo.top;
      this.campoPosition = {
        left: this.finalX,
        top: this.finalY,
        height: this.draggableDiv.nativeElement.clientHeight,
        width: this.draggableDiv.nativeElement.clientWidth
      }
      this.datosConfiguracionDocumentosService.campoPosition = this.campoPosition;
      if(this.datosConfiguracionDocumentosService.isDentro('campos-temporal', this.campoPosition)){
        this.campoEnviar = {
          x: this.campoPosition.left - 81,
          y: this.campoPosition.top - 100,
          width: this.campoPosition.width,
          height: this.campoPosition.height,
          firmante: this.posicionCampo.firmante
        }
        this.formularioDocumentoService.cancelarCopiaDoc = false;
        this.datosConfiguracionDocumentosService.transferirDatosCampo(this.campoEnviar)
      }
      this.posicionCampo.mostrar = false;
    }
  }

  setTransform() {
    this.draggableDiv.nativeElement.style.transform = `translate3d(${this.currentX}px, ${this.currentY}px, 0)`;
  }

}
