<!-- <div class="layout-general "> -->
<div class="main-container">

    <app-barra-superior class="layout-menu"></app-barra-superior>

    <!-- <app-bread-crumbs class="breadcrumb"></app-bread-crumbs> -->

    <app-menu-lateral class="aside"(mostrarMenu)="plegarMenu($event)"></app-menu-lateral>

    <div class="main" [ngClass]="menuVisible ? '':'mainMenuPlegado'">
        <router-outlet></router-outlet>
    </div>
</div>

<!-- </div> -->
