import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { DownloadFilesService } from '../../../../shared/accesoApi/download-files.service';

@Component({
  selector: 'app-descarga-masiva-modal',
  templateUrl: './descarga-masiva-modal.component.html',
  styleUrls: ['./descarga-masiva-modal.component.css']
})
export class DescargaMasivaModalComponent implements OnInit {
  ids:number[] = []

  completado:string = '0';
  lengEnviado:number=0;
  lengTotal:number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
              private dowonladService:DownloadFilesService,
              public dialogRef: MatDialogRef<any>) {
    this.ids = data.ids;
  }


  ngOnInit() {
    this.lengTotal = this.ids.length;
    this.dowonladService.canelaDescarga = false;
    this.procesaDescarga();
  ;

  }

  private async procesaDescarga(){
    let cont = 1;
    const fecha = new Date();
    while(this.ids.length>0 && !this.dowonladService.canelaDescarga){
      const idsAEnviar=this.recuperaNPrimeros();


      let nombre = `Hire&Sign_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}_${cont}.zip`
      await this.dowonladService.GetAllDocs(idsAEnviar,nombre);
      this.lengEnviado = this.lengEnviado+idsAEnviar.length;
      let por = (this.lengEnviado/this.lengTotal)*100;
      this.completado = por.toFixed(2);
      cont++;
      //await new Promise(resolve=>setTimeout(resolve,1000));

    }
    this.dialogRef.close()
  }

  private recuperaNPrimeros():number[]{
    const paquete = environment.PaquetesDescargaMasiva;
    let cont = 0;
    let final = false;
    const ret:number[] = []
    while(!final){
      let element = this.ids.shift();
      if(element) ret.push(element);
      if(this.ids.length==0) final=true;
      cont++;
      if(cont==paquete)final=true;
    }
    return ret;

  }

  public close(){
    this.dowonladService.canelaDescarga=true;
    this.dialogRef.close()
  }

}
