<div class="barra-flex-space-between">
  <h1>Gestión empleados</h1>
  <div class="acciones">
    <app-empleados-barra-superior (botonClicked)="clickBarraSuperior($event)"></app-empleados-barra-superior>
    <app-boton-accion [id]="'importar'" [icono]="'hs-upload'" [titulo]="'importar'" [outline]="true"  [cancelar]="true" (click)="goToImportEmpleados()"></app-boton-accion>
    <app-boton-accion [id]="'nuevo empleado'" [icono]="'icon-plus'" [titulo]="'nuevo empleado'" [outline]="false" (click)="creaNuevoEmpleado()"></app-boton-accion>
  </div>
</div>
<div class="main">
  <div *ngIf="empleadosSeleccionados.length==0" class="flex-center-items">
    <app-input-buscador (buscador)="buscarEmpleados($event)" [textoBuscar]="textoBuscar"></app-input-buscador>
    <app-empleados-filtros (filtrarEmpleados)="filtrarEmpleados($event)"
                           [textoBuscar]="textoBuscar"
                           [empresasList]="empresasLista"
                           [centrosList]="centrosLista"
                           (limpiarBuscador)="buscarEmpleados($event)"
                           (limpiarTodoEmitter)="limpiarTodosEstados($event)"></app-empleados-filtros>
  </div>
  <app-pastilla-seleccion-multiple *ngIf="empleadosSeleccionados.length>0"
                                   (borrarEmitter)="deleteEmpleado()"
                                   (deseleccionarEmitter)="deseleccionarAll()"
                                   [textoMostrar]="textoBorrar"
                                   [tipoDato]="'empleados'"></app-pastilla-seleccion-multiple>
  <!-- <app-empleados-data-grid [empleados]="empleados"
                           (refresh)="getEmpleados()"
                           (seleccionEmpleadosEmitter)="seleccionEmpleados($event)"
                           [deseleccionar]="deseleccionar"></app-empleados-data-grid> -->
  <app-sqp-grid-component [data]="empleados"
                          (refresh)="getEmpleados()"
                          [columnDefs]="columnDefs"
                          [seleccion]="'multiple'"
                          (seleccionEliminarEmitter)="seleccionEmpleados($event)"
                          [deseleccionar]="deseleccionar"
                          (abrirModalDataEmitter)="abreModal($event)"
                          [iconoEditar]="true"
  >
  </app-sqp-grid-component>
</div>

