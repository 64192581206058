<h3>Estado de los envíos</h3>
<div class="square">
    <div *ngIf="resumenFirmas.length == 0" class="spinner">
        <mat-spinner diameter="35" strokeWidth="2"></mat-spinner>
    </div>
    <ul *ngIf="resumenFirmas.length > 0">
        <li *ngFor="let item of resumenFirmas" (click)="estadosFirmaService.clickEstadoEnvio(item.estado); goToFirmas()">
            <div [innerHTML]="getIcono(item)"></div>
            <div>{{item.etiqueta}}<app-indicador-numero *ngIf="NumeroNuevas(item)!=0" [numero]="NumeroNuevas(item).toString()" [color]="'var(--hs-yellow)'"></app-indicador-numero></div>
            <div>{{item.firmas.length}}</div>
        </li>
    </ul>
</div>
