import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FirmanteNif, TipoFirma } from '../../../models/firmante-nif';
import { DatosConfiguracionDocumentosService } from '../../../services/datos-configuracion-documentos.service';
import { FormularioDocumentoService } from '../../../services/formulario-documento.service';

@Component({
  selector: 'app-configuracion-firmas-tipo-firmantes',
  templateUrl: './configuracion-firmas-tipo-firmantes.component.html',
  styleUrls: ['./configuracion-firmas-tipo-firmantes.component.css']
})
export class ConfiguracionFirmasTipoFirmantesComponent implements OnInit {

  @Input() firmante: any;
  @Input() isEstado: boolean = false;
  indexFirma: number = 1;
  @Output() firmanteSeleccionado: EventEmitter<FirmanteNif> = new EventEmitter<FirmanteNif>()
  tipoFirmaAnterior: TipoFirma;
  tieneTagAnterior: boolean;
  tipoFirmaAnteriorArray: TipoFirma;
  isAtras: boolean = false;

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              private _snackBar: MatSnackBar,
              private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService) { }

  ngOnInit(): void {
    this.tipoFirmaAnterior = this.tipoFirma.value;
    this.tieneTagAnterior = this.tieneTag.value;
    this.datosConfiguracionDocumentosService.transferirTipoFirma(this.getTipoFirmaConFirmanteAnterior())
  }

  // si hay errores, hacemos que el firmante se abra por el paso donde hay el error. Añadimos isAtras para que nos deje tirar para atrás y no contemple el error.
  ngDoCheck(){
    if(this.firmante.tipoErrorFirma=='validadoresTipoFirmaRequired' && this.tipoFirma.value == null) this.indexFirma=1;
    if(this.firmante.tipoErrorFirma=='validadoresTagRequired' && this.tieneTag.value == null && this.isAtras==false) this.indexFirma=2;
    // if(this.firmante.tipoErrorFirma=='validadoresTagRequired' && this.tieneTag.value == null && this.isAtras==true) this.indexFirma=2;
    if(this.firmante.tipoErrorFirma=='validadoresNombreTagRequired' && (this.nombreTag.value == null || this.nombreTag.value == '') && this.isAtras==false) this.indexFirma=4;
    if(this.firmante.tipoErrorFirma=='validadoresPosicionFirmasRequired' && this.posicionFirmas.length==0 && this.isAtras==false) this.indexFirma=3;
    // if(this.firmante.tipoErrorFirma=='validadoresNombreCertificadoRequired' && (this.nombreCertificado.value == null || this.nombreCertificado.value == '') && this.isAtras==false) this.indexFirma=5;
  }

  //obtenemos los valores de tipoFirma para cada firmante al momento de cargar y se lo pasamos al componente configuracion-documento-page para que chequee si el usuario ha cambiado el tipoFirma.
  getTipoFirmaConFirmanteAnterior(){
    let tipoFirmaConFirmanteArray: any[] = [];
    this.formularioDocumentoService.firmantesNif.value.forEach((x: FirmanteNif) => {
      let objetoFirmaConFirmante: any;
      objetoFirmaConFirmante ={
        tipoFirmante: x.tipoFirmante,
        tipoFirma: x.firmas.tipoFirma,
        tieneTag: x.firmas.tieneTag
      }
      tipoFirmaConFirmanteArray.push(objetoFirmaConFirmante)
    })
    return tipoFirmaConFirmanteArray
  }


  get tipoFirma(){ return (this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup).get('tipoFirma') }

  get tieneTag(){ return (this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup).get('tieneTag') }

  get nombreTag(){ return (this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup).get('nombreTag') }

  get nombreCertificado(){ return (this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup).get('nombreCertificado') }

  get posicionFirmas(){ return (this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup).get('posicionFirmas') as FormArray}

  get formFirma(){ return this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup }

  //mandamos el estado al padre (configuracion-documento-configurar-firma) para que gestione abrir/cerrar el firmante seleccionado.
  mostrarEstado(firmanteNuevo: FirmanteNif){
    this.firmanteSeleccionado.emit(firmanteNuevo)
    }

  siguiente(){
    this.isAtras=false;
    if(this.indexFirma==1){
      //si tipoFirma ha cambiado su valor, reseteamos todos los otros controles para poder añadir los nuevos valores y no se queden dos valores excluyentes simultáneamente. Para vaciar el array de posicionFirmas en el formulario lo hacemos eliminando objeto a objeto con un loop. (reset, clear o new FormArray no funciona)
      if(this.tipoFirmaAnterior !== this.tipoFirma.value){
        Object.keys(this.formFirma.controls).forEach(key => {
          if(key !== 'tipoFirma') this.formFirma.controls[key].reset();
          while (this.posicionFirmas.length > 0) this.posicionFirmas.removeAt(0);
        });
      }
      if(this.tipoFirma.value == TipoFirma.Dibujada) this.indexFirma=2;
      else if(this.tipoFirma.value == TipoFirma.Certificado) {
        //enviamos a documento-upload el firmante del cual se tienen que borrar en pantalla las posicionesFirma.
        this.datosConfiguracionDocumentosService.transferirArrayFirmasVacio(this.firmante.tipoFirmante);
        this.indexFirma=5;
      } else this._snackBar.open(`Seleccione una opción para continuar`,'Cerrar', {duration: 3000});
    }else if(this.indexFirma==2){
      //si tieneTag ha cambiado su valor, reseteamos las siguientes opciones para añadir las nuevas.
      if(this.tieneTagAnterior !== this.tieneTag.value){
        this.nombreTag.reset();
        while (this.posicionFirmas.length > 0) this.posicionFirmas.removeAt(0);
        this.datosConfiguracionDocumentosService.transferirArrayFirmasVacio(this.firmante.tipoFirmante);
      }
      if(this.tieneTag.value == false) this.indexFirma=3;
      else if(this.tieneTag.value == true) this.indexFirma=4;
      else this._snackBar.open(`Seleccione una opción para continuar`,'Cerrar', {duration: 3000});
    }else if(this.indexFirma==5){
      this.indexFirma=2;
    }
    this.formularioDocumentoService.addFirmaEnFirmanteNif(this.firmante.tipoFirmante)
  }

  atras(){
    this.isAtras = true;
    delete this.firmante.tipoErrorFirma; //si hay un error lo borramos para que no se solape con la nueva info que añadimos.
    if(this.indexFirma==5) { //this.indexFirma==2 ||
      this.formularioDocumentoService.deleteCampoFirma(this.firmante.tipoFirmante, 'tipoFirma');
      this.indexFirma=1;
      this.isAtras = false;
    }
    if(this.indexFirma==2){
      if(this.tipoFirma.value == TipoFirma.Certificado){
        this.indexFirma=5
        this.isAtras = false
      }else{
        this.formularioDocumentoService.deleteCampoFirma(this.firmante.tipoFirmante, 'tipoFirma');
        this.indexFirma=1
        this.isAtras = false
      }
    }
    if(this.indexFirma==3 || this.indexFirma==4){
      this.formularioDocumentoService.deleteCampoFirma(this.firmante.tipoFirmante, 'tieneTag');
      if(this.tipoFirma.value == TipoFirma.Certificado){
        this.indexFirma=2;
      }else{ this.indexFirma=2 }
    }
  }

  obtenerIndexNifArray():number{
    return this.formularioDocumentoService.firmantesNif.value.findIndex((x:FirmanteNif) => x.tipoFirmante.codigo == this.firmante.tipoFirmante.codigo)
  }
}
