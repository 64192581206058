import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationMessageComponent } from 'src/app/shared/components/modals/confirmation-message/confirmation-message.component';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';
import { Seguridad, UsuariActivo, UsuarioModel, UsuarioPermisos, UsuarioTipo } from '../../models/usuario-model';
import { DatosUsuariosService } from '../../services/datos-usuarios.service';
import { UsuariosModalSeguridadComponent } from '../usuarios-modal-seguridad/usuarios-modal-seguridad.component';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { requiredIfUsuario } from '../../models/custom-validators-usuarios';
import { SpinnerModalComponent } from 'src/app/shared/components/others/spinner-modal/spinner-modal.component';

@Component({
  selector: 'app-usuarios-modal',
  templateUrl: './usuarios-modal.component.html',
  styleUrls: ['./usuarios-modal.component.css']
})
export class UsuariosModalComponent implements OnInit {

  formUsuario: FormGroup = new FormGroup({});
  isEditar: boolean = false;
  nuevo: boolean = false;
  isActivar: boolean = false;
  isDesactivar: boolean = false;
  isCerrar: boolean = false;
  estado: string = '';

  titulosArray: string[]=[
    "Detalle usuario",
    "Edición usuario",
    "Invitación de usuario"
  ]
  tituloModal: string = "";
  usuarioTipos: UsuarioTipo[]=[];
  usuarioPermisos: UsuarioPermisos[];
  objetoUsuario: UsuarioModel;
  usuarioSeguridad: any[];
  seleccionarSeguridad: string = '';
  mostrarLink: boolean = false;
  seguridadSeleccionada: string = '';
  iconPermisos: string = '';
  enviosSeleccionados: Seguridad[] = [];
  empresasSeleccionadas: Seguridad[] = [];
  centrosSeleccionados: Seguridad[] = [];
  errorMessage:string='';

  permUsuario: string[] = ["Envío de dosieres", "Envío de test (SANDBOX)", "Configuración de datos identificativos", "Cambio de contraseña"]

  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public info: any,
              private _snackBar: MatSnackBar,
              private fb: FormBuilder,
              private datosUsuariosService: DatosUsuariosService,
              private router: Router,
              private loginService: LoginService) { }

  ngOnInit(): void {
    this.nuevo = this.info.nuevo;
    this.cambiarTituloModal();
    this.getUsuarioTipo();
    this.getUsuarioSeguridad();
    this.iniciarFormularioUsuario();
    if(this.info.datosUsuario.usuarioActivo) {
      this.isDesactivar = true;
      this.estado = 'Activo';
    }else if(!this.info.datosUsuario.usuarioActivo && this.info.datosUsuario.invitacionEnviada && !this.info.datosUsuario.invitacionAceptada){
      this.isActivar = false;
    }else if(!this.info.datosUsuario.usuarioActivo && !this.info.datosUsuario.invitacionEnviada && !this.info.datosUsuario.invitacionAceptada){
      this.isActivar=true
    }else{
      this.isActivar = true;
      this.estado ='Desactivado';
    }

  }

  cambiarTituloModal(){
    if(!this.isEditar && !this.nuevo) this.tituloModal = this.titulosArray[0];
    else if(this.nuevo) this.tituloModal = this.titulosArray[2];
    else this.tituloModal = this.titulosArray[1];
  }

  iniciarFormularioUsuario(){
    this.formUsuario = this.fb.group({
      // nombreUsuario:[`${this.info.datosUsuario.nombre} ${this.info.datosUsuario.apellido1}`, Validators.required],
      nombreUsuario:[this.info.datosUsuario.nombre, Validators.required],
      apellidosUsuario: [this.info.datosUsuario.apellido1, Validators.required],
      emailUsuario:[this.info.datosUsuario.email, [Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      accesoUsuario:[this.info.datosUsuario.email],
      rolUsuario:[this.info.datosUsuario.rol, Validators.required],
      seguridadUsuario:[this.info.datosUsuario.tipoSeguridad, requiredIfUsuario(()=> this.rolUsuario)],
      usuarioActivo:[this.info.datosUsuario.usuarioActivo]
    })
  }

  get nombreUsuario(){ return this.formUsuario.get('nombreUsuario')}
  get apellidosUsuario(){ return this.formUsuario.get('apellidosUsuario')}
  get emailUsuario(){ return this.formUsuario.get('emailUsuario')}
  get accesoUsuario(){ return this.formUsuario.get('accesoUsuario')}
  get rolUsuario(){ return this.formUsuario.get('rolUsuario')}
  get seguridadUsuario(){ return this.formUsuario.get('seguridadUsuario')}
  get usuarioActivo(){ return this.formUsuario.get('usuarioActivo')}
  get getEnviosSeleccionados(): Seguridad[] { return (this.seguridadUsuario.value == 'TipoEnvio') ? this.enviosSeleccionados : []; }
  get getEmpresasSeleccionadas(): Seguridad[] { return (this.seguridadUsuario.value == 'Empresa') ? this.empresasSeleccionadas : []; }
  get getCentrosSeleccionados(): Seguridad[] { return (this.seguridadUsuario.value == 'Centro') ? this.centrosSeleccionados : []; }

  // pattern('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
  editarUsuario(){
    this.isActivar = false;
    this.isDesactivar = false;
    this.isEditar = true;

    this.cambiarTituloModal();

    if(this.info.datosUsuario.empresaPermitida.length>0) this.checkSeguridad(this.info.datosUsuario.empresaPermitida);
    if(this.info.datosUsuario.centroPermitido.length>0) this.checkSeguridad(this.info.datosUsuario.centroPermitido);
    if(this.info.datosUsuario.tipoEnvioPermitido.length>0) this.checkSeguridad(this.info.datosUsuario.tipoEnvioPermitido);
  }

  getUsuarioTipo(){
    this.datosUsuariosService.getUsuariosTipo().subscribe(respuesta=>{
      this.usuarioTipos = respuesta;

      // this.usuarioTipos.forEach(x=>{


      //   if(x.rol == this.info.datosUsuario.rol) this.usuarioPermisos = x.permisos
      // })
      // this.getUsuarioPermisos();

      // this.iconPermisos = '#icon-check-circle-green'
      // this.iconPermisos = '#icon-check-outline'
    })
  }

  // getUsuarioPermisos(){
  //   this.datosUsuariosService.getUsuariosPermisos().subscribe(
  //     resp=>{
  //       this.usuarioPermisos = resp;

  //       if(this.info.datosUsuario.rol == "Usuario"){
  //         this.permUsuario.forEach(x=>{
  //           this.usuarioPermisos.forEach((y:any,i:number)=>{
  //            if(y.permisos.includes(x)) {
  //             this.iconPermisos = '#icon-check-circle-green'
  //            }
  //            else this.iconPermisos = '#icon-check-outline'
  //           })
  //         })

  //       }
  //     }
  //   )
  // }

  getUsuarioSeguridad(){
    this.datosUsuariosService.getUsuariosSeguridad().subscribe(respuesta=>{
      this.usuarioSeguridad = respuesta;
      let _tipoSeguridad = this.info.datosUsuario.tipoSeguridad;
      this.cambiarSeguridad({target:{value: _tipoSeguridad}});
    })
  }


  guardarUsuario(datosUsuario: UsuarioModel, nuevo?:boolean){
    if(this.seguridadUsuario.value == 'Empresa' && this.getEmpresasSeleccionadas.length==0){
      this.errorMessage = 'Es requerido seleccionar como mínimo una empresa.'
      return
    }
    if(this.seguridadUsuario.value == 'Centro' && this.getCentrosSeleccionados.length==0){
      this.errorMessage = 'Es requerido seleccionar como mínimo un centro.'
      return
    }
    if(this.seguridadUsuario.value == 'TipoEnvio' && this.getEnviosSeleccionados.length==0){
      this.errorMessage = 'Es requerido seleccionar como mínimo un tipo de dosier.'
      return
    }
    if(this.formUsuario.invalid){
      this.formUsuario.markAllAsTouched();
      this._snackBar.open(`Hay errores`,'Cerrar', {duration: 3000});
      return
    }

    this.montarUsuarioObjeto(datosUsuario);

    if(nuevo) this.dialogRef.close('OK');

    const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
    this.datosUsuariosService.guardaUser(this.objetoUsuario).subscribe(
      then => {
        dialogRefSpinner.close();
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'Usuario guardado correctamente',
          },
        });
        let objetoUsuarioActivo: UsuariActivo = {
          Login: this.objetoUsuario.email,
          Nombre: this.objetoUsuario.nombre,
          Apellido1: this.objetoUsuario.apellido1
        };
        if(this.objetoUsuario.email == this.loginService.loginData.User.Login){
            this.datosUsuariosService.transferirUsuarioActivo(objetoUsuarioActivo);
        }
      },
      error => {
        dialogRefSpinner.close();
        console.error("error", error);
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'Hay errores. El usuario no se ha editado.',
            alert: true
          },
        });
        this.dialogRef.close('');
        this.isEditar = false;
        this.isCerrar = true;
        if(this.info.datosUsuario.usuarioActivo) this.isDesactivar = true;
        else this.isActivar = true;
      },
      () => {

        this.dialogRef.close('OK');
        this.isEditar = false;
        this.isCerrar = true;
        if(this.info.datosUsuario.usuarioActivo) this.isDesactivar = true;
        else this.isActivar = true;
      }
    );
  }

  desactivarUsuario(){
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: {
        tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
        titulo: 'Desactivar usuario',
        mensaje:'Desactivando al usuario, éste dejará de tener acceso a Hire & Sign. Sus envíos seguiran siendo visibles y podrá activarlo cuando desee. Desea continuar?',
        preguntaConfirmacion: '¿Desea continuar?',
        textoConfirmacion: 'Desactivar',
        textoCancelar: 'cancelar'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'OK'){
        this.datosUsuariosService.desactivaUsuario(this.emailUsuario.value).subscribe(
          ok => {

            const dialogRef = this.dialog.open(ModalMessageComponent, {
              data: {
                mensaje: 'Se ha desactivado el usuario',
              },
            });
            this.dialogRef.close('OK');
          },
          error => {
            console.error("error", error)
          }
        )
      }
    })
  }

  activarUsuario(){
    if(this.formUsuario.invalid){
      this.formUsuario.markAllAsTouched();
      this._snackBar.open(`Hay errores`,'Cerrar', {duration: 3000});
      return
    }

    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: {
        tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
        titulo: 'Activar usuario',
        mensaje:'Activando al usuario, éste volverá a tener acceso a Hire&Sign. Deseas continuar?',
        preguntaConfirmacion: '¿Desea continuar?',
        textoConfirmacion: 'Activar',
        textoCancelar: 'cancelar'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'OK'){
        this.datosUsuariosService.activaUsuario(this.emailUsuario.value).subscribe(
          ok => {

            const dialogRef = this.dialog.open(ModalMessageComponent, {
              data: {
                mensaje: 'Se ha activado el usuario',
              },
            });
            this.dialogRef.close('OK');
          },
          error => {
            console.error("error", error)
          }
        )
      }
    })
  }

  isCampoValido(campo:string){
    return this.formUsuario.controls[campo].errors && this.formUsuario.controls[campo].touched;
  }

  cambiarRol(event:any){
    this.rolUsuario?.setValue(event.target.value, {
      onlySelf: true
    });
    // this.usuarioTipos.forEach(x=>{
    //   if(x.rol == this.rolUsuario.value) this.usuarioPermisos = x.permisos
    // })

  }

  cambiarSeguridad(event:any){
    this.empresasSeleccionadas = [];
    this.centrosSeleccionados = [];
    this.enviosSeleccionados = [];
    this.seguridadUsuario?.setValue(event.target.value, {
      onlySelf: true
    })
    this.seguridadSeleccionada = event.target.value
    switch(event.target.value){
      case 'TipoEnvio':
        this.seleccionarSeguridad = 'Selecciona tipos de dosieres'
        this.mostrarLink = true;
        break;
      case 'Empresa':
        this.seleccionarSeguridad = 'Selecciona empresas';
        this.mostrarLink = true;
        break;
      case 'Centro':
        this.seleccionarSeguridad = 'Selecciona centros de trabajo';
        this.mostrarLink = true;
        break;
      default:
        this.mostrarLink = false;
    }
    this.errorMessage='';
  }

  abreModalSeguridad(tipoSeguridad: string){
    const dialogRef = this.dialog.open(UsuariosModalSeguridadComponent, {
      data: {tipoSeguridad: tipoSeguridad,
             filtroSeguridadFirmas: (tipoSeguridad == this.info.datosUsuario.tipoSeguridad) ? this.info.datosUsuario.filtroSeguridadFirmas : '',
             enviosSeleccionados: this.getEnviosSeleccionados,
             empresasSeleccionadas: this.getEmpresasSeleccionadas,
             centrosSeleccionados: this.getCentrosSeleccionados,
      },
      panelClass: 'modal-class-detalle',
      position: { right: '0'},
      autoFocus: 'dialog'
    });

    dialogRef.afterClosed().subscribe(result=>{
      if(result){
        this.errorMessage='';
        this.checkSeguridad(result);
      }
    })
  }

  checkSeguridad(arraySeguridad: Seguridad[]){
    switch(this.seguridadSeleccionada){
      case 'TipoEnvio':
        this.enviosSeleccionados = arraySeguridad;
        if(arraySeguridad.length == 1) this.seleccionarSeguridad = `${arraySeguridad.length} tipo de dosier seleccionado`;
        else if(arraySeguridad.length>1) this.seleccionarSeguridad = `${arraySeguridad.length} tipo de dosieres seleccionados`;
        else this.seleccionarSeguridad = 'Selecciona tipos de dosieres';
        break;
      case 'Empresa':
        this.empresasSeleccionadas = arraySeguridad;
        if(arraySeguridad.length == 1) this.seleccionarSeguridad = `${arraySeguridad.length} empresa seleccionada`;
        else if(arraySeguridad.length>1)this.seleccionarSeguridad = `${arraySeguridad.length} empresas seleccionadas`;
        else this.seleccionarSeguridad = 'Selecciona empresas';
        break;
      case 'Centro':
        // Obtenemos la cantidad de centros seleccionados del JSON devuelto por el modal de centros
        let _arraySeguridadLength = 0;
        if(arraySeguridad.length > 0) JSON.parse(arraySeguridad[0].id).map(x => _arraySeguridadLength= _arraySeguridadLength+x.codigosCentro.length);
        this.centrosSeleccionados = arraySeguridad;
        if(_arraySeguridadLength == 1) this.seleccionarSeguridad = `${_arraySeguridadLength} centro seleccionado`;
        else if(_arraySeguridadLength >1) this.seleccionarSeguridad = `${_arraySeguridadLength} centros seleccionados`;
        else this.seleccionarSeguridad = 'Selecciona centros de trabajo';
        break;
    }
  }

  reInvitarUsuario(){
    this.formUsuario.markAllAsTouched();
    if(this.formUsuario.invalid){
      this._snackBar.open('Complete todos los campos correctamente', 'cerrar', {duration:3000})
    }else{
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          titulo: 'Reenvío de invitación',
          mensaje:`Se va a reenviar una invitación para configurar y acceder a Hire&Sign a ${this.nombreUsuario.value} ${this.apellidosUsuario.value}. ¿Desea continuar?`,
          preguntaConfirmacion: '¿Desea continuar?',
          textoConfirmacion: 'Reenviar invitación',
          textoCancelar: 'cancelar'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
        if(result === 'OK'){
          let token = this.loginService.getUserLocalStorage().Token.Token;
          let tokenInfo = this.loginService.DecodeTokenLogin(token);
          let data: UsuarioModel = {
            apellido1: this.formUsuario.value.apellidosUsuario,
            email: this.formUsuario.value.emailUsuario,
            nombre: this.formUsuario.value.nombreUsuario,
            rol: this.formUsuario.value.rolUsuario,
            tipoSeguridad: this.formUsuario.value.seguridadUsuario,
            usuarioActivo: this.formUsuario.value.usuarioActivo,
            tipoEnvioPermitido: [],
            empresaPermitida: [],
            centroPermitido: [],
            loginOld: '',
            password: '',
            passwordOld: '',
            azureAdObjectId: '',
            tokenInvitacion: '',
            usuarioA3Trans: '',
            tenant: tokenInfo.tenant,
            multiTenant: false,
            filtroSeguridadFirmas: '',
            invitacionEnviada: true,
            invitacionAceptada: false
          };
          this.datosUsuariosService.reenviarInvitacion(data).subscribe(
            (ok:any) => {
              dialogRefSpinner.close();
              const dialogConfirmacion = this.dialog.open(ModalMessageComponent, {
                data: {
                  mensaje: `Se le ha reenviado el email al usuario ${this.nombreUsuario.value} ${this.apellidosUsuario.value} satisfactoriamente.`
                }
              });
              dialogConfirmacion.afterClosed().subscribe(result => this.dialogRef.close('OK'))
            },
            error => {
              dialogRefSpinner.close();
              console.error("error", error);
              if(error.status == 404) this._snackBar.open('Se ha producido un error recuperando el empleado', 'Cerrar', {duration: 3000});
              if(error.status == 400) this._snackBar.open(`${error.message}`, 'Cerrar', {duration: 3000});
              if(error.status == 500) this._snackBar.open(`Se ha producido un error en el servidor`, 'Cerrar', {duration: 3000});
            }
          )
        }else{
          dialogRefSpinner.close();
        }
      });
    }
  }

  eliminarUsuario(){
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: {
        tipo: TipoMensajeConfirmacion.ConfirmacionCompleja,
        icono: 'icon-delete',
        titulo: '¿Eliminar usuario?',
        // preguntaConfirmacion: '¿Eliminar centro?',
        textoConfirmacion: 'Eliminar el usuario seleccionado',
        textoBoton: 'Eliminar',
        iconoConfirmacion: 'icon-delete'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'OK'){
        const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);

        this.datosUsuariosService.deleteUsuario(this.info.datosUsuario.email).subscribe(
          ok => {
            dialogRefSpinner.close();
            const dialogRef = this.dialog.open(ModalMessageComponent, {
              data: {
                mensaje: 'Usuario eliminado correctamente',
              },
            });
            this.dialogRef.close('OK');
            // this.datosUsuariosService.refreshUsuarios = true;
          },
          error => {
            dialogRefSpinner.close();
            console.error("error", error);
            const dialogRef = this.dialog.open(ModalMessageComponent, {
              data: {
                mensaje: 'Error. El usuario no se ha eliminado',
                alert: true
              },
            });
          }
        );
      }
    })


  }

  invitarUsuario(){
    if(this.seguridadUsuario.value == 'Empresa' && this.getEmpresasSeleccionadas.length==0){
      this.errorMessage = 'Es requerido seleccionar como mínimo una empresa.'
      return
    }
    if(this.seguridadUsuario.value == 'Centro' && this.getCentrosSeleccionados.length==0){
      this.errorMessage = 'Es requerido seleccionar como mínimo un centro.'
      return
    }
    if(this.seguridadUsuario.value == 'TipoEnvio' && this.getEnviosSeleccionados.length==0){
      this.errorMessage = 'Es requerido seleccionar como mínimo un tipo de dosier.'
      return
    }
    this.formUsuario.markAllAsTouched();
    if(this.formUsuario.invalid){
      this._snackBar.open('Complete todos los campos correctamente', 'cerrar', {duration:3000})
    }else{
      let dataUsuario = this.formUsuario.value;
      let usuarioExiste = this.checkSiUsuarioExiste(dataUsuario);
      if(!usuarioExiste){
        const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
          data: {
            tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
            titulo: 'Envío invitación',
            mensaje:`Se va a enviar una invitación para configurar y acceder a Hire&Sign a ${this.nombreUsuario.value} ${this.apellidosUsuario.value}. ¿Desea continuar?`,
            preguntaConfirmacion: '¿Desea continuar?',
            textoConfirmacion: 'Enviar invitación',
            textoCancelar: 'cancelar'
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
          let data = this.formUsuario.value
          if(result === 'OK'){
            // this.dialogRefInvitacion.close('OK');
            this.datosUsuariosService.invitaUsuario(data).subscribe(
              (ok:any) => {
                dialogRefSpinner.close();
                let emailEnviado: string = `Invitación enviada correctamente a ${this.nombreUsuario.value} ${this.apellidosUsuario.value}.`
                let emailNoEnviado: string = `${this.nombreUsuario.value} ${this.apellidosUsuario.value} ya había sido invitado/a a otro tenant y se le ha activado acceso al tenant actual.`;
                if(!ok.Enviado){
                  const dialogConfirmacion = this.dialog.open(ModalMessageComponent, {
                    data: {
                      mensaje: ok.Enviado ? emailEnviado : emailNoEnviado,
                    }
                  });
                  dialogConfirmacion.afterClosed().subscribe(result =>{
                    // this.dialogRefInvitacion.close('OK')
                    this.dialogRef.close('OK');

                  })
                }
                this.nuevo = false;
                this.getUsuarios()
              },
              error => {
                dialogRefSpinner.close();
                const dialogConfirmacion = this.dialog.open(ModalMessageComponent, {
                  data: {
                    mensaje: 'Ha ocurrido un error. El usuario no se ha invitado.',
                  }
                });
                dialogConfirmacion.afterClosed().subscribe(result => {
                  // this.dialogRefInvitacion.close('OK');
                  this.dialogRef.close('OK');
                  this.nuevo = false;
                }
                )
                console.error("error", error);
              }
            )
          }else{
            dialogRefSpinner.close();
          }
        });
      }else{
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: 'El usuario ya existe, no lo puedes volver a invitar.',
            alert: true
          },
        });
      }
    }
  }

  checkSiUsuarioExiste(usuario: any):boolean{
    let index = this.datosUsuariosService.usuarios.findIndex(x=> x.email == usuario.emailUsuario);
    if(index==-1) return false
    else return true
  }

  montarUsuarioObjeto(usuario: UsuarioModel){
    this.objetoUsuario = {
      // nombre: nombreArray[0],
      // apellido1: nombreArray[1],
      nombre: this.nombreUsuario.value,
      apellido1: this.apellidosUsuario.value,
      email: this.emailUsuario.value,
      rol: this.rolUsuario.value,
      usuarioActivo: this.usuarioActivo.value,
      tipoSeguridad: this.seguridadUsuario.value,
      tipoEnvioPermitido: this.getEnviosSeleccionados,
      empresaPermitida: this.getEmpresasSeleccionadas,
      centroPermitido: this.getCentrosSeleccionados,
      loginOld: usuario?.loginOld,
      password: usuario?.password,
      passwordOld: usuario?.passwordOld,
      azureAdObjectId: usuario?.azureAdObjectId,
      tokenInvitacion: usuario?.tokenInvitacion,
      usuarioA3Trans: usuario?.usuarioA3Trans,
      tenant: this.loginService.getTenantToken().tenantActivo.Name,
      // tenant: this.loginService.usuarioActivo.tenant,
      multiTenant: this.loginService.getTenantToken().tenants.length>1 ? true : false,
      // multiTenant: this.loginService.usuarioActivo.multiTenant
      // estado: usuario?.estado
      // filtroSeguridadFirmas: usuario?.filtroSeguridadFirmas,
      filtroSeguridadFirmas: '',
      invitacionAceptada: usuario?.invitacionAceptada,
      invitacionEnviada: usuario?.invitacionEnviada
    }
  }

  getUsuarios(){
    this.datosUsuariosService.refreshUsuarios = true;
    this.datosUsuariosService.getUsuarios().subscribe(
      next => {
        let usuarios = next;

        let usuarioNuevo = usuarios.find(x=> x.email==this.emailUsuario.value)
        this.guardarUsuario(usuarioNuevo,true);

      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

}
