import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { DatosEmpleadosService } from './datos-empleados.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FetchConfiguracionAplicationService implements Resolve<any>{

  constructor(private datosEmpleadosService: DatosEmpleadosService) {}

  resolve(): Observable<any> {
    return new Observable(observer => {
      this.datosEmpleadosService.getConfiguracionAplicacion().subscribe(
        next => {
          if (next.TipoIntegracion == 'Meta4Cloud') {
            // this.datosEmpleadosService.consultaEmpleados = true;
            this.datosEmpleadosService.consultaEmpleados = false;
          }
          observer.next(next); // Emit the fetched data
          observer.complete(); // Complete the observable
        },
        error => {
          console.error(error);
          observer.error(error); // Emit the error
          observer.complete(); // Complete the observable
        }
      );
    });
  }
}
