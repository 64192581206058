<div class="menu-container">
  <div class="flex" [ngClass]="{'seleccionada': opcion=='editar'}" (click)="elegirOpcionMenu('editar')">
    <svg class="icon">
      <use xlink:href="#hs-edit"></use>
    </svg>
    <span class="opcion">Editar perfil</span>
  </div>
  <div class="flex" [ngClass]="{'seleccionada': opcion=='seguridad'}" (click)="elegirOpcionMenu('seguridad')">
    <svg class="icon">
      <use xlink:href="#icon-security"></use>
    </svg>
    <span class="opcion">Seguridad</span>
  </div>
</div>
