import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { iDocumentoPdfConfiguracionService } from './ports/i-documento-pdf-configuracion-service';

@Injectable({
  providedIn: 'root'
})
export class DocumentoPdfConfiguracionApiService implements iDocumentoPdfConfiguracionService{

  private readonly URL_GET_FICHERO_TEMPORAL: string = this.endpointService.UrlGetUrlFileTemporal;
  private readonly URL_SUBE_FICHERO_TEMPORAL: string = this.endpointService.UrlApiSubeFicheroTemporal
  private readonly URL_GET_CIF_DOCUMENTO: string = this.endpointService.UrlGetCifDocumento
  private readonly URL_GET_NIF_DOCUMENTO: string = this.endpointService.UrlGetNifDocumento

  constructor(private endpointService: EndpointService,
              private loginService: LoginService,
              private client: HttpClient) { }

  //api real se tiene que implementar aún
  putDocumentoTemporal(file: FileList): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    const formData = new FormData();
    let fileName = this.normalizaNombreFichero(file[0].name);
    formData.append('file', file[0], fileName);
    return this.client.post(this.URL_SUBE_FICHERO_TEMPORAL,formData, options).pipe(
      map((response: any) => {
        return response.Id;
      })
    );
  }


  getDocumentoTemporal(id: string): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.get(this.URL_GET_FICHERO_TEMPORAL.replace("{id}", id), options).pipe(
      map((response: any) =>{
        if (response) {
          return response.Url;
        }
      })
    );
  }

  traeCif(id: string, pagina: number, orden: number): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.get(this.URL_GET_CIF_DOCUMENTO.replace("{idDocumento}", id).replace("{page}", pagina.toString()).replace("{orden}", orden.toString()), options).pipe(
      map((response: any) =>{
        if (response) {
          return response.CifNif;
        }
      })
    );
  }

  traeNif(id: string, pagina: number, orden: number): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    if(id!==null && pagina==null && orden==null){ //cambiar cuando se pueda recuperar el Nif a través del formulario
      return of('');
    }else
    return this.client.get(this.URL_GET_NIF_DOCUMENTO.replace("{idDocumento}", id).replace("{page}", pagina.toString()).replace("{orden}", orden.toString()), options).pipe(
      map((response: any) =>{
        if (response) {
          return response.CifNif;
        }
      })
    );
  }

  normalizaNombreFichero(fileName: string): string {
    return fileName.replace(/[^a-zA-Z0-9 _\-\.]+/g, "");
  }
}

// return `https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf`
