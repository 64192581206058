import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DetallesEstado } from '../../models/detalles-estado';
import { Firmas } from '../../models/firmas';

@Component({
  selector: 'app-detalle-firma-rlt',
  templateUrl: './detalle-firma-rlt.component.html',
  styleUrls: ['./detalle-firma-rlt.component.css']
})
export class DetalleFirmaRltComponent implements OnInit {

  @Input() firma: Firmas;
  firmanteRlt: boolean = false;
  @Input() mostrarDetalles: {count: number, abrir: boolean};
  @Output() switchRltEmitter: EventEmitter<DetallesEstado> = new EventEmitter<DetallesEstado>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges){
    if(changes.mostrarDetalles){
      this.firmanteRlt = changes.mostrarDetalles.currentValue.abrir;
      this.switchRltEmitter.emit({tipo: 'rlt', estado: this.firmanteRlt})
    }

  }

  ngOnInit(): void {
  }

  mostrarRlt(){
    this.firmanteRlt = !this.firmanteRlt;
    this.switchRltEmitter.emit({tipo: 'rlt', estado: this.firmanteRlt})
  }

}
