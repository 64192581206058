<div class="container">
  <div class="top-info" [ngStyle]="{'background-color': isSandbox ? '#F7F4FF':''}">
    <app-info-dosier></app-info-dosier>
    <app-instrucciones-subida></app-instrucciones-subida>
  </div>
  <div class="content">
        <app-upload-file (fileListEmitter)="uploadFiles($event)" [accept]="accept" [textoFondo]="textoSubida"
          *ngIf="!hayFicheros" [multiple]="multiple"></app-upload-file>
        <!-- <app-progress *ngIf="hayFicheros" [porcentajeSubida]="porcentajeSubida" [mensjeCompletado]="'Ficheros subidos'" [mensjeProgreso]="'Subiendo...'"></app-progress> -->
  </div>
</div>
