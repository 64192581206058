import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FiltroDocs } from 'src/app/shared/models/filtros';
import { FormularioEnvioService } from '../../../envios/services/formulario-envio.service';
import { TipoDocumento } from '../../models/tipo-documento';
import { DatosDocumentosService } from '../../services/datos-documentos.service';

@Component({
  selector: 'app-documentos-modal',
  templateUrl: './documentos-modal.component.html',
  styleUrls: ['./documentos-modal.component.css']
})
export class DocumentosModalComponent implements OnInit {

  docsAdjuntar: TipoDocumento[] = [];
  buscar: boolean = false;
  textoBuscar: string = "";
  listaTipoDocs: TipoDocumento[] = []
  filtros: FiltroDocs[] = [];
  titulo: string = '';
  iconoBoton: string = '';
  textoBoton: string = '';
  iconoTitulo: string = '';
  seleccion: string = '';
  docCopiar: TipoDocumento;

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any,
              private formularioEnvioService: FormularioEnvioService,
              public dialogRef: MatDialogRef<any>,
              private datosDocumentosService: DatosDocumentosService) { }

  ngOnInit(): void {
    this.configurarInfoModal();

  }

  configurarInfoModal(){
    if(this.data.origenModal == 'anadir-doc-existente'){
      this.iconoTitulo = '#icon-attach'
      this.titulo = 'Adjuntar documentos existentes'
      this.iconoBoton = 'icon-attach'
      this.textoBoton = 'Aceptar selección'
      this.seleccion = 'multiple'
    }
    if(this.data.origenModal == 'configuracion-documento'){
      this.iconoTitulo = '#icon-copy'
      this.titulo = 'Copiar configuración de documento'
      this.iconoBoton = 'hs-copiar'
      this.textoBoton = 'Copiar'
      this.seleccion = 'single'
    }

  }

  guardarDocsExistentes(){
    if(this.seleccion == 'multiple'){
      this.formularioEnvioService.actualizaFormulario(this.data.objetoEnvio);
      this.formularioEnvioService.tiposDocumento.clear();
      this.docsAdjuntar.map(x=> x.fusionable = true);
      this.formularioEnvioService.addDocumentosEnForm(this.docsAdjuntar);
      this.dialogRef.close('OK');
    }
    if(this.seleccion == 'single') this.dialogRef.close({data: this.docCopiar})
  }


  //tenemos que pasar la info de events cuando se cierra el modal hacia el componente que lo ha abierto, este componente cogerá la info y ara lo necesario.
  seleccionarDocs(event:any){
    if(event.docsOrigen == 'multiple') this.docsAdjuntar = event.docsSeleccionados
    if(event.docsOrigen == 'single') this.docCopiar = event.docsSeleccionados
  }

  botonAccionClicked(id: string){
    if(id == "Buscar") this.buscar = true;
  }

  buscarTexto(textoBuscar: string){
    this.buscar = true;
    this.textoBuscar = textoBuscar;
    if(textoBuscar == undefined) this.buscar = false;
    this.buscarDocumentos(textoBuscar)
  }

  buscarDocumentos(textoBuscar:string){
    if(this.filtros.length == 0){
      this.filtros.push(
        { text: '',
          textoBuscar: '',
          value: '',
          estado: '',
          idTipoDocumento: 0,
          nombre:'',
          tipoDoc:''
        })
      }
    this.filtros[0].textoBuscar = textoBuscar;
    if(textoBuscar == undefined){
      this.filtros.pop();
    }
    this.datosDocumentosService.SetFilter(this.filtros);
    this.datosDocumentosService.Filter();
    this.data.documentos = this.datosDocumentosService.docsFiltrados
  }
}
