<div class="barra-flex-space-between">
  <h1>Configuración de Empresas</h1>
  <div class="acciones">
    <app-empresas-barra-superior (botonClicked)="clickBarraSuperior($event)"></app-empresas-barra-superior>
    <app-boton-accion [id]="'nueva empresa'" [icono]="'icon-plus'" [titulo]="'nueva empresa'" [outline]="false" (click)="creaNuevaEmpresa();"></app-boton-accion>
  </div>
</div>
<div class="main">
  <div *ngIf="empresasSeleccionadas.length==0" class="flex-center-items">
    <app-input-buscador (buscador)="buscarEmpresas($event)"></app-input-buscador>
    <!-- <app-empresas-filter-details [filtrosActivos]="datosEmpresasService.filtros" (qitarFiltro)="quitarFiltro($event)"></app-empresas-filter-details> -->
  </div>
  <!-- <app-empresas-data-grid [empresas]="empresas" (refresh)="refresh()" ></app-empresas-data-grid> -->
  <app-pastilla-seleccion-multiple *ngIf="empresasSeleccionadas.length>0"
                                    (borrarEmitter)="eliminarEmpresa()"
                                    (deseleccionarEmitter)="deseleccionarAll()"
                                    [textoMostrar]="textoBorrar"
                                    [tipoDato]="'empresas'"></app-pastilla-seleccion-multiple>
  <app-sqp-grid-component [data]="empresas"
                          (refresh)="getEmpresas()"
                          [columnDefs]="columnDefs"
                          [seleccion]="'multiple'"
                          (seleccionEliminarEmitter)="seleccionEmpresas($event)"
                          [deseleccionar]="deseleccionar"
                          (abrirModalDataEmitter)="abreModal($event)"
                          [iconoEditar]="true"></app-sqp-grid-component>
</div>
