import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TipoFirmante } from '../../../../envios/models/tipo-firmante';
import { ConfiguracionFirma, ErroresForm, FirmanteNif, TipoFirma } from '../../../models/firmante-nif';
import { FormularioDocumentoService } from '../../../services/formulario-documento.service';

@Component({
  selector: 'app-configuracion-documento-formularios',
  templateUrl: './configuracion-documento-formularios.component.html',
  styleUrls: ['./configuracion-documento-formularios.component.css']
})
export class ConfiguracionDocumentoFormulariosComponent implements OnInit {

  mostrarFirmantes: any[] = [];
  @Output() firmanteSeleccionado: EventEmitter<TipoFirmante> = new EventEmitter<TipoFirmante>();
  @Input() erroresForm: ErroresForm[];

  constructor(public formularioDocumentoService: FormularioDocumentoService) { }

  //si hay errores, los recuperamos
  ngOnChanges(changes: SimpleChanges){
    if(changes.erroresForm.currentValue && changes.erroresForm.currentValue.length > 0) this.seleccionarFirmanteError();
  }

  ngOnInit(): void {
    if(this.firmantesNif.value.length == 0) this.addFirmanteNifEnForm();
    this.mostrarFirmantes = this.firmantesNif.value.filter(x=> x.firmas.tipoFirma !== TipoFirma.Validador);
  }

  get firmantesNif(){ return this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'] as FormArray}

  //obtenemos el firmante seleccionado del hijo formulario-firmantes, le añadimos el atributo seleccionado y modificamos el array mostrarFirmante que pasaremos al hijo otra vez para que muestre el que está seleccionado y cierre los demás. Por otra parte, enviamos el firmante seleccionado al padre documento-page para que se lo pase a otro hijo documento-upload y se muestren solo las casillas dibujadas del firmante que está seleccionado en ese momento.
  seleccionarFirmante(firmante: FirmanteNif){
    this.mostrarFirmantes.forEach(x=> {
      if(x.tipoFirmante.desc == firmante.tipoFirmante.desc){
        if(x.seleccionado == true) x.seleccionado = false;
        else x.seleccionado = true;
      }else{
        x.seleccionado = false;
      }
    })
    this.firmanteSeleccionado.emit(firmante.tipoFirmante)
  }

  //obtenemos los errores referente a formularios y modificamos el array mostrarFirmantes, para que se abra el primer firmante donde haya un error. Los siguientes firmantes con errores se irán mostrando a medida que se vayan solucionado los errores anteriores.
  seleccionarFirmanteError(){
    this.mostrarFirmantes.forEach(x => {
      if(x.tipoFirmante.desc == this.erroresForm[0].firmante.desc){
          x.seleccionado = true;
          x.tipoErrorFirma = this.erroresForm[0].error;
          this.firmanteSeleccionado.emit(x.tipoFirmante)
      }else{
        x.seleccionado = false;
      }
    })
  }

  addFirmanteNifEnForm(){
    let valoresFirmanteNif: FirmanteNif;
    valoresFirmanteNif = {
      tipoFirmante: {
        codigo: '100',
        desc: 'Firmante principal'
      },
      nifPage: null,
      nifOrden: null,
      firmas: {} as ConfiguracionFirma,
      formularios: [],
      camposEditables: [],
      nifFormulario:'',
      posicionNif: null
    }
    this.formularioDocumentoService.addFirmanteNifInFormArray(valoresFirmanteNif)
  }

}
