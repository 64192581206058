import { Injectable } from '@angular/core';
import { IRecuperarUsuariosService } from './ports/i-recuperar-usuarios-service';
import { Observable, of, throwError } from 'rxjs';
import { UsuarioModel, UsuarioEstado, UsuarioTipo, UsuarioPermisos, UsuariActivo, Seguridad } from '../models/usuario-model';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from '../../login/services/login.service';
import { catchError, map } from 'rxjs/operators';
import { ConversionDatosApiService } from 'src/app/shared/services/conversion-datos-api.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TenanData } from '../../login/models/tenant_data';
import { DatosEnviosService } from '../../configuracion/Envios/envios/services/datos-envios.service';
import { DatosCentrosService } from '../../configuracion/maestros/centros/services/datos-centros.service';
import { RecuperaEmpresasApiService } from '../../configuracion/maestros/empresas/services/recupera-empresas-api.service';
import { EmpresasModel } from '../../configuracion/maestros/empresas/models/empresas-model';
import { CentrosModel } from '../../configuracion/maestros/centros/models/centros-model';
import { TipoEnvio } from '../../configuracion/Envios/envios/models/tipo-envio';
import { DesplegableData } from 'src/app/shared/models/desplegable-data';

@Injectable({
  providedIn: 'root'
})
export class RecuperaUsuariosApiService implements IRecuperarUsuariosService{

  private readonly URL_GET_USUARIOS_TIPO: string = this.endpointService.UrlGetUsuariosTipo;
  private readonly URL_GET_ESTADOS_USUARIO: string = this.endpointService.UrlGetEstadosUsuario;
  private readonly URL_INVITA_USUARIO: string = this.endpointService.UrlInvitaUsuario;
  private readonly URL_GET_USERS: string = this.endpointService.UrlGetUsers;
  private readonly URL_GUARDA_USER: string = this.endpointService.UrlGuardaUser;
  private readonly URL_ACTIVA_USUARIO: string = this.endpointService.UrlActivaUsuario;
  private readonly URL_DESACTIVA_USUARIO: string = this.endpointService.UrlDesactivaUsuario;
  private readonly URL_RESET_PASSWORD_AZURE_AD: string = this.endpointService.UrlResetPasswordAzureAd;
  private readonly URL_UPDATE_USER:string = this.endpointService.UrlUpdateUser;
  private readonly URL_REENVIAR_INVITACION: string = this.endpointService.UrlRenviaInvitacion;
  private readonly URL_DELETE_USUARIO: string = this.endpointService.UrlDeleteUsuario;

  usuarios: UsuarioModel[];
  empresasData: EmpresasModel[] = [];
  centrosData: CentrosModel[] = [];
  enviosData: TipoEnvio[] = [];
  estadosUsuarios: {codigo: string, desc: string}[];
  usuariosTipo: string[];

  constructor(private endpointService: EndpointService,
              private loginService: LoginService,
              private client: HttpClient,
              private conversionDatosApiService: ConversionDatosApiService,
              private route:ActivatedRoute,
              private _snackBar: MatSnackBar,
              private datosEnviosService: DatosEnviosService,
              private datosCentrosService: DatosCentrosService,
              private datosEmpresasApiService: RecuperaEmpresasApiService ) {
              }


  guardaUsuarioActivo(data: UsuariActivo,tenant:string): Observable<void> {

    let url = this.URL_UPDATE_USER.replace('{tenant}',tenant);


    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };

    return this.client.post<void>(url, data, options)
  }


  getUsuariosPermisos(): Observable<UsuarioPermisos[]> {
    throw new Error('Method not implemented.');
  }

  getUsuarioSeguridad(): Observable<UsuarioEstado[]> {
    throw new Error('Method not implemented.');
  }

  empresaNewArray:Seguridad[]=[];
  centroNewArray:Seguridad[]=[];
  tipoEnvioNewArray:Seguridad[]=[];

  getUsuarios(refresh?: boolean): Observable<UsuarioModel[]> {
    if(this.usuarios && !refresh){
      return of(this.usuarios)
    }else{
      const options = {
        headers: new HttpHeaders({
          'Content-Type':'application/json',
          'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
        })
      };
      return this.client.get(this.URL_GET_USERS, options).pipe(
        map((response: any) =>{
          if (response) {

            let users = this.conversionDatosApiService.mapUsersEntrada(response);
            this.datosEnviosService.getTipoEnvios().then(response => {
              this.enviosData = this.datosEnviosService.enviosFiltrados;
              users.map(x=>{
                x.tipoEnvioPermitido = x.tipoEnvioPermitido.map(y=> this.conversionDatosApiService.mapSeguridadPermitidaEntrada(y));
                x.tipoEnvioPermitido.map((y:Seguridad)=>{
                  this.enviosData.forEach((z:TipoEnvio)=>{
                    this.tipoEnvioNewArray = [];
                    if(z.idTipoEnvio == y.id){
                      y.desc = z.nombre;
                      this.tipoEnvioNewArray.push(y);
                    }
                  })
                })
                this.tipoEnvioNewArray.forEach(envio=>{
                  if(!this.tipoEnvioNewArray.includes(envio)) x.tipoEnvioPermitido.push(envio)
                })
              })
            })
            this.datosEmpresasApiService.getEmpresas().subscribe(respuesta => {
              this.empresasData = respuesta;
              users.map(x=>{
                x.empresaPermitida = x.empresaPermitida.map((y:Seguridad)=> this.conversionDatosApiService.mapSeguridadPermitidaEntrada(y));
                x.empresaPermitida.map((y:Seguridad)=> {
                  this.empresasData.forEach((z:EmpresasModel) => {
                    this.empresaNewArray = [];
                    if(z.codigoEmpresa == y.id){
                      y.desc = z.nombreEmpresa;
                      this.empresaNewArray.push(y);
                    }
                  })
                })
                this.empresaNewArray.forEach(emp=> {
                  if(!this.empresaNewArray.includes(emp)) x.empresaPermitida.push(emp)
                })
              })
            })
            this.datosCentrosService.getCentros().subscribe(respuesta => {
              this.centrosData = respuesta;
              users.map(x=>{
                x.centroPermitido = x.centroPermitido.map(y=> this.conversionDatosApiService.mapSeguridadPermitidaEntrada(y));
                x.centroPermitido.map((y:Seguridad)=>{
                  this.centrosData.forEach((z:CentrosModel)=>{
                    this.centroNewArray = [];
                    if(z.codigoCentro == y.id){
                      y.desc = z.nombreCentro;
                      this.centroNewArray.push(y);
                    }
                  })
                })
                this.centroNewArray.forEach(centro=>{
                  if(!this.centroNewArray.includes(centro)) x.centroPermitido.push(centro)
                })
              })
            })

            this.usuarios = users;
            return users;
          }
          else throw new Error(response.Message);
        })
      )
    };
  }

  getEstadosUsuario(): Observable<UsuarioEstado[]> {
    if(this.estadosUsuarios){
      return of(this.estadosUsuarios)
    }else{
      const options = {
        headers: new HttpHeaders({
          'Content-Type':'application/json',
          'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
        })
      };
      return this.client.get(this.URL_GET_ESTADOS_USUARIO, options).pipe(
        map((response: any) =>{
          if (response) {
            let estadosUsuario = this.conversionDatosApiService.mapTiposEnvioDesplegablesEntrada(response)
            estadosUsuario.map(x=>{
              if(x.codigo=='PendienteConfirmacion'){
                x.desc='Pendiente activación'
              }
            })
            estadosUsuario.unshift({codigo:'Todos',desc:'Todos'});
            this.estadosUsuarios = estadosUsuario
            return estadosUsuario;
          }
          else throw new Error(response.Message);
        })
      );
    }

  }

  getUsuariosTipo(): Observable<any[]> {
    if(this.usuariosTipo){
      return of(this.usuariosTipo)
    }else{
      const options = {
        headers: new HttpHeaders({
          'Content-Type':'application/json',
          'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
        })
      };
      return this.client.get(this.URL_GET_USUARIOS_TIPO, options).pipe(
        map((response: any) =>{
          if (response) {
            this.usuariosTipo = response;
            return response;
          }
          else throw new Error(response.Message);
        })
      );
    }

  }

  invitaUsuario(data: any): Observable<string>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    let token = this.loginService.getUserLocalStorage().Token.Token;
    let tokenInfo = this.loginService.DecodeTokenLogin(token);

    //  tenants = JSON.parse(tokenINfo.tenant);
    let usuarioData = {
      Email: data.emailUsuario,
      Tenant: tokenInfo.tenant,
      Nombre: data.nombreUsuario,
      Apellido1: data.apellidosUsuario,
      Rol: data.rolUsuario,
      NombreAnfitrion: this.loginService.loginData.User.Nombre,
      ApellidoAnfitrion: this.loginService.loginData.User.Apellido1
    }

    return this.client.post<any>(this.URL_INVITA_USUARIO, usuarioData, options)
    .pipe(
        map((response: string) =>{
        return response
      }),
      catchError(error => {
              return throwError("Ha ocurrido un error y no se ha invitado al usuario.");
            })
    );
  }

  guardaUser(data:any): Observable<void> {

    data = this.conversionDatosApiService.mapGuardaUserSalida(data);

    if(data.TipoEnvioPermitido) data.TipoEnvioPermitido = data.TipoEnvioPermitido.map((x:any)=> x = this.conversionDatosApiService.mapSeguridadPermitidaSalida(x));
    else data.TipoEnvioPermitido = [];
    if(data.EmpresaPermitida) data.EmpresaPermitida = data.EmpresaPermitida.map((x:any)=> x = this.conversionDatosApiService.mapSeguridadPermitidaSalida(x));
    else data.EmpresaPermitida = [];
    if(data.CentroPermitido) data.CentroPermitido = data.CentroPermitido.map((x:any)=> x = this.conversionDatosApiService.mapSeguridadPermitidaSalida(x));
    else data.CentroPermitido = [];


    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };

    return this.client.post<void>(this.URL_GUARDA_USER, data, options)
    // .pipe(
    //     map((response: any) =>{

    //     if (response) {

    //       return response;
    //     }
    //     else throw new Error(response.Message);
    //   })
    // );

  }

  desactivaUsuario(emailUsuario: any): Observable<void>{
    const options = {
      headers: new HttpHeaders({
        // 'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    // let token = this.loginService.getUserLocalStorage().Token.Token;
    // let tokenInfo = this.loginService.DecodeTokenLogin(token);

    //  tenants = JSON.parse(tokenINfo.tenant);
    // let usuarioData = {
    //   Email: data.emailUsuario,
    //   Tenant: tokenInfo.tenant,
    //   Nombre: data.nombreUsuario,
    //   Apellido1: data.apellidosUsuario,
    //   Rol: data.rolUsuario,
    //   NombreAnfitrion: this.loginService.loginData.User.Nombre,
    //   ApellidoAnfitrion: this.loginService.loginData.User.Apellido1
    // }

    return this.client.post<void>(this.URL_DESACTIVA_USUARIO.replace("{login}", emailUsuario), {}, options)
    // .pipe(
    //   map((response: any) =>{
    //     if (response) {

    //       return response;
    //     }
    //     else throw new Error(response.Message);
    //   })
    // );
  }

  activaUsuario(emailUsuario:string): Observable<void>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    // let token = this.loginService.getUserLocalStorage().Token.Token;
    // let tokenInfo = this.loginService.DecodeTokenLogin(token);

    return this.client.post<void>(this.URL_ACTIVA_USUARIO.replace("{login}", emailUsuario), {}, options)

  }

  resetPasswordAzureAd():Observable<void>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.post(this.URL_RESET_PASSWORD_AZURE_AD, {}, options).pipe(
      map((response: any) =>{
        if (response) {

          return response;
        }
        else throw new Error(response.Message);
      })
    );
  }

  reenviarInvitacion(data: UsuarioModel): Observable<void> {

    let usuarioData = {
      Email: data.email,
      Tenant: data.tenant,
      Nombre: data.nombre,
      Apellido1: data.apellido1,
      Rol: data.rol,
      NombreAnfitrion: this.loginService.loginData.User.Nombre,
      ApellidoAnfitrion: this.loginService.loginData.User.Apellido1
    }

    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.post<void>(this.URL_REENVIAR_INVITACION, usuarioData, options);
  }

  deleteUsuario(login:string): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.delete<any>(this.URL_DELETE_USUARIO.replace("{login}", login), options)
    .pipe(
      map(resp=>{

      }),
      catchError(error => {
        return throwError("No se ha eliminado el usuario.");
      })
    );

  }
}
