import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { Workbook } from 'exceljs';
import { catchError, map } from 'rxjs/operators';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { environment } from 'src/environments/environment';
import * as fs from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError,Observable } from 'rxjs';
import { IGestionarEmpleadosService } from './ports/i-gestionar-empleados-service';
import { ConfiguracionExcelEmpleados } from 'src/app/pages/empleados/models/configuracion-excel-empleados';
import { Empleado } from 'src/app/pages/empleados/models/empleado';
import { DatosLogSubida } from '../models/datos-log-subida';
import { read, utils } from "xlsx";
@Injectable({
  providedIn: 'root'
})
export class EmpleadosService {

  constructor(private httpClient: HttpClient,
               private _snackBar: MatSnackBar,
               @Inject('GestionaEmpleados') private datosEmpleados: IGestionarEmpleadosService) { }


  private readonly COLUMNS = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

  config: ConfiguracionExcelEmpleados[] = [];
  dateFormat: string = "";
  cultureInfo: string = "";
  delimiterCSV: string = "";
  empleadosEstructura: any = {};
  empledosDefaultEstructura: any ={};
  columnDefs: ColDef[] = [];
  columnasExcel: any[] = [];
  workbook: any;
  worksheet: any;

  getConfiguration(){
    return this.datosEmpleados.getExcelConfiguration().pipe(
      map( (data: any) =>{

        this.dateFormat = data.DateFormat ? data.DateFormat : 'yyyy-MM-dd hh:mm:ss';
        this.cultureInfo = data.CultureInfo ? data.CultureInfo : 'en-US';
        this.delimiterCSV = data.Delimiter ? data.Delimiter : ',';
        let _config: ConfiguracionExcelEmpleados[] = data.Configuracion;
        this.config = _config.sort((a,b) => a.posExcel - b.posExcel);
        let empleadoProperties: string[] = [];
        this.config.forEach(x => {
          let temp = '';
          if(x.tituloExcelDefault) temp += x.tituloExcelDefault+';';
          if(x.tituloExcel) temp += x.tituloExcel;
          empleadoProperties.push(temp);
          this.empledosDefaultEstructura[x.tituloExcel] = "";
        });
        this.empleadosEstructura = {};
        empleadoProperties.forEach(property => {
          this.empleadosEstructura[property] = "";
        });

        this.defineColumnasExcel();

      })
    );
  }

  validateConfiguration(){

    if(Object.keys(this.empleadosEstructura).length == 0)
    {
      this._snackBar.open("Configure los diferentes campos para poder continuar con la operación", "Cerrar", {duration: 3000});
      return false;
    } else return true;
  }

  private defineColumnasExcel(){
    this.columnDefs = [];
    this.columnasExcel = [];
    this.columnDefs.push(
      {
        field: 'errorMensaje',
        headerName: 'Error',
        width: 75,
        cellRenderer: 'empleadosTooltip'
      }
    );
    this.columnasExcel.push(
      {
        name: 'errorMensaje',
        headerName: 'errorMensaje',
        filterButton: true,
        width: 10,
        ColumnExcel: "A",
      }
    );
    this.config.forEach((element, index) => {
      if(["FECHA_NACIMIENTO", "FECHA_ALTA"].includes(element.campoInterno))
      {
        this.columnDefs.push({
          field: element.tituloExcelDefault ? element.tituloExcelDefault : element.tituloExcel.split(';')[0],
          //field: element.tituloExcel.split(';')[0],
          resizable: true,
          width: 150,
          headerName: element.tituloExcelDefault ? element.tituloExcelDefault : element.tituloExcel.split(';')[0],
          cellRenderer: (col) => {
            if (!(isNaN(new Date(col.value).getDate()))){
              let date = new Date(col.value);
              return date.toLocaleDateString('en-GB');
            } else return '';
          }
        });
      } else {
        this.columnDefs.push({
          field: element.tituloExcelDefault ? element.tituloExcelDefault : element.tituloExcel.split(';')[0],
          resizable: true,
          width: 150,
          headerName: element.tituloExcelDefault ? element.tituloExcelDefault : element.tituloExcel.split(';')[0],
        });
      }
      this.columnasExcel.push({
        name: element.tituloExcelDefault ? element.tituloExcelDefault : element.tituloExcel.split(';')[0],
        filterButton: true,
        width: 20,
        ColumnExcel: ((index+1) <= this.COLUMNS.length) ? this.COLUMNS[(index+1)] : `${this.COLUMNS[0]}${this.COLUMNS[(index+1)-this.COLUMNS.length]}`
      });
    });
  }

  // Consula a la API para eliminar el listado completo de empleados registrados
  // deleteEmpleados(){
  //   let httpOptions: any ={
  //     headers: new HttpHeaders({
  //       'Content-Type':'application/json',
  //       "authorization": `Bearer ${this.loginService.getUserLocalStorage().Token.Token}`
  //     })
  //   };
  //   return this.httpClient.delete(this.URL_DELETE_EMPLEADOS, httpOptions).pipe(
  //     map( (response: any) =>{
  //       return response.Mesage;
  //     }),
  //     catchError(error => {
  //       return "Ha habido un error durante la eliminación de empleados";
  //     })
  //   );
  // }
  deleteEmpleados(){
    return this.datosEmpleados.deleteEmpleados().pipe(
      map( (response: any) =>{
        return response
      }),
      catchError(error => {
        return "Ha habido un error durante la eliminación de empleados";
      })
    );
  }

  // Añadimos el listado de empleados del excel previamente subido y validado
  // addEmpleados(empleados: any[]){
  //   this.buildEmpleadosSubida(empleados);

  //   let httpOptions: any ={
  //     headers: new HttpHeaders({
  //       'Content-Type':'application/json',
  //       "authorization": `Bearer ${this.loginService.getUserLocalStorage().Token.Token}`
  //     })
  //   };
  //   return this.httpClient.post(this.URL_ADD_EMPLEADOS, empleados, httpOptions).pipe(
  //     map( (response: any) =>{
  //       return response.Message;
  //     }),
  //     catchError(error => {
  //       return throwError("No se han podido actualizar los empleados debido a un error interno.");
  //     })
  //   );
  // }
  // addEmpleados(empleados: any[]){
  //   this.buildEmpleadosSubida(empleados);
  //   let chunks: any[] = [];
  //   const chunkSize = 500;
  //   for(let i = 0; i < empleados.length; i += chunkSize) {
  //     const chunk = empleados.slice(i, i + chunkSize);
  //     chunks.push(chunk);
  //   }
  //   let errorSubida = false;
  //   this.enviarPorPaquetes(chunks).then(result => errorSubida = result);
  //   return new Observable<any>(observer => {
  //     if(errorSubida) observer.error('No se han podido actualizar los empleados debido a un error interno.');
  //     else observer.next('OK');
  //   });
  // }

  // private async enviarPorPaquetes(chunksEmpleados: any[]): Promise<boolean>{

  //   let errorSubida = false;
  //   for (const chunk of chunksEmpleados) {

  //     await this.datosEmpleados.addEmpleados(chunk).pipe(
  //       map( (response: any) =>{

  //         return response;
  //       }),
  //       catchError(error => {
  //         errorSubida = true;

  //         return throwError("No se han podido actualizar los empleados debido a un error interno");
  //       })
  //     ).toPromise();
  //   }
  //   return new Promise<boolean>( resolve => { return errorSubida; });
  // }

  addEmpleados(empleados: any[]){
    this.buildEmpleadosSubida(empleados);

    return this.datosEmpleados.addEmpleados(empleados).pipe(
      map( (response: any) =>{

        return response
      }),
      catchError(error => {
        return throwError("No se han podido actualizar los empleados debido a un error interno.");
      })
    );
  }

  // Exportamos el listado de empleados a un fichero excel
  exportaExcel(empleados: any[],exprtaFormato:boolean){
    this.workbook = new Workbook();
    this.worksheet = this.workbook.addWorksheet('Empleados');
    let rows:any[] = this.GetRows(empleados);
    let columns: any[] = this.columnasExcel;
    // Si descargamos la plantilla, debe ser sin mostrar la columna errorMensaje
    if(exprtaFormato) {
      columns = columns.filter(x => x.name != "errorMensaje");
      rows.forEach(x => x.shift());
    }
    this.AnchColumna();
    if(rows.length > 0) {
      this.worksheet.addTable({
        name: 'empleados',
        ref: "A1",
        headerRow: true,
        style: {
          theme: 'TableStyleMedium2',
          showRowStripes: true,
        },
        columns: columns,
        rows: rows,
      });
      this.aplicarEstilosExcel();
      this.workbook.xlsx.writeBuffer().then((data:any) => {
        let fecha = new Date();
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, `Hire&Sign_empleados_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getMinutes()}.xlsx`);

      });
    }
  }

  private aplicarEstilosExcel(){
    let errorMensajeCol = 0;
    let campos: any[] = [];
    this.getTitulosExcelCamposObligatorios().forEach(x => x.split(';').forEach(y => { if(y) campos.push({campo: `'${y}'`, column: 1}); }));
    let _worksheet = this.worksheet;
    this.worksheet.eachRow(function(row:any, rowNumber:number){
      let rowHasError = false;
        row.eachCell( function(cell:any, colNumber:number){
          if(rowNumber == 1) {
            if(cell.value == "errorMensaje") errorMensajeCol = colNumber;
            else {
              let _campo = campos.find(x => x.campo == `'${cell.value}'`);
              if(_campo) _campo.column = colNumber;
            }
          } else {
            if(cell.value != '' && errorMensajeCol == colNumber) {
              let celdasErroneas = cell.value.match(/'(.*?)'/g);
              celdasErroneas.forEach(celda => {
                let _campo = campos.find((x:any) => x.campo == celda);
                if(_campo) {
                  rowHasError = true;
                  let _cell = row.getCell(_campo.column);
                  _cell.border = {
                    top: { style: 'thick' },
                    left: { style: 'thick' },
                    bottom: { style: 'thick' },
                    right: { style: 'thick' },
                    color: {argb:'FFFF0000'}
                  }
                  let titleCellAddress = _cell.address.replace(/[0-9]/, "1")
                  let titleCell = _worksheet.getCell(titleCellAddress)
                  titleCell.font = { bold: true, color: {argb:'FFFF0000'} };
                }
              });
            }
            if(rowHasError) {
              row.fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor: {argb: 'FEE4E2' }
                // fgColor: {argb: 'f8c9cb' }
              }
              row.getCell(1).font = { bold: true, color: {argb:'FFFF0000'} };
            }
          }
        });
      });
  }

  // Mapeamos los datos de las firmas a las filas del excel
  private GetRows(empleados:any[]):any[]{
    let rows:any[] = [];
    empleados.forEach(empleado => {
      let row:any[] = [];
      this.columnasExcel.forEach(columna => {
        row.push(empleado[columna.name]);
      });
      rows.push(row);
    });
    return rows;
  }

  private AnchColumna(){
    this.columnasExcel.forEach(x=>{
      let col = this.worksheet.getColumn(x.ColumnExcel);
      if(col)col.width = x.width;
    });
  }

  // Leemos el fichero y obtenemos el listado de empleados ya mapeados con las cabeceras personalizadas
  async readFile(file: File|null){
    let empleados: Empleado[] = [];
    // Obtenemos el contenido fichero
    let arrayBuffer = await file?.arrayBuffer().then(x => {
      return x;
    }).catch((err: any) => {
        console.error(err);
    });
    // Separamos  el mapeo según se haya subido un documento Excel o CSV
    switch(file?.type){
      case "application/vnd.ms-excel":
        empleados = await this.leerFicheroExcelOld(arrayBuffer);
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        empleados = await this.leerFicheroExcel(arrayBuffer);
        break;
      case "text/csv":
        empleados = this.leerFicheroCSV(arrayBuffer);
        break;
    }
    return empleados;
  }

  private async leerFicheroExcel(arrayBuffer: ArrayBuffer | void){
    let cabeceras: string[] = [];
    // Trabajamos sobre exceljs
    this.workbook = new Workbook();
    this.worksheet = this.workbook.addWorksheet('Empleados');

    // Obtenemos las cabeceras y el listado de lineas que habrá que mapear a un array de empleados
    let empleadosExcel = await this.workbook.xlsx.load(arrayBuffer).then(() => {
    const ws = this.workbook.getWorksheet(this.workbook.worksheets[0].name);
      const lastRow = ws.lastRow;
      cabeceras = ws.getRow(1).values;
      return ws.getRows(2, (lastRow.number-1));
    }).catch((x: any) => {
      this._snackBar.open("Excel no válido, inténtelo de nuevo", "Cerrar", {duration: 3000});
    });

    // Mapeamos los empleados
    if(!this.validaCabeceras){
      this._snackBar.open("Los capmpos no corresponden a ningún modelo", "Cerrar", {duration: 3000});
      return null;
    }
    let empleados = this.getEmpleadosExcel(empleadosExcel, cabeceras);
    return empleados;
  }
  private validaCabeceras(cabeceras:string[]):boolean{
    let indexSel = -1;
    let error:boolean=false;
    cabeceras.forEach(x=>{
      let element = Object.keys(this.empleadosEstructura).find(z => z.split(';').includes(x));
      if(element){
        let index = element.split(';').indexOf(x);
        if(indexSel<0) indexSel=index
        if(indexSel!= index ) error=true;
      }
    });
    return true;


  }

  private leerFicheroCSV(arrayBuffer: ArrayBuffer | void){
    let cabeceras: string[] = [];
    // Decodificamos el arraybuffer obtenido anteriormente en texto plano
    let textDecoder = new TextDecoder("UTF-8");
    let text = textDecoder.decode(new Uint8Array(arrayBuffer as ArrayBufferLike));
    let rows = text.split("\r\n") ?? '';
    let empleadosCSV: any[] = [];
    // Obtenemos las cabeceras y el listado de lineas que habrá que mapear a un array de empleados
    for (var i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (i == 0) cabeceras = row.split(this.delimiterCSV);
      else {
        let splitted = row.split(this.delimiterCSV);
        if(!splitted.every(x => x == "")) empleadosCSV.push(splitted);
      }
    }
    // Mapeamos los empleados
    let empleados = this.getEmpleadosCSV(empleadosCSV, cabeceras);
    return empleados;
  }
  private async leerFicheroExcelOld(arrayBuffer: ArrayBuffer | void){
    let cabeceras: string[] = [];
    // Trabajamos sobre exceljs

    // Obtenemos las cabeceras y el listado de lineas que habrá que mapear a un array de empleados
    const workbook = read(arrayBuffer);
    const first_sheet_name = workbook.SheetNames[0];
    const first_worksheet = workbook.Sheets[first_sheet_name];
    let data = utils.sheet_to_json(first_worksheet, {header:1});

    if(this.esFormatoA3NOm(data)){
      cabeceras = Object.values(data[1]);
      data = utils.sheet_to_json(first_worksheet, {header:cabeceras });
      data.shift();
    }else{
      cabeceras = Object.values(data[1]);
      data = utils.sheet_to_json(first_worksheet, {header:cabeceras });
    }
    cabeceras = Object.values(data[0]);
    data.shift();
    let empleados = this.getEmpleadosExcelOld(data,cabeceras);


    return empleados;
  }
  private esFormatoA3NOm(data:any[]):boolean{
    if(!data||data.length<=2) return false;
    let object = data[0][0];

    if(object.includes('Fecha Listado')){
      object = data[1][0];
      if(object.includes('Emp->NIF_de_la_Empresa')) return true;
    }
    return false;

  }
  private getEmpleadosExcelOld(empleadosExcel: any[], cabeceras: string[]){
    let empleados: Empleado[] = [];
    empleadosExcel.forEach((empleadoExcel: any, index: number) => {
      let empleado: Empleado = {};
      let modelo=-1;
      Object.keys(empleadoExcel).forEach((key:string) => {

          const property = Object.keys(this.empleadosEstructura).find(x => x.toUpperCase().split(';').includes(key.toUpperCase()));
          if(property){
            let propertyTemp:string[] = property.split(';');
            // let index = propertyTemp.indexOf(cabeceraExcel);
            // if(modelo<0) modelo==index;
            empleado[propertyTemp[0]] = empleadoExcel[key];
            // property.split(';').forEach(_property =>{
            //   empleado[_property] = cell.text;
            // });
          };
      });
      // Si se lee una línea vacía, el empleado no tendrá propiedades (solo "error" a true y "errorMensaje" indicando los campos obligatorios). No lo añadimos al listado de empleados.
      this.validarCamposObligatorios(empleado);
      if(JSON.stringify(Object.keys(empleado)) != JSON.stringify(["errorMensaje", "error"])) empleados.push(empleado);
    });
    return empleados;
  }
  // Transformamos el array de filas del CSV a array de empleados con los datos mapeados y validados
  private getEmpleadosCSV(empleadosCSV: any[], cabeceras: string[]){
    let empleados: Empleado[] = [];
    let cabecerasEstructura = Object.keys(this.empleadosEstructura);
    empleadosCSV.forEach(empleadoCSV => {
      let empleado: Empleado = {};
      cabeceras.forEach((cabecera: string, columnIndex: number) => {
        if(cabecerasEstructura.find(x => x.split(';').find(y => y == cabecera))) {
          empleado[cabecera] = empleadoCSV[columnIndex];
        }
      });
      this.validarCamposObligatorios(empleado);
      if(JSON.stringify(Object.keys(empleado)) != JSON.stringify(["errorMensaje", "error"])) empleados.push(empleado);
    });
    return empleados;
  }

  // Transformamos el array de filas del excel a array de empleados con los datos mapeados y validados
  private getEmpleadosExcel(empleadosExcel: any[], cabeceras: string[]) {
    let empleados: Empleado[] = [];
    empleadosExcel.forEach((empleadoExcel: any, index: number) => {
      let empleado: Empleado = {};
      let modelo=-1;
      empleadoExcel.eachCell((cell: any, k: number) => {
          const cabeceraExcel = cabeceras[k];
          const property = Object.keys(this.empleadosEstructura).find(x => x.toUpperCase().split(';').includes(cabeceraExcel.toUpperCase()));
          if(property){
            let propertyTemp:string[] = property.split(';');
            let index = propertyTemp.indexOf(cabeceraExcel);
            if(modelo<0) modelo==index;
            empleado[propertyTemp[index]] = cell.text;
            if(property.includes('FECHA') && typeof(cell.value) == 'object') empleado[propertyTemp[index]] = `${cell.value.getDate().toString().padStart(2,'0')}/${(cell.value.getMonth()+1).toString().padStart(2,'0')}/${cell.value.getFullYear()}`;
            // property.split(';').forEach(_property =>{
            //   empleado[_property] = cell.text;
            // });
          };
      });
      // Si se lee una línea vacía, el empleado no tendrá propiedades (solo "error" a true y "errorMensaje" indicando los campos obligatorios). No lo añadimos al listado de empleados.
      this.validarCamposObligatorios(empleado);
      if(JSON.stringify(Object.keys(empleado)) != JSON.stringify(["errorMensaje", "error"])) empleados.push(empleado);
    });
    return empleados;
  }

  // Comprobamos que estén informados los siguientes campos del excel: CODIGO (empleado PK), FECHA_ALTA (no admite nulos), NIF y algún correo (para poder realizar envíos)
  private validarCamposObligatorios(empleado: Empleado){
    const CAMPO_OBLIGATORIO_NOMBRE: string = this.getTituloExcel('NOMBRE');
    const CAMPO_OBLIGATORIO_NIF: string = this.getTituloExcel('NIF');
    const CAMPO_OBLIGATORIO_CIF_EMPRESA: string = this.getTituloExcel('EMPRESA_CIF');
    const CAMPO_OBLIGATORIO_FECHA_ALTA: string = this.getTituloExcel('FECHA_ALTA');
    const CAMPO_OBLIGATORIO_MAIL_PERSONAL: string = this.getTituloExcel('MAIL_PERSONAL');
    const CAMPO_OBLIGATORIO_MAIL_PROFESIONAL: string = this.getTituloExcel('MAIL_PROFESIONAL');
    const CAMPO_OPCIONAL_FECHA_NACIMIENTO: string = this.getTituloExcel('FECHA_NACIMIENTO');

    let errores: string[] = [];
    if(!this.isValidValue(empleado, CAMPO_OBLIGATORIO_NOMBRE, 'string')) errores.push(`\'${CAMPO_OBLIGATORIO_NOMBRE.split(';')[0]}\'`);
    if(!this.isValidValue(empleado, CAMPO_OBLIGATORIO_NIF, 'string')) errores.push(`\'${CAMPO_OBLIGATORIO_NIF.split(';')[0]}\'`);
    if(!this.isValidValue(empleado, CAMPO_OBLIGATORIO_CIF_EMPRESA, 'string')) errores.push(`\'${CAMPO_OBLIGATORIO_CIF_EMPRESA.split(';')[0]}\'`);
    if(!this.isValidValue(empleado, CAMPO_OBLIGATORIO_FECHA_ALTA, 'date')) errores.push(`\'${CAMPO_OBLIGATORIO_FECHA_ALTA.split(';')[0]}\'`);
    if(!this.isValidValue(empleado, CAMPO_OPCIONAL_FECHA_NACIMIENTO, 'date', true)) errores.push(`\'${CAMPO_OPCIONAL_FECHA_NACIMIENTO.split(';')[0]}\'`);
    if(!this.isValidValue(empleado, CAMPO_OBLIGATORIO_MAIL_PERSONAL, 'string') && !this.isValidValue(empleado, CAMPO_OBLIGATORIO_MAIL_PROFESIONAL.split(';')[0], 'string'))errores.push(`\'${CAMPO_OBLIGATORIO_MAIL_PERSONAL.split(';')[0]}\' o \'${CAMPO_OBLIGATORIO_MAIL_PROFESIONAL.split(';')[0]}\'`);
    empleado.errorMensaje = (errores.length > 0) ? `Revise que los siguientes campos estén informados y tengan un formato válido: ${errores.join(', ', )}` : '';
    empleado.error = (empleado.errorMensaje.length > 0);
  }
 private getTituloExcelObject(nombreInterno:string,objeto:any){
  let nombre = Object.keys(this.empleadosEstructura).find(x => x.toUpperCase().split(';').includes(nombreInterno));
  let titulo = Object.keys(objeto).find(x=>nombre.includes(x));

  return titulo



 }
  // Validamos que los campos obligatorios del empleado estén informados y tengan un formato correcto
  private isValidValue(empleado: Empleado, campo: string, type: string, optional: boolean = false): boolean{
    try {
      let value: any;
      campo.split(';').forEach(x => { if(!value) value = empleado[x]; });
      if((value == null || value == undefined || value?.toString().trim().length == 0) && optional) return true;
      else if((value == null || value == undefined || value?.toString().trim().length == 0) && !optional) return false;
      else {
        if(type == 'number') return !(isNaN(parseInt(value)));
        else if(type == 'date') {
          if (!isNaN(value)) {
            campo.split(';').forEach(x => { empleado[x] = '';});
            return false;
          } else if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) {
            // Formato en-GB (dd/MM/yyyy)
            let day = parseInt(value.toString().split('/')[0]);
            let month = parseInt(value.toString().split('/')[1]);
            let year = parseInt(value.toString().split('/')[2]);
            let date = new Date(year, month-1, day);
            date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
            campo.split(';').forEach(x => { empleado[x] = date.toISOString(); });
            return true;
          } else if (/^\d{4}\-\d{2}\-\d{2}$/.test(value)) {
            if(!(isNaN(new Date(value).getDate()))) {
              let date = new Date(value);
              date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
              campo.split(';').forEach(x => { empleado[x] = date.toISOString(); });
              return true;
            } else {
              campo.split(';').forEach(x => { empleado[x] = '';});
              return false;
            }
          } else {
            campo.split(';').forEach(x => { empleado[x] = '';});
            return false;
          }
        }
        else return true;
      }
    } catch (error) {
      return false;
    }
  }

  public GetLog(id:string):Observable<DatosLogSubida>{
    return this.datosEmpleados.getEstadoSubida(id).pipe(
      map((data:any)=>{

        let dataRet:DatosLogSubida;
        if(!data){
          dataRet={
            estado : "ERROR",
            Avance:"",
            errorMessage:"No se han recuperado datos de seguimiento"
          }

          return dataRet;
        }
        switch(data.Estado){
          case "ERROR":

            dataRet = {
              estado:data.Estado,
              errorMessage:data.Mensaje,
              Avance:''
            };

            return dataRet;
          case  "BLOQUEADO":

            dataRet = {
              estado:data.Estado,
              errorMessage: "Parece que el proceso no ha terminado correcamente en el servidor",
              Avance:''
            };

            return dataRet;
          case  "FINALIZADO":

            dataRet = {
              estado:data.Estado,
              Avance:'',
              errorMessage:''
            };


            return dataRet;
          case  "PROCESANDO":

            dataRet = {
              estado:data.Estado,
              Avance: data.Mensaje,
              errorMessage:''
            };

            return dataRet;
            default:

              dataRet = {
                estado:data.Estado,
                errorMessage: "Se ha producido un estado no esperado",
                Avance:""
              };

              return dataRet;
        }



      })
    );


  }

  public getSubidaPendiente():Observable<string>{
      return this.datosEmpleados.getSubidaPendiente().pipe(
      map((data:any)=>{
        return data.id
      }

      ),

      );

  }

  // Transformamos las keys del excel a las internas para subirlas a la API
  private buildEmpleadosSubida(_empleados: Empleado[]){
    _empleados.forEach(empleado => {
      for (const key in empleado) {
        let campoInterno = this.getCampoInterno(key);
        if(campoInterno != key)
        {
          empleado[campoInterno] = empleado[key];
          delete empleado[key];
        }
      }
    });
  }

  // Obtenemos la cabecera del excel de empleados un campo configurado
  private getTituloExcel(key: string){
    let result = '';
    let columnConfig = this.config.find(x => x.campoInterno.toUpperCase() == key.toUpperCase());
    if(columnConfig) {
      if (columnConfig.tituloExcelDefault) {
        result += columnConfig.tituloExcelDefault+';';
      }
      if (columnConfig.tituloExcel) {
        result += columnConfig.tituloExcel;
      }
    }
    return result;
  }

  // Obtenemos el campo interno del excel de empleados un campo configurado
  private getCampoInterno(key: string){
    return this.config.find(x => x.tituloExcelDefault?.toUpperCase() == key.toUpperCase() || x.tituloExcel?.toUpperCase().split(';').includes(key.toUpperCase()))?.campoInterno ?? '';
  }

  private getTitulosExcelCamposObligatorios():string[]{
    return [this.getTituloExcel('CODIGO'),this.getTituloExcel('NOMBRE'),this.getTituloExcel('NIF'),this.getTituloExcel('FECHA_ALTA'),this.getTituloExcel('MAIL_PERSONAL'),this.getTituloExcel('MAIL_PROFESIONAL'), this.getTituloExcel('EMPRESA_CIF'), this.getTituloExcel('EMPRESA')];
  }

}
