import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Firmas } from 'src/app/pages/panelControl/models/firmas';
import { ResumenEventos } from 'src/app/pages/panelControl/models/resumen-eventos';
import { DetallesEstado } from '../../models/detalles-estado';

@Component({
  selector: 'app-detalle-firmas-eventos',
  templateUrl: './detalle-firmas-eventos.component.html',
  styleUrls: ['./detalle-firmas-eventos.component.css']
})
export class DetalleFirmasEventosComponent implements OnInit {

  @Input() firma?: Firmas;
  panelOpenState: boolean = false;
  eventosAgrupados: any[] = [];
  firmanteEventos: boolean = false;
  @Input() mostrarDetalles: {count: number, abrir: boolean};
  @Output() switchEventosEmitter: EventEmitter<DetallesEstado> = new EventEmitter<DetallesEstado>();

  constructor() {   }

  ngOnChanges(changes: SimpleChanges){
    if(changes.mostrarDetalles){
      this.firmanteEventos = changes.mostrarDetalles.currentValue.abrir;
      this.switchEventosEmitter.emit({tipo: 'eventos', estado: this.firmanteEventos})
    }


  }

  ngOnInit(): void {
    this.getEventosAgrupados()

  }

  getDiaEvento(index: number){
    return this.eventosAgrupados[index].fecha;
  }

  getHoraEvento(fechaUTC: Date){
    let _fechaLocal = Date.UTC(fechaUTC.getFullYear(),fechaUTC.getMonth(), fechaUTC.getDate(), fechaUTC.getHours(), fechaUTC.getMinutes(), fechaUTC.getSeconds(), fechaUTC.getMilliseconds());
    let fechaLocal = new Date(_fechaLocal);
    return fechaLocal.toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' });
  }

  getVeces(evento: any) {
    return `${evento.veces} ${(evento.veces > 1) ? ' veces' : ' vez'}`;
  }

  getFirmante(evento: any){
    let res = this.firma?.documentos.find(x => x.documentosFases.find(y => y.email == evento.email));
    return `${res?.documentosFases[0]?.name} ${res?.documentosFases[0]?.apellido1 ?? ''} ${res?.documentosFases[0]?.apellido2 ?? ''}`;
  }

  getEventosAgrupados() {
    if(this.eventosAgrupados.length == 0)
    {
      // Obtenemos todas las fechas y filtramos los eventos

      let fechas = this.firma?.eventos.map(x => x.fecha.toLocaleDateString("en-GB")).filter((value, index, self) => self.indexOf(value) === index) ?? [];
      fechas.forEach((fecha: string, index) => {
        // Filtramos los eventos por fecha
        let eventos = this.firma?.eventos.filter(evento => evento.fecha.toLocaleDateString("en-GB") == fecha);
        var temp: any = {};
        // Agrupamos los eventos repetidos en un mismo día
        var result = eventos?.reduce(function(previousValue: any, currentValue: any) {
          var key: any = currentValue.descripcionEvento + '-' + currentValue.email;
          if(!temp[key]) {
            // Si el evento no se ha guardado lo añadimos
            temp[key] = Object.assign({}, currentValue);
            temp[key].veces = 1;
            previousValue.push(temp[key]);
          } else {
            // Si el evento ya está guardado actualizamos la fecha a la del último
            // temp[key].fecha = new Date(currentValue.fecha);
            // temp[key].veces++;
          }
          return previousValue;
        }, []);
        this.eventosAgrupados.push({
          fecha: fecha,
          eventos: result
        });
      });
    }
    return true;
  }

  mostrarEventos(){
    this.firmanteEventos = !this.firmanteEventos;
    this.switchEventosEmitter.emit({tipo: 'eventos', estado: this.firmanteEventos});
  }
}
