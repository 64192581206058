import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TipoFirmante } from '../../../../envios/models/tipo-firmante';

@Component({
  selector: 'app-campos-editables-add-campos',
  templateUrl: './campos-editables-add-campos.component.html',
  styleUrls: ['./campos-editables-add-campos.component.css']
})
export class CamposEditablesAddCamposComponent implements OnInit {

  @Input() firmante: any;
  @ViewChild('botonAnadir') botonAnadir: ElementRef;

  posicionBoton: { left: number, top: number, height: number, width: number };
  posicionCampo: {
    mostrar: boolean,
    left: number,
    top: number,
    firmante: TipoFirmante
  } = {mostrar: false, left:0, top:0, firmante:{codigo:'', desc:''}}

  campoMoverArray: any[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges){
    this.posicionCampo.firmante = changes.firmante?.currentValue.tipoFirmante;
  }

  ngOnInit(): void {
  }

  getPosicion(event:any){
    const {left, top, height, width} = this.botonAnadir.nativeElement.getBoundingClientRect();
    this.posicionBoton = {left, top, height, width};
    this.posicionCampo = {
      mostrar: true,
      left: this.posicionBoton.left,
      top: this.posicionBoton.top,
      firmante: this.posicionCampo.firmante
    }

    this.campoMoverArray.push(this.posicionCampo)

    //solo puede haber un elemento al momento de moverse. Si se ha creado uno y no se ha movido, lo borramos al crear el segundo
    if(this.campoMoverArray.length > 1){
    this.campoMoverArray.shift();
    }
  }

}
