import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-boton-accion-secundaria',
  templateUrl: './boton-accion-secundaria.component.html',
  styleUrls: ['./boton-accion-secundaria.component.css']
})
export class BotonAccionSecundariaComponent implements OnInit {

  @Input() icono: string = '';
  @Input() titulo: string = '';
  @Input() id: string = '';
  @Output() botonClicked: EventEmitter<string> = new EventEmitter<string>();
  @Input() disabled: boolean = false; // ojo en los modales

  constructor() { }

  ngOnInit(): void {
  }

  clicked(){
    if(!this.disabled) this.botonClicked.emit(this.id);
  }

}
