<h5>Dibujada - mediante tag</h5>
<!-- <p>Emplazamiento del campo de firma: mediante tag</p> -->

<p>Introduce el texto específico que el documento contiene en el lugar donde debe emplazarse la firma.</p>
<form [formGroup]="formFirma">
  <div class="flex-input">
    <label for="nombreTag" style="font-weight: 500;">Tag:</label>
    <input class="texto" type="text" formControlName="nombreTag" [ngClass]="formularioValidacionesDocsService.isCampoValidoArray('firmas', obtenerIndexNifArray()) ? 'errorInput':''  ">
  </div>

</form>
<div class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValidoArray('firmas', obtenerIndexNifArray())">
  {{formularioValidacionesDocsService.mensajeErrorNombreTag}}
</div>

