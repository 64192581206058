import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { EnviosPageComponent } from './envios/components/envios-page/envios-page.component';
import { EnviosDataGridComponent } from './envios/components/envios-data-grid/envios-data-grid.component';
import { EnviosBarraSuperiorComponent } from './envios/components/envios-barra-superior/envios-barra-superior.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { EnviosFilterDetailsComponent } from './envios/components/envios-filter-details/envios-filter-details.component';
import { EnviosFiltroSelectorComponent } from './envios/components/envios-filtro-selector/envios-filtro-selector.component';
import { TipoFirmantesComponent } from './envios/components/tipo-firmantes/tipo-firmantes.component';
import { DetalleFirmantesComponent } from './envios/components/detalle-firmantes/detalle-firmantes.component';
import { FirmantesComponent } from './envios/components/firmantes/firmantes.component';
import { ConfiguraEnvioPageComponent } from './envios/components/configura-envio-page/configura-envio-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DocumentoComponent } from './envios/components/documento/documento.component';
import { GrupoDocsComponent } from './envios/components/grupo-docs/grupo-docs.component';
import { EnviosConfirmacionFirmantesModalComponent } from './envios/components/envios-confirmacion-firmantes-modal/envios-confirmacion-firmantes-modal.component';
import { EnviosRecordatoriosComponent } from './envios/components/envios-recordatorios/envios-recordatorios.component';
import { EnviosFiltrosNuevosComponent } from './envios/components/envios-filtros-nuevos/envios-filtros-nuevos.component';
import { SqpComponentsModule } from 'src/app/shared/components/sqp-components/sqp-components.module';

@NgModule({
  declarations: [
    EnviosPageComponent,
    EnviosDataGridComponent,
    EnviosBarraSuperiorComponent,
    EnviosFilterDetailsComponent,
    EnviosFiltroSelectorComponent,
    TipoFirmantesComponent,
    DetalleFirmantesComponent,
    FirmantesComponent,
    ConfiguraEnvioPageComponent,
    DocumentoComponent,
    GrupoDocsComponent,
    EnviosConfirmacionFirmantesModalComponent,
    EnviosRecordatoriosComponent,
    EnviosFiltrosNuevosComponent
  ],

  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    ReactiveFormsModule,
    MatDialogModule,
    DragDropModule,
    SqpComponentsModule
  ],

  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
 ],
})
export class EnviosModule { }
