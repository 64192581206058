import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuarioEstado } from '../../models/usuario-model';
import { DatosUsuariosService } from '../../services/datos-usuarios.service';

@Component({
  selector: 'app-usuarios-filter-details',
  templateUrl: './usuarios-filter-details.component.html',
  styleUrls: ['./usuarios-filter-details.component.css']
})
export class UsuariosFilterDetailsComponent implements OnInit {

  usuarioEstados: UsuarioEstado[];
  // @ViewChild('estadoSel') estadoSel: ElementRef;
  @Output() estadoSeleccionado: EventEmitter<string> = new EventEmitter<string>();

  constructor(private datosUsuariosService: DatosUsuariosService,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getEstadosUsuario();
  }

  getEstadosUsuario(){
    this.datosUsuariosService.getEstadosUsuario().subscribe(
      next => {
        this.usuarioEstados = next;

        // this.filtros.push({ text: '',
        //                     textoBuscar: '',
        //                     value: ''
        //                     })
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  filterEstado(event: any){
    this.estadoSeleccionado.emit(event.target.value)
  }

}
