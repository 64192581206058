<div class="doc-info">
  <div class="flex-title">
    <div *ngIf="doc.fusionable == true" class="orden">{{index+1}}º</div>
    <svg class="icon-doc">
      <use xlink:href="#hs-doc"></use>
    </svg>
    <span class="doc-name">{{doc.nombre}}</span>
  </div>
  <div class="flex-icons">
    <svg class="icon-edit" (click)="editar()">
      <use xlink:href="#hs-edit"></use>
    </svg>
    <svg *ngIf="!doc.esRLT" class="icon-bin" (click)="clicked()">
      <use xlink:href="#hs-bin"></use>
    </svg>
  </div>


</div>
<!-- <div class="pastilla-doc" [ngClass]="doc.fusionable ? 'fusionable':''" matTooltip="{{getTooltip(doc.nombre)}}" [matTooltipPosition]="'above'">
  <div class="flex-icons">
    <svg class="icon-edit" (click)="editar()">
      <use xlink:href="#icon-edit"></use>
    </svg>
    <svg class="icon-close" (click)="clicked()">
      <use xlink:href="#icon-close"></use>
    </svg>
  </div>
  <span class="documento">
    <svg class="icon-doc">
      <use xlink:href="#icon-doc-fill"></use>
    </svg>
    <span *ngIf="doc.fusionable == true" class="orden">{{index+1}}º</span>
  </span>
  <p class="cut-text">{{doc.nombre}}</p>
</div> -->

<!-- <div class="pastilla-doc" [ngClass]="doc.fusionable ? 'fusionable':''" matTooltip="{{getTooltip(doc.nombre)}}" [matTooltipPosition]="'above'" > -->
  <!-- <div class="flex-icons">
    <svg class="icon-edit" (click)="editar()">
      <use xlink:href="#icon-edit"></use>
    </svg>
    <svg class="icon-close" (click)="clicked()">
      <use xlink:href="#icon-close"></use>
    </svg>
  </div> -->
  <!-- <svg class="icon-close" (click)="clicked()">
    <use xlink:href="#icon-close"></use>
  </svg>
    <span class="documento">
      <svg class="icon-doc">
        <use xlink:href="#icon-doc-fill"></use>
      </svg>
      <span *ngIf="doc.fusionable == true" class="orden">{{index+1}}º</span>
    </span>
    <p class="cut-text">{{doc.nombre}}</p>
  <span class="documento">
    <svg class="icon-doc">
      <use xlink:href="#icon-doc-fill"></use>
    </svg>
    <span *ngIf="doc.fusionable == true" class="orden">{{index+1}}º</span>
  </span>
  <p class="cut-text">{{doc.nombre}}</p>
</div> -->
