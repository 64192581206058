import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef } from 'ag-grid-community';
import { ConfirmationMessageComponent } from 'src/app/shared/components/modals/confirmation-message/confirmation-message.component';
import { DatosExcel } from 'src/app/shared/ExportadorFicheros/models/datos-excel';
import { DatosPdf } from 'src/app/shared/ExportadorFicheros/models/datos-pdf';
import { ExportadorExcelService } from 'src/app/shared/ExportadorFicheros/services/exportador-excel.service';
import { ExportadorPdfService } from 'src/app/shared/ExportadorFicheros/services/exportador-pdf.service';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';
import { FiltroBasico, Filtros } from 'src/app/shared/models/filtros';
import { EmpresasModel } from '../../models/empresas-model';
import { EmpresasBarraSuperiorComponent } from '../empresas-barra-superior/empresas-barra-superior.component';
import { NuevaEmpresaModalComponent } from '../nueva-empresa-modal/nueva-empresa-modal.component';
import { RecuperaEmpresasApiService } from '../../services/recupera-empresas-api.service';
import { DatosEmpresasService } from '../../services/datos-empresas.service';
import { GridColDefsEmpresas } from '../../models/grid-col-defs-empresas';
import { RecuperaEmpresasMockService } from '../../services/recupera-empresas-mock.service';
import { EmpresasModalComponent } from '../empresas-modal/empresas-modal.component';
import { SpinnerModalComponent } from 'src/app/shared/components/others/spinner-modal/spinner-modal.component';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';


@Component({
  selector: 'app-empresas-page',
  templateUrl: './empresas-page.component.html',
  styleUrls: ['./empresas-page.component.css']
})
export class EmpresasPageComponent implements OnInit {

  empresas: EmpresasModel[];
  filtros: FiltroBasico[] = [];
  @ViewChild(EmpresasBarraSuperiorComponent) barrSuperior!: EmpresasBarraSuperiorComponent;
  columnDefs: ColDef[] = new GridColDefsEmpresas().columnDefs;
  empresasSeleccionadas: EmpresasModel[] = [];
  textoBorrar: string = '';
  constructor(public datosEmpresasService: DatosEmpresasService,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog,
              private exportadorPdfService: ExportadorPdfService,
              private exportadorExcelService: ExportadorExcelService,
              private datosEmpresasApiService: RecuperaEmpresasApiService) { }

  ngOnInit() {
    this.getEmpresas();
  }

  getEmpresas(){
    // this.datosEmpresasService.getEmpresas().subscribe(
    //   next => {
    //     this.empresas = next;
    //     this.filtros.push({ text: '',
    //                         textoBuscar: '',
    //                         value: ''
    //                         })
    //   },
    //   error => {
    //     this._snackBar.open(error, 'Cerrar',{duration: 3000});
    //   }
    // )

    //llamamos directamente al servicio de la api, porque no reconoce getEmpresas() como función al pasar por la interface IRecuperarEmpresasService
    this.datosEmpresasApiService.getEmpresas().subscribe(
      next => {
        this.empresas = next;
        this.filtros.push({ text: '',
                            textoBuscar: '',
                            value: ''
                            })
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  refresh(){
    this.datosEmpresasApiService.refreshEmpresas = true;
    this.getEmpresas();
  }

  clickBarraSuperior(id: string){
    switch (id) {
      case 'Excel':
        this.exportaExcel();
        break;
      case "Pdf":
        this.exportaPdf();
        break;
      case id.match(/(Buscar:.*)/)?.input:
        let textoBuscar = id.replace('Buscar:','');
        this.buscarEmpresas(textoBuscar);
        break;
      case "Plus":
        this.creaNuevaEmpresa();
        break;
      default:
        break;
    }
  }

  creaNuevaEmpresa(){
    const dialogRef = this.dialog.open(NuevaEmpresaModalComponent, {
      data: {},
      panelClass: 'modal-class-detalle',
      position: { right: '0'}
    });

    dialogRef.afterClosed().subscribe(result=>{
      if(result === 'OK'){
        this.refresh();
      }
    })
  }

  exportaPdf(){
    // this.empresas = this.datosEmpresasService.empresas;
    this.empresas = this.datosEmpresasApiService.empresas
    if(this.empresas){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-pdf-empty',
          titulo: 'Generar PDF',
          mensaje: '<p>Genera un documento PDF con la información de los registros mostrados en el listado</p>',
          textoConfirmacion: 'Generar PDF',
          iconoConfirmacion: 'icon-pdf-empty',
          textoCancelar: 'cancelar'
        }
      });

      //montamos el objeto a pasar al servicio con el modelo DatosPdf
      const datos = this.montarParametrosPdf();

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK') this.exportadorPdfService.exportaPdf(datos, this.empresas)
      });
    }
  }

  montarParametrosPdf(): DatosPdf{
    let columns: any [] = [];
    this.columnDefs.forEach( x => {
      columns.push( {header: x.headerName, dataKey: x.field} )
    })
    let fecha = new Date();
    let datosPdf = {
      landscape: true,
      nombreFichero:`Hire&Sign_empresas_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.pdf`,
      registros: columns
    }
    return datosPdf
  }

  exportaExcel(){
    // this.empresas = this.datosEmpresasService.empresas;
    this.empresas = this.datosEmpresasApiService.empresas;
    if(this.empresas){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: 'icon-excel-empty',
          titulo: 'Generar excel',
          mensaje: '<p>Genera un documento Excel con la información de los registros mostrados en el listado</p>',
          textoConfirmacion: 'Generar excel',
          iconoConfirmacion: 'icon-excel-empty',
          textoCancelar: 'cancelar'
        }
      });

      let rows = this.GetRows(this.empresas);
      let datosExcel = this.montarParametrosExcel();

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK') this.exportadorExcelService.exportaExcel(datosExcel, rows);
      });
    }
  }

  montarParametrosExcel(): DatosExcel{
    let fecha = new Date();
    let datosExcel = {
      workSheetName:'Empresas',
      nombreFichero:`Hire&Sign_empresas_${fecha.getFullYear()}${fecha.getMonth()+1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}.xlsx`,
      columnas: this.GetColumns()
    }
    return datosExcel
  }

  //Generamos las columnas basandonos en las del grid
  private GetColumns(): any[]{
    let columns:any[] = [];
    this.columnDefs.forEach(x => {
        let element:any = {};
        element.name = x.headerName;
        element.filterButton = true;
        element.width = x.width;
        columns.push(element);
    });
    return columns;
  }

  // Mapeamos los datos de las empresas a las filas del excel
  private GetRows(data:EmpresasModel[]):any[]{
    let rows:any[] = [];
    data.forEach(x=>{
      let row:any[] =
      [
        x.codigoEmpresa,
        x.nombreEmpresa,
        x.cif,
        this.getNombreEmpleado(x),
        x.emailEmpleado
      ];
      rows.push(row);
    });
    return rows
  }

  getNombreEmpleado(empresa: EmpresasModel): string{
    let nombreFinal: string;
    if(empresa.nombreEmpleado && empresa.apellido1Empleado) nombreFinal = `${empresa.nombreEmpleado} ${empresa.apellido1Empleado}`;
    else if(empresa.nombreEmpleado && !empresa.apellido1Empleado) nombreFinal = `${empresa.nombreEmpleado}`;
    return nombreFinal
  }

  buscarEmpresas(textoBuscar:string){
    if(this.empresas){
      this.filtros[0].textoBuscar = textoBuscar;
      this.filtros[0].text = 'Texto';
      this.filtros[0].value = textoBuscar;
      this.datosEmpresasService.SetFilter(this.filtros);
      this.datosEmpresasService.Filter();
      this.empresas = this.datosEmpresasService.empresasFiltradas;
    }
  }

  quitarFiltro(filtro: FiltroBasico){
    this.datosEmpresasService.deleteFilter(filtro);
    this.datosEmpresasService.Filter();
    this.empresas = this.datosEmpresasService.empresasFiltradas;
    this.barrSuperior.buscarTexto('');
  }

  abreModal(empresa:any){
    let info: any = {};
    info.empresa = empresa.data;
    info.nuevo = false;
    const dialogRef = this.dialog.open(EmpresasModalComponent, {
      data: info,
      panelClass: 'modal-class-detalle',
      position: { right: '0'}
    });

    dialogRef.afterClosed().subscribe(result=>{
      if(result === 'OK'){
        this.refresh();
      }
    })
  }

  seleccionEmpresas(event: EmpresasModel[]){
    this.empresasSeleccionadas = event;
    if(this.empresasSeleccionadas.length > 1) this.textoBorrar = `${event.length} empresas seleccionadas`;
    else this.textoBorrar = `${event.length} empresa seleccionada`;
  }

  deseleccionar: {count: number, uncheck: boolean}
  num: number = 1;

  deseleccionarAll(){
    this.deseleccionar = {count: this.num++, uncheck: true}
  }

  eliminarEmpresa(){
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: {
        tipo: TipoMensajeConfirmacion.ConfirmacionCompleja,
        icono: 'hs-bin',
        titulo: this.empresasSeleccionadas.length>1? '¿Eliminar empresas?':'¿Eliminar empresa?',
        // preguntaConfirmacion: '¿Eliminar empresa?',
        textoConfirmacion: this.empresasSeleccionadas.length>1?'Eliminar las empresas seleccionadas':'Eliminar la empresa seleccionada',
        textoBoton: 'Eliminar',
        iconoConfirmacion: 'hs-bin'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'OK'){
        const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
        let count: number = 0;
        this.empresasSeleccionadas.forEach(x=>{
          this.datosEmpresasApiService.deleteEmpresa(x).subscribe(
            next => {
              count++;
              if(this.empresasSeleccionadas.length == count){
                dialogRefSpinner.close();
                const dialogRef = this.dialog.open(ModalMessageComponent, {
                  data: {
                    mensaje: this.empresasSeleccionadas.length>1?'Empresas eliminadas correctamente':'Empresa eliminada correctamente',
                  },
                  });
                  this.datosEmpresasApiService.refreshEmpresas = true;
                  this.refresh();
              }
            },
            error => {
              dialogRefSpinner.close();
              const dialogRef = this.dialog.open(ModalMessageComponent, {
                data: {
                  mensaje: 'Error. La empresa no se ha eliminado',
                  alert: true
                },
              });
            }
          );
        })

      }
    });

  }
}
