import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracion-documento-ayuda-subida',
  templateUrl: './configuracion-documento-ayuda-subida.component.html',
  styleUrls: ['./configuracion-documento-ayuda-subida.component.css']
})
export class ConfiguracionDocumentoAyudaSubidaComponent implements OnInit {

  text: string= 'Sube un ejemplo del documento a configurar'

  constructor() { }

  ngOnInit(): void {
  }

}
