<div class="flex">
  <span class="titulo">RLT</span>
  <span (click)="mostrarRlt()">
    <svg class="icon-arrow" [ngClass]="firmanteRlt ? 'icon-arrow-down':''">
      <use xlink:href="#hs-arrow"></use>
    </svg>
  </span>
</div>
<div *ngIf="firmanteRlt" class="rlt">
  <div>Estado: {{firma.estadoRlt ? 'Enviado':'Pendiente'}}</div>
  <div>Email: {{firma.emailRlt}}</div>
</div>
