import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tipo-firmantes',
  templateUrl: './tipo-firmantes.component.html',
  styleUrls: ['./tipo-firmantes.component.css']
})
export class TipoFirmantesComponent implements OnInit {

  anadirsAdd: boolean = true;
  @Input() codigo: string = "";
  @Input() descripcion: string = '';
  @Output() botonClicked: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }

  clicked(){
    let pastillaInfo = {
      codigo: this.codigo,
      desc: this.descripcion,
    }
    console.log('pastillaInfo: ', pastillaInfo)
    this.botonClicked.emit(pastillaInfo)
  }

}
