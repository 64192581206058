import { Inject, Injectable } from '@angular/core';
import { IRecuperaConfiguracionAplicacionService } from './ports/i-recupera-configuracion-aplicacion-service';
import { AbstractControlOptions, FormBuilder, FormGroup } from '@angular/forms';
import { ConfiguracionRlt } from '../models/configuracion-rlt';
import { Observable } from 'rxjs';
import { DatosEnviosService } from '../../Envios/envios/services/datos-envios.service';
import { TipoEnvio } from '../../Envios/envios/models/tipo-envio';
import { formRltValidators } from './custom-validators-rlt';

@Injectable({
  providedIn: 'root'
})
export class DatosConfiguracionAplicacionService {

  public configuracionRltForm: FormGroup = new FormGroup({});
  configuracionRlt: ConfiguracionRlt;
  nombreDosier: string = '';
  envio: TipoEnvio = {} as TipoEnvio;

  constructor(@Inject('RecuperaConfiguracionAplicacion') private datosConfiguracionAplicacion: IRecuperaConfiguracionAplicacionService,
              private fb: FormBuilder,
              private datosEnviosService: DatosEnviosService) {
                this.montarFormularioVacio();
              }

  montarFormularioVacio(){
    this.configuracionRltForm = this.fb.group({
      activo:[],
      dosier:[],
      periodicidad:[],
      diaEnvio:[],
      horaEnvio:[]
    })
  }

  iniciaConfiguracionRltForm(){
    this.configuracionRltForm = this.fb.group({
      activo:[this.configuracionRlt.activo],
      dosier:[this.nombreDosier],
      periodicidad:[this.configuracionRlt.periodicidad],
      diaEnvio:[this.configuracionRlt.diaEnvio],
      horaEnvio:[this.configuracionRlt.hora]
      // horaEnvio:[this.horaString(this.configuracionRlt.hora)]
    },{ validator: [formRltValidators(this.datosEnviosService.enviosFiltrados)]} as AbstractControlOptions)

  }
  horaString(date:Date):string{

    let nuevaDate = new Date(2022, 1, 1, 12, 0);

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');


    return `${hoursString}:${minutesString}`;
  }
  guardaConfiguracionRlt(objetoConfiguracionRlt: ConfiguracionRlt): Observable<void>{
    return this.datosConfiguracionAplicacion.guardaConfiguracionRlt(objetoConfiguracionRlt);
  }

  getConfiguracionRlt(){
    return this.datosConfiguracionAplicacion.getConfiguracionRlt().subscribe(
      response => {

        this.configuracionRlt = response;
        this.datosEnviosService.enviosFiltrados.forEach(x=>{
          if(x.idTipoEnvio == this.configuracionRlt.idTipoDosier.toString()){
            this.nombreDosier = x.nombre;
            this.envio = x;
          }
        })
      },
      error => {
        console.error();
      },
      ()=>{
        this.iniciaConfiguracionRltForm();
      }
    );
  }

}
