import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TiposContenido, TiposContenidoSearchResponse } from '../models/tipos-contenido';
import { IDatosConfiguracionService } from './ports/i-datos-configuracion-service';

@Injectable({
  providedIn: 'root'
})
export class RecuperaTiposContenidoMockService implements IDatosConfiguracionService{

  constructor(private http: HttpClient) { }

  getTiposContenido(): Observable<TiposContenido[]> {

    const headers = new HttpHeaders()
    .set('content-type', 'application/json')

    return this.http.get<TiposContenidoSearchResponse>(`${environment.ApiTiposContenidoMock}`, {headers})
      .pipe(
        map(resp => {

            let tiposContenido: TiposContenido[] = resp.datos;
            return tiposContenido;
          }
        )
    );
  }
}
