import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DocumentoFase } from 'src/app/pages/panelControl/models/documento-fase';
import { Firmas } from 'src/app/pages/panelControl/models/firmas';
import { nullFirma } from '../../models/firmas';
import { DetallesEstado } from '../../models/detalles-estado';

@Component({
  selector: 'app-detalle-firma-firmantes',
  templateUrl: './detalle-firma-firmantes.component.html',
  styleUrls: ['./detalle-firma-firmantes.component.css']
})
export class DetalleFirmaFirmantesComponent implements OnInit {

  @Input() firma: Firmas = nullFirma();
  panelOpenState: boolean = false;
  firmantes: DocumentoFase[] = [];
  @Input() mostrarDetalles: {count: number, abrir: boolean}
  firmanteInfo: boolean = false;
  @Output() switchFirmantesEmitter: EventEmitter<DetallesEstado> = new EventEmitter<DetallesEstado>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges){
    if(changes.mostrarDetalles){
      this.firmanteInfo = changes.mostrarDetalles.currentValue.abrir;
      this.switchFirmantesEmitter.emit({tipo: 'firmantes', estado: this.firmanteInfo});
    }
  }

  ngOnInit(): void {
    this.firmantesCargados();
  }

  firmantesCargados(){
    if(this.firmantes.length == 0)
    {
      if(this.firma?.documentos.length == 0) return false;
      else{
        this.firma?.documentos.forEach(x => {
          x.documentosFases = x.documentosFases.sort((a, b) => a.tipoFirmante > b.tipoFirmante ? 1 : a.tipoFirmante === b.tipoFirmante ? 0 : -1);
          x.documentosFases.forEach(y => {
            if(this.firmantes.find(z => z.email == y.email) == undefined) this.firmantes.push(y);
          })
        });
      }
    }
    return true;
  }

  mostrarFirmantes(){
    this.firmanteInfo = !this.firmanteInfo;
    this.switchFirmantesEmitter.emit({tipo: 'firmantes', estado: this.firmanteInfo});
  }

}
