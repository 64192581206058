import { Component, EventEmitter, Output } from '@angular/core';
import { TooltipMessages } from 'src/app/shared/models/tooltip.mesages';

@Component({
  selector: 'app-barra-descargas',
  templateUrl: './barra-descargas.component.html',
  styleUrls: ['./barra-descargas.component.css']
})

export class BarraDescargasComponent {

  @Output() botonClicked: EventEmitter<string> = new EventEmitter<string>();

  getTooltip(accion: string){
    if (accion.toUpperCase() == "DESCARGAR") return TooltipMessages.superior_descarga;
    else if (accion.toUpperCase() == "PDF") return TooltipMessages.superior_pdf;
    else if (accion.toUpperCase() == "EXCEL") return TooltipMessages.superior_excel;
    else if (accion.toUpperCase() == "ELIMINAR") return TooltipMessages.superior_eliminar;
    // else if (accion.toUpperCase() == "PLUS") return TooltipMessages.superior_envionuevo;
    // else if (accion.toUpperCase() == "FILTRO") return TooltipMessages.superior_filtro;
    // else if (accion.toUpperCase() == "BUSCAR") return TooltipMessages.superior_busqueda;
    else return '';
  }

  botonAccionClicked(id: string) {
    this.botonClicked.emit(id);
  }

}
