export class DatosProcesoServidor{

    dataLogSubida:any[]=[];
    codigoSubida:number = 0;
    total:number = 0;
    procesados:number=0;
    avanceProceso:number = 0;
    avanceAnterior:number=0;
    fechaUltimoAvance:Date = new Date();
    sinConexion:boolean;
    

}