import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { Token } from '@angular/compiler';
import { TenanData } from '../../../models/tenant_data';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatosCentrosService } from 'src/app/pages/configuracion/maestros/centros/services/datos-centros.service';
import { DatosDocumentosService } from 'src/app/pages/configuracion/Envios/documentos/services/datos-documentos.service';
import { DatosEnviosService } from 'src/app/pages/configuracion/Envios/envios/services/datos-envios.service';

@Component({
  selector: 'app-selector-tenants',
  templateUrl: './selector-tenants.component.html',
  styleUrls: ['./selector-tenants.component.css']
})
export class SelectorTenantsComponent implements OnInit {

 public tenants:TenanData[] =[{Name:'',Tenant :''}];
 private token:string ='';
 public tenantsInicio: TenanData[] = [];

  constructor(private route: ActivatedRoute,private snack:MatSnackBar,
      private loginService:LoginService,
      private datosFirmasService: DatosFirmaService,
      private router: Router) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');

    let tokenINfo = this.loginService.DecodeTokenLogin(this.token);

    this.tenants = JSON.parse(tokenINfo.Tenant);

    this.tenantsInicio = [...this.tenants];


  }
  Ingresar(event:any){
    const selectTenant = event.Tenant
    const tenant = this.tenants.find(x=>x.Tenant==selectTenant);
    this.loginService.setTenantToken(event, this.token)
    this.loginService.LoginAzureAd(tenant,this.token,this.tenants).subscribe(
      ok => {

        this.datosFirmasService.GetFirmas().subscribe(
          ok => {

            // Redirigir al menu principal

            // this.datosFirmasService.Filter();
            this.datosFirmasService.filtrarNuevo(this.datosFirmasService.filtrosConEstado);
          },
          error => {
            console.error(error);
          });

          //MIentras recuperamos las firmas vamos al menu principal.
          this.router.navigate(["/panel/main"]);
      },
      error =>{
        // Mostrar mensaje de error
        console.error(error);
        let errorMsg = (error.status == 401) ? 'Usuario y/o contraseña no válidos' : error.message;

        this.snack.open(errorMsg, 'Cerrar', {duration: 3000});
      });
  }

  filtrarTenant(event:any){
    this.tenants = this.tenantsInicio.filter(x=> x.Name.toString().toUpperCase().includes(event.toString().toUpperCase()))
  }
}
