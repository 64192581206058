<div class="container-docs">
  <div class="container-fusionados">
    <h3>Fusionados</h3>
    <div class="flex list"
        cdkDropList
        #totalDocsFusionList="cdkDropList"
        [cdkDropListData]="totalDocsFusion"
        [cdkDropListConnectedTo]="[totalDocsNoFusionList]"
        (cdkDropListDropped)="drop($event)">
        <app-documento *ngFor="let doc of totalDocsFusion; let i = index"
                        [doc]="doc"
                        [index]="i"
                        (eliminarClicked)="eliminarDoc($event)"
                        class="box"
                        cdkDrag></app-documento>
    </div>
    <span class="texto-validacion" *ngIf="formularioValidacionesService.isCampoValido('tiposDocumento')">
      {{formularioValidacionesService.mensajeErrorDocumentos}}
    </span>
  </div>
  <div class="container-no-fusionados">
    <h3>No fusionados</h3>
    <div class="flex list"
        cdkDropList
        #totalDocsNoFusionList="cdkDropList"
        [cdkDropListData]="totalDocsNoFusion"
        [cdkDropListConnectedTo]="[totalDocsFusionList]"
        (cdkDropListDropped)="drop($event)">
          <app-documento  *ngFor="let doc of totalDocsNoFusion; let i = index"
                          [doc]="doc"
                          [index]="i"
                          (eliminarClicked)="eliminarDoc($event)"
                          class="box"
                          cdkDrag></app-documento>
    </div>
  </div>
</div>

