export const TooltipMessages = {
    lanzadera_estandar: 'Dirigido solo a los empleados',
    lanzadera_universal:  'Dirigido a empleados y a personas ajenas a la empresa. Consta de documentos sin datos personales',
    superior_descarga: 'Descarga documentos firmados y certificados de firma',
    superior_pdf: 'Descarga tabla en formato .pdf',
    superior_excel: 'Descarga tabla en formato excel',
    superior_eliminar: 'Elimina envíos con estado erróneo',
    superior_envionuevo: 'Nuevo envío',
    superior_filtro: 'Filtrar datos mostrados',
    superior_busqueda: 'Búsqueda de envíos por palabras clave',
    envio_borradores: 'Envíos pendientes de configuración',
    envio_firmados: 'Envíos firmados y completados',
    envio_parc_firmados: 'Existen documentos firmados y otros pendientes de firma, caducados o rechazados',
    envio_rechazados: 'La firma ha sido declinada por el firmante',
    envio_por_firmar: 'Todos los documentos pendientes de firma',
    envio_caducados: 'El documento ha expirado sin ser firmado',
    envio_errores: 'Envio no completado, se ha registrado algún error durante el envío',
    envio_cancelados: 'El envío ha sido cancelado por el usuario',
    envio_nuevos: 'Envíos realizados desde la última vez que entraste',
    filtro_tipoDosier: 'Tipo dosier',
    filtro_empresa: 'Empresa',
    filtro_centro: 'Centro'
}
