import {  AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TiposConfiguracion } from 'src/environments/Configuration/TiposConfiguracion';
import { environment } from 'src/environments/environment';
import { Firmantes } from '../../models/firmantes';
import { TipoBusqueda } from '../../models/tipo-busqueda';
import { TipoDestinatario } from '../../models/tipo-destinatario';
import { TipoFirmante } from '../../models/tipo-firmante';
import { DatosEnviosService } from '../../services/datos-envios.service';
import { FormularioEnvioService } from '../../services/formulario-envio.service';
import { FormularioValidacionesService } from '../../services/formulario-validaciones.service';

@Component({
  selector: 'app-detalle-firmantes',
  templateUrl: './detalle-firmantes.component.html',
  styleUrls: ['./detalle-firmantes.component.css']
})
export class DetalleFirmantesComponent implements OnInit{

  @Input() firmante: Firmantes;
  @Input() inputOtro: boolean;
  @Input() i: number;

  otro: boolean = false;

  @Input() destinatarios: TipoDestinatario[] = [];
  @Input() busquedas: TipoBusqueda[] = [];
  descDestinatario: string = '';
  descBusqueda: string = '';
  firmanteForm: FormGroup = new FormGroup({});
  // personificableCodigo: string = '103';
  // personificableDesc: string = 'Otro (personificable)';
  firmantePrincipalCodigo: string = '100';
  mostrarFirmantePpal: boolean = true;
  destinatarioFirmanteCodigo: string ='';

  @Output() eliminarClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() resetBusqueda: boolean;

  constructor(private fb: FormBuilder,
              public formularioEnvioService: FormularioEnvioService,
              public formularioValidacionesService: FormularioValidacionesService) {
              }

  ngOnChanges(changes: SimpleChanges){
    if(changes.resetBusqueda?.currentValue){
      this.busqueda.setValue("");
    }
  }

  ngOnInit(): void {
    this.inicializaFormulario();
    if(environment.Configuracion == TiposConfiguracion.V3) this.destinatarioFirmanteCodigo = 'signer';
    else this.destinatarioFirmanteCodigo = '201';
  }

  get esRLT(){ return this.formularioEnvioService.formularioEnvio.controls['esRLT']}

  inicializaFormulario(){
    let valorTipoFirmante = this.firmante.tipoFirmante.desc;
    // if(this.firmante.tipoFirmante.codigo === this.personificableCodigo && this.firmante.tipoFirmante.desc !== this.personificableDesc){
    //   this.otro = true;
    //   this.inputOtro = false;
    // }else if(this.firmante.tipoFirmante.codigo === this.personificableCodigo && this.firmante.tipoFirmante.desc === this.personificableDesc){
    //   valorTipoFirmante = '';
    //   this.inputOtro = true;
    // }else{
    //   this.inputOtro = false;
    // }
    if(this.firmante.tipoFirmante.codigo !== '100'){
      this.mostrarFirmantePpal = false;
    }
    this.firmanteForm = this.fb.group({
      tipoFirmanteInput: [valorTipoFirmante, Validators.required],
      destinatario:[this.firmante.tipoDestinatario.codigo, Validators.required],
      busqueda: [this.firmante.tipoBusqueda.codigo, Validators.required]
    })
  }


  get destinatario(){
    return this.firmanteForm.get('destinatario')
  }

  get busqueda(){
    return this.firmanteForm.get('busqueda')
  }

  get tipoFirmanteInput(){
    return this.firmanteForm.get('tipoFirmanteInput')
  }

  // mostrarOtro(){
    // this.inputOtro = false
    // this.otro = true
    // if(this.firmanteForm.controls['tipoFirmanteInput'].value == ''){
    // this.firmanteForm.controls['tipoFirmanteInput'].setValue('Otro')
    // }
    // if(this.firmanteForm.invalid){
    //   return
    // }
  // }

  // edit(){
  //   this.inputOtro = true
  //   this.otro = false
  // }

  changeValue(e: any,value:any) {
    value?.setValue(e.target.value, {
      onlySelf: true,
    });
    this.cambioLista();
  }

  cambioLista(){
    this.buscaDescripciones();
    let firmanteActualizado: Firmantes = {
      orden: this.firmante.orden,
      tipoFirmante:{
        codigo: this.firmante.tipoFirmante.codigo, desc: this.tipoFirmanteInput?.value},
      tipoDestinatario:{
        codigo: this.destinatario?.value, desc: this.descDestinatario },
      tipoBusqueda:{
        codigo: this.busqueda?.value, desc: this.descBusqueda}
      }
      this.formularioEnvioService.updateFirmantes(firmanteActualizado)
  }

  buscaDescripciones(){
    this.destinatarios.forEach(x => {
      if(x.codigo == this.destinatario?.value){
        this.descDestinatario = x.desc
      }
    })
    this.busquedas.find(x => {
      if(x.codigo == this.busqueda?.value){
        this.descBusqueda = x.desc
      }
    })
  }

  clicked(){
    this.eliminarClicked.emit(this.firmante.orden)
  }

}
