import { Injectable } from '@angular/core';
import { ResumenFirmas } from 'src/app/pages/panelControl/models/resumen-firmas';

@Injectable({
  providedIn: 'root'
})
export class ModificarArraysService {

  constructor() { }

  swapElements(array: ResumenFirmas[], index1: number, index2: number) {
    if (index1 < 0 || index1 >= array.length || index2 < 0 || index2 >= array.length) {
      return;
    }
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
  }
}
