import { ColDef } from "ag-grid-community";

export class GridColDefsCentros{
  columnDefs: ColDef[] = [
    { headerName:"Nombre del centro", field: "nombreCentro", sortable:true, resizable:true, headerClass:"header", width: 500, headerCheckboxSelection: true, checkboxSelection: true},
    { headerName:"E-mail", field: "emailCentro", sortable:true, resizable:true, headerClass:"header", width: 400},
    { headerName:"Código de centro", field: "codigoCentro", sortable:true, resizable:true, headerClass:"header", width: 300,
    valueFormatter: (params) => {
      // Format numbers to include leading zeros for proper sorting
      return params.value.toLocaleString(undefined, {minimumIntegerDigits: 10});
    },
    comparator: (valueA, valueB) => {
      // Convert the formatted numbers back to compare for sorting
      return Number(valueA) - Number(valueB);
    }
    },
    { headerName:"Código de empresa", field: "codigoEmpresa", sortable:true, resizable:true, headerClass:"header", width: 300,
    valueFormatter: (params) => {
      return params.value.toLocaleString(undefined, {minimumIntegerDigits: 10});
    },
    comparator: (valueA, valueB) => {
      return Number(valueA) - Number(valueB);
    }
     },
    { headerName:"Empresa", field: "nombreEmpresa", sortable:true, resizable:true, headerClass:"header", width: 400 },
    { headerName:"Nombre Representante Legal", field: "nombreRlt", sortable:true, resizable:true, headerClass:"header", width: 400 },
    { headerName:"DNI Representante Legal", field: "dniRlt", sortable:true, resizable:true, headerClass:"header", width: 300 }
  ];
}
