import { Injectable } from "@angular/core";
import { LoginData } from "src/app/pages/login/models/login-data";
import { LoginService } from "src/app/pages/login/services/login.service";
import { EndpointConfigV2 } from "src/environments/Configuration/EnpointConfig.v2";
import { EndpointConfigV3 } from "src/environments/Configuration/EnpointConfig.v3";
import { environment } from "src/environments/environment";
import { InfoApi } from "src/environments/ModelosApis/InfoApi";
import { IListaEndpointsService } from "./Ports/i-lista-endpoints.service";

@Injectable({
    providedIn: 'root'
  })

  export class ListaEndpointsV3Service implements IListaEndpointsService{
    constructor(){

    }
    public get Endpoints(): InfoApi[]{
      let endopintsBD = this.getUserLocalStorage();
      
      if(!endopintsBD){
        return this.datosInciales();
      }
      let resultado:InfoApi[] =[];
      endopintsBD.Endpoints.forEach(x=>{
      
        let data = new InfoApi();
        data.baseUrl=x.BaseUrlDefault ;
        data.nombre=x.Endpoint.Nombre;
        data.version=x.Version;
      
        data.endpointV2 = x.Endpoint.EndpointValue
        data.endpointV3 = x.Endpoint.EndpointValue
        resultado.push(data);
        
        
      });

      return resultado;
    }
    private datosInciales():InfoApi[]{
      EndpointConfigV3.forEach(x=>x.baseUrl=environment.ApiUrlV3);
      
        return EndpointConfigV3;
    }
    private getUserLocalStorage(): LoginData{
      let loginData: LoginData;
      let loginDataString = localStorage.getItem('SQPFIRMA_login');
   
      if(loginDataString) {
         loginData = JSON.parse(loginDataString);
         
      }
   
      return loginData;
    }
  
  }
