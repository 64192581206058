import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UsuariActivo, UsuarioEstado, UsuarioModel, UsuarioPermisos, UsuarioTipo } from '../models/usuario-model';
import { IRecuperarUsuariosService } from './ports/i-recuperar-usuarios-service';

@Injectable({
  providedIn: 'root'
})
export class RecuperaUsuariosMockService implements IRecuperarUsuariosService{

  constructor(private http: HttpClient) { }
  deleteUsuario(data: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  resetPasswordAzureAd(): Observable<void> {
    throw new Error('Method not implemented.');
  }

  activaUsuario(emailUsuario: string): Observable<void> {
    throw new Error('Method not implemented.');
  }

  desactivaUsuario(emailUsuario: string): Observable<void> {
    throw new Error('Method not implemented.');
  }

  guardaUser(data: UsuarioModel): Observable<void> {
    throw new Error('Method not implemented.');
  }

  invitaUsuario(data: any): Observable<string> {
    throw new Error('Method not implemented.');
  }
  guardaUsuarioActivo(data: UsuariActivo): Observable<void> {
    throw new Error('Method not implemented.');
  }

  getEstadosUsuario(): Observable<UsuarioEstado[]> {
        return this.http.get(environment.ApiUsuariosEstadoMock)
        .pipe(
          map((response:any) => {
            let usuarioEstados: UsuarioEstado[] = response;
              return usuarioEstados;
            }
          )
      );
  }

  getUsuarios(): Observable<UsuarioModel[]> {
      return this.http.get(environment.ApiUsuariosMock)
        .pipe(
          map((response:any) => {
            let usuarios: UsuarioModel[] = response;
              return usuarios;
            }
          )
      );
  }

  getUsuariosTipo(): Observable<UsuarioTipo[]>{
    return this.http.get(environment.ApiUsuariosTipoMock)
    .pipe(
      map((response:any) => {
        let usuariosTipo: UsuarioTipo[] = response;
          return usuariosTipo;
        }
      )
    );
  }

  getUsuarioSeguridad(): Observable<UsuarioEstado[]>{
    return this.http.get(environment.ApiUsuariosSeguridadMock)
    .pipe(
      map((response:any) => {
        let usuariosSeguridad: UsuarioEstado[] = response;
          return usuariosSeguridad;
        }
      )
    );
  }

  getUsuariosPermisos(): Observable<UsuarioPermisos[]>{
    return this.http.get(environment.ApiUsuariosPermisosMock)
      .pipe(
        map((response:any) => {
          let permisosUsuarios = response;
          return permisosUsuarios;
        })
      )
  }

  reenviarInvitacion(data: UsuarioModel): Observable<void> {
    throw new Error('Method not implemented.');
  }
}
