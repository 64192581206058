import { ColDef } from "ag-grid-community";

export class SqpGridColDefs{

  sqpColumnDefs: ColDef[] = [
    {headerName:"", field: "", sortable:true, resizable:true, headerClass:"header", width: 100, cellClass: "hoverCell",
      cellRenderer: params => {
        let style: string;
        style='width: 15px; height: 15px; color: var(--hs-grey2);'
        return `<span class="hoverEdit"><svg style="${style}"><use xlink:href="#hs-edit"></use></svg></span>`
      }
    }
  ];
}
