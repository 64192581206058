<div class="switch-layout">
    <div  class="main-title-right-new"
          [ngClass]="active ? 'main-title-right-active' :''">
        <svg [ngClass]="{'icon':true, 'hide':!iconoOpcion1}" >
            <use [attr.xlink:href]="iconoOpcion1|iconoRef"></use>
          </svg>
        <span [ngClass]="active ? 'main-title-right-active' :''">{{textoOpcion1}}</span>
    </div>


    <label class="buttonSwitch">
        <input type="checkbox" (click)="click()" [checked]="active">
        <span class="slider" [ngClass]="{'slider': true, 'slider-sandbox': textoOpcion2=='Sandbox' || textoOpcion1=='SANDBOX'}" ></span>
    </label>
    <div class="main-title-right-new"
         [ngClass]="active ? 'main-title-right-active' :''">
        <svg [ngClass]="{'icon':true, 'hide':!iconoOpcion2}" >
            <use [attr.xlink:href]="iconoOpcion2|iconoRef"></use>
          </svg>
        <span [ngClass]="{'main-title-right-active': active, 'main-title-right-active-background': active && backgroundActive}">{{textoOpcion2}}</span>


    </div>

</div>
<!-- <div class="switch-layout">
    <span class="label"><img *ngIf="iconoOpcion1" [src]="iconoOpcion1">{{textoOpcion1}}</span>
    <div [ngClass]="this.getClasss()" (click)="click()">
        <div class="palanca"></div>
    </div>
    <span class="label"><img *ngIf="iconoOpcion2" [src]="iconoOpcion2">{{textoOpcion2}}</span>
</div> -->
