import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { EmpleadoCrud } from "../../models/empleado";

export function validacionCodigo(empleados: EmpleadoCrud[], empleado: EmpleadoCrud): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    if (value == '' || value == null) {
        return {codigoVacio: true};
    }else{
      let codigoExiste = empleados.findIndex(x => x.codigo.toUpperCase() == control.value.toUpperCase())

      if(codigoExiste > -1){
        let mismoNif = empleados.findIndex(x=> x.nif == empleado.nif)
        if(mismoNif > -1 && codigoExiste == mismoNif){
          return null
        }else{
          return {codigoExiste: true}
        }
      }else{
        return null
      }
    }
  }
}

export function formEmpleadoValidators(empleados: EmpleadoCrud[], empleado: EmpleadoCrud): ValidatorFn {
  return (form:AbstractControl) : ValidationErrors | null => {
      const nif = form.get('nif').value;
      const empresa = form.get('empresa').value;
      const _codigoEmpresa = empresa?.split('-')[0];
      const codigo = form.get('codigo').value;

      let nifExiste = empleados.findIndex(x => x.nif.toUpperCase() == nif?.toUpperCase());

      // if(codigo!=='' &&
      //    codigo==empleado.codigo &&
      //    nifExiste>-1 &&
      //    nif!==empleado.nif &&
      //    _codigoEmpresa==empleados[nifExiste].codigoEmpresa) return {empleadoExiste: true};
      // else if(codigo == '' &&
      //         nifExiste>-1 &&
      //         _codigoEmpresa==empleados[nifExiste].codigoEmpresa) return {empleadoExiste: true};
      // else return null


      if(codigo==empleado.codigo && codigo!==''){
        if(nifExiste>-1 && nif!==empleado.nif){
          if(_codigoEmpresa==empleados[nifExiste].codigoEmpresa) return {empleadoExiste: true};
          else return null
        }else return null
      }else if(codigo == '' && nifExiste>-1){
        if(_codigoEmpresa==empleados[nifExiste].codigoEmpresa) return {empleadoExiste: true};
        else return null
      }else return null

    }
}
