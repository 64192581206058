<form class="" [formGroup]="formCentro">
  <div class="modal-container">
    <svg class="icon-close" mat-dialog-close>
      <use xlink:href="#icon-close"></use>
    </svg>
    <h1>{{tituloModal}}</h1>
    <div class="flex-container">
      <div class="modal-content">
        <h6>Datos identificativos</h6>
        <span class="input-text-custom sin-margen">
          <label for="nombreCentro">Nombre del centro</label>
          <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{nombreCentro}}</div>
          <input *ngIf="isEditar || nuevo"
                  type="text"
                  id="nombreCentro"
                  name="nombreCentro"
                  formControlName="nombreCentro"
                  [ngClass]="{'errorInput': isCampoValido('nombreCentro')}"
                />
          <span class="texto-validacion"
                  *ngIf="isCampoValido('nombreCentro')">
                  El nombre del centro es requerido.
          </span>
        </span>
        <span class="input-text-custom" >
          <label for="codigoCentro">Código del centro</label>
          <div *ngIf="isEditar || !nuevo" class="infoCentro">{{codigoCentro}}</div>
          <input *ngIf="nuevo" type="text"
                  id="codigoCentro"
                  name="codigoCentro"
                  formControlName="codigoCentro"
                  [ngClass]="{'errorInput': isCampoValido('codigoCentro')}" (change)="validateCodigoCentro($event)" />
          <span class="texto-validacion" *ngIf="controlCodigoCentro.errors?.codigoVacio && controlCodigoCentro.touched">El código del centro es requerido.</span>
          <span class="texto-validacion" *ngIf="controlCodigoCentro.errors?.codigoExiste && controlCodigoCentro.touched">El código de centro ya existe para la empresa seleccionada.</span>
          <span class="texto-validacion" *ngIf="controlCodigoCentro.errors?.codigoLargo && controlCodigoCentro.touched">El código del centro debe contener como máximo 25 caracteres.</span>
        </span>
        <span class="input-text-custom">
          <label for="codigoEmpresa">Empresa</label>
          <div *ngIf="isEditar || !nuevo" class="infoCentro">{{info.centro.nombreEmpresa}}</div>
          <select *ngIf="nuevo" id="codigoEmpresa" name="codigoEmpresa" formControlName="codigoEmpresa" (change)="validateCodigoCentro($event)" >
            <option disabled selected value> -- seleccionar una empresa -- </option>
            <option *ngFor="let empresa of info.empresas" [value]="empresa.codigoEmpresa">{{empresa.nombreEmpresa}}</option>
          </select>
          <!-- <input *ngIf="nuevo"
                  type="text"
                  id="nombreEmpresa"
                  name="nombreEmpresa"
                  formControlName="nombreEmpresa"/> -->
        </span>
        <div *ngIf="moduloRltActivo" id="datosRlt">
          <h6>Representante legal de trabajadores (RLT)</h6>
          <span class="input-text-custom sin-margen">
            <label for="nombreRlt">Nombre</label>
            <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{nombreRlt}}</div>
            <input *ngIf="isEditar || nuevo"
                    type="text"
                    id="nombreRlt"
                    name="nombreRlt"
                    formControlName="nombreRlt">
          </span>
          <span class="input-text-custom">
            <label for="emailCentro">E-mail</label>
            <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{emailCentro}}</div>
            <input *ngIf="isEditar || nuevo"
                    type="text"
                    id="emailCentro"
                    name="emailCentro"
                    formControlName="emailCentro">
          </span>
          <span class="input-text-custom">
            <label for="dniRlt">DNI</label>
            <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{dniRlt}}</div>
            <input *ngIf="isEditar || nuevo"
                    type="text"
                    id="dniRlt"
                    name="dniRlt"
                    formControlName="dniRlt">
          </span>
        </div>
      </div>
      <div class="modal-buttons">
        <!-- <app-boton-accion
                        *ngIf="!isEditar && !nuevo"
                        (botonClicked)="eliminarCentro()"
                        [icono]="'hs-bin'"
                        [titulo]="'Eliminar'"
                        [id]="'Eliminar'"
                        [outline]="true"
                        [cancelar]="true">
        </app-boton-accion> -->
        <app-boton-accion mat-dialog-close
                        *ngIf="isEditar || nuevo"
                        [icono]="'icon-close'"
                        [titulo]="'Cancelar'"
                        [id]="'Cancelar'"
                        [outline]="true"
                        [cancelar]="true">
        </app-boton-accion>
        <app-boton-accion
                        *ngIf="!isEditar && !nuevo"
                        (botonClicked)="editarCentro()"
                        [icono]="'hs-edit'"
                        [titulo]="'Editar'"
                        [id]="'Editar'">
        </app-boton-accion>
        <app-boton-accion
                        *ngIf="isEditar || nuevo"
                        (botonClicked)="guardarCambiosCentro()"
                        [icono]="'hs-guardar'"
                        [titulo]="'Guardar'"
                        [id]="'Guardar'">
        </app-boton-accion>
      </div>
    </div>

  </div>
</form>


