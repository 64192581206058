import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatosSubidaService } from 'src/app/pages/subidaPrincipal/services/datos-subida.service';


@Component({
  selector: 'app-avance-subida',
  templateUrl: './avance-subida.component.html',
  styleUrls: ['./avance-subida.component.css']
})
export class AvanceSubidaComponent implements OnInit {

  @Output()linkClicked:EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(public datosSubidaService:DatosSubidaService) { }

  ngOnInit(): void {
  }

  get avance(){
    return `${this.datosSubidaService.datosProcesoServidor.avanceProceso.toFixed(2)}%`
  }
  get mensajeCompletado(){
    return 'Completada'

  }

  get mensajeProcesando(){
    return `Procesando ${this.datosSubidaService.datosProcesoServidor.procesados} de ${this.datosSubidaService.datosProcesoServidor.total }`

  }
  iraDetalle(){
    this.linkClicked.emit(true);
  }

}
