<div class="content" [ngClass]="firmante.seleccionado ? 'seleccionado' : ''" >
  <div class="flex" (click)="mostrarEstado(firmante)">
    <!-- <svg class="icon-user">
      <use xlink:href="#icon-user"></use>
    </svg> -->
    <h6>{{firmante.tipoFirmante.desc}}</h6>
    <svg class="icon-arrow" [ngClass]="firmante.seleccionado ? 'icon-arrow-down':''">
      <use xlink:href="#icon-arrow"></use>
    </svg>
  </div>
  <div *ngIf="firmante.seleccionado">
    <app-formularios-grupo-casillas *ngFor="let form of casillasVerificacionArrayMostrar ; let i = index" [firmante]="firmante" [formulario]="convertirAForm(i)" [indexMostrar]="i" (addForm)="showNewForm()"></app-formularios-grupo-casillas>
  </div>
</div>
