import { CdkDragStart } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ContenidoDocVisible } from '../../models/contenido-doc-visible';
import { TiposContenido } from '../../models/tipos-contenido';
import { DatosConfiguracionDocumentosService } from '../../services/datos-configuracion-documentos.service';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';


@Component({
  selector: 'app-contenido-doc',
  templateUrl: './contenido-doc.component.html',
  styleUrls: ['./contenido-doc.component.css']
})
export class ContenidoDocComponent implements OnChanges, OnInit, AfterViewInit {

  @Input() palabraClave: any;
  @Input() topPosition:number;
  @Input() leftPosition:number;
  @ViewChild('draggableIcon') draggableIcon: ElementRef;
  @ViewChild('draggableDiv') draggableDiv: ElementRef;
  @ViewChild('draggableUse') draggableUse: ElementRef;
  palabraPosition: { left: number, top: number, height: number, width: number };
  palabraEnviar: any;
  mostrar: boolean;
  isDragging: boolean = false;
  clientX: number = 0;
  clientY: number = 0;
  initialX: number = 0;
  initialY: number = 0;
  xOffset: number = 0;
  yOffset: number = 0;
  currentX: number = 0;
  currentY: number = 0;
  finalX: number = 0;
  finalY: number = 0;

  constructor(private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService,
              private formularioDocumentoService: FormularioDocumentoService) { }

  ngAfterViewInit(): void {

    if(this.mostrar){
      this.draggableDiv.nativeElement.style.display = 'flex';
      this.isDragging = true;
      this.clientX = this.leftPosition;
      this.clientY = this.topPosition;
      this.initialX = this.leftPosition - this.xOffset;

      this.initialY = this.topPosition - this.yOffset;
    }
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges){


    changes ? this.mostrar = true : this.mostrar = false;
  }

  @HostListener('document:mousemove', ['$event']) onMouseMove(event: any) {
    if (this.isDragging) {
      this.currentX = event.clientX - this.initialX - 15;
      this.currentY = event.clientY - this.initialY - 15;
      this.xOffset = this.currentX;
      this.yOffset = this.currentY;
      this.finalX = this.currentX + this.leftPosition;
      this.finalY = this.currentY + this.topPosition - 50;
      this.palabraPosition = {
        left: this.finalX,
        top: this.finalY,
        height: this.draggableDiv.nativeElement.clientHeight,
        width: this.draggableDiv.nativeElement.clientWidth
      }
      this.setTransform();
    }
  }

  //enviamos la posición final a doc-drag a través del observable del servicio
  @HostListener('document:mouseup', ['$event']) onMouseUp(event: any) {
    if(!this.isDragging) return;
    this.isDragging = false;




    if (this.draggableIcon && (event.target === this.draggableIcon.nativeElement || event.target === this.draggableUse.nativeElement)) {

      this.finalX = this.currentX + this.leftPosition;
      this.finalY = this.currentY + this.topPosition;
      this.palabraPosition = {
        left: this.finalX,
        top: this.finalY,
        height: this.draggableDiv.nativeElement.clientHeight,
        width: this.draggableDiv.nativeElement.clientWidth
      }
      this.datosConfiguracionDocumentosService.palabraPosition = this.palabraPosition;

      if(this.datosConfiguracionDocumentosService.isDentro('contenido-doc', this.palabraPosition)){
        this.palabraEnviar = {
          tipo: {id: this.palabraClave.id, nombre: this.palabraClave.nombre},
          x: this.palabraPosition.left - 81,
          y: this.palabraPosition.top - 100,
          width: this.palabraPosition.width,
          height: this.palabraPosition.height
        }
        this.formularioDocumentoService.cancelarCopiaDoc = false;


        this.datosConfiguracionDocumentosService.transferirDatosPalabra(this.palabraEnviar)
      }
      this.mostrar = false;
      this.palabraEnviar = {} as any;
    }
  }

  setTransform() {
    this.draggableDiv.nativeElement.style.transform = `translate3d(${this.currentX}px, ${this.currentY}px, 0)`;
  }

}
