import { Injectable } from '@angular/core';
import { IRecuperaConfiguracionAplicacionService } from './ports/i-recupera-configuracion-aplicacion-service';
import { ConfiguracionRlt } from '../models/configuracion-rlt';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RecuperaConfiguracionAplicacionMockService implements IRecuperaConfiguracionAplicacionService{

  constructor(private client: HttpClient) { }

  getConfiguracionRlt(): Observable<ConfiguracionRlt> {
    return this.client.get(environment.ApiConfiguracionRlt)
      .pipe(
        map((response:any) => {
          let configuracionRlt: ConfiguracionRlt = response;
            return configuracionRlt;
          }
        )
    );
  }

  guardaConfiguracionRlt(): Observable<void> {

    throw new Error('Method not implemented.');
  }

  isModuloRltActivo(): Observable<boolean>{
    return this.client.get<boolean>(environment.ApiConfiguracionRlt)
      .pipe(
        map((response:any) => {
            return true;
          }
        )
    );
  }
}
