import { Component, OnInit } from '@angular/core';
import { DatosUsuariosService } from '../../../services/datos-usuarios.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuariActivo, UsuarioModel } from '../../../models/usuario-model';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { ConfirmationMessageComponent } from 'src/app/shared/components/modals/confirmation-message/confirmation-message.component';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';

@Component({
  selector: 'app-usuario-activo-page',
  templateUrl: './usuario-activo-page.component.html',
  styleUrls: ['./usuario-activo-page.component.css']
})
export class UsuarioActivoPageComponent implements OnInit {

  opcionMenu: string = '';
  usuarios: UsuarioModel[] = [];
  usuarioActivo: UsuarioModel;

  constructor(private datosUsuariosService: DatosUsuariosService,
              private _snackBar: MatSnackBar,
              private loginService: LoginService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getUsuarios();
  }

  getOpcionSeleccionada(event:string){
    this.opcionMenu = event;
    if(event=='seguridad'){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
          icono: '',
          titulo: 'Resetear contraseña',
          mensaje:'Si clica continuar, será redirigido a una ventada para resetear la contraseña. ¿Está seguro que desea continuar?',
          // preguntaConfirmacion: '¿Está seguro que desea salir de la configuración de documento sin guardar?',
          textoConfirmacion: 'continuar',
          iconoConfirmacion: 'icon-tick',
          textoCancelar: 'cancelar'
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK'){
          this.datosUsuariosService.resetPasswordAzureAd().subscribe(
            (response:any )=> {
              let url = response.Url;
              window.location.href = url;
            },
            error => {
              console.error(error);
            }
          );
        }
      });
    }
  }

  getUsuarios(){
    this.datosUsuariosService.getUsuarios().subscribe(
      next => {
        this.usuarios = next;
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  guardarCambiosUsuarioActivo(){

    if(this.datosUsuariosService.usuarioActivoForm.invalid){
      this.datosUsuariosService.usuarioActivoForm.markAllAsTouched();
      this._snackBar.open(`Hay errores`,'Cerrar', {duration: 3000});
      return
    }
    this.usuarios.forEach(x=>{

      if(x.email == this.loginService.loginData.User.Login){
        let objetoUsuario: UsuariActivo = {
          Login:x.email,
          Nombre: this.datosUsuariosService.usuarioActivoForm.controls['nombre'].value,
          Apellido1: this.datosUsuariosService.usuarioActivoForm.controls['apellido1'].value

          // multiTenant: this.loginService.usuarioActivo.multiTenant
        }

        const tenant = this.loginService.usuarioActivo.tenantActivo.Tenant;
        this.datosUsuariosService.guardaUserActivo(objetoUsuario,tenant).subscribe(
          then => {
            this.datosUsuariosService.getUsuarios();
            const dialogRef = this.dialog.open(ModalMessageComponent, {
              data: {
                mensaje: 'Usuario guardado correctamente',
              },
            });
            this.datosUsuariosService.transferirUsuarioActivo(objetoUsuario);
          },
          error => {
            console.error("error", error);
          },
          () => {

          }
        );
      }
    })

  }
}
