import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './pages/login/services/login.service';
import { hideWidget } from '../assets/js/freshworks.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'SqpFirmaFront';

  constructor(){}

  ngOnInit(){
    // eval('window.FreshworksWidget("hide", "launcher")');
    // hideWidget();
  }

}
