<div class="flex">
  <span class="titulo">DETALLES DEL DOSIER</span>
  <span (click)="mostrarDosieres()">
    <svg class="icon-arrow" [ngClass]="firmanteDosier ? 'icon-arrow-down':''">
      <use xlink:href="#hs-arrow"></use>
    </svg>
  </span>
</div>
<div *ngIf="firmanteDosier">
  <div class="dossier">
    <div class="dossier-name">
      <!-- <svg class="icon-files">
        <use xlink:href="#icon-files"></use>
      </svg> -->
      <span>{{firma.tipoEnvio}}</span> <span class="dossier-name-numberdocs">{{numeroDocsNecesarios}}</span>
    </div>
    <div class="dossier-docs" *ngFor="let doc of firma.tiposDocumento">
      <div [class]=" iconoEstadoDocumento(doc)+' dossier-docs-name'">
        <svg [class]="iconoEstadoDocumento(doc)" style="color: var(--hs-grey2)">
          <use [attr.xlink:href]="iconoEstadoDocumento(doc)|iconoRef"></use>
        </svg> {{doc.nombre}}
      </div>
      <!-- <div class="dossier-signer">
        <div class="dossier-signer-status">
          <svg [class]="estadoDocumentoFirma(doc, true)">
            <use [attr.xlink:href]="estadoDocumentoFirma(doc, true)|iconoRef"></use>
          </svg> {{estadoDocumentoFirma(doc, false)}}: </div>
        <div class="dossier-signer-name">{{firma.empleado}}</div>
      </div> -->
    </div>
</div>
</div>
<div class="">
    <!-- <input type="checkbox" id="chck2" checked>
    <label class="tab-label" for="chck2">DETALLES DEL DOSIER</label> -->
    <div class="">

    </div>
</div>
