import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { EmpresasModel } from 'src/app/pages/configuracion/maestros/empresas/models/empresas-model';
import { GridColDefsSeguridadEmpresas } from '../../models/grid-col-defs-seguridad-empresas';

@Component({
  selector: 'app-grid-seguridad-empresas',
  templateUrl: './grid-seguridad-empresas.component.html',
  styleUrls: ['./grid-seguridad-empresas.component.css']
})
export class GridSeguridadEmpresasComponent implements OnInit {

  @Input() empresasData: EmpresasModel[] = [];

  api!: GridApi;
  apiCol: any;
  columnDefs: ColDef[] = new GridColDefsSeguridadEmpresas().columnDefs;
  gridOptions: GridOptions = {
    headerHeight: 0,
    onFirstDataRendered: (params) => {
      // let nodesToSelect: RowNode[] = [];
      let codigosEmpresa = this.filtroSeguridadFirmas.split(';');
      this.dataSeguridad.empresasSeleccionadas=this.dataSeguridad.empresasSeleccionadas.map(x=>x.id);

      params.api.forEachNode(node => {
        // if (codigosEmpresa.includes(node.data.codigoEmpresa)) node.setSelected(true);
        if (this.dataSeguridad.empresasSeleccionadas.includes(node.data.codigoEmpresa)) node.setSelected(true);
      });
      // params.api.forEachNode((node:any) => {
      //   if (codigosEmpresa.includes(node.data.codigoEmpresa)) nodesToSelect.push(node);
      // });
      // nodesToSelect.forEach(x => (this.api as any).selectNode(x, true));

    }
  };
  @Input() filtroSeguridadFirmas: string = '';
  @Input() dataSeguridad: any;
  @Output() seleccionEmpresas: EventEmitter<any> = new EventEmitter<any>();
  overlayNoRowsTemplate: string ='<span>No se han encontrado empresas</span>';
  overlayLoadingTemplate: string ='<span class="ag-overlay-loading-center">Cargando...</span>';

  constructor() { }

  ngOnInit(): void {
  }

  cargaApi(arg: GridReadyEvent){
    this.api = arg.api;
    this.apiCol = arg.columnApi;
    this.api.sizeColumnsToFit();

    // let codigosEmpresa = this.filtroSeguridadFirmas.split(';');
    // if(this.filtroSeguridadFirmas.length>0){

    //   this.api.forEachNodeAfterFilter((node:any) => {

    //     let index = codigosEmpresa.findIndex(x => x == node.data.codigoEmpresa)

    //     if(index > -1) node.setSelected(true);
    //   });
    // }
  }


  selectRows(event: any){
   let empresasSeleccionadas = this.api.getSelectedRows();
   this.seleccionEmpresas.emit(empresasSeleccionadas);
  }

}
