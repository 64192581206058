import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatosSubidaService } from 'src/app/pages/subidaPrincipal/services/datos-subida.service';

@Component({
  selector: 'app-subida-archivo',
  templateUrl: './subida-archivo.component.html',
  styleUrls: ['./subida-archivo.component.css']
})
export class SubidaArchivoComponent implements OnInit {
  readonly textoDoc='Arrastra aquí los documentos PDF del dosier o haz click para seleccionarlos';
  readonly textoExcel='Arrastra aquí el excel de empleados o haz click para seleccionarlo';
  constructor(public datosSubidaService: DatosSubidaService) { }
  @Output()fileListEmitter: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Input()porcentajeSubida: string = "0%";
  @Input()detallesSubida: string = "";
  hayFicheros:boolean = false;
  @Input() isSandbox: boolean = false;

  ngOnInit(): void {
    this.datosSubidaService.files = [];

  }
   get textoSubida(){
     if(this.datosSubidaService.tipoSubida==this.datosSubidaService.SUBIDA_NORMAL){
       return this.textoDoc;
     }
     if(this.datosSubidaService.tipoSubida==this.datosSubidaService.SUBIDA_MASIVA){
       if(this.datosSubidaService.indexStepActive==2){
         return this.textoExcel;
       }else{
        return this.textoDoc;
       }
     }
     //Valor por defecto
     return 'Arraste o seleccione los documentos';
   }
  uploadFiles(data: FileList | any){

    if(!(data instanceof FileList)) data = data.target?.files;
    this.hayFicheros =Array.from(data).length>0


    this.fileListEmitter.emit(data);


  }
  get accept():string{
    if(this.datosSubidaService.faseSubidaExcel){
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }else{
      return 'application/pdf';
    }

  }
  get multiple(): boolean{
    return this.datosSubidaService.tipoSubida != this.datosSubidaService.SUBIDA_MASIVA;
  }

  finalizaSubida(){
    this.hayFicheros=false;
  }

}
