import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmpresasModel, EmpresasSearchResponse } from 'src/app/pages/configuracion/maestros/empresas/models/empresas-model';
import { environment } from 'src/environments/environment';
import { IRecuperarEmpresasService } from './ports/i-recuperar-empresas-service';



@Injectable({
  providedIn: 'root'
})
export class RecuperaEmpresasMockService implements IRecuperarEmpresasService{

  empresas:EmpresasModel[];

  constructor(private http: HttpClient) { }

  guardaEmpresa(data: EmpresasModel): Observable<void> {
    throw new Error('Method not implemented.');
  }

  getEmpresas(): Observable<EmpresasModel[]> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')

      return this.http.get<EmpresasSearchResponse>(`${environment.ApiEmpresasMock}`, {headers})
        .pipe(
          map(resp => {
            this.empresas= resp.datos;
              return this.empresas;
            }
          )
      );
  }

  deleteEmpresa(data: EmpresasModel): Observable<void> {
    throw new Error('Method not implemented.');
  }

}
