import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DesplegableData } from 'src/app/shared/models/desplegable-data';
import { FiltroDocs } from 'src/app/shared/models/filtros';
import { IRecuperarTiposDocumentoService } from 'src/app/pages/configuracion/Envios/documentos/services/ports/i-recuperar-tipos-documento-service';
import { EstadosTipoDocumento, EstadosTipoDocumentoApi, GenericoTipoDocumento, TipoDocumento } from '../models/tipo-documento';
import { iDocumentoPdfConfiguracionService } from './ports/i-documento-pdf-configuracion-service';
import { FirmanteNif } from '../models/firmante-nif';

@Injectable({
  providedIn: 'root'
})
export class DatosDocumentosService {

  public documentos: TipoDocumento[] = [];
  docsFiltrados: TipoDocumento[] = [];
  filtros: FiltroDocs[] = [];
  columnDef: ColDef[] = [];
  negrita: boolean = false;
  configDoc: boolean = false;
  refreshDocs: boolean;

  constructor(private http: HttpClient,
              @Inject('RecuperaTipoDocumento') private datosDocumento: IRecuperarTiposDocumentoService,
              @Inject('DocumentoPdfConfiguracion') private documentoPdfConfiguracion: iDocumentoPdfConfiguracionService) {
              }

  //añadir getTipoDocumento(id)

  getTipoDocumentos(): Observable<TipoDocumento[]> {

    const headers = new HttpHeaders()
    .set('content-type', 'application/json')

    return this.datosDocumento.getTipoDocumentos(this.refreshDocs)
      .pipe(
        map(resp => {

            this.documentos = resp;
            this.documentos.forEach(x=>{
              x.estado = this.mostrarEstadoGrid(x.estado);
              x.tipoDoc = this.mostrarGenericoGrid(x.generico)
            }
            )
            this.refreshDocs = false;
            return this.documentos;
          }
        )
    );
  }

  mostrarEstadoGrid(estadoDocumento: string): string{
    let estadoDocumentoGrid: string = '';
    switch (estadoDocumento){
      case EstadosTipoDocumentoApi.completo:
        estadoDocumentoGrid = EstadosTipoDocumento.completo
        break;
      case EstadosTipoDocumentoApi.incompleto:
        estadoDocumentoGrid = EstadosTipoDocumento.incompleto
        break;
    }
    return estadoDocumentoGrid;
    }

  mostrarGenericoGrid(genericoDocumento: boolean): string{
    let genericoDocumentoGrid: string = '';
    switch(genericoDocumento){
      case false:
        genericoDocumentoGrid = GenericoTipoDocumento.docDatosPers
        break;
      case true:
        genericoDocumentoGrid = GenericoTipoDocumento.plantilla
    }
    return genericoDocumentoGrid;
  }

  getTipoDocumento(id:number): TipoDocumento{
    let index = this.documentos.findIndex(x=> x.idTipoDocumento == id)
    if(index > -1){
      return this.documentos[index]
    }
    return null
  }

  SetFilter(_filtros: FiltroDocs[]){
    this.filtros = _filtros;
  }

  // Aplicamos los filtros cargados al listado completo de documentos y guardamos el resultado
  Filter(){
    this.docsFiltrados = [];
    this.docsFiltrados = this.documentos;
    if(this.filtros.length == 0){
      this.docsFiltrados = this.documentos;
    }else{
      for( const filter of this.filtros){
        if(filter.textoBuscar.trim() != ""){
          this.docsFiltrados =  this.docsFiltrados.filter(x =>

            x.tipoDoc.toString().toUpperCase().includes(filter.textoBuscar.toLocaleUpperCase()) ||
            x.nombre.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.paginas.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            this.filterFirmantesNif(x.firmantesNif, filter)
            // x.firmantesNif?.length.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase())
            // x.estado.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
          )
        }
        // if(filter.estado?.trim() !="" && filter.estado?.trim().toUpperCase() != "TODOS"){
        //   this.docsFiltrados = this.docsFiltrados.filter(x => x.estado == filter.estado);
        // }
        if(filter.tipoDoc?.trim() !="" && filter.tipoDoc?.trim().toUpperCase() != "TODOS"){
          this.docsFiltrados = this.docsFiltrados.filter(x => x.tipoDoc == filter.tipoDoc);
        }
        if(filter.nombre?.trim() !="" && filter.nombre?.trim().toUpperCase() != "TODOS"){
          this.docsFiltrados = this.docsFiltrados.filter(x => x.nombre == filter.nombre);
        }
      }
      this.docsFiltrados.forEach(x => {
        if(! this.docsFiltrados.includes(x))  this.docsFiltrados.push(x);
      })
    }
  }

  filterFirmantesNif(firmantesNif: FirmanteNif[] | number, filter: FiltroDocs):boolean{
    if(Array.isArray(firmantesNif)){
      if(firmantesNif?.length.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase())) return true;
      else return false
    }else{
      if(firmantesNif?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase())) return true;
      else return false
    }
  }

  // deleteFilter(filter:FiltroDocs){
  //   this.filtros = this.filtros.filter(x=>x.text!=filter.text && x.value!=filter.value);
  // }

  // Obtenemos el listado de estados de los documentos
  GetEstadosLista(): DesplegableData[]{
    let estados: DesplegableData[] = [];
    estados.push({id:'TODOS',desc:'Estado'});
    estados.push({
                    id: EstadosTipoDocumento.incompleto,
                    desc: EstadosTipoDocumento.incompleto
                  },
                  {
                    id: EstadosTipoDocumento.completo,
                    desc: EstadosTipoDocumento.completo
                  }
                )
    return estados;
  }

  // Obtenemos el listado de tipos de documento
  GetTipoDocumentosLista(): DesplegableData[]{
    let tipoDocumentos: DesplegableData[] = [];
    tipoDocumentos.push({id:'TODOS',desc:'Todos los tipos documento'});
    tipoDocumentos.push({
      id: GenericoTipoDocumento.plantilla,
      desc: GenericoTipoDocumento.plantilla
    },
    {
      id: GenericoTipoDocumento.docDatosPers,
      desc: GenericoTipoDocumento.docDatosPers
    })
    return tipoDocumentos;
  }

  GetNombreDocsLista(): DesplegableData[]{
    let nombre: DesplegableData[] = [];
    nombre.push({id:'TODOS',desc:'Nombre documento'});
    this.documentos.forEach(x => {
      if(!nombre.find(y => y.id == x.idTipoDocumento.toString())){
        nombre.push({id: x.idTipoDocumento.toString(), desc: x.nombre})
      }
    })
    return nombre;
  }

  // Configuración documento PDF

  putDocumentoTemporal(file:FileList): Observable<string> {
    return this.documentoPdfConfiguracion.putDocumentoTemporal(file)
      .pipe(
        map(respuesta => {
            return respuesta;
          }
        )
    )
  }

  getDocumentoTemporal(id: string): Observable<string> {
    return this.documentoPdfConfiguracion.getDocumentoTemporal(id)
      .pipe(
        map(respuesta => {
          return respuesta;
        })
      )
  }

  traeCif(id: string, pagina: number, orden: number): Observable<string>{
    return this.documentoPdfConfiguracion.traeCif(id, pagina, orden)
    .pipe(
      map(respuesta => {
        return respuesta;
      })
    )
  }

  traeNif(id: string, pagina: number, orden: number): Observable<string>{
    return this.documentoPdfConfiguracion.traeNif(id, pagina, orden)
    .pipe(
      map(respuesta => {
        return respuesta;
      })
    )
  }

  guardaDocumento(objetoDocumento: TipoDocumento): Observable<void>{
    return this.datosDocumento.guardaDocumento(objetoDocumento);
  }

}
