import { Component, OnInit } from '@angular/core';
import { DatosSubidaService } from 'src/app/pages/subidaPrincipal/services/datos-subida.service';


@Component({
  selector: 'app-info-dosier',
  templateUrl: './info-dosier.component.html',
  styleUrls: ['./info-dosier.component.css']
})
export class InfoDosierComponent implements OnInit {

  docs: boolean = true;

  constructor(public datosSubidaService: DatosSubidaService) { }

  ngOnInit(): void {
  }

  mostrarDocs(){
    this.docs = !this.docs;
  }

}
