import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-boton-accion',
  templateUrl: './boton-accion.component.html',
  styleUrls: ['./boton-accion.component.css']
})
export class BotonAccionComponent implements OnInit {

  @Input() icono: string = '';
  @Input() titulo: string = '';
  @Input() id: string = '';
  @Output() botonClicked: EventEmitter<string> = new EventEmitter<string>();
  @Input() disabled: boolean = false; // ojo en los modales
  @Input() outline: boolean = false;
  @Input() cancelar: boolean = false;
  @Input() btnAcceso: boolean = false;
  @Input() outlineYellow: boolean = false;

  btnAccion: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  clicked(){
    if(!this.disabled) this.botonClicked.emit(this.id);
  }

}
