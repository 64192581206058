import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusquedaSearchResponse, TipoBusqueda } from 'src/app/pages/configuracion/Envios/envios/models/tipo-busqueda';
import { DestinatarioSearchResponse, TipoDestinatario } from 'src/app/pages/configuracion/Envios/envios/models/tipo-destinatario';
import { EnviosSearchResponse, TipoEnvio } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';
import { FirmanteSearchResponse, TipoFirmante } from 'src/app/pages/configuracion/Envios/envios/models/tipo-firmante';
import { MailSearchResponse, TipoMail } from 'src/app/pages/configuracion/Envios/envios/models/tipo-mail';
import { environment } from 'src/environments/environment';
import { IGestionarTiposEnvioService } from './ports/igestionar-tipos-envio-service';


//OL Refactor: Este servicio se encarga de recuperar los datos de tipos de envio segun el
@Injectable({
  providedIn: 'root'
})
export class RecuperaTipoEnvioMockService implements IGestionarTiposEnvioService {

  envios: TipoEnvio[] = [];
  envioObject: TipoEnvio;
  mails: TipoMail[];

  constructor(private http: HttpClient) { }

  // getTipoEnvios():Observable<TipoEnvio[]>{
  //   const headers = new HttpHeaders()
  //     .set('content-type', 'application/json')

  //     return this.http.get<EnviosSearchResponse>(`${environment.ApiEnviosMock}`, {headers})
  //       .pipe(
  //         map(resp => {
  //           this.envios = resp.datos;

  //             return this.envios;
  //           }
  //         )
  //     )
  // }

  async getTipoEnvios(refresh?: boolean):Promise<void>{
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')

      await this.http.get<EnviosSearchResponse>(`${environment.ApiEnviosMock}`, {headers})
        .pipe(
          map(resp => {
            this.envios = resp.datos;
            }
          )
      ).toPromise()
  }



  getTipoFirmante(): Observable<TipoFirmante[]> {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')

    return this.http.get<FirmanteSearchResponse>(`${environment.ApiFirmanteMock}`, {headers})
      .pipe(
        map(resp => {
          let firmantes: TipoFirmante[] = resp.datos;
            return firmantes;
          }
        )
    );
  }

  getTipoDestinatario(): Observable<TipoDestinatario[]> {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')

    return this.http.get<DestinatarioSearchResponse>(`${environment.ApiDestinatarioMock}`, {headers})
      .pipe(
        map(resp => {
          let destinatarios: TipoDestinatario[] = resp.datos;
            return destinatarios;
          }
        )
    );
  }

  getTipoBusqueda(): Observable<TipoBusqueda[]> {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')

    return this.http.get<BusquedaSearchResponse>(`${environment.ApiBusquedaMock}`, {headers})
      .pipe(
        map(resp => {
          let busquedas: TipoBusqueda[] = resp.datos;
            return busquedas;
          }
        )
    );
  }

  getTipoMail(refresh?:boolean): Observable<TipoMail[]> {
    if(this.mails && !refresh){

      return of(this.mails)
    }else{
      const headers = new HttpHeaders()
      .set('content-type', 'application/json')

      return this.http.get<MailSearchResponse>(`${environment.ApiMailMock}`, {headers})
        .pipe(
          map(resp => {
            this.mails= resp.datos;

              return this.mails;
            }
          )
      );
    }

  }

  guardaEnvio(): Observable<void> {
    throw new Error('Method not implemented.');
  }

  guardaError():Observable<TipoEnvio>{
    throw new Error('Method not implemented.');
  }

  // getTipoEnvio(idTipoEnvio:number): Observable<TipoEnvio> {
  //   if(this.envios.length>0){
  //     let envioObject = this.envios.find(x=> x.idTipoEnvio == idTipoEnvio)

  //     return of(envioObject)
  //   }else{
  //     // let newEnvio = {} as TipoEnvio
  //     this.getTipoEnvios().subscribe(
  //       next => {
  //         this.envios = next;

  //       }
  //     )
  //     let envioObject = this.envios.find(x=> x.idTipoEnvio == idTipoEnvio)

  //     return of(envioObject)
  //   }


  // }



  async getTipoEnvio(idTipoEnvio:string): Promise<TipoEnvio> {
      await this.getTipoEnvios()
      this.envioObject = this.envios.find(x=> x.idTipoEnvio == idTipoEnvio)
      return this.envioObject
  }

}
