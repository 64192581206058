import { Component, EventEmitter, Inject, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DesplegableData, SeleccionItems } from 'src/app/shared/models/desplegable-data';
import { DatosFirmaService } from '../../services/datos-firma.service';
import { FiltroFirma, Filtros, getFiltroInicial } from 'src/app/shared/models/filtros';

@Component({
  selector: 'app-firmas-filtros-nuevos',
  templateUrl: './firmas-filtros-nuevos.component.html',
  styleUrls: ['./firmas-filtros-nuevos.component.css']
})
export class FirmasFiltrosNuevosComponent {
  formRange: FormGroup = new FormGroup({});
  @Input() tiposDosierList: DesplegableData[] = [];
  @Input() empresasList: DesplegableData[] = [];
  @Input() centrosList: DesplegableData[] = [];
  @Input() isEntornoSandbox: any;
  @Input() verEstado: string;
  @Output() filtrarFirmas: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() switchOffNuevas: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() switchOffSandbox: EventEmitter<boolean> = new EventEmitter<boolean>();
  filtrosFirmas: FiltroFirma[] = [];
  @Input() verNuevasParam: boolean;
  @Input() textoBuscar: string;
  limpiarTodo: {num: number, clear: boolean} = { num: 0, clear:false};
  @Output() limpiarTodoEmitter: EventEmitter<{num: number, clear: boolean}> = new EventEmitter<{num: number, clear: boolean}>();
  @Output() limpiarBuscador: EventEmitter<string> = new EventEmitter<string>();
  @Output() limpiarEstados: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filtrosNuevosEmitter: EventEmitter<FiltroFirma[]> = new EventEmitter<FiltroFirma[]>();

  constructor(public datosFirmaService: DatosFirmaService) {
    this.inicializaFormularioRange();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges){
    this.anadirEstadoSeleccionado();
    let filtro: FiltroFirma;
    if(changes.verNuevasParam){
      filtro = {
        text: 'Nuevos',
        nuevos: changes.verNuevasParam.currentValue
      }
      let index = this.filtrosFirmas.findIndex(x=> x.text == filtro.text)
      if(index == -1) this.filtrosFirmas.push(filtro);
      else this.filtrosFirmas[index] = filtro;
    }
    if(changes.textoBuscar && (changes.textoBuscar.currentValue || changes.textoBuscar.currentValue == '')){
      filtro = {
        text: 'Buscar',
        buscar: changes.textoBuscar.currentValue
      }
      let index = this.filtrosFirmas.findIndex(x=> x.text == filtro.text)
      if(index == -1) this.filtrosFirmas.push(filtro);
      else this.filtrosFirmas[index] = filtro;
    }
    if(changes.verEstado && changes.verEstado.currentValue){
      filtro = {
        text:'Estado',
        estado: changes.verEstado.currentValue
      }
      let index = this.filtrosFirmas.findIndex(x=> x.text == filtro.text)
      if(index == -1) this.filtrosFirmas.push(filtro);
      else this.filtrosFirmas[index] = filtro;
    }
    if(changes.isEntornoSandbox){
      filtro = {
        text: 'Sandbox',
        isEntornoSandbox: this.isEntornoSandbox
      }
      let index = this.filtrosFirmas.findIndex(x=> x.text == filtro.text)
      if(index == -1) this.filtrosFirmas.push(filtro);
      else this.filtrosFirmas[index] = filtro;
    }
    this.datosFirmaService.filtrarNuevo(this.filtrosFirmas);
    this.filtrarFirmas.emit(true);
    this.filtrosNuevosEmitter.emit(this.filtrosFirmas);

  }

  inicializaFormularioRange(){
    this.formRange = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null)
    })
  }

  get start(){return this.formRange.controls['start'].value}
  get end(){return this.formRange.controls['end'].value}

  getSelectedItems(event: SeleccionItems){
    if(event.tipo=='Nuevos' && event.items.length==0){
      this.verNuevasParam = false;
      this.switchOffNuevas.emit(true);
    }
    if(event.tipo =='Sandbox'){
      this.isEntornoSandbox = false;
      this.switchOffSandbox.emit(true);
    }
    this.anadirFiltros(event);
  }

  anadirFiltros(seleccion: SeleccionItems){
    this.anadirEstadoSeleccionado();
    let valoresSeleccionados: string[] = [];
    seleccion.items.forEach(x=> valoresSeleccionados.push(x.id))
    let filtro: FiltroFirma;
    if(seleccion.tipo !== 'Sandbox'){
      filtro = {
        text: seleccion.tipo,
        listaValores: valoresSeleccionados
      }
    }else{
      filtro = {
        text: seleccion.tipo,
        isEntornoSandbox: this.isEntornoSandbox
      }
    }

    let index = this.filtrosFirmas.findIndex(x=> x.text == seleccion.tipo);

    //actualizamos el tipo de filtro con nuevos valores
    if(index == -1) this.filtrosFirmas.push(filtro);
    else this.filtrosFirmas[index] = filtro;

    //eliminamos el filtro que le hemos quitado todas las opciones seleccionadas
    let indexEliminar = this.filtrosFirmas.findIndex(x=> x.listaValores?.length==0)
    if(indexEliminar>-1) this.filtrosFirmas.splice(indexEliminar,1);
    this.datosFirmaService.filtrarNuevo(this.filtrosFirmas);
    this.filtrarFirmas.emit(true);
    this.filtrosNuevosEmitter.emit(this.filtrosFirmas);
  }

  anadirFiltroFechaEnvio(filtro: FiltroFirma){

    this.anadirEstadoSeleccionado();

    let indexFecha = this.filtrosFirmas.findIndex(x=> x.text=='Fecha de envío');

    if(filtro.fechaDesde !== null && filtro.fechaHasta !== null) {
      if(indexFecha>-1) this.filtrosFirmas[indexFecha] = filtro;
      else this.filtrosFirmas.push(filtro);
      this.datosFirmaService.filtrarNuevo(this.filtrosFirmas)
      this.filtrarFirmas.emit(true);
    }
    if(filtro.fechaDesde == null && filtro.fechaHasta == null){
      this.filtrosFirmas.splice(indexFecha,1);
      this.datosFirmaService.filtrarNuevo(this.filtrosFirmas)
      this.filtrarFirmas.emit(true);
      this.filtrosNuevosEmitter.emit(this.filtrosFirmas);
    }
  }

  anadirFiltroFechaContrato(filtro: FiltroFirma){
    this.anadirEstadoSeleccionado();

    let indexFecha = this.filtrosFirmas.findIndex(x=> x.text=='Fecha contrato');

    if(filtro.fechaDesde !== null && filtro.fechaHasta !== null) {
      if(indexFecha>-1) this.filtrosFirmas[indexFecha] = filtro;
      else this.filtrosFirmas.push(filtro);
      this.datosFirmaService.filtrarNuevo(this.filtrosFirmas)
      this.filtrarFirmas.emit(true);
    }
    if(filtro.fechaDesde == null && filtro.fechaHasta == null){
      this.filtrosFirmas.splice(indexFecha,1);
      this.datosFirmaService.filtrarNuevo(this.filtrosFirmas)
      this.filtrarFirmas.emit(true);
      this.filtrosNuevosEmitter.emit(this.filtrosFirmas);
    }
  }
  // anadirFiltroFecha(){
  //   this.anadirEstadoSeleccionado();
  //   let fechaDesde: Date | null = this.formRange.controls["start"].value ? (this.formRange.controls["start"].value as any).toDate() : null;
  //   let fechaHasta: Date | null = this.formRange.controls["end"].value ? (this.formRange.controls["end"].value as any).toDate() : null;
  //   if(fechaHasta) fechaHasta.setDate(fechaHasta.getDate()+1);


  //   let filtro: FiltroFirma = {
  //     text: 'Fecha',
  //     fechaDesde: fechaDesde,
  //     fechaHasta: fechaHasta
  //   }

  //   let indexFecha = this.filtrosFirmas.findIndex(x=> x.text=='Fecha')

  //   if(fechaDesde !== null && fechaHasta !== null) {
  //     if(indexFecha>-1) this.filtrosFirmas[indexFecha] = filtro;
  //     else this.filtrosFirmas.push(filtro);
  //     this.datosFirmaService.filtrarNuevo(this.filtrosFirmas)
  //     this.filtrarFirmas.emit(true);
  //   }
  //   if(fechaDesde == null && fechaHasta == null){
  //     this.filtrosFirmas.splice(indexFecha,1);
  //     this.datosFirmaService.filtrarNuevo(this.filtrosFirmas)
  //     this.filtrarFirmas.emit(true);
  //     this.filtrosNuevosEmitter.emit(this.filtrosFirmas);
  //   }
  // }

  limpiarFecha(){
    this.formRange.controls['start'].setValue(null);
    this.formRange.controls['end'].setValue(null);
    let indexFecha = this.filtrosFirmas.findIndex(x=> x.text=='Fecha')
    this.filtrosFirmas.splice(indexFecha,1);
    this.datosFirmaService.filtrarNuevo(this.filtrosFirmas);
    this.filtrarFirmas.emit(true);
    this.filtrosNuevosEmitter.emit(this.filtrosFirmas);
  }

  limpiarFiltros(){
    this.formRange.controls['start'].setValue(null);
    this.formRange.controls['end'].setValue(null);
    this.filtrosFirmas = [];
    this.tiposDosierList  = this.datosFirmaService.GetTipoDosierLista();
    this.empresasList  = this.datosFirmaService.GetEmpresaLista();
    this.centrosList  = this.datosFirmaService.GetCentrosLista();
    this.datosFirmaService.filtrarNuevo(this.filtrosFirmas)
    this.filtrarFirmas.emit(true);
    this.limpiarTodo = {
      num: this.limpiarTodo.num+1,
      clear: true
    }
    this.verNuevasParam = false;
    this.switchOffNuevas.emit(true);
    this.isEntornoSandbox = false;
    this.switchOffSandbox.emit(true);
    this.limpiarBuscador.emit('');
    this.limpiarTodoEmitter.emit({num: this.limpiarTodo.num+1, clear: true});
  }

  anadirEstadoSeleccionado(){
    if(this.verEstado){
      let index= this.filtrosFirmas.findIndex(x=>x.text=='Estado')
      if(index == -1) this.filtrosFirmas.push({text: 'Estado',
        estado: this.verEstado});
      else this.filtrosFirmas[index] = {text: 'Estado',
      estado: this.verEstado};
    }
  }

  isFiltrosLimpios(): number {
    let contador:number = 0;
    this.filtrosFirmas.forEach(x=>{
      if(x.text=='Buscar' && x?.buscar!=='') contador++;
      if(x.text=='Nuevos' && x?.nuevos==true) contador++;
      if(x.text=='Sandbox' && x?.isEntornoSandbox==true) contador++;
      if(x.listaValores?.length>0) contador++;
      if(x.text=='Fecha de envío' && (x?.fechaDesde!==null || x?.fechaHasta!==null)) contador++;
      if(x.text=='Fecha contrato' && (x?.fechaDesde!==null || x?.fechaHasta!==null)) contador++;
      if(x.text=='Estado' && x?.estado!=='TODOS') contador++;
    })
    return contador;
  }
}
