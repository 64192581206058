import { ViewportRuler } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationMessageComponent } from 'src/app/shared/components/modals/confirmation-message/confirmation-message.component';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';
import { Firmas } from 'src/app/pages/panelControl/models/firmas';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { SpinnerModalComponent } from 'src/app/shared/components/others/spinner-modal/spinner-modal.component';

@Component({
  selector: 'app-panel-eliminar-firmas',
  templateUrl: './panel-eliminar-firmas.component.html',
  styleUrls: ['./panel-eliminar-firmas.component.css']
})
export class PanelEliminarFirmasComponent implements OnInit, OnChanges {

  @Output() cerrar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refrescar: EventEmitter<boolean> = new EventEmitter<boolean>();
  firmasBorradas: boolean = false;
  formGroup: UntypedFormGroup = new UntypedFormGroup({});

  constructor(@Inject(MAT_DIALOG_DATA) public firmas: Firmas[],
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private datosFirmaService: DatosFirmaService,
              private _snackBar: MatSnackBar) {
    this.inicializaFormulario();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.firmas.currentValue) this.inicializaFormulario();
  }

  inicializaFormulario(){
    if(!this.formGroup.controls['eliminarTodos']) this.formGroup.addControl('eliminarTodos', new UntypedFormControl(false));
    this.firmas.forEach(x=>{
      this.formGroup.addControl(`eliminar_${x.idFirma}`, new UntypedFormControl(false));
    });
    this.formGroup.controls['eliminarTodos'].valueChanges.subscribe(x => {
      Object.keys(this.formGroup.controls).filter(y => y != 'eliminarTodos').forEach(key => {
        this.formGroup.controls[key].setValue(x);
      });
    });
  }

  confirmarEliminacion(){
    let idFirmas: number[]= [];

    Object.keys(this.formGroup.controls).forEach(key => {
      if(this.formGroup.controls[key].value) idFirmas.push(parseInt(key.replace('eliminar_','')));
    });
    if(idFirmas.length > 0){
      const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
        data: {
          tipo: TipoMensajeConfirmacion.ConfirmacionCompleja,
          icono: 'icon-delete',
          titulo: '¿Eliminar firmas?',
          mensaje: '<p>Se eliminará de forma irreversible los registros seleccionados.</p>',
          preguntaConfirmacion: '¿Eliminar registros?',
          textoConfirmacion: 'Eliminar los registros seleccionados',
          textoBoton: 'Eliminar',
          iconoConfirmacion: 'icon-delete'
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result === 'OK')
        {
          const dialogRefSpinner = this.dialog.open(SpinnerModalComponent);
          this.datosFirmaService.BorrarFirmas(idFirmas).subscribe(
            ok =>{
              dialogRefSpinner.close();
              if(ok.Eliminados.length > 0) {
                this.dialogRef.close(ok.Eliminados.length);
              }
            },
            error => {
              dialogRefSpinner.close();
              this.dialog.open(ModalMessageComponent, {data: {alert: true, mensaje: error}, panelClass: 'modal-class' });
            }
          );
        }
      });
    }
    else this.dialog.open(ModalMessageComponent, {data: {alert: true, mensaje: "Debes seleccionar los errores que deseas eliminar"}, panelClass: 'modal-class' });
  }

  change(evento:any){
    const value = this.formGroup.controls['eliminarTodos'].value;
    for (const elem in this.formGroup.controls){
      this.formGroup.controls[elem].setValue(value);

    }

  }

}
