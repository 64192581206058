import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DatosSubidaFicheros } from '../models/subida-ficheros';
import { IGestionarDatosSubidaService } from './ports/i-gestionar-datos-subida-service';

@Injectable({
  providedIn: 'root'
})
export class GestionaDatosSubidaMockService implements IGestionarDatosSubidaService{

  constructor() { }

  getIdActiva(): Observable<any> {
    let response = 'Mock - Gestiona Datos Subida - getIdActiva()'

    return of(response)
  }
  getEmpleados(file: File): Observable<any> {
    let response = 'Mock - Gestiona Datos Subida - getEmpleados()'

    return of(response)
  }
  subeFichero(idTipoEnvio: string, files: DatosSubidaFicheros[]): Observable<string> {
    let response = idTipoEnvio

    return of(response)
  }
  getIdSubida(): Observable<any> {
    let response = 'Mock - Gestiona Datos Subida - getIdSubida()'

    return of(response)
  }
  SubeFichero(data: FormData, codigoSubida: number): Observable<any> {
    let response = 'Mock - Gestiona Datos Subida - SubeFichero()'

    return of(response)
  }
  procesaFicheros(idSubida: number, param: any): Observable<any> {
    let response = 'Mock - Gestiona Datos Subida - procesaFicheros()'

    return of(response)
  }
  getLogSubida(idSubida: number): Observable<boolean> {
    let response = false

    return of(response)
  }
  cancelaSubida(idSubida: number): Observable<any> {
    let response = 'Mock - Gestiona Datos Subida - cancelaSubida()'

    return of(response)
  }
  cancelaSubidaInacitivad(codigoSubida: string): Observable<any> {
    let response = 'Mock - Gestiona Datos Subida - cancelaSubidaInacitivad()'

    return of(response)
  }
}
