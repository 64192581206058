import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map,tap } from 'rxjs/operators';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../login/services/login.service';
import { IGestionarEmpleadosService } from './ports/i-gestionar-empleados-service';

@Injectable({
  providedIn: 'root'
})
export class GestionaEmpleadosApiService implements IGestionarEmpleadosService{




  constructor(private loginService: LoginService,
              private endpointService:EndpointService,
              private httpClient: HttpClient) { }

  getExcelConfiguration(): Observable<void> {
    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        "authorization": `Bearer ${this.loginService.getUserLocalStorage().Token.Token}`
      })
    };
    return this.httpClient.get(this.endpointService.UrlEmpleadosGetExcelConfiguration, httpOptions).pipe(
      map( (response: any) =>{
        let data: any = JSON.parse(response.Data);

        return data
      })
    );
  }

  deleteEmpleados(): Observable<any> {
    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        "authorization": `Bearer ${this.loginService.getUserLocalStorage().Token.Token}`
      })
    };
    return this.httpClient.delete(this.endpointService.UrlEmpleadoDeleteEmpleados, httpOptions).pipe(
      map( (response: any) =>{
        return response.Message;
      }),
      catchError(error => {
        return "Ha habido un error durante la eliminación de empleados";
      })
    );
  }

  addEmpleados(empleados: any[]): Observable<any> {

    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        "authorization": `Bearer ${this.loginService.getUserLocalStorage().Token.Token}`
      })
    };
    return this.httpClient.post(this.endpointService.UrlEmpleadoAddEmpleados, empleados, httpOptions).pipe(
      map( (response: any) =>{

        return response.Data;
      }),
      catchError(error => {
        return throwError("No se han podido actualizar los empleados debido a un error interno.");
      })
    );
  }


  getEstadoSubida(id:string): Observable<any>{
    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        "authorization": `Bearer ${this.loginService.getUserLocalStorage().Token.Token}`
      })
    };
    let url = this.endpointService.UrGetEstadoSubidaExcel.replace('{id}',id);
    return this.httpClient.get(url,httpOptions).pipe(
      map((resp:any)=>{

        return resp;
      }),
      catchError(error => {
        return throwError("No se Ha podido recuperar el estado de la subida.");
      })

    )

  }

  getSubidaPendiente():Observable<any>{
    let httpOptions: any ={
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        "authorization": `Bearer ${this.loginService.getUserLocalStorage().Token.Token}`
      })
    };

    return this.httpClient.get(this.endpointService.UrlGetSubidasPendientesExcel,httpOptions).pipe(
      map((resp:any)=>{

        return resp;
      }),
      catchError(error => {
        return throwError("No se Ha podido recuperar el estado de la subida.");
      })

    )
  }
}
