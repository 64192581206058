import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRecuperarCentrosService } from 'src/app/pages/configuracion/maestros/centros/services/ports/i-recuperar-centros-service';
import { FiltroBasico } from 'src/app/shared/models/filtros';
import { CentrosModel } from '../models/centros-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Workbook } from 'exceljs';
import { ConfiguracionExcelEmpleados } from 'src/app/pages/empleados/models/configuracion-excel-empleados';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DatosCentrosService {

  centros: CentrosModel[] = [];
  centrosFiltrados: CentrosModel[] = [];
  filtros: FiltroBasico[] = [];
  workbook: any;
  worksheet: any;
  centrosEstructura: any = ['Nombre del centro', 'E-mail', 'Código de centro', 'Código de empresa', 'Empresa', 'Nombre Representante Legal', 'DNI Representante Legal']
  refreshCentros: boolean;

  // config: ConfiguracionExcelEmpleados[] = [];

  constructor(private http: HttpClient,
              @Inject('RecuperaCentros') private datosCentro: IRecuperarCentrosService,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  getCentros(): Observable<CentrosModel[]>{
    return this.datosCentro.getCentros(this.refreshCentros)
      .pipe(
        map(resp => {
          this.centros = resp
          this.refreshCentros = false;
          return this.centros
        })
      )
  }

  SetFilter(_filtros: FiltroBasico[]){
    this.filtros = _filtros;
  }

  // Aplicamos los filtros cargados al listado completo de centros y guardamos el resultado
  Filter(){
    this.centrosFiltrados = [];
    this.centrosFiltrados = this.centros;
    if(this.filtros.length == 0){
      this.centrosFiltrados = this.centros;
    }else{
      for( const filter of this.filtros){
        if(filter.textoBuscar.trim() != ""){
          this.centrosFiltrados =  this.centrosFiltrados.filter(x =>
            x.nombreCentro?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.emailCentro?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.codigoCentro?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.nombreEmpresa?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.nombreRlt?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.dniRlt?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase())
            )
          }
        }
    this.centrosFiltrados.forEach(x => {
      if(! this.centrosFiltrados.includes(x))  this.centrosFiltrados.push(x);
    })
  }
}

  deleteFilter(filter: FiltroBasico){
    this.filtros = [];
    // this.filtros = this.filtros.filter(x=>x.text!=filter.text && x.value!=filter.value);
  }

  guardaCentro(objetoCentro: CentrosModel): Observable<void>{
   return this.datosCentro.guardaCentro(objetoCentro)
  }

  guardaCentros(centros: CentrosModel[]){
    return this.datosCentro.guardaCentros(centros);
  }

  //Subir Excel

  validateConfiguration(){
    //buscamos colDef para comprobar que lo que subimos tiene el mismo formato.
    if(this.centrosEstructura.length == 0)
    {
      this._snackBar.open("Configure los diferentes campos para poder continuar con la operación", "Cerrar", {duration: 3000});
      return false;
    } else return true;
  }

  // Leemos el fichero y obtenemos el listado de empleados ya mapeados con las cabeceras personalizadas
  async readFile(file: File|null){
    let centros: CentrosModel[] = [];
    // Obtenemos el contenido fichero
    let arrayBuffer = await file?.arrayBuffer().then(x => {
      return x;
    }).catch((err: any) => {
        console.error(err);
    });
    // Separamos  el mapeo según se haya subido un documento Excel o CSV
    switch(file?.type){
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        centros = await this.leerFicheroExcel(arrayBuffer);
        break;
    }
    return centros;
  }

  private async leerFicheroExcel(arrayBuffer: ArrayBuffer | void){
    let cabeceras: string[] = [];
    // Trabajamos sobre exceljs
    this.workbook = new Workbook();
    this.worksheet = this.workbook.addWorksheet('Centros');
    // Obtenemos las cabeceras y el listado de lineas que habrá que mapear a un array de empleados
    let centrosExcel = await this.workbook.xlsx.load(arrayBuffer).then(() => {
    const ws = this.workbook.getWorksheet(this.workbook.worksheets[0].name);
      const lastRow = ws.lastRow;
      cabeceras = ws.getRow(1).values;
      return ws.getRows(2, (lastRow.number-1));
    }).catch((x: any) => {
      this._snackBar.open("Excel no válido, inténtelo de nuevo", "Cerrar", {duration: 3000});
    });
    // Comprobamos que los campos subido son del modelo de centros
    if(!this.validaCabeceras(cabeceras)){
      this._snackBar.open("Los campos no corresponden a ningún modelo, vuela a subir el arhivo modificado.", "Cerrar", {duration: 4000});
      return null;
    }else{
      let centros = this.getCentrosExcel(centrosExcel, cabeceras);
      return centros;
    }

  }

  // Primero eliminamos el primer valor "empty" del array de cabeceras para poderlo comparar con la estructura de centros. Añadimos undefined en el mismo sitio después para que el array de cabeceras no quede alterado y lo reconozca al extraer los valores del excel
  private validaCabeceras(cabeceras:string[]):boolean{
    cabeceras.shift();
    const arraysIguales:boolean = cabeceras.length === this.centrosEstructura.length && cabeceras.every(value=> this.centrosEstructura.includes(value))
    cabeceras.unshift(undefined)
    return arraysIguales
  }

  // Transformamos el array de filas del excel a array de centros con los datos mapeados y validados
  private getCentrosExcel(centrosExcel: any[], cabeceras: string[]) {
    let centros: CentrosModel[] = [];
    centrosExcel.forEach((centroExcel: any, index: number) => {
      let centro: CentrosModel = {} as CentrosModel;
      // let modelo=-1;
      centroExcel.eachCell((cell: any, k: number) => {
          const cabeceraExcel = cabeceras[k];
          const property = this.centrosEstructura.find((x:string) => x.toUpperCase() == cabeceraExcel.toUpperCase());
          if(property){

            // let propertyTemp:string[] = property.split(';');
            // let index = propertyTemp.indexOf(cabeceraExcel);
            // if(modelo<0) modelo==index;

            switch(property){
              case 'Nombre del centro': centro.nombreCentro = cell.text; break;
              case 'E-mail': centro.emailCentro = cell.text; break;
              case 'Código de centro':centro.codigoCentro = cell.text; break;
              case 'Empresa': centro.nombreEmpresa = cell.text; break;
              case 'Nombre Representante Legal': centro.nombreRlt = cell.text; break;
              case 'DNI Representante Legal': centro.dniRlt = cell.text; break;
              case 'Código de empresa': centro.codigoEmpresa = cell.text; break;
            }
            // centro[propertyTemp[index]] = cell.text;
            // property.split(';').forEach(_property =>{
            //   centro[_property] = cell.text;
            // });
          };
      });
      centros.push(centro)
    });
    if(!this.validarCamposObligatorios(centros)){
      return centros;
    }else return null
  }

    // Comprobamos que estén informados los siguientes campos del excel: CODIGO
    private validarCamposObligatorios(centros: CentrosModel[]): boolean{
      let arrayErrores: boolean[] = [];
      centros.forEach(x=>{
        if(x.codigoCentro == '' || x.codigoCentro == undefined || x.codigoCentro == null){
          this._snackBar.open("Los códigos de centro son obligatorios, vuela a subir el arhivo modificado.", "Cerrar", {duration: 4000});
          arrayErrores.push(true)
        }
      })
      if(arrayErrores.includes(true)) return true
      else return false
    }

    deleteCentro(centro: CentrosModel){
      return this.datosCentro.deleteCentro(centro);
    }

}
