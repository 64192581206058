import { Component, OnInit } from '@angular/core';
import { DatosSubidaService } from 'src/app/pages/subidaPrincipal/services/datos-subida.service';


@Component({
  selector: 'app-instrucciones-subida',
  templateUrl: './instrucciones-subida.component.html',
  styleUrls: ['./instrucciones-subida.component.css']
})
export class InstruccionesSubidaComponent implements OnInit {

  constructor( public datosSubida:DatosSubidaService) { }

  ngOnInit(): void {
  }

}
