import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-icono-superior',
  templateUrl: './icono-superior.component.html',
  styleUrls: ['./icono-superior.component.css']
})
export class IconoSuperiorComponent implements OnInit {

  @Input() icono: string = '';
  @Input() id: string = '';
  @Input() isGrey:boolean = false;
  @Output() clickIcon: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  clicked(){
    this.clickIcon.emit(this.id);
  }

}
