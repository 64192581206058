import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { iDocumentoPdfConfiguracionService } from './ports/i-documento-pdf-configuracion-service';

@Injectable({
  providedIn: 'root'
})
export class DocumentoPdfConfiguracionMockService implements iDocumentoPdfConfiguracionService{

  constructor() { }

  putDocumentoTemporal(file: FileList): Observable<string> {
    return of (`doc1`);
  }

  getDocumentoTemporal(id: string): Observable<string> {
    return of(`https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf`);
  }

  traeCif(id: string, pagina: number, orden: number): Observable<string> {
    return new Observable<string>().pipe(
      map( (response: any) => {
        return 'B67657189'; 
      }
    ));
  }

  traeNif(id: string, pagina: number, orden: number): Observable<string> {
    return new Observable<string>().pipe(
      map( (response: any) => {
        return '35589509N'; 
      }
    ));
  }

}
