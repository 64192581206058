import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TipoFirmante } from '../../../../envios/models/tipo-firmante';
import { FirmanteNif, TipoFirma } from '../../../models/firmante-nif';
import { FormularioDocumentoService } from '../../../services/formulario-documento.service';

@Component({
  selector: 'app-campos-editables-page',
  templateUrl: './campos-editables-page.component.html',
  styleUrls: ['./campos-editables-page.component.css']
})
export class CamposEditablesPageComponent implements OnInit {

  mostrarFirmantes: any[] = [];
  icono: string = '';

  @Output() firmanteSeleccionado: EventEmitter<TipoFirmante> = new EventEmitter<TipoFirmante>();

  constructor(public formularioDocumentoService: FormularioDocumentoService) { }

  ngOnInit(): void {
    this.mostrarFirmantes = this.firmantesNif.value.filter(x=> x.firmas.tipoFirma !== TipoFirma.Validador);
  }

  get firmantesNif(){ return this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'] as FormArray}

  //obtenemos el firmante seleccionado del hijo formulario-firmantes, le añadimos el atributo seleccionado y modificamos el array mostrarFirmante que pasaremos al hijo otra vez para que muestre el que está seleccionado y cierre los demás. Por otra parte, enviamos el firmante seleccionado al padre documento-page para que se lo pase a otro hijo documento-upload y se muestren solo las casillas dibujadas del firmante que está seleccionado en ese momento.
  seleccionarFirmante(firmante: FirmanteNif){

    this.mostrarFirmantes.forEach(x=> {
      if(x.tipoFirmante.desc == firmante.tipoFirmante.desc){
        if(x.seleccionado == true) x.seleccionado = false;
        else x.seleccionado = true;
      }else{
        x.seleccionado = false;
      }
    })
    this.firmanteSeleccionado.emit(firmante.tipoFirmante)
  }

}
