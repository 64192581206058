import { Injectable } from '@angular/core';
import { TipoFirma } from 'src/app/pages/configuracion/Envios/documentos/models/firmante-nif';
import { Firmantes } from 'src/app/pages/configuracion/Envios/envios/models/firmantes';
import { TipoEnvio } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';
import { ConfiguracionRlt } from 'src/app/pages/configuracion/configuracion-rlt/models/configuracion-rlt';

@Injectable({
  providedIn: 'root'
})
export class ConversionDatosApiService {

  constructor() { }

  mapEnviosEntrada(response: any[]){
    if(!response) return null;
    return response.map(({
                          Activo,
                          Body,
                          // DiasRecordatorio,
                          // Directorio,
                          // DocumentosEnvioProgramado,
                          EnvioMasivo,
                          // Expire_time,
                          Firmantes,
                          IdTipoEnvio,
                          IdV3TipoEnvio,
                          MailPrincipal,
                          Nombre,
                          // numeroAdjuntos,
                          // Programado,
                          Recordatorio,
                          // Recordatorios,
                          Reminders_String,
                          // SolicitarAdjunto,
                          // SubDirectorio,
                          Subject,
                          // TipoMail,
                          TiposDocumento,
                          EsSoloGenericos,
                          EsRLT
                          })=>({
                          activo:Activo,
                          body:Body,
                          // diasRecordatorio: DiasRecordatorio,
                          // directorio: Directorio,
                          // documentosEnvioProgramado: DocumentosEnvioProgramado,
                          envioMasivo: EnvioMasivo,
                          // expire_time: Expire_time,
                          firmantes: Firmantes,
                          idTipoEnvio: IdTipoEnvio,
                          idV3TipoEnvio: IdV3TipoEnvio,
                          mailPrincipal: MailPrincipal,
                          nombre: Nombre,
                          // numeroAdjuntos: numeroAdjuntos,
                          // programado: Programado,
                          recordatorio: Recordatorio,
                          // recordatorios: Recordatorios,
                          reminders_String: Reminders_String,
                          // solicitarAdjunto: SolicitarAdjunto,
                          // subDirectorio: SubDirectorio,
                          subject: Subject,
                          // tipoMail: TipoMail,
                          tiposDocumento: TiposDocumento,
                          esSoloGenericos: EsSoloGenericos,
                          esRLT: EsRLT
                          }))
  }

  mapEnviosSalida(data:any){
    if(!data) return null;
    const { idV3TipoEnvio: IdV3TipoEnvio,
            activo: Activo,
            body: Body,
            //defectoBody: DefectoBody,
            //defectoSubject: DefectoSubject,
            // documentosEnvioProgramado: DocumentosEnvioProgramado,
            // estado: Estado,
            envioMasivo: EnvioMasivo,
            firmantes: Firmantes,
            mailPrincipal: MailPrincipal,
            nombre: Nombre,
            // numeroAdjuntos: NumeroAdjuntos,
            // programado:Programado,
            recordatorio: Recordatorio,
            reminders_String: Reminders_String,
            // solicitarAdjunto: SolicitarAdjunto,
            subject: Subject,
            tiposDocumento: TiposDocumento,
            esSoloGenericos: EsSoloGenericos,
            esRLT: EsRLT
          } = data;


    const newObject = { IdV3TipoEnvio,
                        Activo,
                        Body,
                        // DocumentosEnvioProgramado,
                        // Estado,
                        EnvioMasivo,
                        Firmantes,
                        MailPrincipal,
                        Nombre,
                        // NumeroAdjuntos,
                        // Programado,
                        Recordatorio,
                        Reminders_String,
                        // SolicitarAdjunto,
                        Subject,
                        TiposDocumento,
                        EsSoloGenericos,
                        EsRLT
                      }

          return newObject
  }

  mapFirmantesEntrada(firmantes: any[]){
    if(!firmantes) return null;
    return firmantes.map(({Orden, TipoBusqueda, TipoDestinatario, TipoFirmante})=>({orden: Orden, tipoBusqueda: TipoBusqueda, tipoDestinatario: TipoDestinatario, tipoFirmante: TipoFirmante}))
  }

  mapFirmantesSalida(datos: any[]){
    if(!datos) return null;
    return datos.map(({ orden, tipoBusqueda, tipoDestinatario, tipoFirmante}) => ({ Orden: orden, TipoBusqueda: tipoBusqueda, TipoDestinatario: tipoDestinatario, TipoFirmante: tipoFirmante}))
  }

  mapTiposEnvioDesplegablesEntrada(response:any[]){
    if(!response) return null;
    return response.map(({Codigo, Descripcion})=>({codigo: Codigo, desc: Descripcion}))
  }

  mapTiposEnvioDesplegablesEntradaSingle(data:any){
    if(!data) return null;
    const newObject = { codigo: data.Codigo, desc: data.Descripcion };
    return newObject;
  }

  mapTiposEnvioDesplegablesSalida(data:any){
    if(!data) return null;
    const newObject = { Codigo: data.codigo, Descripcion: data.desc };
    return newObject;
  }

  mapCoordenadasSimpleSalida(data:any[]){

    if(!data) return null;
    return data.map(
    ({id, pagina, posicionHeight, posicionLeft, posicionRight, posicionTop, posicionWidth}) =>
    ({
      Id: id,
      Pagina: pagina,
      PosicionHeight: ((posicionHeight ?? 0) * 100),
      PosicionLeft: ((posicionLeft ?? 0) * 100),
      PosicionRight: ((posicionRight ?? 0) * 100),
      PosicionTop: ((posicionTop ?? 0) * 100),
      PosicionWidth: ((posicionWidth ?? 0) * 100)
    }))
  }

  mapFormulariosSalida(data:any[]){
    if(!data) return null;
    return data.map(({id, pagina, nombre, orden, posicionHeight, posicionLeft, posicionRight, posicionTop, posicionWidth}) =>
     ({Id: id,
      Pagina: pagina,
      Nombre: nombre,
      Orden: orden,
      PosicionHeight: ((posicionHeight ?? 0) * 100),
      PosicionLeft: ((posicionLeft ?? 0) * 100),
      PosicionRight: ((posicionRight ?? 0) * 100),
      PosicionTop: ((posicionTop ?? 0) * 100),
      PosicionWidth: ((posicionWidth ?? 0) * 100)
    }))
  }

  mapFirmasSalida(data: any){
    if(!data) return null;
    const newObject = { NombreCertificado: data.nombreCertificado, NombreTag: data.nombreTag, PosicionFirmas: this.mapCoordenadasSimpleSalida(data.posicionFirmas), TieneTag: data.tieneTag, TipoFirma: data.tipoFirma};
    return newObject;
  }
  // mapFirmasSalida(data: any){
  //   if(!data) return null;
  //   const newObject = { NombreCertificado: data.nombreCertificado, NombreTag: data.nombreTag, PosicionFirmas: data.posicionFirmas, TieneTag: data.tieneTag, TipoFirma: data.tipoFirma};
  //   return newObject;
  // }

  mapTiposContenidoSalida(data:any[]){
    if(!data) return null;
    return data.map(
      ({id, pagina, tipo, posicionHeight, posicionLeft, posicionRight, posicionTop, posicionWidth}) =>
      ({
        Id: id,
        Pagina: pagina,
        Tipo: this.mapTiposContenidoTipoSalida(tipo),
        PosicionHeight: ((posicionHeight ?? 0) * 100),
        PosicionLeft: ((posicionLeft ?? 0) * 100),
        PosicionRight: ((posicionRight ?? 0) * 100),
        PosicionTop: ((posicionTop ?? 0) * 100),
        PosicionWidth: ((posicionWidth ?? 0) * 100)
      }))
  }

  mapTiposContenidoTipoSalida(data: any){
    if(!data) return null;
    const newObject = { Id: data.id, Nombre: data.nombre};
    return newObject;
  }

  mapTiposContenidoEntrada(data:any[]){
    if(!data) return null;
    return data.map(
      ({Id, Pagina, Tipo, PosicionHeight, PosicionLeft, PosicionRight, PosicionTop, PosicionWidth}) =>
      ({
        id: Id,
        pagina: Pagina,
        tipo: this.mapTiposContenidoTipoEntrada(Tipo),
        posicionHeight: ((PosicionHeight ?? 0) / 100),
        posicionLeft: ((PosicionLeft ?? 0) / 100),
        posicionRight: ((PosicionRight ?? 0) / 100),
        posicionTop: ((PosicionTop ?? 0) / 100),
        posicionWidth: ((PosicionWidth ?? 0) / 100)
    }))
  }

  mapTiposContenidoTipoEntrada(data:any){
    if(!data) return null;
    else if (Array.isArray(data)) return data.map(({Id, Nombre})=>({id: Id, nombre: Nombre}))
    else return {id: data.Id, nombre: data.Nombre};
  }

  convertir0ANull(datos:any[]):any[]{
    if(!datos) return null;
    datos.map(x => {
      if(x.recordatorios == 0) x.recordatorios = null;
      if(x.numeroAdjuntos == 0) x.numeroAdjuntos = null;
      if(x.documentosEnvioProgramado == 0) x.documentosEnvioProgramado = null;
      // if(x.diasRecordatorio == 0) x.diasRecordatorio = null
    })
    return datos
  }

  mapDocumentosEntrada(response: any[]){
    if(!response) return null;
    let documentos = response.map(({
                          Activo,
                          CifOrden,
                          CifPage,
                          CifFormulario,
                          Fusionable,
                          Generico,
                          IdTipoDocumento,
                          IdV3TipoDocumento,
                          Nombre,
                          NombreEnvio,
                          OrdenFusion,
                          Paginas,
                          Texto,
                          TextoFormulario,
                          TextoAExcluir,
                          TextoAExcluirFormulario,
                          TiposContenido,
                          FirmantesNif,
                          IdPdf,
                          EsCopiaBasica,
                          FormatoNombreArchivoAuditTrail,
                          FormatoNombreArchivoFirmado,
                          Split,
                          NombreDocumentoGenerico,
                          EsRLT
                        }) => ({
                          activo: Activo,
                          cifOrden: CifOrden,
                          cifPage: CifPage,
                          cifFormulario:CifFormulario,
                          fusionable: Fusionable,
                          generico: Generico,
                          idTipoDocumento: IdTipoDocumento,
                          idV3TipoDocumento: IdV3TipoDocumento,
                          nombre: Nombre,
                          nombreEnvio: NombreEnvio,
                          ordenFusion: OrdenFusion,
                          paginas: Paginas,
                          texto: Texto,
                          textoFormulario:TextoFormulario,
                          textoAExcluir: TextoAExcluir,
                          textoAExcluirFormulario:TextoAExcluirFormulario,
                          tiposContenido: this.mapTiposContenidoEntrada(TiposContenido),
                          firmantesNif: this.mapFirmantesNifEntrada(FirmantesNif),
                          idPdf: IdPdf,
                          esCopiaBasica: EsCopiaBasica,
                          formatoNombreArchivoAuditTrail: FormatoNombreArchivoAuditTrail,
                          formatoNombreArchivoFirmado: FormatoNombreArchivoFirmado,
                          split: Split,
                          nombreDocumentoGenerico: NombreDocumentoGenerico,
                          esRLT: EsRLT
                        }));
      return documentos;
  }

  mapDocumentosSalida(data:any){
    if(!data) return null;
    const {cifOrden: CifOrden,
          cifPage: CifPage,
          cifFormulario:CifFormulario,
          //contieneCif: ContieneCif,
          //copyConfiguracion: CopyConfiguracion,
          fusionable: Fusionable,
          ordenFusion: OrdenFusion,
          firmantesNif: FirmantesNif,
          generico: Generico,
          //idTipoDocOrigen: IdTipoDocOrigen,
          idTipoDocumento: IdTipoDocumento,
          //incluirContenido: IncluirContenido,
          nombre: Nombre,
          texto: Texto,
          textoFormulario:TextoFormulario,
          textoAExcluir: TextoAExcluir,
          textoAExcluirFormulario:TextoAExcluirFormulario,
          tiposContenido: TiposContenido,
          paginas: Paginas,
          activo: Activo,
          idPdf: IdPdf,
          esCopiaBasica: EsCopiaBasica,
          formatoNombreArchivoAuditTrail: FormatoNombreArchivoAuditTrail,
          formatoNombreArchivoFirmado: FormatoNombreArchivoFirmado,
          split: Split,
          nombreDocumentoGenerico: NombreDocumentoGenerico,
          esRLT: EsRLT
          //urlDocTemp
         } = data;
   const newObject = {
                      CifOrden,
                      CifPage,
                      CifFormulario,
                      Fusionable,
                      OrdenFusion,
                      FirmantesNif,
                      Generico,
                      IdTipoDocumento,
                      Nombre,
                      Texto,
                      TextoFormulario,
                      TextoAExcluir,
                      TextoAExcluirFormulario,
                      TiposContenido,
                      Paginas,
                      Activo,
                      IdPdf,
                      EsCopiaBasica,
                      FormatoNombreArchivoAuditTrail,
                      FormatoNombreArchivoFirmado,
                      Split,
                      NombreDocumentoGenerico,
                      EsRLT
                      }

    return newObject
  }

  mapFirmantesNifSalida(firmantes:any[]){
    if(!firmantes) return null;

    firmantes.forEach(element => {
      element.tipoBusqueda = {codigo: 'documento', desc: 'documento'};
      if (element.firmas.tipoFirma == TipoFirma.Validador) {
        element.firmas.tipoFirma = "validator";
        element.tipoDestinatario = {codigo: 'validator', desc: 'Validador'};
      }
      else {
        element.tipoDestinatario = {codigo: 'signer', desc: 'signer'};
        if(element.firmas.tipoFirma == TipoFirma.Certificado) element.firmas.tipoFirma = "dcf";
        if(element.firmas.tipoFirma == TipoFirma.Validador) element.firmas.tipoFirma = "validator";
        if(element.firmas.tipoFirma == TipoFirma.Dibujada) element.firmas.tipoFirma = "signer";
        if(element.firmas.tipoFirma == TipoFirma.SinFirma) element.firmas.tipoFirma = "";


        // element.firmas.tipoFirma = element.firmas.tipoFirma == TipoFirma.Certificado ? "dcf" : "signature";
      }
      element.firmas.tieneTag = element.firmas.tieneTag == true; // hay casos en los que aparece como null y da problemas
      if(element.nifOrden==null ) element.nifOrden=0;
      if(element.nifPage==null ) element.nifPage=0;
    });

    return firmantes.map(({
      firmas,
      nifOrden,
      nifPage,
      nifFormulario,
      tipoFirmante,
      formularios,
      camposEditables,
      tipoBusqueda,
      tipoDestinatario
    })=>({
      Firmas: this.mapFirmasSalida(firmas),
      NifOrden: nifOrden,
      NifPage: nifPage,
      NifFormulario:nifFormulario,
      TipoFirmante: this.mapTiposEnvioDesplegablesSalida(tipoFirmante),
      Formularios: this.mapFormulariosSalida(formularios),
      CamposEditables: this.mapCoordenadasSimpleSalida(camposEditables),
      TipoBusqueda: this.mapTiposEnvioDesplegablesSalida(tipoBusqueda),
      TipoDestinatario: this.mapTiposEnvioDesplegablesSalida(tipoDestinatario)
    }));
    // return firmantes.map(({
    //   firmas,
    //   nifOrden,
    //   nifPage,
    //   nifFormulario,
    //   tipoFirmante,
    //   formularios,
    //   camposEditables,
    //   tipoBusqueda,
    //   tipoDestinatario
    // })=>({
    //   Firmas: firmas,
    //   NifOrden: nifOrden,
    //   NifPage: nifPage,
    //   NifFormulario:nifFormulario,
    //   TipoFirmante: tipoFirmante,
    //   Formularios: formularios,
    //   CamposEditables: camposEditables,
    //   TipoBusqueda: this.mapTiposEnvioDesplegablesSalida(tipoBusqueda),
    //   TipoDestinatario: this.mapTiposEnvioDesplegablesSalida(tipoDestinatario)
    // }));
  }

  mapFirmantesNifEntrada(data: any[]){
    if(!data) return null;
    return data.map(({
      TipoBusqueda,
      TipoDestinatario,
      TipoFirmante,
      CamposEditables,
      Firmas,
      Formularios,
      NifOrden,
      NifPage,
      NifFormulario,
      Orden
    }) => ({
      tipoBusqueda: this.mapTiposEnvioDesplegablesEntradaSingle(TipoBusqueda),
      tipoDestinatario: this.mapTiposEnvioDesplegablesEntradaSingle(TipoDestinatario),
      tipoFirmante: this.mapTiposEnvioDesplegablesEntradaSingle(TipoFirmante),
      camposEditables: this.mapCamposEditablesEntrada(CamposEditables),
      firmas: this.mapFirmasEntrada(Firmas),
      formularios: this.mapFormulariosEntrada(Formularios),
      nifOrden: NifOrden,
      nifPage: NifPage,
      nifFormulario:NifFormulario,
      orden: Orden
    }));
  }

  mapCamposEditablesEntrada(response: any[]){
    if(!response) return null;
    return response.map(({
      AlturaTagFirma,
      AnchuraTagFirma,
      EsFirmaTag,
      EsTextoEditable,
      IdPosicionFirma,
      IdTipoDocumento,
      Nombre,
      Orden,
      Pagina,
      PosicionHeight,
      PosicionLeft,
      PosicionTop,
      PosicionWidth,
      PosicionRight,
      Tag,
      TextoPorDefecto,
      TipoFirma
    })=>({
      id: IdPosicionFirma,
      pagina: Pagina,
      posicionHeight: ((PosicionHeight ?? 0) / 100),
      posicionLeft: ((PosicionLeft ?? 0) / 100),
      posicionRight: ((PosicionRight ?? 0) / 100),
      posicionTop: ((PosicionTop ?? 0) / 100),
      posicionWidth: ((PosicionWidth ?? 0) / 100)
    }))
  }

  mapFirmasEntrada(data:any){
    if(!data) return null;
    const newObject = {
                nombreCertificado: data.NombreCertificado,
                nombreTag: data.NombreTag,
                posicionFirmas: this.mapPosicionFirmasEntrada(data.PosicionFirmas),
                tieneTag: data.TieneTag,
                tipoFirma: data.TipoFirma
              };
    return newObject;
  }

  mapPosicionFirmasEntrada(response:any[]){
    if(!response) return null;
    return response.map(({
      AlturaTagFirma,
      AnchuraTagFirma,
      EsFirmaTag,
      EsTextoEditable,
      IdPosicionFirma,
      IdTipoDocumento,
      Nombre,
      Orden,
      Pagina,
      PosicionHeight,
      PosicionLeft,
      PosicionTop,
      PosicionWidth,
      PosicionRight,
      Tag,
      TextoPorDefecto,
      TipoFirma
    })=>({
      id: IdPosicionFirma,
      pagina: Pagina,
      posicionHeight: ((PosicionHeight ?? 0) / 100),
      posicionLeft: ((PosicionLeft ?? 0) / 100),
      posicionRight: ((PosicionRight ?? 0) / 100),
      posicionTop: ((PosicionTop ?? 0) / 100),
      posicionWidth: ((PosicionWidth ?? 0) / 100)
    }))
  }

  mapFormulariosEntrada(response: any[]){
    if(!response) return null;
    return response.map(({
      AlturaTagFirma,
      AnchuraTagFirma,
      EsFirmaTag,
      EsTextoEditable,
      IdPosicionFirma,
      IdTipoDocumento,
      Nombre,
      Orden,
      Pagina,
      PosicionHeight,
      PosicionLeft,
      PosicionTop,
      PosicionWidth,
      PosicionRight,
      Tag,
      TextoPorDefecto,
      TipoFirma
    })=>({
      pagina: Pagina,
      nombre: Nombre,
      orden: Orden,
      posicionHeight: ((PosicionHeight ?? 0) / 100),
      posicionLeft: ((PosicionLeft ?? 0) / 100),
      posicionRight: ((PosicionRight ?? 0) / 100),
      posicionTop: ((PosicionTop ?? 0) / 100),
      posicionWidth: ((PosicionWidth ?? 0) / 100)
    }))
  }

  // mapTiposContenidoEntrada(response: any[]){
  //   return response.map(({
  //     AlturaTagFirma,
  //     AnchuraTagFirma,
  //     EsFirmaTag,
  //     EsTextoEditable,
  //     IdPosicionFirma,
  //     IdTipoDocumento,
  //     Nombre,
  //     Orden,
  //     Pagina,
  //     PosicionHeight,
  //     PosicionLeft,
  //     PosicionTop,
  //     PosicionWidth,
  //     Tag,
  //     TextoPorDefecto,
  //     Tipo,
  //     TipoFirma
  //   })=>({
  //     pagina: Pagina,
  //     tipo: this.mapTiposContenidoTipoEntradaSingle(Tipo),
  //     posicionTop: PosicionTop,
  //     posicionLeft: PosicionLeft,
  //     // posicionRight: PosicionRight,
  //     posicionWidth: PosicionWidth,
  //     posicionHeight: PosicionHeight
  //   }))

  // }

  mapTiposContenidoTipoEntradaSingle(data: any){
    if(!data) return null;
    const newObject = {
                id: data.Id,
                nombre: data.Nombre
              };
    return newObject;
  }

  mapCentrosEntrada(response: any[]){
    if(!response) return null;
    return response.map(({
                          CodigoCentro,
                          NombreCentro,
                          EmailCentro,
                          CodigoEmpresa,
                          NombreEmpresa,
                          NombreRlt,
                          DniRlt
                          })=>({
                          codigoCentro: CodigoCentro,
                          nombreCentro: NombreCentro,
                          emailCentro: EmailCentro,
                          codigoEmpresa: CodigoEmpresa,
                          nombreEmpresa: NombreEmpresa,
                          nombreRlt: NombreRlt,
                          dniRlt: DniRlt
                          }))
  }

  mapCentrosSalida(data: any){
    if(!data) return null;
   const{ codigoCentro: CodigoCentro,
          nombreCentro: NombreCentro,
          emailCentro: EmailCentro,
          codigoEmpresa: CodigoEmpresa,
          nombreEmpresa: NombreEmpresa,
          nombreRlt: NombreRlt,
          dniRlt: DniRlt
        } = data;
   const newObject = {
                      CodigoCentro,
                      NombreCentro,
                      EmailCentro,
                      CodigoEmpresa,
                      NombreEmpresa,
                      NombreRlt,
                      DniRlt
                      }

    return newObject
  }

  mapEmpresasEntrada(response: any[]){
    if(!response) return null;
    return response.map(({
                          Apellido1Empleado,
                          Apellido2Empleado,
                          // BrandinId,
                          Centros,
                          Cif,
                          CodigoEmpresa,
                          EmailCopia,
                          EmailEmpleado,
                          Logo,
                          // NombreCopia,
                          NombreEmpleado,
                          NombreEmpresa,
                          TipoDocumento,
                          // Token
                          })=>({
                          codigoEmpresa: CodigoEmpresa,
                          tipoDocumento: TipoDocumento,
                          cif: Cif,
                          nombreEmpresa: NombreEmpresa,
                          nombreEmpleado: NombreEmpleado,
                          emailEmpleado: EmailEmpleado,
                          apellido1Empleado: Apellido1Empleado,
                          apellido2Empleado: Apellido2Empleado,
                          logo: Logo,
                          centros: this.mapCentrosEntrada(Centros)
                          }))
  }

  mapEmpresasSalida(data: any){
    if(!data) return null;
   const{ codigoEmpresa: CodigoEmpresa,
          tipoDocumento: TipoDocumento,
          cif: Cif,
          nombreEmpresa: NombreEmpresa,
          nombreEmpleado: NombreEmpleado,
          emailEmpleado: EmailEmpleado,
          apellido1Empleado: Apellido1Empleado,
          apellido2Empleado: Apellido2Empleado,
          logog: Logo,
          token: Token,
          brandId: BrandinId,
          emailCopia: EmailCopia,
          nombreCopia: NombreCopia,
          centros: Centros
        } = data;
   const newObject = {
                      CodigoEmpresa,
                      TipoDocumento,
                      Cif,
                      NombreEmpresa,
                      NombreEmpleado,
                      EmailEmpleado,
                      Apellido1Empleado,
                      Apellido2Empleado,
                      Logo,
                      Token,
                      BrandinId,
                      EmailCopia,
                      NombreCopia,
                      Centros
                      }

    return newObject
  }

//USERS

  mapUsersEntrada(response: any[]){
    if(!response) return null;
    return response.map(({
      Login,
      LoginOld,
      Password,
      PasswordOld,
      ClientId,
      Nombre,
      Apellido1,
      Rol,
      UltimaConexion,
      InvitacionEnviada,
      UsuarioActivo,
      TokenInvitacion,
      FechaInvitacion,
      InvitacionAceptada,
      AzureAdObjectId,
      AplicationId,
      TipoSeguridad,
      TipoEnvioPermitido,
      EmpresaPermitida,
      CentroPermitido,
      UsuarioA3Trans,
      Tenant,
      MultiTenant,
      FiltroSeguridadFirmas
     })=>({
      email: Login,
      loginOld: LoginOld,
      password: Password,
      passwordOld: PasswordOld,
      azureAdObjectId: AzureAdObjectId,
      tokenInvitacion: TokenInvitacion,
      tipoSeguridad: TipoSeguridad,
      nombre: Nombre,
      apellido1: Apellido1,
      rol: Rol,
      usuarioActivo: UsuarioActivo,
      tipoEnvioPermitido: TipoEnvioPermitido,
      empresaPermitida: EmpresaPermitida,
      centroPermitido: CentroPermitido,
      usuarioA3Trans: UsuarioA3Trans,
      tenant: Tenant,
      multiTenant: MultiTenant,
      filtroSeguridadFirmas: FiltroSeguridadFirmas,
      invitacionAceptada: InvitacionAceptada,
      invitacionEnviada: InvitacionEnviada
     }))
  }

  mapGuardaUserSalida(data:any){
    if(!data) return null;
    const {
          apellido1: Apellido1,
          centroPermitido: CentroPermitido,
          email: Login,
          empresaPermitida: EmpresaPermitida,
          nombre: Nombre,
          rol: Rol,
          tipoEnvioPermitido: TipoEnvioPermitido,
          usuarioActivo: UsuarioActivo,
          loginOld: LoginOld,
          password: Password,
          passwordOld: PasswordOld,
          azureAdObjectId: AzureAdObjectId,
          tokenInvitacion: TokenInvitacion,
          tipoSeguridad: TipoSeguridad,
          usuarioA3Trans: UsuarioA3Trans,
          tenant: Tenant,
          multiTenant: MultiTenant,
          filtroSeguridadFirmas: FiltroSeguridadFirmas,
          invitacionAceptada: InvitacionAceptada,
          invitacionEnviada: InvitacionEnviada
         } = data;
   const newObject = {
                      Apellido1,
                      CentroPermitido,
                      Login,
                      EmpresaPermitida,
                      Nombre,
                      Rol,
                      TipoEnvioPermitido,
                      UsuarioActivo,
                      LoginOld,
                      Password,
                      PasswordOld,
                      AzureAdObjectId,
                      TokenInvitacion,
                      TipoSeguridad,
                      UsuarioA3Trans,
                      Tenant,
                      MultiTenant,
                      FiltroSeguridadFirmas,
                      InvitacionAceptada,
                      InvitacionEnviada
                      }

    return newObject
  }

  mapSeguridadPermitidaEntrada(data: any){
    if(!data) return null;
    let newObject;
    if(data.CodigoEmpresa){
      newObject = {
        id: data.Id,
        desc: data.Desc,
        codigoEmpresa: data.CodigoEmpresa
      };
    }else{
      newObject = {
        id: data.Id,
        desc: data.Desc
      };
    }
    return newObject;
  }

  mapSeguridadPermitidaSalida(data:any){
    if(!data) return null;
    let newObject;
    if(data.codigoEmpresa){
      const{id:Id, desc: Desc, codigoEmpresa: CodigoEmpresa} = data;
      newObject = {Id, Desc, CodigoEmpresa};
    }else{
      const{id:Id, desc: Desc} = data;
      newObject = {Id, Desc};
    }
    return newObject;
  }

  //CONFIGURACION RLT

  mapConfiguracionRltEntrada(data: any){
    if(!data) return null;

    const newObject = {
                        activo: data.Activo,
                        idTipoDosier: data.idTipoDosier,
                        idTipoDosierV3: '',//data.IdTipoDosierV3,
                        periodicidad: data.Periodicidad,
                        diaEnvio: data.DiaEnvio,
                        hora: data.Hora
                        }
    return newObject
  }

  mapConfiguracionRltSalida(data:any){
    if(!data) return null;
    const {
          activo: Activo,
          idTipoDosier: idTipoDosier,
          idTipoDosierV3: IdTipodosierV3,
          periodicidad: Periodicidad,
          diaEnvio: DiaEnvio,
          hora: Hora
         } = data;
   const newObject = {
                      Activo,
                      idTipoDosier,
                      IdTipodosierV3,
                      Periodicidad,
                      DiaEnvio,
                      Hora
                      }

    return newObject
  }

  mapEmpleadosEntrada(response: any[]){
    if(!response) return null;
    return response.map(({
      CODIGO,
      NOMBRE,
      APELLIDO1,
      APELLIDO2,
      TIPO_DOCUMENTO,
      NIF,
      FECHA_NACIMIENTO,
      MAIL_PERSONAL,
      NACIONALIDAD,
      LUGAR_NACIMIENTO,
      FECHA_ALTA,
      EMPRESA_CIF,
      ESTADO_CIVIL,
      SEXO,
      TIPO_PERSONA,
      SIGLA,
      PROVINCIA,
      MUNICIPIO,
      CODIGO_POSTAL,
      NUMERO,
      ESCALERA,
      PISO,
      PUERTA,
      EXTENSION_TELEFONO_PERSONAL,
      EXTENSION_TELEFONO_PROFESIONAL,
      DESCRIPCION_PUESTO_TRABAJO,
      DESCRIPCION_CATEGORIA,
      DESCRIPCION_CONVENIO,
      EMPRESA,
      EMPRESA_NOMBRE,
      CENTRO,
      TELEFONO_PESONAL,
      MAIL_PROFESIONAL,
      TELFONO_PROFESIONAL,
      NOMBRE_CENTRO
     })=>({
      codigo: CODIGO,
      nombre: NOMBRE,
      apellido1: APELLIDO1,
      apellido2: APELLIDO2,
      tipoDocumento: TIPO_DOCUMENTO,
      nif: NIF,
      fechaNacimiento: FECHA_NACIMIENTO,
      mailPersonal: MAIL_PERSONAL,
      nacionalidad: NACIONALIDAD,
      lugarNacimiento: LUGAR_NACIMIENTO,
      fechaAlta: FECHA_ALTA,
      empresaCif: EMPRESA_CIF,
      estadoCivil: ESTADO_CIVIL,
      sexo: SEXO,
      tipoPersona: TIPO_PERSONA,
      sigla: SIGLA,
      provincia: PROVINCIA,
      municipio: MUNICIPIO,
      codigoPostal: CODIGO_POSTAL,
      numero: NUMERO,
      escalera: ESCALERA,
      piso: PISO,
      puerta: PUERTA,
      extensionTelefonoPersonal: EXTENSION_TELEFONO_PERSONAL,
      extensionTelefonoProfesional: EXTENSION_TELEFONO_PROFESIONAL,
      puestoTrabajo: DESCRIPCION_PUESTO_TRABAJO,
      categoria: DESCRIPCION_CATEGORIA,
      convenio: DESCRIPCION_CONVENIO,
      codigoEmpresa: EMPRESA,
      // nombreEmpresa: EMPRESA_NOMBRE,
      nombreEmpresa: '',
      codigoCentro: CENTRO,
      telefonoPersonal: TELEFONO_PESONAL,
      mailProfesional: MAIL_PROFESIONAL,
      telefonoProfesional: TELFONO_PROFESIONAL,
      nombreCentro: NOMBRE_CENTRO
     }))
  }

  mapUpdateEmpleadoSalida(data:any){
    if(!data) return null;
    const {
          codigo: CODIGO,
          nombre: NOMBRE,
          apellido1: APELLIDO1,
          apellido2: APELLIDO2,
          tipoDocumento: TIPO_DOCUMENTO,
          nif: NIF,
          fechaNacimiento: FECHA_NACIMIENTO,
          mailPersonal: MAIL_PERSONAL,
          nacionalidad: NACIONALIDAD,
          lugarNacimiento: LUGAR_NACIMIENTO,
          fechaAlta: FECHA_ALTA,
          empresaCif: EMPRESA_CIF,
          estadoCivil: ESTADO_CIVIL,
          sexo: SEXO,
          tipoPersona: TIPO_PERSONA,
          sigla: SIGLA,
          provincia: PROVINCIA,
          municipio: MUNICIPIO,
          codigoPostal: CODIGO_POSTAL,
          numero: NUMERO,
          escalera: ESCALERA,
          piso: PISO,
          puerta: PUERTA,
          extensionTelefonoPersonal: EXTENSION_TELEFONO_PERSONAL,
          extensionTelefonoProfesional: EXTENSION_TELEFONO_PROFESIONAL,
          puestoTrabajo: DESCRIPCION_PUESTO_TRABAJO,
          categoria: DESCRIPCION_CATEGORIA,
          convenio: DESCRIPCION_CONVENIO,
          codigoEmpresa: EMPRESA,
          nombreEmpresa: EMPRESA_NOMBRE,
          codigoCentro: CENTRO,
          telefonoPersonal: TELEFONO_PESONAL,
          mailProfesional: MAIL_PROFESIONAL,
          telefonoProfesional: TELFONO_PROFESIONAL,
          nombreCentro: NOMBRE_CENTRO
         } = data;
   const newObject = {
                      CODIGO,
                      NOMBRE,
                      APELLIDO1,
                      APELLIDO2,
                      TIPO_DOCUMENTO,
                      NIF,
                      FECHA_NACIMIENTO,
                      MAIL_PERSONAL,
                      NACIONALIDAD,
                      LUGAR_NACIMIENTO,
                      FECHA_ALTA,
                      EMPRESA_CIF,
                      ESTADO_CIVIL,
                      SEXO,
                      TIPO_PERSONA,
                      SIGLA,
                      PROVINCIA,
                      MUNICIPIO,
                      CODIGO_POSTAL,
                      NUMERO,
                      ESCALERA,
                      PISO,
                      PUERTA,
                      EXTENSION_TELEFONO_PERSONAL,
                      EXTENSION_TELEFONO_PROFESIONAL,
                      DESCRIPCION_PUESTO_TRABAJO,
                      DESCRIPCION_CATEGORIA,
                      DESCRIPCION_CONVENIO,
                      EMPRESA,
                      EMPRESA_NOMBRE,
                      CENTRO,
                      TELEFONO_PESONAL,
                      MAIL_PROFESIONAL,
                      TELFONO_PROFESIONAL,
                      NOMBRE_CENTRO
                      }

    return newObject
  }

}
