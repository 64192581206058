import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-standar-layout',
  templateUrl: './standar-layout.component.html',
  styleUrls: ['./standar-layout.component.css']
})
export class StandarLayoutComponent implements OnInit {

  menuVisible: boolean = true;

  constructor() { }

  ngOnInit(): void {
    //QUitamos el pie
    document.body.classList.add('no-footer');
  }

  plegarMenu(event: boolean){
    this.menuVisible = event;
  }

}
