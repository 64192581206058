import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { LoginRequestData } from '../../models/login-request-data';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

  procesando: boolean = false;
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  year: number = new Date().getFullYear();
  constructor(private loginService: LoginService,
    private datosFirmasService: DatosFirmaService, private router: Router, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.inicalizaFormulario();
  }

  inicalizaFormulario(){
    this.formGroup = new UntypedFormGroup({
      Username: new UntypedFormControl('',[Validators.required]),
      Password:new UntypedFormControl('',[Validators.required])
    });
  }

  // Validacion campo username del formulario
  public usernameOk():boolean{
    return this.formGroup.controls['Username'].valid;
  }

  public usernameError(): string{
    let errors = this.formGroup.controls['Username'].errors;
    if(errors && errors.required) return 'Usuario obligatorio';
    else return '';
  }

  // Validacion campo password del formulario
  public passwordOk():boolean{
    return this.formGroup.controls['Password'].valid;
  }

  public passwordError(): string{
    let errors = this.formGroup.controls['Password'].errors;
    if(errors && errors.required) return 'Contraseña obligatorio';
    else return '';
  }

  public loginOk():boolean{
    return this.formGroup.controls['Username'].valid && this.formGroup.controls['Password'].valid;
  }

  hacerLogin(){
    if(this.formGroup.valid){
      this.procesando = true;
      let loginData: LoginRequestData = {
        Username: this.formGroup.controls['Username'].value,
        Password: this.formGroup.controls['Password'].value
      };
      this.loginService.login(loginData).subscribe(
        ok => {

          this.datosFirmasService.GetFirmas().subscribe(
            ok => {

              // Redirigir al menu principal
              this.procesando = false;
              // this.datosFirmasService.Filter();
              this.datosFirmasService.filtrarNuevo(this.datosFirmasService.filtrosConEstado);


            },
            error => {
              console.error(error);
            });
            //MIentras recuperamos las firmas vamos al menu principal.
            this.router.navigate(["/panel/main"]);
        },
        error =>{
          // Mostrar mensaje de error
          console.error(error);
          let errorMsg = (error.status == 401) ? 'Usuario y/o contraseña no válidos' : error.message;
          this.procesando = false;
          this._snackBar.open(errorMsg, 'Cerrar', {duration: 3000});
        });


    }

  }
}
