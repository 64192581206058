<h5>Dibujada</h5>
<p style="font-weight: 500;">Emplazamiento del campo de firma</p>
<p>¿Cómo se emplazará su firma en el documento?</p>
<form [formGroup]="formFirma">
  <div class="input-firmante">
    <div class="input-radio">
      <input type="radio" name="tieneTag" formControlName="tieneTag" [value]=false>
    </div>

    <label for="tieneTag">Trazarlo en el documento</label>
  </div>
  <div class="input-firmante" style="margin-top: 10px">
    <div class="input-radio">
      <input type="radio" name="tieneTag" formControlName="tieneTag" [value]=true>
    </div>
    <label for="tieneTag">Mediante tag: emplazar la firma donde se encuentre un texto específico</label>
  </div>
  <ul>
    <li>Tamaño por defecto (modificable mediante petición a nuestro equipo)</li>
    <li>Recomendamos usar el tag (texto específico): “FirmaSqp”.</li>
  </ul>
</form>
<div *ngIf="tieneTag.value == null">
  <div class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValidoArray('firmas', obtenerIndexNifArray())">
    {{formularioValidacionesDocsService.mensajeErrorTieneTag}}
  </div>
</div>
