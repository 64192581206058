<div class="container">
  <div *ngIf="mostrarConfiguracionUsuario" class="flex" (click)="avisaCerrarConfiguraciones('configuracion')">
    <svg class="icon">
      <use xlink:href="#icon-conf-perfil"></use>
    </svg>
    <span>Configurar perfil</span>
  </div>
  <!-- <div *ngIf="multipleTenants" class="flex" (click)="cambiarEntorno()"> -->
  <div *ngIf="loginService.getTenantToken().tenants.length>1" class="flex" (click)="avisaCerrarConfiguraciones('entorno')">
    <svg class="icon">
      <use xlink:href="#icon-switch"></use>
    </svg>
    <span>Cambiar de entorno</span>
  </div>
  <div class="flex" (click)="avisaCerrarConfiguraciones('logout')">
    <svg class="icon">
      <use xlink:href="#icon-cerrar-sesion"></use>
    </svg>
    <span>Cerrar sesión</span>
  </div>
</div>
