<div class="flex">
  <span class="titulo">REMITENTE</span>
  <span (click)="mostrarRemitente()">
    <svg class="icon-arrow" [ngClass]="firmanteRemitente ? 'icon-arrow-down':''">
      <use xlink:href="#hs-arrow"></use>
    </svg>
  </span>
</div>
<div *ngIf="firmanteRemitente" class="remitente">
  {{remitente}}
</div>
