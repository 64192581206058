import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EmpresasModel } from 'src/app/pages/configuracion/maestros/empresas/models/empresas-model';
import { IRecuperarEmpresasService } from './ports/i-recuperar-empresas-service';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { map } from 'rxjs/operators';
import { ConversionDatosApiService } from 'src/app/shared/services/conversion-datos-api.service';

@Injectable({
  providedIn: 'root'
})
export class RecuperaEmpresasApiService implements IRecuperarEmpresasService{

  private readonly URL_GET_EMPRESAS: string = this.endpointService.UrlGetEmpresas;
  private readonly URL_GUARDA_EMPRESA: string = this.endpointService.UrlGuardaEmpresa;
  private readonly URL_DELETE_EMPRESA: string = this.endpointService.UrlDeleteEmpresa;

  empresas: any;
  refreshEmpresas: boolean;
  countEmpresas: number = 0

  constructor(private endpointService: EndpointService,
              private client: HttpClient,
              private loginService: LoginService,
              private conversionDatosApiService: ConversionDatosApiService) { }

  getEmpresas(): Observable<EmpresasModel[]> {
    if(this.empresas && !this.refreshEmpresas){
      this.refreshEmpresas = false;
      return of(this.empresas)
    }else{
      const options = {
        headers: new HttpHeaders({
          'Content-Type':'application/json',
          'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
        })
      };
      return this.client.get(this.URL_GET_EMPRESAS, options).pipe(
        map((response: any) =>{
          if (response) {
            this.empresas = this.conversionDatosApiService.mapEmpresasEntrada(response)
            this.refreshEmpresas = false;
            return this.empresas;
          }
          else throw new Error(response.Message);
        })
      );
    }

  }

  guardaEmpresa(data:any): Observable<void>{
    data = this.conversionDatosApiService.mapEmpresasSalida(data);

    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.client.post<void>(this.URL_GUARDA_EMPRESA, data, options)
  }

  deleteEmpresa(data:any): Observable<void>{
    data = this.conversionDatosApiService.mapEmpresasSalida(data);
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    let url = this.URL_DELETE_EMPRESA.replace('{codigoEmpresa}', data.CodigoEmpresa);
    return this.client.delete<void>(url, options);
  }
}
