import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { EmpresasModel } from "../models/empresas-model";

export function validacionCodigoEditar(empresas: EmpresasModel[], empresa: EmpresasModel, campo: string): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    if (value == '') {
        return {inputVacio: true};
    } else {
      if(campo.toUpperCase() == 'CODIGOEMPRESA') {
        if(value.length>25) return {codigoLargo: true};
        let indexCodigo = empresas.findIndex(x=> x.codigoEmpresa == value);
        if(indexCodigo>-1) return {codigoExiste: true};
        else return null;
      } else if (campo.toUpperCase() == 'CIF') {
        // if(value.length>25) return {codigoLargo: true};
        let indexCif = empresas.findIndex(x=> x.cif == value);
        if(indexCif>-1){
          let indexCodigo = empresas.findIndex(x=> x.codigoEmpresa == empresa.codigoEmpresa);
          if(indexCodigo >-1 && indexCif==indexCodigo){
            return null
          }else{
            return {cifExiste:true}
          }
        }
        else return null;
      }
    }
    return null;
  }
}

export function validacionCodigoNuevo(empresas: EmpresasModel[], campo: string): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    if (value == '') {
        return {inputVacio: true};
    } else {
      if(campo.toUpperCase() == 'CODIGOEMPRESA') {
        if(value.length>25) return {codigoLargo: true};
        let indexCodigo = empresas.findIndex(x=> x.codigoEmpresa == value);
        if(indexCodigo>-1) return {codigoExiste: true};
        else return null;
      } else if (campo.toUpperCase() == 'CIF') {
        // if(value.length>25) return {codigoLargo: true};
        let indexCif = empresas.findIndex(x=> x.cif == value);
        if(indexCif > -1) return {cifExiste: true};
        else return null;
      }
    }
    return null;
  }
}

