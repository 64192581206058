
<app-upload-file-datos-documento *ngIf="!pdfCargado" (fileListEmitter)="uploadFiles($event)" [accept]="accept" [textoFondo]="'Arrastra aquí el documento PDF o haz click para seleccionarlo'" [configDoc]="true"
></app-upload-file-datos-documento>

<div *ngIf="pdfCargado" class="pdf-container" [style.position]="'relative'" >
  <div class="controls">
    <div class="pages">
      <button class="total" (click)="cambioPagina(-1)" [disabled]="page === 1">
        <svg class="icon">
          <use xlink:href="#icon-minus"></use>
        </svg>
      </button>
      <input type="number" #inputPagina value={{page}} (keyup)="irPagina(inputPagina.value)">
      <span class="total">/ {{totalPages}}</span>
      <button class="total" (click)="cambioPagina(+1)" [disabled]="page === totalPages">
        <svg class="icon">
          <use xlink:href="#icon-plus"></use>
        </svg>
      </button>
    </div>
  </div>
  <app-doc-drag *ngFor="let palabra of palabrasArrayPagina; let i = index"
                [palabra]="palabra"
                [i]="i"
                [id]="palabra.id"
                [pagina]="palabra.pagina"
                [x]=palabra.x
                [y]=palabra.y
                [Scrolly]=scrolltop
                (coordAfterDrag)="getNuevasCoord($event)"
                (borrarPalabra)="borrarPalabraArray($event)"></app-doc-drag>
  <app-firmas-dibujo *ngFor="let firma of firmasArrayFirmante; let i = index "
                     [firma]="firma"
                     [i]="i"
                     [id]="firma.id"
                     [pagina]="firma.pagina"
                     [x]=firma.x
                     [y]=firma.y
                     [Scrolly]=scrolltop
                     (coordAfterDrag)="getNuevasCoordFirma($event)"
                     (borrarFirma)="borrarFirmaArray($event)"></app-firmas-dibujo>

  <app-casillas-dibujo  *ngFor="let casilla of casillasArrayFirmante; let i = index "
                        [casilla]="casilla"
                        [i]="i"
                        [id]="casilla.id"
                        [pagina]="casilla.pagina"
                        [x]=casilla.x
                        [y]=casilla.y
                        [Scrolly]=scrolltop
                        (coordAfterDrag)="getNuevasCoordCasillas($event)"
                        (borrarCasilla)="borrarCasillaArray($event)"></app-casillas-dibujo>
  <app-campos-dibujo  *ngFor="let campo of camposArrayFirmante; let i = index "
                        [campo]="campo"
                        [i]="i"
                        [id]="campo.id"
                        [pagina]="campo.pagina"
                        [x]=campo.x
                        [y]=campo.y
                        [Scrolly]=scrolltop
                        (coordAfterDrag)="getNuevasCoordCampo($event)"
                        (borrarCampo)="borrarCampoArray($event)"
                        ></app-campos-dibujo>


  <div #pdfDoc>
    <mat-spinner *ngIf="cargando"  diameter="35" strokeWidth="2"></mat-spinner>
    <pdf-viewer id="pdConten"
      [src]="src"
      [rotation]="0"
      [original-size]="false"
      [show-all]="false"
      [page]="page"
      [fit-to-page]="true"
      [zoom]="1"
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="false"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      (after-load-complete)="afterLoadComplete($event)"
      class="pdf-viewer"
      (pages-initialized)="verCambios()"
      (on-progress)="showSpinner($event)"
      (page-rendered)="pageRendered($event)"
    >
    </pdf-viewer>
  </div>
</div>

