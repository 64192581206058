<div class="main">
  <h2>Identificación del contenido</h2>
  <!-- <div *ngIf="!generico.value && !esCopiaBasica.value"> -->
  <div *ngIf="!generico.value">
    <h5>NIF de los firmantes</h5>
    <p>Selecciona el Nif de los firmantes en el documento</p>
    <app-configuracion-documento-icon-info [nombre]="'NIF'"></app-configuracion-documento-icon-info>
  </div>
  <!-- <div *ngIf="generico.value || esCopiaBasica.value"> -->
  <div *ngIf="generico.value">
    <h5 class="incluirFirmantes">Incluir firmantes</h5>
  </div>
  <mat-spinner *ngIf="tiposFirmantes.length==0" diameter="30" strokeWidth="2" style="margin: auto; margin-top: 100px;"></mat-spinner>
  <form [formGroup]="formularioDocumentoService.formularioDocumento" *ngIf="tiposFirmantes.length>0">
    <app-configuracion-documento-datos-nif *ngFor="let tipo of tiposFirmantes" [tipoFirmante]="tipo" [idPdf]="idPdf"></app-configuracion-documento-datos-nif>
  </form>
</div>

