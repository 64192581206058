import { Component, Input, OnInit } from '@angular/core';
import { ResumenFirmas } from 'src/app/pages/panelControl/models/resumen-firmas';
import { DatosFirmaService } from 'src/app/pages/panelControl/services/datos-firma.service';
import { OpcionesMeuMultiple } from '../../model/opciones-meu-multiple';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Firmas } from 'src/app/pages/panelControl/models/firmas';
import { environment } from 'src/environments/environment';
import { DatosEnviosService } from 'src/app/pages/configuracion/Envios/envios/services/datos-envios.service';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { UsuarioModel } from 'src/app/pages/seguridad/models/usuario-model';
import { Router } from '@angular/router';
import { RecuperaConfiguracionAplicacionApiService } from 'src/app/pages/configuracion/configuracion-rlt/services/recupera-configuracion-aplicacion-api.service';
import { TooltipMessages } from 'src/app/shared/models/tooltip.mesages';


@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

  opcionPrincipalEnvio: OpcionesMeuMultiple = {Icono: '#icon-send', Texto: 'Nuevo envío'};
  public opcionesEnvio: OpcionesMeuMultiple[] = [
    new OpcionesMeuMultiple('#icon-building-fill','Estándar'),
    new OpcionesMeuMultiple('#icon-universal','Universal'),
  ]

  // opcionPrincipalConfiguracion: OpcionesMeuMultiple = {Icono: '#icon-config', Texto: 'Configuración'};
  // public opcionesConfiguracion: OpcionesMeuMultiple[] = [
  //   new OpcionesMeuMultiple('#icon-building-single','Centro'),
  //   new OpcionesMeuMultiple('#icon-building-fill','Empresa'),
  // ]

  opcionPrincipalDocumentos: OpcionesMeuMultiple = {Icono: '#icon-doc', Texto: 'Documentos'};
  public opcionesDocumentos: OpcionesMeuMultiple[] = [
    new OpcionesMeuMultiple('#icon-plantilla-fill','Nuevo doc. genérico'),
    new OpcionesMeuMultiple('#icon-personal-fill','Nuevo doc. a procesar'),
  ]

   firmasResumen: ResumenFirmas[] = [];
   ultimasFirmas: Firmas[] = [];
   versionDesarrollo: boolean;
   iconoConfig: string = '#icon-config';
   numEnviosIncompletos: string = '-';
   opcionPrincipalDosieres: OpcionesMeuMultiple = {Icono: '#icon-dosier', Texto: 'Dosieres'};
   public opcionesDosieres: OpcionesMeuMultiple[] = [
    new OpcionesMeuMultiple('#icon-dosier','Nuevo dosier'),
      new OpcionesMeuMultiple('-','Incompletos')
   ];

   usuarioActivo: UsuarioModel;
   nuevasFirmas: number = 6;
   accesoDirectoTitulo: string;
   accesoDirectoTexto: string;

  constructor(public datosFirmasService: DatosFirmaService,
              private _snackBar: MatSnackBar,
              private datosEnviosService: DatosEnviosService,
              private loginService: LoginService,
              private router: Router,) {}

  async ngOnInit() {
    await this.datosEnviosService.getTipoEnvios();
    this.numEnviosIncompletos = this.datosEnviosService.enviosFiltrados.filter(x=> x.estado=='Incompleto').length.toString();
    this.opcionesDosieres = [
      new OpcionesMeuMultiple('#icon-dosier','Nuevo dosier'),
      new OpcionesMeuMultiple(this.numEnviosIncompletos,'Incompletos')
    ]
    this.cargarFirmasResumen();
    //Cuando se haya acabado de cargar cargamos los datos
    this.datosFirmasService.FinCarga().then(res=>{
      this.ultimasFirmas =  this.datosFirmasService.GetUltimasFirmas();
      this.ultimasFirmas.map(x => x.empleado = x.empleado.toLowerCase());
    });
  }

  async cargarFirmasResumen(){
     await this.datosFirmasService.GetResumen(true).then(resp=>this.firmasResumen=resp).catch(error=>{

        // Mostrar mensaje de error
        console.error(error);

        this._snackBar.open('Se ha producido un error cargando firmas: '+error.message, 'Cerrar', {duration: 3000});
      });

    this.firmasResumen = this.firmasResumen.filter(x=>x.estado!='TODOS');

  }

  getNameLogin(){
    let userData = this.loginService.getUserLocalStorage();
    return (userData.User.Nombre) ? `${userData.User.Nombre}` : userData.User.Login;
  }

  navega(id: string){
    switch(id) {
      case 'NUEVAS':
        this.router.navigate(['/panel/envios/nuevos']);
        break;
      case 'ESTANDAR':
        this.router.navigate(['/panel/subida'], {queryParams: {'envio': 'estandar'}});
        break;
      case 'UNIVERSAL':
        this.router.navigate(['/panel/subida'], {queryParams: {'envio': 'universal'}});
        break;
      case 'FIRMAS':
        this.router.navigate(['/panel/envios']);
        break;
      case 'EMPLEADOS':
        this.router.navigate(['/panel/configuracion/empleados']);
        break;
      case 'DOCUMENTOS':
        this.router.navigate(['/panel/documentos-configurados']);
        break;
      case 'INCOMPLETOS':
        this.router.navigate(['/panel/dosieres-configurados/incompletos'])
    }
  }

  getTooltip(){
    return TooltipMessages.envio_nuevos;

  }
}
