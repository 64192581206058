
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DocumentoFase } from 'src/app/pages/panelControl/models/documento-fase';
import { Firmas } from 'src/app/pages/panelControl/models/firmas';
import { nullFirma } from '../../models/firmas';
import { Documento } from '../../models/documento';
import { TipoDocumento } from 'src/app/pages/configuracion/Envios/documentos/models/tipo-documento';
import { EstadosFirmaService } from '../../services/estados-firma.service';
import { DetallesEstado } from '../../models/detalles-estado';


@Component({
  selector: 'app-detalle-firma-documentos',
  templateUrl: './detalle-firma-documentos.component.html',
  styleUrls: ['./detalle-firma-documentos.component.css']
})
export class DetalleFirmaDocumentosComponent implements OnInit {
  // readonly ICONO_FICHERO_COMPLETADO:string = 'icon-file';
  readonly ICONO_FICHERO_COMPLETADO:string = 'hs-doc';
  // readonly ICONO_FICHERO_INCOMPLETO:string = 'icon-close';
  readonly ICONO_FICHERO_INCOMPLETO:string = 'hs-close';
  @Input() firma: Firmas = nullFirma();
  // panelOpenState: boolean = false;
  // estadoFirma: string = '';
  // estadoFirmantes: any[] = [];
  firmanteDosier: boolean = true;
  @Input() mostrarDetalles: {count: number, abrir: boolean};
  @Output() switchDocumentosEmitter: EventEmitter<DetallesEstado> = new EventEmitter<DetallesEstado>();

  constructor( private estdosFirmaService:EstadosFirmaService) { }

  ngOnChanges(changes: SimpleChanges){
    if(changes.mostrarDetalles.currentValue.count>0){
      this.firmanteDosier = changes.mostrarDetalles.currentValue.abrir;
      this.switchDocumentosEmitter.emit({tipo: 'documentos', estado: this.firmanteDosier});
    }

  }

  ngOnInit(): void {
  }

  get numeroDocsNecesarios():string{
    if(this.firma.docNecesarios==1){
      return `(${this.firma.docNecesarios} doc)`;
    }else{
      return `(${this.firma.docNecesarios} docs)`;
    }

  }

  iconoEstadoDocumento(doc:TipoDocumento){

    if(doc.generico) return this.ICONO_FICHERO_COMPLETADO;
    let elem = this.firma.documentos.find(x=>x.idTipoDocumento == doc.idTipoDocumento);
    if(doc.fusionable) elem = this.getDocumentoOrigenFusionable();

    if(elem){
      return this.ICONO_FICHERO_COMPLETADO;
    }else{
      return this.ICONO_FICHERO_INCOMPLETO;
    }
  }

  estadoDocumentoFirma(tipdoc:TipoDocumento, esIcono: boolean){
    let doc:Documento|undefined;
    if(tipdoc.fusionable){
      doc = this.getDocumentoOrigenFusionable();
    } else{
      doc = this.firma.documentos.find(x=>x.idTipoDocumento==tipdoc.idTipoDocumento);
    }
    switch(doc?.estado){
      case 0://NO_FIRMADO
        if(esIcono) return this.estdosFirmaService.GetIcono('No Firmado');
        else return 'No Firmado';
      case 1://FIRMADO
        if(esIcono) return this.estdosFirmaService.GetIcono('Firmado');
        else return 'Firmado';
      case 2://ERROR
        if(esIcono) return this.estdosFirmaService.GetIcono('Error');
        else return 'Error';
      case 3://CADUCADO
        if(esIcono)return this.estdosFirmaService.GetIcono('CADUCADO');
        else return 'Caducado';
      case 4://RECHAZADO
        if(esIcono) return this.estdosFirmaService.GetIcono('RECHAZADO');
        else return 'Rechazado';
      case 5://ENVIADA_A_FIRMAR
        if(esIcono) return this.estdosFirmaService.GetIcono('BORRADOR');
        else return 'Borrador';
      case 6://CANCELADO
        if(esIcono) return this.estdosFirmaService.GetIcono('CANCELADO');
        else return 'Cancelado';
      default:
        if(esIcono) return this.estdosFirmaService.GetIcono('Error');
        else return 'Error';
    }
  }


  documentoEntregado(tipoDocumento: TipoDocumento){
    if (tipoDocumento.generico) return true;
    else return this.firma?.documentos.find(x => x.idTipoDocumento == tipoDocumento.idTipoDocumento) != undefined;
  }

  getDocumentoOrigenFusionable(){
    // Si es fusionable obtenemos el que tenga orden fusion 1, ya que es el único que se reconoce
    let docCombinados = this.firma.tiposDocumento.find(x => x.fusionable && x.ordenFusion && x.ordenFusion <= 1);
    return this.firma.documentos.find(x=>  docCombinados != undefined && x.idTipoDocumento == docCombinados.idTipoDocumento);
  }

  mostrarDosieres(){
    this.firmanteDosier = !this.firmanteDosier;
    this.switchDocumentosEmitter.emit({tipo: 'documentos', estado: this.firmanteDosier});
  }
}
