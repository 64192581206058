import { HttpErrorResponse } from '@angular/common/http';
import { IfStmt } from '@angular/compiler';
import { Component, EventEmitter, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber, Subscription } from 'rxjs';
import { DatosSubidaService } from 'src/app/pages/subidaPrincipal/services/datos-subida.service';
import { DownloadFilesService } from '../../../../shared/accesoApi/download-files.service';
import { SubidaArchivoComponent } from '../subida-archivo/subida-archivo.component';
import { DatosFirmaService } from '../../../panelControl/services/datos-firma.service';
import { DatosEnviosService } from 'src/app/pages/configuracion/Envios/envios/services/datos-envios.service';
import { TipoEnvio } from 'src/app/pages/configuracion/Envios/envios/models/tipo-envio';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { ConfirmationMessageComponent } from 'src/app/shared/components/modals/confirmation-message/confirmation-message.component';
import { TipoMensajeConfirmacion } from 'src/app/shared/models/confirmation-message-data';
import { UserValid4Sandbox } from 'src/app/pages/login/models/user-valid4sandbox';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BreadcrumbsService } from 'src/app/shared/services/breadcrumbs.service';

@Component({
  selector: 'app-subida-principal',
  templateUrl: './subida-principal.component.html',
  styleUrls: ['./subida-principal.component.css']
})
export class SubidaPrincipalComponent implements OnInit,OnDestroy
 {

  panelOculto:boolean=false;
  tiposEnvio: TipoEnvio[] = [];
  tiposEnvioCargados: boolean = false;
  private tiposEnvioCompleto:TipoEnvio[]=[];
  titulo: string = "";
  subtitulo: string = "";
  porcentajeSubida: string = "0%";
  detallesSubida: string = "";
  errorSubida: boolean = false;
  private intervalLogSubida: any;
  private subidaCancelada: boolean = false;
  private suscritpon!:Subscription;
  private timerActivo:boolean = false;
  finalizarProceso: boolean = false;
  @ViewChild(SubidaArchivoComponent) uplodad!: SubidaArchivoComponent;
  form: UntypedFormGroup = new UntypedFormGroup({});
  entornoSandbox: UntypedFormControl = new UntypedFormControl(false);
  mostrarEntornoSandbox: boolean = false;
  mostrarAvisoUsuarioMailTest: boolean = false;

  constructor( public datosTipoEnvioService: DatosEnviosService,
              public datosSubidaService: DatosSubidaService,
              private router: Router,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog,
              private downloadFilesService:DownloadFilesService,
              private datosFirmasService:DatosFirmaService,
              private activatedRoute: ActivatedRoute,
              private loginService: LoginService,
              private breadcrumbsService: BreadcrumbsService) {
this.form.addControl('entornoSandbox', this.entornoSandbox);
    this.datosSubidaService.getIdActiva().subscribe(
      ok=>{
        if(this.datosSubidaService.datosProcesoServidor.codigoSubida>0){
          this.suscritpon =   this.datosSubidaService.datosProcesosAnteriores.subscribe(next=>console.log(next));
        }
      },
      error=>{
        console.log("No hay logs de subida previos");
      }
    );

  }

  ngOnInit(): void {
    this.isValidUserEnvioTest();
    this.inicializa();
    this.cargarTiposEnvio();
  }

  ngOnDestroy(): void {
    if(this.suscritpon){
      clearInterval(this.datosSubidaService.interval)
      this.suscritpon.unsubscribe();
    }
  }

  inicializa(){
    this.datosSubidaService.incializaSubida();
    this.datosSubidaService.pasos = this.datosSubidaService.PASOS_ENVIO_NORMAL;
    this.finalizarProceso = false;
    this.subidaCancelada = false;
    this.datosSubidaService.tipoSubida = "";
    this.titulo = "Realizar un nuevo envío";
    this.subtitulo = this.datosSubidaService.pasos[this.datosSubidaService.indexStepActive];
    this.datosSubidaService.tipoEnvio = undefined;
  }

  // cargarTiposEnvio(){
  //   this.tiposEnvioCargados = false;
  //   this.activatedRoute.queryParams.subscribe(params => {
  //     let envio = params['envio'];
  //     // Movemos a la pantalla correspondiente al leer el parámetro
  //     if  (envio == "estandar") this.selectTipoSubida(this.datosSubidaService.SUBIDA_NORMAL);
  //     else if (envio == "universal") this.selectTipoSubida(this.datosSubidaService.SUBIDA_MASIVA);
  //     this.datosTipoEnvioService.getTipoEnvios().subscribe(
  //       next =>{
  //         this.tiposEnvioCargados = true;
  //         this.tiposEnvioCompleto = next;
  //         // Con los tipos de envío ya cargados, los mostramos según el filtro
  //         if  (envio == "estandar") this.selectTipoSubida(this.datosSubidaService.SUBIDA_NORMAL);
  //         else if (envio == "universal") this.selectTipoSubida(this.datosSubidaService.SUBIDA_MASIVA);
  //       },
  //       error => {
  //         this.tiposEnvioCargados = true;
  //         this._snackBar.open(error, 'Cerrar',{duration: 3000});
  //       },
  //     );
  //   });
  // }

  async cargarTiposEnvio(){
    this.tiposEnvioCargados = false;
    this.activatedRoute.queryParams.subscribe(async params => {

      let envio = params['envio'];

      // Movemos a la pantalla correspondiente al leer el parámetro
      if  (envio == "estandar") {
        this.selectTipoSubida(this.datosSubidaService.SUBIDA_NORMAL);
        this.breadcrumbsService.addBreadcrumbs('Envío estándar');
      }
      else if (envio == "universal") {
        this.selectTipoSubida(this.datosSubidaService.SUBIDA_MASIVA);
        this.breadcrumbsService.addBreadcrumbs('Envío universal');
      }
      await this.datosTipoEnvioService.getTipoEnvios();

      this.tiposEnvioCargados = true;
      // this.tiposEnvioCompleto = this.datosTipoEnvioService.enviosFiltrados;
      this.tiposEnvioCompleto = this.datosTipoEnvioService.enviosIniciales;
      // Con los tipos de envío ya cargados, los mostramos según el filtro
      if  (envio == "estandar") this.selectTipoSubida(this.datosSubidaService.SUBIDA_NORMAL);
      else if (envio == "universal") this.selectTipoSubida(this.datosSubidaService.SUBIDA_MASIVA);

    });
  }

  selectTipoSubida(tipoSubida: any){
    this.tiposEnvio = [];
    if(!this.datosSubidaService.tipoSubida) {
      this.datosSubidaService.indexStepActive++;
      this.datosSubidaService.stepActive = this.datosSubidaService.pasos[this.datosSubidaService.indexStepActive];
    }
    this.datosSubidaService.tipoSubida = tipoSubida;
    if(tipoSubida.toUpperCase() == this.datosSubidaService.SUBIDA_NORMAL) {
      this.titulo = "Envío estándar";
      this.datosSubidaService.pasos =  this.datosSubidaService.PASOS_ENVIO_NORMAL;
      this.datosSubidaService.tipoSubida = this.datosSubidaService.SUBIDA_NORMAL;
      // Volvemos a entrar en esta funcion cada 1s si no se han cargado los tipos de envio
      if(!this.tiposEnvioCargados && this.tiposEnvioCompleto.length == 0) setTimeout(() =>{ this.selectTipoSubida(tipoSubida); }, 1000);
      else this.tiposEnvio = this.tiposEnvioCompleto.filter(x => !x.envioMasivo && x.tiposDocumento.length > 0);
    }
    else if(tipoSubida.toUpperCase() == this.datosSubidaService.SUBIDA_MASIVA) {
      this.titulo = "Envío universal";
      this.datosSubidaService.pasos =this.datosSubidaService.PASOS_ENVIO_MASIVO;
      this.datosSubidaService.tipoSubida = this.datosSubidaService.SUBIDA_MASIVA;
      // Volvemos a entrar en esta funcion cada 1s si no se han cargado los tipos de envio
      if(!this.tiposEnvioCargados && this.tiposEnvioCompleto.length == 0) setTimeout(() =>{ this.selectTipoSubida(tipoSubida); }, 1000);
      else this.tiposEnvio = this.tiposEnvioCompleto.filter(x => x.envioMasivo && x.tiposDocumento.length > 0);
    }
    this.subtitulo = this.datosSubidaService.pasos[this.datosSubidaService.indexStepActive];

  }

  selectTipoEnvio(idTipoEnvio: string){
    if(!this.datosSubidaService.tipoEnvio) {
      this.datosSubidaService.indexStepActive++;
      this.datosSubidaService.stepActive = this.datosSubidaService.pasos[this.datosSubidaService.indexStepActive];
    }
    this.datosSubidaService.tipoEnvio = this.tiposEnvio.find(x => x.idTipoEnvio == idTipoEnvio);
  }

  atras(){
    if(this.datosSubidaService.indexStepActive == 0){
      // Si le damos atras desde el paso inicial, retrocedemos en la página
      window.history.back();
      return;
    }
    else if((this.datosSubidaService.indexStepActive == this.datosSubidaService.PASOS_ENVIO_NORMAL.length - 1) || (this.datosSubidaService.indexStepActive == this.datosSubidaService.PASOS_ENVIO_MASIVO.length - 1)){
      // Si retrocedemos en el último paso (Procesamiento) iniciamos un nuevo proceso de subida
      this.inicializa();
      // Redirigimos para limpiar la url (no pasa por ngoninit)
      this.router.navigate(['/panel/subida']);
      return;
    }
    else if (this.datosSubidaService.indexStepActive > 0) {
      // Bajamos el paso activo si es necsario
      this.datosSubidaService.indexStepActive--;
    }
    // Controlamos el retroceder de paso en el stepper
    if (this.datosSubidaService.indexStepActive == 0) {
      // Iniciamos un nuevo proceso de subida
      this.inicializa();
      // Redirigimos para limpiar la url (no pasa por ngoninit)
      this.router.navigate(['/panel/subida']);
    }
    else if (this.datosSubidaService.indexStepActive == 1){
      this.datosSubidaService.tipoEnvio = undefined;
    }
    else if(this.datosSubidaService.indexStepActive > 1){
      this.datosSubidaService.files = [];
      this.datosSubidaService.datosProcesoServidor.dataLogSubida = [];
      this.errorSubida = false;
      // if(!this.subidaCancelada) this.cancelar(true);
    }
    this.datosSubidaService.stepActive = this.datosSubidaService.pasos[this.datosSubidaService.indexStepActive];
  }

  cancelar(){
    if(!this.subidaCancelada && (this.datosSubidaService.indexStepActive == this.datosSubidaService.PASOS_ENVIO_NORMAL.length - 1) || (this.datosSubidaService.indexStepActive == this.datosSubidaService.PASOS_ENVIO_MASIVO.length - 1))
    {
      if(this.datosSubidaService.datosProcesoServidor.codigoSubida != 0 && !this.finalizarProceso)
      {
        clearInterval(this.intervalLogSubida);
        this.datosSubidaService.cancelaSubida(this.datosSubidaService.datosProcesoServidor.codigoSubida).subscribe(
          next => {
            this._snackBar.open("Se ha cancelado la subida", 'Cerrar',{duration: 3000});
            this.inicializa();
            this.router.navigate(['/panel/main']);
          },
          error => {
            console.error(error);
          });
      } else if (this.finalizarProceso){
        this.inicializa();
        this.router.navigate(['/panel/main']);
      } else if (this.datosSubidaService.datosProcesoServidor.codigoSubida == 0){
        this._snackBar.open("No se han cargado los datos de la subida. Inténtelo de nuevo", 'Cerrar',{duration: 3000});
      }
    } else {
      this.inicializa();
      this.router.navigate(['/panel/main']);
    }
  }

  ocultar(event:boolean){
    this.panelOculto=event;
  }

  async uploadFiles(files: FileList){
    let error = false;
    if(this.datosSubidaService.faseSubidaExcel){
      this.uploadExcel(files);
      return;
    }
    const fileArray = Array.from(files);
    let cont =0;
    this.porcentajeSubida = "0%";
    if(fileArray.find(x => x.type != "application/pdf")) this._snackBar.open("Solo se admiten documentos PDF", 'Cerrar',{duration: 3000});
    else{
      //Inicalizamos la subida
      await this.datosSubidaService.getIdSubida();

      for(let i =0;i<fileArray.length;i++){
        if(error) return;
        await this.datosSubidaService.SubeFichero(fileArray[i]).catch((err:any)=>{
          this._snackBar.open(`No se puede subir el fichero ${fileArray[i].name} :${err.error.ExceptionMessage}`, 'Cerrar',{duration: 3000});
          this.datosSubidaService.indexStepActive--;
          this.datosSubidaService.stepActive = this.datosSubidaService.pasos[this.datosSubidaService.indexStepActive]
          error = true;
        });
        cont++;

        this.porcentajeSubida = `${((cont)/fileArray.length) * 100}%`;
        this.detallesSubida = `${(cont == fileArray.length) ? "Subidos" : "Subiendo"} ${(cont)} de ${fileArray.length}`;


      }
      //Inicializamos el proceso de subida

      if(!error){
        this.procesarEnvio();

      }

    }
  }
 async uploadExcel(files: FileList){

   if(files.length>1){
    this._snackBar.open("Solo se admite un excel", 'Cerrar',{duration: 3000});
   }
   if(!files[0].name.endsWith('.xlsx')){
     this._snackBar.open("Solo se admite ficheros .xlsx", 'Cerrar',{duration: 3000});
   }
  this.datosSubidaService.getEmpleados(files[0]).subscribe((ok)=>{

    this.datosSubidaService.indexStepActive++;
    this.datosSubidaService.stepActive = this.datosSubidaService.pasos[this.datosSubidaService.indexStepActive];
    this.uplodad.finalizaSubida();
    if(this.datosSubidaService.tipoEnvio?.envioMasivo && this.datosSubidaService.tipoEnvio?.esSoloGenericos) this.procesarEnvio();


  },
    (err)=>{
      switch(err.status){
        case 401:
          this._snackBar.open("No puede realizar esta accion", 'Cerrar',{duration: 3000});
          break;
        default:
          this._snackBar.open("Se ha producido el siguiente error: "+err.error.ExceptionMessage, 'Cerrar',{duration: 3000});
          break;

      }
    });

 }

 descagaFormatoExcel(){
   this.downloadFilesService.exportaFormatoExcel().subscribe();
 }
  toBase64(file: File){
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString().split(",")[1]);
      reader.onerror = error => reject(error);
    });
  }

  procesarEnvio(){
    this.finalizarProceso = false;
    this.datosSubidaService.procesaFicheros(this.datosSubidaService.datosProcesoServidor.codigoSubida, this.entornoSandbox.value).subscribe(
      next => {
        //Si es onpremise, no esperamos ya que la tarea programada se encarga
        if(next=='OnPremise'){
          this._snackBar.open('Los ficheros están en cola de salida. Puede comprobar el estado pasado unos minutos','Cerrar',
          {duration:3000});
          this.datosSubidaService.incializaSubida();
          this.finalizarProceso = true;
        }else{
          this.inicalizaTimmer();
          this.datosSubidaService.indexStepActive++;
          this.datosSubidaService.stepActive = this.datosSubidaService.pasos[this.datosSubidaService.indexStepActive];
        }
        this.porcentajeSubida = "100%";
      },
      error => {
        this._snackBar.open(`No se ha podido inicializar el proceso ${error.error.ExceptionMessage}`,'Cerrar',
          {duration:3000});
      }
    );


  }
  inicalizaTimmer(){
    if(this.timerActivo) return;
    this.timerActivo = true;
     this.porcentajeSubida = "0%";
     if(!this.suscritpon){
        this.suscritpon = this.datosSubidaService.datosProcesosAnteriores.subscribe();
     }
     this.intervalLogSubida = setInterval(() => {
       this.datosSubidaService.getLogSubida(this.datosSubidaService.datosProcesoServidor.codigoSubida).subscribe(
         next =>{
           if(this.datosSubidaService.datosProcesoServidor.sinConexion) {
           const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
             data: {
               tipo: TipoMensajeConfirmacion.Informacion,
               soloMensaje: 'Debe iniciar sesión con la API de a3Laboral desde el entorno de configuración',
               textoConfirmacion: 'Aceptar'
             },
           });
           this.finalizarProceso=true;
           dialogRef.afterClosed().subscribe(
            next => {
              this.loginService.loginA3Api().subscribe(
                next => {
                  window.location.href = next;
                },
                error => {});
            });
           }
           if(next) this.finalizarProceso=true;
           if(this.finalizarProceso)
           {
             //this.errorSubida = next;
             clearInterval(this.intervalLogSubida);
             this.timerActivo = false;
             if(this.finalizarProceso) this._snackBar.open('Se ha finalizado el proceso', 'Cerrar',{duration: 3000});
             this.datosFirmasService.refreshFirmas = true;
             this.datosFirmasService.GetFirmas().subscribe(
              ok => {

                // Redirigir al menu principal
                //this.procesando = false;
                // this.datosFirmasService.Filter();
                this.datosFirmasService.filtrarNuevo(this.datosFirmasService.filtrosConEstado);
                this.datosFirmasService.refreshFirmas = false;
              },
              error => {
                console.error(error);
              });
             //this.datosSubidaService.incializaSubida();
           }
         },
         (error: HttpErrorResponse) =>{
           this.errorSubida = true;
           this.finalizarProceso = true;
           this._snackBar.open(error.error, 'Cerrar',{duration: 3000});
           clearInterval(this.intervalLogSubida);
           this.timerActivo = false;
         }
       );
     }, 1000);

  }

  verDetalleSubidaAnterior(){
    if (this.datosSubidaService.pasos.length == 0) this.datosSubidaService.pasos = this.datosSubidaService.PASOS_ENVIO_NORMAL;
    if (this.datosSubidaService.tipoSubida == "") this.datosSubidaService.tipoSubida = this.datosSubidaService.SUBIDA_NORMAL;
    this.datosSubidaService.indexStepActive = this.datosSubidaService.pasos.length - 1;
    this.datosSubidaService.stepActive = this.datosSubidaService.pasos[this.datosSubidaService.indexStepActive];
    this.inicalizaTimmer();
  }


  async isValidUserEnvioTest(){
    await this.loginService.isValidUserEnvioTest().toPromise().then(
      ok => {
        if(ok == UserValid4Sandbox.VALID_USER) {
          this.mostrarEntornoSandbox = true;
          this.mostrarAvisoUsuarioMailTest = false;
        }
        else if(ok == UserValid4Sandbox.MAIL_TEST_MISSING) {
          this.mostrarEntornoSandbox = true;
          this.mostrarAvisoUsuarioMailTest = true;
        }else this.mostrarEntornoSandbox  = false;
       },
      error => { console.log(error); }
    );
  }

  selectIsEnvioEntornoSandbox(value: boolean){
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: {
        tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
        icono: '',
        titulo: value ? 'Activar SANDBOX' : 'Desactivar SANDBOX',
        textoConfirmacion: value ? 'Activar SANDBOX' : 'Desactivar SANDBOX',
        mensaje: value ? `<p>Vas a realizar un envío sin coste para comprobar la correcta configuración del envío.</p><p>Recibirás en tu e-mail los correos que según la configuración recibirían los firmantes/validadores indicados.</p>` :
        '<p>Desactivando el modo SANDBOX el dosier se va a enviar a todos los destinatarios para su firma.</p><p>¿Deseas salir del modo SANDBOX de prueba para realizar un envío real?</p>',
        iconoConfirmacion: '',
        textoCancelar: 'cancelar'
      },
    });
    dialogRef.afterClosed().subscribe(x => {
      if (x == "OK") {
        if (value && this.mostrarAvisoUsuarioMailTest) {
          const dialogRef2 = this.dialog.open(ConfirmationMessageComponent, {
            data: {
              tipo: TipoMensajeConfirmacion.ConfirmacionSimple,
              icono: '',
              titulo: 'No tienes definido ningún correo electrónico',
              textoConfirmacion: 'IR A CONFIGURACIÓN',
              mensaje: '<p>Para poder realizar los envíos de prueba es necesario un correo electrónico dónde se enviarán los documentos de prueba.</p><p>Accede a configuración de usuario para definir un correo electrónico y poder empezar a realizar envíos en modo SANDBOX.</p>',
              iconoConfirmacion: '',
              textoCancelar: 'cancelar'
            },
          });
          dialogRef2.afterClosed().subscribe(y => {
            if (y == "OK"){}
            else this.router.navigate(['/panel/main']);
          });
        }
      } else this.entornoSandbox.setValue(!value);
    });
  }
}
