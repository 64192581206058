<div class="barra-flex-space-between">
  <h1>Configuración de Usuario</h1>
  <!-- <app-boton-accion [id]="'guardar cambios usuario activo'" [icono]="'hs-guardar'" [titulo]="'guardar'" [outline]="false" (click)="guardarCambiosUsuarioActivo()"></app-boton-accion> -->
</div>
<div class="main">
  <div class="flex-container">
    <app-usuario-activo-menu (opcionElegida)="getOpcionSeleccionada($event)"></app-usuario-activo-menu>
    <app-usuario-activo-conf *ngIf="opcionMenu=='editar'" (guardarcambios)="guardarCambiosUsuarioActivo()"></app-usuario-activo-conf>
  </div>

</div>

<!-- <app-barra-footer [id]="'guardar cambios usuario activo'"
                  [icono]="'icon-save'"
                  [titulo]="'guardar'"
                  [dobleAccion]=false
                  (botonClicked)="guardarCambiosUsuarioActivo()"
                  ></app-barra-footer> -->
