import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { ConversionDatosApiService } from 'src/app/shared/services/conversion-datos-api.service';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { TiposContenido } from '../models/tipos-contenido';
import { IDatosConfiguracionService } from './ports/i-datos-configuracion-service';
import { FormularioDocumentoService } from './formulario-documento.service';

@Injectable({
  providedIn: 'root'
})
export class RecuperaTiposContenidoApiService implements IDatosConfiguracionService{

  private readonly URL_GET_TIPOS_CONTENIDO: string = this.endpointService.UrlGetTiposContenido;
  private readonly URL_GET_TIPOS_CONTENIDO_RLT: string = this.endpointService.UrlGetTiposContenidoRLT;

  tiposContenido: TiposContenido[];
  tiposContenidoRLT: TiposContenido[];

  constructor(private endpointService: EndpointService,
              private loginService: LoginService,
              private client: HttpClient,
              private conversionDatosApiService: ConversionDatosApiService,
              private formularioDocumentoService: FormularioDocumentoService) { }

  get esRLT(){ return this.formularioDocumentoService.formularioDocumento.controls['esRLT'].value};

  getTiposContenido(): Observable<TiposContenido[]> {
    if(this.esRLT && this.tiposContenidoRLT){
      return of(this.tiposContenidoRLT)
    }else if(!this.esRLT && this.tiposContenido){
      return of(this.tiposContenido)
    }else{
      const options = {
        headers: new HttpHeaders({
          'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
        })
      };

      return this.client.get(this.esRLT ? this.URL_GET_TIPOS_CONTENIDO_RLT : this.URL_GET_TIPOS_CONTENIDO, options).pipe(
        map((response: any) => {
          response = this.conversionDatosApiService.mapTiposContenidoTipoEntrada(response);
          if(this.esRLT) this.tiposContenidoRLT = response;
          else this.tiposContenido = response;
          return response;
        })
      );
    }

  }

}
