import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { EmpleadoCrud } from 'src/app/pages/empleados/models/empleado';

@Component({
  selector: 'app-pastilla-seleccion-multiple',
  templateUrl: './pastilla-seleccion-multiple.component.html',
  styleUrls: ['./pastilla-seleccion-multiple.component.css']
})
export class PastillaSeleccionMultipleComponent implements OnInit {

  @Input() textoMostrar: string = '';
  @Output() deseleccionarEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() borrarEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() tipoDato: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  deseleccionar(){
    this.deseleccionarEmitter.emit(true);
  }

  borrar(){
    this.borrarEmitter.emit(true);
  }

}
