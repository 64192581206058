<div class="flex">
  <span class="titulo">DETALLES DE LOS FIRMANTES/VALIDADORES</span>
  <span (click)="mostrarFirmantes()">
    <svg class="icon-arrow" [ngClass]="firmanteInfo ? 'icon-arrow-down':''">
      <use xlink:href="#hs-arrow"></use>
    </svg>
  </span>
</div>
<div *ngIf="firmanteInfo">
  <div *ngFor="let firmante of firmantes; let i = index;">
    <div class="signers-title">{{ (i == 0) ? 'Principal' : 'Secundario' }}</div>
    <div class="signers-name">{{firmante.name}} {{firmante.apellido1}} {{firmante.apellido2}}</div>
    <div class="signers-info">{{firmante.email}}</div>
    <div class="signers-info">{{firmante.telefono}}</div>
    <div class="signers-info">{{firmante.empresa}}</div>
  </div>
  <!-- <div class="signers" *ngFor="let firmante of firmantes; let i = index;">
    <div class="signers-title">{{ (i == 0) ? 'Principal' : 'Secundario' }}</div>
    <div class="signers-name">{{firmante.name}} {{firmante.apellido1}} {{firmante.apellido2}}</div>
    <div class="signers-info">{{firmante.email ? firmante.email : '-'}}</div>
    <div class="signers-info">{{firmante.telefono ? firmante.telefono : '-'}}</div>
    <div class="signers-info">{{firmante.empresa ? firmante.empresa : '-'}}</div>
  </div> -->
</div>
