<div class="container">
  <div class="top-info" [ngStyle]="{'background-color': isSandbox ? '#F7F4FF':''}">
    <ul>
      <li>Se envía un dosier con uno o más documentos a firmar.</li>
      <li>El dosier y sus documentos deben ser <strong>configurados previamente</strong>.</li>
      <li>Tipo de firmantes: empleados y no-empleados.</li>
      <li>Configuración previa: dosier y documentos requieren configuración previa. En caso de no tener acceso a la configuración será necesario solicitarlo a quien tenga estos permisos.</li>
    </ul>
    <h6>Instrucciones</h6>
    <div class="instructions">Selecciona el dosier a enviar para que se pueda comprobar que los documentos que subas más adelante son los correctos.</div>
  </div>
  <div class="content">
    <div class="form-title">¿Qué tipo de dosier quieres enviar?</div>
        <mat-spinner *ngIf="!tiposEnvioCargados" diameter="35" strokeWidth="2"></mat-spinner>
        <app-input-buscador *ngIf="tiposEnvioCargados" (buscador)="filtrar($event)"></app-input-buscador>
        <div class="mat-container" *ngIf="tiposEnvioCargados">
          <mat-accordion>
            <mat-radio-group>
              <mat-expansion-panel (opened)="panelOpenState" (closed)="!panelOpenState" *ngFor="let tipoEnvio of getTiposEnvioFiltro(); let i = index">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <!-- <mat-radio-button value="{{tipoEnvio.idTipoEnvio}}">{{tipoEnvio.nombre}}</mat-radio-button> -->
                    <mat-radio-button value="{{tipoEnvio.idTipoEnvio}}" (change)="selectTipoEnvio(tipoEnvio.idTipoEnvio)"></mat-radio-button>
                    <div class="titulo-envio">
                      {{tipoEnvio.nombre}}
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- <p>Contiene {{tipoEnvio.tiposDocumento.length}} documentos</p> -->
                <div class="flex-docs-subida">
                  <div>
                    <p class="tipo-docs">Documentos a subir</p>
                    <div class="flex-inner-docs">
                      <div *ngFor="let tipoDocumento of tipoEnvio.tiposDocumento">
                        <span *ngIf="!tipoDocumento.generico" class="titulo-doc" [ngStyle]="{'margin-right': !tipoDocumento.generico ? '25px':0}">
                          <svg class="icon">
                            <use xlink:href="#hs-doc-subir"></use>
                          </svg>
                          <span>
                            {{tipoDocumento.nombre}}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p class="tipo-docs">Documentos genéricos</p>
                    <div class="flex-inner-docs">
                      <div *ngFor="let tipoDocumento of tipoEnvio.tiposDocumento">
                        <span class="titulo-doc" *ngIf="tipoDocumento.generico" [ngStyle]="{'margin-right': tipoDocumento.generico ? '25px':0}">
                          <svg class="icon">
                            <use xlink:href="#hs-doc-ok"></use>
                          </svg>
                          <span>
                            {{tipoDocumento.nombre}}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-radio-group>
          </mat-accordion>
        </div>
  </div>
</div>

