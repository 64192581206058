import { Injectable } from '@angular/core';
import { IDatosEmpleadosService } from './i-datos-empleados-service';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { EmpleadoCrud } from '../../models/empleado';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from 'src/app/pages/login/services/login.service';
import { EndpointService } from 'src/app/shared/services/endpoint.service';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ConversionDatosApiService } from 'src/app/shared/services/conversion-datos-api.service';
import { RecuperaEmpresasApiService } from 'src/app/pages/configuracion/maestros/empresas/services/recupera-empresas-api.service';
import { DatosCentrosService } from 'src/app/pages/configuracion/maestros/centros/services/datos-centros.service';

@Injectable({
  providedIn: 'root'
})
export class RecuperaEmpleadosApiService implements IDatosEmpleadosService{

  private readonly URL_GET_EMPLEADOS: string = this.endpointService.UrlGetEmpleadosData;
  private readonly URL_UPDATE_EMPLEADO: string = this.endpointService.UrlUpdateEmpleado;
  private readonly URL_DELETE_EMPLEADO: string = this.endpointService.UrlDeleteEmpleado;
  private readonly URL_CONFIGURACION_APLICACION: string = this.endpointService.UrlGetConfiguracionAplicacion;
  empleados: EmpleadoCrud[];

  constructor(private loginService: LoginService,
              private endpointService: EndpointService,
              private httpClient: HttpClient,
              private conversionDatosApiService: ConversionDatosApiService,
              private datosEmpresasApiService : RecuperaEmpresasApiService,
              private datosCentrosService: DatosCentrosService) { }

  getEmpleados(refresh?: boolean): Observable<EmpleadoCrud[]> {
    if (this.empleados && !refresh) {
      return of(this.empleados);
    } else {
      let httpOptions: any = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "authorization": `Bearer ${this.loginService.getUserLocalStorage().Token.Token}`
        })
      };

      return this.httpClient.get(this.URL_GET_EMPLEADOS, httpOptions).pipe(
        switchMap((response: any) => {
          if (response) {
            this.empleados = this.conversionDatosApiService.mapEmpleadosEntrada(response);
            return this.datosCentrosService.getCentros().pipe(
              mergeMap((nextCentros: any) => {
                this.empleados.forEach((x: EmpleadoCrud) => {
                  if (x.codigoCentro) {
                    x.nombreCentro = nextCentros.find(y => x.codigoCentro == y.codigoCentro)?.nombreCentro;
                  }
                  if (x.codigoEmpresa && this.datosEmpresasApiService.empresas) {
                    x.nombreEmpresa = this.datosEmpresasApiService.empresas.find(y => x.codigoEmpresa == y.codigoEmpresa)?.nombreEmpresa;
                  }
                });
                this.empleados.forEach(x => {
                  x.nombre = x.nombre?.toUpperCase();
                  x.apellido1 = x.apellido1?.toUpperCase();
                  x.apellido2 = x.apellido2?.toUpperCase();
                  x.nif = x.nif?.toUpperCase();
                  x.nombreEmpresa = x.nombreEmpresa?.toUpperCase();
                  x.nombreCentro = x.nombreCentro?.toUpperCase();
                });
                return of(this.empleados);
              }),
              catchError(error => {
                // Error de centros
                return throwError(error);
              })
            );
          } else {
            throw new Error(response.Message);
          }
        }),
        catchError(error => {
          // Error de empleados
          return throwError(error);
        })
      );
    }
  }

  // getEmpleados2(refresh?: boolean): Observable<EmpleadoCrud[]> {
  //   if (this.empleados && !refresh) {
  //     return of(this.empleados);
  //   } else {
  //     let httpOptions: any = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         "authorization": `Bearer ${this.loginService.getUserLocalStorage().Token.Token}`
  //       })
  //     };

  //     return this.httpClient.get(this.URL_GET_EMPLEADOS, httpOptions).pipe(
  //       switchMap((response: any) => {
  //         if (response) {

  //           this.empleados = this.conversionDatosApiService.mapEmpleadosEntrada(response);

  //           // Use forkJoin to combine multiple observables
  //           return forkJoin([
  //             this.datosEmpresasApiService.getEmpresas(),
  //             this.datosCentrosService.getCentros()
  //           ]).pipe(
  //             map(([nextEmpresas, nextCentros]) => {
  //               this.empleados.forEach((x: EmpleadoCrud) => {
  //                 x.nombreEmpresa = nextEmpresas.find(y => x.codigoEmpresa == y.codigoEmpresa).nombreEmpresa;
  //                 x.nombreCentro = nextCentros.find(y => x.codigoCentro == y.codigoCentro).nombreCentro;
  //               });
  //               this.empleados.forEach(x => {
  //                 x.nombre = x.nombre.toUpperCase();
  //                 x.apellido1 = x.apellido1.toUpperCase();
  //                 x.apellido2 = x.apellido2?.toUpperCase();
  //                 x.nif = x.nif.toUpperCase();
  //                 x.nombreEmpresa = x.nombreEmpresa.toUpperCase();
  //                 x.nombreCentro = x.nombreCentro.toUpperCase();
  //               });
  //               return this.empleados;
  //             })
  //           );
  //         } else {
  //           throw new Error(response.Message);
  //         }
  //       })
  //     );
  //   }
  // }

  updateEmpleado(data: any): Observable<void> {
    data = this.conversionDatosApiService.mapUpdateEmpleadoSalida(data);
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.httpClient.post<void>(this.URL_UPDATE_EMPLEADO, data, options)
  }

  deleteEmpleado(codigo: string): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.httpClient.delete<any>(this.URL_DELETE_EMPLEADO.replace("{codigoEmpleado}", codigo), options)
    .pipe(
      map(resp=>{

      }),
      catchError(error => {
        return throwError("No se ha eliminado el empleado.");
      })
    );
  }

  getConfiguracionAplicacion():Observable<any>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type':'application/json',
        'authorization': 'Bearer '+this.loginService.getUserLocalStorage().Token.Token
      })
    };
    return this.httpClient.get(this.URL_CONFIGURACION_APLICACION, options).pipe(
      map((response: any) =>{
        if (response) {
          // response.TipoIntegracion = 'Meta4Cloud';
          return response;
        }
        else throw new Error(response.Message);
      })
    );
  }

}


