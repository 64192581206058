<div class="modal-msg">
  <svg class="icon-close-msg" mat-dialog-close>
    <use xlink:href="#icon-close"></use>
  </svg>
  <div class="flex">
    <svg class="icon">
      <use xlink:href="#hs-download"></use>
    </svg>
    <h1 class="titulo-msg">Procesando descarga masiva</h1>
  </div>
  <div class="progrees">
      <mat-progress-bar mode="determinate" [value]="completado"></mat-progress-bar>
      <div class="modal-text">Descargado: {{completado}}%</div>
  </div>
  <div class="modal-buttons-msg">
    <app-boton-accion mat-dialog-close [id]="'cancelar'" [titulo]="'cancelar'" [outline]="true" [cancelar]="true" (click)="close()"></app-boton-accion>
  </div>
</div>

