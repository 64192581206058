import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FirmanteNif, PosicionFormularioConFirmante } from '../../../models/firmante-nif';
import { FormularioDocumentoService } from '../../../services/formulario-documento.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-formularios-firmante',
  templateUrl: './formularios-firmante.component.html',
  styleUrls: ['./formularios-firmante.component.css']
})
export class FormulariosFirmanteComponent implements OnInit {

  @Input() firmante: any;
  @Output() firmanteSeleccionado: EventEmitter<FirmanteNif> = new EventEmitter<FirmanteNif>();
  casillasVerificacionArray:any[]=[]
  casillasVerificacionArrayMostrar:any[]=[];
  casillasMostrarFormArray: FormArray = new FormArray([]);

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  //mandamos el estado al padre (configuracion-documento-formularios) para que gestione abrir/cerrar el firmante seleccionado.
  mostrarEstado(firmanteNuevo: FirmanteNif){
    this.firmanteSeleccionado.emit(firmanteNuevo)
    let hayFormularios = this.formularioDocumentoService.casillasVerificacionFormArray.value;
    hayFormularios = hayFormularios.filter(x=> x.firmante.desc == this.firmante.tipoFirmante.desc)
    if(hayFormularios.length==0){ //si no existen formularios para el firmante seleccionado le añadimos un automáticamente
      this.formularioDocumentoService.addFormCasillas(firmanteNuevo)
    }
    this.mostrarFormularioArrayFirmante();
    }

  showNewForm (){
    // let index = this.casillasVerificacionArrayMostrar.length-1
    // if(this.casillasVerificacionArrayMostrar[index].nombre==''||this.casillasVerificacionArrayMostrar[index].nombre==null){
    //   this._snackBar.open('Se tiene que completar el formulario anterior antes de añadir uno nuevo, añada nombre y/o campos antes de continuar. También puede continuar si borra el formulario por icono', 'cerrar', {duration:5000})
    // }else{

      this.formularioDocumentoService.addFormCasillas(this.firmante);
    // }
    this.mostrarFormularioArrayFirmante();
  }

  mostrarFormularioArrayFirmante(){
    this.casillasVerificacionArray = this.formularioDocumentoService.casillasVerificacionFormArray.value;
    this.casillasVerificacionArrayMostrar = [];
    this.casillasMostrarFormArray = new FormArray([]);
    this.casillasVerificacionArrayMostrar = this.casillasVerificacionArray.filter(x =>
      x.firmante.desc == this.firmante.tipoFirmante.desc
    );
    this.removeSecondOccurrences(this.casillasVerificacionArrayMostrar);

    // this.casillasVerificacionArrayMostrar = this.casillasVerificacionArrayMostrar.filter(x => x.orden == 1 || x.orden == null);
    this.casillasVerificacionArrayMostrar.forEach(x=>{
      let newFormSingle = this.fb.group({
        orden: x.orden,
        nombre: x.nombre,
        firmante: x.firmante,
        id: x.id,
        pagina: [x.pagina],
        posicionTop: [x.posicionTop],
        posicionLeft: [x.posicionLeft],
        posicionRight: [x.posicionRight],
        posicionHeight: [x.posicionHeight],
        posicionWidth: [x.posicionWidth]
      })

      this.casillasMostrarFormArray.push(newFormSingle)
      // if(this.casillasMostrarFormArray.value.length==0) this.casillasMostrarFormArray.push(newFormSingle)
      // else{
      //   this.casillasMostrarFormArray.controls.forEach(x=>

      //   let index = this.casillasMostrarFormArray.controls.findIndex(x=> (x as FormGroup).controls['nombre'].value == newFormSingle.controls['nombre'].value)

      //   if(index==-1) this.casillasMostrarFormArray.push(newFormSingle)
    // }

    })
  }

  mostrarFormularioArrayFirmanteN(){
    // this.formularioDocumentoService.casillasVerificacionFormArray.controls.filter(x=> console.log('x as FormGroup: ', x as FormGroup))
    this.formularioDocumentoService.casillasVerificacionFormArray.controls.filter(x=> ((x as FormGroup).controls['firmante'] as FormGroup).value.desc == this.firmante.tipoFirmante.desc
    )
    this.casillasMostrarFormArray = this.formularioDocumentoService.casillasVerificacionFormArray;
  }

  convertirAForm(indice:number):FormGroup{
    return this.casillasMostrarFormArray.at(indice) as FormGroup;
  }

  removeSecondOccurrences(casillasVerificacionArrayMostrar: PosicionFormularioConFirmante[]) {
    const nombreOccurrencesMap = {};

    for (let i = 0; i < casillasVerificacionArrayMostrar.length; i++) {
      const item = casillasVerificacionArrayMostrar[i];

      if (item.nombre) {
        // Check if nombre has occurred before
        if (nombreOccurrencesMap[item.nombre]) {
          // Remove the second occurrence by splicing the array
          casillasVerificacionArrayMostrar.splice(i, 1);
          i--; // Decrement i to avoid skipping the next element
        } else {
          // Mark the occurrence of this nombre
          nombreOccurrencesMap[item.nombre] = true;
        }
      }
    }
  }
}
