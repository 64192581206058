import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmpleadosService } from '../../services/empleados.service';

import { EmpleadosTooltipComponent } from '../empleados-tooltip/empleados-tooltip.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';
import { DatosEmpleadosService } from '../../services/mantenimiento/datos-empleados.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-excel-empleados',
  templateUrl: './upload-excel-empleados.component.html',
  styleUrls: ['./upload-excel-empleados.component.css']
})
export class UploadExcelEmpleadosComponent implements OnInit,OnDestroy {

  mensajeCarga:string = 'Subiendo datos al servidor espere'
  mensajeCargaIni:string = 'Subiendo datos al servidor espere'
  mostrarTablaEmpleados: boolean = false;
  cargandoEmpleados: boolean = false;
  empleados: any[] = [];
  empleadosFiltro: any[] = [];
  empleadosErroneos = false;
  api: any;
  apiCol: any;
  resumenEmpleados: string = '';
  verNuevasParam:boolean=false;
  getRowStyle: any = function (params: any) {
    // if(params.data.error) return { background: "rgba(248, 201, 203, 1)" };
    if(params.data.error) return { background: "#FEE4E2" };
    else return {};
  };
  frameworkComponents: any = {empleadosTooltip: EmpleadosTooltipComponent};
  private intervalLogSubida: any;
  private intrvalOn:boolean = false;

  hayErrores: boolean;

  overlayLoadingTemplate: string = '<span class="ag-overlay-loading-center">Cargando...</span>';
  overlayNoRowsTemplate: string = '<span>No se han encontrado empleados</span>';

  constructor(public empleadoService: EmpleadosService,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog,
              private datosEmpleadosService: DatosEmpleadosService,
              private router: Router) { }

  ngOnInit(): void {
    this.getExcelConfiguration();
    this.mensajeCarga = 'Subiendo datos al servidor espere';

    this.empleadoService.getSubidaPendiente().subscribe(next=>this.verCargasAntriores(next));
  }
  ngOnDestroy(): void {
    if(this.intervalLogSubida){
      clearInterval(this.intervalLogSubida);
    }

  }
  verCargasAntriores(id:string){

    if(id&&id.trim()!=''){

      this.cargandoEmpleados = true;
       this.mostrarTablaEmpleados = false;
       this.mensajeCarga = 'Procesando empleados....'
       this.intervalLogSubida = setInterval(()=>this.verLogSubida(id),5000)
       //this.cargandoEmpleados = false;
       this.mostrarTablaEmpleados = false;
    }


  }

  cargaApi(arg: any){
    this.api = arg.api;
    this.apiCol = arg.columnApi;
  }

  getExcelConfiguration(descargar: boolean = false){
    this.empleadoService.getConfiguration().subscribe(
      next =>{},
      error => {
        this._snackBar.open(error, 'Cerrar', {duration: 3000});
      }
    );
    return;
  }

  async uploadFiles(files: FileList){
    if(!this.empleadoService.validateConfiguration()) return;
    this.empleados = await this.empleadoService.readFile(files.item(0));

    let errores = this.empleados.filter(x => x.error).length;
    this.resumenEmpleados = `Se han leído ${this.empleados?.length} registros, ${errores} contienen errores`;
    this.empleadosErroneos = (errores > 0);
    if(errores > 0) this.hayErrores = true;
    else this.hayErrores = false;
    if(this.empleados?.length > 0) {
      this.mostrarTablaEmpleados = true;
      this.seleccionFiltroChange(false);
    } else {
      this._snackBar.open("No se han encontrado coincidencias con las cabeceras configuradas", 'Cerrar', {duration: 3000});
    }
  }

  seleccionFiltroChange(mostrarErrores: boolean){
    if(mostrarErrores) this.empleadosFiltro = this.empleados.filter(x => x.error);
    else this.empleadosFiltro = this.empleados;
  }

  actualizarEmpleados(){
    if(this.empleadosErroneos){
      const dialogRef = this.dialog.open(ModalMessageComponent, {
        data: {
          mensaje: 'Hay errores, no se puede actualizar los empleados. Descarga, edita los registros erróneos y vuelve a cargar el excel.',
          alert: true
        },
      });
    }else{
      this.cargandoEmpleados = true;
      this.mostrarTablaEmpleados = false;
      this.empleadoService.deleteEmpleados().subscribe(
        next => {

          this.empleadoService.addEmpleados(this.empleados).subscribe(
            next => {


              this.mensajeCarga = 'Procesando empleados....'
              this.intervalLogSubida = setInterval(()=>this.verLogSubida(next),5000)
              //this.cargandoEmpleados = false;
              this.mostrarTablaEmpleados = false;
            },
            error => {
              console.error(error);
              this._snackBar.open(error, 'Cerrar', {duration: 3000});
              this.cargandoEmpleados = false;
              this.mostrarTablaEmpleados = false;
            }
          );
        },
        error =>{
          console.error(error);
        }
      );
    }

  }
  verLogSubida(id:string){
    this.intrvalOn = true;

    this.empleadoService.GetLog(id).subscribe(

      next=>{

        switch(next.estado){

            case "PROCESANDO":
              this.mensajeCarga=next.Avance;
              break;
            case "FINALIZADO":
              this.datosEmpleadosService.refreshEmpleados = true;
              this.mensajeCarga = this.mensajeCargaIni;
              clearInterval(this.intervalLogSubida);
              this.intrvalOn = false;
                // this._snackBar.open(`Proceso de carga de excel finalizado`, 'Cerrar', {duration: 3000});
                const dialogRef = this.dialog.open(ModalMessageComponent, {
                  data: {
                    mensaje: 'Proceso de carga de empleados finalizado correctamente.'
                  },
                });
                this.cargandoEmpleados = false;
                this.mostrarTablaEmpleados = false;
                // this.router.navigateByUrl("/panel/configuracion/empleados");
                break;
            default:
                this.mensajeCarga = this.mensajeCargaIni;
                this.intrvalOn = false;
                clearInterval(this.intervalLogSubida);
                this._snackBar.open(`Se ha producido un eror en el proceso: ${next.errorMessage}`, 'Cerrar', {duration: 3000});

                this.cargandoEmpleados = false;
                this.mostrarTablaEmpleados = false;
                break;

        }

      },
      error=>{

        clearInterval(this.intervalLogSubida);
        this._snackBar.open(error, 'Se ha producido un error en la carga', {duration: 3000});

        this.cargandoEmpleados = false;

      }
    );
  }
  exportarPlantillaEmpleados(){
    if(!this.empleadoService.validateConfiguration()) return;

    let cabecerasExport:any = {};
    Object.keys(this.empleadoService.empleadosEstructura).forEach(x=>{
      if(x!='errorMensaje') cabecerasExport[x]=""
    })


    this.empleadoService.exportaExcel([this.empleadoService.empledosDefaultEstructura],true);
  }

  exportarEmpleados(){
    this.empleadoService.exportaExcel(this.empleadosFiltro,false);
  }

  atras(){
    this.empleados = [];
    this.empleadosFiltro = [];
    this.cargandoEmpleados = false;
    this.mostrarTablaEmpleados = false;
    this.empleadosErroneos = false;
  }

}
