import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  constructor(private route:Router) {
    if(environment.LoginUniversal){
      this.route.navigate(['/IniPage']);
    }
  }

  ngOnInit(): void {
    document.body.classList.remove('no-footer');
  }

}
