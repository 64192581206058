<div class="content">
  <form [formGroup]="formularioDocumentoService.formularioDocumento">
    <!-- <div class="flex" style="margin-bottom: 30px">
      <svg class="icon-building">
        <use xlink:href="#icon-building-fill"></use>
      </svg>
      <span>CIF (empresa)</span>
    </div> -->
    <p class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValido('posicionCif')">
      {{formularioValidacionesDocsService.mensajeErrorCifPosicion}}
    </p>
    <div class="flex-box">
      <div class="flex">
        <div class="input-radio">
          <input type="radio" formControlName="posicionCif" value="pagina" (click)="borrarInputContrario('pagina')">
        </div>
        <div class="flex-input" style="margin-right: 25px; margin-left: 10px;">
          <label for="cifPagina">Página</label>
          <input #inputPage type="number" formControlName="cifPage" (keyup)="mostrarCif()" [ngClass]="formularioValidacionesDocsService.isCampoValido('cifPage') ? 'errorInput':'' " [readOnly]="posicionCif.value!=='pagina'">
        </div>
        <div class="flex-input">
          <label for="cifOrden">Orden</label>
          <input type="number" formControlName="cifOrden" (keyup)="mostrarCif()" [ngClass]="formularioValidacionesDocsService.isCampoValido('cifOrden') ? 'errorInput':'' " [readOnly]="posicionCif.value!=='pagina'">
        </div>
      </div>
        <mat-spinner *ngIf="cargando" diameter="30" strokeWidth="2" style="margin-top: 25px;"></mat-spinner>
        <div id="cifId" *ngIf="!cargando && cif!==''" class="cif">
          CIF: {{cif}}
        </div>
    </div>

    <p id='cifPageId' class="texto-validacion"  style="margin-bottom: 0;" *ngIf="formularioValidacionesDocsService.isCampoValido('cifPage')">
      {{formularioValidacionesDocsService.mensajeErrorCifPage}}
    </p>
    <p id='cifOrdenId' class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValido('cifOrden')">
      {{formularioValidacionesDocsService.mensajeErrorCifOrden}}
    </p>
    <!-- <p class="texto-validacion" *ngIf="datosConfiguracionDocumentosService.errorCif">Cif no encontrado. Informe de una página y orden válidos.</p> -->
    <div class="flex" style="margin-top: 10px;">
      <div class="input-radio">
        <input type="radio" formControlName="posicionCif" value="formulario" (click)="borrarInputContrario('formulario')">
      </div>
      <div class="flex-box" style="margin-top: 10px;">
        <!-- <div class="flex"> -->
          <div class="flex-input" style="margin-right: 25px; margin-left: 10px;">
            <label for="cifFormulario">Formulario</label>
            <input class="text-largo" type="text" formControlName="cifFormulario" [ngClass]="formularioValidacionesDocsService.isCampoValido('cifFormulario') ? 'errorInput':'' " [readOnly]="posicionCif.value!=='formulario'">
          </div>
      </div>
    </div>
    <p class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValido('cifFormulario')">
      {{formularioValidacionesDocsService.mensajeErrorCifFormulario}}
    </p>
    <p class="texto-validacion" *ngIf="datosConfiguracionDocumentosService.nohayCif">Cif no encontrado. Informe de una página y orden válidos.</p>
  </form>

</div>


