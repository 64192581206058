<h5>Dibujada - trazado  en el documento</h5>
<!-- <p>Emplazamiento del campo de firma: trazado en el documento</p> -->
<!-- <p class="subtitulo">Instrucciones</p> -->
<p>Selecciona en el documento el emplazamiento y medida del campo de firma para este firmante</p>
<div class="flex-anadir">
  <div #botonAnadir>
    <app-boton-accion [icono]="'icon-plus'" [titulo]="'añadir firma'" (botonClicked)="getPosicion($event)"  ></app-boton-accion>
  </div>
</div>
<app-firmas-temporal *ngFor="let firma of firmaMoverArray" [posicionFirma]="posicionFirma"></app-firmas-temporal>
<div class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValidoArray('firmas', obtenerIndexNifArray())">
  {{formularioValidacionesDocsService.mensajeErrorPosicionFirmas}}
</div>


