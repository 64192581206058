import { Injectable } from '@angular/core';
import { DatosConfiguracionAplicacionService } from './datos-configuracion-aplicacion.service';

@Injectable({
  providedIn: 'root'
})
export class ValidacionesFormularioRltService {

  mensajeErrorDosier: string = '';
  mensajeErrorPeriodicidad: string = '';
  mensajeErrorDiaEnvio: string = '';
  mensajeErrorHoraEnvio: string = '';

  constructor(private datosConfiguracionAplicacionService: DatosConfiguracionAplicacionService) { }

  get rltForm(){ return this.datosConfiguracionAplicacionService.configuracionRltForm }
  get dosier(){ return this.datosConfiguracionAplicacionService.configuracionRltForm.controls['dosier'] }
  get periodicidad(){ return this.datosConfiguracionAplicacionService.configuracionRltForm.controls['periodicidad'] }
  get diaEnvio(){ return this.datosConfiguracionAplicacionService.configuracionRltForm.controls['diaEnvio'] }
  get horaEnvio(){ return this.datosConfiguracionAplicacionService.configuracionRltForm.controls['horaEnvio'] }

  isCampoValidoPeriodicidad(){
    if(this.rltForm.errors?.periodicidadRequired && this.rltForm.controls['periodicidad'].touched){
      this.mensajeErrorPeriodicidad = 'La periodicidad es requerida  si la configuración de Rlt está activa.'
      return true
    }else return false
  }
  isCampoValidoDosier(){
    if(this.rltForm.errors?.dosierRequired && this.rltForm.controls['dosier'].touched){
      this.mensajeErrorDosier = 'El nombre del dosier es requerido si la configuración de Rlt está activa.'
      return true
    }
    if(this.rltForm.errors?.oneDocRequired && this.rltForm.controls['dosier'].touched){
      this.mensajeErrorDosier = 'El dosier debe tener un solo documento asociado.'
      return true
    }
    else return false
  }
  isCampoValidoDiaEnvio(){
    if(this.rltForm.errors?.diaEnvioRequired && this.rltForm.controls['diaEnvio'].touched){
      this.mensajeErrorDiaEnvio = 'El día de envío es requerido si la configuración de Rlt está activa.'
      return true
    }else return false
  }
  isCampoValidoHoraEnvio(){
    if(this.rltForm.errors?.horaEnvioRequired && this.rltForm.controls['horaEnvio'].touched){
      this.mensajeErrorHoraEnvio = 'La hora de envío es requerida si la configuración de Rlt está activa.'
      return true
    }else return false
  }


}
