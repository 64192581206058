export class ResumenEventos {
    email: string ='';
    fecha: Date=new Date();
    codigoEvento: string = '';
    descripcionEvento: string= '';
    documentId: string= '';

    constructor(data?:any){
        if(data){
            this.email = data.email;
            this.fecha = new Date(data.fecha);
            this.codigoEvento = data.codigoEvento;
            this.descripcionEvento = data.descripcionEvento;
            this.documentId = data.documentId;
        }
        
    }
}

