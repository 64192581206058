import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatosDocumentosService } from 'src/app/pages/configuracion/Envios/documentos/services/datos-documentos.service';

@Component({
  selector: 'app-upload-file-datos-documento',
  templateUrl: './upload-file-datos-documento.component.html',
  styleUrls: ['./upload-file-datos-documento.component.css']
})
export class UploadFileDatosDocumentoComponent implements OnInit {

  @Input()textoFondo ='';
  @Input()accept='';
  @Input()multiple: boolean = false;
  @Output()fileListEmitter: EventEmitter<FileList> = new EventEmitter<FileList>();
  @ViewChild('InputFile') inputFile!:ElementRef
  @Input() configDoc: boolean = false;

  constructor(private _snackBar: MatSnackBar,
              private datosDocumentosService: DatosDocumentosService) { }

  ngOnInit(): void {
    this.configDoc = this.datosDocumentosService.configDoc
  }

  uploadFiles(data: FileList | any){

    if(!data ||data.lenth==0) return;
    if(!(data instanceof FileList)) data = data.target?.files;
    try {
      this.validateFilesFormat(data);
      this.fileListEmitter.emit(data);
      this.inputFile.nativeElement.value="";
    } catch (error) {
      this._snackBar.open(`${error}`, 'Cerrar', { duration: 3000 });
    }
  }

  validateFilesFormat(data: FileList) {
    if(!this.multiple && data.length > 1) throw new Error(`No se permiten subir más de 1 documento`);
    let formats = this.accept.split(',').map(x => x.trim());
      for (let i = 0; i < data.length; i++) {
      const element = data.item(i);
      if(!formats.includes(element?.type ?? '')) throw new Error(`El formato del documento ${element?.name} no es correcto`);
    }
  }
}
