import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-usuario-activo-menu',
  templateUrl: './usuario-activo-menu.component.html',
  styleUrls: ['./usuario-activo-menu.component.css']
})
export class UsuarioActivoMenuComponent implements OnInit {

  @Output() opcionElegida: EventEmitter<string> = new EventEmitter<string>()
  opcion: string = 'editar';

  constructor() { }

  ngOnInit(): void {
    this.opcionElegida.emit('editar')
  }

  elegirOpcionMenu(opcion:string){
    this.opcionElegida.emit(opcion)
    this.opcion = opcion;
  }
}
