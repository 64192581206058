<!-- <div class="mainPanel">
    <div class="main">
        <div class="BarraIconos">
            <app-titulo-opcion titulo="Dosieres configurados" icono="#icon-send"></app-titulo-opcion>
            <app-envios-barra-superior (botonClicked)="clickBarraSuperior($event)"></app-envios-barra-superior>
            <div class="main-title-right flexRight">
                <app-selector [textoOpcion1]="'Todos'" [textoOpcion2]="'Incompletos'" [active]="verIncompletos" [control]="'estado'" (mostrarSeleccion)="seleccionFiltroChange($event)"></app-selector>
            </div>
        </div>
        <app-barra-info [texto]="'Sube documentos, envíalos, consulta el estado del proceso, genera informes, cancela la firma de documentos no firmados o descarga los firmados.'"></app-barra-info>
        <div class="main-container">
            <div class="main-content">
                <app-envios-filter-details [filtrosActivos]="datosEnviosService.filtros" (qitarFiltro)="quitarFiltro($event)" (isIncompleto)="getIncompleto($event)"></app-envios-filter-details>
                <app-envios-data-grid [envios]="datosEnviosService.enviosFiltrados" (refresh)="refresh()"></app-envios-data-grid>
            </div>
        </div>
    </div>
</div>
<app-barra-footer [id]="'nuevo envio configurado'" [icono]="'icon-plus'" [titulo]="'configurar nuevo dosier'" (botonClicked)="configuraNuevoEnvio()"></app-barra-footer> -->

<div class="barra-flex-space-between">
  <h1>Dosieres configurados</h1>
  <div class="acciones">
    <app-envios-barra-superior (botonClicked)="clickBarraSuperior($event)"></app-envios-barra-superior>
    <app-boton-accion [id]="'nuevo dosier configurado'" [icono]="'icon-plus'" [titulo]="'nuevo dosier'" [outline]="false" (click)="configuraNuevoEnvio()"></app-boton-accion>
  </div>
</div>
<div class="main">
  <div class="barra-flex-space-between">
    <div class="flex-center-items">
      <app-input-buscador (buscador)="buscarEnvios($event)" [textoBuscar]="textoBuscar"></app-input-buscador>
      <!-- <app-envios-filter-details [filtrosActivos]="datosEnviosService.filtros" (qitarFiltro)="quitarFiltro($event)" (isIncompleto)="getIncompleto($event)"></app-envios-filter-details> -->
      <app-envios-filtros-nuevos></app-envios-filtros-nuevos>
    </div>
    <!-- <app-selector [textoOpcion1]="'Todos'" [textoOpcion2]="'Incompletos'" [active]="verIncompletos" [control]="'estado'" (mostrarSeleccion)="seleccionFiltroChange($event)"></app-selector> -->

  </div>
  <!-- <app-envios-data-grid [envios]="datosEnviosService.enviosFiltrados" (refresh)="refresh()"></app-envios-data-grid> -->
  <app-sqp-grid-component [data]="datosEnviosService.enviosFiltrados"
                          (refresh)="getEnviosData()"
                          [columnDefs]="columnDefs"
                          [seleccion]="'single'"
                          (abrirModalDataEmitter)="abrirDetalleDosier($event)"
                          [gridOptions]="gridOptions"></app-sqp-grid-component>
</div>
