<div class="modal modal-buttons modal-right show-modal">
    <div class="modal-container">
        <span class="modal-close" mat-dialog-close></span>
        <div class="modal-content">
            <h2>{{titulo}}</h2>
            <!-- <div class="flex"> -->
                <!-- <app-icono-superior *ngIf="!buscar" style="margin-bottom: 15px" [icono]="'icon-search'" [id]="'Buscar'" (clickIcon)="botonAccionClicked($event)">
                </app-icono-superior> -->
                <!-- <app-input-buscador (buscador)="buscarTexto($event)" [textoBuscar]="textoBuscar"></app-input-buscador> -->
            <!-- </div> -->
            <app-add-docs-data-grid [documentos]="data.documentos" [oldDocsSelected]="data.objetoEnvio.tiposDocumento" [seleccion]="seleccion" (seleccionDocs)="seleccionarDocs($event)"></app-add-docs-data-grid>
            <!-- <app-add-docs-data-grid [documentos]="data.documentos" [oldDocsSelected]="data.objetoEnvio.tiposDocumento" [seleccion]="seleccion" (addDocs)="adjuntarDocs($event)"></app-add-docs-data-grid> -->

            <div class="modal-buttons modal-buttons-extra">
                <app-boton-accion mat-button (botonClicked)="guardarDocsExistentes()" [icono]="iconoBoton" [titulo]="textoBoton" [id]="textoBoton">
                </app-boton-accion>
            </div>
        </div>
    </div>
</div>
