<ag-grid-angular
  #agGrid
  class="ag-theme-alpine grid"
  [columnDefs]="sqpColumnDefs"
  [rowData]="data"
  [rowStyle]="rowStyle"
  [rowSelection]="seleccion === 'multiple' ? 'multiple' : 'single'"
  [rowMultiSelectWithClick]="seleccion === 'multiple' ? true : false"
  [suppressRowClickSelection]="seleccion === 'multiple' ? true : false"
  [overlayLoadingTemplate]="overlayLoadingTemplate"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  (gridReady)="cargaApi($event)"
  (selectionChanged)="onSelectionChanged()"
  (cellClicked)="onCellClicked($event)"
  [gridOptions]="gridOptions">
</ag-grid-angular>
