import { Injectable } from '@angular/core';
import { Breadcrumb } from '../models/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  breadcrumbs: Breadcrumb[] = [];

  constructor() { }

  addBreadcrumbs(campo: string){
    this.breadcrumbs.push({label: campo, url:''})
  }
}
