<h5>Certificado Digital</h5>
<!-- <p class="subtitulo">Instrucciones</p> -->
<p>Introduce el nombre del certificado digital</p>
<form [formGroup]="formFirma">
  <div class="input-flex">
    <label for="nombreCertificado" style="font-weight: 500;">Certificado Digital:</label>
    <input class="texto" type="text" formControlName="nombreCertificado">
  </div>

</form>
<!-- [ngClass]="formularioValidacionesDocsService.isCampoValidoArray('firmas', obtenerIndexNifArray()) ? 'errorInput':''  " -->
<!-- <div class="texto-validacion" *ngIf="formularioValidacionesDocsService.isCampoValidoArray('firmas', obtenerIndexNifArray())">
  {{formularioValidacionesDocsService.mensajeErrorNombreCertificado}}
</div> -->
