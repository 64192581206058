import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TipoFirmante } from '../../../../envios/models/tipo-firmante';
import { FirmanteNif } from '../../../models/firmante-nif';
import { FormularioDocumentoService } from '../../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../../services/formulario-validaciones-docs.service';

@Component({
  selector: 'app-configuracion-firmas-dibujada-tag',
  templateUrl: './configuracion-firmas-dibujada-tag.component.html',
  styleUrls: ['./configuracion-firmas-dibujada-tag.component.css']
})
export class ConfiguracionFirmasDibujadaTagComponent implements OnInit {

  @Input() firmante: TipoFirmante;

  constructor(public formularioDocumentoService: FormularioDocumentoService,
              public formularioValidacionesDocsService: FormularioValidacionesDocsService) { }

  ngOnInit(): void {
    if(this.formFirma.controls['tieneTag'].value==true && this.nombreTag.value==null){
      this.nombreTag.setValue('FirmaSqp')
    }
  }

  get formFirma(){ return this.formularioDocumentoService.formularioFirmaArray.at(this.obtenerIndexNifArray()) as FormGroup}
  get nombreTag(){ return this.formFirma.controls['nombreTag']}

  obtenerIndexNifArray():number{
    return this.formularioDocumentoService.firmantesNif.value.findIndex((x:FirmanteNif) => x.tipoFirmante.codigo == this.firmante.codigo)
  }

}
