<div class="barra-flex-space-between">
  <h1>Configuración de Usuarios</h1>
  <app-boton-accion [id]="'invitar usuario'" [icono]="'hs-user-plus'" [titulo]="'invitar usuario'" [outline]="false" (click)="invitarUsuario()"></app-boton-accion>
</div>
<div class="main">
  <div class="flex-center-items">
    <app-input-buscador (buscador)="buscarUsuarios($event)"></app-input-buscador>
    <app-usuarios-filter-details (estadoSeleccionado)="filterUsuarios($event)"></app-usuarios-filter-details>
  </div>

  <!-- <app-usuarios-data-grid [usuarios]="usuariosFiltrados" (refresh)="refreshUsuarios($event)" ></app-usuarios-data-grid> -->
  <app-sqp-grid-component [data]="usuariosFiltrados"
                          (refresh)="getUsuarios()"
                          [columnDefs]="columnDefs"
                          [seleccion]="'single'"
                          (abrirModalDataEmitter)="abreModal($event)"></app-sqp-grid-component>
</div>
