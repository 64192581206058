import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TipoFirmante } from '../../models/tipo-firmante';
import { DatosEnviosService } from '../../services/datos-envios.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { Firmantes } from '../../models/firmantes';
import { FormularioEnvioService } from '../../services/formulario-envio.service';
import { TipoDestinatario } from '../../models/tipo-destinatario';
import { TipoBusqueda } from '../../models/tipo-busqueda';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalMessageComponent } from 'src/app/shared/components/modals/modal-message/modal-message.component';


@Component({
  selector: 'app-firmantes',
  templateUrl: './firmantes.component.html',
  styleUrls: ['./firmantes.component.css']
})
export class FirmantesComponent implements OnInit {

  tiposFirmantes: TipoFirmante[] = [];
  @Input() totalFirmantes: Firmantes[] = [];
  // inputOtro: boolean = false;
  destinatarios: TipoDestinatario[];
  busquedas: TipoBusqueda[];
  @Input() hayCopiaBasica: number;
  busquedasInicial: TipoBusqueda[] = [];
  resetBusqueda: boolean = false;

  constructor(private datosEnvioService: DatosEnviosService,
              private _snackBar: MatSnackBar,
              public formularioEnvioService: FormularioEnvioService,
              private dialog: MatDialog) { }

  ngOnChanges(changes: SimpleChanges){
    this.resetBusqueda = false;
    if (changes.hayCopiaBasica && changes.hayCopiaBasica.currentValue !== undefined) {
    if(changes.hayCopiaBasica?.currentValue>0){
      this.busquedas = [{codigo: 'documento', desc:'Documento'}];
      let firmantesForm = this.formularioEnvioService.formularioEnvio.get('firmantes') as FormArray;
      let countFirmantes: number = 0;
      firmantesForm.controls.forEach(x=> {
        let busqueda = (x as FormGroup).controls['tipoBusqueda'] as FormGroup;
        if(busqueda.controls['codigo'].value!='documento'){
          busqueda.controls['codigo'].setValue('documento');
          busqueda.controls['desc'].setValue('Documento');
          countFirmantes++;
        }
      });
      if(countFirmantes>0){
        const dialogRef = this.dialog.open(ModalMessageComponent, {
          data: {
            mensaje: `El tipo de búsqueda de uno o más firmantes ha cambiado a 'Documento'.`,
            alert: true
          },
        });
      }
    }else if(changes.hayCopiaBasica?.currentValue==0 && !changes.hayCopiaBasica?.firstChange){
      this.busquedas = this.busquedasInicial;
      let firmantesForm = this.formularioEnvioService.formularioEnvio.get('firmantes') as FormArray;
      firmantesForm.controls.forEach(x=> {
        let busqueda = (x as FormGroup).controls['tipoBusqueda'] as FormGroup;
        busqueda.controls['codigo'].setValue('');
        busqueda.controls['desc'].setValue('');
      });
      this.resetBusqueda = true;
    }
  }
  }

  ngOnInit(): void {
    this.getTipoFirmantes();
    this.getTipoBusqueda();
    this.getTipoDestinatario();
  }

  get esRLT(){ return this.formularioEnvioService.formularioEnvio.controls['esRLT']}

  getTipoFirmantes(){
    this.datosEnvioService.getTipoFirmante().subscribe(
      next => {
        this.tiposFirmantes = next;
        this.tiposFirmantes.shift();
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  recuperaClick(pastillaInfo:any){
    let infoFirmante: Firmantes = {
      orden: this.totalFirmantes.length+1,
      tipoFirmante: {
        codigo: pastillaInfo.codigo,
        desc: pastillaInfo.desc
      },
      tipoDestinatario:{
        codigo: '',
        desc: ''
      },
      tipoBusqueda: {
        codigo: '',
        desc: ''
      }
    }
    // if(pastillaInfo.codigo === '103'){
      // this.inputOtro = true;
      // this.totalFirmantes = this.formularioEnvioService.addFirmante(infoFirmante)
    // }else{
      let eliminarFirmante = this.totalFirmantes.findIndex(x => x.tipoFirmante.codigo == infoFirmante.tipoFirmante.codigo)
      if(eliminarFirmante == -1){
        this.totalFirmantes = this.formularioEnvioService.addFirmante(infoFirmante)
      }else{
        this._snackBar.open(`No se puede añadir más de un '${infoFirmante.tipoFirmante.desc}'`,'Cerrar', {duration: 3000});
      }
    // }
  }

  reordenaFirmantes(){
    this.totalFirmantes.map(x => x.orden = this.totalFirmantes.indexOf(x)+1);
  }

  drop(event: CdkDragDrop<Firmantes[]>) {
      this.resetBusqueda = false;
      this.totalFirmantes = this.formularioEnvioService.getFirmantes()
      moveItemInArray(this.totalFirmantes, event.previousIndex, event.currentIndex);
      this.reordenaFirmantes();
      this.totalFirmantes = this.formularioEnvioService.updateOrdenInForm(this.totalFirmantes)
  }

  eliminarFirmante(info:any){
    this.totalFirmantes = this.formularioEnvioService.deleteFirmante(info-1)
  }

  getTipoDestinatario(){
    return this.datosEnvioService.getTipoDestinatario().subscribe(
      next => {
        this.destinatarios = next;
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  getTipoBusqueda(){
    return this.datosEnvioService.getTipoBusqueda().subscribe(
      next => {
        this.busquedas = next;
        this.busquedasInicial = [...this.busquedas];
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }
}
