<h6>Selecciona el tipo de envío</h6>
<div class="flex">
  <app-boton-acceso [icono]="'#hs-send'" [accionBoton]="'Crear Envío'" [tituloBoton]="'Envío estándar'" [texto]="'Dirigido a empleados'" [id]="'ESTÁNDAR'" (click)="selectTipoSubida('estándar')"></app-boton-acceso>
  <app-boton-acceso [icono]="'#hs-send-universal'" [accionBoton]="'Crear Envío'" [tituloBoton]="'Envío universal'" [texto]="'Dirigido a empleados y no empleados'" [id]="'MASIVA'" (click)="selectTipoSubida('masiva')"></app-boton-acceso>
</div>




<!-- <div class="main-selector">
    <div class="main-container main-content-nuevo-envio">
        <div class="main-content">
            <div class="main-content-column">
                <button class="btn buttonBig" (click)="selectTipoSubida('estándar')">
                <span class="btn-icon">
                    <svg class="icon">
                        <use xlink:href="#icon-standard-shipping"></use>
                    </svg>
                </span>
                <span class="btn-text">Envío estándar</span>
                </button>
                <div class="buttonBig-subtitle">Dirigido sólo a empleados</div>
            </div>
            <div class="main-content-column">
                <button class="btn buttonBig" (click)="selectTipoSubida('masiva')">
                    <span class="btn-icon">
                        <svg class="icon">
                        <use xlink:href="#icon-universal-shipping"></use>
                        </svg>
                    </span>
                    <span class="btn-text">Envío universal</span>
                </button>
                <div class="buttonBig-subtitle">Dirigido a empleados y no empleados</div>
                <div class="buttonBig-text">Consta de documentos genéricos (sin datos personales) que el sistema autorellena al combinarlo con una plantilla excel de firmantes-destinatarios.
                    <ul>
                        <li>Permite 1 único firmante por dosier.</li>
                        <li>Requiere subida y configuración previa del dosier genérico.</li>
                        <li>Se proporciona plantilla excel a rellenar con el listado de firmantes.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
