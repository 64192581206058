<div class="content" [ngClass]="firmante.seleccionado ? 'seleccionado' : ''">
  <!-- <div class="flex" [ngClass]="firmante.seleccionado ? 'seleccionado' : ''" (click)="mostrarEstado(firmante)"> -->
  <div class="flex" (click)="mostrarEstado(firmante)">
    <!-- <svg class="icon-user">
      <use xlink:href="#icon-user"></use>
    </svg> -->
    <h6>{{firmante.tipoFirmante.desc}}</h6>
    <svg class="icon-arrow" [ngClass]="firmante.seleccionado ? 'icon-arrow-down':''">
      <use xlink:href="#hs-arrow"></use>
    </svg>
  </div>

  <div *ngIf="firmante.seleccionado">
    <app-configuracion-firmas-tipo-firma id="tipoFirmaId" *ngIf="indexFirma==1" [firmante]="firmante"></app-configuracion-firmas-tipo-firma>
    <app-configuracion-firmas-dibujada *ngIf="indexFirma==2" [firmante]="firmante.tipoFirmante"></app-configuracion-firmas-dibujada>
    <app-configuracion-firmas-dibujada-trazado *ngIf="indexFirma==3" [firmante]="firmante.tipoFirmante"></app-configuracion-firmas-dibujada-trazado>
    <app-configuracion-firmas-dibujada-tag *ngIf="indexFirma==4" [firmante]="firmante.tipoFirmante"></app-configuracion-firmas-dibujada-tag>
    <app-configuracion-firmas-certificado *ngIf="indexFirma==5" [firmante]="firmante.tipoFirmante"></app-configuracion-firmas-certificado>
    <div *ngIf="tipoFirma.value !== 'VALIDADOR' && tipoFirma.value !== 'SIN FIRMA'">
      <p class="siguiente" *ngIf="indexFirma==1" (click)="siguiente()">Siguiente</p>
      <div *ngIf="indexFirma!==1" class="flex-direcciones">
        <span (click)="atras()" style="font-size: 14px;">Atrás</span>
      <!-- </div> -->
        <!-- <span (click)="siguiente()">Siguiente</span> -->
      <!-- <div> -->
        <span *ngIf="indexFirma==2 || indexFirma==5" style="font-size: 14px;" (click)="siguiente()">Siguiente</span>
        <!-- <span *ngIf="indexFirma==1 || indexFirma==2" (click)="siguiente()">Siguiente</span> -->
      </div>
    </div>

  </div>
</div>

<!-- <pre>Error Firma: {{firmante.tipoErrorFirma}}</pre>
<pre>Tipo Firma: {{tipoFirma.value}}</pre>
<pre>Tiene Tag: {{tieneTag.value}}</pre>
<pre>isAtras: {{isAtras}}</pre> -->
<!-- || firmante.tipoErrorFirma=='validadoresNombreCertificadoRequired' -->
