<form class="" [formGroup]="formEmpleado">
  <div class="modal-container">
    <svg class="icon-close" mat-dialog-close>
      <use xlink:href="#icon-close"></use>
    </svg>
    <h1>{{tituloModal}}</h1>
    <h2>{{nif}}</h2>
    <div class="flex-container">
      <div [ngClass]="nuevo ? 'modal-content-nuevo':'modal-content'" class="modal-content">
        <div class="seccion">
          <div class="flex-seccion" (click)="mostrarDetalles('identificativos')">
            <h6>Identificación personal</h6>
            <svg class="icon-arrow" [ngClass]="detallesIdentificativos ? 'icon-arrow-down':''">
              <use xlink:href="#hs-arrow"></use>
            </svg>
          </div>
          <div *ngIf="detallesIdentificativos">
            <span class="input-text-custom">
              <label for="sigla">Sigla</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{sigla}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="sigla"
                      name="sigla"
                      formControlName="sigla">
            </span>
            <span class="input-text-custom">
              <label for="nombre">Nombre</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{nombre}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="nombre"
                      name="nombre"
                      formControlName="nombre"
                      [ngClass]="{'errorInput': isCampoValido('nombre')}"
                    />
              <span class="texto-validacion"
                      *ngIf="isCampoValido('nombre')">
                      La nombre es requerido.
              </span>
            </span>
            <span class="input-text-custom">
              <label for="apellido1">Apellido 1</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{apellido1}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="apellido1"
                      name="apellido1"
                      formControlName="apellido1"
                      [ngClass]="{'errorInput': isCampoValido('apellido1')}"
                    />
              <span class="texto-validacion"
                      *ngIf="isCampoValido('apellido1')">
                      El primer apellido es requerido.
              </span>
            </span>
            <span class="input-text-custom">
              <label for="apellido2">Apellido 2</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{apellido2}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="apellido2"
                      name="apellido2"
                      formControlName="apellido2"
                      [ngClass]="{'errorInput': isCampoValido('apellido2')}"
                    />
              <span class="texto-validacion"
                      *ngIf="isCampoValido('apellido2')">
                      El segundo apellido es requerido.
              </span>
            </span>
            <span class="input-text-custom" >
              <label for="tipoDocumento">Tipo doc.</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{tipoDocumento}}</div>
              <input *ngIf="isEditar || nuevo" type="text"
                      id="tipoDocumento"
                      name="tipoDocumento"
                      formControlName="tipoDocumento"/>
            </span>
            <span class="input-text-custom">
              <label for="nif">NIF</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{nif}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="nif"
                      name="nif"
                      formControlName="nif"
                      [ngClass]="{'errorInput': isCampoValido('nif')}"
                    />
              <span class="texto-validacion"
                      *ngIf="isCampoValido('nif')">
                      El nif es requerido.
              </span>
            </span>
          </div>
        </div>
        <div class="seccion">
          <div class="flex-seccion" (click)="mostrarDetalles('laborales')">
            <h6>Datos laborales</h6>
            <svg class="icon-arrow" [ngClass]="detallesLaborales ? 'icon-arrow-down':''">
              <use xlink:href="#hs-arrow"></use>
            </svg>
          </div>
          <div *ngIf="detallesLaborales">
            <span class="input-text-custom">
              <label for="tipoPersona">Tipo persona</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{tipoPersona}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="tipoPersona"
                      name="tipoPersona"
                      formControlName="tipoPersona"
                    />
            </span>
            <span class="input-text-custom">
              <label for="empresa">Empresa</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{empresa}}</div>
              <select *ngIf="isEditar || nuevo" id="empresa" name="empresa" formControlName="empresa" [ngClass]="{'errorInput': (isCampoValido('empresa') || formEmpleado.errors?.empleadoExiste && formEmpleado.touched)}">
                <option disabled selected [value]="null"> -- seleccionar una empresa -- </option>
                <option *ngFor="let empresa of empresasList" [value]="empresa">{{empresa}}</option>
              </select>
              <span class="texto-validacion"
                      *ngIf="isCampoValido('empresa')">
                      La empresa es requerida.
              </span>
              <span class="texto-validacion" *ngIf="formEmpleado.errors?.empleadoExiste && formEmpleado.touched">El empleado ya existe en esta empresa</span>
            </span>
            <span class="input-text-custom">
              <label for="centro">Centro</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{centro}}</div>
              <select *ngIf="isEditar || nuevo" id="centro" name="centro" formControlName="centro" [ngClass]="{'errorInput': isCampoValido('centro')}">
                <option disabled selected [value]="null"> -- seleccionar un centro -- </option>
                <option *ngFor="let centro of centrosList" [value]="centro">{{centro}}</option>
              </select>
              <span class="texto-validacion"
                      *ngIf="isCampoValido('centro')">
                      El centro es requerido.
              </span>
            </span>
            <span class="input-text-custom">
              <label for="fechaAlta">Fecha alta</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{fechaAltaEntrada}}</div>
              <app-selector-fecha *ngIf="isEditar || nuevo"
                                  [campoFecha]="fechaAlta.value"
                                  (fechaEmitter)="setFecha($event, 'alta')"
                                  [errorClass]="isCampoValido('fechaAlta')"></app-selector-fecha>
              <!-- <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="fechaAlta"
                      name="fechaAlta"
                      formControlName="fechaAlta"
                      [ngClass]="{'errorInput': isCampoValido('fechaAlta')}"
                      (keyup)="onKeyUp($event, fechaAlta.value, 'alta')"> -->
              <span class="texto-validacion" *ngIf="isCampoValido('fechaAlta')">
              La fecha es requerida y debe seguir este formato: DD/MM/AAAA.
              </span>
            </span>
            <span class="input-text-custom">
              <label for="puestoTrabajo">Puesto trabajo</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{puestoTrabajo}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="puestoTrabajo"
                      name="puestoTrabajo"
                      formControlName="puestoTrabajo">
            </span>
            <span class="input-text-custom">
              <label for="categoria">Categoria</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{categoria}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="categoria"
                      name="categoria"
                      formControlName="categoria">
            </span>
            <span class="input-text-custom">
              <label for="convenio">Convenio</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{convenio}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="convenio"
                      name="convenio"
                      formControlName="convenio">
            </span>
          </div>
        </div>
        <div class="seccion">
          <div class="flex-seccion" (click)="mostrarDetalles('domicilio')">
            <h6>Domicilio</h6>
            <svg class="icon-arrow" [ngClass]="detallesDomicilio ? 'icon-arrow-down':''">
              <use xlink:href="#hs-arrow"></use>
            </svg>
          </div>
          <div *ngIf="detallesDomicilio">
            <span class="input-text-custom sin-margen">
              <label for="provincia">Provincia</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{provincia}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="provincia"
                      name="provincia"
                      formControlName="provincia">
            </span>

            <span class="input-text-custom">
              <label for="municipio">Municipio</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{municipio}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="municipio"
                      name="municipio"
                      formControlName="municipio">
            </span>
            <span class="input-text-custom">
              <label for="codigoPostal">Código postal</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{codigoPostal}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="codigoPostal"
                      name="codigoPostal"
                      formControlName="codigoPostal">
            </span>

            <span class="input-text-custom">
              <label for="numero">Número</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{numero}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="numero"
                      name="numero"
                      formControlName="numero">
            </span>
            <span class="input-text-custom">
              <label for="escalera">Escalera</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{escalera}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="escalera"
                      name="escalera"
                      formControlName="escalera">
            </span>
            <span class="input-text-custom">
              <label for="piso">Piso</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{piso}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="piso"
                      name="piso"
                      formControlName="piso">
            </span>
            <span class="input-text-custom">
              <label for="puerta">Puerta</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{puerta}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="puerta"
                      name="puerta"
                      formControlName="puerta">
            </span>
          </div>
        </div>
        <div class="seccion">
          <div class="flex-seccion" (click)="mostrarDetalles('personales')">
            <h6>Datos personales</h6>
            <svg class="icon-arrow" [ngClass]="detallesPersonales ? 'icon-arrow-down':''">
              <use xlink:href="#hs-arrow"></use>
            </svg>
          </div>
          <div *ngIf="detallesPersonales">
            <span class="input-text-custom">
              <label for="mailPersonal">Mail personal</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{mailPersonal}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="mailPersonal"
                      name="mailPersonal"
                      formControlName="mailPersonal"
                      [ngClass]="{'errorInput': isCampoValido('mailPersonal')}">
              <span class="texto-validacion" *ngIf="isCampoValido('mailPersonal')">
              El mail personal es requerido y tiene que tener un formato correcto.
              </span>
            </span>
            <span class="input-text-custom">
              <label for="extensionTelefonoPersonal">Extensión teléfono personal</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{extensionTelefonoPersonal}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="extensionTelefonoPersonal"
                      name="extensionTelefonoPersonal"
                      formControlName="extensionTelefonoPersonal">
            </span>
            <span class="input-text-custom">
              <label for="telefonoPersonal">Teléfono personal</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{telefonoPersonal}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="telefonoPersonal"
                      name="telefonoPersonal"
                      formControlName="telefonoPersonal">
            </span>
            <span class="input-text-custom">
              <label for="mailProfesional">Mail profesional</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{mailProfesional}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="mailProfesional"
                      name="mailProfesional"
                      formControlName="mailProfesional">
            </span>
            <span class="input-text-custom">
              <label for="extensionTelefonoProfesional">Extensión teléfono profesional</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{extensionTelefonoProfesional}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="extensionTelefonoProfesional"
                      name="extensionTelefonoProfesional"
                      formControlName="extensionTelefonoProfesional">
            </span>
            <span class="input-text-custom">
              <label for="telefonoProfesional">Teléfono profesional</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{telefonoProfesional}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="telefonoProfesional"
                      name="telefonoProfesional"
                      formControlName="telefonoProfesional">
            </span>
            <span class="input-text-custom">
              <label for="fechaNacimiento">Fecha de nacimiento</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{fechaNacimientoEntrada}}</div>
              <app-selector-fecha *ngIf="isEditar || nuevo"
                                  [campoFecha]="fechaNacimiento.value"
                                  (fechaEmitter)="setFecha($event, 'nacimiento')"
                                  [errorClass]="isCampoValido('fechaNacimiento')"></app-selector-fecha>
              <!-- <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="fechaNacimiento"
                      name="fechaNacimiento"
                      formControlName="fechaNacimiento"
                      [ngClass]="{'errorInput': isCampoValido('fechaNacimiento')}"
                      (keyup)="onKeyUp($event, fechaNacimiento.value, 'nacimiento')"/> -->
              <span class="texto-validacion" *ngIf="isCampoValido('fechaNacimiento')">
                La fecha es requerida y debe seguir este formato: DD/MM/AAAA.
              </span>
            </span>
            <span class="input-text-custom">
              <label for="nacionalidad">Nacionalidad</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{nacionalidad}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="nacionalidad"
                      name="nacionalidad"
                      formControlName="nacionalidad"
                    />
            </span>
            <span class="input-text-custom">
              <label for="lugarNacimiento">Lugar nacimiento</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{lugarNacimiento}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="lugarNacimiento"
                      name="lugarNacimiento"
                      formControlName="lugarNacimiento"
                    />
            </span>
            <span class="input-text-custom">
              <label for="estadoCivil">Estado civil</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{estadoCivil}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="estadoCivil"
                      name="estadoCivil"
                      formControlName="estadoCivil"
                    />
            </span>
            <span class="input-text-custom">
              <label for="sexo">Sexo</label>
              <div *ngIf="!isEditar && !nuevo" class="infoCentro">{{sexo}}</div>
              <input *ngIf="isEditar || nuevo"
                      type="text"
                      id="sexo"
                      name="sexo"
                      formControlName="sexo"
                    />
            </span>
          </div>
        </div>
      </div>
      <div class="modal-buttons">
        <!-- <app-boton-accion
                        *ngIf="!isEditar && !nuevo"
                        (botonClicked)="deleteEmpleado()"
                        [icono]="'hs-bin'"
                        [titulo]="'Eliminar'"
                        [id]="'Eliminar'"
                        [outline]="true"
                        [cancelar]="true">
        </app-boton-accion> -->
        <!-- *ngIf="isEditar || nuevo" -->
        <app-boton-accion mat-dialog-close
                        [icono]="'icon-close'"
                        [titulo]="'Cancelar'"
                        [id]="'Cancelar'"
                        [outline]="true"
                        [cancelar]="true">
        </app-boton-accion>
        <app-boton-accion
                        *ngIf="!isEditar && !nuevo && !datosEmpleadosService.consultaEmpleados"
                        (botonClicked)="editarEmpleado()"
                        [icono]="'hs-edit'"
                        [titulo]="'Editar'"
                        [id]="'Editar'">
        </app-boton-accion>
        <app-boton-accion
                        *ngIf="isEditar || nuevo"
                        (botonClicked)="guardarCambiosEmpleado()"
                        [icono]="'hs-guardar'"
                        [titulo]="'Guardar'"
                        [id]="'Guardar'">
        </app-boton-accion>
      </div>

    </div>
  </div>
</form>



