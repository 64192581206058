import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-boton-acceso-directo',
  templateUrl: './boton-acceso-directo.component.html',
  styleUrls: ['./boton-acceso-directo.component.css']
})

export class BotonAccesoDirectoComponent {

  @Input() icono: string;
  @Input() titulo: string;
  @Input() texto: string;

}
