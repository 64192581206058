import { HttpClient } from '@angular/common/http';
import { ApplicationRef, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { Subscription } from 'rxjs';
import { TipoFirmante } from '../../../envios/models/tipo-firmante';
import { ContenidoDocVisible } from '../../models/contenido-doc-visible';
import { ContenidoDocVisibleGuardar } from '../../models/contenido-doc-visible-guardar';
import { CasillaDibujo, FirmaDibujo, FirmanteNif, PosicionFirma, PosicionFirmaConFirmante, PosicionFormularioConFirmante } from '../../models/firmante-nif';
import { DatosConfiguracionDocumentosService } from '../../services/datos-configuracion-documentos.service';
import { DatosDocumentosService } from '../../services/datos-documentos.service';
import { FormularioDocumentoService } from '../../services/formulario-documento.service';
import { FormularioValidacionesDocsService } from '../../services/formulario-validaciones-docs.service';
import { tick } from '@angular/core/testing';

@Component({
  selector: 'app-configuracion-documento-upload',
  templateUrl: './configuracion-documento-upload.component.html',
  styleUrls: ['./configuracion-documento-upload.component.css']
})
export class ConfiguracionDocumentoUploadComponent implements OnInit,OnDestroy {

  @Output() fileListEmitter: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Output() idPdfEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() ficheroCargado:EventEmitter<string> = new EventEmitter();
  @Input() src:string = '';
  @Input() pdfCargado:boolean = false;
  @Input() idDocumentoEditar: number;
  @Input() idPdf: string;
  idDocTemporal: string = '';
  page: number = 1;
  totalPages: number;
  @ViewChild('inputPagina') inputPagina: ElementRef;
  @ViewChild('pdfDoc') pdfDoc: ElementRef;
  pdfPosition: { left: number, top: number, height: number, width: number }
  listener: any;
  viewerContainer: any;

  drags: number = 0;
  palabrasArray: ContenidoDocVisible[] = [];
  palabrasArrayPagina: ContenidoDocVisible[] = [];
  // idPalabra: number = 0;
  objetoGuardar: ContenidoDocVisible;
  palabrasGuardarArray: ContenidoDocVisibleGuardar[] = [];
  medidaReal: {altura: number, anchura: number};
  medidaPdf: {altura:number,anchura:number}

  firmasArray: FirmaDibujo[] = [];

  firmasArrayPagina: FirmaDibujo[] = [];
  objetoGuardarFirma: FirmaDibujo;
  firmasGuardarArray: PosicionFirmaConFirmante[] = [];
  // firmasGuardarArray: PosicionFirma[] = [];
  idFirma: number = 0;
  @Input() firmanteSeleccionado: TipoFirmante;
  firmasArrayFirmante: FirmaDibujo[];
  casillasArray: any[] = [];
  // casillasArray: CasillaDibujo[] = [];
  casillasArrayFirmante: CasillaDibujo[];
  casillasArrayPagina: CasillaDibujo[];
  objetoGuardarCasilla: CasillaDibujo;
  casillasGuardarArray: PosicionFormularioConFirmante[] = [];
  idCasilla: number = 0;
  camposArrayFirmante: CasillaDibujo[];
  camposArrayPagina: CasillaDibujo[];
  camposArray: any[] = [];
  camposGuardarArray: PosicionFirmaConFirmante[];
  objetoGuardarCampo: FirmaDibujo;
  cargando: boolean = true;
  scrolltop: number = 0;
  PalbraSuscription: Subscription;
  FirmasSuscription: Subscription;
  VaciarArrayFirmasSuscription:Subscription;
  GetNuevaCasillaSuscription:Subscription
  GetNuevoCampoSuscription:Subscription;
  VaciarArrayFormulariosSuscription:Subscription;
  VaciarArrayCamposEditablesSuscription:Subscription;
  // private datosDocumentosService: DatosDocumentosService,
  // private formularioValidacionesDocService: FormularioValidacionesDocsService,
  // private http: HttpClient,
  // private  ngZone:NgZone,

  constructor(private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService,
              private render:Renderer2,
              private formularioDocumentoService: FormularioDocumentoService) {
                this.listener = this.render.listen('window', 'scroll', (e) => {
                });
                this.getNuevaPalabra();
                this.getNuevaFirma();
                this.vaciarArrayFirmas();
                this.getNuevaCasilla();
                this.getNuevoCampo();
                this.vaciarArrayFormularios();
                this.vaciarArrayCamposEditables();
              }

  //guardamos lo que haya seleccionado en un firmante antes de cambiar a otro.
  ngOnChanges(changes: SimpleChanges){
    if(changes.firmanteSeleccionado?.previousValue){
      this.formularioDocumentoService.addFirmaEnFirmanteNif(changes.firmanteSeleccionado.previousValue);
    }
    this.mostrarFirmasFirmante();
    this.mostrarCasillasFirmante();
    this.mostrarCamposFirmante();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.listener();
    //this.datosConfiguracionDocumentosService.infoCasilla$.next({});
    if(this.PalbraSuscription) this.PalbraSuscription.unsubscribe();
    if(this.FirmasSuscription) this.FirmasSuscription.unsubscribe();
    if(this.VaciarArrayFirmasSuscription) this.VaciarArrayFirmasSuscription.unsubscribe();
    if(this.GetNuevaCasillaSuscription) this.GetNuevaCasillaSuscription.unsubscribe();
    if(this.GetNuevoCampoSuscription) this.GetNuevoCampoSuscription.unsubscribe();
    if(this.VaciarArrayFormulariosSuscription) this.VaciarArrayFormulariosSuscription.unsubscribe();
    if(this.VaciarArrayCamposEditablesSuscription) this.VaciarArrayCamposEditablesSuscription.unsubscribe();
  }

  pageRendered(e:any){
    if(e.pageNumber!=this.page) return;
    this.medidaPdf ={
      altura:e.source.height,
      anchura:e.source.width
    }
    this.medidaPdf.anchura=e.source.width;
    this.convertirCoordMostrarFirmas();
    this.convertirCoordMostrar();
    this.convertirCoordMostrarCasillas();
    this.convertirCoordMostrarCampos();
  }

  getYPosition(e: Event): number {
    return (e.target as Element).scrollTop;
  }

  uploadFiles(data: FileList | any){
    if(!(data instanceof FileList)) data = data.target?.files;
    this.fileListEmitter.emit(data);
    this.pdfCargado = true;
  }

  get accept():string { return 'application/pdf' }

  afterLoadComplete(pdfData: PDFDocumentProxy) {
    this.totalPages = pdfData.numPages;
    this.formularioDocumentoService.totalPages = this.totalPages;
    if(this.idDocumentoEditar) this.formularioDocumentoService.inicializaFormularioDoc(this.idDocumentoEditar); //143125 - 1538 - 143091
    else this.formularioDocumentoService.inicializaFormularioDoc(0);
    this.formularioDocumentoService.formularioDocumento.controls['idPdf'].setValue(this.idPdf);
    this.loadBox();
    this.convertirCoordMostrar();
    this.convertirCoordMostrarFirmas();
    this.convertirCoordMostrarCasillas();
    this.convertirCoordMostrarCampos();
    this.ficheroCargado.emit("ok");
  }

  showSpinner(event: {loaded: number, total: number}){
    this.cargando = event.loaded < event.total;
  }

  verCambios(){
    let element =  window.document.getElementsByClassName("ng2-pdf-viewer-container").item(0)
    this.scrolltop =element.scrollTop;
    element.addEventListener("scroll",(e)=>{
     this.drags=0;
       this.scrolltop= element.scrollTop;
     });
   }

  cambioPagina(numero:number){
    this.page += numero;
    this.datosConfiguracionDocumentosService.pagina = this.page;
    this.inputPagina.nativeElement.value = this.page
    this.mostrarPalabraPagina();
    this.mostrarFirmasPagina();
    this.mostrarCasillasPagina();
    this.mostrarCamposPagina();
  }

  irPagina(numero:any){
    this.page = parseInt(numero);
    this.mostrarPalabraPagina();
    this.mostrarFirmasPagina();
    this.mostrarCasillasPagina();
    this.mostrarCamposPagina();
  }

  //buscamos las dimensiones del pdf
  loadBox(){
    const {left, top, height, width} = this.pdfDoc.nativeElement.getBoundingClientRect();
    //lo que se ve en pantalla
    this.pdfPosition = {left, top, height, width};

    this.datosConfiguracionDocumentosService.docPosition = this.pdfPosition;
    this.getDimensionesRealesDoc();
  }

  getDimensionesRealesDoc(){
    this.medidaReal = {
      anchura: this.pdfPosition.width,
      altura: (this.pdfPosition.width*1123)/794,
      // altura: (this.pdfPosition.width*3508)/2480,
    }
  }

  //-------Métodos para dibujar los tipos de contenido (palabras)--------//

  //recogemos la nueva información y actualizamos la palabra en el array a través del id
  getNuevasCoord(e:any){
    this.objetoGuardar = {
      id: e.id,
      pagina:this.page,
      x: e.xAfterDrag,
      y: e.yAfterDrag,
      width: e.width,
      height: e.height,
      tipo: e.tipo
    }
    let index = this.palabrasArray.findIndex(x => x.id == this.objetoGuardar.id);
    this.palabrasArray[index] = this.objetoGuardar;
    this.enviarArrayAFormulario();
  }

  //cargamos la palabra que se está moviendo en este momento a través de un observable, añadimos la página en la que está, buscamos el id más grande que ya exista en el array y le añadimos uno para la nueva palabra y push al array. Si es la primera palabra iniciamos el id con 0. isEmpty mira si el objeto observado tiene valor o no.
  datosSubscription: Subscription;

  getNuevaPalabra(){
   this.PalbraSuscription = this.datosConfiguracionDocumentosService.datosPalabra$.subscribe((value:any) => {
    try{
      if(value.hasOwnProperty('posicionHeight')){

        this.convertirCoordMostrar();
      }else{
        let idPalabra: number;
        let idArray = this.palabrasArray.map(x => x.id);
        idPalabra = Math.max(...idArray);
        const isEmpty = Object.keys(value).length;
        if(idArray.length == 0) idPalabra = 0;
        if(isEmpty !== 0){

          idPalabra ++;
          let element =  window.document.getElementsByClassName("ng2-pdf-viewer-container").item(0);
          let scrolltop = element.scrollTop;


          value.y = value.y+scrolltop;

          value.pagina = this.page;
          value.id = idPalabra;
          this.palabrasArray.push(value);
          this.enviarArrayAFormulario();
          //this.datosConfiguracionDocumentosService.infoPalabra$.next({});
        }else{
          if(this.formularioDocumentoService.cancelarCopiaDoc == true) this.palabrasArray=[]
        }
        this.mostrarPalabraPagina();
      }
    }catch(error){
      console.error(error);
    }

    })
  }

  //acción que viene de doc-drag cuando movemos una palabra fuera del documento
  borrarPalabraArray(eventID:number){
    let index = this.palabrasArray.findIndex(x => x.id == eventID)
    this.palabrasArray.splice(index,1);
    this.enviarArrayAFormulario();
  }

  //mostramos solo las palabras que pertenecen a una página
  mostrarPalabraPagina(){
    let palabrasArrayFiltrada = this.palabrasArray.filter(x => x.pagina == this.page)
    this.palabrasArrayPagina = JSON.parse(JSON.stringify(palabrasArrayFiltrada));
  }

  convertirCoordAGuardar(){
    let palabraGuardar: ContenidoDocVisibleGuardar;
    this.palabrasGuardarArray = [];
    this.palabrasArray.map(x => {
      palabraGuardar = {
        id: x.id,
        pagina: x.pagina,
        tipo: x.tipo,
        posicionTop: (x.y-50)/this.medidaPdf.altura,
        posicionLeft: x.x/this.medidaPdf.anchura,
        posicionRight: (x.x+x.width)/this.medidaPdf.anchura,
        posicionHeight: x.height/this.medidaPdf.altura,
        posicionWidth: x.width/this.medidaPdf.anchura
      }
      this.palabrasGuardarArray.push(palabraGuardar);
    })
  }

  enviarArrayAFormulario(){
    this.convertirCoordAGuardar();
    this.formularioDocumentoService.setExistingPalabrasArray(this.palabrasGuardarArray);
  }

  convertirCoordMostrar(){
    if(!this.medidaPdf) return
    this.palabrasArray =[];
    let palabrasArrayForm = this.formularioDocumentoService.formularioDocumento.controls['tiposContenido'] as FormArray;
    let palabraMostrar: ContenidoDocVisible;
    palabrasArrayForm.controls.forEach((x:any) => {
      palabraMostrar = {
        id: x.controls['id'].value,
        pagina: x.controls['pagina'].value,
        tipo: x.controls['tipo'].value,
        x: x.controls['posicionLeft'].value*this.medidaPdf.anchura,
        y:  (x.controls['posicionTop'].value*this.medidaPdf.altura)+50,
        width: x.controls['posicionWidth'].value*this.medidaPdf.anchura,
        height: x.controls['posicionHeight'].value*this.medidaPdf.altura
      }
      let index = this.palabrasArray.findIndex(x=>x.id==palabraMostrar.id)
      if(index == -1) this.palabrasArray.push(palabraMostrar);
      this.mostrarPalabraPagina();
    })
  }

//-------Métodos para dibujar las firmas--------

  //recogemos la nueva información y actualizamos la firma en el array a través del id
  getNuevasCoordFirma(e:any){
    this.objetoGuardarFirma = {
      id: e.id,
      pagina:this.page,
      x: e.xAfterDrag,
      y: e.yAfterDrag,
      width: e.width,
      height: e.height,
      firmante: e.firmante
    }
    let index = this.firmasArray.findIndex(x => x.id == this.objetoGuardarFirma.id && x.firmante.codigo==e.firmante.codigo);
    this.firmasArray[index] = this.objetoGuardarFirma;
    this.enviarArrayFirmasAFormulario();
  }

  //acción que viene de firmas-dibujo cuando movemos una firma fuera del documento
  borrarFirmaArray(eventID:number){
    let index = this.firmasArray.findIndex(x => x.id == eventID)
    this.firmasArray.splice(index,1);
    this.enviarArrayFirmasAFormulario();
  }

  firmante: TipoFirmante;

  enviarArrayFirmasAFormulario(){
    this.convertirCoordAGuardarFirma();
    this.formularioDocumentoService.addFirmasArray(this.firmante,this.firmasGuardarArray);
  }

  convertirCoordAGuardarFirma(){
    let firmaGuardar: PosicionFirmaConFirmante;
    this.firmasGuardarArray = [];
    this.firmasArray.forEach(x => {
      // firmaGuardar = {
      //   id: x.id,
      //   pagina: x.pagina,
      //   firmante: x.firmante,
      //   posicionTop: x.y/this.medidaReal.altura,
      //   posicionLeft: x.x/this.medidaReal.anchura,
      //   posicionRight: (x.x+x.width)/this.medidaReal.anchura,
      //   posicionHeight: x.height/this.medidaReal.altura,
      //   posicionWidth: x.width/this.medidaReal.anchura,
      //   x: x.x,
      //   y:x.y,
      //   height:x.height,
      //   width:x.width,
      //   pdfheight:this.medidaPdf.altura,
      //   pdfwidth:this.medidaPdf.anchra
      // }
      firmaGuardar = {
        id: x.id,
        pagina: x.pagina,
        firmante: x.firmante,
        posicionTop: (x.y-50)/this.medidaPdf.altura,
        posicionLeft: x.x/this.medidaPdf.anchura,
        posicionRight: (x.x+x.width)/this.medidaPdf.anchura,
        posicionHeight: x.height/this.medidaPdf.altura,
        posicionWidth: x.width/this.medidaPdf.anchura,
      }
      this.firmasGuardarArray.push(firmaGuardar);
    })
    this.mostrarFirmasPagina();
  }

  //cargamos la firma que se está moviendo en este momento a través de un observable, añadimos la página en la que está, buscamos el id más grande que ya exista en el array y le añadimos uno para la nueva firma y push al array
  getNuevaFirma(){
    this.FirmasSuscription = this.datosConfiguracionDocumentosService.datosFirma$.subscribe((value:any) => {
      try{
        if(value){

        }
        if(value.hasOwnProperty('posicionHeight')){
          this.convertirCoordMostrarFirmas();
        }else{
          let idArray = this.firmasArray.map(x => x.id);
          this.idFirma = Math.max(...idArray);
          const isEmpty = Object.keys(value).length;
          if(idArray.length == 0) this.idFirma = 0;
          if(isEmpty !== 0){
            this.idFirma ++;
            let element =  window.document.getElementsByClassName("ng2-pdf-viewer-container").item(0);
            let scrolltop = element.scrollTop;
            value.y = value.y+scrolltop;
            value.pagina = this.page;
            value.id = this.idFirma;
            this.firmante = value.firmante;

            // delete value.firmante;
            this.firmasArray.push(value);
            this.enviarArrayFirmasAFormulario();
            this.datosConfiguracionDocumentosService.infoFirma$.next({});
          }
          this.mostrarFirmasPagina();
        }
      }catch(error){
        console.log(error);
      }


    })

  }

  //obtenemos el firmante al que tenemos que borrar las posicionesFirmas a través de un observable que viene al clicar siguiente en configuracion-firmas-tipo-firmantes.
  vaciarArrayFirmas(){
    this.VaciarArrayFirmasSuscription = this.datosConfiguracionDocumentosService.datosArrayFirmasVacio$.subscribe((value:any) => {
      try{
        const isEmpty = Object.keys(value).length
        if(isEmpty == 0){
          this.firmasArray = [];
        }else{
          this.firmasArray = this.firmasArray.filter(x => x.firmante.codigo !== value.codigo)
        }
        this.mostrarFirmasPagina();
      }catch(error){
        console.log(error);
      }
    })
  }

  mostrarFirmasPagina(){
    let datosFiltrados = this.firmasArray.filter(x => x.pagina == this.page)
    this.firmasArrayPagina = JSON.parse(JSON.stringify(datosFiltrados));
    this.mostrarFirmasFirmante();
  }

  mostrarFirmasFirmante(){
    if(this.firmanteSeleccionado){
      let datosFiltrados= this.firmasArray.filter(x => x.firmante.codigo == this.firmanteSeleccionado.codigo && x.pagina == this.page);
      this.firmasArrayFirmante = this.casillasArrayPagina = JSON.parse(JSON.stringify(datosFiltrados));
    }
  }

  get firmantesNif(){ return this.formularioDocumentoService.formularioDocumento.controls['firmantesNif'] as FormArray}

  //recuperamos los firmantesNif del formulario y creamos un array solo con las posicionFirmas donde le habremos añadido el tipoFirmante en cada posición
  convertirCoordMostrarFirmas(){
    this.firmasArray = [];
    let id=0;
    if(!this.medidaPdf) return
    this.firmantesNif.controls.forEach((x: any) => {
      x.controls['firmas']?.controls['posicionFirmas'].controls?.forEach((y:any)=>{
        let elemento:FirmaDibujo ={
          id:  id,//y.controls['id'].value,
          pagina: y.controls['pagina'].value,
          x: y.controls['posicionLeft'].value *this.medidaPdf.anchura ,
          y: (y.controls['posicionTop'].value*this.medidaPdf.altura)+50,
          width: y.controls['posicionWidth'].value*this.medidaPdf.anchura,
          height:  y.controls['posicionHeight'].value*this.medidaPdf.altura,
          firmante: y.controls['firmante'].value
        };
        id++;
        this.firmasArray.push(elemento)
      });
    });
    this.mostrarFirmasPagina();
  }

  //-------Métodos para dibujar las casillas de los formularios para cada firmante--------//

  //cargamos la casilla que se está moviendo en este momento a través de un observable, añadimos la página en la que está, buscamos el id más grande que ya exista en el array y le añadimos uno para la nueva casilla y push al array

  entramos: number=0

  datosSubcription: Subscription;

  getNuevaCasilla(){

   this.GetNuevaCasillaSuscription = this.datosConfiguracionDocumentosService.datosCasilla$.subscribe((value:any) => {
      // si tiene la siguiente property al principio significa que estamos copiando la configuración de otro documento.
    try{
      const isEmpty = Object.keys(value).length
      if(isEmpty == 0){
        this.casillasArray = [];
        this.mostrarCasillasPagina();
      }else{
        let element =  window.document.getElementsByClassName("ng2-pdf-viewer-container").item(0);
        let scrolltop = element.scrollTop;
        let elementoRecibido:any ={
          x: value.x, //- 60,
          y: value.y+scrolltop, //- 150,
          width: value.width,
          height: value.height,
          firmante: value.firmante,
          nombre: value.nombre,
          orden: value.orden
        }

        this.convertirCoordMostrarCasillas(); //añadido aquí para que se muestren las casillas/formularios en pantalla cuando se copia la configuración de documento y después se selecciona el firmante ya que la propiedad 'procesado' no viene.
        if(value.hasOwnProperty('procesado')){

          this.convertirCoordMostrarCasillas()
        }else{
          //this.casillasArray = this.formularioDocumentoService.casillasVerificacionFormArray.value
          elementoRecibido.pagina = this.page;
          if(elementoRecibido.firmante.hasOwnProperty('tipoFirmante')){
            this.firmante = elementoRecibido.firmante.tipoFirmante;
            elementoRecibido.firmante = elementoRecibido.firmante.tipoFirmante;
          }else{
            this.firmante = elementoRecibido.firmante;
            elementoRecibido.firmante = elementoRecibido.firmante;
          }

          let nombreExiste = this.casillasArray.findIndex((x:any)=> x.nombre==elementoRecibido.nombre)

          if(nombreExiste > -1){
            let arrayNombre = this.casillasArray.filter((x:any)=>x.nombre == elementoRecibido.nombre);
            let indexOrdenArray = arrayNombre.map((x:any)=>x.orden);
            let maxOrden = Math.max(...indexOrdenArray);
            elementoRecibido.orden = maxOrden + 1;

          }else{
            elementoRecibido.orden = 1
          }
          let arrayIds = this.casillasArray.map((x:any)=>x.id);
          if(arrayIds==null||arrayIds.length==0){
            elementoRecibido.id=1;
          }else{
            let maxId = Math.max(...arrayIds);
            elementoRecibido.id = maxId + 1;
          }
          this.casillasArray.push(elementoRecibido);
          this.casillasArray.map(x=>{
                        if(x.hasOwnProperty('width')) this.convertirGuardarCasillaSingle(x)
                      })
          this.casillasGuardarArray = [];
          this.casillasGuardarArray = this.casillasArray;
          this.convertirGuardarCasillaSingle(elementoRecibido);
          this.enviarArrayCasillasAFormulario();
          this.convertirCoordMostrarCasillas();
          this.mostrarCasillasPagina();
        }
    }
    }catch(error){
      console.error(error)
    }


  });
}
  // getNuevaCasillaOld(){

  //   this.GetNuevaCasillaSuscription = this.datosConfiguracionDocumentosService.datosCasilla$.subscribe((value:any) => {
  //      // si tiene la siguiente property al principio significa que estamos copiando la configuración de otro documento.

  //      const isEmpty = Object.keys(value).length
  //      if(isEmpty == 0){
  //        this.casillasArray = [];
  //        this.mostrarCasillasPagina();
  //      }else{
  //        let element =  window.document.getElementsByClassName("ng2-pdf-viewer-container").item(0);
  //        let elementoRecibido:any ={
  //          x: value.x, //- 60,
  //          y: value.y, //- 150,
  //          width: value.width,
  //          height: value.height,
  //          firmante: value.firmante,
  //          nombre: value.nombre,
  //          orden: value.orden
  //        }

  //        if(value.hasOwnProperty('procesado')){

  //          this.convertirCoordMostrarCasillas()
  //        }else{
  //          this.casillasArray = this.formularioDocumentoService.casillasVerificacionFormArray.value

  //          let nombreExiste = this.casillasArray.findIndex((x:any)=> x.nombre==elementoRecibido.nombre)

  //          if(nombreExiste > -1){

  //            let element =  window.document.getElementsByClassName("ng2-pdf-viewer-container").item(0);
  //            let scrolltop = element.scrollTop;
  //            elementoRecibido.y = elementoRecibido.y+scrolltop;
  //            elementoRecibido.pagina = this.page;
  //            if(elementoRecibido.firmante.hasOwnProperty('tipoFirmante')){
  //              this.firmante = elementoRecibido.firmante.tipoFirmante;
  //              elementoRecibido.firmante = elementoRecibido.firmante.tipoFirmante;
  //            }else{
  //              this.firmante = elementoRecibido.firmante;
  //              elementoRecibido.firmante = elementoRecibido.firmante;
  //            }

  //            if(this.casillasArray[nombreExiste].orden == null){

  //              elementoRecibido.orden = 1
  //              elementoRecibido.id = this.casillasArray[nombreExiste].id;
  //              this.convertirGuardarCasillaSingle(elementoRecibido);
  //              this.casillasArray[nombreExiste]=elementoRecibido;
  //            }else{

  //              let arrayNombre = this.casillasArray.filter((x:any)=>x.nombre == elementoRecibido.nombre);
  //              let indexOrdenArray = arrayNombre.map((x:any)=>x.orden);
  //              let maxOrden = Math.max(...indexOrdenArray);
  //              elementoRecibido.orden = maxOrden + 1;
  //              let arrayIds = this.casillasArray.map((x:any)=>x.id);
  //              let maxId = Math.max(...arrayIds);
  //              elementoRecibido.id = maxId + 1;
  //              this.convertirGuardarCasillaSingle(elementoRecibido);
  //              // control posiciones no validas (se añaden posiciones incorrectas)
  //              if(!isNaN(elementoRecibido.orden) && !isNaN(elementoRecibido.pagina) && !isNaN(elementoRecibido.posicionHeight) && !isNaN(elementoRecibido.posicionLeft) && !isNaN(elementoRecibido.posicionRight) && !isNaN(elementoRecibido.posicionTop) &&!isNaN(elementoRecibido.posicionWidth)) this.casillasArray.push(elementoRecibido);
  //            }

  //          this.casillasArray.map(x=>{
  //            if(x.hasOwnProperty('width')) this.convertirGuardarCasillaSingle(x)
  //          })
  //          this.casillasGuardarArray = [];
  //          this.casillasGuardarArray = this.casillasArray;

  //          this.convertirCoordAGuardarCasilla()
  //          this.enviarArrayCasillasAFormulario();
  //          this.convertirCoordMostrarCasillas();
  //          this.mostrarCasillasPagina();
  //          // this.datosConfiguracionDocumentosService.infoCasilla$.next({});

  //          }
  //        }
  //      }

  //    })
  //  }

  //método no utilizado
  convertirMostrarCasillaSingle(value:any){
        value.x = value.posicionLeft*this.medidaReal.anchura;
        value.y = value.posicionTop*this.medidaReal.altura;
        value.width = value.posicionWidth*this.medidaReal.anchura;
        value.height = value.posicionHeight*this.medidaReal.altura;
        delete value.posicionHeight;
        delete value.posicionLeft;
        delete value.posicionRight;
        delete value.posicionTop;
        delete value.posicionWidth;
  }

  convertirGuardarCasillaSingle(value:any){
        value.posicionTop = (value.y-50)/this.medidaPdf.altura;
        value.posicionLeft = value.x/this.medidaPdf.anchura;
        value.posicionRight = (value.x+value.width)/this.medidaPdf.anchura;
        value.posicionHeight = value.height/this.medidaPdf.altura;
        value.posicionWidth = value.width/this.medidaPdf.anchura;
        value.procesado = true
        // delete value.y;
        // delete value.x;
        // delete value.height;
        // delete value.width;

  }

  mostrarCasillasFirmante(){
    if(this.firmanteSeleccionado){
      let firmante = this.casillasArray.filter(x => x.firmante.codigo == this.firmanteSeleccionado.codigo && x.pagina==this.page);
      this.casillasArrayFirmante =  JSON.parse(JSON.stringify(firmante));
    }
  }

  mostrarCasillasPagina(){
    let pagina =  this.casillasArray.filter(x => x.pagina == this.page);
    this.casillasArrayPagina = JSON.parse(JSON.stringify(pagina));
    this.mostrarCasillasFirmante();
  }

  //recogemos la nueva información y actualizamos la casilla en el array a través del id
  getNuevasCoordCasillas(e:any){
    this.objetoGuardarCasilla = {
      id: e.id,
      pagina:this.page,
      x: e.xAfterDrag,
      y: e.yAfterDrag,
      width: e.width,
      height: e.height,
      firmante: e.firmante,
      nombre: e.nombre,
      orden: e.orden
    }

    let index = this.casillasArray.findIndex(x => x.id == this.objetoGuardarCasilla.id);
    this.casillasArray[index] = this.objetoGuardarCasilla;
    this.convertirCoordAGuardarCasilla();
    this.enviarArrayCasillasAFormulario();
    this.mostrarCasillasPagina();
    this.mostrarCasillasFirmante();
  }

  enviarArrayCasillasAFormulario(){
    // this.convertirCoordAGuardarCasilla();
    this.formularioDocumentoService.addCasillasArray(this.casillasGuardarArray);
    }

  convertirCoordAGuardarCasilla(){
    let casillaGuardar: any;
    // let casillaGuardar: PosicionFormularioConFirmante;
    this.casillasGuardarArray = [];
    // this.casillasArray.forEach(x => {
    //   casillaGuardar = {
    //     id: x.id,
    //     pagina: x.pagina,
    //     firmante: x.firmante,
    //     posicionTop: x.y/this.medidaReal.altura,
    //     posicionLeft: x.x/this.medidaReal.anchura,
    //     posicionRight: (x.x+x.width)/this.medidaReal.anchura,
    //     posicionHeight: x.height/this.medidaReal.altura,
    //     posicionWidth: x.width/this.medidaReal.anchura,
    //     nombre: x.nombre,
    //     orden: x.orden
    //   }
    this.casillasArray.forEach(x => {
      casillaGuardar = {
        id: x.id,
        pagina: x.pagina,
        firmante: x.firmante,
        posicionTop: (x.y-50)/this.medidaPdf.altura,
        posicionLeft: x.x/this.medidaPdf.anchura,
        posicionRight: (x.x+x.width)/this.medidaPdf.anchura,
        posicionHeight: x.height/this.medidaPdf.altura,
        posicionWidth: x.width/this.medidaPdf.anchura,
        nombre: x.nombre,
        orden: x.orden
      }
      this.casillasGuardarArray.push(casillaGuardar);
    })
    this.mostrarCasillasPagina();
  }

  //acción que viene de firmas-dibujo cuando movemos una firma fuera del documento
  borrarCasillaArray(eventID:number){
    let index = this.casillasArray.findIndex(x => x.id == eventID)
    this.casillasArray.splice(index,1);
    //this.enviarArrayFirmasAFormulario();
    this.convertirCoordAGuardarCasilla();
    this.enviarArrayCasillasAFormulario();
  }

  //recuperamos los firmantesNif del formulario y creamos un array solo con las posicionFirmas donde le habremos añadido el tipoFirmante en cada posición
  convertirCoordMostrarCasillas(){

    if(!this.medidaPdf) return;
    this.casillasArray=[];
    this.firmantesNif.controls.forEach((x: any) => {

      x.controls['formularios'].controls.forEach((y:any) => {
        // if(y.hasOwnProperty('x')){
        //   this.casillasArray.push(y);
        // }else{

          let element:any={};
          element.id = y.controls['id'].value;
          element.pagina = y.controls['pagina'].value;
          element.orden = y.controls['orden'].value;
          element.nombre = y.controls['nombre'].value;
          element.x =  y.controls['posicionLeft'].value*this.medidaPdf.anchura;
          element.y = (y.controls['posicionTop'].value*this.medidaPdf.altura)+50;
          element.width = y.controls['posicionWidth'].value*this.medidaPdf.anchura;
          element.height = y.controls['posicionHeight'].value*this.medidaPdf.altura;
          element.firmante = x.controls['tipoFirmante'].value

          if(!isNaN(element.x)  && !isNaN(element.y) && (!isNaN(element.width) && element.width > 0) && (!isNaN(element.height) && element.height > 0)) this.casillasArray.push(element)
       // }
      })
    })
    this.mostrarCasillasPagina();
  }

    //obtenemos el firmante al que tenemos que borrar los formularios a través de un observable que viene al clicar siguiente en configuracion-firmas-tipo-firmantes.
    vaciarArrayFormularios(){
      this.VaciarArrayFormulariosSuscription = this.datosConfiguracionDocumentosService.datosArrayFormulariosVacio$.subscribe((value:any) => {
        try{
          const isEmpty = Object.keys(value).length
          if(isEmpty == 0){
            this.casillasArray = [];
          }else{
            this.casillasArray = this.casillasArray.filter(x => x.firmante.codigo !== value.codigo)
          }
          this.mostrarCasillasPagina();
        }catch(error){
          console.log(error);
        }
      })
    }

  //-------Métodos para dibujar los campos de los campos editables para cada firmante--------//

  //cargamos la palabra que se está moviendo en este momento a través de un observable, añadimos la página en la que está, buscamos el id más grande que ya exista en el array y le añadimos uno para la nueva palabra y push al array. Si es la primera palabra iniciamos el id con 0. isEmpty mira si el objeto observado tiene valor o no.
  getNuevoCampo(){

    this.GetNuevoCampoSuscription = this.datosConfiguracionDocumentosService.datosCampo$.subscribe((value:any) => {
      try{
        if(value.hasOwnProperty('posicionHeight')){
          this.convertirCoordMostrarCampos();
        }else{
          let elemento ={
          x : value.x,
          y : value.y,
          width :value.width,
          height : value.height,
          firmante : value.firmante,
          pagina:0,
          id:0
          };

          let idCampo: number;
          let idArray = this.camposArray.map(x => x.id);
          idCampo = Math.max(...idArray);
          const isEmpty = Object.keys(value).length;
          if(idArray.length == 0) idCampo = 0;
          if(isEmpty !== 0){
            idCampo ++;
            let element =  window.document.getElementsByClassName("ng2-pdf-viewer-container").item(0);
            let scrolltop = element.scrollTop;
            elemento.y = elemento.y+scrolltop;
            elemento.pagina = this.page;
            elemento.id = idCampo;

            this.camposArray.push(elemento);
            this.enviarArrayCamposAFormulario();

            this.datosConfiguracionDocumentosService.infoCampo$.next({});
          }else{
            if(this.formularioDocumentoService.cancelarCopiaDoc == true) this.camposArray = [];
          }
          this.mostrarCamposPagina();

        }
      }catch(error){
        console.error(error);
      }

    })
  }

  mostrarCamposPagina(){
    let datosFiltrados = this.camposArray.filter(x => x.pagina == this.page);
    this.camposArrayPagina = JSON.parse(JSON.stringify(datosFiltrados));
    this.mostrarCamposFirmante();
  }

  mostrarCamposFirmante(){
    if(this.firmanteSeleccionado){
      let datosFiltrados = this.camposArray.filter(x => x.firmante.codigo == this.firmanteSeleccionado.codigo && x.pagina == this.page)
      this.camposArrayFirmante = JSON.parse(JSON.stringify(datosFiltrados));
    }

  }

  convertirCoordMostrarCampos(){
    if(!this.medidaPdf) return;
    this.camposArray=[];
    let id=0;
    this.firmantesNif.controls.forEach((x: any) => {
      let campos: any[] = x.camposEditables;

      x.controls['camposEditables'].controls.forEach((y:any) => {

        if(y.controls.hasOwnProperty('posicionHeight')){
          let element:any={};
          element.id = id//y.controls['id'].value;
          element.pagina = y.controls['pagina'].value;
          element.x = y.controls['posicionLeft'].value*this.medidaPdf.anchura;
          element.y = (y.controls['posicionTop'].value*this.medidaPdf.altura)+50;
          element.width = y.controls['posicionWidth'].value*this.medidaPdf.anchura;
          element.height =  y.controls['posicionHeight'].value*this.medidaPdf.altura;
          element.firmante = x.controls['tipoFirmante'].value
          delete y.posicionHeight;
          delete y.posicionLeft;
          delete y.posicionRight;
          delete y.posicionTop;
          delete y.posicionWidth;
          id++;


          this.camposArray.push(element)
        }

      })

    })
    this.mostrarCamposPagina();
  }

  getNuevasCoordCampo(e:any){
    this.objetoGuardarCampo = {
      id: e.id,
      pagina:this.page,
      x: e.xAfterDrag,
      y: e.yAfterDrag,
      width: e.width,
      height: e.height,
      firmante: e.firmante,
    }
    let index = this.camposArray.findIndex(x => x.id == this.objetoGuardarCampo.id && x.firmante.codigo ==this.objetoGuardarCampo.firmante.codigo);
    this.camposArray[index] = this.objetoGuardarCampo;
    this.enviarArrayCamposAFormulario();
  }

  //acción que viene de campo-dibujo cuando movemos un campo fuera del documento
  borrarCampoArray(eventId:number){
    let index = this.camposArray.findIndex(x => x.id == eventId)
    this.camposArray.splice(index,1);
    this.enviarArrayCamposAFormulario();
  }

  convertirCoordAGuardarCampos(){
    let campoGuardar: PosicionFirmaConFirmante;
    this.camposGuardarArray = [];
    this.camposArray.map(x => {
      campoGuardar = {
        id: x.id,
        pagina: x.pagina,
        firmante: x.firmante,
        posicionTop: (x.y-50)/this.medidaPdf.altura,
        posicionLeft: x.x/this.medidaPdf.anchura,
        posicionRight: (x.x+x.width)/this.medidaPdf.anchura,
        posicionHeight: x.height/this.medidaPdf.altura,
        posicionWidth: x.width/this.medidaPdf.anchura
      }
      this.camposGuardarArray.push(campoGuardar);
    })
  }

  enviarArrayCamposAFormulario(){
    this.convertirCoordAGuardarCampos();
    this.formularioDocumentoService.addCamposArray(this.firmante,this.camposGuardarArray);
  }

  //obtenemos el firmante al que tenemos que borrar los formularios a través de un observable que viene al clicar siguiente en configuracion-firmas-tipo-firmantes.
  vaciarArrayCamposEditables(){
    this.VaciarArrayCamposEditablesSuscription = this.datosConfiguracionDocumentosService.datosArrayCamposEditablesVacio$.subscribe((value:any) => {
      try{
        const isEmpty = Object.keys(value).length
        if(isEmpty == 0){
          this.camposArray = [];
        }else{
          this.camposArray = this.camposArray.filter(x => x.firmante.codigo !== value.codigo)
        }
        this.mostrarCamposPagina();
      }catch(error){
        console.log(error);
      }
    })
  }

}
