import { Injectable } from '@angular/core';
import { AbstractControlOptions, FormArray, FormBuilder, FormControl, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TipoFirmante } from '../../envios/models/tipo-firmante';
import { ContenidoDocVisibleGuardar } from '../models/contenido-doc-visible-guardar';
import { ConfiguracionFirma, FirmanteNif, PosicionFirma, PosicionFirmaConFirmante, PosicionFormulario, PosicionFormularioConFirmante, TipoFirma } from '../models/firmante-nif';
import { TipoDocumento, TipoDocumentoClass } from '../models/tipo-documento';
import { cifValidators,  formDocValidators, posicionCifValidators,  validacionCopiaBasica,  validacionGenerico, validacionNombre, validacionNombreArchivoFirmado, validacionNombreFormulario, validacionSplit } from './customValidatorsDocs';
import { DatosDocumentosService } from './datos-documentos.service';
import { DatosConfiguracionDocumentosService } from './datos-configuracion-documentos.service';
import { environment } from 'src/environments/environment';
import { TiposConfiguracion } from 'src/environments/Configuration/TiposConfiguracion';

@Injectable({
  providedIn: 'root'
})
export class FormularioDocumentoService {

  public formularioDocumento: FormGroup = new FormGroup({});
  public formularioCopiarConfiguracion: FormGroup = new FormGroup({});
  documentos: TipoDocumento[] = [];
  oldDoc: TipoDocumento;
  idTipoDocOrigen: number;
  totalPages: number;
  formArrayNif = new FormArray([]);
  firmas = new FormArray([]);
  public formularioFirma: FormGroup = new FormGroup({});
  formularioPosicionFirmas: FormGroup = new FormGroup({});
  indexFirma: number;

  objetoFirma: ConfiguracionFirma;
  objetoDocumentoEnviar: TipoDocumento = {} as TipoDocumento;
  camposEditablesFormArray: FormArray = new FormArray([]);

  casillasVerificacionFormArray: FormArray = new FormArray([]);
  cancelarCopiaDoc: boolean = false;

  indexPaso: number = 0;
  objetoPredicate:{firmantes: FormArray, index: number};

  constructor(public fb: FormBuilder,
              public datosDocumentosService: DatosDocumentosService,
              private _snackBar: MatSnackBar,
              private datosConfiguracionDocumentosService: DatosConfiguracionDocumentosService) {
                this.getDocumentosData();
                this.montarFormularioDocVacio();
                // this.montarFormularioFirmaVacio();
                // this.montarCasillasVerificacionFormArray();
               }

  //llamamos a getDocumentosData() en el constructor para que se pueda hacer la validacionNombre
  //montamos formulario vacio para crear los controles


  refrescaDocumento(){
    try{

      if(this.formularioDocumento.controls){
        this.formularioDocumento.controls['cifFormulario'].updateValueAndValidity();
        this.formularioDocumento.controls['cifOrden'].updateValueAndValidity();
        this.formularioDocumento.controls['cifPage'].updateValueAndValidity();
        this.formularioDocumento.controls['contieneCif'].updateValueAndValidity();
        this.formularioDocumento.controls['posicionCif'].updateValueAndValidity();
        this.formularioDocumento.controls['copyConfiguracion'].updateValueAndValidity();
        this.formularioDocumento.controls['esCopiaBasica'].updateValueAndValidity();
        this.refreshFirmantesNif();
        this.formularioDocumento.controls['formatoNombreArchivoAuditTrail'].updateValueAndValidity();
        this.formularioDocumento.controls['formatoNombreArchivoFirmado'].updateValueAndValidity();
        this.formularioDocumento.controls['generico'].updateValueAndValidity();
        this.formularioDocumento.controls['idPdf'].updateValueAndValidity();
        this.formularioDocumento.controls['idTipoDocOrigen'].updateValueAndValidity();
        this.formularioDocumento.controls['idTipoDocumento'].updateValueAndValidity();
        this.formularioDocumento.controls['incluirContenido'].updateValueAndValidity();
        this.formularioDocumento.controls['nombre'].updateValueAndValidity();
        this.formularioDocumento.controls['paginas'].updateValueAndValidity();
        this.formularioDocumento.controls['split'].updateValueAndValidity();
        this.formularioDocumento.controls['texto'].updateValueAndValidity();
        this.formularioDocumento.controls['textoAExcluir'].updateValueAndValidity();
        this.formularioDocumento.controls['textoAExcluirFormulario'].updateValueAndValidity();
        this.formularioDocumento.controls['textoFormulario'].updateValueAndValidity();
        this.refreshTipocontenido();
        this.formularioDocumento.updateValueAndValidity();
      }
    }catch(ex){
      console.error(ex);
    }
  }

  refreshTipocontenido(){
    let tiposContenido = this.formularioDocumento.controls['tiposContenido'] as FormArray;
    //Recorremos el from group
    if(tiposContenido!=null && tiposContenido.length>0){
      for(let i=0;i<tiposContenido.length;i++){
        let element = tiposContenido.at(i) as FormGroup;

        element.controls['id'].updateValueAndValidity();
        element.controls['pagina'].updateValueAndValidity();
        element.controls['posicionHeight'].updateValueAndValidity();
        element.controls['posicionRight'].updateValueAndValidity();
        element.controls['posicionTop'].updateValueAndValidity();
        element.controls['posicionWidth'].updateValueAndValidity();
        element.controls['tipo'].updateValueAndValidity();
        element.updateValueAndValidity();
      }

    }

    this.formularioDocumento.controls['tiposContenido'].updateValueAndValidity();

  }

  refreshFirmantesNif(){
    let firmantesNif = this.formularioDocumento.controls['firmantesNif'] as FormArray;
      //Recorremos el from group
      if(firmantesNif!=null && firmantesNif.length>0){
        for(let i=0;i<firmantesNif.length;i++){
          let element = firmantesNif.at(i) as FormGroup;
          this.refreshCamposEditables(element);

          this.refresFirmas((element.controls['firmas'] as FormGroup));
          this.refreshFormulario((element.controls['formularios'] as FormArray));
          element.controls['nifFormulario'].updateValueAndValidity();
          element.controls['nifOrden'].updateValueAndValidity();
          element.controls['nifPage'].updateValueAndValidity();
          element.controls['tipoFirmante'].updateValueAndValidity();
          // element.controls['posicionNif'].updateValueAndValidity();
          element.updateValueAndValidity();
        }

      }

      this.formularioDocumento.controls['firmantesNif'].updateValueAndValidity();

  }
  refreshCamposEditables(element:FormGroup){
    let camposEditables =  element.controls['camposEditables'] as FormArray;
    if(camposEditables!=null && camposEditables.length>0){
      for(let i=0;i<camposEditables.length;i++){
        let element = camposEditables.at(i) as FormGroup;

          element.controls['id'].updateValueAndValidity();
          element.controls['pagina'].updateValueAndValidity();
          element.controls['posicionHeight'].updateValueAndValidity();
          element.controls['posicionLeft'].updateValueAndValidity();
          element.controls['posicionRight'].updateValueAndValidity();
          element.controls['posicionTop'].updateValueAndValidity();
          element.controls['posicionWidth'].updateValueAndValidity();
          element.updateValueAndValidity();
      }
      camposEditables.updateValueAndValidity();
    }
  }
  refresFirmas(element:FormGroup){
      element.controls['nombreCertificado'].updateValueAndValidity();
      element.controls['nombreTag'].updateValueAndValidity();
      this.refreshPosicionFirmas(element);
      element.controls['tieneTag'].updateValueAndValidity();
      element.controls['tipoFirma'].updateValueAndValidity();


  }
  refreshPosicionFirmas(element:FormGroup){

    let posiconFirmas =  element.controls['posicionFirmas'] as FormArray;
    if(posiconFirmas!=null && posiconFirmas.length>0){
      for(let i=0;i<posiconFirmas.length;i++){
        let element = posiconFirmas.at(i) as FormGroup;
          element.controls['firmante'].updateValueAndValidity();
          element.controls['id'].updateValueAndValidity();
          element.controls['pagina'].updateValueAndValidity();
          element.controls['posicionHeight'].updateValueAndValidity();
          element.controls['posicionLeft'].updateValueAndValidity();
          element.controls['posicionRight'].updateValueAndValidity();
          element.controls['posicionTop'].updateValueAndValidity();
          element.controls['posicionWidth'].updateValueAndValidity();
          element.updateValueAndValidity();
      }
      posiconFirmas.updateValueAndValidity();
    }
  }
  refreshFormulario(formularios:FormArray){
    if(formularios!=null && formularios.length>0){
      for(let i=0;i<formularios.length;i++){
        let element = formularios.at(i) as FormGroup;
          element.controls['firmante'].updateValueAndValidity();
          element.controls['id'].updateValueAndValidity();
          element.controls['pagina'].updateValueAndValidity();
          element.controls['posicionHeight'].updateValueAndValidity();
          element.controls['posicionLeft'].updateValueAndValidity();
          element.controls['posicionRight'].updateValueAndValidity();
          element.controls['posicionTop'].updateValueAndValidity();
          element.controls['posicionWidth'].updateValueAndValidity();
          element.controls['nombre'].updateValueAndValidity();
          element.controls['orden'].updateValueAndValidity();
          element.updateValueAndValidity();
      }
      formularios.updateValueAndValidity();
    }


}
  montarFormularioDocVacio(){
    this.formularioDocumento = this.fb.group({
      idTipoDocumento: [null],
      nombre: [''],
      urlDocTemp: [''],
      idPdf:[''],
      idTipoDocOrigen:[null],
      copyConfiguracion: [false],
      generico:[],
      incluirContenido:[false],
      tiposContenido: this.fb.array([]),
      texto:[''],
      textoFormulario:[''],
      textoAExcluir:[''],
      textoAExcluirFormulario:[''],
      contieneCif:[false],
      posicionCif:[],
      cifOrden:[],
      cifPage:[],
      cifFormulario:[],
      firmantesNif: this.fb.array([]),
      paginas:[],
      esCopiaBasica:[],
      split:[''],
      formatoNombreArchivoAuditTrail:[''],
      formatoNombreArchivoFirmado:[''],
      esRLT:[false]
    })
  }

  get esRLT(){ return this.formularioDocumento?.get('esRLT')}

  inicializaFormularioFirma(firmanteNif: FirmanteNif){

    let nuevoFormularioFirma = this.fb.group({
        tipoFirma: [firmanteNif.firmas.tipoFirma],
        nombreCertificado: [firmanteNif.firmas.nombreCertificado],
        tieneTag: [firmanteNif.firmas.tieneTag],
        nombreTag: [firmanteNif.firmas.nombreTag],
        posicionFirmas: firmanteNif.firmas.posicionFirmas ? [firmanteNif.firmas.posicionFirmas] : this.fb.array([])
        // posicionFirmas: this.fb.array([])
      },{ validator: [formDocValidators([() => this.objetoPredicate,() => this.formularioDocumento])]} as AbstractControlOptions);
      this.inicializaFormularioFirmaArray(nuevoFormularioFirma);
      return nuevoFormularioFirma

  }

  formularioFirmaArray: FormArray = new FormArray([], { validator: [formDocValidators([() => this.firmantesNif, this.formularioDocumento])]} as AbstractControlOptions);

  inicializaFormularioFirmaArray(formularioFirma: FormGroup){
    this.formularioFirmaArray.push(formularioFirma)
  }

  //para hacer la validación del formulario firma y obtener el índice del momento
  updateObjetoPredicate(){
    this.objetoPredicate = {firmantes: this.firmantesNif, index: this.indexPaso}
  }

  get tipoFirma(){ return this.formularioFirma.controls['tipoFirma']}
  get tieneTag(){ return this.formularioFirma.controls['tieneTag']}

  inicializaFormularioDoc(id: number){
    if(id==0){
      this.casillasVerificacionFormArray = new FormArray([]);
      this.formularioDocumento = this.fb.group({
        idTipoDocumento: [null],
        nombre: ['', [validacionNombre(this.documentos, id), Validators.pattern(/^[^']*$/)]],
        urlDocTemp: [''],
        idPdf:[''],
        idTipoDocOrigen:[null],
        copyConfiguracion: [false],
        generico:[, validacionGenerico(() => this.indexPaso)],
        incluirContenido:[false],
        tiposContenido: this.fb.array([]),
        texto:[''],
        textoFormulario:[''],
        textoAExcluir:[''],
        textoAExcluirFormulario:[''],
        contieneCif:[false],
        posicionCif:[, posicionCifValidators(()=>this.contieneCif)],
        cifOrden:[, cifValidators([() => this.contieneCif, () => this.posicionCif], this.totalPages, 'cifOrden')],
        cifPage:[, cifValidators([() => this.contieneCif, () => this.posicionCif], this.totalPages, 'cifPage')],
        cifFormulario:[, cifValidators([() => this.contieneCif, () => this.posicionCif], this.totalPages, 'cifFormulario')],
        firmantesNif: this.fb.array([]),
        paginas:[this.totalPages],
        esCopiaBasica:[false, validacionCopiaBasica(()=>this.formularioDocumento)],
        split:['', validacionSplit(this.totalPages)],
        formatoNombreArchivoAuditTrail:['{NOMBRE_EMPLEADO}_{APELLIDO1_EMPLEADO}_{APELLIDO2_EMPLEADO}_{NOMBRE_DOCUMENTO}_AuditTrail.pdf', validacionNombreArchivoFirmado(()=> this.formularioDocumento, 'audit')],
        formatoNombreArchivoFirmado:['{NOMBRE_EMPLEADO}_{APELLIDO1_EMPLEADO}_{APELLIDO2_EMPLEADO}_{NOMBRE_DOCUMENTO}.pdf', validacionNombreArchivoFirmado(()=> this.formularioDocumento, 'doc')],
        esRLT:[false]
      })
    }else{
      this.oldDoc = this.datosDocumentosService.getTipoDocumento(id)
      this.formularioDocumento = this.fb.group({
        idTipoDocumento: [this.oldDoc.idTipoDocumento],
        nombre: [this.oldDoc.nombre, [validacionNombre(this.documentos, id), Validators.pattern(/^[^']*$/)]],
        // urlDocTemp: [this.oldDoc.urlDocTemp],
        idPdf:[this.oldDoc.idPdf],
        idTipoDocOrigen: [this.oldDoc.idTipoDocOrigen],
        copyConfiguracion: [],
        generico:[this.oldDoc.generico, validacionGenerico(() => this.indexPaso)],
        incluirContenido:[this.contienePalabrasClave(this.oldDoc.tiposContenido)],
        tiposContenido: this.setExistingPalabrasArray(this.oldDoc.tiposContenido),
        texto:[this.oldDoc.texto],
        textoFormulario:[this.oldDoc.textoFormulario],
        textoAExcluir:[this.oldDoc.textoAExcluir],
        textoAExcluirFormulario:[this.oldDoc.textoAExcluirFormulario],
        contieneCif:[this.contieneDatos(this.oldDoc.cifOrden, this.oldDoc.cifPage,this.oldDoc.cifFormulario)],
        posicionCif:[this.buscarPosicionCif(this.oldDoc.cifOrden, this.oldDoc.cifPage,this.oldDoc.cifFormulario), posicionCifValidators(()=>this.contieneCif)],
        cifOrden:[this.oldDoc.cifOrden, cifValidators([() => this.contieneCif, () => this.posicionCif], this.totalPages, 'cifOrden')],
        cifPage:[this.oldDoc.cifPage, cifValidators([() => this.contieneCif, () => this.posicionCif], this.totalPages, 'cifPage')],
        cifFormulario:[this.oldDoc.cifFormulario, cifValidators([() => this.contieneCif, () => this.posicionCif], this.totalPages, 'cifFormulario')],
        firmantesNif: this.setExistingFirmantesNif(this.oldDoc.firmantesNif),
        paginas:[this.oldDoc.paginas],
        esCopiaBasica:[this.oldDoc.esCopiaBasica, validacionCopiaBasica(()=>this.formularioDocumento)],
        split:[this.oldDoc.split, validacionSplit(this.totalPages)],
        formatoNombreArchivoAuditTrail:[this.oldDoc.formatoNombreArchivoAuditTrail, validacionNombreArchivoFirmado(()=> this.formularioDocumento, 'audit')],
        formatoNombreArchivoFirmado:[this.oldDoc.formatoNombreArchivoFirmado, validacionNombreArchivoFirmado(()=> this.formularioDocumento, 'doc')],
        esRLT:[this.oldDoc.esRLT]
      })
      this.setExisitingFirmasArray(this.oldDoc.firmantesNif);

    }
  }

  get nombre(){ return this.formularioDocumento.controls['nombre']}
  get contieneCif(){ return this.formularioDocumento.get('contieneCif').value }
  get posicionCif(){ return this.formularioDocumento.get('posicionCif').value}
  get firmantesNif(){ return this.formularioDocumento.get('firmantesNif') as FormArray}


  contieneDatos(orden: number, page: number,cifFormulario:string): boolean{
    if(orden || page||cifFormulario) return true
    return false
  }

  buscarPosicionCif(orden: number, page: number,cifFormulario:string):string{
    if(orden && page) return 'pagina'
    if(cifFormulario) return 'formulario'
    return null
  }

  contienePalabrasClave(tiposContenido: ContenidoDocVisibleGuardar[]): boolean{
    if(tiposContenido.length > 0) return true
    return false
  }

  getDocumentosData(){
    if(environment.Configuracion== TiposConfiguracion.V2) return;
    this.datosDocumentosService.getTipoDocumentos().subscribe(
      next => {
        this.documentos = next;
      },
      error => {
        this._snackBar.open(error, 'Cerrar',{duration: 3000});
      }
    )
  }

  //Miramos los campos que vienen desde el documento que hemos seleccionado en el modal (el que queremos copiar su configuración), si están en el formulario, los actualizamos, sino no. Excluimos las siguientes keys ya que son las propias del nuevo documento que estamos creando
  copiaTipoDocumento2(doc: TipoDocumento){
    let keysExcluir = ['idTipoDocumento', 'idTipoDocOrigen', 'nombre', 'urlDocTemp']
    Object.entries(doc).forEach(([keyD, valueD]) => {
      Object.entries(this.formularioDocumento.value).forEach(([keyF, valueF]) => {
        if(keyD == keyF && !keysExcluir.includes(keyD)){
          this.formularioDocumento.controls[keyF].setValue(valueD)
        }
      })
    })
  }

  get idTipoDocumento(){ return this.formularioDocumento.controls['idTipoDocumento']}

  get idPdf(){ return this.formularioDocumento.controls['idPdf'] }

  get paginas(){ return this.formularioDocumento.controls['paginas']}

  copiaTipoDocumento(doc: TipoDocumento){
    this.cancelarCopiaTipoDocumento() //cancelamos copia primero para borrar si hubiera datos en el formulario
    this.cancelarCopiaDoc = false;
    this.formularioDocumento = this.fb.group({
      idTipoDocumento: [this.idTipoDocumento.value],
      nombre: [this.nombre.value, [validacionNombre(this.documentos, this.idTipoDocumento.value), Validators.pattern(/^[^']*$/)]],
      idPdf:[this.idPdf.value],
      idTipoDocOrigen: [doc.idTipoDocumento],
      copyConfiguracion: [true],
      generico:[doc.generico, validacionGenerico(() => this.indexPaso)],
      incluirContenido:[this.contienePalabrasClave(doc.tiposContenido)],
      tiposContenido: this.setExistingPalabrasArray(doc.tiposContenido),
      texto:[doc.texto],
      textoFormulario:[doc.textoFormulario],
      textoAExcluir:[doc.textoAExcluir],
      textoAExcluirFormulario:[doc.textoFormulario],
      contieneCif:[this.contieneDatos(doc.cifOrden, doc.cifPage,doc.cifFormulario)],
      posicionCif:[this.buscarPosicionCif(doc.cifOrden, doc.cifPage,doc.cifFormulario), posicionCifValidators(()=>this.contieneCif)],
      cifOrden:[doc.cifOrden, cifValidators([() => this.contieneCif, () => this.posicionCif], this.totalPages, 'cifOrden')],
      cifPage:[doc.cifPage, cifValidators([() => this.contieneCif, () => this.posicionCif], this.totalPages, 'cifPage')],
      cifFormulario:[doc.cifFormulario, cifValidators([() => this.contieneCif, () => this.posicionCif], this.totalPages, 'cifFormulario')],
      firmantesNif: this.setExistingFirmantesNif(doc.firmantesNif),
      paginas:[this.paginas.value],
      esCopiaBasica:[doc.esCopiaBasica, validacionCopiaBasica(()=>this.formularioDocumento)],
      split:[doc.split, validacionSplit(this.totalPages)],
      formatoNombreArchivoAuditTrail:[doc.formatoNombreArchivoAuditTrail, validacionNombreArchivoFirmado(()=> this.formularioDocumento, 'audit')],
      formatoNombreArchivoFirmado:[doc.formatoNombreArchivoFirmado, validacionNombreArchivoFirmado(()=> this.formularioDocumento, 'doc')],
      esRLT: [doc.esRLT]
    })
    this.setExisitingFirmasArray(doc.firmantesNif);
    doc.tiposContenido.forEach(x=>{
      this.datosConfiguracionDocumentosService.transferirDatosPalabra(x)
    })
    doc.firmantesNif.forEach(x=>{
      x.camposEditables.forEach(y=> this.datosConfiguracionDocumentosService.transferirDatosCampo(y));
      x.formularios.forEach(y=> this.datosConfiguracionDocumentosService.transferirDatosCasilla(y));
      x.firmas.posicionFirmas.forEach(y=> this.datosConfiguracionDocumentosService.transferirDatosFirma(y));
    })

  }

  cancelarCopiaTipoDocumento(){
    this.cancelarCopiaDoc = true;
    this.formularioDocumento = this.fb.group({
      idTipoDocumento: [this.idTipoDocumento.value],
      nombre: [this.nombre.value, [validacionNombre(this.documentos, this.idTipoDocumento.value), Validators.pattern(/^[^']*$/)]],
      idPdf:[this.idPdf.value],
      idTipoDocOrigen:[null],
      copyConfiguracion: [false],
      generico:[],
      incluirContenido:[false],
      tiposContenido: this.fb.array([]),
      texto:[''],
      textoFormulario:[''],
      textoAExcluirFormulario:[''],
      textoAExcluir:[''],
      contieneCif:[false],
      posicionCif:[],
      cifOrden:[],
      cifPage:[],
      cifFormulario:[],
      firmantesNif: this.fb.array([]),
      paginas:[this.paginas.value],
      esCopiaBasica:[],
      split:[''],
      formatoNombreArchivoAuditTrail:[''],
      formatoNombreArchivoFirmado:[''],
      esRLT:[false]
    })
    this.datosConfiguracionDocumentosService.transferirDatosPalabra([])
    this.datosConfiguracionDocumentosService.transferirArrayFirmasVacio([])
    this.datosConfiguracionDocumentosService.transferirDatosCasilla([])
    this.datosConfiguracionDocumentosService.transferirDatosCampo([])
    this.datosConfiguracionDocumentosService.transferirDocId({})
    this.setExisitingFormulariosArray(null)
  }

  resetFormularioDocumento(){
    this.cancelarCopiaDoc = true;
    this.formularioDocumento = this.fb.group({
      idTipoDocumento: [null],
      nombre: [''],
      urlDocTemp: [''],
      idPdf:[''],
      idTipoDocOrigen:[null],
      copyConfiguracion: [false],
      generico:[],
      incluirContenido:[false],
      tiposContenido: this.fb.array([]),
      texto:[''],
      textoFormulario:[''],
      textoAExcluir:[''],
      textoAExcluirFormulario:[''],
      contieneCif:[false],
      posicionCif:[],
      cifOrden:[],
      cifPage:[],
      cifFormulario:[],
      firmantesNif: this.fb.array([]),
      paginas:[this.paginas.value],
      esCopiaBasica:[],
      split:[''],
      formatoNombreArchivoAuditTrail:[''],
      formatoNombreArchivoFirmado:[''],
      esRLT:[false]
    })
    this.datosConfiguracionDocumentosService.transferirDatosPalabra([])
    this.datosConfiguracionDocumentosService.transferirArrayFirmasVacio([])
    this.datosConfiguracionDocumentosService.transferirDatosCasilla([])
    this.datosConfiguracionDocumentosService.transferirDatosCampo([])
    this.datosConfiguracionDocumentosService.transferirDocId({})
    this.setExisitingFormulariosArray(null)
  }

  //cargamos el array de palabras claves que viene de la api y las añadimos al form. Tb sirve para añadir al form las palabras nuevas
  setExistingPalabrasArray(palabrasClave: ContenidoDocVisibleGuardar[]):FormArray{
    const formArrayPalabras = new FormArray([]);
    palabrasClave.forEach(x => formArrayPalabras.push(this.fb.group({
        id: [x.id],
        pagina: [x.pagina],
        tipo: [x.tipo],
        posicionTop: [x.posicionTop],
        posicionLeft: [x.posicionLeft],
        posicionRight: [x.posicionRight],
        posicionHeight: [x.posicionHeight],
        posicionWidth: [x.posicionWidth]
    })))
    this.formularioDocumento.controls['tiposContenido'] = formArrayPalabras
    return formArrayPalabras
  }

  //creamos newArray con los valores existentes del formArray, le añadimos el nuevo firmante o actualizamos y se lo pasamos a setExisitngFirmantesNif para que convierta el array en formArray y lo asigne al atributo "firmantesNif" del formulario
  addFirmanteNifInFormArray(firmanteNif: FirmanteNif){
    let newArray = this.firmantesNif.value
    let index = newArray.findIndex((x:FirmanteNif) => x.tipoFirmante.codigo == firmanteNif.tipoFirmante.codigo)

    if(index > -1) newArray[index] = firmanteNif
    else newArray.push(firmanteNif)

    this.formularioDocumento.controls['firmantesNif'] = this.setExistingFirmantesNif(newArray)

    this.setExisitingFirmasArray(newArray);
  }

  deleteFirmanteNifInFormArray(firmanteNif: FirmanteNif){
    let newArray = this.firmantesNif.value
    let index = newArray.findIndex((x:FirmanteNif) => x.tipoFirmante.codigo == firmanteNif.tipoFirmante.codigo)
    if(index > -1){
      newArray.splice(index,1)
      this.formularioDocumento.controls['firmantesNif'] = this.setExistingFirmantesNif(newArray)
    }
  }

  RequiereNif():boolean{
    if (this.formularioDocumento.controls['generico'].value || this.formularioDocumento.controls['esCopiaBasica'].value  ){
      return false;
    }else{
      return true;
    }
  }
  setExistingFirmantesNif(firmantesNif: FirmanteNif[]): FormArray{
    const formArray = new FormArray([]);
    this.formularioFirmaArray = new FormArray([]);
    let objetoPredicateNifPage:{arrayFirmantes: FirmanteNif[], nifInfo: number, index: number}
    let objetoPredicateNifOrden:{arrayFirmantes: FirmanteNif[], nifInfo: number, index: number}
    let objetoPredicateNifFormulario:{arrayFirmantes: FirmanteNif[], nifInfo: null, index: number}
    let permiteSinNif = (this.formularioDocumento.controls['generico'].value || this.formularioDocumento.controls['esCopiaBasica'].value  )
    firmantesNif.forEach(x => {
      let index = firmantesNif.findIndex(y=> y.tipoFirmante.desc == x.tipoFirmante.desc )
      objetoPredicateNifPage={
        arrayFirmantes: this.firmantesNif.value,
        nifInfo: x.nifPage,
        index: index
      }
      objetoPredicateNifOrden={
        arrayFirmantes: this.firmantesNif.value,
        nifInfo: x.nifOrden,
        index: index
      }
      objetoPredicateNifFormulario={
        arrayFirmantes: this.firmantesNif.value,
        nifInfo: null,
        index: index
      }

      // let nifOrdenValidation = x.nifOrden;
      // let nifPageValidation = x.nifPage;
      formArray.push(this.fb.group({
        tipoFirmante: this.fb.group({
          codigo:[x.tipoFirmante.codigo],
          desc:[x.tipoFirmante.desc]
        }),
        nifPage: [x.nifPage],
        // nifPage: [x.nifPage, nifValidators(() => objetoPredicateNifOrden, this.totalPages, 'nifPage',permiteSinNif)],
        // nifPage: [x.nifPage, nifValidators(() => nifOrdenValidation, this.totalPages, 'nifPage')],
        nifOrden: [x.nifOrden],
        // nifOrden: [x.nifOrden, nifValidators(() => objetoPredicateNifPage, this.totalPages, 'nifOrden',permiteSinNif)],
        // nifOrden: [x.nifOrden, nifValidators(() => nifPageValidation, this.totalPages, 'nifOrden')],
        nifFormulario:[x.nifFormulario],
        // nifFormulario:[x.nifFormulario, nifValidators(() => objetoPredicateNifFormulario, this.totalPages, 'nifFormulario',permiteSinNif)],
        posicionNif:[x.posicionNif],
        firmas: this.inicializaFormularioFirma(x),
        formularios: this.setExisitingFormulariosArray(x),
        camposEditables: this.setExisitingCamposArray(x)
      }))
    })
    return formArray
  }


  addFirmaEnFirmanteNif(firmante:TipoFirmante){
    let newArray = this.firmantesNif.value
    const index = newArray.findIndex((x:FirmanteNif) => x.tipoFirmante.codigo == firmante.codigo)
    let firmaNif = this.firmantesNif.at(index) as FormGroup ;
    firmaNif.controls['firmas'] = this.formularioFirmaArray.at(index) as FormGroup;
    this.refreshFormValues(this.firmantesNif);
    this.refreshFormValues(this.formularioDocumento);
  }

  deleteCampoFirma(firmante:TipoFirmante, campo:string){
    let newArray = this.firmantesNif.value
    const index = newArray.findIndex((x:FirmanteNif) => x.tipoFirmante.codigo == firmante.codigo)
    let firmaNif = this.firmantesNif.at(index) as FormGroup ;
    firmaNif.controls['firmas'].get(campo).reset();
    this.refreshFormValues(this.firmantesNif);
    this.refreshFormValues(this.formularioDocumento);
  }


  //añadir al form las firmas nuevas
  addFirmasArray(firmante: TipoFirmante, posicionFirmas: PosicionFirmaConFirmante[]){

    let indexFirmanteNif: number = -1;
    let index: number;
    let arrayFirmanteEspecifico: PosicionFirmaConFirmante[] = [];
    let formArrayFirmasFirmante = new FormArray([]);
    let posicionFirmantesForm:FormGroup;
    let posicionFirmantesFormarray:FormGroup;

    this.firmantesNif.value.forEach((x: FirmanteNif) => {


      indexFirmanteNif++;
      posicionFirmantesForm = this.firmantesNif.at(indexFirmanteNif).get('firmas') as FormGroup;

      formArrayFirmasFirmante = new FormArray([]);
      posicionFirmantesForm.controls['posicionFirmas'] = new FormArray([]);

      //posicionFirmantesFormarray = this.formularioFirmaArray.at(indexFirmanteNif).get('firmas') as FormGroup;
      //posicionFirmantesFormarray.controls['posicionFirmas'] = new FormArray([]);
        posicionFirmas.forEach(y => {

          arrayFirmanteEspecifico = [];
          if(x.tipoFirmante.codigo == y.firmante.codigo){
            (posicionFirmantesForm.controls['posicionFirmas'] as FormArray).push(this.newPosFirmas(y));
           // (posicionFirmantesFormarray.controls['posicionFirmas'] as FormArray).push(this.newPosFirmas(y));
           posicionFirmantesForm.controls['posicionFirmas'].updateValueAndValidity();

          }

        })

        this.refreshFormValues(this.formularioDocumento);
        this.refreshFormValues(this.firmantesNif);

        this.refreshFormValues(posicionFirmantesForm);
        this.refreshFormValues(posicionFirmantesForm.controls['posicionFirmas']);
        this.refrescaDocumento();
    })


  }
  newPosFirmas(x:PosicionFirmaConFirmante):FormGroup{
    return this.fb.group({
      id: [x.id],
      pagina: [x.pagina],
      firmante: [x.firmante],
      posicionTop: [x.posicionTop],
      posicionLeft: [x.posicionLeft],
      posicionRight: [x.posicionRight],
      posicionHeight: [x.posicionHeight],
      posicionWidth: [x.posicionWidth]
    })
  }

  //cargamos cada firmanteNif que viene de la api y añadimos el array de posicionFirmas al correspondiente firmanteNif del form.
  setExisitingFirmasArray(firmantesNifArray: FirmanteNif[]){
    firmantesNifArray.forEach(x=>{
      const isEmpty = Object.keys(x.firmas).length;
      if(x && isEmpty !== 0 && x.firmas.posicionFirmas?.length > 0){
        const index = this.firmantesNif.value.findIndex((y:FirmanteNif) => y.tipoFirmante.desc == x.tipoFirmante.desc)
        let firmasArray = x.firmas.posicionFirmas;
        let formArrayFirmasFirmante = new FormArray([]);
        firmasArray.forEach(z=>{
          formArrayFirmasFirmante.push(this.fb.group({
            id: [z.id],
            pagina: [z.pagina],
            firmante:[x.tipoFirmante],
            posicionTop: [z.posicionTop],
            posicionLeft: [z.posicionLeft],
            posicionRight: [z.posicionRight],
            posicionHeight: [z.posicionHeight],
            posicionWidth: [z.posicionWidth]
          }))
        })

        let posicionFirmantesForm = this.firmantesNif.at(index).get('firmas') as FormGroup;
        posicionFirmantesForm.controls['posicionFirmas'] = formArrayFirmasFirmante;

        this.refreshFormValues(posicionFirmantesForm)

        this.refreshFormValues(this.formularioFirmaArray.at(index) as FormGroup)
        this.refreshFormValues(posicionFirmantesForm.controls['posicionFirmas'])

      }
    })

  }

// Formularios

  addFormCasillas(firmante: FirmanteNif){
    let idFormulario:number = 0
    if(this.casillasVerificacionFormArray.length > 0){
      let idArray = this.casillasVerificacionFormArray.value.map((x:any) => x.id);
      idFormulario = Math.max(...idArray);
    }
    idFormulario++;
    const newForm = this.fb.group({
      nombre:[''],
      // nombre:[{value: '', disabled: true}],
      orden:[],
      firmante:[firmante.tipoFirmante],
      id: [idFormulario],
      pagina: [],
      posicionTop: [],
      posicionLeft: [],
      posicionRight: [],
      posicionHeight: [],
      posicionWidth: [],
    },{ validator: [validacionNombreFormulario(this.filtrarCasillasVerificacionMostradas())]} as AbstractControlOptions)

    // newForm.controls['nombre'].disable();
    this.casillasVerificacionFormArray.push(newForm)
    this.addFormulariosEnFirmante(firmante, newForm);
  }


  addFormulariosEnFirmante(firmante: FirmanteNif, formularioNuevo: FormGroup){
    let index = this.firmantesNif.value.findIndex((x: FirmanteNif) => x.tipoFirmante.desc == firmante.tipoFirmante.desc)
    let firmanteNif = this.firmantesNif.at(index) as FormGroup;
    let indexCasillasForm = this.casillasVerificacionFormArray.value.findIndex((x:any) => x.id == formularioNuevo.controls['id'].value)
    if(indexCasillasForm == -1){
      this.casillasVerificacionFormArray.push(formularioNuevo)
    }else{
      let singleCasillasForm = this.casillasVerificacionFormArray.at(indexCasillasForm) as FormGroup;
      singleCasillasForm.setValue(formularioNuevo.value)
    }
    let formArrayToSave = this.casillasVerificacionFormArray.value.filter((x:any)=> x.firmante.desc == firmante.tipoFirmante.desc)
    let newArray: FormArray = new FormArray([]);
    formArrayToSave.forEach((x:any)=> {
      newArray.push(this.fb.group({
        nombre:[x.nombre],
        orden: [x.orden],
        id: [x.id],
        pagina: [x.pagina],
        // firmante: [x.firmante],
        posicionTop: [x.posicionTop],
        posicionLeft: [x.posicionLeft],
        posicionRight: [x.posicionRight],
        posicionHeight: [x.posicionHeight],
        posicionWidth: [x.posicionWidth],
      },{ validator: [validacionNombreFormulario(this.filtrarCasillasVerificacionMostradas())]} as AbstractControlOptions))
    });
    firmanteNif.controls['formularios'] = newArray;
    this.refreshFormValues(firmanteNif)
    this.refreshFormValues(firmanteNif.controls['formularios'])
    this.refreshFormValues(this.formularioDocumento)
  }

  filtrarCasillasVerificacionMostradas(){
    let casillasVerificacionMostradas;
    casillasVerificacionMostradas = this.casillasVerificacionFormArray.value.filter((x:any)=> x.orden == 1 || x.orden == null)
    return casillasVerificacionMostradas
  }
  addCasillasArray(posicionCasillas: PosicionFormularioConFirmante[]){
    let indexFirmanteNif: number = -1;
    let index: number;
    let arrayCasillaEspecifico: PosicionFormularioConFirmante[] = [];
    let formArrayCasillasFirmante = new FormArray([]);
    let posicionFirmantesForm:FormGroup;
    this.casillasVerificacionFormArray = new FormArray([]);
    this.firmantesNif.value.forEach((x: FirmanteNif) => {
      indexFirmanteNif++;
      posicionFirmantesForm = this.firmantesNif.at(indexFirmanteNif) as FormGroup;
      posicionFirmantesForm.controls['formularios'] = new FormArray([]);
      posicionFirmantesForm.controls['formularios'].updateValueAndValidity();
      posicionCasillas.forEach(y => {
        arrayCasillaEspecifico = [];
        if(x.tipoFirmante.codigo == y.firmante.codigo){
          index = indexFirmanteNif;
          (posicionFirmantesForm.controls['formularios'] as FormArray).push(this.fb.group({
              id: [y.id],
              pagina: [y.pagina],
              firmante: [y.firmante],
              posicionTop: [y.posicionTop],
              posicionLeft: [y.posicionLeft],
              posicionRight: [y.posicionRight],
              posicionHeight: [y.posicionHeight],
              posicionWidth: [y.posicionWidth],
              orden: [y.orden],
              nombre: [y.nombre]
            }));

            posicionFirmantesForm.controls['formularios'].updateValueAndValidity();
            this.casillasVerificacionFormArray.push(this.fb.group({
              id: [y.id],
              pagina: [y.pagina],
              firmante: [y.firmante],
              posicionTop: [y.posicionTop],
              posicionLeft: [y.posicionLeft],
              posicionRight: [y.posicionRight],
              posicionHeight: [y.posicionHeight],
              posicionWidth: [y.posicionWidth],
              orden: [y.orden],
              nombre: [y.nombre]
            }))
        }
        this.casillasVerificacionFormArray.updateValueAndValidity();
      })
    })
    //this.montarObjetoDocumentoGuardar();
  }

  setExisitingFormulariosArray(firmanteNif: FirmanteNif | null){
    let newFormArray = new FormArray([]);
    if(firmanteNif == null){
      this.casillasVerificacionFormArray = new FormArray([]);
    }else if(firmanteNif.formularios){
      if(firmanteNif.firmas.tipoFirma!==TipoFirma.Validador){ //solo añadimos los formularios de los firmantes que no son validadores. Cambio hecho porqué ahora los validadores no pueden tener formularios ni campos editables.
        firmanteNif.formularios.forEach(x=>{
          const newForm = this.fb.group({
            id: [x.id],
            nombre:[x.nombre],
            orden:[x.orden],
            pagina: [x.pagina],
            posicionTop: [x.posicionTop],
            posicionLeft: [x.posicionLeft],
            posicionRight: [x.posicionRight],
            posicionHeight: [x.posicionHeight],
            posicionWidth: [x.posicionWidth],
            firmante:[firmanteNif.tipoFirmante]
          });
          // ,{ validator: [validacionNombreFormulario(this.casillasVerificacionFormArray.value)]} as AbstractControlOptions
          newFormArray.push(newForm);
          let index = this.casillasVerificacionFormArray.value.findIndex(x=> x.id == newForm.controls['id'].value)
          if(index==-1) this.casillasVerificacionFormArray.push(newForm);
        })
      }

    }
    return newFormArray
  }

  borrarFormulariosSinNombre(){
    // this.formularioDocumento.controls['firmantesNif'].value.forEach((x:any)=>{
    //   x.formularios.forEach((y:any, i:number)=>{
    //     if(y.nombre==''||y.nombre==null){
    //     }
    //   })
    // })

    // this.firmantesNif.controls.forEach((x,i)=>{
    //   let formulariosArray = (x as FormGroup).controls['formularios'] as FormArray;
    //     formulariosArray.controls.forEach((y,iF)=>{
    //       let formNuevo: PosicionFormulario = {} as PosicionFormulario;
    //       formNuevo.nombre = (y as FormGroup).controls['nombre'].value;
    //       formNuevo.id = (y as FormGroup).controls['id'].value;
    //       if(formNuevo.nombre == ''|| formNuevo.nombre == null){
    //
    //         (this.firmantesNif.at(i).get('formularios') as FormArray).removeAt(iF)
    //       }
    //     })
    // })

  }

//Campos Editables
  //añadir al form las firmas nuevas
  addCamposArray(firmante: TipoFirmante, posicionCampos: PosicionFirmaConFirmante[]){
    let indexFirmanteNif: number = -1;
    let arrayFirmanteEspecifico: PosicionFirmaConFirmante[] = [];
    let formArrayCamposFirmante = new FormArray([]);
    let posicionFirmantesForm;
    this.firmantesNif.value.forEach((x: FirmanteNif) => {
      indexFirmanteNif++;
      let firmanteNif = this.firmantesNif.at(indexFirmanteNif) as FormGroup;
      firmanteNif.controls['camposEditables'] = new FormArray([]);
      firmanteNif.controls['camposEditables'].updateValueAndValidity();
      posicionCampos.forEach(y => {
        arrayFirmanteEspecifico = [];
        if(x.tipoFirmante.codigo == y.firmante.codigo){
          (firmanteNif.controls['camposEditables'] as FormArray).push(this.fb.group({
              id: [y.id],
              pagina: [y.pagina],
              firmante: [y.firmante],
              posicionTop: [y.posicionTop],
              posicionLeft: [y.posicionLeft],
              posicionRight: [y.posicionRight],
              posicionHeight: [y.posicionHeight],
              posicionWidth: [y.posicionWidth]
            }))
            firmanteNif.controls['camposEditables'].updateValueAndValidity();
        }
      })
    })
    this.montarObjetoDocumentoGuardar();
  }

  //cargamos cada firmanteNif que viene de la api y añadimos el array de camposEditables al correspondiente firmanteNif del form.
  setExisitingCamposArray(firmanteNif: FirmanteNif){
    let newFormArray = new FormArray([]);
    if(firmanteNif.camposEditables && firmanteNif.firmas.tipoFirma!==TipoFirma.Validador){ //solo añadimos al formulario los campos editables que su tipo de firma no sea validador.
      firmanteNif.camposEditables.forEach(x=>{
        const newForm = this.fb.group({
          id: [x.id],
          pagina: [x.pagina],
          posicionTop: [x.posicionTop],
          posicionLeft: [x.posicionLeft],
          posicionRight: [x.posicionRight],
          posicionHeight: [x.posicionHeight],
          posicionWidth: [x.posicionWidth],
          firmante:[firmanteNif.tipoFirmante]
        })
        newFormArray.push(newForm);
        this.camposEditablesFormArray.push(newForm);
      })
    }
    return newFormArray
  }

// General
  montarObjetoDocumentoGuardar(){
    if(this.formularioDocumento){
      this.objetoDocumentoEnviar.cifOrden = this.formularioDocumento.controls['cifOrden'].value;
      this.objetoDocumentoEnviar.cifPage = this.formularioDocumento.controls['cifPage'].value;
      this.objetoDocumentoEnviar.cifFormulario = this.formularioDocumento.controls['cifFormulario'].value;
      // this.objetoDocumentoEnviar.contieneCif = this.formularioDocumento.controls['contieneCif'].value;
      // this.objetoDocumentoEnviar.copyConfiguracion = this.formularioDocumento.controls['copyConfiguracion'].value;
      this.objetoDocumentoEnviar.generico = this.formularioDocumento.controls['generico'].value;
      this.objetoDocumentoEnviar.idPdf = this.formularioDocumento.controls['idPdf'].value;
      this.objetoDocumentoEnviar.idTipoDocOrigen = this.formularioDocumento.controls['idTipoDocOrigen'].value;
      this.objetoDocumentoEnviar.idTipoDocumento = this.formularioDocumento.controls['idTipoDocumento'].value;
      this.objetoDocumentoEnviar.incluirContenido = this.formularioDocumento.controls['incluirContenido'].value;
      this.objetoDocumentoEnviar.nombre = this.formularioDocumento.controls['nombre'].value.trim();
      this.objetoDocumentoEnviar.texto = this.formularioDocumento.controls['texto'].value;
      this.objetoDocumentoEnviar.textoAExcluir = this.formularioDocumento.controls['textoAExcluir'].value;
      this.objetoDocumentoEnviar.tiposContenido = this.formularioDocumento.controls['tiposContenido'].value;
      // this.objetoDocumentoEnviar.urlDocTemp = this.formularioDocumento.controls['urlDocTemp'].value;
      this.objetoDocumentoEnviar.paginas = this.formularioDocumento.controls['paginas'].value;
      this.objetoDocumentoEnviar.esCopiaBasica = this.formularioDocumento.controls['esCopiaBasica'].value;
      this.objetoDocumentoEnviar.split = this.formularioDocumento.controls['split'].value;
      this.objetoDocumentoEnviar.formatoNombreArchivoAuditTrail = this.formularioDocumento.controls['formatoNombreArchivoAuditTrail'].value;
      this.objetoDocumentoEnviar.formatoNombreArchivoFirmado = this.formularioDocumento.controls['formatoNombreArchivoFirmado'].value
      this.objetoDocumentoEnviar.firmantesNif = [];
      this.objetoDocumentoEnviar.textoFormulario= this.formularioDocumento.controls['textoFormulario'].value;
      this.objetoDocumentoEnviar.textoAExcluirFormulario = this.formularioDocumento.controls['textoAExcluirFormulario'].value;
      this.objetoDocumentoEnviar.esRLT = this.formularioDocumento.controls['esRLT'].value;

      this.firmantesNif.controls.forEach(x=>{
        let newFirmanteNif: FirmanteNif = {} as FirmanteNif;
        newFirmanteNif.tipoFirmante = ((x as FormGroup).controls['tipoFirmante'] as FormGroup).value;
        newFirmanteNif.nifOrden = (x as FormGroup).controls['nifOrden'].value;
        newFirmanteNif.nifFormulario = (x as FormGroup).controls['nifFormulario'].value;
        newFirmanteNif.nifPage = (x as FormGroup).controls['nifPage'].value;
        newFirmanteNif.firmas = this.convierteFirmantes((x as FormGroup).controls['firmas'] as FormGroup);
        newFirmanteNif.formularios = [];

        let formulariosArray = (x as FormGroup).controls['formularios'] as FormArray;
        formulariosArray.controls.forEach(y=>{
          let formNuevo: PosicionFormulario = {} as PosicionFormulario;
          formNuevo.id = (y as FormGroup).controls['id'].value;
          formNuevo.orden = (y as FormGroup).controls['orden'].value;
          formNuevo.nombre = (y as FormGroup).controls['nombre'].value;
          formNuevo.pagina = (y as FormGroup).controls['pagina'].value;
          formNuevo.posicionTop = (y as FormGroup).controls['posicionTop'].value;
          formNuevo.posicionLeft = (y as FormGroup).controls['posicionLeft'].value;
          formNuevo.posicionRight = (y as FormGroup).controls['posicionRight'].value;
          formNuevo.posicionHeight = (y as FormGroup).controls['posicionHeight'].value;
          formNuevo.posicionWidth = (y as FormGroup).controls['posicionWidth'].value;

          if(formNuevo.nombre !== '' && formNuevo.orden !== null) newFirmanteNif.formularios.push(formNuevo)
        })

        newFirmanteNif.camposEditables = [];
        let camposArray = (x as FormGroup).controls['camposEditables'] as FormArray;
        camposArray.controls.forEach(y=>{
          let formNuevo: PosicionFormulario = {} as PosicionFormulario;
          formNuevo.id = (y as FormGroup).controls['id'].value;
          formNuevo.pagina = (y as FormGroup).controls['pagina'].value;
          formNuevo.posicionTop = (y as FormGroup).controls['posicionTop'].value;
          formNuevo.posicionLeft = (y as FormGroup).controls['posicionLeft'].value;
          formNuevo.posicionRight = (y as FormGroup).controls['posicionRight'].value;
          formNuevo.posicionHeight = (y as FormGroup).controls['posicionHeight'].value;
          formNuevo.posicionWidth = (y as FormGroup).controls['posicionWidth'].value;

          newFirmanteNif.camposEditables.push(formNuevo)
        })
        this.objetoDocumentoEnviar.firmantesNif.push(newFirmanteNif)
      })
    }
    return this.objetoDocumentoEnviar
  }

  convierteFirmantes(x:FormGroup):any{
    var element:any ={};
    element.tipoFirma = x.controls["tipoFirma"].value;
    element.nombreCertificado = x.controls["nombreCertificado"].value;
    element.tieneTag = x.controls["tieneTag"].value;
    element.nombreTag = x.controls["nombreTag"].value;
    element.posicionFirmas = x.controls["posicionFirmas"].value;
    return element;
  }

  //refrescamos los valores de todos los controles para que salga el resultado también en value y no solo dentro de los controles
  refreshFormValues(values:any){
    for (const field in values.controls) { // 'field' is a string
      const control = values.get(field); // 'control' is a FormControl
      control.updateValueAndValidity();
    }
  }

  documentoAEditar: TipoDocumento = new TipoDocumentoClass();

  hayCambios():boolean{
    //borramos las paginas ya que al crear un nuevo documento después de haber abierto uno existente, se quedan las paginas del existente en el objetoFinal y al hacer la comparación siempre da true.
    delete this.documentoAEditar.paginas;
    let objetoFinal = this.montarObjetoDocumentoGuardar();
    delete objetoFinal.paginas;
    if(JSON.stringify(this.documentoAEditar) === JSON.stringify(objetoFinal)){
      return false;
    }else{
      return true
    }
  }

}
