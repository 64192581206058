import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsuariActivo, UsuarioEstado, UsuarioModel, UsuarioPermisos, UsuarioTipo } from '../models/usuario-model';
import { RecuperaUsuariosMockService } from './recupera-usuarios-mock.service';
import { IRecuperarUsuariosService } from 'src/app/pages/seguridad/services/ports/i-recuperar-usuarios-service';
import { LoginService } from '../../login/services/login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FiltroBasico } from 'src/app/shared/models/filtros';

@Injectable({
  providedIn: 'root'
})
export class DatosUsuariosService {

  usuarios: UsuarioModel[]=[];
  usuariosFiltrados: UsuarioModel[] = [];
  filtros: FiltroBasico[] = [];
  usuarioEstados: UsuarioEstado[];
  usuariosPermisos: UsuarioPermisos[];
  usuarioActivoForm: FormGroup = new FormGroup({});

  private infoUsuarioActivo$ = new BehaviorSubject<any>({});
  datosUsuarioActivo$ = this.infoUsuarioActivo$.asObservable();
  refreshUsuarios: boolean;

  // @Inject('RecuperaUsuarios') private datosUsuarios: IRecuperarUsuariosService
  constructor(private datosUsuariosMockService: RecuperaUsuariosMockService,
              @Inject('RecuperaUsuarios') private datosUsuarios: IRecuperarUsuariosService,
              private loginService: LoginService,
              private fb: FormBuilder
             ) {}

  transferirUsuarioActivo(info:any){
    this.infoUsuarioActivo$.next(info);
    this.loginService.updateUserActivoLocalStorage(info);
  }

  getUsuariosTipo(): Observable<UsuarioTipo[]>{
    return this.datosUsuarios.getUsuariosTipo()
    .pipe(
      map((response:any) => {
        let usuariosTipo: UsuarioTipo[] = response;
          return usuariosTipo;
        }
      )
    );
  }


  getUsuarios(): Observable<UsuarioModel[]>{
    return this.datosUsuarios.getUsuarios(this.refreshUsuarios)
      .pipe(
        map(resp=>{
          this.usuarios = resp;
          this.refreshUsuarios = false;
          return this.usuarios;
        })
      )
  }

  getUsuariosPermisos(): Observable<UsuarioPermisos[]>{
    return this.datosUsuariosMockService.getUsuariosPermisos()
      .pipe(
        map(resp=>{
          this.usuariosPermisos = resp;
          return this.usuariosPermisos
        })
      )
  }

  getEstadosUsuario(): Observable<UsuarioEstado[]>{
    return this.datosUsuarios.getEstadosUsuario()
      .pipe(
        map(resp=>{
          this.usuarioEstados = resp
          return this.usuarioEstados
        })
      )
  }

  getUsuariosSeguridad(): Observable<UsuarioEstado[]>{
    return this.datosUsuariosMockService.getUsuarioSeguridad()
      .pipe(
        map(resp=>{
          let usuarioSeguridad: UsuarioEstado[] = resp;
          return usuarioSeguridad
        })
      )
  }

  invitaUsuario(emailUsuario:any): Observable<string>{
    return this.datosUsuarios.invitaUsuario(emailUsuario)
  }

  guardaUser(data: UsuarioModel): Observable<void>{
    return this.datosUsuarios.guardaUser(data)
  }
  guardaUserActivo(data: UsuariActivo,tenant:string): Observable<void>{
    return this.datosUsuarios.guardaUsuarioActivo(data,tenant)
  }


  desactivaUsuario(emailUsuario:string): Observable<void>{
    return this.datosUsuarios.desactivaUsuario(emailUsuario);
  }

  activaUsuario(emailUsuario:string): Observable<void>{
    return this.datosUsuarios.activaUsuario(emailUsuario);
  }

  inicializarUsuarioActivoForm(){
    this.usuarioActivoForm = this.fb.group({
      nombre:[this.loginService.loginData.User.Nombre, Validators.required],
      apellido1: [this.loginService.loginData.User.Apellido1, Validators.required],
      email: [this.loginService.loginData.User.Login, Validators.required]
    })
  }

  resetPasswordAzureAd():Observable<void>{
    return this.datosUsuarios.resetPasswordAzureAd();
  }

  SetFilter(_filtros: FiltroBasico[]){
    this.filtros = _filtros;
  }

  // Aplicamos los filtros cargados al listado completo de usuarios y guardamos el resultado
  Filter(){
    this.usuariosFiltrados = [];
    this.usuariosFiltrados = this.usuarios;
    if(this.filtros.length == 0){
      this.usuariosFiltrados = this.usuarios;
    }else{
      for( const filter of this.filtros){
        if(filter.textoBuscar.trim() != ""){
          this.usuariosFiltrados =  this.usuariosFiltrados.filter(x =>
            x.nombre?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.apellido1?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.email?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.rol?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase()) ||
            x.usuarioActivo?.toString().toUpperCase().includes(filter.textoBuscar.toUpperCase())
            )
          }
        }
    this.usuariosFiltrados.forEach(x => {
      if(! this.usuariosFiltrados.includes(x))  this.usuariosFiltrados.push(x);
      })
    }
  }

  reenviarInvitacion(data: UsuarioModel){
    return this.datosUsuarios.reenviarInvitacion(data);
  }

  deleteUsuario(login:string):Observable<any>{
    return this.datosUsuarios.deleteUsuario(login);
  }
}
