<div *ngIf="mostrar" id="dragDiv"
     #draggableDiv
     class="flex"
     [ngStyle]="{'top.px': topPosition, 'left.px': leftPosition}">

    <svg #draggableIcon class="icon-draggable">
      <use #draggableUse xlink:href="#icon-draggable"></use>
    </svg>
    <span>{{palabraClave.nombre}}</span>
</div>
