<form [formGroup]="formEmpresa">
  <div class="modal-container">
    <svg class="icon-close" mat-dialog-close>
      <use xlink:href="#icon-close"></use>
    </svg>
    <h1>{{tituloModal}}</h1>
    <div class="flex-column">
      <div class="modal-content">
        <h6>Datos identificativos de la empresa</h6>
        <div class="input-text-custom">
          <label for="nombreEmpresa">Nombre</label>
          <div *ngIf="!isEditar" class="info">{{nombreEmpresa.value}}</div>
          <input *ngIf="isEditar"
                  type="text"
                  id="nombreEmpresa"
                  name="nombreEmpresa"
                  formControlName="nombreEmpresa"
                  [ngClass]="{'errorInput': isCampoValido('nombreEmpresa')}"/>
          <span class="texto-validacion"
              *ngIf="isCampoValido('nombreEmpresa')">
              El nombre de la empresa es requerido.
          </span>
        </div>
        <div class="input-text-custom">
          <label for="codigoEmpresa">Código</label>
          <div class="info">{{codigoEmpresa.value}}</div>
        </div>
        <div class="input-text-custom">
          <label for="cif">Cif</label>
          <div *ngIf="!isEditar" class="info">{{cif.value}}</div>
          <input *ngIf="isEditar"
                  type="text"
                  id="cif"
                  name="cif"
                  formControlName="cif"
                  [ngClass]="{'errorInput': isCampoValido('cif')}"/>
          <span class="texto-validacion" *ngIf="cif.errors?.inputVacio && cif.touched">El cif de la empresa es requerido.</span>
          <span class="texto-validacion" *ngIf="cif.errors?.cifExiste && cif.touched">El cif de la empresa ya existe.</span>
        </div>
        <div class="input-text-custom">
          <label for="tipoDocumento">Tipo de documento</label>
          <div *ngIf="!isEditar" class="info">{{tipoDocumento.value}}</div>
          <input *ngIf="isEditar"
                  type="text"
                  id="tipoDocumento"
                  name="tipoDocumento"
                  formControlName="tipoDocumento"/>
        </div>
        <h6>Datos del validador</h6>
        <div class="input-text-custom">
          <label for="nombreEmpleado">Nombre</label>
          <div *ngIf="!isEditar" class="info">{{nombreEmpleado.value}}</div>
          <input *ngIf="isEditar"
                  type="text"
                  id="nombreEmpleado"
                  name="nombreEmpleado"
                  formControlName="nombreEmpleado"
                  [ngClass]="{'errorInput': isCampoValido('nombreEmpleado')}"/>
          <!-- <span class="texto-validacion"
                  *ngIf="isCampoValido('nombreEmpleado')">
                  El nombre del empleado es requerido.
          </span> -->
        </div>
        <div class="input-text-custom">
          <label for="apellido1Empleado">1º apellido</label>
          <div *ngIf="!isEditar" class="info">{{apellido1Empleado.value}}</div>
          <input *ngIf="isEditar"
                  type="text"
                  id="apellido1Empleado"
                  name="apellido1Empleado"
                  formControlName="apellido1Empleado"
                  [ngClass]="{'errorInput': isCampoValido('apellido1Empleado')}"/>
          <!-- <span class="texto-validacion"
                  *ngIf="isCampoValido('apellido1Empleado')">
                  El primer apellido es requerido.
          </span> -->
        </div>
        <div class="input-text-custom">
          <label for="apellido2Empleado">2º apellido</label>
          <div *ngIf="!isEditar" class="info">{{apellido2Empleado.value}}</div>
          <input *ngIf="isEditar"
                  type="text"
                  id="apellido2Empleado"
                  name="apellido2Empleado"
                  formControlName="apellido2Empleado"/>
        </div>
        <div class="input-text-custom">
          <label for="emailEmpleado">E-mail</label>
          <div *ngIf="!isEditar" class="info">{{emailEmpleado.value}}</div>
          <input *ngIf="isEditar"
                  type="email"
                  id="emailEmpleado"
                  name="emailEmpleado"
                  formControlName="emailEmpleado"
                  [ngClass]="{'errorInput': isCampoValido('emailEmpleado')}"/>
          <span class="texto-validacion"
                *ngIf="isCampoValido('emailEmpleado')">
                El email tiene que contener un formato correcto.
          </span>
        </div>
      </div>
      <div class="modal-buttons">
        <!-- <app-boton-accion
                        *ngIf="!isEditar && !nuevo"
                        (botonClicked)="eliminarEmpresa()"
                        [icono]="'hs-bin'"
                        [titulo]="'Eliminar'"
                        [id]="'Eliminar'"
                        [outline]="true"
                        [cancelar]="true">
        </app-boton-accion> -->
        <app-boton-accion
                        *ngIf="!isEditar"
                        (botonClicked)="editarEmpresa()"
                        [icono]="'hs-edit'"
                        [titulo]="'Editar'"
                        [id]="'Editar'">
        </app-boton-accion>
        <app-boton-accion
                        *ngIf="isEditar"
                        (botonClicked)="guardarCambiosEmpresa()"
                        [icono]="'hs-guardar'"
                        [titulo]="'Guardar'"
                        [id]="'Guardar'">
        </app-boton-accion>
      </div>
    </div>
  </div>
</form>

