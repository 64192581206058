import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CoordenadasObjeto } from '../models/firmante-nif';

@Injectable({
  providedIn: 'root'
})

export class DatosConfiguracionDocumentosService {

  pagina: number;
  palabraPosition:CoordenadasObjeto;
  firmaPosition: CoordenadasObjeto;
  casillaPosition: CoordenadasObjeto;
  docPosition: CoordenadasObjeto;
  campoPosition: CoordenadasObjeto;

  infoPalabra$ = new BehaviorSubject<any>({});
  datosPalabra$ = this.infoPalabra$.asObservable();

  infoFirma$ = new BehaviorSubject<any>({});
  datosFirma$ = this.infoFirma$.asObservable();

  private infoArrayVacio$ = new BehaviorSubject<any>({});
  datosArrayFirmasVacio$ = this.infoArrayVacio$.asObservable();

  private infoArrayFormulariosVacio$ = new BehaviorSubject<any>({});
  datosArrayFormulariosVacio$ = this.infoArrayFormulariosVacio$.asObservable();

  private infoArrayCamposEditablesVacio$ = new BehaviorSubject<any>({});
  datosArrayCamposEditablesVacio$ = this.infoArrayCamposEditablesVacio$.asObservable();

  private infoTipoFirma$ = new BehaviorSubject<any>({});
  datosTipoFirma$ = this.infoTipoFirma$.asObservable();

  infoCasilla$ = new BehaviorSubject<any>({});
  datosCasilla$ = this.infoCasilla$.asObservable();

  infoCampo$ = new BehaviorSubject<any>({});
  datosCampo$ = this.infoCampo$.asObservable();

  private infoDocId$ = new BehaviorSubject<any>({});
  datosDocId$ = this.infoDocId$.asObservable();

  private infoValidador$ = new BehaviorSubject<any>({});
  datosValidador$ = this.infoValidador$.asObservable();

  nohayCif: boolean = false;
  public noExistenNifs: boolean[] = [];

  constructor() {}

  transferirDatosValidador(info:any){
    this.infoValidador$.next(info);
  }

  transferirDatosPalabra(info:any){
    this.infoPalabra$.next(info);
  }

  transferirDatosFirma(info:any){
    this.infoFirma$.next(info);
  }

  transferirArrayFirmasVacio(info:any){
    this.infoArrayVacio$.next(info);
  }

  transferirArrayFormulariosVacio(info:any){
    this.infoArrayFormulariosVacio$.next(info);
  }

  transferirArrayCamposEditablesVacio(info:any){
    this.infoArrayCamposEditablesVacio$.next(info);
  }

  transferirTipoFirma(info:any){
    this.infoTipoFirma$.next(info);
  }

  transferirDatosCasilla(info:any){
    this.infoCasilla$.next(info);
  }

  transferirDatosCampo(info:any){
    this.infoCampo$.next(info);
  }

  transferirDocId(info:any){
    this.infoDocId$.next(info);
  }

  isDentro(origen:string, objetoMover: any): boolean{
    let xNWOrigen: number;
    let yNWOrigen: number;
    let xSEOrigen: number;
    let ySEOrigen: number;

    if(origen == 'contenido-doc' || origen == 'firmas-temporal' || origen == 'casillas-temporal' || origen == 'campos-temporal'){
      xNWOrigen = this.docPosition.left;
      yNWOrigen = this.docPosition.top - 50;
      xSEOrigen = this.docPosition.left + this.docPosition.width - 15
      ySEOrigen = this.docPosition.top + this.docPosition.height
    }
    if(origen == 'doc-drag' || origen == 'firmas-dibujo' || origen == 'casillas-dibujo' || origen == 'campos-dibujo'){
      xNWOrigen = 0;
      yNWOrigen = 0;
      xSEOrigen = this.docPosition.left + this.docPosition.width - 60 - 15,
      ySEOrigen = this.docPosition.top + this.docPosition.height - 100
    }

    let coordDoc = {
      xNW: xNWOrigen,
      yNW: yNWOrigen,
      xSE: xSEOrigen,
      ySE: ySEOrigen
    }

    let coordObjetoMover = {
      xNW: objetoMover.left,
      yNW: objetoMover.top - 50,
      xSE: objetoMover.left + objetoMover.width,
      ySE: objetoMover.top + objetoMover.height
    }

    if(coordObjetoMover.xNW >= coordDoc.xNW &&
       coordObjetoMover.yNW >= coordDoc.yNW &&
       coordObjetoMover.xSE <= coordDoc.xSE &&
       coordObjetoMover.ySE <= coordDoc.ySE){
        return true
      }else{
      return false
      }
  }

}
