import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { CentrosModel } from 'src/app/pages/configuracion/maestros/centros/models/centros-model';
import { GridColDefsSeguridadCentros } from '../../models/grid-col-defs-seguridad-centros';

@Component({
  selector: 'app-grid-seguridad-centros',
  templateUrl: './grid-seguridad-centros.component.html',
  styleUrls: ['./grid-seguridad-centros.component.css']
})
export class GridSeguridadCentrosComponent implements OnInit {

  @Input() centrosData: CentrosModel[];

  api!: GridApi;
  apiCol: any;
  columnDefs: ColDef[] = new GridColDefsSeguridadCentros().columnDefs;
  gridOptions: GridOptions = {
    headerHeight: 0,
    onFirstDataRendered: (params) => {
      // Obtenemos los centros seleccionados del JSON
      // let _centrosPermitidos: any[] = JSON.parse(this.filtroSeguridadFirmas);
      let _centrosPermitidos: any[] = this.dataSeguridad.centrosSeleccionados.length>0 ? JSON.parse(this.dataSeguridad.centrosSeleccionados[0]?.id):[];
      params.api.forEachNode((node: any) => {
        if (_centrosPermitidos.find(x => x.codigoEmpresa == node.data.codigoEmpresa && x.codigosCentro.includes(node.data.codigoCentro))) node.setSelected(true);
      });
    }
  };
  @Input() filtroSeguridadFirmas: string = '';
  @Input() dataSeguridad: any;
  @Output() seleccionCentros: EventEmitter<any> = new EventEmitter<any>();
  overlayNoRowsTemplate: string ='<span>No se han encontrado centros</span>';
  overlayLoadingTemplate: string ='<span class="ag-overlay-loading-center">Cargando...</span>';

  constructor() { }

  ngOnInit(): void {
  }

  cargaApi(arg: GridReadyEvent){
    this.api = arg.api;
    this.apiCol = arg.columnApi;
    this.api.sizeColumnsToFit();
  }


  selectRows(event: any){
   let centrosSeleccionados = this.api.getSelectedRows();
   this.seleccionCentros.emit(centrosSeleccionados);
  }

}
