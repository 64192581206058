<div class="flex">
  <span class="titulo">EVENTOS DEL PROCESO</span>
  <span (click)="mostrarEventos()">
    <svg class="icon-arrow" [ngClass]="firmanteEventos ? 'icon-arrow-down':''">
      <use xlink:href="#hs-arrow"></use>
    </svg>
  </span>
</div>
<div *ngIf="firmanteEventos" class="events">
  <div class="events-block" *ngFor="let item of eventosAgrupados; let i = index">
    <div class="events-date">{{getDiaEvento(i)}}</div>
    <div class="events-message"  *ngFor="let evento of item.eventos">
      <span class="events-message-hour">{{getHoraEvento(evento.fecha)}}</span>
      <span>{{evento.descripcionEvento}}</span>
    </div>
  </div>
</div>
