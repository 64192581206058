<div id="dragDiv"
     #draggableDiv
     class="draggable resize"
     [ngStyle]="{'top.px': topPosition, 'left.px': leftPosition, 'width.px': casilla.width, 'height.px': casilla.height}">
      <svg #draggableIcon class="icon-draggable">
        <use xlink:href="#icon-draggable"></use>
      </svg>
      <svg class="icon-close" (click)="borrarCampo()">
        <use xlink:href="#icon-close"></use>
      </svg>
     <div id="resizeDiv" class="pinta"></div>
</div>
